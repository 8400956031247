var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mdc-popover",class:{ 'mdc-popover--expand': _vm.expandControl },on:{"keydown":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"]))return null;return _vm.closeIfDismissable.apply(null, arguments)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"up",38,$event.key,["Up","ArrowUp"])&&_vm._k($event.keyCode,"down",40,$event.key,["Down","ArrowDown"]))return null;$event.preventDefault();}],"keyup":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"up",38,$event.key,["Up","ArrowUp"]))return null;$event.preventDefault();return _vm.handleKeyUp.apply(null, arguments)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"down",40,$event.key,["Down","ArrowDown"]))return null;$event.preventDefault();return _vm.handleKeyDown.apply(null, arguments)}]}},[_c('div',{ref:"control",staticClass:"mdc-popover__control",class:{ 'mdc-popover__control--expand': _vm.expandControl }},[_vm._t("target",null,{"id":_vm.safeId('mdc-popover-target'),"ariaControls":_vm.isOpen ? _vm.safeId('mdc-popover') : null,"ariaExpanded":_vm.isOpen,"ariaHaspopup":"true","open":_vm.open,"close":_vm.close,"toggle":_vm.toggle})],2),(_vm.isOpen)?_c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.closeIfDismissable),expression:"closeIfDismissable"}],ref:"popover",staticClass:"mdc-popover__popover",class:{
						[_vm.$style['mds-popover']]: true,
						[_vm.$style['mds-popover--visible']]: _vm.isOpen,
						[_vm.$style[`mds-popover--${_vm.mdsPlacementModifier}`]]: true,
						'mdc-popover__popover--x-narrow': _vm.xNarrow,
						'mdc-popover__popover--narrow': _vm.narrow,
						'mdc-popover__popover--standard': !_vm.xNarrow && !_vm.narrow && !_vm.wide,
						'mdc-popover__popover--wide': _vm.wide
					},style:(_vm.popoverStyle),attrs:{"id":_vm.safeId('mdc-popover'),"aria-labelledby":_vm.safeId('mdc-popover-target'),"aria-hidden":!_vm.isOpen,"tabindex":"0","role":_vm.role},on:{"keydown":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"]))return null;return _vm.closeIfDismissable.apply(null, arguments)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"up",38,$event.key,["Up","ArrowUp"])&&_vm._k($event.keyCode,"down",40,$event.key,["Down","ArrowDown"]))return null;$event.preventDefault();}],"keyup":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"up",38,$event.key,["Up","ArrowUp"]))return null;$event.preventDefault();return _vm.handleKeyUp.apply(null, arguments)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"down",40,$event.key,["Down","ArrowDown"]))return null;$event.preventDefault();return _vm.handleKeyDown.apply(null, arguments)}]}},[(_vm.title)?_c('header',{staticClass:"mdc-popover__header",class:{
							[_vm.$style['mds-popover__header']]: true
						}},[(_vm.closeable)?_c('div',{class:_vm.$style['mds-popover__buttons']},[(_vm.closeable)?_c('mdc-button',{class:_vm.$style['mds-popover__button'],attrs:{"icon-only":"","small":"","icon":"remove"},on:{"click":_vm.close}}):_vm._e()],1):_vm._e(),_c('div',{staticClass:"mdc-popover__title",class:{
								[_vm.$style['mds-popover__title']]: true
							}},[_vm._v(" "+_vm._s(_vm.title)+" ")])]):_vm._e(),_c('div',{staticClass:"mdc-popover__content"},[_vm._t("content",null,{"open":_vm.open,"close":_vm.close,"toggle":_vm.toggle})],2)]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }