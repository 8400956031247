var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"newsletter-type-main"},[(_vm.isResponsiveToggle)?_c('div',[_c('div',{staticClass:"individual-newsletter-image-slider"},[(this.$store.state.article.ArticleCounter != 3 && !this.$store.state.header.isResponsiveSubscriptionModalActive)?_c('div',{staticClass:"combine-articles-opacity-right"}):_vm._e(),(this.$store.state.article.ArticleCounter == 3 && (!this.$store.state.header.isResponsiveHeaderActive && !this.$store.state.header.isResponsiveSubscriptionModalActive))?_c('div',{staticClass:"combine-articles-opacity-left"}):_vm._e(),_c('mds-layout-grid',{staticClass:"slider-grid"},[_c('mds-row',{staticClass:"slider-grid-row"},[_c('mds-col',{staticClass:"slider-grid-col"},[_c('mdc-carousel',{staticClass:"slider-grid-coro"},_vm._l((_vm.Imagedata),function(image,index){return _c('mdc-carouselItems',{key:index,staticClass:"slider-grid-coro-item"},[[_c('img',{staticClass:"slider-images",attrs:{"src":image},on:{"click":function($event){return _vm.getProductName(_vm.product.productname)}}})]],2)}),1)],1)],1)],1)],1),_c('div',{class:[this.$store.state.article.ArticleCounter == 3 ? _vm.sliderBorderLeft : '', _vm.sliderBorderRight]})]):(_vm.productData != null && _vm.productData != undefined && _vm.productData != '')?_c('div',{staticClass:"newsletter-container"},[_c('div',{staticClass:"newsletter-image-column newsletter-image-column-left"},[_c('div',{class:[
        _vm.isfirstimageaddClass ? 'selected-product-div' : '',
        '',
      ],on:{"click":function($event){return _vm.addfirstimageClass(_vm.productData[0].productname)}}},[_c('div',{staticClass:"product-image-container"},[_c('img',{class:[
            _vm.isfirstimageaddClass ? 'selected-product' : '',
            'product-image',
          ],attrs:{"src":_vm.MFIimageUrl}})]),_c('p',{class:[
          _vm.isfirstimageaddClass ? 'selected-product-name' : '',
          'product-name',
        ]},[_vm._v(" "+_vm._s(_vm.productData[0].productname)+" ")]),_c('div',{class:[_vm.isfirstimageaddClass ? 'select-product-row' : '']})])]),_c('div',{staticClass:"newsletter-image-column"},[_c('div',{class:[
        _vm.issecondimageaddClass ? 'selected-product-div' : '',
        'product-div',
      ],on:{"click":function($event){return _vm.addsecondimageClass(_vm.productData[1].productname)}}},[_c('div',{staticClass:"product-image-container"},[_c('img',{class:[
            _vm.issecondimageaddClass ? 'selected-product' : '',
            'product-image',
          ],attrs:{"src":_vm.MSIimageUrl}})]),_c('p',{class:[
          _vm.issecondimageaddClass ? 'selected-product-name' : '',
          'product-name',
        ]},[_vm._v(" "+_vm._s(_vm.productData[1].productname)+" ")]),_c('div',{class:[_vm.issecondimageaddClass ? 'select-product-row' : '']})])]),_c('div',{staticClass:"newsletter-image-column"},[_c('div',{class:[
        _vm.isthirdimageaddClass ? 'selected-product-div' : '',
        'product-div',
      ],on:{"click":function($event){return _vm.addthirdimageClass(_vm.productData[2].productname)}}},[_c('div',{staticClass:"product-image-container"},[_c('img',{class:[
            _vm.isthirdimageaddClass ? 'selected-product' : '',
            'product-image',
          ],attrs:{"src":_vm.MDIimageUrl}})]),_c('p',{class:[
          _vm.isthirdimageaddClass ? 'selected-product-name' : '',
          'product-name',
        ]},[_vm._v(" "+_vm._s(_vm.productData[2].productname)+" ")]),_c('div',{class:[_vm.isthirdimageaddClass ? 'select-product-row' : '']})])]),_c('div',{staticClass:"newsletter-image-column"},[_c('div',{class:[
        _vm.isfourthimageaddClass ? 'selected-product-div' : '',
        'product-div',
      ],on:{"click":function($event){return _vm.addfourthimageClass(_vm.productData[3].productname)}}},[_c('div',{staticClass:"product-image-container"},[_c('img',{class:[
            _vm.isfourthimageaddClass ? 'selected-product' : '',
            'product-image',
          ],attrs:{"src":_vm.MEIimageUrl}})]),_c('p',{class:[
          _vm.isfourthimageaddClass ? 'selected-product-name' : '',
          'product-name',
        ]},[_vm._v(" "+_vm._s(_vm.productData[3].productname)+" ")]),_c('div',{class:[_vm.isfourthimageaddClass ? 'select-product-row' : '']})])])]):_vm._e(),_c('div',{staticClass:"border-line"}),_c('combine-newsletter-type',{attrs:{"productName":_vm.selectedProduct}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }