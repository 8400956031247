
const UserModule = {
    namespaced: true,
    state() {
        return {
          LoggedIn: false,
          NewRegister:false
      }
    }
  };
  
  export default UserModule;