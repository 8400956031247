var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:{
		'mdc-header': true,
		[_vm.$style['mds-section']]: true,
		[_vm.$style['mds-component--on-dark']]: _vm.onDark,
	}},[_c('div',{staticClass:"mdc-header__container",class:{
			[_vm.$style['mds-section__header-container']]: true,
			[_vm.$style['mds-section--level-' + _vm.size]]: true,
			[_vm.$style['mds-section--primary']]: _vm.primary,
			[_vm.$style['mds-section--secondary']]: _vm.secondary,
			[_vm.$style['mds-section--tertiary']]: _vm.tertiary,
			[_vm.$style['mds-section--border-bottom']]: _vm.borderBottom
		}},[_c(_vm.hTag,{tag:"component",staticClass:"mdc-header__title",class:{
				[_vm.$style['mds-section__title']]: true,
				'mdc-header__title--thin': _vm.thin,
				'mdc-header__title--light': _vm.light,
				'mdc-header__title--regular': _vm.regular,
				'mdc-header__title--bold': _vm.bold,
			}},[_vm._t("default")],2),(_vm.$slots.actions)?_c('div',{class:['mdc-header__actions', _vm.$style['mds-section__actions']]},[_vm._t("actions")],2):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }