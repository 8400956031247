<template>
  <div class="fund-500-category-container">
    <div v-if="this.$store.state.fund500.Fund500CategoryVisible">
      <div>
        <div class="fund-500-category-loader">
          <mds-loader v-if="isLoading" aria-label="Medium Loader"></mds-loader>
        </div>
        <div class="fund-500-category-header-text">
          <span class="fund-500-category-title">FundInvestor 500</span>

          <div style="position: relative" v-if="!isDataTableResponsiveToggle">
            <div class="fund-500-category-search">
              <mds-search-field placeholder="Find a Fund Report" label="Search" size="small" @keyup.enter="SearchFund()"
                v-model.trim="fundTickerText" maxlength="5"  @keyup="FilterTicker($event)">
              </mds-search-field>
            </div>
          </div>

          <div class="fund-500-category-fonts fund-500-category-help-text">
            Click on category name to view the funds in that category.
          </div>
        </div>
        <div style="position: relative" v-if="isDataTableResponsiveToggle">
          <br />
          <div class="fund-500-category-search">
            <mds-search-field placeholder="Find a Fund Report" label="Search" size="large" @keyup.enter="SearchFund()"
              v-model.trim="fundTickerText" maxlength="5"  @keyup="FilterTicker($event)">
            </mds-search-field>
          </div>
        </div>
      </div>
      <br />
      <!-- <mds-button variation="primary"> Button Text </mds-button> -->
      <div class="fund-500-category-table-div">
        <mds-table show-sortable :fixedFirstCol="isDataTableResponsiveToggle" class="fund-500-category-table">
          <mds-thead :nested-headers="1">
            <template v-slot:mds-thead-group-1>
              <mds-th colspan="1" class="fund-500-category-name-header"></mds-th>
              <mds-th colspan="6" class="fund-500-category-annual-return-header">
                <span class="fund-500-category-table-header-fonts fund-500-category-annual-return-text">Total Annualized
                  Return %</span>
                <span class="fund-500-category-annual-text-border"></span>
              </mds-th>
            </template>
            <mds-th :nested-headers="1" v-for="(header, index) in headers" :key="index"
              :right-aligned="header.align === 'right'" :class="header.class" :sortable="header.sortable"
              :sorted="header.sorted" @mds-th-sort-changed="
                              handleSortEvent(index, header.fieldName, $event)
                            ">
              <span :class="
                                index == 0
                                  ? 'fund-500-category-table-header-fonts'
                                  : 'fund-500-category-table-fonts'
                              ">
                {{ header.text }}
              </span>
            </mds-th>
          </mds-thead>
          <mds-tbody v-if="isDisplay">
            <mds-tr v-for="(row, index) in rows" :key="index">
              <mds-td class="fund-500-category-name">
                <div v-if="
                                    row.GroupName == 'N/A' ||
                                    row.GroupName == 'NaN' ||
                                    row.GroupName == '0' ||
                                    row.GroupName == '' ||
                                    row.GroupName == '&mdash;' ||
                                    row.GroupName == null ||
                                    row.GroupName == '&nbsp;'
                                  ">
                  &mdash;
                </div>
                <div v-else>
                  <span style="cursor: pointer" @click="categoryClick(row.ShortCode)">{{ row.GroupName }}</span>
                </div>
              </mds-td>
              <mds-td right-aligned class="fund-500-category-1month">
                <div v-if="
                                    row.AVG_DailyReturn4Week == 'N/A' ||
                                    row.AVG_DailyReturn4Week == 'NaN' ||
                                    row.AVG_DailyReturn4Week == '0' ||
                                    row.AVG_DailyReturn4Week == '' ||
                                    row.AVG_DailyReturn4Week == '&mdash;' ||
                                    row.AVG_DailyReturn4Week == null ||
                                    row.AVG_DailyReturn4Week == '&nbsp;'
                                  ">
                  &mdash;
                </div>
                <div v-else>
                  {{
                                    row.AVG_DailyReturn4Week | customDataFilter
                                    }}
                </div>
              </mds-td>
              <mds-td right-aligned class="fund-500-category-data">
                <div v-if="
                                    row.AVG_DailyReturnYTD == 'N/A' ||
                                    row.AVG_DailyReturnYTD == 'NaN' ||
                                    row.AVG_DailyReturnYTD == '0' ||
                                    row.AVG_DailyReturnYTD == '' ||
                                    row.AVG_DailyReturnYTD == '&mdash;' ||
                                    row.AVG_DailyReturnYTD == null ||
                                    row.AVG_DailyReturnYTD == '&nbsp;'
                                  ">
                  &mdash;
                </div>
                <div v-else>
                  {{
                                    row.AVG_DailyReturnYTD | customDataFilter
                                    }}
                </div>
              </mds-td>
              <mds-td right-aligned class="fund-500-category-data">
                <div v-if="
                                    row.AVG_DailyReturn156Week == 'N/A' ||
                                    row.AVG_DailyReturn156Week == 'NaN' ||
                                    row.AVG_DailyReturn156Week == '0' ||
                                    row.AVG_DailyReturn156Week == '' ||
                                    row.AVG_DailyReturn156Week == '&mdash;' ||
                                    row.AVG_DailyReturn156Week == null ||
                                    row.AVG_DailyReturn156Week == '&nbsp;'
                                  ">
                  &mdash;
                </div>
                <div v-else>
                  {{
                                    row.AVG_DailyReturn156Week | customDataFilter
                                    }}
                </div>
              </mds-td>
              <mds-td right-aligned class="fund-500-category-data">
                <div v-if="
                                    row.AVG_DailyReturn260Week == 'N/A' ||
                                    row.AVG_DailyReturn260Week == 'NaN' ||
                                    row.AVG_DailyReturn260Week == '0' ||
                                    row.AVG_DailyReturn260Week == '' ||
                                    row.AVG_DailyReturn260Week == '&mdash;' ||
                                    row.AVG_DailyReturn260Week == null ||
                                    row.AVG_DailyReturn260Week == '&nbsp;'
                                  ">
                  &mdash;
                </div>
                <div v-else>
                  {{
                                    row.AVG_DailyReturn260Week | customDataFilter
                                    }}
                </div>
              </mds-td>
              <mds-td right-aligned class="fund-500-category-data">
                <div v-if="
                                    row.AVG_MonthlyReturn10Year == 'N/A' ||
                                    row.AVG_MonthlyReturn10Year == 'NaN' ||
                                    row.AVG_MonthlyReturn10Year == '0' ||
                                    row.AVG_MonthlyReturn10Year == '' ||
                                    row.AVG_MonthlyReturn10Year == '&mdash;' ||
                                    row.AVG_MonthlyReturn10Year == null ||
                                    row.AVG_MonthlyReturn10Year == '&nbsp;'
                                  ">
                  &mdash;
                </div>
                <div v-else>
                  {{
                                    row.AVG_MonthlyReturn10Year | customDataFilter
                                    }}
                </div>
              </mds-td>
              <mds-td right-aligned class="fund-500-category-data">
                <div v-if="
                                    row.AVG_MonthlyReturn15Year == 'N/A' ||
                                    row.AVG_MonthlyReturn15Year == 'NaN' ||
                                    row.AVG_MonthlyReturn15Year == '0' ||
                                    row.AVG_MonthlyReturn15Year == '' ||
                                    row.AVG_MonthlyReturn15Year == '&mdash;' ||
                                    row.AVG_MonthlyReturn15Year == null ||
                                    row.AVG_MonthlyReturn15Year == '&nbsp;'
                                  ">
                  &mdash;
                </div>
                <div v-else>
                  {{
                                    row.AVG_MonthlyReturn15Year | customDataFilter
                                    }}
                </div>
              </mds-td>
            </mds-tr>
          </mds-tbody>
        </mds-table>
        <div style="margin-top: 41px; margin-bottom: 56px" class="fund-500-category-fonts">
          Data through {{ currentDate | formatDateValForDataTables }}. *Data through previous month end.
        </div>
      </div>
    </div>
    <fund500-funds :categoryValue="gridCategoryValue" :searchTickerName="searchTicker"></fund500-funds>
  </div>
</template>

<script>
import MdsLoader from "@mds/loader";
import { mapState } from "vuex";
import {
  MdsTable,
  MdsThead,
  MdsTh,
  MdsTbody,
  MdsTr,
  MdsTd,
} from "@mds/data-table";
import MdsSearchField from "@mds/search-field";
import Fund500Funds from "./Fund500Funds.vue";
import { fetchData } from "@/utilities/API/apiUtility";
export default {
  name: "fund500-categories",
  components: {
    MdsTable,
    MdsThead,
    MdsTh,
    MdsTbody,
    MdsTr,
    MdsTd,
    MdsSearchField,
    MdsLoader,
    Fund500Funds,
  },
  data() {
    return {
      is500Categories: true,
      isDataTableResponsiveToggle: null,
      gridCategoryValue: "",
      fundTickerText: "",
      searchTicker: "",
      headers: [
        {
          text: "Morningstar Categories",
          fieldName: "GroupName",
          sortable: true,
          sorted: 1,
        },
        {
          text: "1 Month",
          fieldName: "AVG_DailyReturn4Week",
          sortable: true,
          align: "right",
          class: "fund-500-category-header-1month",
        },
        {
          text: "YTD",
          fieldName: "AVG_DailyReturnYTD",
          sortable: true,
          align: "right",
          class: "fund-500-category-header",
        },
        {
          text: "3-Yr",
          fieldName: "AVG_DailyReturn156Week",
          sortable: true,
          align: "right",
          class: "fund-500-category-header",
        },
        {
          text: "5-Yr",
          fieldName: "AVG_DailyReturn260Week",
          sortable: true,
          align: "right",
          class: "fund-500-category-header",
        },
        {
          text: "10-Yr*",
          fieldName: "AVG_MonthlyReturn10Year",
          sortable: true,
          align: "right",
          class: "fund-500-category-header",
        },
        {
          text: "15-Yr*",
          fieldName: "AVG_MonthlyReturn15Year",
          sortable: true,
          align: "right",
          class: "fund-500-category-header",
        },
      ],
      rows: [],
      sortOrder: -1,
      sortColIndex: 0,
      M500CategoryGroupsUrl:
        process.env.VUE_APP_BackEndAPI + process.env.VUE_APP_M500CategoryGroups,
      isLoading: true,
      isDisplay: false,
      currentDate: "",
    };
  },
  methods: {
   FilterTicker(event) {
      var alphabetsOnly = /^[A-Za-z]+$/;
      if (!event.key.match(alphabetsOnly)) {
        this.fundTickerText = this.fundTickerText.substring(0,this.fundTickerText.length - 1);
      } else {
        this.fundTickerText = this.fundTickerText.toUpperCase();
      }
    },  
    GetM500CategoryGroups() {
      try {
        fetchData(this.M500CategoryGroupsUrl)
          .then((response) => {
            if (response) {
              if (response.status == 200) {
                if (response.data) {
                  this.rows = response.data;
                  this.handleSortEvent(0,this.headers[0].fieldName);
                  return true;
                }
              }
            }
          })
          .catch((error) => {
            console.log(error);
            this.errored = true;
          })
          .finally(() => ((this.isLoading = false), (this.isDisplay = true)));
      } catch (ex) {
        console.log(ex.message);
      }
    },
    handleSortEvent(colIndex, colName) {
      // if your tracked sort order is 0 (none), set it 1 (ascending)
      // if there is already an order being tracked inverse it
      this.sortOrder = this.sortOrder === 0 ? 1 : -this.sortOrder;

      // if the table was already sorted by a different column, then reset that column to 0 (none)
      if (this.sortColIndex !== null && this.sortColIndex !== colIndex) {
        this.$set(this.headers[this.sortColIndex], "sorted", 0);
        this.sortOrder = 1;
      }

      // track the current column as the one being sorted on
      this.sortColIndex = colIndex;
      this.$set(this.headers[colIndex], "sorted", this.sortOrder);

      // sort the row data by column clicked on
      this.rows.sort((a, b) => {
        return a[colName] >= b[colName] ? this.sortOrder : -this.sortOrder;
      });
    },

    categoryClick(value) {
      this.gridCategoryValue = value;
      this.$store.state.fund500.Fund500CategoryVisible = false;
    },
    SearchFund() {  
      if (this.fundTickerText != "" && this.fundTickerText != undefined) {
        this.searchTicker = this.fundTickerText;
        //this.$store.state.fund500.Fund500CategoryVisible = false;
      }
    },
  },
  mounted() {    
    this.$store.state.fund500.Fund500CategoryVisible= true,
    this.isDataTableResponsiveToggle =
      this.$store.state.responsive.isMobileContent;
    const date = new Date();
    // let day = ("0" + date.getDay(-1)).slice(-2);
    // let month = ("0" + (date.getMonth(-1) + 1)).slice(-2);
    // let year = date.getFullYear(-1);
    // let twoDigitYear = year.toString().substr(-2);

    // this.currentDate = `${month}-${day}-${twoDigitYear}`;
    date.setDate(date.getDate() - 1);
    this.currentDate = date;
    this.GetM500CategoryGroups();
  },
  computed: mapState({
    isDataTableResponsive: (state) => state.responsive.isMobileContent,
    isDataTableResponsiveAlias: "isMobileContent",
    isDataTableResponsiveState(state) {
      return state.responsive.isMobileContent;
    },   
  }),
  watch: {
    isDataTableResponsiveState() {
      this.isDataTableResponsiveToggle = this.isDataTableResponsiveState;
    },    
  },
};
</script>

<style lang="scss" scoped>
::-webkit-scrollbar {
  height: 7px;
  width: 7px;
}

::-webkit-scrollbar-thumb {
  background: rgba(171, 171, 171, 0.3);
  border-radius: 6px;
}

.fund-500-category-search {
  position: absolute;
  width: 195px;
  height: 29px;
  right: 1%;
  top: -31px;
}

.fund-500-category-data {
  padding-right: 8px;
  font-size: 16px;
}

.fund-500-category-1month {
  padding-right: 8px;
  font-size: 16px;
}

.fund-500-category-title {
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  color: #1e1e1e;
}

.fund-500-category-header-text {
  width: 100%;
  padding-top: 48px;
  margin-left: 2px;
}

.fund-500-category-annual-return-header {
  width: 900px;
  overflow: scroll;
}

.fund-500-category-table-div {
  width: 100%;
  padding-top: 48px;
  overflow-x: scroll;
}

.mds-thead .mds-thead__group .mds-th__inner {
  border-bottom: 0px !important;
  margin-bottom: 0px !important;
}

.fund-500-category-table {
  width: 100%;
  box-shadow: none;
}

.fund-500-category-table-fonts {
  font-weight: 700;
  font-size: 16px;
  color: #5e5e5e;
  display: flex;
  align-items: flex-end;
  color: #5e5e5e;
  flex: none;
  order: 0;
  flex-grow: 1;
  line-height: 21px;
}

.fund-500-category-table-header-fonts {
  font-weight: 700;
  font-size: 16px;
  color: #5e5e5e;
  display: flex;
  align-items: flex-end;
  color: #5e5e5e;
  flex: none;
  order: 0;
  flex-grow: 1;
  line-height: 21px;
}



.fund-500-category-annual-return-text {
  margin-left: 90px;
  position: absolute;
  top: -30px;
}

.fund-500-category-annual-text-border {
  border-bottom: 1px solid #808080;
  width: 90.5%;
  position: absolute;
  top: -10px;
  margin-left: 90px;
}

.fund-500-category-fonts {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #5e5e5e;
}

.fund-500-category-help-text {
  padding-top: 24px;
}

.fund-500-category-container {
  width: 1280px;
  margin: 0 auto;
}

.fund-500-category-name {
  cursor: pointer;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  color: #1e1e1e;
  line-height: 21px;
  flex: none;
  order: 0;
  flex-grow: 1;
}

.fund-500-category-header {
  padding-right: 8px;
}

.fund-500-category-header-1month {
  padding-right: 8px;
}

.fund-500-category-name-header {
  width: 320px;
}

.fund-500-category-name:hover {
  cursor: pointer;
}

.mds-table--show-sortable .mds-th--sortable .mds-th__inner {
  border-bottom: none;
}

.mds-th--sortable.mds-th--sorted-descending .mds-th__inner,
.mds-th--sortable.mds-th--sorted-ascending .mds-th__inner {
  border-bottom: 1px solid rgb(30, 30, 30);
}

@media only screen and (min-width: 1001px) and (max-width: 1200px) {
  .fund-500-category-header-text {
    padding-top: 48px;
  }

  .fund-500-category-header-1month {
    padding-top: 48px;
  }
}

@media only screen and (min-width: 500px) and (max-width: 600px) {
  .fund-500-category-search {
    right: -1%;
  }

}

@media only screen and (min-width: 320px) and (max-width: 1415px) {
  .fund-500-category-container {
    width: 90%;
    margin-left: 5%;
    margin-right: 5%;
  }
}

@media only screen and (max-width: 320px) {
  .fund-500-category-search {
    width: 90vw;
    padding-top: 60px;
  }

  .fund-500-category-name {
    font-size: 10px;
  }

  .fund-500-category-annual-return-header {
    width: 80vw;
    overflow: hidden;
  }

  .fund-500-category-help-text {
    padding-top: 4px;
  }

  .fund-500-category-name-header {
    width: 50vw;
  }

  .fund-500-category-annual-return-text {
    margin-left: 10px;
    position: relative;
    top: -1px;
    border-bottom: 1px solid #808080;
    width: 80vw;
  }

  .fund-500-category-table-fonts {
    font-weight: 700;
    font-size: 10px;
    color: #5e5e5e;
    display: flex;
    align-items: flex-end;
    color: #5e5e5e;
    flex: none;
    order: 0;
    flex-grow: 1;
    line-height: 21px;
  }

  .fund-500-category-table-header-fonts {
    font-weight: 700;
    font-size: 10px;
    color: #5e5e5e;
    display: flex;
    align-items: flex-end;
    color: #5e5e5e;
    flex: none;
    order: 0;
    flex-grow: 1;
    line-height: 21px;
  }

  .fund-500-category-data {
    font-size: 12px;
  }

  .fund-500-category-1month {
    font-size: 12px;
  }
}

@media only screen and (min-width: 375px) and (max-width: 428px) {

  .fund-500-category-table-fonts {
    font-size: 14px;
    //width:80px;
  }

  .fund-500-category-table-header-fonts {
    font-size: 14px;
  }

  .fund-500-category-1month {
    padding-right: 0px;
  }

  .fund-500-category-header-1month {
    padding-right: 0px;
  }

}



@media only screen and (min-width: 375px) and (max-width: 600px) {
  .fund-500-category-search {
    width: 90vw;
    padding-top: 60px;
  }

  .fund-500-category-annual-return-header {
    width: 80vw;
    overflow: hidden;
  }

  .fund-500-category-help-text {
    padding-top: 4px;
  }

  .fund-500-category-name-header {
    width: 50vw;
  }

  .fund-500-category-annual-return-text {
    margin-left: 10px;
    position: relative;
    top: -1px;
    border-bottom: 1px solid #808080;
    width: 80vw;
  }


}
</style>

<style lang="scss">
@import "@mds/constants";

.fund-500-category-loader {
  position: absolute;
  width: "276px";
  height: "69px";
  left: 40%;
  right: 37.92%;
  top: 61%;
}

@media only screen and (min-width: 320px) and (max-width: 600px) {
  .fund-500-category-table.mds-table__scroller .mds-table--fixed-column {
    th {
      position: sticky;
      top: 0;
    }

    th:nth-child(1) {
      z-index: $mds-z-index-low + 1;
    }
  }

  .fund-500-category-data>div {
    padding-right: 0px;
  }
}
</style>