import Home from "@/components/Common/Home/Home.vue";
import LandingPage from "@/components/Common/CombinedHome/LandingPage.vue";
import Commentary from "@/components/Common/Commentary/Commentary";
import AnalystReports from "@/components/Common/AnalystReports/AnalystReports";

import Fund500Categories from "@/components/MFI/Fund500Categories";
import FundSelector from "@/components/MFI/FundSelector";
import Glossary from "@/components/Common/Home/Glossary/Glossary.vue"

import TortoisePortfolio from "@/components/MSI/TortoisePortfolio.vue";
import HarePortfolio from "@/components/MSI/HarePortfolio.vue";
import TortoiseAndHareWatchlist from "@/components/MSI/Tortoise&HareWatchlist.vue";

// import DividendDeferPortfolio from "@/components/MDI/DividendDeferPortfolio.vue";
import DividendSelectPortfolio from "@/components/MDI/DividendSelectPortfolio.vue";
import IncomeBellwethers from "@/components/MDI/IncomeBellwethers.vue";

import ModelPortfolios from "@/components/MEI/ModelPortfolios.vue";
import EquityWatchlist from "@/components/MEI/EquityWatchlist.vue";
import BondWatchlist from "@/components/MEI/BondWatchlist.vue";
import ArchiveSection from "@/components/Common/Archive/ArchiveSection.vue";
import MdiDisclosureSection from "@/components/Common/Disclosures/MdiDisclosureSection.vue";
import MsiDisclosureSection from "@/components/Common/Disclosures/MsiDisclosureSection.vue";
import PageNotFound from "@/components/Common/ErrorPages/PageNotFound.vue";
import AccessDenied from "@/components/Common/ErrorPages/AccessDenied.vue";
import SaveArticle from "@/components/Common/Commentary/Blog/SaveArticle";
import DraftPost from "@/components/Common/Commentary/Blog/DraftPost.vue";
import MoreVideos from "@/components/Common/Home/MoreVideos.vue";
import VideoTranscript from "@/components/Common/Videos/VideoTranscript.vue";
import SearchResults from "@/components/Common/Layout/Headers/Search/SearchResults.vue";
import MarketingLanding from "@/components/Marketing/MarketingLanding.vue";
import PrintRegistrationSubscribe from "@/components/PrintRegistration/PrintRegistrationSubscribe.vue";
import PrintRegAccountInfo from "@/components/PrintRegistration/PrintRegAccountInfo.vue";
import PrintRegistrationConfirmation from "@/components/PrintRegistration/PrintRegistrationConfirmation.vue";

import Registration from "@/components/Common/Registration/RegistrationSection.vue";
import Subscription from "@/components/Common/Registration/SubscriptionSelectionSection.vue";
import SubscriptionExists from "@/components/Common/Registration/ProductSubscriptionCheck.vue";
import BillingAddress from "@/components/Common/Registration/BillingAddressSection.vue";
import AutoRenew from "@/components/Common/Registration/AutorenewSection.vue";
import ReviewSubscription from "@/components/Common/Registration/ReviewSubscriptionConfirmation.vue";
import SubscriptionConfirmation from "@/components/Common/Registration/SubscriptionConfirmation.vue";
import MyAccount from "@/components/MyAccount/MyAccount.vue";
import UserAgreement from "@/components/Common/TermsAndCondition/UserAgreement.vue";

import VueRouter from "vue-router";
import Vue from "vue";

import { authGuard } from "./auth-guard";
Vue.use(VueRouter);

const routes = [
  { path: "/mdi-disclosures", name: 'mdi-disclosure', component: MdiDisclosureSection },
  { path: "/msi-disclosures", name: 'msi-disclosure', component: MsiDisclosureSection },
  { path: "/user-agreement", name: 'user-agreement', component: UserAgreement },
  { path: "/:currentproduct/learn", name: 'marketing-landing', component: MarketingLanding },

  { path: "/videos", name: 'videos', component: MoreVideos },
  { path: "/videos/:title", name: 'videoTranscript', component: VideoTranscript },
  { path: "/register", name: 'print-registration', component: PrintRegAccountInfo },
  { path: "/register", name: 'print-registration-subscribe', component: PrintRegistrationSubscribe },
  { path: "/register-confirmation", name: 'print-registration-confirmation', component: PrintRegistrationConfirmation },

  { path: "/searchresults/:keyword", name: "searchResults", component: SearchResults },
  { path: "/:currentproduct/account", name: 'myAccount', component: MyAccount , beforeEnter: authGuard },
  //{ path: "/:currentproduct/categoryoverview/:categoryId?", name: "categoryOverview", component: CategoryOverview, beforeEnter: authGuard },
  
  { path: "/:currentproduct/commentary/:pid?", name: 'commentary', component: Commentary, beforeEnter: authGuard },
  { path: "/:currentproduct/commentary/newPost/add", name: 'newPost', component: SaveArticle, beforeEnter: authGuard },
  { path: "/:currentproduct/commentary/updatePost/:pid", name: 'updatePost', component: SaveArticle, beforeEnter: authGuard },
  { path: "/:currentproduct/commentary/articles/drafts", name: 'draftsPost', component: DraftPost, beforeEnter: authGuard },
  { path: "/:currentproduct/archive", name: 'archive', component: ArchiveSection, beforeEnter: authGuard },
  { path: "/:currentproduct/:ticker?/analysis", name: 'analyst', component: AnalystReports, beforeEnter: authGuard },
  { path: "/:currentproduct/fundinvestor500", name: "fundinvestor", component: Fund500Categories, beforeEnter: authGuard },
  { path: "/:currentproduct/fundspy", name: "fundselector", component: FundSelector, beforeEnter: authGuard },
  { path: "/:currentproduct/glossary", name: "glossary", component: Glossary, beforeEnter: authGuard },

  { path: "/:currentproduct/tortoise", name: "tortoisePort", component: TortoisePortfolio, beforeEnter: authGuard },
  { path: "/:currentproduct/hare", name: "harePort", component: HarePortfolio, beforeEnter: authGuard },
  { path: "/:currentproduct/watchlists", name: "TortoiseAndHareWatchlist", component: TortoiseAndHareWatchlist, beforeEnter: authGuard },

  // { path: "/:currentproduct/dividendselectdefer", name: "dividendDeferPort", component: DividendDeferPortfolio, beforeEnter: authGuard },
  { path: "/:currentproduct/dividendselect", name: "dividendSelectPort", component: DividendSelectPortfolio, beforeEnter: authGuard },
  { path: "/:currentproduct/bellwethers", name: "incomeBellWethers", component: IncomeBellwethers, beforeEnter: authGuard },

  { path: "/:currentproduct/model", name: "modelPort", component: ModelPortfolios, beforeEnter: authGuard },
  { path: "/:currentproduct/equity", name: "equityWatchlist", component: EquityWatchlist, beforeEnter: authGuard },
  { path: "/:currentproduct/bonds", name: "bondWatchlist", component: BondWatchlist, beforeEnter: authGuard },  

  { path: "/", name: 'combined-home', component: LandingPage },
  { path: "/logout", name: "logout", component: LandingPage },
  { path: "/accessDenied", name: "accessDenied", component: AccessDenied },
  { path: "/:currentproduct", name: 'home', component: Home, beforeEnter: authGuard },
  { path: "*", name: "pageNotFound", component: PageNotFound },

  {path:"/purchase/referid/:referid/productcode/:productcode",name:'purchase',component:Registration},
  {path:"/purchase/referid/:referid/productcode/:productcode",name:'subscription',component:Subscription},
  {path:"/purchase/referid/:referid/productcode/:productcode",name:'subscriptionexists',component:SubscriptionExists},
  {path:"/purchase/referid/:referid/productcode/:productcode",name:'billingaddress',component:BillingAddress},
  {path:"/purchase/referid/:referid/productcode/:productcode",name:'autorenew',component:AutoRenew},
  {path:"/purchase/referid/:referid/productcode/:productcode",name:'reviewsubscription',component:ReviewSubscription},
  {path:"/purchase-confirmation",name:'subscriptionconfirmation',component:SubscriptionConfirmation}
];

const router = new VueRouter({
  mode: "history",
  routes
});

export default router;