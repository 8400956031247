<template>
  <div class="user-agreement-parent-container">
    <main class="user-agreement-main">
      <div class="user-agreement-heading">
        <h1>Terms of Use</h1>
      </div>
      <div>
        <p>
          This Terms of Use is the user agreement (“User Agreement”) for the
          below-defined Service made available to you by Morningstar, Inc. 
          (“Morningstar”).  Any updated version of this User Agreement will 
          be available for review at 
          <router-link :to="{name:'user-agreement'}" tabindex="0">https://newsletters.morningstar.com/user-agreement </router-link>
          and will be accessible by clicking on the Terms of Use link at the 
          bottom of each web page.
        </p>
        <p>
          YOUR USE OF THIS PRODUCT/SERVICE AND ANY OF ITS CONTENTS MADE AVAILABLE
          TO YOU HEREUNDER, INCLUDING BUT NOT LIMITED TO, ANY TEXT, DATA, INFORMATION,
          MATERIALS, SOFTWARE, AND GRAPHICS CONTAINED OR PROVIDED THROUGH IT IN ANY
          FORM OR MEDIA AND ALL SERVICES THAT MAY BE PROVIDED IN CONJUNCTION WITH IT
          (ALL SUCH INFORMATION, MATERIALS AND SERVICES, COLLECTIVELY, THE “SERVICE”)
          IS SUBJECT TO THE TERMS AND CONDITIONS OF THIS USER AGREEMENT.
          BY SUBSCRIBING TO THE SERVICE, USING THE SERVICE IN ANY WAY, OR ACCEPTING 
          ANY RELATED PRODUCTS AND/OR SERVICES FROM MORNINGSTAR, YOU AGREE THAT YOU: 
          (1) HAVE READ AND UNDERSTAND THIS USER AGREEMENT, (2) HAVE THE CAPACITY 
          AND AUTHORITY TO ENTER INTO IT; AND (3) ARE BOUND BY ALL OF ITS TERMS AND 
          CONDITIONS.
        </p>
        <p>
          Morningstar may update and/or change the terms and conditions of this User 
          Agreement at any time. A notification of such change(s) will be displayed on
          the Morningstar Investor Newsletters home page, as well as the revised User 
          Agreement itself, will be posted at 
          <router-link :to="{name:'user-agreement'}" tabindex="0">https://newsletters.morningstar.com/user-agreement </router-link>. 
          You will be responsible for regularly checking the My Account Area and for 
          reviewing any changes to the User Agreement. By using the Service after any 
          such changes take place, you signify your acceptance of the change(s) and your 
          agreement to be bound by them.
        </p>
        <h3>Use of this Service</h3>
        <p>
          Subject to the terms of this User Agreement, Morningstar grants you a revocable,
          non-transferable, non-exclusive right to use those portions of the Service to 
          which you are granted access but only for your own personal, noncommercial use 
          and not for any use on behalf of any third party. For absence of doubt, you may 
          not use the Service to service, or otherwise perform work on behalf of, any 
          third party, including, without limitation, any brokerage, financial services 
          or related person(s) or their clients. Use of the Service is intended only for 
          citizens or legal residents of the United States and its territories.
        </p>
        <p>
          Subject to the terms of this User Agreement, you may download or print hard 
          copies of pages or entire monthly issues from the Service but only in connection 
          with your own personal, noncommercial use and not on behalf of any third party.
          You will not delete or obscure any notices/disclaimers appearing in those downloads and will affix the notice, 
          “(c) {{currentYear}} Morningstar. All rights reserved.” to any downloaded materials not already containing a similar notice. Except as 
          expressly set forth in this User Agreement, you may not modify, copy, distribute, 
          disclose, retransmit, sell, publish, broadcast, or circulate the Service, or any 
          portion of it (including, but not limited to, any download or printed copies made from it) 
          without Morningstar’s prior written consent.
        </p>
        <p>
          Morningstar has the right, at any time without notice, to: (i) revoke or limit 
          your license to use the Service (or any portion of it, including any Product, 
          as defined below, delivered with or through the Service); and (ii) change or 
          discontinue providing the Service, any Product or any portion of any of the 
          foregoing. Morningstar has no obligation to update the Service or to correct 
          any errors or omissions that might be contained in it.
        </p>
        <p>
          Your subscription is personal to you, and you may not transfer or share your 
          subscription with another person, other than immediate family members living 
          in the same household, provided that: (i) each such family member reads and 
          agrees to be bound by the terms and conditions of this User Agreement; (ii) 
          your subscription is not simultaneously used by more than one person at any 
          given time; and (iii) you agree to be responsible for any use of the Service by 
          such family member(s).
        </p>
        <h3>Ownership and Copyright</h3>
        <p>
          You acknowledge and agree that the Service (including, without limitation, all 
          information contained in or associated with it) is owned by Morningstar and/or 
          its content providers, whether affiliates of Morningstar or third parties 
          (collectively, “Content Providers”) and is protected by international copyright 
          and other intellectual property laws. You further acknowledge and agree that the 
          Service contains certain proprietary data and information of Morningstar and its 
          Content Providers, that you will not use these data or information for any unlawful 
          or unauthorized purpose, and that you will use reasonable efforts to protect them 
          from illicit distribution or use by others. Each Content Provider is a third-party 
          beneficiary of this Agreement to the extent necessary to enable it to enforce its 
          proprietary rights in any of its content and in any use restrictions related to 
          that content that are contained in this Agreement.
        </p>
        <h3>Trademarks</h3>
        <p>
          MORNINGSTAR, MORNINGSTAR.COM, and the MORNINGSTAR & DESIGN logo are registered marks 
          of Morningstar in the U.S. and certain other jurisdictions. In addition, the Morningstar 
          Rating™ and Morningstar Style Box™ marks (both word and design), as well as any other 
          Morningstar marks appearing in the Service, are the property of Morningstar. You will 
          not use or allow any third party to use any of Morningstar’s marks, including but not 
          limited to the foregoing, without Morningstar’s prior express written consent. All marks 
          not owned by Morningstar are the property of their respective owners.
        </p>
        <h3>Disclaimer of Warranties</h3>
        <p>
          THE SERVICE IS PROVIDED “AS IS” WITH ALL FAULTS. MORNINGSTAR AND ITS CONTENT PROVIDERS 
          DISCLAIM ALL WARRANTIES OF ANY KIND REGARDING THE SERVICE, EITHER EXPRESS OR IMPLIED, 
          INCLUDING BUT NOT LIMITED TO, ANY IMPLIED WARRANTY OF MERCHANTABILITY, FITNESS FOR A 
          PARTICULAR PURPOSE, ANY WARRANTY AROUND OWNERSHIP, NONINFRINGEMENT, ACCURACY OF 
          INFORMATIONAL CONTENT, ABSENCE OF VIRUSES AND DAMAGING OR DISABLING CODE OR CONTINUOUS 
          AVAILABILITY. NEITHER MORNINGSTAR NOR ANY OF ITS CONTENT PROVIDERS WARRANT THE ACCURACY, 
          COMPLETENESS, OR TIMELINESS OF THE SERVICE OR ANY INFORMATION PROVIDED IN CONJUNCTION WITH IT. 
          NEITHER MORNINGSTAR NOR ANY OF ITS CONTENT PROVIDERS SHALL BE RESPONSIBLE FOR INVESTMENT 
          DECISIONS, DAMAGES, OR OTHER LOSSES RESULTING FROM ACCESS TO OR USE OF THE SERVICE.
        </p>
        <p>
          PAST PERFORMANCE DOES NOT GUARANTEE FUTURE PERFORMANCE. NEITHER MORNINGSTAR NOR ANY OF ITS 
          CONTENT PROVIDERS SHALL BE CONSIDERED AN “EXPERT” UNDER THE SECURITIES ACT OF 1933. NEITHER 
          MORNINGSTAR NOR ANY OF ITS CONTENT PROVIDERS WARRANT THAT THE SERVICE OR ANY PORTION OF IT 
          COMPLIES WITH THE LAWS OF ANY JURISDICTION OR REQUIREMENTS OF THE NASD OR ANY SIMILAR ORGANIZATION.  
          ADDITIONALLY, YOU ARE SOLELY RESPONSIBLE FOR ENSURING THAT YOUR ACCESS AND/OR USE OF THE SERVICE 
          COMPLIES WITH ANY LAWS, REGULATIONS AND REQUIREMENTS THAT MAY APPLY TO YOU.
        </p>
        <p>
          Some jurisdictions do not allow the exclusion or limitation of implied warranties, 
          so the above exclusions or limitations may not apply in all cases.
        </p>
        <h3>Limitation of Liability</h3>
        <p>
          NEITHER MORNINGSTAR NOR ANY OF ITS CONTENT PROVIDERS NOR ANY PERSON THROUGH WHOM MORNINGSTAR 
          MAKES THE SERVICE AVAILABLE SHALL BE LIABLE FOR ANY DAMAGES OF ANY KIND ARISING FROM OR 
          RELATING TO THIS USER AGREEMENT, THE SERVICE, OR THE USE OF, OR RELIANCE ON, THE SERVICE, 
          WHETHER IN CONTRACT OR TORT OR OTHERWISE, EXCEPT TO THE EXTENT SUCH DAMAGES ARISE DIRECTLY FROM 
          A WILLFUL BREACH BY MORNINGSTAR (OR THAT PERSON) OF ANY OF ITS EXPRESS OBLIGATIONS UNDER THIS 
          USER AGREEMENT OR EXCEPT TO THE EXTENT OTHERWISE EXPLICITLY MANDATED BY APPLICABLE LAW.
        </p>
        <p>
          EXCEPT TO THE EXTENT OTHERWISE EXPLICITLY PROHIBITED BY APPLICABLE LAW, THE AGGREGATE 
          LIABILITY OF MORNINGSTAR AND ITS CONTENT PROVIDERS ARISING FROM OR RELATING TO THIS USER 
          AGREEMENT, THE SERVICE, AND THE USE OF, OR RELIANCE ON, THE SERVICE, WHETHER IN CONTRACT, 
          TORT OR OTHERWISE, SHALL BE LIMITED TO ACTUAL DIRECT DAMAGES THAT CAN BE FINALLY PROVED 
          IN A COURT OF COMPETENT JURISDICTION IN AN AMOUNT NOT TO EXCEED $1,000.
        </p>
        <p>
          EXCEPT TO THE EXTENT OTHERWISE EXPLICITLY PROHIBITED BY APPLICABLE LAW, NEITHER MORNINGSTAR 
          NOR ITS CONTENT PROVIDERS SHALL BE LIABLE FOR ANY PUNITIVE, SPECIAL, INDIRECT, CONSEQUENTIAL OR SIMILAR 
          DAMAGES ARISING FROM OR RELATING TO THE FOREGOING, WHETHER IN CONTRACT OR TORT OR OTHERWISE, 
          EVEN IF MORNINGSTAR OR ITS CONTENT PROVIDER HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
        </p>
        <h3>Indemnification</h3>
        <p>
          Except to the extent expressly prohibited by applicable law, you agree to indemnify, 
          defend, and hold harmless Morningstar and its Content Providers from and against any 
          and all claims, liabilities, losses, costs (including, but not limited to, reasonable 
          attorneys’ fees), and/or damages of any kind arising from or relating to: (i) your 
          use of the Service; (ii) your breach of this User Agreement; and (iii) any messages, 
          information, or materials uploaded, posted, published, or transmitted by you in 
          connection with the Service (including, without limitation, in any portion of the 
          Morningstar Community (as defined below) that may be made available as part of the Service).
        </p>
        <h3>User Password</h3>
        <p>
          If you are required to select a password to access or use the Service, you may then modify 
          that password at any time as often as you like. You may not select any password that violates 
          any third party’s rights or is offensive, improper, or inappropriate. If Morningstar determines 
          in its sole discretion that any password you select is inappropriate for any reason, Morningstar 
          may delete that password and/or limit or terminate your access to the Service.
        </p>
        <p>
          Your password is for your personal use only, and you agree to keep it secret 
          and not to share it with anyone (except as expressly allowed under this User Agreement).
        </p>
        <p>
          YOU ARE RESPONSIBLE FOR ALL USE, ACTIVITIES, AND CHARGES ASSOCIATED WITH OR ARISING 
          FROM ANY USE OF YOUR PASSWORD, REGARDLESS OF WHETHER YOU AUTHORIZED SUCH USE. YOU MUST 
          PROMPTLY NOTIFY MORNINGSTAR (USING THE TOLL-FREE NUMBER OR E-MAIL ADDRESS LISTED IN THE 
          CUSTOMER SUPPORT SECTION OF “MY ACCOUNT” OR BY CLICKING ON THE “CONTACT US” LINK LOCATED 
          IN THE WEBSITE FOOTER) OF ANY UNAUTHORIZED USE OF YOUR PASSWORD.
        </p>
        <h3>Third-Party Web Sites, Links, and Information</h3>
        <p>
          The Service may contain information, products, and services provided by third parties and 
          links (including advertisements) to Web sites made available by third parties. This information 
          and these products, services, and links are provided only as a convenience to users of this Service. 
          Morningstar does not control this information or these products, services, or Web sites.
          Morningstar does not make any representations or warranties, express or implied, regarding 
          this information or these products, services, or Web sites and shall not be liable for any 
          information or products/services received from them. Inclusion of any of the foregoing in conjunction 
          with the Service does not constitute or imply an endorsement, authorization, sponsorship, or 
          affiliation by or with Morningstar with respect to any third party, any third party’s Web site or 
          its content, or any information, products, or services provided by a third party.
        </p>
        <h3>Stock Quotes</h3>
        <p>
          Where available within the Service, U.S. intraday exchange quotes are real-time 
          and provided by BATS Exchange. NASDAQ, NYSE and Amex securities quotes are delayed 
          by 5 minutes during market hours, with end-of-day quotes appearing 15 minutes after 
          market close. All quotes are believed to be correct, but Morningstar does not warrant 
          or guarantee the accuracy, completeness or timeliness of any such information. Morningstar 
          advises you to always confirm the price of a particular investment with a broker or other 
          party executing its sale, purchase or redemption, as appropriate, prior to trading on it.
        </p>
        <h3>Privacy and Information Made Available by You</h3>
        <p>
          Subject to the terms of Morningstar’s Privacy Statement, Morningstar shall have the 
          right to use and disclose any information provided by you through the Service 
          (including but not limited to information provided through e-mail) or made available 
          through your use of the Service, for any lawful purpose. In no event shall you be 
          entitled to any compensation for the use of any of this information. Please see our 
          Privacy Statement for further details.
        </p>
        <h3>Use of Morningstar Community</h3>
        <p>
          To the extent the Service involves any access to any forum, blog, private message, email or other medium 
          (e.g., a comments section within a video or associated with an article) by which discussions may be effected 
          (individually and collectively, the “Morningstar Community”), you understand and agree that Morningstar makes 
          no representations or warranties with respect to any such portion of the Morningstar Community or with respect 
          to any messages, information, or materials contained in it. You acknowledge and agree that your use of, or 
          reliance upon, any such messages, information, or materials is at your sole risk and expense.
        </p>
        <p>
          Morningstar does not, and cannot, review all of the information and materials provided in the Morningstar 
          Community and has no responsibility or liability for any such information or materials or their use. 
          If Morningstar becomes aware of any information or materials that it determines violate the terms of 
          this User Agreement or the Morningstar Community policies promulgated by Morningstar from time to time 
          or that Morningstar otherwise deems inappropriate in its sole discretion, Morningstar reserves the right 
          to delete, move, or edit any such information or materials.
        </p>
        <p>
          To the extent that you use the Morningstar Community, you covenant that you will: (a) comply with the 
          Morningstar Community policies promulgated by Morningstar from time to time; (b) be solely responsible 
          for your use of the Morningstar Community and any messages, information, and materials provided by you 
          or through another user making use of your password; (c) not advertise or offer to sell goods or services 
          of any kind in the Morningstar Community; and (d) not upload, post, publish, or transmit any messages, 
          information, or materials that (i) restrict or inhibit any other user from accessing, using or enjoying 
          the Service, (ii) are unlawful, threatening, abusive, libelous, defamatory, obscene, vulgar, offensive, 
          pornographic, profane, sexually explicit, or indecent, (iii) constitute or encourage conduct that would 
          constitute a criminal offense, give rise to civil liability, or otherwise violate any applicable law, (iv) 
          violate or infringe the rights of Morningstar or any third party (including but not limited to copyrights, 
          trademarks, patents, trade secrets, and rights of privacy and publicity), (v) constitute or contain false 
          or misleading indications of origin or statements of fact, (vi) contain software or any other commercial 
          materials or information, or (vii) contain any viruses or other harmful component.
        </p>
        <p>
          By uploading, posting, publishing, or transmitting any messages, information, or materials in connection 
          with the Morningstar Community, you hereby grant to Morningstar and its affiliates a royalty-free, worldwide, 
          perpetual, transferable, irrevocable, nonexclusive license (with the right to sublicense) to use, copy, modify, 
          display, publicly perform, distribute, reproduce, and create derivative works of the foregoing in any form, 
          media, software, or technology of any kind now existing or developed in the future.
        </p>
        <h3>Governing Law and Jurisdiction</h3>
        <p>
          This User Agreement is governed by and construed in accordance with the laws of the State of 
          Illinois, U.S.A., without giving effect to any choice-of-law or conflict-of-law provision or 
          rule (whether of the State of Illinois or any other jurisdiction) that would cause the 
          application of the laws of any jurisdiction other than those of the State of Illinois. 
          You hereby consent to, and agree to bring any and all claims in, the exclusive jurisdiction of 
          the state and federal courts located in Cook County, Illinois, U.S.A., for any action or proceeding 
          arising under or relating to this User Agreement, the Service, or the use of, or reliance on this Service, 
          whether based in contract or tort or otherwise. Each party expressly waives the right to trial by jury.
        </p>
        <h3>Waiver of Class Action</h3>
        <p>
          Except as otherwise specifically prohibited by applicable law, all disputes arising from or related 
          to this User Agreement, a party’s performance hereunder or any of its acts or omissions 
          (or any of the acts or omissions of any of its agents) will be adjudicated on an individual basis 
          and not in a class or representative action or as a member of a class, mass, consolidated or representative 
          action, irrespective of the forum in which they are heard. Any claim asserted by a party hereunder shall not 
          be joined for any purpose with the claim or claims of any other person or entity, unless both parties 
          specifically agree to the joinder of individual actions.
        </p>
        <h3>Entire Agreement</h3>
        <p>
          This User Agreement constitutes the entire agreement between you and Morningstar regarding the subject 
          matter hereof and supersedes any and all prior negotiations, representations, warranties, undertakings, 
          or agreements, written or oral, between the parties regarding such subject matter.
        </p>
        <h3>Severability</h3>
        <p>
          To the maximum extent possible, each provision in this User Agreement shall be interpreted in such 
          a manner as to be valid and enforceable under applicable law. If any provision of this User Agreement 
          is held to be invalid or unenforceable under applicable law, such provision shall be modified so as to 
          be rendered valid and enforceable while implementing, to the maximum extent possible, the original intent 
          of such provision. If such modification is not possible or allowed, then such provision shall be 
          ineffective only to the extent of such unenforceability or invalidity without affecting the remainder of such 
          provision or any other provisions in this User Agreement.
        </p>
        <h3>Subscribing to the Service</h3>
        <p>
          You may not use the Service without a valid subscription from Morningstar — at which point you will 
          be deemed a Subscriber.  To obtain a subscription, you must register for the Service either by completing 
          and submitting the online registration form available for the Service or by calling the toll-free number 
          listed below under “Customer Service and Product Support” and providing Morningstar with all of the required 
          enrollment information. Your subscription will not be valid unless and until Morningstar accepts your completed 
          registration. By registering for the Service, you represent and warrant that all registration information that 
          you provide is true, complete, and accurate, and you agree to notify Morningstar promptly of any changes to 
          this registration information. This notification may be effected either by editing your user profile in the 
          Service or by calling the toll-free number listed below under “Customer Service and Product Support.”
        </p>
        <h3>AUTOMATIC RENEWAL OF SERVICE</h3>
        <p>
          <strong>
            YOU MAY BE OFFERED A TRIAL SUBSCRIPTION TO THE SERVICE AND THIS TRIAL SUBSCRIPTION WILL NORMALLY 
            BEGIN ON THE DATE ON WHICH MORNINGSTAR ACCEPTS YOUR COMPLETED REGISTRATION. UNLESS CANCELLED BY 
            YOU OR MORNINGSTAR DURING THIS TRIAL PERIOD, THE INITIAL TERM OF YOUR REGULAR PAID SERVICE SUBSCRIPTION 
            WILL START IMMEDIATELY AFTER THE TRIAL SUBSCRIPTION PERIOD ENDS, AND WILL CONTINUE FOR THE LENGTH OF 
            TIME THAT YOU SELECTED WHEN YOU REGISTERED. IF YOU HAVE EVER TAKEN A TRIAL TO THE SERVICE, YOU WILL NOT 
            BE ELIGIBLE TO RECEIVE ANOTHER TRIAL NOW. INSTEAD, THE INITIAL TERM OF YOUR REGULAR PAID SERVICE SUBSCRIPTION 
            WILL BEGIN IMMEDIATELY UPON MORNINGSTAR ACCEPTING YOUR REGISTRATION AND YOUR CREDIT CARD WILL BE CHARGED FOR 
            THE SUBSCRIPTION FEE ASSOCIATED WITH THE TERM LENGTH YOU SELECTED WHEN YOU REGISTERED.
          </strong>
        </p>
        <p>
          <strong>
            IN THOSE INSTANCES WHERE YOU SUBSCRIBE TO THE SERVICE USING A SPECIAL OR INTRODUCTORY OFFER, 
            UNLESS YOU NOTIFY US THAT YOU WISH TO CANCEL YOUR SERVICE SUBSCRIPTION BEFORE THE END OF ITS 
            INITIAL TERM, YOUR SERVICE SUBSCRIPTION WILL AUTOMATICALLY RENEW FOR THE PERIOD OF TIME SPECIFIED 
            IN THAT OFFER AND YOUR CREDIT CARD WILL BE CHARGED THE SUBSCRIPTION FEE IN EFFECT AT THE TIME FOR 
            THAT SUBSCRIPTION TERM LENGTH.
          </strong>
        </p>
        <p>
          <strong>
            OTHER THAN AS DESCRIBED ELSEWHERE IN THIS SECTION, UNLESS YOU NOTIFY US THAT YOU WISH TO 
            CANCEL YOUR SERVICE SUBSCRIPTION BEFORE THE END OF ITS THEN-CURRENT SUBSCRIPTION TERM, YOUR 
            SERVICE SUBSCRIPTION WILL CONTINUE TO AUTOMATICALLY RENEW FOR SUCCESSIVE TERMS OF THE SAME 
            DURATION AND YOUR CREDIT CARD WILL BE CHARGED FOR EACH SUCH RENEWAL AT THE SUBSCRIPTION FEE 
            IN EFFECT AT THE TIME OF THAT RENEWAL.
          </strong>
        </p>
        <h3>Changes to the Service</h3>
        <p>
          MORNINGSTAR RESERVES THE RIGHT TO CHANGE THE SERVICE AT ANY TIME WITHOUT NOTICE 
          (INCLUDING BUT NOT LIMITED TO ADDING OR REMOVING FEATURES). IF YOU ARE DISSATISFIED 
          WITH ANY SUCH CHANGES, OR IF YOU ARE OTHERWISE DISSATISFIED WITH THE SERVICE 
          (INCLUDING BUT NOT LIMITED TO ITS PERFORMANCE OR AVAILABILITY), YOUR ONLY REMEDY IS TO CANCEL 
          YOUR SUBSCRIPTION, AS SET FORTH BELOW.
        </p>
        <h3>Charges and Billing Practices</h3>
        <p>
          Service Subscription Fees on credit-card transactions are authorized immediately, but not actually 
          processed until any applicable limited time trial period has expired. Subscription fees will be 
          based upon the subscription rates in effect at the start of each subscription term and apply whether 
          or not you actually use the Service during that term. You can always find the current retail subscription 
          rates by visiting the Service home page at 
          <router-link :to="{name:'combined-home'}" tabindex="0">newsletters.morningstar.com.</router-link>
        </p>
        <p>
          Morningstar reserves the right to change the fees it charges for the Service and/or the products, 
          services or features (collectively, “Products”) offered in connection with the Service at any time. 
          These sorts of changes include, but are not limited to, adding fees for new/optional Products and/or 
          modifying fees for existing Products. By using any of the Products, with which there are associated 
          fees, you agree to pay those fees. If you do not want to pay these associated fees, you must cancel your 
          subscription, to those Products as set forth below. For absence of doubt, if Morningstar increases the base 
          Service subscription fee at some point during your then-current subscription term, that increased fee will 
          only apply to you upon the next renewal, if any, of your Service subscription.
        </p>
        <p>
          Subscription fees and all other fees and charges associated with your Service subscription 
          (including, but not limited to, any applicable federal, state, and local taxes) will be billed 
          automatically to the credit card you provide when registering for the Service (“your credit card”). 
          If your credit card is a VISA or MasterCard and the number or expiration date for your credit card 
          changes for any reason (for instance, you get a new number because your old card expired, was stolen, etc.), 
          that updated information may be automatically passed on from your credit card issuer to Morningstar, and if 
          we do receive such information, it will be treated by Morningstar as if it were originally furnished by you. 
          To learn more about this pass-along service being provided by your credit card issuer, please contact it directly.
        </p>
        <p>
          While you are a Subscriber, you must promptly notify Morningstar — if any information you provided to us 
          related to your credit card — for instance, your billing address — changes or is no longer valid for any 
          reason. Absent such notification, Morningstar will assume that all of the information related to your credit 
          card remains valid and will submit to the card processor all information that it requires for approval, both 
          when you initially subscribe and, if applicable, upon the commencement of any renewal of that subscription. 
          If any subscription fees or other charges billed to your credit card are declined for any reason, Morningstar 
          shall have the right to suspend your subscription until such subscription fees or other charges are paid in full. 
          You agree to pay all costs (including attorneys’ fees) incurred by Morningstar in collecting any unpaid subscription 
          fees or other charges from you. To notify Morningstar, please either reach out to us at the toll-free number set 
          forth below in Customer Service and Product Support or email us at 
          <a href="mailto:newslettersupport@morningstar.com" tabindex="0">newslettersupport@morningstar.com.</a>
        </p>
        <h3>Cancellation</h3>
        <p>
          If you purchased your Service subscription through Morningstar, you may cancel it (i) 
          by calling the telephone number listed under “Customer Support” in the My Account area or 
          Contact Us located at the bottom of every page to notify Morningstar; or (ii) by sending an 
          email to <a href="mailto:newslettersupport@morningstar.com." tabindex="0">newslettersupport@morningstar.com.</a>
        </p>
        <p>
          If you cancel your subscription during your trial period, you will not be responsible for the payment of the subscription fee. However, except as otherwise provided herein, if you cancel your Service subscription at any time after your trial period expires, your subscription to the Service will continue in effect until the next monthly anniversary of the effective date of your paid subscription. (For example, if your paid Service subscription start date is on January 1 and you cancel it on June 15, your Service subscription remains in effect until July 1, at which time it ends.) For those with yearly or multi-year subscriptions, any remaining prepaid charges will be refunded, prorated on a monthly basis, to your credit card after your Service subscription ends.
        </p>
        <p>
          Morningstar may at any time immediately cancel your subscription, without notice or liability, if it determines 
          in its sole discretion that: (i) you have breached any portion of this User Agreement; or (ii) your use of or access 
          to any portion of the Service  violates any applicable law or regulation, (iii) your use inhibits Morningstar from 
          providing or any other subscriber from using or accessing, any portion of the Service or (iv) your use infringes a 
          third party’s intellectual property, privacy or other related rights. Upon such cancellation, you will have no 
          further right to access or use any portion of the Service. None of the foregoing limits any other legal, equitable, 
          or contractual rights available to Morningstar.
        </p>
        <p>
          Morningstar may, in its sole discretion, choose not to renew your subscription by sending a notice of 
          non-renewal at any time during your subscription term to the e-mail address you provided when completing 
          your registration. You will be deemed to have received this notice, whether or not you saw it or the e-mail 
          address provided by you continues to be valid. In the event of such a notice, your subscription will cease 
          and will not automatically renew at the end of your then-current subscription term.
        </p>
        <h3>Customer Service and Product Support</h3>
        <p>
          For customer service related to your Service subscription (including information regarding registration, billing, cancellation and address changes), 
          please call +1 (866) 608-9570 Monday-Friday, 8AM-5PM CST.
        </p>
        <p>
          For technical and product support related to your Service subscription, please email us at 
          <a href="mailto:newslettersupport@morningstar.com" tabindex="0">newslettersupport@morningstar.com</a> or call us [toll-free] at +1 (312) 424-4288 Monday-Friday: 8AM-6PM CST.
        </p>
        <p>
          This User Agreement was last updated in June 2023.
        </p>
      </div>
    </main>
  </div>
</template>

<script lang="js">

  export default  {
    name: 'user-agreement',
    data () {
      return {
        currentYear: new Date().getFullYear(),
      }
    },
}


</script>

<style scoped>
  .user-agreement-parent-container {
    max-width:1280px;
    margin:0 auto;
  }
  .user-agreement-main{
    max-width:565px;
    margin:32px auto;
  }
  .user-agreement-heading{
    margin-bottom:32px;
  }
  .user-agreement-main p {
    margin-top:4px;
    margin-bottom:24px;
    font-size:16px;
    font-style:normal;
    font-weight:300;
    line-height: 1.375;
    color:#1E1E1E;
  }
  .user-agreement-main a {
    text-decoration:none;
    color:inherit;
    border-bottom:1px solid #ababab;
    transition: color .2s linear,border-bottom .2s linear;
  }
  .user-agreement-main a:hover{
    color:#0077cf;
    border-bottom:1px solid #0077cf;
    transition: color .2s linear,border-bottom .2s linear;
  }
  .user-agreement-main h3{
    font-weight: 600;
    font-size: 18px;
    line-height: 1.2;
    font-style: normal;
    color:#1E1E1E;
  }
  @media only screen and (max-width:767px){
    .user-agreement-parent-container{
      width:90%;
    }
  }
</style>
