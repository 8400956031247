<template>
    <div>
    <div v-if="isMobileHeaderToggle"></div>
    <div v-else>
    <div class="nav-menu" v-if="this.CurrentProduct == 'mdi' || this.CurrentProduct == 'msi' || this.CurrentProduct == 'mfi' || this.CurrentProduct == 'mei'">
        <mds-tabs variation="site-navigation" alignment="center" size="large">
            <mds-tabs-item>
                <router-link class="routerLink" :to="{ name: 'home', params: { currentproduct: this.CurrentProduct }}">Home</router-link>
            </mds-tabs-item>
            <mds-tabs-item>
                <router-link class="routerLink" id="commentary-tab" :to="{ name: 'commentary', params: { currentproduct: this.CurrentProduct }}">Commentary</router-link>
            </mds-tabs-item>
            <mds-tabs-item v-if="CurrentProduct == 'mdi'">
                <router-link class="routerLink" :to="{ name: 'dividendSelectPort', params: { currentproduct: this.CurrentProduct }}">
                    Dividend Select Portfolio
                </router-link>
            </mds-tabs-item>
            <mds-tabs-item v-if="CurrentProduct == 'mdi'">
                <router-link class="routerLink" :to="{ name: 'dividendDeferPort', params: { currentproduct: this.CurrentProduct }}">
                    Dividend Select Deferred Portfolio
                </router-link>
            </mds-tabs-item>
            <mds-tabs-item v-if="CurrentProduct == 'mdi'">
                <router-link class="routerLink" :to="{ name: 'incomeBellWethers', params: { currentproduct: this.CurrentProduct }}">
                    Income Bellwethers
                </router-link>
            </mds-tabs-item>
            <mds-tabs-item v-if="CurrentProduct == 'mei'">
                <router-link class="routerLink" :to="{ name: 'modelPort', params: { currentproduct: this.CurrentProduct }}">
                    Model Portfolios
                </router-link>
            </mds-tabs-item>
            <mds-tabs-item v-if="CurrentProduct == 'mei'">
                <router-link class="routerLink" :to="{ name: 'equityWatchlist', params: { currentproduct: this.CurrentProduct }}">
                    Equity Watchlist
                </router-link>
            </mds-tabs-item>
            <mds-tabs-item v-if="CurrentProduct == 'mei'">
                <router-link class="routerLink" :to="{ name: 'bondWatchlist', params: { currentproduct: this.CurrentProduct }}"> Bond Watchlist </router-link>
            </mds-tabs-item>
            <mds-tabs-item v-if="CurrentProduct == 'mfi'">
                <router-link class="routerLink" :to="{ name: 'fundinvestor', params: { currentproduct: this.CurrentProduct }}">
                    FundInvestor 500
                </router-link>
            </mds-tabs-item>
            <mds-tabs-item v-if="CurrentProduct == 'mfi'">
                <router-link class="routerLink" :to="{ name: 'fundselector', params: { currentproduct: this.CurrentProduct }}">
                    FundInvestor Selector
                </router-link>
            </mds-tabs-item>
            <mds-tabs-item v-if="CurrentProduct == 'msi'">
                <router-link class="routerLink" :to="{ name: 'tortoisePort', params: { currentproduct: this.CurrentProduct }}">
                    Tortoise Portfolio
                </router-link>
            </mds-tabs-item>
            <mds-tabs-item v-if="CurrentProduct == 'msi'">
                <router-link class="routerLink" :to="{ name: 'harePort', params: { currentproduct: this.CurrentProduct }}">
                    Hare Portfolio
                </router-link>
            </mds-tabs-item>
            <mds-tabs-item v-if="CurrentProduct == 'msi'">
                <router-link class="routerLink" :to="{ name: 'TortoiseAndHareWatchlist', params: { currentproduct: this.CurrentProduct }}"> Tortoise & Hare Watchlist </router-link>
            </mds-tabs-item>
            <mds-tabs-item v-if="CurrentProduct != 'mfi'">
                <router-link class="routerLink" id="analyst-tab"  :to="{ name: 'analyst', params: { currentproduct: this.CurrentProduct }}"> Analyst Reports </router-link>
            </mds-tabs-item>
            <mds-tabs-item>
                <router-link class="routerLink" :to="{ name: 'archive', params: { currentproduct: this.CurrentProduct }}"> Archive </router-link>
            </mds-tabs-item>
        </mds-tabs>
    </div>
    <h1 v-if="this.CurrentProduct == 'mdi' || this.CurrentProduct == 'msi' || this.CurrentProduct == 'mfi' || this.CurrentProduct == 'mei'" class="navigation-bottom-border"></h1>
    </div>
    </div>
    </template>
    
    <script>
    import { mapState } from "vuex";
    import {
        MdsTabs,
        MdsTabsItem
    } from "@mds/tabs";
    
    export default {
        name: "product-navigation",
        components: {
            MdsTabs,
            MdsTabsItem,
        },
        data() {
            return {
                CurrentProduct: "",
                isMobileHeaderToggle: null,
          viewPortWidth: null,
            }
        },
        mounted() {
            this.isMobileHeaderToggle=this.$store.state.responsive.isMobileHeader;
            if (this.$session.exists()) {
                this.CurrentProduct = this.$session.get('current-product') != undefined && this.$session.get('current-product') != "" && this.$session.get('current-product') != null  ? this.$session.get('current-product').toLowerCase() : "Common";
    
            }
            if(window.location.pathname.includes('commentary')){
                this.activeCommentaryTab();
            }
            if(window.location.pathname.includes('analysis'))
            {
                this.activeAnalystReportTab();
            }

    },
    methods: {
        activeCommentaryTab(){
            setTimeout(() => {
                var commentaryElement = document.getElementById("commentary-tab");
                    if(commentaryElement != null || commentaryElement != undefined){
                        commentaryElement.classList.add("commentary-tab-active");
                    }else{
                        commentaryElement.classList.remove("commentary-tab-active");
                    }
            }, 50);
        },
        activeAnalystReportTab(to){
            setTimeout(() => {
                var analysisElement = document.getElementById("analyst-tab"); 
                if(to == "analyst")
                {
                  if(analysisElement != null || analysisElement != undefined){
                        analysisElement.classList.add("commentary-tab-active");
                    }
                }
                else{
                     analysisElement.classList.remove("commentary-tab-active");
                    }
            }, 50);
        }
    },
    
    computed:mapState({
    isMobileHeader: state => state.responsive.isMobileHeader,
      isMobileHeaderAlias:'isMobileHeader',
      isMobileHeaderState(state){
      return state.responsive.isMobileHeader
    }

  }),

  watch:{
    isMobileHeaderState(){
      this.isMobileHeaderToggle = this.isMobileHeaderState;
    },
    $route(to){
        if(to.name == "commentary"){
            this.activeCommentaryTab();
        }
        else
        {
        this.activeAnalystReportTab(to.name);
        }
    }
  }
    };
    </script>
    
    <style scoped>
    .nav-menu {
    position: relative;
        width: 1280px;
        margin: auto;
        padding: auto;
        margin-top: 110px;
    }

    .navigation-bottom-border{
        margin-top: -0.8px;
        border-top: #E5E5E5 solid 1.3px;
    }
    
    .routerLink {
        text-decoration: none;
        font-family: "Univers", HelveticaNeue, "Helvetica Neue", Helvetica, Arial,
            sans-serif;
        font-style: normal;
        font-weight: 300;
        font-size: 18px;
        line-height: 22px;
        color: #000000;
        border-bottom: none;
    }
    
    .router-link-exact-active {
        margin-top: 10px;
        box-shadow: inset 0 -4px 0 0 red;
        position: relative;
        padding-bottom: 14px;
    }

    .commentary-tab-active{
        margin-top: 10px;
        box-shadow: inset 0 -4px 0 0 red;
        position: relative;
        padding-bottom: 14px; 
    }
    
    @media only screen and (min-width: 480px) and (max-width: 580px) {

        .routerLink {
        text-decoration: none;
        font-family: "Univers", HelveticaNeue, "Helvetica Neue", Helvetica, Arial,
            sans-serif;
        font-style: normal;
        font-weight: 300;
        font-size: 16px;
        line-height: 16px;
        color: #000000;
        border-bottom: none;
    }
    
    }
    

    @media only screen and (min-width: 580px) and (max-width: 596px) {
     
        .routerLink {
        text-decoration: none;
        font-family: "Univers", HelveticaNeue, "Helvetica Neue", Helvetica, Arial,
            sans-serif;
        font-style: normal;
        font-weight: 300;
        font-size: 12px;
        line-height: 16px;
        color: #000000;
        border-bottom: none;
    }
    
    }

    
    @media only screen and (min-width: 596px) and (max-width: 600px) {


        .routerLink {
        text-decoration: none;
        font-family: "Univers", HelveticaNeue, "Helvetica Neue", Helvetica, Arial,
            sans-serif;
        font-style: normal;
        font-weight: 300;
        font-size: 12px;
        line-height: 16px;
        color: #000000;
        border-bottom: none;
    }
    
    }

    @media only screen and (min-width: 600px) and (max-width: 630px) {
        .routerLink {
        text-decoration: none;
        font-family: "Univers", HelveticaNeue, "Helvetica Neue", Helvetica, Arial,
            sans-serif;
        font-style: normal;
        font-weight: 300;
        font-size: 9px;
        line-height: 16px;
        color: #000000;
        border-bottom: none;
    }

    .router-link-exact-active {
        margin-top: 10px;
        box-shadow: inset 0 -4px 0 0 red;
        position: relative;
        padding-bottom: 16px;
    }
    }

    @media only screen and (min-width: 0px) and (max-width: 1000px) {
        .nav-menu {
      display: none !important;
    }
    .navigation-bottom-border{
        display: none;
    }
    }

    @media only screen and (min-width: 1000px) and (max-width: 1200px) {
        .nav-menu {
        position: relative;
        width: 99%;
        top: -15px;
    }

    .navigation-bottom-border{
        display: none;
    }
    }

    @media only screen and (min-width: 1200px) and (max-width: 1280px) {
        .nav-menu {
       
        width: 99%;
        
    }

    .navigation-bottom-border{
        display: none;
    }
    }

    @media only screen and (min-width: 630px) and (max-width: 662px) {
      

        .routerLink {
        text-decoration: none;
        font-family: "Univers", HelveticaNeue, "Helvetica Neue", Helvetica, Arial,
            sans-serif;
        font-style: normal;
        font-weight: 300;
        font-size: 10px;
        line-height: 16px;
        color: #000000;
        border-bottom: none;
    }
    .router-link-exact-active {
        margin-top: 10px;
        box-shadow: inset 0 -4px 0 0 red;
        position: relative;
        padding-bottom: 16px;
    }
    
    }
    
    @media only screen and (min-width: 662px) and (max-width: 721px) {

        .routerLink {
        text-decoration: none;
        font-family: "Univers", HelveticaNeue, "Helvetica Neue", Helvetica, Arial,
            sans-serif;
        font-style: normal;
        font-weight: 300;
        font-size: 11px;
        line-height: 16px;
        color: #000000;
        border-bottom: none;
    }

    .router-link-exact-active {
        margin-top: 10px;
        box-shadow: inset 0 -4px 0 0 red;
        position: relative;
        padding-bottom: 16px;
    }
    
    }
     
    @media only screen and (min-width: 721px) and (max-width: 840px) {
        .routerLink {
        text-decoration: none;
        font-family: "Univers", HelveticaNeue, "Helvetica Neue", Helvetica, Arial,
            sans-serif;
        font-style: normal;
        font-weight: 300;
        font-size: 12px;
        line-height: 16px;
        color: #000000;
        border-bottom: none;
    }

    .router-link-exact-active {
        margin-top: 10px;
        box-shadow: inset 0 -4px 0 0 red;
        position: relative;
        padding-bottom: 15px;
    }
    
    }
    @media only screen and (min-width: 840px) and (max-width: 940px) {
        .routerLink {
        text-decoration: none;
        font-family: "Univers", HelveticaNeue, "Helvetica Neue", Helvetica, Arial,
            sans-serif;
        font-style: normal;
        font-weight: 300;
        font-size: 13px;
        line-height: 16px;
        color: #000000;
        border-bottom: none;
    }
    .router-link-exact-active {
        margin-top: 10px;
        box-shadow: inset 0 -4px 0 0 red;
        position: relative;
        padding-bottom: 15px;
    }

    
    }
    @media only screen and (min-width: 940px) and (max-width: 1040px) {
        .routerLink {
        text-decoration: none;
        font-family: "Univers", HelveticaNeue, "Helvetica Neue", Helvetica, Arial,
            sans-serif;
        font-style: normal;
        font-weight: 300;
        font-size: 15px;
        line-height: 16px;
        color: #000000;
        border-bottom: none;
    }

    .router-link-exact-active {
        margin-top: 10px;
        box-shadow: inset 0 -4px 0 0 red;
        position: relative;
        padding-bottom: 15px;
    }

    }
    </style>
    