<template>
  <div class="msi-disclosure-main">
    <ad currentPage="disclosures"></ad>
    <div v-if="isMobileHeaderToggle"></div>
    <div v-else>
      <h1 class="msi-disclosure-title">Regulatory Disclosures</h1>
    </div>


    <div v-if="isMobileHeaderToggle"></div>

    <div class="msi-disclosure-title-div" v-else>
      <div>
        <mds-tabs>
          <mds-tabs-item>
            <router-link class="msi-disclosure-link" :to="{
              name: 'msi-disclosure',
              params: {
                currentproduct: this.$store.state.product.CurrentProduct,
              },
            }">
              StockInvestor
            </router-link>
          </mds-tabs-item>
          <mds-tabs-item>
            <router-link class="mdi-disclosure-link" :to="{
              name: 'mdi-disclosure',
              params: {
                currentproduct: this.$store.state.product.CurrentProduct,
              },
            }">
              DividendInvestor
            </router-link>
          </mds-tabs-item>
        </mds-tabs>
      </div>
    </div>

    <div v-if="isMobileHeaderToggle" class="popover-msi-disclosure-main-div">
      <div>

        <h1 class="msi-disclosure-title-responsive">Regulatory Disclosures</h1>
      </div>

      <div class="main-popover-div-for-padding">
        <mds-button id="glossary-btn-trigger" class="glossary-terms-btn"
          @click="glossaryPopoverToggle = !glossaryPopoverToggle" size="large" variation="secondary"
          icon-right="caret-down">{{ currentProductFullName }}</mds-button>
        <mds-popover class="glossary-list-popover" :position="['bottom-right']" triggered-by="glossary-btn-trigger"
          v-model="glossaryPopoverToggle">
          <mds-list-group class="msi-glow-effect-list" has-collapsible-child>
            <mds-list-group-item text="Disclosures" aria-current="page" :active="defaultActiveResponsive"
              class="responsive-popover-table-group-item"
              @click.native.stop="DefaultActiveResponsive()"></mds-list-group-item>
            <mds-list-group-item text="Performance" collapsible class="responsive-popover-table-group-item-performance"
              :expanded="showList === true" @click.native.stop="PerformanceActiveResponsive()">
              <template #mds-list-item-children>
                <mds-list-group-item class="responsive-popover-table-group-item" text="Investing Risks"
                  @click.native.stop="InvestingTabResponsive()"
                  :active="activeInvestingResponsive"></mds-list-group-item>
                <mds-list-group-item class="responsive-popover-table-group-item" text="Benchmark Information"
                  @click.native.stop="BenchmarkTabResponsive()"
                  :active="activeBenchmarkResponsive"></mds-list-group-item>
                <mds-list-group-item class="responsive-popover-table-group-item" text="Tortoise and Hare Portfolios"
                  @click.native.stop="DividendTabResponsive()" :active="activeDividendResponsive"></mds-list-group-item>
                <mds-list-group-item class="responsive-popover-table-group-item"
                  text="Investment Theses and Latest Thoughts" @click.native.stop="InvestmentTabResponsive()"
                  :active="activeInvestmentResponsive"></mds-list-group-item>
                <mds-list-group-item class="responsive-popover-table-group-item" text="Research Roundup"
                  @click.native.stop="ResearchTabResponsive()" :active="activeResearchResponsive"></mds-list-group-item>
                <mds-list-group-item class="responsive-popover-table-group-item" text="Tortoise and Hare Watchlist"
                  @click.native.stop="IncomeTabResponsive()" :active="activeIncomeResponsive"></mds-list-group-item>
                <mds-list-group-item class="responsive-popover-table-group-item" text="Stock Focus and Spotlights"
                  @click.native.stop="PortfolioFocusTabResponsive()"
                  :active="activePortfolioResponsive"></mds-list-group-item>
              </template>
            </mds-list-group-item>
            <mds-list-group-item text="Data and Definitions" :active="dataAndDefinitionSelectedResponsive"
              @click.native.stop="dataAndDefinitionActiveResponsive()"
              class="responsive-popover-table-group-item"></mds-list-group-item>
          </mds-list-group>
        </mds-popover>
      </div>

      <div class="msi-disclosure-title-div">
        <div>
          <mds-tabs>
            <mds-tabs-item>
              <router-link class="msi-disclosure-link" :to="{
                name: 'msi-disclosure',
                params: {
                  currentproduct: this.$store.state.product.CurrentProduct,
                },
              }">
                StockInvestor
              </router-link>
            </mds-tabs-item>
            <mds-tabs-item>
              <router-link class="mdi-disclosure-link" :to="{
                name: 'mdi-disclosure',
                params: {
                  currentproduct: this.$store.state.product.CurrentProduct,
                },
              }">
                DividendInvestor
              </router-link>
            </mds-tabs-item>
          </mds-tabs>
        </div>
      </div>


    </div>


    <div class="msi-left-side-dropdown" v-else>
      <nav>
        <mds-list-group class="msi-glow-effect-list" has-collapsible-child>
          <mds-list-group-item text="Disclosures" aria-current="page" :active="defaultActive"
            class="msi-leftpanel-style" @click.native.stop="DefaultActive()"></mds-list-group-item>
          <mds-list-group-item :expanded="showList" text="Performance" collapsible
            class="msi-left-side-nav-performance msi-leftpanel-style" @click.native.stop="PerformanceActive()">
            <template #mds-list-item-children>
              <mds-list-group-item class="msi-left-side-nav msi-leftpanel-style" text="Investing Risks"
                @click.native.stop="InvestingTab()" :active="activeInvesting"></mds-list-group-item>
              <mds-list-group-item class="msi-left-side-nav msi-leftpanel-style" text="Benchmark Information"
                @click.native.stop="BenchmarkTab()" :active="activeBenchmark"></mds-list-group-item>
              <mds-list-group-item class="msi-left-side-nav msi-leftpanel-style" text="Tortoise and Hare Portfolios"
                @click.native.stop="DividendTab()" :active="activeDividend"></mds-list-group-item>
              <mds-list-group-item class="msi-left-side-nav msi-leftpanel-style"
                text="Investment Theses and Latest Thoughts" @click.native.stop="InvestmentTab()"
                :active="activeInvestment"></mds-list-group-item>
              <mds-list-group-item class="msi-left-side-nav msi-leftpanel-style" text="Research Roundup"
                @click.native.stop="ResearchTab()" :active="activeResearch"></mds-list-group-item>
              <mds-list-group-item class="msi-left-side-nav msi-leftpanel-style" text="Tortoise and Hare Watchlist"
                @click.native.stop="IncomeTab()" :active="activeIncome"></mds-list-group-item>
              <mds-list-group-item class="msi-left-side-nav msi-leftpanel-style" text="Stock Focus and Spotlights"
                @click.native.stop="PortfolioFocusTab()" :active="activePortfolio"></mds-list-group-item>
            </template>
          </mds-list-group-item>
          <mds-list-group-item text="Data and Definitions" :active="dataAndDefinitionSelected"
            @click.native.stop="dataAndDefinitionActive()" class="msi-leftpanel-style"></mds-list-group-item>
        </mds-list-group>
      </nav>
    </div>

    <div class="msi-data-main">
      <div v-if="isMobileHeaderToggle">
        <p class="disclosure-msi-main-for-top-margin" v-if="definitionView">
          The commentary, analysis, references to, and performance information
          contained within <i>Morningstar® StockInvestor
            <sup class="superscript-data">SM</sup></i>, except where explicitly noted,
          reflects that of portfolios owned by Morningstar, Inc. that are invested
          in accordance with the Tortoise and Hare strategies managed by
          Morningstar Investment Management LLC, a registered investment adviser
          and subsidiary of Morningstar, Inc. Throughout the newsletter,
          references to "Morningstar" refer to Morningstar, Inc.and/or, where
          applicable, its affiliates.<br /><br />
          Investment research is produced and issued by subsidiaries of
          Morningstar, Inc. including, but not limited to, Morningstar Research
          Services LLC, registered with and governed by the U.S. Securities and
          Exchange Commission. <br /><br />Opinions expressed are as of the
          current date and subject to change without notice. Morningstar, Inc. and
          Morningstar Investment Management shall not be responsible for any
          trading decisions, damages, or other losses resulting from, or related
          to, the information, data, analyses or opinions contained herein or
          their use, which do not constitute investment advice, are provided as of
          the date written, and are provided solely for informational purposes.
          This newsletter, including its data and commentary, is for informational
          purposes only and has not been tailored to suit any individual.
          References to specific securities or investment options should not be
          considered an offer to purchase or sell that specific investment.There
          is no guarantee that the objective of any investing strategy will be
          achieved.<br /><br />
          Employees of Morningstar or its affiliates may have holdings in the
          stocks shown herein. All data presented is based on the most recent
          information available to Morningstar as of the date indicated and may
          not be an accurate reflection of current data. There is no assurance
          that the data will remain the same.<br /><br />
          This commentary contains certain forward-looking statements.
          Forward-looking statements involve known and unknown risks,
          uncertainties and other factors which may cause the actual results to
          differ materially and/or substantially from any future results,
          performance or achievements expressed or implied by those projected in
          the forward-looking statements for any reason.<br />
        </p>
      </div>
      <div v-else>
        <p id="Disclosure" class="disclosure-msi-main-for-top-margin">
          The commentary, analysis, references to, and performance information
          contained within <i>Morningstar® StockInvestor
            <sup class="superscript-data">SM</sup></i>, except where explicitly noted,
          reflects that of portfolios owned by Morningstar, Inc. that are invested
          in accordance with the Tortoise and Hare strategies managed by
          Morningstar Investment Management LLC, a registered investment adviser
          and subsidiary of Morningstar, Inc. Throughout the newsletter,
          references to "Morningstar" refer to Morningstar, Inc.and/or, where
          applicable, its affiliates.<br /><br />
          Investment research is produced and issued by subsidiaries of
          Morningstar, Inc. including, but not limited to, Morningstar Research
          Services LLC, registered with and governed by the U.S. Securities and
          Exchange Commission. <br /><br />Opinions expressed are as of the
          current date and subject to change without notice. Morningstar, Inc. and
          Morningstar Investment Management shall not be responsible for any
          trading decisions, damages, or other losses resulting from, or related
          to, the information, data, analyses or opinions contained herein or
          their use, which do not constitute investment advice, are provided as of
          the date written, and are provided solely for informational purposes.
          This newsletter, including its data and commentary, is for informational
          purposes only and has not been tailored to suit any individual.
          References to specific securities or investment options should not be
          considered an offer to purchase or sell that specific investment.There
          is no guarantee that the objective of any investing strategy will be
          achieved.<br /><br />
          Employees of Morningstar or its affiliates may have holdings in the
          stocks shown herein. All data presented is based on the most recent
          information available to Morningstar as of the date indicated and may
          not be an accurate reflection of current data. There is no assurance
          that the data will remain the same.<br /><br />
          This commentary contains certain forward-looking statements.
          Forward-looking statements involve known and unknown risks,
          uncertainties and other factors which may cause the actual results to
          differ materially and/or substantially from any future results,
          performance or achievements expressed or implied by those projected in
          the forward-looking statements for any reason.<br />
        </p>
      </div>

      <div v-if="performanceHide">
        <div class="msi-performce">
          <p id="Performance" class="msi-border-performanance"></p>

          <h1 class="msi-performance-text">Performance</h1>
        </div>
        <div class="msi-disclosure-performance-data">
          <p class="msi-disclosure-data-set">
            The performance shown herein should in no way be considered indicative
            of, or a guarantee of the actual or future performance of, or viewed as
            a substitute for any portfolio invested in a similar strategy.
            Performance data shown represents past performance which does not
            guarantee future results.<br /><br />
            Morningstar Investment Management provides portfolio construction and
            ongoing monitoring and maintenance of model portfolios offered by a
            subsidiary through a discretionary advisory program. Discretionary
            accounts include those where our subsidiary has total authority over the
            investment management process and trading authority without any
            constraints that would hinder the ability to implement the intended
            strategy. Composites are created to group the accounts in the program by
            investment objective and allocation targets. Accounts are included in a
            composite at the beginning of the first full calendar month and are
            excluded at the end of the last full month the account is under
            management or fails to meet the composite definition. Accounts must meet
            a minimum asset level to be included in a composite and composite
            performance is expressed in United States dollars. Additional
            information about the composites and their construction are available
            upon request. The purpose in presenting composite performance is to
            provide a historical indication of the performance of this strategy. The
            actual results for an individual account within a composite may differ,
            sometimes substantially, from the composite’s historical performance
            shown here and may include an individual client incurring a loss.<br /><br />
            Composite performance is presented below. An asset- and time-weighted
            return is calculated by weighting individual account returns using
            beginning-of-period values. For an account, time-weighted returns is a
            method of calculating period-by-period returns that reflect the change
            in value and negates the effect of cash flows in or out of an account.
            Returns are calculated on a monthly basis using either daily valuation
            total return (which includes realized and unrealized gains and losses
            plus income for the measurement period) or the Modified Dietz method
            (which accounts for the timing of cash flows and assumes a constant rate
            of return over the time period) and are geometrically linked. Returns
            for periods over one year are annualized.<br /><br />
            Returns for individual holdings of an account are time-weighted trailing
            returns, which takes the change in a security’s net asset value (NAV),
            assuming the reinvestment of all income (in the form of dividends or
            interest payments) and capital gains distributions (on the actual
            reinvestment date used by the security) during the period and dividing
            by the initial NAV. Account returns are calculated by weighting the
            individual holding returns. The account returns do not reflect the
            deduction of custodian fees or the effects of taxation. If included, the
            actual performance would be lower than that shown.<br /><br />
            Gross returns reflect the deduction of trading costs, fund expenses, and
            the reimbursement of dividends, interest, and capital gains, and include
            the returns of cash or cash equivalent vehicles. Net returns reflect the
            deduction of the actual advisory fee, brokerage or other commissions,
            and other expenses that a client paid in connection with the advisory
            services they received. Accounts that are not charged an advisory fee
            have a model fee applied that equals the highest advisory fee that could
            be charged to this strategy. Net returns are calculated by deducting the
            applicable fees from the gross returns.<br /><br /></p>
        </div>
      </div>

      <div v-if="isMobileHeaderToggle">

        <div class="msi-disclosure-performance-data-first" v-if="performanceHide">
          <p class="msi-disclosure-performance-data-set">
            Performance shown for Morningstar’s Tortoise and Hare accounts does not
            include the deduction of advisory fees given that this newsletter is
            intended for self-directed investors who have not hired an investment
            adviser to manage their account. Performance shown for Morningstar’s
            portfolios does include the deduction of commissions. Reinvestment of
            all dividends and master limited partnership (MLP) distributions is
            assumed, although they may not be reinvested into the stock or unit that
            paid the dividend or distribution. The effects of taxation, other than
            foreign tax withholdings that may be applied to foreign stocks, are not
            taken into account. If these fees and expenses were included, the
            performance shown would be lower.<br />
          </p>
        </div>
        <div class="msi-graph-title-div" v-if="performanceHide">
          <p class="msi-border-performanance"></p>
          <h1 class="msi-net-composite-performance">Net Composite Performance %</h1>
        </div>
        <div class="msi-composite-performance-graph" v-if="performanceHide">
          <img class="msi-disclosure-graph" :src="imageUrl" />
        </div>
      </div>

      <div v-else>
        <div class="msi-graph-title-div">
          <p class="msi-border-performanance"></p>
          <h1 class="msi-net-composite-performance">Net Composite Performance %</h1>
        </div>
        <div class="msi-composite-performance-graph">
          <img class="msi-disclosure-graph" :src="imageUrl" />
        </div>
        <div class="msi-disclosure-performance-data-first">
          <p class="msi-disclosure-performance-data-set">
            Performance shown for Morningstar’s Tortoise and Hare accounts does not
            include the deduction of advisory fees given that this newsletter is
            intended for self-directed investors who have not hired an investment
            adviser to manage their account. Performance shown for Morningstar’s
            portfolios does include the deduction of commissions. Reinvestment of
            all dividends and master limited partnership (MLP) distributions is
            assumed, although they may not be reinvested into the stock or unit that
            paid the dividend or distribution. The effects of taxation, other than
            foreign tax withholdings that may be applied to foreign stocks, are not
            taken into account. If these fees and expenses were included, the
            performance shown would be lower.<br />
          </p>
        </div>

      </div>



      <div class="msi-investing-risks-paragraph">
        <p id="InvestingRisk" class="msi-paragraphs-group" v-if="InvestingRiskHide">
          <strong class="msi-title-paragraphs">Investing Risks</strong><br />
          Investments in securities are subject to investment
          risk, including possible loss of principal. Prices of securities may
          fluctuate from time to time and may even become valueless. Securities in
          this report are not FDIC-insured, may lose value, and are not guaranteed
          by a bank or other financial institution. Before making any investment
          decision, investors should read and consider all the relevant investment
          product information. Investors should seriously consider if the
          investment is suitable for them by referencing their own financial
          position, investment objectives, and risk profile before making any
          investment decision. There can be no assurance that any financial
          strategy will be successful.<br /><br />
          Common stocks are typically subject to greater fluctuations in market
          value than other asset classes as a result of factors such as a
          company's business performance, investor perceptions, stock market
          trends and general economic conditions. American Depositary Receipts
          ("ADRs") are foreign stocks listed on a U.S. exchange. Investment risks
          associated with ADRs and foreign stocks include, but are not limited to,
          currency, inflationary, and liquidity risks as well as the risk of
          adverse political, economic and social developments of the underlying
          issuer's home country. The underlying issuers of certain ADRs are under
          no obligation to distribute shareholder communications to ADR holders,
          or to pass through any voting rights with respect to the deposited
          securities.Dividends are not guaranteed and are paid at the discretion
          of the stock-issuing company.<br />
        </p>
        <p id="BenchmarkInformation" class="msi-paragraphs-group" v-if="BenchmarkHide">
          <strong class="msi-title-paragraphs">Benchmark Information</strong><br />
          Benchmark performance represents the return of an index. Indexes are not
          available for direct investment, and the performance does not reflect
          fees or expenses associated with investing in securities. Benchmarks
          provide historical market data that serves as a point of references for
          an investment and/or strategy. The S&P 500 Index is a market
          capitalization-weighted index composed of the 500 most widely held
          stocks whose assets and/or revenues are based in the U.S. It is often
          used as a proxy for the U.S. stock market.The Dow Jones U.S. Select
          Dividend™ Index is an annual dividend weighted index composed of 100
          stocks. It is used as a proxy for the U.S.'s leading stocks by dividend
          yield.<br />
        </p>
        <p id="TortoiseandHarePortfolios" class="msi-paragraphs-group" v-if="tortoisePortfolioHide">
          <strong class="msi-title-paragraphs">Tortoise and Hare Portfolios </strong><br />Portfolio tables include a
          list
          of all holdings in Morningstar's
          Tortoise and Hare accounts as of the date indicated. This information is
          subject to change at any time. <br />
        </p>
        <p id="InvestmentThesesandLatestThoughts" class="msi-paragraphs-group" v-if="InvestomentHide">
          <strong class="msi-title-paragraphs">Investment Theses and Latest Thoughts</strong><br />The Investment Theses
          and
          Latest Thoughts section includes the
          portfolio managers’ analysis and/or rationale for including each stock
          in Morningstar’s Tortoise and Hare portfolios. All opinions are those of
          the portfolio managers, and may include forward looking statements.
          <br />
        </p>
        <p id="ResearchRoundup" class="msi-paragraphs-group" v-if="researchHide">
          <strong class="msi-title-paragraphs">Research Roundup</strong><br />
          Research Roundup is a curated sampling of analyses from Morningstar,
          Inc. or its affiliates for the Tortoise and Hare portfolios’ current
          holdings published since <i>StockInvestor’s</i> last publication date.
          Analyst notes chosen for inclusion are prioritized based first on
          changes in moat ratings, then on changes in fair value uncertainty
          ratings, and then on changes in fair value estimates. <br />
        </p>
        <p id="TortoiseandHareWatchlist" class="msi-paragraphs-group" v-if="tortoiseAndHare">
          <strong class="msi-title-paragraphs">Tortoise and Hare Watchlist</strong><br />
          The Watchlist contains 120 companies that could potentially fit with the
          investment mandate of Morningstar’s Tortoise or Hare portfolios. These
          may include companies with wide or narrow moats, positive or stable moat
          trends, and below-average price/fair value ratios according to analysts
          of Morningstar, Inc. or its affiliates at the time the Watchlist is
          compiled. You should not assume that specific securities on the
          Watchlist is being considered for inclusion in Morningstar’s Tortoise or
          Hare portfolios or will be profitable. <br />
        </p>
        <p id="StockFocusandSpotlights" class="msi-paragraphs-group" v-if="stockFocusHide">
          <strong class="msi-title-paragraphs">Stock Focus and Spotlights</strong><br />
          The Stock Focus section contains Morningstar, Inc.'s analyses of several
          stocks that the editors believe may be of interest to
          <i>StockInvestor</i> subscribers. The Spotlight section includes recent
          investment research previously published by Morningstar, Inc. and have
          been edited for length. Morningstar's analysis is provided for
          informational purposes only.<br /><br />
          Securities identified in the Featured Holdings, Stock Focus, and
          Spotlight sections, to the extent they reflect holdings of Morningstar's
          Tortoise or Hare portfolios, do not include all of the securities
          purchased, sold, or recommended by Morningstar Investment Management for
          Morningstar's Tortoise and Hare portfolios. You should not assume
          securities in the Stock Focus and Spotlight sections were considered for
          inclusion in Morningstar's Tortoise and Hare portfolios. You should not
          assume that investments in the securities identified and discussed were
          or will be profitable.<br />
        </p>
      </div>
      <div class="msi-data-and-definition" v-if="dataanddefinitionHide">
        <p class="msi-border-performanance-data-definitions" id="DataandDefinitions"></p>
        <h1 class="msi-data-and-definition-title">Data and Definitions</h1>
        <p class="msi-data-and-definition-description">
          The monthly <i>StockInvestor</i> newsletter contains terms and data
          points that you may not be familiar with. Definitions and explanations
          of these items can be found below.
        </p>
      </div>
      <div class="msi-expanded-text">
        <mds-section v-for="(header, index) in dataAndDefinitions" :key="index" border="none" :style="header.style"
          :size="7" class="msi-collapsible-first" collapsible :title="header.title" :expanded="header.expanded" bold
          @mds-section-expanded="toggleSection(index)">
          <p class="data-definition-content" v-html="header.datatext"></p>
        </mds-section>
      </div>
    </div>

  </div>
</template>


<script>
const links = document.querySelectorAll(".page-header ul a");
for (const link of links) {
  link.addEventListener("click", clickHandler);
}

function clickHandler(e) {
  e.preventDefault();
  const href = this.getAttribute("href");
  const offsetTop = document.querySelector(href).offsetTop;

  scroll({
    top: offsetTop,
    behavior: "smooth",
  });
}

import { MdsListGroup, MdsListGroupItem } from '@mds/list-group'
import MdsSection from "@mds/section";
import { MdsTabs, MdsTabsItem } from "@mds/tabs";
import Ad from "@/components/Common/Ad/Ad.vue";
import { MdsButton } from '@mds/button'
import MdsPopover from "@mds/popover"
//import { MdsLayoutGrid, MdsRow, MdsCol } from "@mds/layout-grid";
import { mapState } from "vuex";
import { fetchData } from '@/utilities/API/apiUtility';
export default {
  name: "msi-disclosure-page",

  components: {
    MdsListGroup,
    MdsListGroupItem,
    MdsSection,
    MdsTabs,
    MdsTabsItem,
    Ad,
    MdsButton,
    MdsPopover,
    // MdsLayoutGrid, 
    // MdsRow, 
    //MdsCol
  },
  created() {
    this.ScrollMethod();
    this.fetchImageData();
  },
  mounted() {
    this.isMobileHeaderToggle = this.$store.state.responsive.isMobileHeader;
    this.toggleCss = !this.toggleCss;
    if (this.toggleCss == false)
      document.getElementById('Disclosure').style.marginTop = '0px';
    else
      document.getElementById('Disclosure').style.marginTop = '0px';
  },
  data() {
    return {
      imageUrl: null,
      ImageDataForMdiDisclosure: process.env.VUE_APP_Newsletters_Utilities + process.env.VUE_APP_GetImagedata,
      placement: 'MSI Disclosures page', // Change this to your desired placement
      productCode: 'MSI', // Change this to your desired product code
      glossaryPopoverToggle: false,
      isMobileHeaderToggle: null,
      showList: true,
      toggleCss: false,
      activePerformance: false,
      activeBenchmark: false,
      activeDividend: false,
      activeInvestment: false,
      activeResearch: false,
      activeIncome: false,
      activePortfolio: false,
      activeInvesting: false,
      defaultActive: true,
      defaultActiveResponsive: true,
      activeInvestingResponsive: false,
      activeBenchmarkResponsive: false,
      activeDividendResponsive: false,
      activeInvestmentResponsive: false,
      activeResearchResponsive: false,
      activeIncomeResponsive: false,
      activePortfolioResponsive: false,
      dataAndDefinitionSelectedResponsive: false,
      dataAndDefinitionSelected: false,
      activePerformanceResponsive: false,
      definitionView: true,
      performanceHide: true,
      InvestingRiskHide: true,
      BenchmarkHide: true,
      tortoisePortfolioHide: true,
      InvestomentHide: true,
      researchHide: true,
      tortoiseAndHare: true,
      stockFocusHide: true,
      dataanddefinitionHide: true,
      dataAndDefinitions: [
        {
          expanded: true,
          title: "Annual Dividend Per Share",
          datatext:
            "Annual dividend per share is the annualized dividend expected to be paid to each share of common stock based on the company's most recently declared dividend rate.",
          style: {
            "border-top": "none",


          },
        }
        ,

        {
          title: "Capital Allocation",
          datatext:
            "Our Capital Allocation (or Stewardship) Rating represents our assessment of the quality of management’s capital allocation, with particular emphasis on the firm’s balance sheet, investments, and shareholder distributions. Analysts consider companies’ investment strategy and valuation, balance sheet management, and dividend and share buyback policies. Corporate governance factors are only considered if they are likely to materially impact shareholder value, through either the balance sheet, investment, or shareholder distributions. Analysts assign one of three ratings: Exemplary, Standard, or Poor. Analysts judge Capital Allocation from an equity holder’s perspective. Ratings are determined on a forward looking and absolute basis. The Standard rating is most common as most managers will exhibit neither exceptionally strong nor poor capital allocation. <br/><br/> Capital Allocation (or Stewardship) analysis published prior to Dec. 9, 2020, was determined using a different process. Beyond investment strategy, financial leverage, and dividend and share buyback policies, analysts also considered execution, compensation, related party transactions, and accounting practices in the rating.",
          style: {
            "border-top": "#CCCCCC solid 1px",

          },
        },
        {
          title: "Cumulative Total Returns",
          datatext:
            "The cumulative total return graphs show the total percentage increase or decrease in the value of a portfolio or security over the specified time period.",
          style: {
            "border-top": "#CCCCCC solid 1px",

          },
        },
        {
          title: "Dividend Growth (%)",
          datatext:
            "Dividend growth, as shown in the Stock Focus data tables, represents the Morningstar analyst's forecast for the compound annual growth rate in the company's per-share dividend for the upcoming four years, starting from the base year indicated.",
          style: {
            "border-top": "#CCCCCC solid 1px",

          },
        },
        {
          title: "Dividend Yield (%)",
          datatext:
            "Dividend yield measures the annual dividend per share as a percentage of the stock price as of the date indicated.",
          style: {
            "border-top": "#CCCCCC solid 1px",

          },
        },
        {
          title: "Earnings Per Share",
          datatext:
            "Earnings per share, or EPS, measures a company's net income divided by diluted shares outstanding. Earnings per share shown in the Stock Focus data tables represent the Morningstar analyst's estimate for the fiscal year indicated. These estimates are frequently on an adjusted (non-GAAP) basis.",
          style: {
            "border-top": "#CCCCCC solid 1px",

          },
        },
        {
          title: "Economic Moat",
          datatext: "See Moat.",
          style: {
            "border-top": "#CCCCCC solid 1px",

          },
        },
        {
          title: "EPS Growth (%)",
          datatext:
            "Earnings per share growth, as shown in the Stock Focus data tables, represents the Morningstar analyst's forecast for the compound annual growth rate in the company's per-share profits for the upcoming four years, starting from the base year indicated. One of the important differences between earnings-per-share growth rates and net-income growth rates is that the former reflects the impact of net share repurchases and/or the dilution that occurs from new stock issuance and the exercise of employee stock options, warrants, or convertible securities.",
          style: {
            "border-top": "#CCCCCC solid 1px",

          },
        },
        {
          title: "Fair Value, Fair Value Estimates",
          datatext:
            "The fair value estimate is a proprietary Morningstar data point that displays a Morningstar analyst's estimate of what a stock is worth. Each stock's fair value is estimated by utilizing a proprietary discounted cash flow model, or DCF. This model assumes that the stock's intrinsic value is equal to the total free cash flows the company is expected to generate in the future, discounted back to the present at a rate commensurate with the riskiness of the cash flows. As with any DCF model, the fair value estimate is highly sensitive to Morningstar's projections of future cash flows and other assumptions.",
          style: {
            "border-top": "#CCCCCC solid 1px",

          },
        },
        {
          title: "Free Cash Flow Per Share",
          datatext:
            "Free cash flow per share is equal to operating cash flow minus capital spending, divided by diluted shares outstanding. Free cash flow represents the cash flow a company has left over after internal investments in its business. As shown in the Stock Focus data tables, free cash flow per share represents the Morningstar analyst's estimate for the fiscal year indicated.",
          style: {
            "border-top": "#CCCCCC solid 1px",

          },
        },
        {
          title: "FCF/Share Growth (%)",
          datatext:
            "Free cash flow per share growth, as shown in the Stock Focus data tables, represents the Morningstar analyst's forecast for the compound annual growth rate in the company's per-share free cash flow over the upcoming four years, starting from the base year indicated.",
          style: {
            "border-top": "#CCCCCC solid 1px",

          },
        },
        {
          title: "FCF Yield (%)",
          datatext:
            "Free cash flow yield is free cash flow per share divided by the stock price as of the date indicated.",
          style: {
            "border-top": "#CCCCCC solid 1px",

          },
        },
        {
          title: "Market Capitalization, Market Cap",
          datatext:
            "Market capitalization is the total equity value of a company as determined by the market price of its outstanding common stock. It is calculated by multiplying the market price as of the close of trading on the date indicated by the number of shares outstanding as of the most recently reported fiscal quarter.",
          style: {
            "border-top": "#CCCCCC solid 1px",

          },
        },
        {
          title: "Moat",
          datatext:
            "Moat is a proprietary Morningstar rating that measures a company's sustainable competitive advantage, if any. An economic moat enables a company to generate returns on invested capital above its cost of capital for a long period of time. Morningstar has identified five sources of economic moats: intangible assets, customer switching costs, cost advantages, network effects, and efficient scale. These are described in greater detail on Page 7 of <i>Morningstar StockInvestor Subscriber’s Handbook.</i> Morningstar assigns stocks one of three moat ratings: wide moat (companies with the longest-lasting competitive advantages), narrow moat (those with less durable competitive advantages), and no moat (those with no sustainable competitive advantage).",
          style: {
            "border-top": "#CCCCCC solid 1px",

          },
        },
        // {
        //   title: "Moat Trend",
        //   datatext:
        //     "Moat trend is a proprietary Morningstar rating that reflects whether the underlying sources of a company's competitive advantage (or potential sources of advantage) are improving over time (positive moat trend), weakening over time (negative moat trend), or neither strengthening nor weakening (stable moat trend). A company may also have a negative moat trend if it faces a substantial competitive threat that is growing. Moat trend ratings tend to change more frequently than moat ratings.",
        //   style: {
        //     "border-top": "#CCCCCC solid 1px",

        //   },
        // },
        {
          title: "Morningstar Rating™",
          datatext:
            "The Morningstar Rating™ for Stocks is a forward-looking, analyst-driven measure of a stock's current price relative to the analyst's estimate of what the shares are worth. Stock star ratings indicate whether a stock, in the equity analyst's educated opinion, is cheap, expensive, or fairly priced. To rate a stock, an analyst estimates what he thinks it is worth (its “fair value”), using a detailed, long-term cash flow forecast for the company. A stock's star rating depends on whether its current market price is above or below the fair value estimate. Those stocks trading at large discounts to their fair values receive the highest ratings (4 or 5 stars). Stocks trading at large premiums to their fair values receive lower ratings (1 or 2 stars). A 3-star rating means the current stock price is fairly close to the analyst's fair value estimate.",
          style: {
            "border-top": "#CCCCCC solid 1px",

          },
        },
        {
          title: "Morningstar Style Box™",
          datatext:
            "The Morningstar Style Box™ reveals a fund's investment strategy as of the date noted on this report. For equity funds, the vertical axis shows the market capitalization of the long stocks owned, and the horizontal axis shows the investment style (value, blend, or growth.) A darkened square in the style box indicates the weighted average style of the portfolio.",
          style: {
            "border-top": "#CCCCCC solid 1px",

          },
        },
        {
          title: "Operating Margin (%)",
          datatext:
            "Operating margin is equal to a company's annual operating profit or loss divided by its annual sales. The figures shown in the Stock Focus data tables use Morningstar analyst estimates for the fiscal year indicated, with operating income frequently stated before nonrecurring charges.",
          style: {
            "border-top": "#CCCCCC solid 1px",

          },
        },
        {
          title: "Price/Earnings Ratio, P/E",
          datatext:
            "The price/earnings ratio, or P/E, is a stock's current price divided by its annual earnings per share. A portfolio's P/E is equal to the weighted average of its stock holdings' P/E ratios. P/E ratios found in the Stock Focus data tables use the Morningstar analyst's estimate of earnings per share for the fiscal year indicated, frequently stated on an adjusted (non-GAAP) basis. P/E ratios listed with company data in the sidebars of some articles use GAAP earnings per share over the trailing twelve months. In other locations, the authors may refer to P/E ratios using other time periods or adjustments as specified.",
          style: {
            "border-top": "#CCCCCC solid 1px",

          },
        },
        {
          title: "Price/Fair Value Ratio, P/FV",
          datatext:
            "The price/fair value ratio, or P/FV, is a stock's current price divided by the Morningstar analyst's fair value estimate. A portfolio's P/FV is equal to the weighted average of its stock holdings' price/fair value ratios. A P/FV ratio above 1 indicates that the Morningstar analyst believes a stock is overvalued, while a P/FV below 1 indicates that the Morningstar analyst believes the stock is undervalued.",
          style: {
            "border-top": "#CCCCCC solid 1px",

          },
        },
        {
          title: "Price/Sales Ratio",
          datatext:
            "The price/sales ratio is a stock's market capitalization divided by its annual revenue for the fiscal year indicated.",
          style: {
            "border-top": "#CCCCCC solid 1px",

          },
        },
        {
          title: "Return on Equity (%)",
          datatext:
            "Return on equity is a company's annual net income divided by its average shareholders' equity. The figures shown in the Stock Focus data tables are based on Morningstar analyst estimates.",
          style: {
            "border-top": "#CCCCCC solid 1px",

          },
        },
        {
          title: "Return on Invested Capital (%)",
          datatext:
            "Return on invested capital, as shown in the Stock Focus data tables, is the Morningstar analyst's estimate of a company's earnings before interest divided by its average invested capital. Invested capital includes operating assets such as inventory, accounts receivable, and property, plant, and equipment, less operating liabilities such as accounts payable and deferred taxes.",
          style: {
            "border-top": "#CCCCCC solid 1px",

          },
        },

        {
          title: "Revenue",
          datatext:
            "Revenue, as shown in the Stock Focus data tables, represents the Morningstar analyst's estimate of a company's sales in the fiscal year indicated.",
          style: {
            "border-top": "#CCCCCC solid 1px",

          },
        },
        {
          title: "Revenue Growth (%)",
          datatext:
            "Revenue growth, as shown in the Stock Focus data tables, represents the Morningstar analyst's forecast for the compound annual growth rate in the company's revenue over the upcoming four years, starting from the base year indicated.",
          style: {
            "border-top": "#CCCCCC solid 1px",

          },
        },
        {
          title: "Sector",
          datatext:
            "A sector is a company's general area of business. Morningstar divides stocks into 11 sectors according to their primary business, grouped into three larger super sectors. The communication services, energy, industrials, and technology sectors make up the sensitive super sector. Healthcare, consumer defensive, and utilities make up the defensive super sector. Basic materials, consumer cyclical, financial services, and real estate make up the cyclical super sector.",
          style: {
            "border-top": "#CCCCCC solid 1px",

          },
        },
        {
          title: "Size of Moat",
          datatext: "See Moat.",
          style: {
            "border-top": "#CCCCCC solid 1px",

          },
        },
        {
          title: "Star Rating",
          datatext: "See Morningstar Rating™.",
          style: {
            "border-top": "#CCCCCC solid 1px",

          },
        },
        {
          title: "Style (%)",
          datatext: "See Morningstar Style Box™.",
          style: {
            "border-top": "#CCCCCC solid 1px",

          },
        },
        {
          title: "Total Returns",
          datatext:
            "Unless stated otherwise, total returns reflect investment performance without adjusting for sales charges (if applicable), advisory fees, or the effects of taxation. Total returns are adjusted to reflect all actual ongoing expenses (such as commissions and foreign withholding taxes) and assume reinvestment of applicable dividends and capital gains. Total returns for periods longer than one year are expressed in terms of compound annual averages, also known as geometric total returns. If adjusted for sales charges, advisory fees, and the effects of taxation, the performance quoted would be significantly reduced. Relative total returns, as shown on the back cover of the monthly newsletter, indicate how the combined Tortoise and Hare portfolios have performed relative to the S&P 500 over the time periods indicated.",
          style: {
            "border-top": "#CCCCCC solid 1px",

          },
        },
        {
          title: "Uncertainty Rating",
          datatext:
            "Morningstar’s Uncertainty Rating is designed to capture the range of potential outcomes for a company’s intrinsic value. The rating begins with a suggested rating produced by a quantitative process based on the trailing 12-month standard deviation of daily stock returns. An analyst overlay is then applied, with analysts using the suggested rating, historical rating data, and their own knowledge of the company to inform them as they make the final Uncertainty Rating decision. Our Uncertainty Rating is meant to consider anything that can increase the potential dispersion of outcomes for the intrinsic value of a company, and anything that can affect our ability to predict these outcomes accurately. These can include cyclical factors, operational and financial factors such as leverage, company-specific events, ESG risks, and anything else that might increase the potential dispersion of future outcomes and our ability to estimate those outcomes. We have five different ratings: Low, Medium, High, Very High, or Extreme. Our recommended margin of safety—the discount to fair value demanded before we’d recommend buying or selling the stock—widens as our uncertainty of the estimated value of the equity increases.",
          style: {
            "border-top": "#CCCCCC solid 1px",

          },
        },
        {
          title: "Yield",
          datatext: "See Dividend Yield.",
          style: {
            "border-top": "#CCCCCC solid 1px",

          },
        },
      ],
    }
  },

  methods: {
    async fetchImageData() {
      try {
        let Url = this.ImageDataForMdiDisclosure;
        Url = Url.replace("{ProductCode}", "MSI");
        fetchData(Url)
          .then((response) => {
            const images = response.data.fileData;
            const filteredImage = images.find(image => image.placement.includes(this.placement));
            if (filteredImage) {
              this.imageUrl = `${filteredImage.s3URL}?t=${new Date().getTime()}`;
            }

          })


      } catch (ex) {
        console.log(ex.message);
      }
    },
    glossaryPopoverTogglebutton() {
      this.glossaryPopoverToggle = true
    },
    ScrollMethod() {
      window.addEventListener("scroll", () => {
        const currentScroll = window.pageYOffset;
        if (currentScroll >= 0 && currentScroll < 930) {
          this.defaultActive = true;
          this.activePerformance = false;
          this.activeInvesting = false;
          this.activeBenchmark = false;
          this.activeDividend = false;
          this.activeInvestment = false;
          this.activeResearch = false;
          this.activeIncome = false;
          this.activePortfolio = false;
          this.dataAndDefinitionSelected = false;
        }
        else if (currentScroll >= 911 && currentScroll < 2394) {
          this.defaultActive = false;
          this.activePerformance = true;
          this.activeInvesting = false;
          this.activeBenchmark = false;
          this.activeDividend = false;
          this.activeInvestment = false;
          this.activeResearch = false;
          this.activeIncome = false;
          this.activePortfolio = false;
          this.dataAndDefinitionSelected = false;
        }
        else if (currentScroll >= 2395 && currentScroll < 2805) {
          this.defaultActive = false;
          this.activePerformance = false;
          this.activeInvesting = true;
          this.activeBenchmark = false;
          this.activeDividend = false;
          this.activeInvestment = false;
          this.activeResearch = false;
          this.activeIncome = false;
          this.activePortfolio = false;
          this.dataAndDefinitionSelected = false;
        }
        else if (currentScroll >= 2808 && currentScroll < 3000) {
          this.defaultActive = false;
          this.activePerformance = false;
          this.activeInvesting = false;
          this.activeBenchmark = true;
          this.activeDividend = false;
          this.activeInvestment = false;
          this.activeResearch = false;
          this.activeIncome = false;
          this.activePortfolio = false;
          this.dataAndDefinitionSelected = false;
        }
        else if (currentScroll >= 3004 && currentScroll < 3100) {
          this.defaultActive = false;
          this.activePerformance = false;
          this.activeInvesting = false;
          this.activeBenchmark = false;
          this.activeDividend = true;
          this.activeInvestment = false;
          this.activeResearch = false;
          this.activeIncome = false;
          this.activePortfolio = false;
          this.dataAndDefinitionSelected = false;
        }
        else if (currentScroll >= 3101 && currentScroll < 3200) {
          this.defaultActive = false;
          this.activePerformance = false;
          this.activeInvesting = false;
          this.activeBenchmark = false;
          this.activeDividend = false;
          this.activeInvestment = true;
          this.activeResearch = false;
          this.activeIncome = false;
          this.activePortfolio = false;
          this.dataAndDefinitionSelected = false;
        }
        else if (currentScroll >= 3205 && currentScroll < 3330) {
          this.defaultActive = false;
          this.activePerformance = false;
          this.activeInvesting = false;
          this.activeBenchmark = false;
          this.activeDividend = false;
          this.activeInvestment = false;
          this.activeResearch = true;
          this.activeIncome = false;
          this.activePortfolio = false;
          this.dataAndDefinitionSelected = false;
        }
        else if (currentScroll >= 3335 && currentScroll < 3501) {
          this.defaultActive = false;
          this.activePerformance = false;
          this.activeInvesting = false;
          this.activeBenchmark = false;
          this.activeDividend = false;
          this.activeInvestment = false;
          this.activeResearch = false;
          this.activeIncome = true;
          this.activePortfolio = false;
          this.dataAndDefinitionSelected = false;
        }
        else if (currentScroll >= 3515 && currentScroll < 3740) {
          this.defaultActive = false;
          this.activePerformance = false;
          this.activeInvesting = false;
          this.activeBenchmark = false;
          this.activeDividend = false;
          this.activeInvestment = false;
          this.activeResearch = false;
          this.activeIncome = false;
          this.activePortfolio = true;
          this.dataAndDefinitionSelected = false;
        }
        else if (currentScroll >= 3760 && currentScroll < 4615) {
          this.defaultActive = false;
          this.activePerformance = false;
          this.activeInvesting = false;
          this.activeBenchmark = false;
          this.activeDividend = false;
          this.activeInvestment = false;
          this.activeResearch = false;
          this.activeIncome = false;
          this.activePortfolio = false;
          this.dataAndDefinitionSelected = true;
        }

      });
    },
    toggleSection(index) {
      this.dataAndDefinitions = this.dataAndDefinitions.map((item, i) => ({
        ...item,
        expanded: i === index,
      }));
    },

    InvestingTab() {
      const element = document.getElementById("InvestingRisk");
      element.scrollIntoView();

    },
    BenchmarkTab() {
      const element = document.getElementById("BenchmarkInformation");
      element.scrollIntoView();

    },

    BenchmarkTabResponsive() {
      this.activePerformanceResponsive = false;
      this.activeInvestingResponsive = false;
      this.activeBenchmarkResponsive = true;
      this.activeDividendResponsive = false;
      this.activeInvestmentResponsive = false;
      this.activeResearchResponsive = false;
      this.activeIncomeResponsive = false;
      this.activePortfolioResponsive = false;
      this.dataAndDefinitionSelectedResponsive = false;
      this.defaultActiveResponsive = false,

        this.BenchmarkHide = true;
      this.tortoisePortfolioHide = true;
      this.InvestomentHide = true;
      this.researchHide = true;
      this.tortoiseAndHare = true;
      this.stockFocusHide = true;
      this.dataanddefinitionHide = true,
        this.glossaryPopoverToggle = false;
      this.performanceHide = false;
      this.definitionView = false;
      this.InvestingRiskHide = false;
      const element = document.getElementById("BenchmarkInformation");
      this.scrollElementIntoView(element, 'smooth');
    },

    DividendTab() {
      const element = document.getElementById("TortoiseandHarePortfolios");
      element.scrollIntoView();

    },

    DividendTabResponsive() {
      this.activePerformanceResponsive = false;
      this.activeInvestingResponsive = false;
      this.activeBenchmarkResponsive = false;
      this.activeDividendResponsive = true;
      this.activeInvestmentResponsive = false;
      this.activeResearchResponsive = false;
      this.activeIncomeResponsive = false;
      this.activePortfolioResponsive = false;
      this.dataAndDefinitionSelectedResponsive = false;
      this.defaultActiveResponsive = false,

        this.tortoisePortfolioHide = true;
      this.InvestomentHide = true;
      this.researchHide = true;
      this.tortoiseAndHare = true;
      this.stockFocusHide = true;
      this.dataanddefinitionHide = true,
        this.BenchmarkHide = false;
      this.glossaryPopoverToggle = false;
      this.performanceHide = false;
      this.definitionView = false;
      this.InvestingRiskHide = false;
      const element = document.getElementById("TortoiseandHarePortfolios");
      this.scrollElementIntoView(element, 'smooth');
    },

    InvestingTabResponsive() {
      this.activePerformanceResponsive = false;
      this.activeInvestingResponsive = true;
      this.activeBenchmarkResponsive = false;
      this.activeDividendResponsive = false;
      this.activeInvestmentResponsive = false;
      this.activeResearchResponsive = false;
      this.activeIncomeResponsive = false;
      this.activePortfolioResponsive = false;
      this.dataAndDefinitionSelectedResponsive = false;
      this.defaultActiveResponsive = false,

        this.glossaryPopoverToggle = false;
      this.InvestingRiskHide = true;
      this.BenchmarkHide = true;
      this.tortoisePortfolioHide = true;
      this.InvestomentHide = true;
      this.researchHide = true;
      this.tortoiseAndHare = true;
      this.stockFocusHide = true;
      this.dataanddefinitionHide = true,
        this.performanceHide = false;
      this.definitionView = false;
      const element = document.getElementById(
        "InvestingRisk"
      );
      this.scrollElementIntoView(element, 'smooth');
    },

    InvestmentTabResponsive() {
      this.activePerformanceResponsive = false;
      this.activeInvestingResponsive = false;
      this.activeBenchmarkResponsive = false;
      this.activeDividendResponsive = false;
      this.activeInvestmentResponsive = true;
      this.activeResearchResponsive = false;
      this.activeIncomeResponsive = false;
      this.activePortfolioResponsive = false;
      this.dataAndDefinitionSelectedResponsive = false;
      this.defaultActiveResponsive = false,

        this.InvestomentHide = true;
      this.researchHide = true;
      this.tortoiseAndHare = true;
      this.stockFocusHide = true;
      this.dataanddefinitionHide = true,
        this.tortoisePortfolioHide = false;
      this.BenchmarkHide = false;
      this.glossaryPopoverToggle = false;
      this.performanceHide = false;
      this.definitionView = false;
      this.InvestingRiskHide = false;
      const element = document.getElementById(
        "InvestmentThesesandLatestThoughts"
      );
      this.scrollElementIntoView(element, 'smooth');
    },

    InvestmentTab() {
      const element = document.getElementById(
        "InvestmentThesesandLatestThoughts"
      );
      element.scrollIntoView();
    },
    ResearchTab() {
      const element = document.getElementById("ResearchRoundup");
      element.scrollIntoView();

    },

    ResearchTabResponsive() {
      this.activePerformanceResponsive = false;
      this.activeInvestingResponsive = false;
      this.activeBenchmarkResponsive = false;
      this.activeDividendResponsive = false;
      this.activeInvestmentResponsive = false;
      this.activeResearchResponsive = true;
      this.activeIncomeResponsive = false;
      this.activePortfolioResponsive = false;
      this.dataAndDefinitionSelectedResponsive = false;
      this.defaultActiveResponsive = false,

        this.researchHide = true;
      this.tortoiseAndHare = true;
      this.stockFocusHide = true;
      this.dataanddefinitionHide = true,
        this.InvestomentHide = false;
      this.tortoisePortfolioHide = false;
      this.BenchmarkHide = false;
      this.glossaryPopoverToggle = false;
      this.performanceHide = false;
      this.definitionView = false;
      this.InvestingRiskHide = false;
      const element = document.getElementById("ResearchRoundup");
      this.scrollElementIntoView(element, 'smooth');
    },

    IncomeTab() {
      const element = document.getElementById("TortoiseandHareWatchlist");
      element.scrollIntoView();

    },

    IncomeTabResponsive() {
      this.activePerformanceResponsive = false;
      this.activeInvestingResponsive = false;
      this.activeBenchmarkResponsive = false;
      this.activeDividendResponsive = false;
      this.activeInvestmentResponsive = false;
      this.activeResearchResponsive = false;
      this.activeIncomeResponsive = true;
      this.activePortfolioResponsive = false;
      this.dataAndDefinitionSelectedResponsive = false;
      this.defaultActiveResponsive = false,

        this.tortoiseAndHare = true;
      this.stockFocusHide = true;
      this.dataanddefinitionHide = true,
        this.researchHide = false;
      this.InvestomentHide = false;
      this.tortoisePortfolioHide = false;
      this.BenchmarkHide = false;
      this.glossaryPopoverToggle = false;
      this.performanceHide = false;
      this.definitionView = false;
      this.InvestingRiskHide = false;
      const element = document.getElementById("TortoiseandHareWatchlist");
      this.scrollElementIntoView(element, 'smooth');
    },

    PortfolioFocusTab() {
      const element = document.getElementById("StockFocusandSpotlights");
      element.scrollIntoView();

    },

    PortfolioFocusTabResponsive() {
      this.activePerformanceResponsive = false;
      this.activeInvestingResponsive = false;
      this.activeBenchmarkResponsive = false;
      this.activeDividendResponsive = false;
      this.activeInvestmentResponsive = false;
      this.activeResearchResponsive = false;
      this.activeIncomeResponsive = false;
      this.activePortfolioResponsive = true;
      this.dataAndDefinitionSelectedResponsive = false;
      this.defaultActiveResponsive = false,

        this.stockFocusHide = true;
      this.tortoiseAndHare = false;
      this.researchHide = false;
      this.InvestomentHide = false;
      this.tortoisePortfolioHide = false;
      this.BenchmarkHide = false;
      this.glossaryPopoverToggle = false;
      this.performanceHide = false;
      this.definitionView = false;
      this.InvestingRiskHide = false;
      const element = document.getElementById("StockFocusandSpotlights");
      this.scrollElementIntoView(element, 'smooth');
    },

    DefaultActive() {
      const element = document.getElementById("Disclosure");
      element.scrollIntoView();

    },
    DefaultActiveResponsive() {
      this.activePerformanceResponsive = false;
      this.activeInvestingResponsive = false;
      this.activeBenchmarkResponsive = false;
      this.activeDividendResponsive = false;
      this.activeInvestmentResponsive = false;
      this.activeResearchResponsive = false;
      this.activeIncomeResponsive = false;
      this.activePortfolioResponsive = false;
      this.dataAndDefinitionSelectedResponsive = false;
      this.defaultActiveResponsive = true,

        this.glossaryPopoverToggle = false;
      this.definitionView = true;
      this.performanceHide = true;
      this.InvestingRiskHide = true;
      this.BenchmarkHide = true;
      this.tortoisePortfolioHide = true;
      this.InvestomentHide = true;
      this.researchHide = true;
      this.tortoiseAndHare = true;
      this.stockFocusHide = true;
      this.dataanddefinitionHide = true;
      const element = document.getElementById("Disclosure");
      this.scrollElementIntoView(element, 'smooth');
    },


    dataAndDefinitionActive() {
      const element = document.getElementById("DataandDefinitions");
      element.scrollIntoView();

    },

    dataAndDefinitionActiveResponsive() {
      this.activePerformanceResponsive = false;
      this.activeInvestingResponsive = false;
      this.activeBenchmarkResponsive = false;
      this.activeDividendResponsive = false;
      this.activeInvestmentResponsive = false;
      this.activeResearchResponsive = false;
      this.activeIncomeResponsive = false;
      this.activePortfolioResponsive = false;
      this.dataAndDefinitionSelectedResponsive = true;
      this.defaultActiveResponsive = false,

        this.dataanddefinitionHide = true,
        this.stockFocusHide = false;
      this.tortoiseAndHare = false;
      this.researchHide = false;
      this.InvestomentHide = false;
      this.tortoisePortfolioHide = false;
      this.BenchmarkHide = false;
      this.glossaryPopoverToggle = false;
      this.performanceHide = false;
      this.definitionView = false;
      this.InvestingRiskHide = false;
      const element = document.getElementById("DataandDefinitions");
      this.scrollElementIntoView(element, 'smooth');
    },

    PerformanceActive() {
      this.showList = !this.showList;
      const element = document.getElementById("Performance");
      element.scrollIntoView();
      if (this.showList == false)
        document.getElementById('Disclosure').style.marginTop = '220px';
      else
        document.getElementById('Disclosure').style.marginTop = '0px';
    },

    PerformanceActiveResponsive() {
      this.activePerformanceResponsive = true;
      this.activeInvestingResponsive = false;
      this.activeBenchmarkResponsive = false;
      this.activeDividendResponsive = false;
      this.activeInvestmentResponsive = false;
      this.activeResearchResponsive = false;
      this.activeIncomeResponsive = false;
      this.activePortfolioResponsive = false;
      this.dataAndDefinitionSelectedResponsive = false;
      this.defaultActiveResponsive = false,

        this.glossaryPopoverToggle = false;
      this.definitionView = false;
      this.performanceHide = true;
      this.InvestingRiskHide = true;
      this.BenchmarkHide = true;
      this.tortoisePortfolioHide = true;
      this.InvestomentHide = true;
      this.researchHide = true;
      this.tortoiseAndHare = true;
      this.stockFocusHide = true;
      this.dataanddefinitionHide = true,
        this.showList = !this.showList;
      const element = document.getElementById("Performance");
      this.scrollElementIntoView(element, 'smooth');
    },
    scrollElementIntoView(element, behavior) {
      let scrollTop = window.scrollY || element.scrollTop
      const headerOutsideIframe = window.parent.document.getElementsByClassName('header-container')[0].clientHeight
      const finalOffset = element.getBoundingClientRect().top + scrollTop + headerOutsideIframe
      window.parent.scrollTo({
        top: finalOffset,
        behavior: behavior || 'auto'
      })
    }
  },



  computed: mapState({

    isMobileHeader: state => state.responsive.isMobileHeader,
    isMobileHeaderAlias: 'isMobileHeader',
    isMobileHeaderState(state) {
      return state.responsive.isMobileHeader
    },

    currentProductFullName() {
      if (this.defaultActiveResponsive == true) {
        return "Table of Contents";
      } else if (this.activePerformanceResponsive == true) {
        return "Performance";
      } else if (this.activeInvestingResponsive == true) {
        return "Investing Risks";
      } else if (this.activeBenchmarkResponsive == true) {
        return "Benchmark Information";
      } else if (this.activeDividendResponsive == true) {
        return "Tortoise and Hare Portfolios";
      } else if (this.activeInvestmentResponsive == true) {
        return "Investment Theses and Latest Thoughts";
      } else if (this.activeResearchResponsive == true) {
        return "Research Roundup";
      } else if (this.activeIncomeResponsive == true) {
        return "Tortoise and Hare Watchlist";
      } else if (this.activePortfolioResponsive == true) {
        return "Stock Focus and Spotlights";
      } else if (this.dataAndDefinitionSelectedResponsive == true) {
        return "Data and Definitions";
      } else {
        return "Table of Contents";
      }
    }

  }),

  watch: {

    isMobileHeaderState() {
      this.isMobileHeaderToggle = this.isMobileHeaderState;
    },

  }



};
</script>

<style scoped>
.msi-disclosure-title {
  margin-top: 48.09px;
  height: 45px;
  margin-bottom: 37px;
  font-weight: 700;
  font-size: 32px;
  line-height: 36px;
  color: #1e1e1e;
}



.msi-left-side-dropdown {
  width: 235px;
  margin-top: -40px;
  padding-top: 40px;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}



@media only screen and (min-width: 0px) and (max-width: 1000px) {
  .disclosure-msi-main-for-top-margin {}


  .msi-composite-performance-graph {
    margin-left: 0px;
    margin-top: 16px;
  }

  .msi-data-and-definition {
    margin-top: 20px;
    margin-bottom: 0px;
  }

  .msi-disclosure-title-div {

    margin-bottom: 26px;
    border-bottom: #e5e5e5 solid 1px;
  }


  .msi-performance-text {
    margin-top: 9px;
    margin-bottom: 21px;
    height: 23px;
    font-weight: 700;
    font-size: 20px;
    line-height: 23px;
    color: #000000;
  }

  .msi-data-and-definition-title {
    margin-left: 0px;
    height: 31px;
    margin-top: 9px;
    margin-bottom: 0px;
    font-weight: 700;
    font-size: 20px;
    line-height: 23px;
    color: #000000;
  }

  .msi-net-composite-performance {
    font-style: normal;
    margin-top: 11.5px;
    margin-bottom: 0px;
    font-weight: 300;
    font-size: 20px;
    line-height: 23px;
    color: #1e1e1e;
    margin-left: 0px;
  }

  .main-popover-div-for-padding {
    margin-bottom: 20px;
  }

  .msi-disclosure-title-responsive {
    margin-top: 40px;
    height: 34px;
    margin-bottom: 19px;
    font-weight: 700;
    font-size: 28px;
    line-height: 34px;
    color: #1e1e1e;
  }

  .popover-msi-disclosure-main-div {
    margin-top: 40px;
    width: 90%;
    margin-left: 5%;
  }

  .glossary-list-popover {
    width: 90%;
    height: auto;
  }

  .msi-data-and-definition-description {
    margin-top: 7px;
    margin-bottom: 22px;
    margin-left: 0px;
    width: 100%;
    height: 57px;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 21px;
    color: #000000;
  }

  .msi-data-main {
    font-weight: 300;
    font-size: 16px;
    line-height: 21px;
    color: #000000;
    margin-left: 5%;
    margin-right: 5%;
    width: 90%;
  }

  .msi-border-performanance {
    border-top: #5e5e5e solid 1px;
    width: 100%;
    margin-top: 21px;
    margin-bottom: 0px;
    margin-left: 0px;
  }

  .msi-border-performanance-data-definitions {
    border-top: #5e5e5e solid 1px;
    width: 100%;
    margin-left: 0px;
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .msi-disclosure-graph {
    margin-bottom: 0px;
    width: 100%;
    max-height: 522px;
  }

  .msi-investing-risks-paragraph {
    margin-left: 0px;
    width: 100%;
    font-style: normal;
    font-weight: 300px;
    font-size: 16px;
    line-height: 21px;
    color: #000000;
    margin-top: 15px;
  }

  .msi-expanded-text>section {
    padding-top: 13px;
    padding-bottom: 13px;
  }


}


@media only screen and (min-width: 1001px) and (max-width: 1100px) {
  .msi-data-and-definition-description {
    margin-top: 14px;
    margin-bottom: 22px;
    margin-left: 0px;
    width: 573px;
    height: 57px;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 21px;
    color: #000000;
  }

  .msi-data-and-definition {
    margin-top: 48px;
    margin-bottom: 0px;
  }

  .msi-data-and-definition-title {
    margin-left: 0px;
    height: 31px;
    margin-top: 9px;
    margin-bottom: 25px;
    font-weight: 700;
    font-size: 23px;
    line-height: 27px;
    color: #000000;
  }

  .msi-net-composite-performance {
    font-style: normal;
    margin-top: 11.5px;
    margin-bottom: 0px;
    font-weight: 300;
    font-size: 23px;
    line-height: 27px;
    color: #1e1e1e;
    margin-left: 0px;
  }

  .msi-performance-text {
    margin-top: 9px;
    margin-bottom: 0px;
    height: 31px;
    font-weight: 700;
    font-size: 23px;
    line-height: 27px;
    color: #000000;
  }

  .msi-expanded-text>section {
    padding-top: 6px;
    padding-bottom: 6px;
  }

  .msi-data-main {
    margin-top: -331px;
    font-weight: 300;
    font-size: 16px;
    line-height: 21px;
    color: #000000;
    margin-left: 300px;
    width: 60%;
  }

  .msi-expanded-text {
    margin-left: 0px;
    width: 110%;
    margin-bottom: 85px;
  }

  .msi-disclosure-performance-data {
    height: 843px;
    width: 110%;
    margin-left: 0px;
    padding-bottom: 50px;
  }

  .msi-border-performanance-data-definitions {
    border-top: #5e5e5e solid 1px;
    width: 110%;
    margin-left: 0px;
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .msi-disclosure-performance-data-first {
    width: 110%;
    margin-bottom: 10px;
    font-weight: 300;
    font-size: 16px;
    line-height: 21px;
    color: #000000;
    margin-left: 0px;
  }

  .msi-disclosure-graph {
    margin-bottom: 20px;
    width: 110%;
    max-height: 522px;
  }


  .msi-border-performanance {
    border-top: #5e5e5e solid 1px;
    width: 110%;
    margin-top: 10px;
    margin-bottom: 0px;
    margin-left: 0px;
  }

}

@media only screen and (min-width: 1001px) and (max-width: 1280px) {
  .msi-disclosure-main {
    max-width: 90%;

    margin: 0 auto;
  }

  .msi-disclosure-title-div {

    margin-bottom: 41px;
    border-bottom: #e5e5e5 solid 1px;
  }

  .msi-investing-risks-paragraph {
    margin-left: 0px;
    width: 584px;
    font-style: normal;
    font-weight: 300px;
    font-size: 16px;
    line-height: 21px;
    color: #000000;
    margin-top: 1px;
  }


  .msi-composite-performance-graph {
    margin-left: 0px;
    margin-top: 28px;
  }

}

@media only screen and (min-width: 1280px) and (max-width: 128000px) {

  .msi-disclosure-main {
    max-width: 1280px;

    margin: 0 auto;
  }

  .msi-disclosure-title-div {

    margin-bottom: 41px;
    border-bottom: #e5e5e5 solid 1px;
  }

  .msi-investing-risks-paragraph {
    margin-left: 0px;
    width: 584px;
    font-style: normal;
    font-weight: 300px;
    font-size: 16px;
    line-height: 21px;
    color: #000000;
    margin-top: 1px;
  }


  .msi-composite-performance-graph {
    margin-left: 0px;
    margin-top: 28px;
  }

}

.disclosure-mid-part {
  display: flex;
}



.msi-performce {
  margin-left: 0px;
}






.msi-paragraphs-group {
  margin-top: 0px;
  margin-bottom: 20px;
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 21px;
  /* or 131% */

  color: #000000;
}

.msi-title-paragraphs {
  font-weight: 700;
  font-size: 16px;
  line-height: 21px;
  color: #000000;
}






@media only screen and (min-width: 1100px) and (max-width: 110000px) {
  .msi-expanded-text>section {
    padding-top: 6px;
    padding-bottom: 6px;
  }

  .msi-data-and-definition {
    margin-top: 48px;
    margin-bottom: 0px;
  }

  .msi-data-and-definition-title {
    margin-left: 0px;
    height: 31px;
    margin-top: 9px;
    margin-bottom: 25px;
    font-weight: 700;
    font-size: 23px;
    line-height: 27px;
    color: #000000;
  }

  .msi-graph-title-div {
    margin-left: 0px;
    margin-top: 33px;
  }

  .msi-net-composite-performance {
    font-style: normal;
    margin-top: 11.5px;
    margin-bottom: 0px;
    font-weight: 300;
    font-size: 23px;
    line-height: 27px;
    color: #1e1e1e;
    margin-left: 0px;
  }

  .msi-performance-text {
    margin-top: 9px;
    margin-bottom: 0px;
    height: 31px;
    font-weight: 700;
    font-size: 23px;
    line-height: 27px;
    color: #000000;
  }

  .msi-data-main {
    margin-top: -331px;
    font-weight: 300;
    font-size: 16px;
    line-height: 21px;
    color: #000000;
    margin-left: 300px;
    width: 625px;
  }

  .msi-expanded-text {
    margin-left: 0px;
    width: 625px;
    margin-bottom: 85px;
  }

  .msi-data-and-definition-description {
    margin-top: 14px;
    margin-bottom: 22px;
    margin-left: 0px;
    width: 573px;
    height: 57px;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 21px;
    color: #000000;
  }



  .msi-disclosure-performance-data {
    height: 843px;
    width: 625px;
    margin-top: 25px;
    margin-left: 0px;
  }

  .msi-border-performanance-data-definitions {
    border-top: #5e5e5e solid 1px;
    width: 625px;
    margin-left: 0px;
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .msi-disclosure-performance-data-first {
    width: 625px;
    margin-bottom: 10px;
    font-weight: 300;
    font-size: 16px;
    line-height: 21px;
    color: #000000;
    margin-left: 0px;
  }

  .msi-disclosure-graph {
    margin-bottom: 20px;
    width: 690px;
    max-height: 522px;
  }


  .msi-border-performanance {
    border-top: #5e5e5e solid 1px;
    width: 625px;
    margin-top: 10px;
    margin-bottom: 0px;
    margin-left: 0px;
  }

}

.mdi-disclosure-link {
  text-decoration: none;
  width: 97px;
  height: 31px;
  padding-top: 0px;
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 21px;
  color: #5e5e5e;
  border-bottom: none;
}

.msi-disclosure-link {
  text-decoration: none;
  width: 97px;
  height: 31px;
  padding-top: 0px;
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 21px;
  color: #5e5e5e;
  border-bottom: none;
}

.msi-disclosure-link {
  margin-top: 0px;
  width: 100%;
  box-shadow: inset 0 -4px 0 0 black;
  position: relative;
  padding-bottom: 10px;
}





/* .msi-collapsible-first :focus {
box-shadow: none !important;
} */

.msi-disclosure-title-div.mds-button--flat {
  padding: 0px;
}

.superscript-data {
  font-size: 10px;
  font-weight: 500;
}

.mds-button--flat {
  padding: 0px 0;
}

.msi-left-side-nav-performance {
  /* padding-bottom: 13px; */
}

.data-definition-content {
  margin-left: 15px;
  margin-bottom: 21px;
  margin-top: 13px;
}
</style>
<style>
.msi-leftpanel-style span:focus {
  box-shadow: none !important;

}

.responsive-popover-table-group-item span:focus {
  box-shadow: none !important;

}

.responsive-popover-table-group-item-performance span:focus {
  box-shadow: none !important;

}

.responsive-popover-table-group-item>span>div>div:nth-child(2) {
  height: 34px;
}

.responsive-popover-table-group-item {
  padding-bottom: 3px;
  padding-top: 3px;
}

.msi-glow-effect-list>li:nth-child(1) {
  padding-bottom: 0px;
  padding-top: 0px;
}

.responsive-popover-table-group-item>span>div:nth-child(1)>div:nth-child(2)>div>span {
  font-size: 20px;
  line-height: 26px;
  font-weight: 300;
}

.responsive-popover-table-group-item-performance>span>div>div:nth-child(2) {
  height: 34px;
}

.responsive-popover-table-group-item-performance>span>div:nth-child(1)>div:nth-child(2)>div>span {
  font-size: 20px;
  line-height: 26px;
  font-weight: 300;
}

.responsive-popover-table-group-item-performance>span>div>div:nth-child(1) {
  padding-top: 5px;
}

.msi-collapsible-first div:focus {
  box-shadow: none !important;
}

.msi-disclosure-drop-down {
  margin-left: 30px;
}
</style>