
<template>
    <div class="mainFooterCls">
      <div class="divTopBorder"></div>
  
      <div class="footer-Main-class">
        <div>
          <img
            class="footer-morningstar-logo"
            src="../../../assets/Images/morningstar_footer.svg"
            alt=""
            style="height: 28px"
          />
        </div>
        <div class="contentText">
          <mds-layout-grid>
            <mds-row>
              <mds-col :cols="12" gutter="none">
                <p style="text-align: left" class="footer-first-para">
                  ©{{ currentYear }} Morningstar, Inc. All rights reserved. Any
                  opinions, recommendations, or information contained herein: (i)
                  is for educational purposes only; (ii) is not guaranteed to be
                  accurate, complete, or timely; (iii) has not been tailored to
                  suit any particular person’s portfolio or holdings; and (iv)
                  should not be construed as investment advice of any kind.
                  Neither Morningstar nor any of its agents shall have any
                  liability with respect to such opinions, recommendations, or
                  information. This publication is intended to provide information
                  to assist investors in making their own investment decisions,
                  not to provide investment advice to any specific investor.
                  Therefore, investments discussed herein may not be suitable for
                  all investors; investors must exercise their own independent
                  judgment as to the suitability of such investments in the light
                  of their own investment objectives, experience, taxation status
                  and financial position. Morningstar has not given its consent to
                  be deemed an “expert” under the federal Securities Act of 1933.
                  Before making any investment decisions, consult with your
                  financial advisor and obtain and carefully read all relevant
                  issue documents (e.g., prospectus) pertaining to the security
                  concerned.
                </p>
                <p style="text-align: left" class="footer-second-para">
                  Investments in securities are subject to market and other risks
                  and there is no assurance or guarantee that the intended
                  investment objectives will be achieved. A security’s investment
                  return and an investor’s principal value will fluctuate so that,
                  when redeemed, an investor’s shares may be worth more or less
                  than their original cost. A security’s current investment
                  performance may be lower or higher than the investment
                  performance noted within the report. Investments in securities
                  involve risk and may not always be profitable. Indexes noted
                  within the report are unmanaged, their returns do not include
                  payment of any sales charges or fees an investor would pay to
                  purchase securities, and cannot be invested in directly. Past
                  performance is no guarantee of future results.
                </p>
                <p style="text-align: left" class="last-para-footer-text">
                  This site is protected by reCAPTCHA and the
                  <a
                    href="https://policies.google.com/privacy"
                    target="_blank"
                    class="footer-link-design"
                  >
                    Google Privacy Policy</a
                  >
                  and
                  <a
                    href="https://policies.google.com/terms"
                    target="_blank"
                    class="footer-link-design"
                    >Terms of Service</a
                  >
                  apply.
                </p>
              </mds-col>
            </mds-row>
          </mds-layout-grid>
  
          <div v-if="isResponsive">
            <div class="contact-us-div">
              <a @click="toggle = !toggle" @keydown.enter="toggle = !toggle" class="spanLinkStyle-responsive footer-link-design"
                >Contact Us</a
              >
              <router-link
                class="spanLinkStyle-responsive"
                style="margin-left: 20px"
                target="_blank"
                :to="{name:'user-agreement'}"
                >Terms Of Use</router-link
              >
              <a
                class="spanLinkStyle-responsive"
                style="margin-left: 20px"
                target="_blank"
                href="https://www.morningstar.com/company/privacy-policy"
                >Privacy Policy</a
              >
              <a
                class="spanLinkStyle-responsive"
                style="margin-left: 20px"
                target="_blank"
                href="https://www.morningstar.com/company/accessibility"
                >Accessibility</a
              >
              <div style="margin-top: 10px">
                <router-link
                  v-if="
                    CurrentProduct.toUpperCase() !== 'MFI' &&
                    CurrentProduct.toUpperCase() !== 'MEI'
                  "
                  class="spanLinkStyle-responsive"
                  target="_blank"
                  :to="{
                    name:
                      CurrentProduct.toLowerCase() == 'mdi'
                        ? 'mdi-disclosure'
                        : 'msi-disclosure',
                    params: {
                      currentproduct:
                        CurrentProduct == undefined || CurrentProduct == ''
                          ? 'Common'
                          : CurrentProduct.toLowerCase(),
                    },
                  }"
                  >Regulatory Disclosures</router-link
                >
              </div>
            </div>
          </div>
          <div v-if="isResponsive" class="BottomDiv">
            <div class="LinkText">
              <a @click="getCurrentProduct('mfi')"  @keydown.enter="getCurrentProduct('mfi')" class="spanLinkStyle-responsive footer-link-design" tabindex="0" style="margin-left: 20px">FundInvestor</a>
              <a
              tabindex="0"
                @click="getCurrentProduct('msi')"
                @keydown.enter="getCurrentProduct('msi')"
                class="spanLinkStyle-responsive footer-link-design"
                style="margin-left: 10px"
                >StockInvestor</a
              >
              <a
              tabindex="0"
                @click="getCurrentProduct('mdi')"
                @keydown.enter="getCurrentProduct('mdi')"
                class="spanLinkStyle-responsive footer-link-design"
                style="margin-left: 10px"
                >DividendInvestor</a
              >
              <a
              tabindex="0"
                @click="getCurrentProduct('mei')"
                @keydown.enter="getCurrentProduct('mei')"
                class="spanLinkStyle-responsive footer-link-design"
                style="margin-left: 10px"
                >ETFInvestor</a
              >
            </div>
          </div>
        </div>
      </div>
  
      
      <div class="background-footer-div" >
          <mds-layout-grid>
            <mds-row>
              <mds-col :cols="12" class="footer-leftpart" gutter="none">
                <div class="LinkText" v-if="!isResponsive">
                  <a @click="getCurrentProduct('mfi')" @keydown.enter="getCurrentProduct('mfi')" class="spanLinkStyle-fund footer-link-design"  tabindex="0"
                    >FundInvestor</a
                  >
                  <a
                  tabindex="0"
                    @click="getCurrentProduct('msi')"  @keydown.enter="getCurrentProduct('msi')"
                    class="spanLinkStyle footer-link-design"
                    >StockInvestor</a
                  >
                  <a
                  tabindex="0"
                    @click="getCurrentProduct('mdi')"  @keydown.enter="getCurrentProduct('mdi')"
                    class="spanLinkStyle footer-link-design"
                    >DividendInvestor</a
                  >
                  <a
                  tabindex="0"
                    @click="getCurrentProduct('mei')"  @keydown.enter="getCurrentProduct('mei')"
                    class="spanLinkStyle footer-link-design"
                    >ETFInvestor</a
                  >
                 
                  <a
                  tabindex="0"
                  v-if="
                      CurrentProduct.toUpperCase() !== 'MFI' &&
                      CurrentProduct.toUpperCase() !== 'MEI'
                    "
                    @click="toggle = !toggle"
                    @keydown.enter="toggle = !toggle"
                    
                    class="spanLinkStyle-contact-us footer-link-design"
                    >Contact Us</a
                  >
                  <router-link
                  v-if="
                      CurrentProduct.toUpperCase() !== 'MFI' &&
                      CurrentProduct.toUpperCase() !== 'MEI'
                    "
                    class="spanLinkStyle"
                    
                    target="_blank"
                    :to="{name:'user-agreement'}"
                    >Terms Of Use</router-link
                  >
                  <a
                  v-if="
                      CurrentProduct.toUpperCase() !== 'MFI' &&
                      CurrentProduct.toUpperCase() !== 'MEI'
                    "
                    class="spanLinkStyle"
                   
                    target="_blank"
                    href="https://www.morningstar.com/company/privacy-policy"
                    >Privacy Policy</a
                  >
                  <a
                  v-if="
                      CurrentProduct.toUpperCase() !== 'MFI' &&
                      CurrentProduct.toUpperCase() !== 'MEI'
                    "
                    class="spanLinkStyle"
                 
                    target="_blank"
                    href="https://www.morningstar.com/company/accessibility"
                    >Accessibility</a
                  >


                  

                  <router-link
                    v-if="
                      CurrentProduct.toUpperCase() !== 'MFI' &&
                      CurrentProduct.toUpperCase() !== 'MEI'
                    "
                    class="spanLinkStyle"
                 
                    target="_blank"
                    :to="{
                      name:
                        CurrentProduct.toLowerCase() == 'mdi'
                          ? 'mdi-disclosure'
                          : 'msi-disclosure',
                      params: {
                        currentproduct:
                          CurrentProduct == undefined || CurrentProduct == ''
                            ? 'Common'
                            : CurrentProduct.toLowerCase(),
                      },
                    }"
                    >Regulatory Disclosures</router-link
                  >
                  <a
                  tabindex="0"
                  v-if="
                      CurrentProduct.toUpperCase() == 'MFI' ||
                      CurrentProduct.toUpperCase() == 'MEI'
                    "
                    @click="toggle = !toggle"
                    @keydown.enter="toggle = !toggle"
                    class="spanLinkStyle-contact-us-mfi-and-etf footer-link-design"
                    >Contact Us</a
                  >
                  <router-link
                  v-if="
                      CurrentProduct.toUpperCase() == 'MFI' ||
                      CurrentProduct.toUpperCase() == 'MEI'
                    "
                    class="spanLinkStyle"
                    target="_blank"
                    :to="{name:'user-agreement'}"
                    >Terms Of Use</router-link
                  >
                  <a
                  v-if="
                      CurrentProduct.toUpperCase() == 'MFI' ||
                      CurrentProduct.toUpperCase() == 'MEI'
                    "
                    class="spanLinkStyle"                  
                    target="_blank"
                    href="https://www.morningstar.com/company/privacy-policy"
                    >Privacy Policy</a
                  >
                  <a
                  v-if="
                      CurrentProduct.toUpperCase() == 'MFI' ||
                      CurrentProduct.toUpperCase() == 'MEI'
                    "
                    class="spanLinkStyle"
                    target="_blank"
                    href="https://www.morningstar.com/company/accessibility"
                    >Accessibility</a
                  >
                </div>               
              </mds-col>
            </mds-row>
          </mds-layout-grid>
      </div>
      <mds-modal v-model="toggle" size="medium" width="600px" aria-label="Contact Us Modal" class="footer-link-contact-us-modal">
        <mds-button-container right-aligned class="contact-us-modal-closing-icon">
          <mds-button @click="toggle = !toggle" variation="icon-only" icon="remove">Close</mds-button>
        </mds-button-container>
        <div class="contact-us-modal-content-container">
          <div>
            <div class="contact-us-modal-border-seperator"></div>
            <div class="contanct-us-modal-headings">Product Support</div>
            <div class="contanct-us-modal-sub-headings">Subscription Support</div>
            <div class="contanct-us-modal-text-content spacing-between-content">Phone: 1-800-957-6021 | Mon-Fri 8:30AM-5PM CST</div>

            
            <div class="contanct-us-modal-sub-headings">Technical Support</div>
            <div class="contanct-us-modal-text-content">Phone: 1-312-424-4288 | Mon-Fri 8AM-6PM CST </div>
            <div class="contanct-us-modal-text-content spacing-between-last-content-and-border"><a href="mailto:newslettersupport@morningstar.com">E-mail: newslettersupport@morningstar.com</a></div>
          </div>

          <div>
            <div class="contact-us-modal-border-seperator"></div>
            <div class="contanct-us-modal-headings">Product Sales</div>
            <div class="contanct-us-modal-sub-headings">Subscription Inquiries</div>
            <div class="contanct-us-modal-text-content">Phone: 1-866-608-9570 Mon–Fri 8AM–5PM CST</div>
            <div class="contanct-us-modal-text-content spacing-between-last-content-and-border"><a href="mailto:newslettersupport@morningstar.com">E-mail: newslettersupport@morningstar.com</a></div>
          </div>

          <div>
            <div class="contact-us-modal-border-seperator"></div>
            <div class="contanct-us-modal-headings">Contact The Editors</div>
            <div class="contanct-us-modal-sub-headings">FundInvestor Editor</div>
            <div class="contanct-us-modal-text-content spacing-between-content"><a href="mailto:russel.kinnel@morningstar.com">russel.kinnel@morningstar.com</a></div>

            <div class="contanct-us-modal-sub-headings">StockInvestor Editor</div>
            <div class="contanct-us-modal-text-content spacing-between-content"><a href="mailto:msi@morningstar.com">msi@morningstar.com</a></div>

            <div class="contanct-us-modal-sub-headings">DividendInvestor Editor</div>
            <div class="contanct-us-modal-text-content spacing-between-content"><a href="mailto:mdi@morningstar.com">mdi@morningstar.com</a></div>

            <div class="contanct-us-modal-sub-headings">ETFInvestor Editor</div>
            <div class="contanct-us-modal-text-content"><a href="mailto:bryan.armour@morningstar.com">bryan.armour@morningstar.com</a></div>
          </div>

        </div>
      </mds-modal>
      <common-overlay v-if="overlayStatus" :current-product="productSelected" :overlay-status="overlayStatus" @resetOverlayStatusValue="resetOverlayStatus"></common-overlay>
    </div>
  </template>
      
      <script>
  import MdsModal from '@mds/modal';
  import { MdsButton, MdsButtonContainer } from '@mds/button';
  import { MdsLayoutGrid, MdsRow, MdsCol } from "@mds/layout-grid";
  import CommonOverlay from "@/components/Common/Overlay/CommonOverlay.vue"
  export default {
    name: "footer-section",
    components: {
      MdsModal,
      MdsButton, 
      MdsButtonContainer,
      MdsLayoutGrid,
      MdsRow,
      MdsCol,
      CommonOverlay,
    },
    data() {
      return {
        currentYear: "",
        toggle: false,
        CurrentProduct: "",
        isResponsive: null,
        viewPortWidth: null,
        productSelected:'',
        overlayStatus:false,
        MEIAccess: false,
        MDIAccess: false,
        MSIAccess: false,
        MFIAccess: false,
      };
    },
    mounted() {
      this.currentYear = new Date().getFullYear();
      if (this.$session.get("current-product")) {
        this.CurrentProduct = this.$session.get("current-product");
      }
    },
  
    methods: {
      checkViewPortSize() {
        this.viewPortWidth = window.innerWidth;
        if (this.viewPortWidth < 601) {
          this.isResponsive = true;
        } else {
          this.isResponsive = false;
        }
      },
      getCurrentProduct(productName){
        if(this.$session.exists() && this.$store.state.user.LoggedIn){
          const userProductAccess = this.$auth0.user["https://morningstar.com/uim_roles"];
          if(userProductAccess != undefined && userProductAccess !=null) {
                this.MEIAccess = userProductAccess.includes("EI_MEMBER");
                this.MDIAccess = userProductAccess.includes("DI_MEMBER");
                this.MSIAccess = userProductAccess.includes("SI_MEMBER");
                this.MFIAccess = userProductAccess.includes("FI_MEMBER");
          }
          if (productName.toUpperCase() == 'MFI' && this.MFIAccess){
            window.scrollTo(0,0);
            this.redirectToIndividualHome(productName);
          }
          else if(productName.toUpperCase() == 'MSI' && this.MSIAccess){
            window.scrollTo(0,0);
            this.redirectToIndividualHome(productName);
          }
          else if(productName.toUpperCase() == 'MDI' && this.MDIAccess){
            window.scrollTo(0,0);
            this.redirectToIndividualHome(productName);
          }
          else if(productName.toUpperCase() == 'MEI' && this.MEIAccess){
            window.scrollTo(0,0);
            this.redirectToIndividualHome(productName);
          }
          else{
            this.productSelected = productName;
            this.overlayStatus = true;
          } 
        }
        else
        {
          this.productSelected = productName;
          this.overlayStatus = true;
        }
      },
      resetOverlayStatus(toggleValue){
        this.overlayStatus = toggleValue;
      },
      redirectToIndividualHome(productType){
        this.$router.push({
          name: 'home',
          params: {
            currentproduct: productType
          },
        })
      }
    },
  
    created() {
      window.addEventListener("resize", this.checkViewPortSize);
      this.checkViewPortSize();
    },
    destroyed() {
      window.removeEventListener("resize", this.checkViewPortSize);
    },
  };
  </script>
      
      <style scoped>
  .mainFooterCls {
    width: 100%;
  }

  .footer-second-para{
    margin-bottom: 20px;
    margin-top: 20px;
  }
  .spanLinkStyle-contact-us-mfi-and-etf{
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #1e1e1e;
    padding-left: 48.4%;
  }
  
  .divTopBorder {
    border: solid 1px #e5e5e5;
  }
  .footer-leftpart {
    padding-right: 0px;
    padding-left: 0px;
  }
  
  .background-footer-div{
    background-color: #f2f2f2; 
    width: 100%; 
    height: 60px;
  }
  .imgFooter1 {
    margin-top: 25px;
  }
  
  .contentText {
    margin-top: 20.72px;
    font-family: "UniversNext for MORN";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    margin-bottom: 0px;
    color: #1e1e1e;
    margin-left: auto;
    margin-right: auto;
  }
  
  .BottomDiv {
    margin-top: 28px;
    background-color: #f2f2f2;
    width: 100%;
    height: 60px;
    margin-left: 0%;
  }
  
  .LinkText {
    padding-top: 21px;
    margin: auto;
    background-color: #f2f2f2;
    padding-bottom: 21px;
    width: 1280px;
  }
  
  .LinkText-one {
    background-color: #f2f2f2;
    padding-top: 21px;
    margin: auto;
    padding-bottom: 21px;
  }
  
  .spanLinkStyle {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #1e1e1e;
    margin-left: 20px;
  }
  
  .spanLinkStyle-responsive{
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #1e1e1e;
  }
  
  .spanLinkStyle-contact-us{
      font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #1e1e1e;
    padding-left: 483px;
  }
  .spanLinkStyle-fund{
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #1e1e1e;
    margin-left: 0px;
  }
  .footer-link-design {
    color: #000000;
    text-decoration: underline;
    border-bottom: none;
  }
  
  .footer-link-design:hover {
    text-decoration: underline;
    border-bottom: none;
    cursor:pointer;
  }
  
  .footer-Main-class {
    /*margin: auto;
  
    margin-top: 25px;
    padding: 0px;
    max-width: 1290px;*/
    margin: 25px auto 0 auto;
    max-width: 1280px;
  }
  
  .last-para-footer-text {
    padding-bottom: 20px;
  }
  .contact-us-modal-closing-icon{
    margin-bottom:8px;
  }
  .contact-us-modal-border-seperator{
    height:1px;
    background-color:#808080;
  }
  .contanct-us-modal-headings{
    color:#1E1E1E;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 21px;
    margin-top:8px;
    margin-bottom:22px;
  }
  .contanct-us-modal-sub-headings{
    color: #1E1E1E;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 18px;
  }
  .contanct-us-modal-text-content a{
    color:#1E1E1E;
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: 21px;
    text-decoration-line: underline;
  }
  .spacing-between-content{
    margin-bottom:18px;
  }
  .spacing-between-last-content-and-border{
    margin-bottom:30px;
  }
  
  @media only screen and (max-width: 600px) {
    .footer-Main-class {
    }

    .background-footer-div{
    display: none;
  }

    .LinkText {
    padding-top: 21px;
    margin: auto;
    background-color: #f2f2f2;
    padding-bottom: 21px;
    width: 100%;
  }
  
    .last-para-footer-text {
      padding-bottom: 20px;
      padding-top: 20px;
      margin-left: 4.5%;
      margin-right: 4%;
    }
  
    .footer-second-para {
      padding-top: 20px;
      margin-left: 4.5%;
      margin-right: 4%;
    }
    
  
    .footer-first-para {
      margin-left: 4.5%;
      margin-right: 4%;
    }
  
    .footer-morningstar-logo {
      margin-left: 4.5%;
    }
  
    .contact-us-div {
      margin-left: 4.5%;
      margin-right: 4%;
    }
  
  }

  @media only screen and (min-width: 700px) and (max-width: 1280px) {
    .last-para-footer-text {
      padding-bottom: 20px;
      padding-top: 20px;
      margin-left: 5%;
      margin-right: 5%;
    }
  
    .footer-second-para {
      padding-top: 20px;
      margin-left: 5%;
      margin-right: 5%;
    }
    
  
    .footer-first-para {
      margin-left: 5%;
      margin-right: 5%;
    }
  
    .footer-morningstar-logo {
      margin-left: 5%;
    }
  
    .contact-us-div {
      margin-left: 5%;
      margin-right: 5%;
    }
  }

  @media only screen and (min-width: 600px) and (max-width: 700px){
  .last-para-footer-text {
      padding-bottom: 20px;
      padding-top: 20px;
      margin-left: 5%;
      margin-right: 5%;
    }
  
    .footer-second-para {
      padding-top: 20px;
      margin-left: 5%;
      margin-right: 5%;
    }
    
  
    .footer-first-para {
      margin-left: 5%;
      margin-right: 5%;
    }
  
    .footer-morningstar-logo {
      margin-left: 5%;
    }
  
    .contact-us-div {
      margin-left: 2.5%;
      margin-right: 2.5%;
    }
}
  
  @media only screen and (min-width: 600px) and (max-width: 620px) { 
  .spanLinkStyle {
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    color: #1e1e1e;
    margin-left: 5px;
  }
  
  .LinkText {
    padding-top: 21px;
    margin: auto;
    background-color: #f2f2f2;
    padding-bottom: 21px;
    width: 100%;
  }

  .spanLinkStyle-fund{
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    color: #1e1e1e;
    margin-left: 7.2%;
  }
  .spanLinkStyle-contact-us{
      font-style: normal;
    font-weight: 400;
    font-size: 10px;
    color: #1e1e1e;
    padding-left: 2%;
  }
  .spanLinkStyle-contact-us-mfi-and-etf{
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    color: #1e1e1e;
    padding-left: 18%;
  }
  }
  @media only screen and (min-width: 620px) and (max-width: 640px) { 
    .spanLinkStyle {
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    color: #1e1e1e;
    margin-left: 5px;
  }
  
  .LinkText {
    padding-top: 21px;
    margin: auto;
    background-color: #f2f2f2;
    padding-bottom: 21px;
    width: 100%;
  }

  .spanLinkStyle-fund{
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    color: #1e1e1e;
    margin-left: 7.2%;
  }
  .spanLinkStyle-contact-us{
      font-style: normal;
    font-weight: 400;
    font-size: 10px;
    color: #1e1e1e;
    padding-left: 8%;
  }

  .spanLinkStyle-contact-us-mfi-and-etf{
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    color: #1e1e1e;
    padding-left: 20%;
  }
  }
  
  @media only screen and (min-width: 640px) and (max-width: 660px) { 
    .spanLinkStyle {
    font-style: normal;
    font-weight: 400;
    font-size: 11px;
    color: #1e1e1e;
    margin-left: 7px;
  }
  
  .LinkText {
    padding-top: 21px;
    margin: auto;
    background-color: #f2f2f2;
    padding-bottom: 21px;
    width: 100%;
  }

  .spanLinkStyle-fund{
    font-style: normal;
    font-weight: 400;
    font-size: 11px;
    color: #1e1e1e;
    margin-left: 7%;
  }
  .spanLinkStyle-contact-us{
      font-style: normal;
    font-weight: 400;
    font-size: 11px;
    color: #1e1e1e;
    padding-left: 2%;
  }

  .spanLinkStyle-contact-us-mfi-and-etf{
    font-style: normal;
    font-weight: 400;
    font-size: 11px;
    color: #1e1e1e;
    padding-left: 21%;
  }
  }

  @media only screen and (min-width: 660px) and (max-width: 680px) { 
    .spanLinkStyle {
    font-style: normal;
    font-weight: 400;
    font-size: 11px;
    color: #1e1e1e;
    margin-left: 8px;
  }
  
  .LinkText {
    padding-top: 21px;
    margin: auto;
    background-color: #f2f2f2;
    padding-bottom: 21px;
    width: 100%;
  }

  .spanLinkStyle-fund{
    font-style: normal;
    font-weight: 400;
    font-size: 11px;
    color: #1e1e1e;
    margin-left: 7%;
  }
  .spanLinkStyle-contact-us{
      font-style: normal;
    font-weight: 400;
    font-size: 11px;
    color: #1e1e1e;
    padding-left: 3%;
  }
  .spanLinkStyle-contact-us-mfi-and-etf{
    font-style: normal;
    font-weight: 400;
    font-size: 11px;
    color: #1e1e1e;
    padding-left: 22%;
  }
  }

  @media only screen and (min-width: 680px) and (max-width: 700px) { 
    .spanLinkStyle {
    font-style: normal;
    font-weight: 400;
    font-size: 11px;
    color: #1e1e1e;
    margin-left: 9px;
  }
  
  .LinkText {
    padding-top: 21px;
    margin: auto;
    background-color: #f2f2f2;
    padding-bottom: 21px;
    width: 100%;
  }

  .spanLinkStyle-fund{
    font-style: normal;
    font-weight: 400;
    font-size: 11px;
    color: #1e1e1e;
    margin-left: 7%;
  }
  .spanLinkStyle-contact-us{
      font-style: normal;
    font-weight: 400;
    font-size: 11px;
    color: #1e1e1e;
    padding-left: 4%;
  }
  .spanLinkStyle-contact-us-mfi-and-etf{
    font-style: normal;
    font-weight: 400;
    font-size: 11px;
    color: #1e1e1e;
    padding-left: 22%;
  }
  }

  @media only screen and (min-width: 700px) and (max-width: 720px) { 
    .spanLinkStyle {
    font-style: normal;
    font-weight: 400;
    font-size: 11px;
    color: #1e1e1e;
    margin-left: 10px;
  }
  
  .LinkText {
    padding-top: 21px;
    margin: auto;
    background-color: #f2f2f2;
    padding-bottom: 21px;
    width: 100%;
  }

  .spanLinkStyle-fund{
    font-style: normal;
    font-weight: 400;
    font-size: 11px;
    color: #1e1e1e;
    margin-left: 7%;
  }
  .spanLinkStyle-contact-us{
      font-style: normal;
    font-weight: 400;
    font-size: 11px;
    color: #1e1e1e;
    padding-left: 5%;
  }
  .spanLinkStyle-contact-us-mfi-and-etf{
    font-style: normal;
    font-weight: 400;
    font-size: 11px;
    color: #1e1e1e;
    padding-left: 20%;
  }
  }

  @media only screen and (min-width: 720px) and (max-width: 745px) { 
    .spanLinkStyle {
    font-style: normal;
    font-weight: 400;
    font-size: 11px;
    color: #1e1e1e;
    margin-left: 10px;
  }
  
  .LinkText {
    padding-top: 21px;
    margin: auto;
    background-color: #f2f2f2;
    padding-bottom: 21px;
    width: 100%;
  }

  .spanLinkStyle-fund{
    font-style: normal;
    font-weight: 400;
    font-size: 11px;
    color: #1e1e1e;
    margin-left: 7%;
  }
  .spanLinkStyle-contact-us{
      font-style: normal;
    font-weight: 400;
    font-size: 11px;
    color: #1e1e1e;
    padding-left: 8.5%;
  }
  .spanLinkStyle-contact-us-mfi-and-etf{
    font-style: normal;
    font-weight: 400;
    font-size: 11px;
    color: #1e1e1e;
    padding-left: 22%;
  }
  }

  @media only screen and (min-width: 745px) and (max-width: 770px) { 
    .spanLinkStyle {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    color: #1e1e1e;
    margin-left: 10px;
  }
  
  .LinkText {
    padding-top: 21px;
    margin: auto;
    background-color: #f2f2f2;
    padding-bottom: 21px;
    width: 100%;
  }

  .spanLinkStyle-fund{
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    color: #1e1e1e;
    margin-left: 7%;
  }
  .spanLinkStyle-contact-us{
      font-style: normal;
    font-weight: 400;
    font-size: 12px;
    color: #1e1e1e;
    padding-left: 5%;
  }
  .spanLinkStyle-contact-us-mfi-and-etf{
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    color: #1e1e1e;
    padding-left: 20%;
  }
  }

  @media only screen and (min-width: 770px) and (max-width: 795px) { 
    .spanLinkStyle {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    color: #1e1e1e;
    margin-left: 10px;
  }
  
  .LinkText {
    padding-top: 21px;
    margin: auto;
    background-color: #f2f2f2;
    padding-bottom: 21px;
    width: 100%;
  }

  .spanLinkStyle-fund{
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    color: #1e1e1e;
    margin-left: 7%;
  }
  .spanLinkStyle-contact-us{
      font-style: normal;
    font-weight: 400;
    font-size: 12px;
    color: #1e1e1e;
    padding-left: 7%;
  }

  .spanLinkStyle-contact-us-mfi-and-etf{
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    color: #1e1e1e;
    padding-left: 22%;
  }
  }

  @media only screen and (min-width: 795px) and (max-width: 825px) { 
    .spanLinkStyle {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    color: #1e1e1e;
    margin-left: 10px;
  }
  
  .LinkText {
    padding-top: 21px;
    margin: auto;
    background-color: #f2f2f2;
    padding-bottom: 21px;
    width: 100%;
  }

  .spanLinkStyle-fund{
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    color: #1e1e1e;
    margin-left: 7%;
  }
  .spanLinkStyle-contact-us{
      font-style: normal;
    font-weight: 400;
    font-size: 12px;
    color: #1e1e1e;
    padding-left: 10%;
  }

  .spanLinkStyle-contact-us-mfi-and-etf{
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    color: #1e1e1e;
    padding-left: 24%;
  }
  }

  @media only screen and (min-width: 825px) and (max-width: 850px) { 
    .spanLinkStyle {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    color: #1e1e1e;
    margin-left: 10px;
  }
  
  .LinkText {
    padding-top: 21px;
    margin: auto;
    background-color: #f2f2f2;
    padding-bottom: 21px;
    width: 100%;
  }

  .spanLinkStyle-fund{
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    color: #1e1e1e;
    margin-left: 7%;
  }
  .spanLinkStyle-contact-us{
      font-style: normal;
    font-weight: 400;
    font-size: 12px;
    color: #1e1e1e;
    padding-left: 12%;
  }

  .spanLinkStyle-contact-us-mfi-and-etf{
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    color: #1e1e1e;
    padding-left: 26%;
  }
  }

  @media only screen and (min-width: 850px) and (max-width: 875px) { 
    .spanLinkStyle {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    color: #1e1e1e;
    margin-left: 10px;
  }
  
  .LinkText {
    padding-top: 21px;
    margin: auto;
    background-color: #f2f2f2;
    padding-bottom: 21px;
    width: 100%;
  }

  .spanLinkStyle-fund{
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    color: #1e1e1e;
    margin-left: 7%;
  }
  .spanLinkStyle-contact-us{
      font-style: normal;
    font-weight: 400;
    font-size: 12px;
    color: #1e1e1e;
    padding-left: 14.5%;
  }
  .spanLinkStyle-contact-us-mfi-and-etf{
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    color: #1e1e1e;
    padding-left: 28%;
  }
  }

  @media only screen and (min-width: 875px) and (max-width: 900px) { 
    .spanLinkStyle {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    color: #1e1e1e;
    margin-left: 10px;
  }
  
  .LinkText {
    padding-top: 21px;
    margin: auto;
    background-color: #f2f2f2;
    padding-bottom: 21px;
    width: 100%;
  }

  .spanLinkStyle-fund{
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    color: #1e1e1e;
    margin-left: 7%;
  }
  .spanLinkStyle-contact-us{
      font-style: normal;
    font-weight: 400;
    font-size: 12px;
    color: #1e1e1e;
    padding-left: 16.5%;
  }

  .spanLinkStyle-contact-us-mfi-and-etf{
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    color: #1e1e1e;
    padding-left: 29%;
  }
  }
  @media only screen and (min-width: 900px) and (max-width: 925px) { 
    .spanLinkStyle {
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    color: #1e1e1e;
    margin-left: 10px;
  }
  
  .LinkText {
    padding-top: 21px;
    margin: auto;
    background-color: #f2f2f2;
    padding-bottom: 21px;
    width: 100%;
  }

  .spanLinkStyle-fund{
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    color: #1e1e1e;
    margin-left: 7%;
  }
  .spanLinkStyle-contact-us{
      font-style: normal;
    font-weight: 400;
    font-size: 13px;
    color: #1e1e1e;
    padding-left: 14.5%;
  }

  .spanLinkStyle-contact-us-mfi-and-etf{
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    color: #1e1e1e;
    padding-left: 27%;
  }
  }
  @media only screen and (min-width: 925px) and (max-width: 950px) { 
    .spanLinkStyle {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #1e1e1e;
    margin-left: 10px;
  }
  
  .LinkText {
    padding-top: 21px;
    margin: auto;
    background-color: #f2f2f2;
    padding-bottom: 21px;
    width: 100%;
  }

  .spanLinkStyle-fund{
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #1e1e1e;
    margin-left: 7%;
  }
  .spanLinkStyle-contact-us{
      font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #1e1e1e;
    padding-left: 10.5%;
  }
  .spanLinkStyle-contact-us-mfi-and-etf{
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #1e1e1e;
    padding-left: 25%;
  }
  }
  @media only screen and (min-width: 950px) and (max-width: 975px) { 
    .spanLinkStyle {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #1e1e1e;
    margin-left: 10px;
  }
  
  .LinkText {
    padding-top: 21px;
    margin: auto;
    background-color: #f2f2f2;
    padding-bottom: 21px;
    width: 100%;
  }

  .spanLinkStyle-fund{
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #1e1e1e;
    margin-left: 6.5%;
  }
  .spanLinkStyle-contact-us{
      font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #1e1e1e;
    padding-left: 12.5%;
  }

  .spanLinkStyle-contact-us-mfi-and-etf{
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #1e1e1e;
    padding-left: 27%;
  }
  }
  @media only screen and (min-width: 975px) and (max-width: 1000px) { 
    .spanLinkStyle {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #1e1e1e;
    margin-left: 10px;
  }
  
  .LinkText {
    padding-top: 21px;
    margin: auto;
    background-color: #f2f2f2;
    padding-bottom: 21px;
    width: 100%;
  }

  .spanLinkStyle-fund{
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #1e1e1e;
    margin-left: 6.5%;
  }
  .spanLinkStyle-contact-us{
      font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #1e1e1e;
    padding-left: 14.5%;
  }

  .spanLinkStyle-contact-us-mfi-and-etf{
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #1e1e1e;
    padding-left: 28%;
  }
  }
  @media only screen and (min-width: 1000px) and (max-width: 1035px) { 
    .spanLinkStyle {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #1e1e1e;
    margin-left: 10px;
  }
  
  .LinkText {
    padding-top: 21px;
    margin: auto;
    background-color: #f2f2f2;
    padding-bottom: 21px;
    width: 100%;
  }

  .spanLinkStyle-fund{
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #1e1e1e;
    margin-left: 6.5%;
  }
  .spanLinkStyle-contact-us{
      font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #1e1e1e;
    padding-left: 17.5%;
  }
  .spanLinkStyle-contact-us-mfi-and-etf{
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #1e1e1e;
    padding-left: 30%;
  }
  }
  @media only screen and (min-width: 1035px) and (max-width: 1070px) { 
    .spanLinkStyle {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #1e1e1e;
    margin-left: 10px;
  }
  
  .LinkText {
    padding-top: 21px;
    margin: auto;
    background-color: #f2f2f2;
    padding-bottom: 21px;
    width: 100%;
  }

  .spanLinkStyle-fund{
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #1e1e1e;
    margin-left: 6.5%;
  }
  .spanLinkStyle-contact-us{
      font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #1e1e1e;
    padding-left: 19.5%;
  }
  .spanLinkStyle-contact-us-mfi-and-etf{
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #1e1e1e;
    padding-left: 31%;
  }
  }
  @media only screen and (min-width: 1070px) and (max-width: 1100px) { 
    .spanLinkStyle {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #1e1e1e;
    margin-left: 10px;
  }
  
  .LinkText {
    padding-top: 21px;
    margin: auto;
    background-color: #f2f2f2;
    padding-bottom: 21px;
    width: 100%;
  }

  .spanLinkStyle-fund{
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #1e1e1e;
    margin-left: 6.5%;
  }
  .spanLinkStyle-contact-us{
      font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #1e1e1e;
    padding-left: 21.5%;
  }

  .spanLinkStyle-contact-us-mfi-and-etf{
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #1e1e1e;
    padding-left: 33%;
  }
  }
  @media only screen and (min-width: 1100px) and (max-width: 1130px) { 
    .spanLinkStyle {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #1e1e1e;
    margin-left: 10px;
  }
  
  .LinkText {
    padding-top: 21px;
    margin: auto;
    background-color: #f2f2f2;
    padding-bottom: 21px;
    width: 100%;
  }

  .spanLinkStyle-fund{
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #1e1e1e;
    margin-left: 6.5%;
  }
  .spanLinkStyle-contact-us{
      font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #1e1e1e;
    padding-left: 23.5%;
  }

  .spanLinkStyle-contact-us-mfi-and-etf{
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #1e1e1e;
    padding-left: 34.5%;
  }
  }
  @media only screen and (min-width: 1130px) and (max-width: 1160px) { 
    .spanLinkStyle {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #1e1e1e;
    margin-left: 10px;
  }
  
  .LinkText {
    padding-top: 21px;
    margin: auto;
    background-color: #f2f2f2;
    padding-bottom: 21px;
    width: 100%;
  }

  .spanLinkStyle-fund{
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #1e1e1e;
    margin-left: 6.5%;
  }
  .spanLinkStyle-contact-us{
      font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #1e1e1e;
    padding-left: 25%;
  }
  .spanLinkStyle-contact-us-mfi-and-etf{
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #1e1e1e;
    padding-left: 36%;
  }
  }
  @media only screen and (min-width: 1160px) and (max-width: 1190px) { 
    .spanLinkStyle {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #1e1e1e;
    margin-left: 10px;
  }
  
  .LinkText {
    padding-top: 21px;
    margin: auto;
    background-color: #f2f2f2;
    padding-bottom: 21px;
    width: 100%;
  }

  .spanLinkStyle-fund{
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #1e1e1e;
    margin-left: 6.5%;
  }
  .spanLinkStyle-contact-us{
      font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #1e1e1e;
    padding-left: 26.5%;
  }
  .spanLinkStyle-contact-us-mfi-and-etf{
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #1e1e1e;
    padding-left: 37.5%;
  }
  }
  @media only screen and (min-width: 1190px) and (max-width: 1220px) { 
    .spanLinkStyle {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #1e1e1e;
    margin-left: 10px;
  }
  
  .LinkText {
    padding-top: 21px;
    margin: auto;
    background-color: #f2f2f2;
    padding-bottom: 21px;
    width: 100%;
  }

  .spanLinkStyle-fund{
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #1e1e1e;
    margin-left: 6.5%;
  }
  .spanLinkStyle-contact-us{
      font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #1e1e1e;
    padding-left: 28.1%;
  }

  .spanLinkStyle-contact-us-mfi-and-etf{
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #1e1e1e;
    padding-left: 39%;
  }
  }
  @media only screen and (min-width: 1220px) and (max-width: 1240px) { 
    .spanLinkStyle {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #1e1e1e;
    margin-left: 10px;
  }
  
  .LinkText {
    padding-top: 21px;
    margin: auto;
    background-color: #f2f2f2;
    padding-bottom: 21px;
    width: 100%;
  }

  .spanLinkStyle-fund{
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #1e1e1e;
    margin-left: 6.5%;
  }
  .spanLinkStyle-contact-us{
      font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #1e1e1e;
    padding-left: 29.5%;
  }

  .spanLinkStyle-contact-us-mfi-and-etf{
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #1e1e1e;
    padding-left: 39.5%;
  }
  }
  @media only screen and (min-width: 1240px) and (max-width: 1260px) { 
    .spanLinkStyle {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #1e1e1e;
    margin-left: 10px;
  }
  
  .LinkText {
    padding-top: 21px;
    margin: auto;
    background-color: #f2f2f2;
    padding-bottom: 21px;
    width: 100%;
  }

  .spanLinkStyle-fund{
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #1e1e1e;
    margin-left: 6.5%;
  }
  .spanLinkStyle-contact-us{
      font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #1e1e1e;
    padding-left: 30.5%;
  }
  .spanLinkStyle-contact-us-mfi-and-etf{
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #1e1e1e;
    padding-left: 40.5%;
  }
  }

  @media only screen and (min-width: 1260px) and (max-width: 1280px) { 
    .spanLinkStyle {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #1e1e1e;
    margin-left: 10px;
  }
  
  .LinkText {
    padding-top: 21px;
    margin: auto;
    background-color: #f2f2f2;
    padding-bottom: 21px;
    width: 100%;
  }

  .spanLinkStyle-fund{
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #1e1e1e;
    margin-left: 6.5%;
  }
  .spanLinkStyle-contact-us{
      font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #1e1e1e;
    padding-left: 31.5%;
  }

  .spanLinkStyle-contact-us-mfi-and-etf{
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #1e1e1e;
    padding-left: 41.2%;
  }
  }
  
  @media only screen and (min-width: 1300px) and (max-width: 12800px) {
    .last-para-footer-text {
      padding-bottom: 20px;
      padding-top: 20px;
      margin-left: 0.4%;
      margin-right: 0.5%;
    }
  
    .footer-second-para {
      padding-top: 20px;
      margin-left: 0.4%;
      margin-right: 0.5%;
    }
    
  
    .footer-first-para {
      margin-left: 0.4%;
      margin-right: 0.5%;
    
    }
  
    .footer-morningstar-logo {
      margin-left: 0.4%;
    }
  
   
  } 

  @media only screen and (min-width: 1280px) and (max-width: 1300px) {
    .last-para-footer-text {
      padding-bottom: 20px;
      padding-top: 20px;
      margin-left: 0%;
      margin-right: 0%;
    }
  
    .footer-second-para {
      padding-top: 20px;
      margin-left: 0%;
      margin-right: 0%;
    }
    
  
    .footer-first-para {
      margin-left: 0%;
      margin-right: 0%;
    
    }
  
    .footer-morningstar-logo {
      margin-left: 0%;
    }
  
   
  } 
  
  </style>
  <style>
    .footer-link-contact-us-modal .mds-modal__content{
      padding:10px 40px 40px 40px;
      overflow-y:scroll;
    }
    .footer-link-contact-us-modal .mds-modal__content::-webkit-scrollbar {
        height: 6px;
        width: 6px;
    }

    .footer-link-contact-us-modal .mds-modal__content::-webkit-scrollbar-thumb {
        background: rgba(171, 171, 171, 0.3);
        border-radius: 6px;
    }
    @media only screen and (max-width:600px){
      .footer-link-contact-us-modal .mds-modal__content{
        padding:10px 20px 20px 20px;
        overflow-y:scroll;
      }
    }
  </style>
      