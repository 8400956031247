<template>
  <div>
    <div v-if="isMobileHeaderToggle"  >
      <mds-button
        v-if="this.$store.state.user.LoggedIn"
        @click="openProductSelectorresponsive()"
        icon="menu"
        variation="flat"
        size="large"
        class="menu-responsive-button"
        id="product-selector"
      ></mds-button>
      <h1
        v-if="!this.$store.state.user.LoggedIn"
        @click="openProductSelectorresponsive()"
        class="logged-out-menubar"
      ></h1>
      <div
        v-if="this.$store.state.user.LoggedIn"
        :key="this.$store.state.product.CurrentProduct"
        class="product-dropdown-responsive"
      >
        <mds-popover 
          v-model="openProductSelector"
          triggered-by="product-selector"
          :position="['bottom-right']"
          class="product-dropdown-popover-responsive"
        >
          <mds-list-group on-dark no-rules>
            <mds-button
              @click="openProductSelectorresponsiveclose()"
              variation="flat"
              size="large"
              ><mds-icon
                class="menu-bar-button"
                name="menu"
                size="large"
              ></mds-icon
            ></mds-button>


            <router-link
              class="router-link-disable"
              :to="{ name: 'home', params: { currentproduct: 'mfi' } }">
            <mds-list-group-item
              v-if="this.MFIAccess"
              :active="MainProductNonActive"
              on-dark
              size="small"
              class="top-product-selection"
            >
              <template>
                <div
                  class="top-product-main-div"
                  @click="openProductSelector = !openProductSelector"
                >
                  <div>
                    <h1 class="top-product-selected-list">FUND</h1>
                  </div>
                  <div class="investor-div">
                    <span>INVESTOR</span>
                  </div>
                </div>
              </template>
            </mds-list-group-item>
            </router-link>

            <mds-list-group-item
              v-if="!this.MFIAccess"
              exact
              :to="{
                name: 'marketing-landing',
                params: { currentproduct: 'mfi' },
              }"
              on-dark
              size="small"
              class="top-product-selection"
            >
              <template>
                <div
                  class="top-product-main-div-lock"
                  @click="openProductSelector = !openProductSelector"
                >
                  <div>
                    <h1 class="top-product-selected-list">FUND</h1>
                  </div>
                  <div class="investor-div">
                    <span>INVESTOR</span>
                  </div>
                  <mds-icon
                    class="product-lock-icon-responsive-mfi"
                    name="lock-close"
                    size="small"
                  ></mds-icon>
                </div>
              </template>
            </mds-list-group-item>

            <mds-list-group-item
              v-if="
                this.currentProductFullName != 'NEWSLETTERS HOME' &&
                this.CurrentProduct == 'mfi'
              "
              exact
              class="tab-selection"
              :to="{
                name: 'home',
                params: { currentproduct: this.CurrentProduct },
              }"
            >
              <span
                @click="openProductSelector = !openProductSelector"
                class="sub-tabs-class"
                >Home</span
              ></mds-list-group-item
            >
            <mds-list-group-item
              v-if="
                this.currentProductFullName != 'NEWSLETTERS HOME' &&
                this.CurrentProduct == 'mfi'
              "
              class="tab-selection"
              :to="{
                name: 'commentary',
                params: { currentproduct: this.CurrentProduct },
              }"
            >
              <span
                @click="openProductSelector = !openProductSelector"
                class="sub-tabs-class"
                >Commentary</span
              ></mds-list-group-item
            >
            <mds-list-group-item
              v-if="
                CurrentProduct == 'mfi' &&
                this.currentProductFullName != 'NEWSLETTERS HOME' &&
                this.CurrentProduct == 'mfi'
              "
              class="tab-selection"
              :to="{
                name: 'fundinvestor',
                params: { currentproduct: this.CurrentProduct },
              }"
            >
              <span
                @click="openProductSelector = !openProductSelector"
                class="sub-tabs-class"
                >FundInvestor 500</span
              >
            </mds-list-group-item>
            <mds-list-group-item
              v-if="
                CurrentProduct == 'mfi' &&
                this.currentProductFullName != 'NEWSLETTERS HOME' &&
                this.CurrentProduct == 'mfi'
              "
              class="tab-selection"
              :to="{
                name: 'fundselector',
                params: { currentproduct: this.CurrentProduct },
              }"
            >
              <span
                @click="openProductSelector = !openProductSelector"
                class="sub-tabs-class"
                >FundInvestor Selector</span
              >
            </mds-list-group-item>
            <mds-list-group-item
              v-if="
                this.currentProductFullName != 'NEWSLETTERS HOME' &&
                this.CurrentProduct == 'mfi'
              "
              class="tab-selection"
              :active="archiveActive"
              :to="{
                name: 'archive',
                params: { currentproduct: this.CurrentProduct },
              }"
              ><span
                @click="openProductSelector = !openProductSelector"
                class="sub-tabs-class"
                >Archive
              </span></mds-list-group-item
            >

            <!--MSI-->
            <router-link
              class="router-link-disable"
              :to="{ name: 'home', params: { currentproduct: 'msi' } }">

            <mds-list-group-item
              v-if="this.MSIAccess"
              :active="MainProductNonActive"
              on-dark
              size="small"
              class="top-product-selection"
            >
              <template>
                <div class="top-product-main-div"   @click="openProductSelector = !openProductSelector">
                  <div>
                    <h1 class="top-product-selected-list">STOCK</h1>
                  </div>
                  <div class="investor-div">
                    <span>INVESTOR</span>
                  </div>
                </div>
              </template>
            </mds-list-group-item>
            </router-link>

            <mds-list-group-item
              v-if="!this.MSIAccess"
              exact
              :to="{
                name: 'marketing-landing',
                params: { currentproduct: 'msi' },
              }"
              on-dark
              size="small"
              class="top-product-selection"
            >
              <template>
                <div
                  class="top-product-main-div-lock"
                  @click="openProductSelector = !openProductSelector"
                >
                  <div>
                    <h1 class="top-product-selected-list">STOCK</h1>
                  </div>
                  <div class="investor-div">
                    <span>INVESTOR</span>
                  </div>
                  <mds-icon
                    class="product-lock-icon-responsive-msi"
                    name="lock-close"
                    size="small"
                  ></mds-icon>
                </div>
              </template>
            </mds-list-group-item>

            <mds-list-group-item
              v-if="
                this.currentProductFullName != 'NEWSLETTERS HOME' &&
                this.CurrentProduct == 'msi'
              "
              exact
              class="tab-selection"
              :to="{
                name: 'home',
                params: { currentproduct: this.CurrentProduct },
              }"
            >
              <span
                @click="openProductSelector = !openProductSelector"
                class="sub-tabs-class"
                >Home</span
              ></mds-list-group-item
            >
            <mds-list-group-item
              v-if="
                this.currentProductFullName != 'NEWSLETTERS HOME' &&
                this.CurrentProduct == 'msi'
              "
              class="tab-selection"
              :to="{
                name: 'commentary',
                params: { currentproduct: this.CurrentProduct },
              }"
            >
              <span
                @click="openProductSelector = !openProductSelector"
                class="sub-tabs-class"
                >Commentary</span
              ></mds-list-group-item
            >

            <mds-list-group-item
              v-if="
                CurrentProduct == 'msi' &&
                this.currentProductFullName != 'NEWSLETTERS HOME'
              "
              class="tab-selection"
              :to="{
                name: 'tortoisePort',
                params: { currentproduct: this.CurrentProduct },
              }"
            >
              <span
                @click="openProductSelector = !openProductSelector"
                class="sub-tabs-class"
                >Tortoise Portfolio</span
              >
            </mds-list-group-item>
            <mds-list-group-item
              v-if="
                CurrentProduct == 'msi' &&
                this.currentProductFullName != 'NEWSLETTERS HOME'
              "
              class="tab-selection"
              :to="{
                name: 'harePort',
                params: { currentproduct: this.CurrentProduct },
              }"
            >
              <span
                @click="openProductSelector = !openProductSelector"
                class="sub-tabs-class"
                >Hare Portfolio</span
              >
            </mds-list-group-item>
            <mds-list-group-item
              v-if="
                CurrentProduct == 'msi' &&
                this.currentProductFullName != 'NEWSLETTERS HOME'
              "
              :to="{ name: 'TortoiseAndHareWatchlist', params: { currentproduct: this.CurrentProduct }}"
              class="tab-selection"
            >
              <span
                @click="openProductSelector = !openProductSelector"
                class="sub-tabs-class"
                >Tortoise & Hare Watchlist</span
              >
            </mds-list-group-item>
            <mds-list-group-item
              v-if="
                CurrentProduct == 'msi' &&
                this.currentProductFullName != 'NEWSLETTERS HOME'
              "
              class="tab-selection"
              :to="{
                name: 'analyst',
                params: { currentproduct: this.CurrentProduct },
              }"
            >
              <span
                @click="openProductSelector = !openProductSelector"
                class="sub-tabs-class"
                >Analyst Reports</span
              >
            </mds-list-group-item>
            <mds-list-group-item
              v-if="
                this.currentProductFullName != 'NEWSLETTERS HOME' &&
                this.CurrentProduct == 'msi'
              "
              class="tab-selection"
              :active="archiveActive"
              :to="{
                name: 'archive',
                params: { currentproduct: this.CurrentProduct },
              }"
              ><span
                @click="openProductSelector = !openProductSelector"
                class="sub-tabs-class"
                >Archive
              </span></mds-list-group-item
            >

            <!--MDI-->

            <router-link
              class="router-link-disable"
            :to="{ name: 'home', params: { currentproduct: 'mdi' } }">

            <mds-list-group-item
               :active="MainProductNonActive"
              v-if="this.MDIAccess"
              size="small"            
              class="top-product-selection"
            >
              <template>
                <div
                  class="top-product-main-div"
                  @click="openProductSelector = !openProductSelector"
                >
                  <div>
                    <h1 class="top-product-selected-list">DIVIDEND</h1>
                  </div>
                  <div class="investor-div">
                    <span>INVESTOR</span>
                  </div>
                </div>
              </template>
            </mds-list-group-item>
          </router-link>

            <mds-list-group-item
              v-if="!this.MDIAccess"
              exact
              :to="{
                name: 'marketing-landing',
                params: { currentproduct: 'mdi' },
              }"
              on-dark
              size="small"
              class="top-product-selection"
              
            >
              <template>
                <div
                  class="top-product-main-div-lock"
                  @click="openProductSelector = !openProductSelector"
                >
                  <div>
                    <h1 class="top-product-selected-list">DIVIDEND</h1>
                  </div>
                  <div class="investor-div">
                    <span>INVESTOR</span>
                  </div>
                  <mds-icon
                    class="product-lock-icon-responsive-mdi"
                    name="lock-close"
                    size="small"
                  ></mds-icon>
                </div>
              </template>
            </mds-list-group-item>

            <mds-list-group-item
              v-if="
                this.currentProductFullName != 'NEWSLETTERS HOME' &&
                this.CurrentProduct == 'mdi'
              "
              exact
              class="tab-selection"
              :to="{
                name: 'home',
                params: { currentproduct: this.CurrentProduct },
              }"
            
            >
              <span
                @click="openProductSelector = !openProductSelector"
                class="sub-tabs-class"
                >Home</span
              ></mds-list-group-item
            >
            <mds-list-group-item
              v-if="
                this.currentProductFullName != 'NEWSLETTERS HOME' &&
                this.CurrentProduct == 'mdi'
              "
              class="tab-selection"
              :to="{
                name: 'commentary',
                params: { currentproduct: this.CurrentProduct },
              }"
             
            >
              <span
                @click="openProductSelector = !openProductSelector"
                class="sub-tabs-class"
                >Commentary</span
              ></mds-list-group-item
            >
            <mds-list-group-item
              v-if="
                CurrentProduct == 'mdi' &&
                this.currentProductFullName != 'NEWSLETTERS HOME'
              "
              class="tab-selection"
              :to="{
                name: 'dividendSelectPort',
                params: { currentproduct: this.CurrentProduct },
              }"
            >
              <span
                @click="openProductSelector = !openProductSelector"
                class="sub-tabs-class"
              >
                Dividend Select Portfolio</span
              >
            </mds-list-group-item>
            <mds-list-group-item
              v-if="
                CurrentProduct == 'mdi' &&
                this.currentProductFullName != 'NEWSLETTERS HOME'
              "
              class="tab-selection"
              :to="{
                name: 'dividendDeferPort',
                params: { currentproduct: this.CurrentProduct },
              }"
            >
              <span
                @click="openProductSelector = !openProductSelector"
                class="sub-tabs-class"
                style="margin-top:15px; line-height: 22px;"
                >Dividend Select Deferred </span
              ><span
                @click="openProductSelector = !openProductSelector"
                class="sub-tabs-class"
                style="padding-left: 10px; padding-bottom:15px; line-height: 10px;"
                >Portfolio</span
              >
            </mds-list-group-item>
            <mds-list-group-item
              v-if="
                CurrentProduct == 'mdi' &&
                this.currentProductFullName != 'NEWSLETTERS HOME'
              "
              class="tab-selection"
              :to="{
                name: 'incomeBellWethers',
                params: { currentproduct: this.CurrentProduct },
              }"
            >
              <span
                @click="openProductSelector = !openProductSelector"
                class="sub-tabs-class"
                >Income Bellwethers</span
              >
            </mds-list-group-item>
            <mds-list-group-item
              v-if="
                this.currentProductFullName != 'NEWSLETTERS HOME' &&
                this.CurrentProduct == 'mdi'
              "
               :to="{
                name: 'analyst',
                params: { currentproduct: this.CurrentProduct },
              }"
              class="tab-selection"
            >
              <span
                @click="openProductSelector = !openProductSelector"
                class="sub-tabs-class"
                >Analyst Reports</span
              >
            </mds-list-group-item>
            <mds-list-group-item
              v-if="
                this.currentProductFullName != 'NEWSLETTERS HOME' &&
                this.CurrentProduct == 'mdi'
              "
              class="tab-selection"
              :active="archiveActive"
              :to="{
                name: 'archive',
                params: { currentproduct: this.CurrentProduct },
              }"
              ><span
                @click="openProductSelector = !openProductSelector"
                class="sub-tabs-class"
                >Archive
              </span></mds-list-group-item
            >

            <!--ETF-->

            <router-link
              class="router-link-disable"
              :to="{ name: 'home', params: { currentproduct: 'mei' } }">
            <mds-list-group-item
              v-if="this.MEIAccess"           
              :active="MainProductNonActive"
              on-dark
              size="small"
              class="top-product-selection"
            >
              <template>
                <div
                  class="top-product-main-div"
                  @click="openProductSelector = !openProductSelector"
                >
                  <div>
                    <h1 class="top-product-selected-list">ETF</h1>
                  </div>
                  <div class="investor-div">
                    <span>INVESTOR</span>
                  </div>
                </div>
              </template>
            </mds-list-group-item>
            </router-link>

            <mds-list-group-item
              v-if="!this.MEIAccess"
              exact
              :to="{
                name: 'marketing-landing',
                params: { currentproduct: 'mei' },
              }"
              on-dark
              size="small"
              class="top-product-selection"
            >
              <template>
                <div
                  class="top-product-main-div-lock"
                  @click="openProductSelector = !openProductSelector"
                >
                  <div>
                    <h1 class="top-product-selected-list">ETF</h1>
                  </div>
                  <div class="investor-div">
                    <span>INVESTOR</span>
                  </div>
                  <mds-icon
                    class="product-lock-icon-responsive-etf"
                    name="lock-close"
                    size="small"
                  ></mds-icon>
                </div>
              </template>
            </mds-list-group-item>

            <mds-list-group-item
              v-if="
                this.currentProductFullName != 'NEWSLETTERS HOME' &&
                this.CurrentProduct == 'mei'
              "
              exact
              class="tab-selection"
              :to="{
                name: 'home',
                params: { currentproduct: this.CurrentProduct },
              }"
            >
              <span
                @click="openProductSelector = !openProductSelector"
                class="sub-tabs-class"
                >Home</span
              ></mds-list-group-item
            >
            <mds-list-group-item
              v-if="
                this.currentProductFullName != 'NEWSLETTERS HOME' &&
                this.CurrentProduct == 'mei'
              "
              class="tab-selection"
              :to="{
                name: 'commentary',
                params: { currentproduct: this.CurrentProduct },
              }"
            >
              <span
                @click="openProductSelector = !openProductSelector"
                class="sub-tabs-class"
                >Commentary</span
              ></mds-list-group-item
            >
            <mds-list-group-item
              v-if="
                CurrentProduct == 'mei' &&
                this.currentProductFullName != 'NEWSLETTERS HOME'
              "
              class="tab-selection"
              :to="{
                name: 'modelPort',
                params: { currentproduct: this.CurrentProduct },
              }"
            >
              <span
                @click="openProductSelector = !openProductSelector"
                class="sub-tabs-class"
                >Model Portfolios</span
              >
            </mds-list-group-item>
            <mds-list-group-item
              v-if="
                CurrentProduct == 'mei' &&
                this.currentProductFullName != 'NEWSLETTERS HOME'
              "
              class="tab-selection"
              :to="{
                name: 'equityWatchlist',
                params: { currentproduct: this.CurrentProduct },
              }"
            >
              <span
                @click="openProductSelector = !openProductSelector"
                class="sub-tabs-class"
                >Equity Watchlist</span
              >
            </mds-list-group-item>
            <mds-list-group-item
              v-if="
                CurrentProduct == 'mei' &&
                this.currentProductFullName != 'NEWSLETTERS HOME'
              "
              class="tab-selection"
              :to="{
                name: 'bondWatchlist',
                params: { currentproduct: this.CurrentProduct },
              }"
              ><span
                @click="openProductSelector = !openProductSelector"
                class="sub-tabs-class"
              >
                Bond Watchlist</span
              >
            </mds-list-group-item>
            <mds-list-group-item
              v-if="
                CurrentProduct == 'mei' &&
                this.currentProductFullName != 'NEWSLETTERS HOME'
              "
              class="tab-selection"
              :to="{
                name: 'analyst',
                params: { currentproduct: this.CurrentProduct },
              }"
            >
              <span
                @click="openProductSelector = !openProductSelector"
                class="sub-tabs-class"
                >Analyst Reports</span
              >
            </mds-list-group-item>
            <mds-list-group-item
              v-if="
                this.currentProductFullName != 'NEWSLETTERS HOME' &&
                this.CurrentProduct == 'mei'
              "
              class="tab-selection"
             
              :to="{
                name: 'archive',
                params: { currentproduct: this.CurrentProduct },
              }"
              ><span
                @click="openProductSelector = !openProductSelector"
                class="sub-tabs-class"
                >Archive
              </span></mds-list-group-item
            >

        

            <p class="border-top-newsletter-home"></p>
            <p
              class="newsletterhome-para"
              @click="openProductSelector = !openProductSelector"
            >
              <mds-list-group-item
                text="NEWSLETTERS HOME"
                :to="{
                  name: 'combined-home',
                  params: { currentproduct: 'Common' },
                }"
                exact
                class="newsletter-product newsletters-home-link-responsive"
                style="margin-top: 10px"
              ></mds-list-group-item>
            </p>
          </mds-list-group>
        </mds-popover>
      </div>
    </div>
  </div>
</template>

<script>
import MdsIcon from "@mds/icon";
import { MdsListGroup, MdsListGroupItem } from "@mds/list-group";
import { MdsButton } from "@mds/button";
import MdsPopover from "@mds/popover";
import { mapState } from "vuex";

export default {
  name: "resposive-header",
  components: {
    MdsButton,
    MdsIcon,
    MdsListGroup,
    MdsListGroupItem,
    MdsPopover,
  },
  data() {
    return {
      MainProductNonActive: false,
      openProductSelector: false,
      HomeActive: false,
      Categories: [],
      Products: [],
      ProductsRoles: "",
      CurrentProduct: this.$store.state.product.CurrentProduct,
      MEIAccess: false,
      MDIAccess: false,
      MSIAccess: false,
      MFIAccess: false,
      isMobileHeaderToggle: null,
      viewPortWidth: null,
      archiveActive: false,
      MfiSelector: false,
      MsiSelector: false,
      MdiSelector: false,
      EtfSelector: false,
    
    };
  },

  methods: {
    clicketf() {
      this.openProductSelector = false;
      this.EtfSelector = true;
      this, (this.MfiSelector = false);
    },

    openProductSelectorresponsive() {
      this.openProductSelector = true;
      this.$store.state.header.isResponsiveHeaderActive = true;
    },

    openProductSelectorresponsiveclose() {
      this.openProductSelector = false;
      this.$store.state.header.isResponsiveHeaderActive = false;
    },

    addProductOrder() {
      //if (this.$store.state.product.ProductOrderedArray !== []) {
      if (this.MFIAccess) {
        this.Products.push({
          name: "MFI",
          text: "FUND",
          access: true,
          product: "FundInvestor",
        });
      }
      if (this.MSIAccess) {
        this.Products.push({
          name: "MSI",
          text: "STOCK",
          access: true,
          product: "StockInvestor",
        });
      }
      if (this.MDIAccess) {
        this.Products.push({
          name: "MDI",
          text: "DIVIDEND",
          access: true,
          product: "DividendInvestor",
        });
      }
      if (this.MEIAccess) {
        this.Products.push({
          name: "MEI",
          text: "ETF",
          access: true,
          product: "ETFInvestor",
        });
      }
      if (!this.MFIAccess) {
        this.Products.push({
          name: "MFI",
          text: "FUND",
          access: false,
          product: "FundInvestor",
        });
      }
      if (!this.MSIAccess) {
        this.Products.push({
          name: "MSI",
          text: "STOCK",
          access: false,
          product: "StockInvestor",
        });
      }
      if (!this.MDIAccess) {
        this.Products.push({
          name: "MDI",
          text: "DIVIDEND",
          access: false,
          product: "DividendInvestor",
        });
      }
      if (!this.MEIAccess) {
        this.Products.push({
          name: "MEI",
          text: "ETF",
          access: false,
          product: "ETFInvestor",
        });
      }
      var MfiProducts = [];
      var MsiProducts = [];
      var MdiProducts = [];
      var MeiProducts = [];
      for (var i = 0; i < this.Products.length; i++) {
        var obj = this.Products[i];
        if (obj.name == "MFI") {
          MfiProducts.push({
            name: obj.name,
            text: obj.text,
            access: obj.access,
            product: obj.product,
          });
        }
        if (obj.name == "MSI") {
          MsiProducts.push({
            name: obj.name,
            text: obj.text,
            access: obj.access,
            product: obj.product,
          });
        }
        if (obj.name == "MDI") {
          MdiProducts.push({
            name: obj.name,
            text: obj.text,
            access: obj.access,
            product: obj.product,
          });
        }
        if (obj.name == "MEI") {
          MeiProducts.push({
            name: obj.name,
            text: obj.text,
            access: obj.access,
            product: obj.product,
          });
        }
      }
      //console.log("MfiProducts =" + MfiProducts);
      this.Products = [];
      if (MfiProducts != null && MfiProducts.length > 0) {
        this.Products.push({
          name: MfiProducts[0].name,
          text: MfiProducts[0].text,
          access: MfiProducts[0].access,
          product: MfiProducts[0].product,
        });
      }
      if (MsiProducts != null && MsiProducts.length > 0) {
        this.Products.push({
          name: MsiProducts[0].name,
          text: MsiProducts[0].text,
          access: MsiProducts[0].access,
          product: MsiProducts[0].product,
        });
      }
      if (MdiProducts != null && MdiProducts.length > 0) {
        this.Products.push({
          name: MdiProducts[0].name,
          text: MdiProducts[0].text,
          access: MdiProducts[0].access,
          product: MdiProducts[0].product,
        });
      }
      if (MeiProducts != null && MeiProducts.length > 0) {
        this.Products.push({
          name: MeiProducts[0].name,
          text: MeiProducts[0].text,
          access: MeiProducts[0].access,
          product: MeiProducts[0].product,
        });
      }
      //this.$store.state.product.ProductOrderedArray = sortProducts;
      //}
    },
  },

  computed: mapState({
    isMobileHeader: (state) => state.responsive.isMobileHeader,
    isMobileHeaderAlias: "isMobileHeader",
    isMobileHeaderState(state) {
      return state.responsive.isMobileHeader;
    },
    currentProductFullName() {
      if (this.$session.get("current-product") == "mfi") {
        return "FUND";
      } else if (this.$session.get("current-product") == "msi") {
        return "STOCK";
      } else if (this.$session.get("current-product") == "mdi") {
        return "DIVIDEND";
      } else if (this.$session.get("current-product") == "mei") {
        return "ETF";
      } else {
        return "NEWSLETTERS HOME";
      }
    },
  }),
  mounted() {
    this.isMobileHeaderToggle = this.$store.state.responsive.isMobileHeader;
    if (this.$session.exists()) {
      this.CurrentProduct =
        this.$session.get("current-product") != undefined ||
        this.$session.get("current-product") != ""
          ? this.$session.get("current-product").toLowerCase()
          : "Common";
    }

    if (this.$auth0.isAuthenticated && this.$session.exists()) {
      this.$store.state.user.LoggedIn = true;
    } else {
      this.$store.state.user.LoggedIn = false;
    }
    if (this.$store.state.user.LoggedIn) {
      this.CurrentProduct = this.$session.get("current-product");
      this.$store.state.product.CurrentProduct = this.CurrentProduct;
      this.ProductsRoles =
        this.$auth0.user["https://morningstar.com/uim_roles"];
        if(this.ProductsRoles != undefined && this.ProductsRoles !=null) {
      this.MEIAccess = this.ProductsRoles.includes("EI_MEMBER");
      this.MDIAccess = this.ProductsRoles.includes("DI_MEMBER");
      this.MSIAccess = this.ProductsRoles.includes("SI_MEMBER");
      this.MFIAccess = this.ProductsRoles.includes("FI_MEMBER");
     }
      this.addProductOrder();

      if (this.currentProductFullName !== "NEWSLETTERS HOME") {
        this.Products.splice(
          this.Products.map((product) => product.text).indexOf(
            this.currentProductFullName
          ),
          1
        );
      }
    }
    this.$forceUpdate();
  },

  watch: {
    isMobileHeaderState() {
      this.isMobileHeaderToggle = this.isMobileHeaderState;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@mds/constants";
@import "@mds/icon/src/icon_mixins";
.product-lock-icon {
  @include mds-icon-color($mds-background-color-white);
  position: absolute;
  top: 6px;
}

.menu-responsive-button {
  margin-left: 8px;
  margin-top: 0px;
}

.sub-tabs-class {
  font-weight: 300;
  font-size: 18px;
  line-height: 22px;
  color: #ffffff;
  padding-left: 10px;
}

.product-dropdown-popover {
  width: 250px !important;
  background-color: $mds-background-color-black !important;
  border-radius: 2px !important;
  height: 150px;
  top: 0px;
  margin-top: 5px;
  padding-top: 5px;
}

.product-dropdown {
  left: 5%;
  position: absolute !important;
  margin-top: 0px !important;
}

.top-product-selection {
  margin-top: 20px;
  height: 45px;
  font-style: normal !important;
  font-weight: 400 !important;
  color: #ffffff !important;
  font-size: 16px !important;
  line-height: 18px !important;
  letter-spacing: 0.15em !important;
  mix-blend-mode: normal !important;
  background-color: $mds-background-color-black !important;
  padding-left: 10px !important;
}

.newsletter-product {
  position: absolute;
  bottom: 0;
  height: 45px;
  font-style: normal !important;
  font-weight: 400 !important;
  color: #ffffff !important;
  font-size: 16px !important;
  line-height: 18px !important;
  letter-spacing: 0.15em !important;
  mix-blend-mode: normal !important;
  background-color: $mds-background-color-black !important;
  padding-left: 0px !important;
}

.product-selected-text {
  font-style: normal !important;
  font-weight: 300 !important;
  color: #ffffff !important;
  font-size: 16px !important;
  line-height: 18px !important;
  letter-spacing: 0.17em !important;
  mix-blend-mode: normal !important;
  background-color: $mds-background-color-black !important;
  border-radius: 2px !important;
  width: 250px !important;
  padding-left: 30px !important;
}

.product-dropdown-disabled-text {
  font-family: "UniversNext for MORN";
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 18px;
  letter-spacing: 0.17em;
  color: #ababab !important;
  mix-blend-mode: normal;
  flex: none;
  order: 0;
  align-self: stretch;
  padding-left: 0px !important;
}

.profile-sign-in {
  float: right;
  right: 20px;
  bottom: 7px;
}

.product-enable-text {
  color: #ffffff !important;
}

.product-disabled-text {
  color: #ababab !important;
}

//responsive

.product-lock-icon-responsive-etf {
  @include mds-icon-color($mds-background-color-white);
  position: absolute;
  top: 9px;
  margin-left: 110px;
}

.product-lock-icon-responsive-mdi {
  @include mds-icon-color($mds-background-color-white);
  position: absolute;
  top: 9px;
  margin-left: 163px;
}



.product-lock-icon-responsive-msi {
  @include mds-icon-color($mds-background-color-white);
  position: absolute;
  top: 9px;
  margin-left: 133px;
}

.product-lock-icon-responsive-mfi {
  @include mds-icon-color($mds-background-color-white);
  position: absolute;
  top: 9px;
  margin-left: 125px;
}

.product-dropdown-popover-responsive {
 border-left: 0px;
  width: 228px !important;
  background-color: $mds-background-color-black !important;
  border-radius: 2px !important;
  height: 100%;
  top: 0px !important;
  margin-top: 0px;
  padding-top: 0px  !important;
  padding-right: 0px  !important;
  left: 0px  !important;
  padding-left: 0px  !important;
  z-index: $mds-z-index-low + 2;
}

.product-dropdown-responsive {
  left: 0%;
  position: absolute !important;
  margin-top: 0px !important;
}

.product-dropdown-text-responsive {
  height: 50px;
  font-style: normal !important;
  font-weight: 300 !important;
  color: #ffffff !important;
  font-size: 16px !important;
  line-height: 18px !important;
  letter-spacing: 0.17em !important;
  mix-blend-mode: normal !important;
  background-color: $mds-background-color-black !important;
  padding-left: 10px;
  margin-left: 25px;
  padding-top: 10px;
}
.mds-list-group {
  padding-left: 0px;
}

.product-selected-text-responsive {
  height: 45px;
  font-style: normal !important;
  font-weight: 300 !important;
  color: #ffffff !important;
  font-size: 16px !important;
  line-height: 18px !important;
  letter-spacing: 0.17em !important;
  mix-blend-mode: normal !important;
  background-color: $mds-background-color-black !important;
  border: none !important;
  width: 210px !important;
  padding-left: 0px !important;
  margin-left: 25px;
}

.product-dropdown-disabled-text-responsive {
  height: 45px;
 
  font-family: "UniversNext for MORN";
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 18px;
  letter-spacing: 0.17em;
  color: #ababab !important;
  mix-blend-mode: normal;
  flex: none;
  order: 0;
  align-self: stretch;
  padding-left: 10px;
  padding-top: 10px;
}
.selected-product-responsive {
  font-style: normal !important;
  font-weight: 300 !important;
  color: #ffffff !important;
  font-size: 16px !important;
  line-height: 18px !important;
  letter-spacing: 0.17em !important;
  mix-blend-mode: normal !important;
  background-color: $mds-background-color-black !important;
  border-radius: 10px !important;
  width: 228px !important;
  padding-left: 0px !important;
}

.button--flat {
  padding-left: 0px;
}
.menu-bar-button {
  margin-left: 15px;
  margin-bottom: 0px;
  margin-top: 8px;
  padding-left: 0px;
  @include mds-icon-color($mds-background-color-white);
}

.mds-list-group-item__link-inner {
  height: 45px;
}
</style>

<style>
.MDI-selected-product > span:nth-child(1) {
  margin-right: 12px !important;
}

.MEI-selected-product > span:nth-child(1) {
  margin-right: 62px !important;
}

.MSI-selected-product > span:nth-child(1) {
  margin-right: 42px !important;
}

.MFI-selected-product > span:nth-child(1) {
  margin-right: 47px !important;
}

.Common-selected-product > span:nth-child(1) {
  margin-right: 2px !important;
}

.newsletters-home-link::before {
  border-top: solid 1px #ffffff !important;
  width: 100% !important;
  /*margin-left: 12px !important;*/
}
.mds-list-group .mds-list-group-item .mds-list-group-item__text {
  /* font-weight: 700 !important;*/
}

.newsletterhome-para {
  position: absolute;
  bottom: 0px;
}

.border-top-newsletter-home {
  bottom: 55px;
  position: absolute;
  margin-top: 0px;
  margin-bottom: -10px;
  padding-bottom: 0px;
  width: 241px;
  margin-left: -16px;
  border-top: 1px solid #ffffff;
}

.newsletter-product > a {
  width: 225.5px;
}

.newsletter-product > a > div {
  height: 45px;
  margin-left: 18px;
}

.tab-selection > a > div {
  height: 45px;
  padding-left: 24px;
}

.top-product-selection > a > div {
  height: 45px;
  padding-left: 24px;
  margin-top: -7px;
  
}

.router-link-disable > li > span {
  height: 45px;
  padding-top: 7px;
  
}


.logged-out-menubar {
  width: 39px;
}

.tab-selection {
  height: 50px;
  padding-top: 3px;
  
  padding-left: 22px;
}

.top-product-selected-list {
  font-weight: 300px;
  font-size: 16px;
  margin-top: 0px;
  margin-bottom: 0px;
}

.top-product-main-div {
  display: flex;
}

.investor-div {
  padding-top: 0px;
  padding-bottom: 0px;
}

.top-product-main-div-lock {
  padding-top: 5px;
  opacity: 0.5;
  display: flex;
}

.router-link-disable{
  text-decoration: none;
  height: 45px;
}
</style>
