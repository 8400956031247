var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.isMobileHeaderToggle)?_c('div',[_c('div',[_c('div',{class:this.CurrentProduct == 'Common' ||
    this.CurrentProduct == '' ||
    this.CurrentProduct == undefined
    ? 'header-img-logout-responsive'
    : 'header-img-logo-responsive'},[_c('img',{attrs:{"src":_vm.getHeaderImage(),"alt":_vm.CurrentProduct},on:{"click":function($event){return _vm.redirectToHomePage()}}})])])]):_c('div',[_c('div',[_c('div',{class:this.CurrentProduct == 'Common' ||
    this.CurrentProduct == '' ||
    this.CurrentProduct == undefined
    ? 'header-img-logout'
    : 'header-img-logo'},[_c('img',{attrs:{"src":_vm.getHeaderImage(),"alt":_vm.CurrentProduct},on:{"click":function($event){return _vm.redirectToHomePage()}}})])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }