<template>
    <div>
        <div v-if="this.$route.name === 'home'">
            <div class="banner-alerts-main-div" v-for="(json, index) in JsonData" :key="index">
                <div v-if="isBeforeCurrentTime(json.StartDate)"></div>
                <div v-else-if="isBeforeCurrentTime(json.EndDate)">
                    <mds-modal aria-label="Default Modal" actionRequired :width="'100%'" v-model="bannerToggle">
                        <div class="content">
                            <mds-alert variation="informational" :persistent="json.Persistent == 'true' ? true : false"
                                @mds-alert-dismissed="CloseAlert">
                                <div v-html="json.Description"></div>
                            </mds-alert>
                        </div>
                    </mds-modal>
                </div>
                <div v-else></div>
            </div>
        </div>
        <cover-images></cover-images>
        <editor-section></editor-section>
        <div v-if="this.CurrentProduct.toUpperCase() == 'MDI'">
            <mdi-performance-section></mdi-performance-section>
        </div>
        <div v-if="this.CurrentProduct.toUpperCase() == 'MSI'">
            <msi-performance-section></msi-performance-section>
        </div>
        <div v-if="this.CurrentProduct.toUpperCase() == 'MFI'">
            <mfi-performance-section></mfi-performance-section>
        </div>
        <div v-if="this.CurrentProduct.toUpperCase() == 'MEI'">
            <mei-performance-section></mei-performance-section>
        </div>
        <video-section></video-section>
    </div>
</template>

<script>
import CoverImages from "@/components/Common/Home/CoverImages.vue";
import EditorSection from "@/components/Common/Home/Editor/EditorSection.vue";
import MfiPerformanceSection from "@/components/Common/Home/Performance/MfiPerformanceSection.vue";
import VideoSection from "@/components/Common/Home/VideoSection.vue";
import MdiPerformanceSection from "@/components/Common/Home/Performance/MdiPerformanceSection.vue";
import MsiPerformanceSection from "@/components/Common/Home/Performance/MsiPerformanceSection.vue";
import MeiPerformanceSection from "@/components/Common/Home/Performance/MeiPerformanceSection.vue";
import MdsAlert from "@mds/alert";
import MdsModal from "@mds/modal";
//import { MdsButton } from "@mds/button";
import { mapState } from "vuex";
import { fetchData } from "@/utilities/API/apiUtility";
// import MdsBanner from "@mds/banner";
export default {
    name: "home",
    components: {
        CoverImages,
        EditorSection,
        MfiPerformanceSection,
        VideoSection,
        MdiPerformanceSection,
        MsiPerformanceSection,
        MeiPerformanceSection,
        MdsAlert,
        MdsModal,
        //MdsButton,
        //MdsBanner
    },
    data() {
        return {
            UlinData: false,
            bannerToggle: true,
            CurrentProduct: this.$session.get("current-product"),
            allBannersData:
                process.env.VUE_APP_BackEndAPI + process.env.VUE_APP_GET_ALL_BANNERS,
            JsonData: [],
            //BannerDiv: true,
            bannerToggleError: true,
            bannerToggleWarning: true,
            bannerToggleInfo: true,
        };
    },
    created() {
        this.GetAllBannersData();
    },
    methods: {
        isBeforeCurrentTime(dateTime) {
            return new Date() < new Date(dateTime);
        },
        CloseAlert() {
            this.bannerToggle = false;
            //this.$store.state.bannerstate.AlertBox = false;
        },
        GetAllBannersData() {
            if (this.$store.state.bannerstate.AlertBox == true) {
                fetchData(this.allBannersData).then((response) => {
                    var lstData = response.data;
                    for (var i = 0; i < lstData.length; i++) {
                        var obj = lstData[i];
                        //console.log(obj);
                        var Description = "";
                        var Alert = "";
                        var Persistent = "";
                        var StartDate = "";
                        var EndDate = "";
                        if (
                            this.CurrentProduct == "msi" &&
                            obj.IsDraft == "No" &&
                            obj.Msi == "Yes" &&
                            obj.Type == "Alert"
                        ) {
                            if (obj.Description) {
                                Description = obj.Description;
                            }
                            if (obj.Alert) {
                                //Alert = obj.Alert;
                            }
                            if (obj.Persistent) {
                                Persistent = obj.Persistent;
                            }
                            if (obj.StartDate) {
                                StartDate = obj.StartDate;
                            }
                            if (obj.EndDate) {
                                EndDate = obj.EndDate;
                            }
                            this.JsonData.push({
                                Description: Description,
                                Alert: Alert,
                                Persistent: Persistent,
                                StartDate: StartDate,
                                EndDate: EndDate,
                            });
                        } else if (
                            this.CurrentProduct == "mdi" &&
                            obj.IsDraft == "No" &&
                            obj.Mdi == "Yes" &&
                            obj.Type == "Alert"
                        ) {
                            if (obj.Description) {
                                Description = obj.Description;
                            }
                            if (obj.Alert) {
                                Alert = obj.Alert;
                            }
                            if (obj.Persistent) {
                                Persistent = obj.Persistent;
                            }
                            if (obj.StartDate) {
                                StartDate = obj.StartDate;
                            }
                            if (obj.EndDate) {
                                EndDate = obj.EndDate;
                            }
                            this.JsonData.push({
                                Description: Description,
                                Alert: Alert,
                                Persistent: Persistent,
                                StartDate: StartDate,
                                EndDate: EndDate,
                            });
                        } else if (
                            this.CurrentProduct == "mfi" &&
                            obj.IsDraft == "No" &&
                            obj.Mfi == "Yes" &&
                            obj.Type == "Alert"
                        ) {
                            if (obj.Description) {
                                Description = obj.Description;
                            }
                            if (obj.Alert) {
                                Alert = obj.Alert;
                            }
                            if (obj.Persistent) {
                                Persistent = obj.Persistent;
                            }
                            if (obj.StartDate) {
                                StartDate = obj.StartDate;
                            }
                            if (obj.EndDate) {
                                EndDate = obj.EndDate;
                            }
                            this.JsonData.push({
                                Description: Description,
                                Alert: Alert,
                                Persistent: Persistent,
                                StartDate: StartDate,
                                EndDate: EndDate,
                            });
                        } else if (
                            this.CurrentProduct == "mei" &&
                            obj.IsDraft == "No" &&
                            obj.Mei == "Yes" &&
                            obj.Type == "Alert"
                        ) {
                            if (obj.Description) {
                                Description = obj.Description;
                            }
                            if (obj.Alert) {
                                Alert = obj.Alert;
                            }
                            if (obj.Persistent) {
                                Persistent = obj.Persistent;
                            }
                            if (obj.StartDate) {
                                StartDate = obj.StartDate;
                            }
                            if (obj.EndDate) {
                                EndDate = obj.EndDate;
                            }
                            this.JsonData.push({
                                Description: Description,
                                Alert: Alert,
                                Persistent: Persistent,
                                StartDate: StartDate,
                                EndDate: EndDate,
                            });
                        }
                    }
                });
            }
        },
    },

    computed: mapState({
        extractNames() {
            return this.JsonData.map((record) => record.Description);
        },
    }),
};
</script>
<style scoped>
@media only screen and (min-width: 0px) and (max-width: 320px) {
    .content {
        position: absolute;
        top: 10%;
        left: 50%;
        transform: translate(-50%, -0%);
        /* Add styles for the content div */
    }
}

@media only screen and (min-width: 320px) and (max-width: 600px) {
    .content {
        position: absolute;
        top: 10%;
        left: 50%;
        transform: translate(-50%, -5%);
        /* Add styles for the content div */
    }
}

@media only screen and (min-width: 601px) and (max-width: 1000px) {
    .content {
        position: absolute;
        top: 10%;
        left: 50%;
        transform: translate(-50%, -15%);
        /* Add styles for the content div */
    }
}

@media only screen and (min-width: 1001px) and (max-width: 60000px) {
    .content {
        position: absolute;
        width: 500px;
        top: 10%;
        left: 50%;
        transform: translate(-50%, -80%);
        /* Add styles for the content div */
    }
}

.main-alert-class-for-banners>ul {
    content: none;
}

.banner-alerts-main-div .mds-alert .mds-alert__list li::before {
    content: none !important;
}

@media only screen and (min-width: 660px) and (max-width: 60000px) {
    .main-alert-dismiss-button {
        margin-left: 170%;
    }
}

@media only screen and (min-width: 0px) and (max-width: 660px) {
    .main-alert-dismiss-button {
        margin-left: 150%;
    }
}
</style>
<style>
.content>div>div {
    margin-right: -5px;
    background: white;
}

.content>div>div:nth-child(2) {
    background: white;
    padding-left: 10px;
}

.content {
    margin-top: 30px;
}
</style>