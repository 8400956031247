import ExcelJS from "exceljs";
import { saveAs } from "file-saver";

export async function exportToExcel(fileName, sheetName, data) {
  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet(sheetName);

  if (!data.length) {
    console.warn("No data available for export.");
    return;
  }

  // Extract headers dynamically from the first row
  const headers = Object.keys(data[0]);

  // Define columns with automatic number formatting and width adjustment
  worksheet.columns = headers.map((header) => ({
    header,
    key: header,
    width: Math.max(15, header.length + 2), // Ensures readable column width
    style: {
      numFmt: isNumericColumn(data, header)  ? !header.includes("Rating") ? "#,##0.00" : "#,##0" : undefined,
    },
  }));

  // Add formatted rows
  data.forEach((row) => {
    const formattedRow = {};
    headers.forEach((header) => {
      formattedRow[header] = isNumericColumn(data, header)
        ? parseFloat(row[header]) || 0 // Ensure numbers are correctly stored
        : row[header] || ""; // Handle missing values
    });
    worksheet.addRow(formattedRow);
  });

  // Style the header with dark grey background, white text, and bold formatting
  const headerRow = worksheet.getRow(1);
  headerRow.height = 20; // Prevent shrinking

  headerRow.eachCell((cell) => {
    cell.alignment = { horizontal: "center", vertical: "middle" }; // Center-align header text
    cell.font = { bold: true, color: { argb: "FFFFFFFF" } }; // White font color and bold text
    cell.fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "7F7F80" }, // Dark grey background
    };
  });

  // Auto-fit columns based on max cell length
  worksheet.columns.forEach((column) => {
    let maxLength = column.header.length + 2; // Minimum width
    column.eachCell({ includeEmpty: true }, (cell) => {
      if (cell.value) {
        const cellLength = cell.value.toString().length;
        if (cellLength > maxLength) {
          maxLength = cellLength;
        }
      }
    });
    column.width = Math.max(maxLength, 15); // Set width dynamically
  });

  // Save the file
  const buffer = await workbook.xlsx.writeBuffer();
  saveAs(new Blob([buffer]), fileName);
}

// Helper function to detect if a column contains only numeric values
function isNumericColumn(data, key) {
  return data.every((row) => !isNaN(parseFloat(row[key])) && isFinite(row[key]));
}