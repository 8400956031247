<template>
  <div>
    <div class="mdi-income-bellwethers-container">
      <div class="mdi-income-bellwethers-table-loader">
        <mds-loader v-if="isLoading" aria-label="Medium Loader"></mds-loader>
      </div>

      <div class="mdi-income-bellwethers-header-text">
        <div class="mdi-income-bellwethers-middle-content">
        <div class="mdi-income-bellwethers-title">Income Bellwethers</div>
        <div class="mdi-income-bellwethers-download-holding">
          <mds-button variation="flat" size="small" icon="document" @click="ExportExcel"
            class="mdi-income-bellwethers-download-data">
            Download data
          </mds-button>
        </div>
        </div>
        <div class="mdi-income-bellwethers-analyst-text">
          Click on company name for latest Morningstar Analyst Report.
        </div>   
        <div class="mdi-income-bellwethers-analyst-text-sec">
          Click on ticker for additional stock data from Morningstar.com.
        </div>     
      </div>
      <div style="padding-top:24px;">
        <mds-table fixed-first-col show-sortable style="max-height:600px;" class="mdi-income-bellwethers-data-table">
          <mds-thead fixed-header>
            <mds-th v-for="(header, index) in headers" :key="index" :right-aligned="header.align === 'right'"
              :style="header.style" :sortable="header.sortable" :sorted="header.sorted" @mds-th-sort-changed="
                handleSortEvent(index, header.fieldName, $event)
                ">
              <span v-html="header.text" class="mdi-income-bellwethers-table-header"></span>
            </mds-th>
          </mds-thead>
          <mds-tbody v-if="isDisplay">
            <mds-tr v-for="(row, index) in rows" :key="'A' + index">
              <mds-td>
                <div
                  v-if="
                    row.Symbol == 'N/A' ||
                    row.Symbol == 'NaN' ||
                    row.Symbol == '0' ||
                    row.Symbol == '' ||
                    row.Symbol == '&mdash;'||
                    row.Symbol == null||
                    row.Symbol == '&nbsp;'
                  "
                >
                  &mdash;
                </div>
                <div v-else @click="redirectToStockQuote(row.ExchangeId,row.Symbol)" class="mdi-income-bellwethers-ticker">
                
                {{ row.Symbol }}
              </div>
              </mds-td>
              <mds-td style="padding-left: 16px"
                >
                <div
                  v-if="
                    row.StandardName == 'N/A' ||
                    row.StandardName == 'NaN' ||
                    row.StandardName == '0' ||
                    row.StandardName == '' ||
                    row.StandardName == '&mdash;'||
                    row.StandardName == null||
                    row.StandardName == '&nbsp;'
                  "
                >
                  &mdash;
                </div>
                <div v-else>
                
                <a
                class="mdi-income-bellwethers-first-row"
                  v-html="row.StandardName"
                @mouseover="openMedalistReport(row.SecId,row.Symbol)"
                @touchstart="openMedalistReport(row.SecId,row.Symbol)"
                target="_blank"
                :href="CurrentURL"
                ></a>
              </div>
            </mds-td>
              <mds-td left-align style="padding-left: 25px">
                <div
                  v-if="
                    row.StockStarRating == 'N/A' ||
                    row.StockStarRating == 'NaN' ||
                    row.StockStarRating == '0' ||
                    row.StockStarRating == '' ||
                    row.StockStarRating == '&mdash;'||
                    row.StockStarRating == null||
                    row.StockStarRating == '&nbsp;'
                  "
                >
                  &mdash;
                </div>
                <div v-else>
                  <mds-star-rating
                    :rating="parseInt(row.StockStarRating)"
                    :custom-size="14"
                  ></mds-star-rating>
                </div>
              </mds-td>

              <mds-td left-aligned style="padding-left: 1px">
                <div
                  v-if="
                    row.EconomicMoat == 'N/A' ||
                    row.EconomicMoat == 'NaN' ||
                    row.EconomicMoat == '0' ||
                    row.EconomicMoat == '' ||
                    row.EconomicMoat == '&mdash;'||
                    row.EconomicMoat == null||
                    row.EconomicMoat == '&nbsp;'
                  "
                >
                  &mdash;
                </div>
                <div v-else>
                  <span v-html="row.EconomicMoat"></span>
                </div>
              </mds-td>

              <mds-td left-aligned style="padding-left: 0px;">
                <div
                  v-if="
                    row.StockRiskRating == 'N/A' ||
                    row.StockRiskRating == 'NaN' ||
                    row.StockRiskRating == '0' ||
                    row.StockRiskRating == '' ||
                    row.StockRiskRating == '&mdash;'||
                    row.StockRiskRating == null||
                    row.StockRiskRating == '&nbsp;'
                  "
                >
                  &mdash;
                </div>
                <div v-else>
                  <span v-html="row.StockRiskRating"></span>
                </div>
              </mds-td>

              <mds-td right-aligned style="padding-right: 0px;">
                <div
                  v-if="
                    row.FairValue == 'N/A' ||
                    row.FairValue == 'NaN' ||
                    row.FairValue == '0' ||
                    row.FairValue == '' ||
                    row.FairValue == '&mdash;'||
                    row.FairValue == null||
                    row.FairValue == '&nbsp;'
                  "
                >
                  &mdash;
                </div>
                <div v-else>
                  <span v-html="row.FairValue"></span>
                </div>
              </mds-td>

              <mds-td right-aligned style="padding-right: 0px;">
                <div
                  v-if="
                    row.PriceOverFairValue == 'N/A' ||
                    row.PriceOverFairValue == 'NaN' ||
                    row.PriceOverFairValue == '0' ||
                    row.PriceOverFairValue == '' ||
                    row.PriceOverFairValue == '&mdash;'||
                    row.PriceOverFairValue == null||
                    row.PriceOverFairValue == '&nbsp;'
                  "
                >
                  &mdash;
                </div>
                <div v-else>
                  <span v-html="row.PriceOverFairValue"></span>
                </div>
              </mds-td>

              <mds-td right-aligned style="padding-right: 0px;">
                <div
                  v-if="
                    row.LastPrice == 'N/A' ||
                    row.LastPrice == 'NaN' ||
                    row.LastPrice == '0' ||
                    row.LastPrice == '' ||
                    row.LastPrice == '&mdash;'||
                    row.LastPrice == null||
                    row.LastPrice == '&nbsp;'
                  "
                >
                  &mdash;
                </div>
                <div v-else>
                  <span v-html="row.LastPrice"></span>
                </div>
              </mds-td>

              <mds-td right-aligned style="padding-right: 0px;">
                <div
                  v-if="
                    row.DividendRate == 'N/A' ||
                    row.DividendRate == 'NaN' ||
                    row.DividendRate == '0' ||
                    row.DividendRate == '' ||
                    row.DividendRate == null||
                    row.DividendRate == '&mdash;' ||
                    row.DividendRate == '&nbsp;'
                  "
                >
                  &mdash;
                </div>
                <div v-else>
                  <span v-html="row.DividendRate"></span>
                </div>
              </mds-td>

              <mds-td right-aligned style="padding-right: 0px;">
                <div
                  v-if="
                    row.ForwardDividendYield == 'N/A' ||
                    row.ForwardDividendYield == 'NaN' ||
                    row.ForwardDividendYield == '0' ||
                    row.ForwardDividendYield == '' ||
                    row.ForwardDividendYield == null||
                    row.ForwardDividendYield == '&mdash;'
                    ||
                    row.ForwardDividendYield == '&nbsp;'
                  "
                >
                  &mdash;
                </div>
                <div v-else>
                  <span v-html="row.ForwardDividendYield"></span>
                </div>
              </mds-td>

              <mds-td  style="padding-left:20px">
                <div
                  v-if="
                    row.Sector == 'N/A' ||
                    row.Sector == 'NaN' ||
                    row.Sector == '0' ||
                    row.Sector == '' ||
                    row.Sector == null||
                    row.Sector == '&mdash;'
                    ||
                    row.Sector == '&nbsp;'
                  "
                >
                  &mdash;
                </div>
                <div v-else>
                  <span v-html="row.Sector"></span>
                </div>
              </mds-td>

              <mds-td  style="padding-left:0px">
                <div
                  v-if="
                    row.Category == 'N/A' ||
                    row.Category == 'NaN' ||
                    row.Category == '0' ||
                    row.Category == '' ||
                    row.Category == null||
                    row.Category == '&mdash;'
                    ||
                    row.Category == '&nbsp;'
                  "
                >
                  &mdash;
                </div>
                <div v-else>
                  <span v-html="row.Category"></span>
                </div>
              </mds-td>              
            </mds-tr>
          </mds-tbody>
        </mds-table>
      </div>
      <div class="mdi-income-bellwethers-cash-holdings-text">
        Portfolio table does not include cash holdings or dividends receivable.<br />
      </div>

      <div class="mdi-income-bellwethers-second-border">
      </div>

      <div class="mdi-income-bellwethers-balance-text" >
        The above information is provided for illustrative purposes only. The data and statistics presented are based on
        the most recent information available from Morningstar, Inc. and may or may not be an accurate reflection of
        current
        data.
        Investing in securities is subject to investment risk, including possible loss of principal. Past performance does
        not guarantee future results. There can be no assurance that any financial strategy will be successful.
        <br /><br />
        The information, data, analyses, and opinions presented herein do not constitute investment advice, are provided
        as of the date written, are provided solely for informational purposes and therefore are not an offer to buy or
        sell a security. Please note that references to specific securities or other investment options within this piece
        should not be considered an offer (as defined by the Securities and Exchange Act) to purchase or sell that
        specific investment.
        <br /><br />
        The Income Bellwethers list is created using quantitative analysis. Other than setting the methodology for
        inclusion on the list, the editors of <i> DividendInvestor</i> have no discretion in choosing which companies
        appear on this list. The Income Bellwethers list is provided for informational purposes only in order to help you
        research other companies that may fit into your investing strategy.
        <br /><br />
        Source: Morningstar Rating, rankings, categorizations, and fair value estimates are based on the analysis and
        estimates of Morningstar analysts.
      </div>     
    </div>
  </div>
</template>
<script>
import { fetchData } from "@/utilities/API/apiUtility.js";
import MdsLoader from "@mds/loader";
import MdsStarRating from "@mds/star-rating";
import moment from 'moment';
import { exportToExcel } from "../../utilities/Excel/exportToExcel.js";
import { MdsButton } from "@mds/button";
import EncryptDecryptMixin  from "../mixins/EncryptDecryptMixin.js";
import { mapState } from "vuex";
import {
  MdsTable,
  MdsThead,
  MdsTh,
  MdsTbody,
  MdsTr,
  MdsTd,
} from "@mds/data-table";
export default {
  name: "income-bellwethers",
  components: {
    MdsTable,
    MdsThead,
    MdsTh,
    MdsTbody,
    MdsTr,
    MdsTd,
    MdsLoader,
    MdsStarRating,
    MdsButton
  },
  mixins: [
		EncryptDecryptMixin
	],
  data() {
    return {
      CurrentURL :  "#",
      isDividentDeferred: true,
      isRearchDisplay: false,
      totalAnnualIncome: 0,
      totalMarketValue: 0,
      totalForwardDividendYield: 0,
      totalWeight: 0,
      cashArray: [],
      strSymbol: "",
      rowsResultRearch: [],
      headers: [
        {
          text: "Ticker",
          fieldName: "Symbol",
          sortable: true,
          style: {
            width: "65px"
          }
        },
        {
          text: "Name",
          fieldName: "StandardName",
          sortable: true,
          sorted: 1,
          style: {
            width: "250px",
            "padding-left" : "16px",
          }
        },

        {
          text: "Morningstar <br/> Rating",
          fieldName: "StockStarRating",
          sortable: true,
          style: {
            width: "120px",
            "padding-left": "24px"
          }
        },
        {
          text: "Economic <br /> Moat",
          fieldName: "EconomicMoat",
          sortable: true,
          style: {
            width: "80px"
          }
        },
        {
          text: "Fair Value<br/> Uncertainity",
          fieldName: "StockRiskRating",
          sortable: true,
          style: {
            width: "95px",

          }
        },
        {
          text: "Fair<br/> Value $",
          fieldName: "FairValue",
          sortable: true,
          style: {
            width: "50px",
          },
          align: "right"
        },
        {
          text: "Price/Fair<br/> Value",
          fieldName: "PriceOverFairValue",
          sortable: true,
          style: {
            width: "80px",

          },
          align: "right"
        },

        {
          text: "Current<br/> Price $",
          fieldName: "LastPrice",
          sortable: true,
          style: {
            width: "85px",

          },
          align: "right"
        },
        {
          text: "Dividend <br /> Rate $",
          fieldName: "DividendRate",
          sortable: true,
          style: {
            width: "70px"
          },
          align: "right"
        },
        {
          text: "Dividend <br/> Yield %",
          fieldName: "ForwardDividendYield",
          sortable: true,
          style: {
            width: "70px"
          },
          align: "right"
        },
        {
          text: "Sector Name",
          fieldName: "Sector",
          sortable: true,
          style: {
            width: "180px",
            "padding-left": "20px",
          },
        },
        {
          text: "Industry Name",
          fieldName: "Category",
          sortable: true,
          style: {
            width: "230px",
          },
        },
        // {
        //   text: "Analysis Date",
        //   fieldName: "AnalysisDate",
        //   sortable: true,
        //    style:{
        //     width:"80px"
        //   }
        // },
      ],
      rows: [],
      rowsRearch: [],
      sortOrder: 1,
      sortColIndex: 1,
      MdiIncomeBellwethersURL: process.env.VUE_APP_BackEndAPI + process.env.VUE_APP_IncomeBellwethersURL,
      isLoading: true,
      isDisplay: false,
      currentDate: "",
    };
  },
  methods: {
    GetIncomeBellwethersData() {
      try {
         fetchData(this.MdiIncomeBellwethersURL)
          .then((response) => {
            if (response) {
              if (response.status == 200) {
                if (response.data) {
                  var pfData = response.data;
                  if (pfData) {
                    for (var j = 0; j < pfData.length; j++) {
                      var obj1 = pfData[j];
                      if (pfData[j].StockStarRating) {
                        if (obj1.StockStarRating != "&mdash;") {
                          pfData[j].StockStarRating = parseFloat(obj1.StockStarRating);
                        }
                      }
                      if (pfData[j].FairValue) {
                        if (obj1.FairValue != "&mdash;") {
                          let FairValue = obj1.FairValue + ".00";
                          pfData[j].FairValue = parseFloat(FairValue).toFixed(2);
                        }
                      }
                      if (pfData[j].PriceOverFairValue) {
                        if (obj1.PriceOverFairValue != "&mdash;") {
                          let PriceOverFairValue = obj1.PriceOverFairValue + ".00";
                          pfData[j].PriceOverFairValue = parseFloat(PriceOverFairValue).toFixed(2);
                        }
                      }
                      if (pfData[j].LastPrice) {
                        if (obj1.LastPrice != "&mdash;") {
                          let LastPrice = obj1.LastPrice + ".00";
                          pfData[j].LastPrice = parseFloat(LastPrice).toFixed(2);
                        }
                      }
                      if (pfData[j].DividendRate) {
                        if (obj1.DividendRate != "&nbsp;") {
                          let DividendRate = obj1.DividendRate + ".00";
                          pfData[j].DividendRate = parseFloat(DividendRate).toFixed(2);
                        }
                      }
                      if (pfData[j].Sector) {
                        if (obj1.Sector != "&nbsp;") {
                          pfData[j].Sector = obj1.Sector;
                        }
                      }
                      if (pfData[j].Category) {
                        if (obj1.Category != "&nbsp;") {
                          pfData[j].Category = obj1.Category;
                        }
                      }
                      if (pfData[j].ForwardDividendYield) {
                        if (obj1.ForwardDividendYield != "&mdash;") {
                          let ForwardDividendYield = obj1.ForwardDividendYield + ".0";
                          pfData[j].ForwardDividendYield = parseFloat(ForwardDividendYield).toFixed(1);

                        }
                      }
                      if (pfData[j].AnalysisDate) {
                        if (obj1.AnalysisDate != "&mdash;") {
                          pfData[j].AnalysisDate = this.analysisDateFormat(obj1.AnalysisDate);
                        }
                      }

                    }
                  }
                  this.rows = pfData;

                }
              }
            }
          })
          .catch((error) => {
            console.log(error);

          })
          .finally(() => ((this.isLoading = false), (this.isDisplay = true)));
      } catch (ex) {
        console.log(ex.message);
      }
    },
    handleSortEvent(colIndex, colName) {
      this.sortOrder = this.sortOrder === 0 ? 1 : -this.sortOrder;

      if (this.sortColIndex !== null && this.sortColIndex !== colIndex) {
        this.$set(this.headers[this.sortColIndex], "sorted", 0);
        this.sortOrder = 1;
      }

      this.sortColIndex = colIndex;
      this.$set(this.headers[colIndex], "sorted", this.sortOrder);

      var IncludeDash = this.rows.filter(function (el) {
        return el[colName] == "&nbsp;";
      });

      var WithOutDash = this.rows.filter(function (el) {
        return el[colName] != "&nbsp;";
      });
      WithOutDash.sort((a, b) => {
        if (colName == "StockStarRating" || colName == "FairValue" || colName == "PriceOverFairValue" || colName == "LastPrice" ||
          colName == "DividendRate" || colName == "ForwardDividendYield") {
          return parseFloat(a[colName]) >= parseFloat(b[colName]) ? this.sortOrder : -this.sortOrder;
        }
        else if (colName == "StockRiskRating") {
          let s = 0;
          const aval = a[colName];
          const bval = b[colName];
          if (aval === bval) {
            s = 0;
          } else if (aval === 'Very High') {
            s = 1;
          } else if (bval === 'Very High') {
            s = -1;
          } else if (aval === 'High') {
            s = 1;
          } else if (bval === 'High') {
            s = -1;
          } else if (aval === 'Medium') {
            s = 1;
          } else if (bval === 'Medium') {
            s = -1;
          } else if (aval === 'Low') {
            s = 1;
          } else if (bval === 'Low') {
            s = -1;
          }
          if (this.sortOrder != 1) {
            s *= -1;
          }

          return s;

        }
        else {
          return a[colName] >= b[colName] ? this.sortOrder : -this.sortOrder;
        }
      });
      let mergeArr = [];
      if (this.sortOrder == 1) {
        mergeArr = IncludeDash.concat(WithOutDash);
      } else {
        mergeArr = WithOutDash.concat(IncludeDash);
      }
      this.rows = mergeArr;
    },
    ExportExcel() {
      const today = moment().format("MMDDYYYY");

      const cleanText = (value) => {
        if (typeof value !== "string") return value ?? "-";
        return value.replace(/&amp;/g, "&").replace(/&mdash;/g, "-").trim() || "-";
      };

      const parseNumber = (num) => {
        if (num === null || num === undefined || num === "") return 0;

        // Ensure no commas interfere with parsing
        const cleanedNum = num.toString().replace(/,/g, "").trim();
        const parsed = parseFloat(cleanedNum);

        return isNaN(parsed) ? 0 : Number(parsed); // Always return a valid number
      };

      const formattedRows = this.rows.map((row) => ({
        Ticker: cleanText(row.Symbol),
        Name: cleanText(row.StandardName),
        "Morningstar Rating": cleanText(row.StockStarRating),
        "Economic Moat": cleanText(row.EconomicMoat),
        "Fair Value $": parseNumber(row.FairValue),
        "Price/Fair Value": parseNumber(row.PriceOverFairValue),
        "Fair Value Uncertainty": cleanText(row.StockRiskRating),
        "Current Price $": parseNumber(row.LastPrice),
        "Dividend Rate $": parseNumber(row.DividendRate),
        "Dividend Yield %": parseNumber(row.ForwardDividendYield),
        "Sector Name": cleanText(row.Sector),
        "Industry Name": cleanText(row.Category),
      }));

      exportToExcel(
        `MDI_Bellwether_${today}.xlsx`,
        "Income Bellwethers Data",
        formattedRows
      );      
    },


    analysisDateFormat(anlysisvalue) {
      var str = anlysisvalue.split('-');
      var result = '';
      if (str) {
        result = str[0] + "/" + str[1] + "/" + str[2];
      }
      return result;
    },
    openMedalistReport(secId,Symbol) {
      this.$session.set("CurrentSecID",this.Encrypt(secId));
      this.$session.set("CurrentPortfolioName",this.Encrypt("incomeBell"));
      if(this.$store.state.responsive.isMobileContent)
      { 
        // this.$router.push({ name: 'analyst', params: { currentproduct: 'mdi',ticker:Symbol}})
        // .catch((err) => {
        //       if (
        //         err.name !== "NavigationDuplicated" &&
        //         !err.message.includes(
        //           "Avoided redundant navigation to current location"
        //         )
        //       ) {
        //         console.log(err);
        //       }
        //     });
        let resolvedRoute = this.$router.resolve({
          name: "analyst",
          params: {currentproduct: "mdi",ticker:Symbol}
        });
        this.CurrentURL = resolvedRoute.href;
       }
        else
        {
        let resolvedRoute = this.$router.resolve({
          name: "analyst",
          params: {currentproduct: "mdi",ticker:Symbol}
        });
        // window.open(resolvedRoute.href, '_blank');
        this.CurrentURL = resolvedRoute.href;
        }
      },
      redirectToStockQuote(exchangeId,ticker){
        var quotePageURL = "https://www.morningstar.com/stocks/"+exchangeId.toLowerCase()+"/"+ticker.toLowerCase()+"/quote";
        window.open(quotePageURL,"_blank");
      }     
   },
  mounted() {
    window.scrollTo(0, 0);
    this.GetIncomeBellwethersData();
  },
  computed: mapState({
    isMobileContent: state => state.responsive.isMobileContent,
      isMobileContentAlias:'isMobileContent',
      isMobileContentState(state){
      return state.responsive.isMobileContent
    }
  }),
};
</script>
    
<style lang="scss" scoped>
@import "@mds/constants";

::-webkit-scrollbar {
  height: 7px;
  width: 7px;
}

::-webkit-scrollbar-thumb {
  background: rgba(171, 171, 171, 0.3);
  border-radius: 6px;
}

.mdi-income-bellwethers-data-table{
  box-shadow: none;
}

.mdi-income-bellwethers-first-row {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  color: #1E1E1E;
  line-height: 25px;
}

.mdi-income-bellwethers-first-row:hover {
  cursor: pointer;
  color: #006fba;
}

.mds-td {
  line-height: 25px;
}

.mdi-income-bellwethers-table-header {
  color: #5E5E5E;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 0px;
}

body {
  font-family: "Univers", HelveticaNeue, "Helvetica Neue", Helvetica, Arial,
    sans-serif;
}

.mdi-income-bellwethers-middle-content{
  display:flex;
  width:128%
}

.mdi-income-bellwethers-title {
  font-weight: 700;
  font-size: 32px;
  color: #1E1E1E;
  line-height: 36px
}


.mdi-income-bellwethers-download-holding {
  margin-top: 30px;
  cursor: pointer;
  order:2;
  margin-left: auto;
  float:right; 
}

.mdi-income-bellwethers-download-data{
    font-size: 14px;
    margin-left: 5px;
    color: #5E5E5E;
}

.mdi-income-bellwethers-download-holding:hover {
  cursor: pointer;
}

.mdi-income-bellwethers-table-loader {
  position: absolute;
  width: "276px";
  height: "69px";
  left: 42.92%;
  right: 37.92%;
  top: 500px;
}

.mdi-income-bellwethers-container {
  width: 1280px;
  margin: 0 auto;
}

.mdi-income-bellwethers-header-text {
  width: 78%;
  padding-top: 48px;
  margin-left: 2px;
}

.mdi-income-bellwethers-second-border {
    border: 1px solid #CCCCCC;
    width: 100.8%;
    margin-top: 20px;
    margin-bottom: 0px;
}
.mdi-income-bellwethers-cash-holdings-text{
  font-size: 14px;
  font-weight: 400px;
  margin-top: 6px;
  font-style: normal;
  color: #5e5e5e;
  align-items: flex-end;
  margin-top:20px;
  line-height:18px;
}   

.mdi-income-bellwethers-balance-text {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #1E1E1E;
    margin-bottom: 60px;
    margin-top: 20px;
}

.mds-table--show-sortable .mds-th--sortable .mds-th__inner {
  border-bottom: none;
}

.mds-th--sortable.mds-th--sorted-descending .mds-th__inner,
.mds-th--sortable.mds-th--sorted-ascending .mds-th__inner {
  border-bottom: 1px solid rgb(30, 30, 30);
}

.mdi-income-bellwethers-dividend-loader {
  position: absolute;
  width: "276px";
  height: "69px";
  left: 42.92%;
  right: 37.92%;
  top: 2000px;
}

.mdi-income-bellwethers-analyst-text {
    font-size: 14px;
    line-height: 18px;
    font-weight: 400;
    color: #5E5E5E;
    margin-top: 24px;
}
.mdi-income-bellwethers-analyst-text-sec {
    font-size: 14px;
    line-height: 18px;
    font-weight: 400;
    color: #5E5E5E;
    margin-top: 6px;
}
.mdi-income-bellwethers-ticker
{
  cursor:pointer;
}
.mdi-income-bellwethers-ticker:hover {
  color:#006fba;
}

$mds-data-table-border-fixed-col: 2px solid $mds-color-neutral-90 !default;


@media only screen and (min-width: 320px) and (max-width: 1415px) {
  .mdi-income-bellwethers-container {
      width: 90%;
      margin-left: 5%;
      margin-right: 5%;
  }

  .mdi-income-bellwethers-balance-text {
    width:90vw; 
  }

  
}

@media only screen and (min-width: 320px) and (max-width: 428px) {  

.mdi-income-bellwethers-cash-holdings-text{
    font-size: 12px;    
}

.mdi-income-bellwethers-analyst-text {
  font-size: 14px;
  line-height: 18px;
  font-weight: 400;
  color: #5E5E5E;
  margin-top: 54px;
  width: 90vw;
}
.mdi-income-bellwethers-analyst-text-sec {
  font-size: 14px;
  line-height: 18px;
  font-weight: 400;
  color: #5E5E5E;
  width: 90vw;
}
.mdi-income-bellwethers-download-holding {
  margin-top: 16px;  
}

.mdi-income-bellwethers-title {
  font-weight: 700;
  font-size: 28px;
  color: #1e1e1e;
  line-height: 34px;
  width: 90vw;
}

.mdi-income-bellwethers-middle-content{
  display: block;
}

.mdi-income-bellwethers-middle-text {
  color: #5E5E5E;
  font-weight: 300;
  font-size: 16px;
  padding-top: 12px;
  line-height: 21px;
  width: 90vw;
}

.mdi-income-bellwethers-research-title {
    font-weight: 700;
    font-size: 20px;
    color: #1E1E1E;
    padding-top: 10px;
    line-height: 23px;
    width:65vw;
  }
}


</style>
<style lang="scss" module>
@import "@mds/constants";

 .mdi-income-bellwethers-data-table.mds-table__scroller .mds-table--fixed-column {
  th {
    position: sticky;
    top: 0;
  }

 th:nth-child(1){
    z-index: $mds-z-index-low + 1;
   }
    th:nth-child(1){
     padding-left:2px;
   }
 }
.mds-table--show-sortable .mds-th--sortable .mds-th__inner {
  border-bottom: none;
}

.mds-th--sortable.mds-th--sorted-descending .mds-th__inner,
.mds-th--sortable.mds-th--sorted-ascending .mds-th__inner {
  border-bottom: 1px solid rgb(30, 30, 30);
}

.mds-th {
  padding-top: 2px;
  padding-right: 2px;
  padding-left: 2px;
}

.mdi-income-bellwethers-data-table > table > tbody > tr > td:nth-child(2) > div > a:focus{ 
    box-shadow:none;
}
.mdi-income-bellwethers-data-table > table > tbody > tr > td:nth-child(2) > div > a:hover{ 
    cursor:pointer;
}

</style>