<template>

    <div>
        <div v-if="isMobileContentToggle" class="main-mfi-performance-section-responsive">
            <div class="common-divBorder-responsive"></div>
            <span class="common-span-cls">Fund Rating Performance</span>
            <div class="performace-image-section-responsive">
                <img class="fund-rating-performance-graph" :src="MFIportfolioperformanceimageUrl"
                    alt=" MFI Performance Graph" border="0" />
            </div>

            <div class="gold-rate-text-responsive">
                <p><strong>Gold-Rated Performance</strong> Returns are calculated for each asset
                    class or category based on which funds were rated Gold at the
                    start of each month over a five-year period. For bond funds,
                    only intermediate and intermediate core-plus funds are used.
                    For muni bond funds, only Muni National Long funds are used.
                </p>
            </div>

            <div class="common-divBorder-responsive"></div>
            <span class="common-span-cls">Resources</span> <br /><br />
            <div>
                <span class="common-inner-span">Resources</span> <br />
                <div style="margin-top: 15px">
                    <mds-table style="width: 90%;" class="mfi-tbl-font">
                        <mds-tbody>
                            <mds-tr v-for="row in resourcesdata" :key="row.ID">
                                <mds-td>
                                    <a class="mfi-resources-links" tabindex="0"
                                        @keydown.enter="DownloadFile(row.PdfName, 'Resources')"
                                        v-on:click="DownloadFile(row.PdfName, 'Resources')">{{ row.PdfTitle }}</a>
                                </mds-td>
                            </mds-tr>
                            <mds-tr>
                                <mds-td>
                                    <a tabindex="0" @keydown.enter="toggle = !toggle" class="resources-section-links"
                                        @click="toggle = !toggle">Investment Strategy</a>
                                </mds-td>
                            </mds-tr>
                            <mds-tr>
                                <mds-td>
                                    <a href="mailto:russel.kinnel@morningstar.com" class="resources-section-links">Ask
                                        Russel</a>
                                </mds-td>
                            </mds-tr>
                        </mds-tbody>
                    </mds-table>
                </div>
            </div>
            <br />
            <div>
                <span class="common-inner-span">Miscellaneous</span><br />
                <div style="margin-top: 15px">
                    <mds-table style="width: 90%;" class="mfi-tbl-font">
                        <mds-tbody>
                            <mds-tr>
                                <mds-td>
                                    <router-link class="resources-section-links"
                                        :to="{ name: 'glossary', params: { currentproduct: this.CurrentProduct } }">
                                        Glossary
                                    </router-link>
                                </mds-td>
                            </mds-tr>
                            <mds-tr>
                                <mds-td>
                                    <a href="https://www.morningstar.com/articles/691041/morningstar-medalists-what-makes-the-best-funds-shine"
                                        target="_blank" class="resources-section-links">Morningstar Medalists: What
                                        Makes the Best Funds Shine
                                        (Video)</a>
                                </mds-td>
                            </mds-tr>
                            <mds-tr>
                                <mds-td>
                                    <a href="https://www.morningstar.com/podcasts/the-long-view" target="_blank"
                                        class="resources-section-links">The Long View (Podcast)</a>
                                </mds-td>
                            </mds-tr>
                            <mds-tr v-for="row in miscellaneousdata" :key="row.ID">
                                <mds-td>
                                    <a class="mfi-resources-links" tabindex="0"
                                        @keydown.enter="DownloadFile(row.PdfName, 'MISCELLANEOUS')"
                                        v-on:click="DownloadFile(row.PdfName, 'MISCELLANEOUS')"> {{ row.PdfTitle }} </a>
                                </mds-td>
                            </mds-tr>
                        </mds-tbody>
                    </mds-table>
                </div>
            </div>
            <div class="common-inner-span-responsive">
                <span class="common-inner-span">Bonus Reports</span><br />
                <div style="margin-top: 15px">
                    <mds-table style="width: 90%;" class="mfi-tbl-font">
                        <mds-tbody>
                            <mds-tr v-for="row in pdfdata" :key="row.ID">
                                <mds-td> <a class="mfi-resources-links" tabindex="0"
                                        @keydown.enter="DownloadFile(row.PdfName, 'BonusReports')"
                                        @click="DownloadFile(row.PdfName, 'BonusReports')"> {{ row.PdfTitle }} </a>
                                </mds-td>
                            </mds-tr>
                        </mds-tbody>
                    </mds-table>
                </div>
            </div>
            <!--<mds-dialog class="investment-srategy-modal" v-model="toggle" title="Investment Strategy">
            <p style="text-align: justify">
                We seek to pick winning funds with superior management and quantitative
                characteristics linked to strong performance. Our quantitative research
                uses the most comprehensive mutual fund database in the world to
                determine the best strategies for long-term investing success. We then
                supplement those studies with extensive qualitative research of
                portfolio managers, analysts, and traders through onsite visits and
                follow-up phone calls.
            </p>
        </mds-dialog>-->

            <mds-modal size="small" width="900px" aria-label="Small Modal" v-model="toggle" actionRequired
                class="investment-strategy-modal">
                <mds-section class="with-nested-sections" border-position="bottom" bold>
                    <template #mds-section-title>
                        <span id="title-id">Investment Strategy</span>
                    </template>
                    <template #mds-section-actions>
                        <mds-button-container right-aligned>
                            <mds-button @click="toggle = !toggle" variation="icon-only" icon="remove">Close</mds-button>
                        </mds-button-container>
                    </template>
                    <mds-section border="none" :size="6">
                        <p>We seek to pick winning funds with superior management and quantitative
                            characteristics linked to strong performance. Our quantitative research
                            uses the most comprehensive mutual fund database in the world to
                            determine the best strategies for long-term investing success. We then
                            supplement those studies with extensive qualitative research of
                            portfolio managers, analysts, and traders through onsite visits and
                            follow-up phone calls.</p>
                    </mds-section>
                </mds-section>
            </mds-modal>
        </div>





        <div v-else class="mfi-performance-section-main">
            <mds-layout-grid>
                <mds-row>
                    <mds-col :cols="6" :cols-at-s="12" :cols-at-m="7" :cols-at-l="6" :cols-at-xl="6" gutter="none">
                        <div class="common-divBorder"></div>
                        <mds-row>
                            <mds-col>
                                <h1 class="common-span-cls">Fund Rating Performance</h1>

                                <div class="performace-image-section">
                                    <img :src="MFIportfolioperformanceimageUrl" alt=" MFI Performance Graph"
                                        border="0" />
                                </div>
                            </mds-col>
                            <mds-col>
                                <div class="gold-rate-text">
                                    <p><strong>Gold-Rated Performance</strong> Returns are calculated for each asset
                                        class or category based on which funds were rated Gold at the
                                        start of each month over a five-year period. For bond funds,
                                        only intermediate and intermediate core-plus funds are used.
                                        For muni bond funds, only Muni National Long funds are used.
                                    </p>
                                </div>
                            </mds-col>
                        </mds-row>
                    </mds-col>

                    <mds-col>
                        <div class="common-divBorder"></div>
                        <h1 class="common-span-cls">Resources</h1>
                        <mds-row style="margin-left:0">
                            <mds-col :cols="6" :cols-at-s="6" :cols-at-m="6" :cols-at-l="6" :cols-at-xl="6"
                                class="resources-main-div" gutter="none">

                                <div>
                                    <span class="common-inner-span">Resources</span> <br />
                                    <div style="margin-top: 15px">
                                        <mds-table style="width: 100%;" class="mfi-tbl-font">
                                            <mds-tbody>
                                                <mds-tr v-for="row in resourcesdata" :key="row.ID">
                                                    <mds-td>
                                                        <a class="mfi-resources-links" tabindex="0"
                                                            @keydown.enter="DownloadFile(row.PdfName, 'Resources')"
                                                            v-on:click="DownloadFile(row.PdfName, 'Resources')">{{
                                                                row.PdfTitle
                                                            }}</a>
                                                    </mds-td>
                                                </mds-tr>
                                                <mds-tr>
                                                    <mds-td>
                                                        <a tabindex="0" class="resources-section-links"
                                                            @keydown.enter="toggle = !toggle"
                                                            @click="toggle = !toggle">Investment Strategy</a>
                                                    </mds-td>
                                                </mds-tr>
                                                <mds-tr>
                                                    <mds-td>
                                                        <a href="mailto:russel.kinnel@morningstar.com"
                                                            class="resources-section-links">Ask Russel</a>
                                                    </mds-td>
                                                </mds-tr>
                                            </mds-tbody>
                                        </mds-table>
                                    </div>
                                </div>
                                <div>
                                    <h1 class="common-inner-span-miscellaneous">Miscellaneous</h1>
                                    <div style="margin-top: 15px">
                                        <mds-table style="width: 100%;" class="mfi-tbl-font">
                                            <mds-tbody>
                                                <mds-tr>
                                                    <mds-td>
                                                        <router-link class="resources-section-links"
                                                            :to="{ name: 'glossary', params: { currentproduct: this.CurrentProduct } }">
                                                            Glossary
                                                        </router-link>
                                                    </mds-td>
                                                </mds-tr>
                                                <mds-tr>
                                                    <mds-td>
                                                        <a href="https://www.morningstar.com/articles/691041/morningstar-medalists-what-makes-the-best-funds-shine"
                                                            target="_blank" class="resources-section-links">Morningstar
                                                            Medalists: What Makes the Best Funds Shine
                                                            (Video)</a>
                                                    </mds-td>
                                                </mds-tr>
                                                <mds-tr>
                                                    <mds-td>
                                                        <a href="https://www.morningstar.com/podcasts/the-long-view"
                                                            target="_blank" class="resources-section-links">The Long
                                                            View
                                                            (Podcast)</a>
                                                    </mds-td>
                                                </mds-tr>
                                                <mds-tr v-for="row in miscellaneousdata" :key="row.ID">
                                                    <mds-td>
                                                        <a class="mfi-resources-links" tabindex="0"
                                                            @keydown.enter="DownloadFile(row.PdfName, 'MISCELLANEOUS')"
                                                            v-on:click="DownloadFile(row.PdfName, 'MISCELLANEOUS')">{{
                                                                row.PdfTitle }}</a>
                                                    </mds-td>
                                                </mds-tr>
                                            </mds-tbody>
                                        </mds-table>
                                    </div>
                                </div>
                            </mds-col>
                            <mds-col :cols="6" :cols-at-s="6" :cols-at-m="6" :cols-at-l="6" :cols-at-xl="6"
                                class="resources-main-div" gutter="none">
                                <div>
                                    <span class="common-inner-span">Bonus Reports</span><br />
                                    <div style="margin-top: 15px">
                                        <mds-table style="width: 100%;" class="mfi-tbl-font">
                                            <mds-tbody>
                                                <mds-tr v-for="row in pdfdata" :key="row.ID">
                                                    <mds-td> <a class="mfi-resources-links" tabindex="0"
                                                            @keydown.enter="DownloadFile(row.PdfName, 'BonusReports')"
                                                            @click="DownloadFile(row.PdfName, 'BonusReports')"> {{
                                                                row.PdfTitle
                                                            }} </a> </mds-td>
                                                </mds-tr>
                                            </mds-tbody>
                                        </mds-table>
                                    </div>
                                </div>
                            </mds-col>
                        </mds-row>
                    </mds-col>
                </mds-row>
            </mds-layout-grid>


            <!--<mds-dialog v-model="toggle" title="Investment Strategy">
            <p style="text-align: justify">
                We seek to pick winning funds with superior management and quantitative
                characteristics linked to strong performance. Our quantitative research
                uses the most comprehensive mutual fund database in the world to
                determine the best strategies for long-term investing success. We then
                supplement those studies with extensive qualitative research of
                portfolio managers, analysts, and traders through onsite visits and
                follow-up phone calls.
            </p>
        </mds-dialog>-->
            <mds-modal size="small" width="900px" aria-label="Small Modal" v-model="toggle" actionRequired
                class="investment-strategy-modal">
                <mds-section class="with-nested-sections" border-position="bottom" bold>
                    <template #mds-section-title>
                        <span id="title-id">Investment Strategy</span>
                    </template>
                    <template #mds-section-actions>
                        <mds-button-container right-aligned>
                            <mds-button @click="toggle = !toggle" variation="icon-only" icon="remove">Close</mds-button>
                        </mds-button-container>
                    </template>
                    <mds-section border="none" :size="6">
                        <p>We seek to pick winning funds with superior management and quantitative
                            characteristics linked to strong performance. Our quantitative research
                            uses the most comprehensive mutual fund database in the world to
                            determine the best strategies for long-term investing success. We then
                            supplement those studies with extensive qualitative research of
                            portfolio managers, analysts, and traders through onsite visits and
                            follow-up phone calls.</p>
                    </mds-section>
                </mds-section>
            </mds-modal>
        </div>
    </div>
</template>

<script>
import { mapState } from "vuex";

import {
    MdsLayoutGrid,
    MdsRow,
    MdsCol
} from "@mds/layout-grid";
//import MdsDialog from "@mds/dialog";
import MdsModal from '@mds/modal';
import MdsSection from "@mds/section";
import { MdsButton, MdsButtonContainer } from '@mds/button';
import {
    MdsTable,
    MdsTbody,
    MdsTr,
    MdsTd,
} from "@mds/data-table";
import GA4TrackingMixin from "../../../mixins/GA4TrackingMixin";
import { fetchData } from "@/utilities/API/apiUtility";
export default {
    name: "mfi-performance-section",
    components: {
        MdsLayoutGrid,
        MdsRow,
        MdsCol,
        //MdsDialog,
        MdsModal,
        MdsSection,
        MdsButton,
        MdsButtonContainer,
        MdsTable,
        MdsTbody,
        MdsTr,
        MdsTd
    },

    data() {
        return {
            MFIportfolioperformanceimageUrl: null,
            ImageDataGet: process.env.VUE_APP_Newsletters_Utilities + process.env.VUE_APP_GetImagedata,
            MFIportfolioperformance: 'MFI Fund Performance Image', // Change this to your desired placement
            toggle: false,
            FileFromS3Url: process.env.VUE_APP_BackEndAPI + process.env.VUE_APP_File_FromS3_Url,
            imgSrc: "",
            fileData: null,
            CurrentProduct: this.$session.get("current-product"),
            isMobileContentToggle: null,
            viewPortWidth: null,
            CurrentDate: new Date(),
            GetAllMiscReportURL: process.env.VUE_APP_BackEndAPI + process.env.VUE_APP_GetAllMiscReport,
            pdfdata: "",
            miscellaneousdata: "",
            resourcesdata: "",
        };
    },
    mixins: [GA4TrackingMixin],
    methods: {

        async MFIperformanceImagesdata() {
            try {
                let Url = this.ImageDataGet;
                Url = Url.replace("{ProductCode}", "MFI");
                fetchData(Url)
                    .then((response) => {
                        const images = response.data.fileData;
                        console.log(images);
                        const filteredImagePortfolio = images.find(image => image.description.includes(this.MFIportfolioperformance));
                        if (filteredImagePortfolio) {
                            this.MFIportfolioperformanceimageUrl = `${filteredImagePortfolio.s3URL}?t=${new Date().getTime()}`;
                        }
                    })


            } catch (ex) {
                console.log(ex.message);
            }

        },

        getImageFromS3() {
            const getImageFromS3 = this.FileFromS3Url + "ProductCode=" + this.CurrentProduct + "&FileName=perfgraphmini_mfi.gif&Type=Resources"
            try {
                fetchData(getImageFromS3).then((response) => {
                    if (response) {
                        if (response.status == 200) {
                            if (response.data) {
                                if (response.data.FilePath) {
                                    this.imgSrc = response.data.FilePath;
                                }
                            }
                        }
                    }
                });
            } catch (ex) {
                console.log(ex.message);
            }
        },
        DownloadFile(FileName, Type) {
            this.$gtag.event('download_pdf', {
                'product': 'MFI',
                'issue_date': this.CurrentDate,
                'issue_title': FileName,
                'type': Type,
                'user_type': localStorage.getItem("userType_MFI")
            })


            const documentS3URL = this.FileFromS3Url + "ProductCode=" + this.CurrentProduct + "&FileName=" + FileName + "&Type=" + Type
            try {
                fetchData(documentS3URL)
                    .then((response) => {
                        if (response.status && response.status == "200") {
                            this.fileData = response.data.FilePath;
                            window.open(this.fileData, "_self")
                        }
                    });
            } catch (ex) {
                console.log(ex.message);
            }
        },
        decodeBase64DownloadFile(rawBase64) {
            const type = "application/pdf";
            const binary = atob(rawBase64.replace(/\s/g, ""));
            const binaryLength = binary.length;
            const buffer = new ArrayBuffer(binaryLength);
            const view = new Uint8Array(buffer);
            for (let i = 0; i < binaryLength; i++) {
                view[i] = binary.charCodeAt(i);
            }
            const blob = new Blob([view], {
                type
            });
            return blob;
        },

        GetAllPdfUpload() {
            try {
                fetchData(this.GetAllMiscReportURL).then((response) => {
                    if (response) {
                        if (response.status == 200) {
                            if (response.data) {
                                this.pdfdata = response.data.filter(row => {
                                    return row.PdfType === 'BonusReports' && row.ProductCode === 'MFI';
                                });
                                this.pdfdata = this.pdfdata.slice().sort((a, b) => {
                                    return parseInt(a.PdfPlacement) - parseInt(b.PdfPlacement);
                                });
                                this.miscellaneousdata = response.data.filter(row => {
                                    return row.PdfType === 'Miscellaneous' && row.ProductCode === 'MFI';
                                });
                                this.resourcesdata = response.data.filter(row => {
                                    return row.PdfType === 'Resources' && row.ProductCode === 'MFI';
                                });
                            }
                        }
                    }
                });
            } catch (ex) {
                console.log(ex.message);
            }
        },
    },


    mounted() {
        this.isMobileContentToggle = this.$store.state.responsive.isMobileContent;
        this.getImageFromS3();
        this.GetAllPdfUpload();
        this.getUserDetailsMixin(this.CurrentProduct);
    },

    computed: mapState({
        isMobileContent: state => state.responsive.isMobileContent,
        isMobileContentAlias: 'isMobileContent',
        isMobileContentState(state) {
            return state.responsive.isMobileContent
        }

    }),

    created() {
        this.MFIperformanceImagesdata()
    },

    watch: {
        isMobileContentState() {
            this.isMobileContentToggle = this.isMobileContentState;
        }
    }
};
</script>

<style lang="scss" scoped>
@import "@mds/typography";
@import "@mds/constants";

body,
body * {
    font-family: "Univers", HelveticaNeue, "Helvetica Neue", Helvetica, Arial,
        sans-serif;
}

.mds-layout-grid__col--3 {
    font-family: "Univers", HelveticaNeue, "Helvetica Neue", Helvetica, Arial,
        sans-serif;
}

span.resources-section-links:hover,
a.resources-section-links:hover {
    color: #ff0000;
    text-decoration: none;
    cursor: pointer;
}




a.resources-section-links:active {
    color: #666666;
    text-decoration: none;
}

.resources-section-links,
a.resources-section-links:link,
span.resources-section-links {
    color: #666666;
    border-top: none;
}

.resources-section-links {

    color: #666666;
    text-decoration: none;
    width: 274px;

    font-family: "Univers", HelveticaNeue, "Helvetica Neue", Helvetica, Arial,
        sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 21px;
    // height: 42px;
    margin-left: 1px;
}

.mfi-resources-links {
    margin-left: 1px;
}

.resources-content-dividing-border {
    border-top: 1px Solid #cccccc;
}

.common-span-cls {
    font-family: "Univers", HelveticaNeue, "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 23px;
    line-height: 27px;
    color: #1E1E1E;
    padding-bottom: 25px;
}

.common-divBorder {
    border-top: 1px solid #5e5e5e;
    margin-bottom: 10px;
    transform: rotate(180deg);
}

.common-inner-span {
    font-family: "Univers", HelveticaNeue, "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    color: #1E1E1E;
    margin-bottom: 10px;
}

.common-inner-span-miscellaneous {
    font-family: "Univers", HelveticaNeue, "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    color: #1E1E1E;
    margin-top: 20px;
    margin-bottom: -10px;
}

.performace-image-section {
    width: 100%;
    height: 391px;
    margin-top: 5px;
}

.performace-image-section img {
    width: 100%;
    // height: 100%;
    height: 391px;
    margin-left: -10px;
}

.resources-content-dividing-margin {
    // height: 21px;
    //margin-top: 4px;
}

// .mds-layout-grid .mds-layout-grid__col--3-at-1200 {
//   max-width: 23.3%;
// }
.mds-layout-grid .mds-layout-grid__col--3-at-1200 {
    width: 23.3%;
}

.gold-rate-text {
    color: #1E1E1E;
    margin-top: 52px;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    width: 100%;
}



@media only screen and (min-width: 0px) and (max-width: 600px) {
    // .colmd3,
    // .colmd9 {
    //   max-width: 100%;
    // }
    // .mds-layout-grid .mds-layout-grid__row {
    //   display: block;
    //   width: 100%;
    // }
    // .mds-layout-grid .mds-layout-grid__col--3 {
    //   flex-basis: 100%;
    //   flex-grow: 0;
    //   flex-shrink: 0;
    //   max-width: 100%;
    // }
    // .mds-layout-grid .mds-layout-grid__col--4 {
    //   flex-basis: 100%;
    //   flex-grow: 0;
    //   flex-shrink: 0;
    //   max-width: 100%;
    // }
    // .mds-layout-grid .mds-layout-grid__col--9 {
    //   flex-basis: 100%;
    //   flex-grow: 0;
    //   flex-shrink: 0;
    //   max-width: 100%;
    // }
}

@media only screen and (min-width: 0px) and (max-width: 500px) {
    .mds-layout-grid .mds-layout-grid__col--3-at-600 {
        max-width: 100%;
    }

    .mds-layout-grid .mds-layout-grid__col--3-at-768 {
        max-width: 100%;
    }

    .mds-layout-grid .mds-layout-grid__col--3-at-1000 {
        max-width: 100%;
    }

    .mds-layout-grid .mds-layout-grid__col--3-at-1200 {
        max-width: 100%;
    }
}

@media only screen and (min-width: 501px) and (max-width: 600px) {
    .mds-layout-grid .mds-layout-grid__col--3-at-600 {
        max-width: 100%;
    }
}

@media only screen and (min-width: 601px) and (max-width: 768px) {
    .mds-layout-grid .mds-layout-grid__col--3-at-768 {
        max-width: 100%;
    }
}

@media only screen and (min-width: 769px) and (max-width: 1000px) {
    .mds-layout-grid .mds-layout-grid__col--3-at-1000 {
        max-width: 100%;
    }
}

@media only screen and (min-width: 1001px) and (max-width: 1200px) {
    .mds-layout-grid .mds-layout-grid__col--3-at-1200 {
        max-width: 23.3%;
    }
}

@media only screen and (min-width: 1201px) and (max-width: 1600px) {
    .mds-layout-grid .mds-layout-grid__col--3-at-1200 {
        max-width: 23.3%;
    }
}

@media only screen and (min-width: 1280px) and (max-width: 16000px) {

    .mfi-performance-section-main {
        width: 1280px;
        margin: auto;
        padding: auto;
        margin-top: 80px;
    }

}

@media only screen and (min-width: 600px) and (max-width: 1280px) {
    .mfi-performance-section-main {
        width: 90%;
        margin: auto;
        padding: auto;
        margin-top: 80px;
    }

    .performace-image-section {
        width: 100%;
        height: 100%;
        margin-top: 20px;
    }

    .performace-image-section img {
        width: 100%;
        // height: 100%;
        height: 80%;
        margin-left: 0px;
        margin-bottom: 20px;
    }
}

.mfi-tbl-font tr td {
    color: #666666;
    text-decoration: none;
    font-family: "Univers", HelveticaNeue, "Helvetica Neue", Helvetica, Arial,
        sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 21px;
    cursor: pointer;
    pointer-events: all;
}

.mfi-tbl-font tr td a {
    color: #666666;
    text-decoration: none;
    font-family: "Univers", HelveticaNeue, "Helvetica Neue", Helvetica, Arial,
        sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 21px;
    cursor: pointer;
    pointer-events: all;
}

.mfi-tbl-font tr td:hover {
    color: #ff0000;
    text-decoration: none;
}

.mds-td:first-child {
    padding-left: 0px;
}

.mfi-tbl-font tr td a:hover {
    color: #ff0000;
    text-decoration: none;
    cursor: pointer;
}



.performace-image-section-responsive {
    width: 100%;
    height: 100%;
    margin-top: 22px;
    margin-right: 16px;
}


.performace-image-section-responsive img {
    width: 90%;
    height: 100%;

    margin-left: 0px;
    margin-right: 0px;
}

.gold-rate-text-responsive {
    color: #1E1E1E;
    margin-top: 42px;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    width: 90%;
}

.common-divBorder-responsive {
    width: 90%;
    border-top: 1px solid #5e5e5e;
    margin-bottom: 10px;
    margin-top: 40px;
    transform: rotate(180deg);
}

.common-inner-span-responsive {
    margin-top: 20px;
}

.resources-main-div {
    padding-left: 0%;
}

@media only screen and (min-width: 0px) and (max-width: 600px) {
    .mfi-performance-section-main {
        display: none !important;
    }

    .investment-strategy-modal .mds-modal__content {
        overflow-y: scroll;
    }

    .main-mfi-performance-section-responsive {
        width: 100%;
        margin: auto;
        padding: auto;
        margin-left: 16px;
        margin-right: 16px;
    }


}

@media only screen and (min-width: 600px) and (max-width: 12800px) {
    .main-mfi-performance-section-responsive {
        display: none !important;
    }
}


@media only screen and (min-width: 600px) and (max-width: 1280px) {
    .resources-main-div {
        padding-left: 0%;
    }



}

@media only screen and (min-width: 600px) and (max-width: 768px) {
    .performace-image-section {
        width: 100%;
        height: 391px;
        margin-top: 20px;
    }

    .performace-image-section img {
        width: 300px;
        // height: 100%;
        height: 391px;
        margin-top: -10px;
        margin-left: -12px;
    }
}


@media only screen and (min-width: 768px) and (max-width: 1000px) {
    .performace-image-section {
        width: 100%;
        height: 391px;
        margin-top: 20px;
    }

    .performace-image-section img {
        width: 250px;
        // height: 100%;
        height: 391px;
        margin-top: -10px;
        margin-left: 0px;
    }
}

@media only screen and (min-width: 1000px) and (max-width: 1280px) {
    .performace-image-section {
        width: 100%;
        height: 391px;
        margin-top: 20px;
    }

    .performace-image-section img {
        width: 300px;
        // height: 100%;
        height: 391px;
        margin-top: -10px;
        margin-left: -12px;
    }
}


.investment-srategy-modal {
    width: 80%;
    padding-left: 8%;
}
</style>
<style>
.investment-strategy-modal .mds-section .mds-section__header-container {
    margin-bottom: 5px;
}
</style>