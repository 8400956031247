const CountryModule = {
  namespaced: true,
  state() {
    return {
      contryList: [
        {
          CountryCode: 218,
          Country: "Afghanistan",
        },
        {
          CountryCode: 219,
          Country: "Albania",
        },
        {
          CountryCode: 220,
          Country: "Algeria",
        },
        {
          CountryCode: 221,
          Country: "American Samoa",
        },
        {
          CountryCode: 222,
          Country: "Andorra",
        },
        {
          CountryCode: 223,
          Country: "Angola",
        },
        {
          CountryCode: 224,
          Country: "Antigua & Barbuda",
        },
        {
          CountryCode: 225,
          Country: "Argentina",
        },
        {
          CountryCode: 226,
          Country: "Armenia",
        },
        {
          CountryCode: 227,
          Country: "Aruba",
        },
        {
          CountryCode: 228,
          Country: "Ascension Island",
        },
        {
          CountryCode: 229,
          Country: "Australia",
        },
        {
          CountryCode: 230,
          Country: "Austria",
        },
        {
          CountryCode: 231,
          Country: "Azerbaijan",
        },
        {
          CountryCode: 232,
          Country: "Bahamas",
        },
        {
          CountryCode: 233,
          Country: "Bahrain",
        },
        {
          CountryCode: 234,
          Country: "Bangladesh",
        },
        {
          CountryCode: 235,
          Country: "Barbados",
        },
        {
          CountryCode: 236,
          Country: "Belarus",
        },
        {
          CountryCode: 237,
          Country: "Belgium",
        },
        {
          CountryCode: 238,
          Country: "Belize",
        },
        {
          CountryCode: 239,
          Country: "Benin",
        },
        {
          CountryCode: 240,
          Country: "Bermuda",
        },
        {
          CountryCode: 241,
          Country: "Bhutan",
        },
        {
          CountryCode: 242,
          Country: "Bolivia",
        },
        {
          CountryCode: 243,
          Country: "Bosnia & Herzegovina",
        },
        {
          CountryCode: 244,
          Country: "Botswana",
        },
        {
          CountryCode: 245,
          Country: "Brazil",
        },
        {
          CountryCode: 246,
          Country: "British West Indies",
        },
        {
          CountryCode: 247,
          Country: "Brunei",
        },
        {
          CountryCode: 248,
          Country: "Bulgaria",
        },
        {
          CountryCode: 249,
          Country: "Burkina Faso",
        },
        {
          CountryCode: 250,
          Country: "Burundi",
        },
        {
          CountryCode: 251,
          Country: "Cambodia",
        },
        {
          CountryCode: 252,
          Country: "Cameroon",
        },
        {
          CountryCode: 253,
          Country: "Canada",
        },
        {
          CountryCode: 254,
          Country: "Cape Verde",
        },
        {
          CountryCode: 255,
          Country: "Cayman Islands",
        },
        {
          CountryCode: 256,
          Country: "Central African Republic",
        },
        {
          CountryCode: 257,
          Country: "Chad",
        },
        {
          CountryCode: 258,
          Country: "Chile",
        },
        {
          CountryCode: 259,
          Country: "China",
        },
        {
          CountryCode: 260,
          Country: "Colombia",
        },
        {
          CountryCode: 261,
          Country: "Commonwealth Dominica",
        },
        {
          CountryCode: 262,
          Country: "Comoros",
        },
        {
          CountryCode: 263,
          Country: "Congo",
        },
        {
          CountryCode: 264,
          Country: "Costa Rica",
        },
        {
          CountryCode: 265,
          Country: "Cote d'lovire",
        },
        {
          CountryCode: 266,
          Country: "Croatia",
        },
        {
          CountryCode: 267,
          Country: "Cuba",
        },
        {
          CountryCode: 268,
          Country: "Cyprus",
        },
        {
          CountryCode: 269,
          Country: "Czech Republic",
        },
        {
          CountryCode: 270,
          Country: "Denmark",
        },
        {
          CountryCode: 271,
          Country: "Djibouti",
        },
        {
          CountryCode: 272,
          Country: "Dominica",
        },
        {
          CountryCode: 273,
          Country: "Dominican Republic",
        },
        {
          CountryCode: 274,
          Country: "E.C.U.",
        },
        {
          CountryCode: 275,
          Country: "Ecuador",
        },
        {
          CountryCode: 276,
          Country: "Egypt",
        },
        {
          CountryCode: 277,
          Country: "El Salvador",
        },
        {
          CountryCode: 278,
          Country: "Equatorial Guinea",
        },
        {
          CountryCode: 279,
          Country: "Eritrea",
        },
        {
          CountryCode: 280,
          Country: "Estonia",
        },
        {
          CountryCode: 281,
          Country: "Ethiopia",
        },
        {
          CountryCode: 282,
          Country: "Fiji",
        },
        {
          CountryCode: 283,
          Country: "Finland",
        },
        {
          CountryCode: 284,
          Country: "France",
        },
        {
          CountryCode: 285,
          Country: "French Antilles",
        },
        {
          CountryCode: 286,
          Country: "French Guiana",
        },
        {
          CountryCode: 287,
          Country: "French Polynesia",
        },
        {
          CountryCode: 288,
          Country: "Gabon",
        },
        {
          CountryCode: 289,
          Country: "Gambia",
        },
        {
          CountryCode: 290,
          Country: "Georgia",
        },
        {
          CountryCode: 291,
          Country: "Germany",
        },
        {
          CountryCode: 292,
          Country: "Ghana",
        },
        {
          CountryCode: 293,
          Country: "Gibraltar",
        },
        {
          CountryCode: 294,
          Country: "Grand Cayman",
        },
        {
          CountryCode: 295,
          Country: "Greece",
        },
        {
          CountryCode: 296,
          Country: "Grenada",
        },
        {
          CountryCode: 297,
          Country: "Guam",
        },
        {
          CountryCode: 298,
          Country: "Guantanamo Bay",
        },
        {
          CountryCode: 299,
          Country: "Guatemala",
        },
        {
          CountryCode: 300,
          Country: "Guinea",
        },
        {
          CountryCode: 301,
          Country: "Guinea-Bissau",
        },
        {
          CountryCode: 302,
          Country: "Guyana",
        },
        {
          CountryCode: 303,
          Country: "Haiti",
        },
        {
          CountryCode: 304,
          Country: "Honduras",
        },
        {
          CountryCode: 305,
          Country: "Hong Kong",
        },
        {
          CountryCode: 306,
          Country: "Hungary",
        },
        {
          CountryCode: 307,
          Country: "Iceland",
        },
        {
          CountryCode: 308,
          Country: "India",
        },
        {
          CountryCode: 309,
          Country: "Indonesia",
        },
        {
          CountryCode: 310,
          Country: "Iran",
        },
        {
          CountryCode: 311,
          Country: "Iraq",
        },
        {
          CountryCode: 312,
          Country: "Ireland",
        },
        {
          CountryCode: 313,
          Country: "Israel",
        },
        {
          CountryCode: 314,
          Country: "Italy",
        },
        {
          CountryCode: 315,
          Country: "Ivory Coast",
        },
        {
          CountryCode: 316,
          Country: "Jamaica",
        },
        {
          CountryCode: 317,
          Country: "Japan",
        },
        {
          CountryCode: 318,
          Country: "Jordan",
        },
        {
          CountryCode: 319,
          Country: "Kazakhstan",
        },
        {
          CountryCode: 320,
          Country: "Kenya",
        },
        {
          CountryCode: 321,
          Country: "Kiribati",
        },
        {
          CountryCode: 322,
          Country: "Kuwait",
        },
        {
          CountryCode: 323,
          Country: "Kyrgyzstan",
        },
        {
          CountryCode: 324,
          Country: "Laos",
        },
        {
          CountryCode: 325,
          Country: "Latvia",
        },
        {
          CountryCode: 326,
          Country: "Lebanon",
        },
        {
          CountryCode: 327,
          Country: "Lesotho",
        },
        {
          CountryCode: 328,
          Country: "Liberia",
        },
        {
          CountryCode: 329,
          Country: "Libya",
        },
        {
          CountryCode: 330,
          Country: "Liechtenstein",
        },
        {
          CountryCode: 331,
          Country: "Lithuania",
        },
        {
          CountryCode: 332,
          Country: "Luxembourg",
        },
        {
          CountryCode: 333,
          Country: "Macao",
        },
        {
          CountryCode: 334,
          Country: "Macedonia",
        },
        {
          CountryCode: 335,
          Country: "Madagascar",
        },
        {
          CountryCode: 336,
          Country: "Malawi",
        },
        {
          CountryCode: 337,
          Country: "Malaysia",
        },
        {
          CountryCode: 338,
          Country: "Maldives",
        },
        {
          CountryCode: 339,
          Country: "Mali",
        },
        {
          CountryCode: 340,
          Country: "Malta",
        },
        {
          CountryCode: 341,
          Country: "Marshall Islands",
        },
        {
          CountryCode: 342,
          Country: "Mauritania",
        },
        {
          CountryCode: 343,
          Country: "Mauritius",
        },
        {
          CountryCode: 344,
          Country: "Mexico",
        },
        {
          CountryCode: 345,
          Country: "Micronesia",
        },
        {
          CountryCode: 346,
          Country: "Moldova",
        },
        {
          CountryCode: 347,
          Country: "Monaco",
        },
        {
          CountryCode: 348,
          Country: "Mongolia",
        },
        {
          CountryCode: 349,
          Country: "Morocco",
        },
        {
          CountryCode: 350,
          Country: "Mozambique",
        },
        {
          CountryCode: 351,
          Country: "Myanmar",
        },
        {
          CountryCode: 352,
          Country: "Namibia",
        },
        {
          CountryCode: 353,
          Country: "Naura",
        },
        {
          CountryCode: 354,
          Country: "Nepal",
        },
        {
          CountryCode: 355,
          Country: "Netherlands",
        },
        {
          CountryCode: 356,
          Country: "Netherlands Antilles",
        },
        {
          CountryCode: 357,
          Country: "New Caledonia",
        },
        {
          CountryCode: 358,
          Country: "New Guinea",
        },
        {
          CountryCode: 359,
          Country: "New Zealand",
        },
        {
          CountryCode: 360,
          Country: "Nicaragua",
        },
        {
          CountryCode: 361,
          Country: "Niger",
        },
        {
          CountryCode: 362,
          Country: "Nigeria",
        },
        {
          CountryCode: 363,
          Country: "North Korea",
        },
        {
          CountryCode: 364,
          Country: "Norway",
        },
        {
          CountryCode: 365,
          Country: "Oman",
        },
        {
          CountryCode: 366,
          Country: "Pakistan",
        },
        {
          CountryCode: 367,
          Country: "Palau",
        },
        {
          CountryCode: 368,
          Country: "Panama",
        },
        {
          CountryCode: 369,
          Country: "Papua New Guinea",
        },
        {
          CountryCode: 370,
          Country: "Paraguay",
        },
        {
          CountryCode: 371,
          Country: "Peru",
        },
        {
          CountryCode: 372,
          Country: "Philippines",
        },
        {
          CountryCode: 373,
          Country: "Poland",
        },
        {
          CountryCode: 374,
          Country: "Portugal",
        },
        {
          CountryCode: 375,
          Country: "Puerto Rico",
        },
        {
          CountryCode: 376,
          Country: "Qatar",
        },
        {
          CountryCode: 377,
          Country: "Romania",
        },
        {
          CountryCode: 378,
          Country: "Russia",
        },
        {
          CountryCode: 379,
          Country: "Rwanda",
        },
        {
          CountryCode: 380,
          Country: "Saipan",
        },
        {
          CountryCode: 381,
          Country: "San Marino",
        },
        {
          CountryCode: 382,
          Country: "Sao Tome & Principe",
        },
        {
          CountryCode: 383,
          Country: "Saudi Arabia",
        },
        {
          CountryCode: 384,
          Country: "Scotland",
        },
        {
          CountryCode: 385,
          Country: "Senegal",
        },
        {
          CountryCode: 386,
          Country: "Seychelles",
        },
        {
          CountryCode: 387,
          Country: "Sierra Leone",
        },
        {
          CountryCode: 388,
          Country: "Singapore",
        },
        {
          CountryCode: 389,
          Country: "Slovakia",
        },
        {
          CountryCode: 390,
          Country: "Slovenia",
        },
        {
          CountryCode: 391,
          Country: "Solomon Islands",
        },
        {
          CountryCode: 392,
          Country: "Somalia",
        },
        {
          CountryCode: 393,
          Country: "South Africa",
        },
        {
          CountryCode: 394,
          Country: "South Korea",
        },
        {
          CountryCode: 395,
          Country: "Spain",
        },
        {
          CountryCode: 396,
          Country: "Sri Lanka",
        },
        {
          CountryCode: 397,
          Country: "St. Kitts & Nevis",
        },
        {
          CountryCode: 398,
          Country: "St. Lucia",
        },
        {
          CountryCode: 399,
          Country: "St. Pierre & Miquelon",
        },
        {
          CountryCode: 400,
          Country: "St. Vincent & Grenadine",
        },
        {
          CountryCode: 401,
          Country: "Sudan",
        },
        {
          CountryCode: 402,
          Country: "Suriname",
        },
        {
          CountryCode: 403,
          Country: "Swaziland",
        },
        {
          CountryCode: 404,
          Country: "Sweden",
        },
        {
          CountryCode: 405,
          Country: "Switzerland",
        },
        {
          CountryCode: 406,
          Country: "Syria",
        },
        {
          CountryCode: 407,
          Country: "Taiwan",
        },
        {
          CountryCode: 408,
          Country: "Tajikistan",
        },
        {
          CountryCode: 409,
          Country: "Tanzania",
        },
        {
          CountryCode: 410,
          Country: "Thailand",
        },
        {
          CountryCode: 411,
          Country: "Togo",
        },
        {
          CountryCode: 412,
          Country: "Tonga",
        },
        {
          CountryCode: 413,
          Country: "Trinidad",
        },
        {
          CountryCode: 414,
          Country: "Tunisia",
        },
        {
          CountryCode: 415,
          Country: "Turkey",
        },
        {
          CountryCode: 416,
          Country: "Tuvalu",
        },
        {
          CountryCode: 417,
          Country: "USA",
        },
        {
          CountryCode: 418,
          Country: "Uganda",
        },
        {
          CountryCode: 419,
          Country: "Ukraine",
        },
        {
          CountryCode: 420,
          Country: "United Arab Emirates",
        },
        {
          CountryCode: 421,
          Country: "United Kingdom",
        },
        {
          CountryCode: 422,
          Country: "Uruguay",
        },
        {
          CountryCode: 423,
          Country: "Uzbekistan",
        },
        {
          CountryCode: 424,
          Country: "Vanuatu",
        },
        {
          CountryCode: 425,
          Country: "Vatican City",
        },
        {
          CountryCode: 426,
          Country: "Venezuela",
        },
        {
          CountryCode: 427,
          Country: "Vietnam",
        },
        {
          CountryCode: 428,
          Country: "Virgin Islands",
        },
        {
          CountryCode: 429,
          Country: "Western Samoa",
        },
        {
          CountryCode: 430,
          Country: "Yemen Arab Republic",
        },
        {
          CountryCode: 431,
          Country: "Yugoslavia",
        },
        {
          CountryCode: 432,
          Country: "Zaire",
        },
        {
          CountryCode: 433,
          Country: "Zambia",
        },
        {
          CountryCode: 434,
          Country: "Zimbabwe",
        },
      ],
      USAstateslist: [
        {
          StateAbbr: "AA",
          StateName: "AA",
        },
        {
          StateAbbr: "AE",
          StateName: "AE",
        },
        {
          StateAbbr: "AP",
          StateName: "AP",
        },
        {
          StateAbbr: "AL",
          StateName: "Alabama",
        },
        {
          StateAbbr: "AK",
          StateName: "Alaska",
        },
        {
          StateAbbr: "AZ",
          StateName: "Arizona",
        },
        {
          StateAbbr: "AR",
          StateName: "Arkansas",
        },
        {
          StateAbbr: "CA",
          StateName: "California",
        },
        {
          StateAbbr: "CO",
          StateName: "Colorado",
        },
        {
          StateAbbr: "CT",
          StateName: "Connecticut",
        },
        {
          StateAbbr: "DE",
          StateName: "Delaware",
        },
        {
          StateAbbr: "DC",
          StateName: "District Of Columbia",
        },
        {
          StateAbbr: "FL",
          StateName: "Florida",
        },
        {
          StateAbbr: "GA",
          StateName: "Georgia",
        },
        {
          StateAbbr: "GU",
          StateName: "Guam",
        },
        {
          StateAbbr: "HI",
          StateName: "Hawaii",
        },
        {
          StateAbbr: "ID",
          StateName: "Idaho",
        },
        {
          StateAbbr: "IL",
          StateName: "Illinois",
        },
        {
          StateAbbr: "IN",
          StateName: "Indiana",
        },
        {
          StateAbbr: "IA",
          StateName: "Iowa",
        },
        {
          StateAbbr: "KS",
          StateName: "Kansas",
        },
        {
          StateAbbr: "KY",
          StateName: "Kentucky",
        },
        {
          StateAbbr: "LA",
          StateName: "Louisiana",
        },
        {
          StateAbbr: "ME",
          StateName: "Maine",
        },
        {
          StateAbbr: "MH",
          StateName: "Marshall Islands",
        },
        {
          StateAbbr: "MD",
          StateName: "Maryland",
        },
        {
          StateAbbr: "MA",
          StateName: "Massachusetts",
        },
        {
          StateAbbr: "MI",
          StateName: "Michigan",
        },
        {
          StateAbbr: "MN",
          StateName: "Minnesota",
        },
        {
          StateAbbr: "MS",
          StateName: "Mississippi",
        },
        {
          StateAbbr: "MO",
          StateName: "Missouri",
        },
        {
          StateAbbr: "MT",
          StateName: "Montana",
        },
        {
          StateAbbr: "NE",
          StateName: "Nebraska",
        },
        {
          StateAbbr: "NV",
          StateName: "Nevada",
        },
        {
          StateAbbr: "NH",
          StateName: "New Hampshire",
        },
        {
          StateAbbr: "NJ",
          StateName: "New Jersey",
        },
        {
          StateAbbr: "NM",
          StateName: "New Mexico",
        },
        {
          StateAbbr: "NY",
          StateName: "New York",
        },
        {
          StateAbbr: "NC",
          StateName: "North Carolina",
        },
        {
          StateAbbr: "ND",
          StateName: "North Dakota",
        },
        {
          StateAbbr: "MP",
          StateName: "Northern Mariana Islands",
        },
        {
          StateAbbr: "OH",
          StateName: "Ohio",
        },
        {
          StateAbbr: "OK",
          StateName: "Oklahoma",
        },
        {
          StateAbbr: "OR",
          StateName: "Oregon",
        },
        {
          StateAbbr: "PA",
          StateName: "Pennsylvania",
        },
        {
          StateAbbr: "PR",
          StateName: "Puerto Rico",
        },
        {
          StateAbbr: "RI",
          StateName: "Rhode Island",
        },
        {
          StateAbbr: "SC",
          StateName: "South Carolina",
        },
        {
          StateAbbr: "SD",
          StateName: "South Dakota",
        },
        {
          StateAbbr: "TN",
          StateName: "Tennessee",
        },
        {
          StateAbbr: "TX",
          StateName: "Texas",
        },
        {
          StateAbbr: "UT",
          StateName: "Utah",
        },
        {
          StateAbbr: "VT",
          StateName: "Vermont",
        },
        {
          StateAbbr: "VI",
          StateName: "Virgin Islands",
        },
        {
          StateAbbr: "VA",
          StateName: "Virginia",
        },
        {
          StateAbbr: "WA",
          StateName: "Washington",
        },
        {
          StateAbbr: "WV",
          StateName: "West Virginia",
        },
        {
          StateAbbr: "WI",
          StateName: "Wisconsin",
        },
        {
          StateAbbr: "WY",
          StateName: "Wyoming",
        },
      ],
      Canadastateslist: [
        {
          StateName: "Alberta",
          StateAbbr: "AB",
        },
        {
          StateName: "British Columbia",
          StateAbbr: "BC",
        },
        {
          StateName: "Manitoba",
          StateAbbr: "MB",
        },
        {
          StateName: "New Brunswick",
          StateAbbr: "NB",
        },
        {
          StateName: "Newfoundland and Labrador",
          StateAbbr: "NF",
        },
        {
          StateName: "Northwest Territories",
          StateAbbr: "NT",
        },
        {
          StateName: "Nova Scotia",
          StateAbbr: "NS",
        },
        {
          StateName: "Nunavut",
          StateAbbr: "NU",
        },
        {
          StateName: "Ontario",
          StateAbbr: "ON",
        },
        {
          StateName: "Prince Edward Island",
          StateAbbr: "PE",
        },
        {
          StateName: "Quebec",
          StateAbbr: "QU",
        },
        {
          StateName: "Saskatchewan",
          StateAbbr: "SK",
        },
        {
          StateName: "Yukon",
          StateAbbr: "YT",
        },
      ],
       phoneType: [
        { 
            text: "Mobile Phone", 
            value: "Mobile" 
        },
        { 
            text: "Home Phone", 
            value: "Landline" 
        },
      ],
    };
  },
};
export default CountryModule;
