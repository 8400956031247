<template>
  <div>
    <div class="mdi-dividend-defer-portfolio-container">
      <div class="mdi-dividend-defer-portfolio-table-loader">
        <mds-loader v-if="isLoading" aria-label="Medium Loader"></mds-loader>
      </div>
      <div class="mdi-dividend-defer-portfolio-header-text">
        <div class="mdi-dividend-defer-portfolio-title">Dividend Select Deferred Portfolio Holdings</div>
        <div class="mdi-dividend-defer-portfolio-middle-content">
        <div class="mdi-dividend-defer-portfolio-middle-text">
          Current holdings as of last trading activity in a portfolio which
          Morningstar, Inc. has invested in accordance with the Dividend Select
          strategy as described on the Home page.
        </div>
        
        <div class="mdi-dividend-defer-portfolio-download-holding">
          <mds-button variation="flat" size="small" icon="document" @click="ExportExcel"
            class="mdi-dividend-defer-portfolio-download-data">
            Download data
          </mds-button>
        </div>
      </div>
        <div class="mdi-dividend-defer-portfolio-analyst-text">
          Click on company name for latest Morningstar Analyst Report.
        </div>
        <div class="mdi-dividend-defer-portfolio-analyst-text-sec">
          Click on ticker for additional stock data from Morningstar.com.
        </div>
      </div>
      <div style="padding-top:24px;">
        <mds-table fixed-first-col style="max-height:600px;" show-sortable class="mdi-dividend-defer-portfolio-data-table">
          <mds-thead fixed-header>
            <mds-th v-for="(header, index) in headers" :key="'A' +index" :right-aligned="header.align === 'right'"
              :style="header.style" :sortable="header.sortable" :sorted="header.sorted" @mds-th-sort-changed="
                handleSortEvent(index, header.fieldName, $event)
                ">
              <span v-html="header.text" class="mdi-dividend-defer-portfolio-table-header"></span>
            </mds-th>
          </mds-thead>
          <mds-tbody v-if="isDisplay">
            <mds-tr v-for="(row, index) in rows" :key="'B' +index">
              <mds-td left-align >
                <div
                  v-if="
                    row.Symbol == 'N/A' ||
                    row.Symbol == 'NaN' ||
                    row.Symbol == '0' ||
                    row.Symbol == '' ||
                    row.Symbol == '&mdash;'||
                    row.Symbol == null||
                    row.Symbol == '&nbsp;'
                  "
                >
                  &mdash;
                </div>
                <div v-else @click="redirectToStockQuote(row.ExchangeId,row.Symbol)" class="mdi-dividend-defer-portfolio-ticker">
                {{ row.Symbol }}
              </div>
              </mds-td>
              <mds-td style="padding-left:16px;">
                <div
                  v-if="
                    row.StandardName == 'N/A' ||
                    row.StandardName == 'NaN' ||
                    row.StandardName == '0' ||
                    row.StandardName == '' ||
                    row.StandardName == '&mdash;'||
                    row.StandardName == null||
                    row.StandardName == '&nbsp;'
                  "
                >
                  &mdash;
                </div>
                <div v-else>
                <a class="mdi-dividend-defer-portfolio-first-row" v-html="row.StandardName"
                @mouseover="openMedalistReport(row.SecId,row.Symbol)"
                @touchstart="openMedalistReport(row.SecId,row.Symbol)"
                target="_blank"
                :href="CurrentURL"
                ></a>
                </div>
                </mds-td>
             
              <mds-td left-align style="padding-left: 0px">
                <div
                  v-if="
                    row.StockStarRating == 'N/A' ||
                    row.StockStarRating == 'NaN' ||
                    row.StockStarRating == '0' ||
                    row.StockStarRating == '' ||
                    row.StockStarRating == '&mdash;'||
                    row.StockStarRating == null||
                    row.StockStarRating == '&nbsp;'
                  "
                >
                  &mdash;
                </div>
                <div v-else>
                  <mds-star-rating
                    :rating="parseInt(row.StockStarRating)"
                    :custom-size="14"
                  ></mds-star-rating>
                </div>
              </mds-td>

              <mds-td left-aligned style="padding-left: 1px">
                <div
                  v-if="
                    row.EconomicMoat == 'N/A' ||
                    row.EconomicMoat == 'NaN' ||
                    row.EconomicMoat == '0' ||
                    row.EconomicMoat == '' ||
                    row.EconomicMoat == '&mdash;'||
                    row.EconomicMoat == null||
                    row.EconomicMoat == '&nbsp;'
                  "
                >
                  &mdash;
                </div>
                <div v-else>
                  <span v-html="row.EconomicMoat"></span>
                </div>
              </mds-td>

              <mds-td right-aligned style="padding-right: 0px;">
                <div
                  v-if="
                    row.FairValue == 'N/A' ||
                    row.FairValue == 'NaN' ||
                    row.FairValue == '0' ||
                    row.FairValue == '' ||
                    row.FairValue == '&mdash;'||
                    row.FairValue == null||
                    row.FairValue == '&nbsp;'
                  "
                >
                  &mdash;
                </div>
                <div v-else>
                  <span v-html="row.FairValue"></span>
                </div>
              </mds-td>

              <mds-td right-aligned style="padding-right: 0px;">
                <div
                  v-if="
                    row.PriceOverFairValue == 'N/A' ||
                    row.PriceOverFairValue == 'NaN' ||
                    row.PriceOverFairValue == '0' ||
                    row.PriceOverFairValue == '' ||
                    row.PriceOverFairValue == '&mdash;'||
                    row.PriceOverFairValue == null||
                    row.PriceOverFairValue == '&nbsp;'
                  "
                >
                  &mdash;
                </div>
                <div v-else>
                  <span v-html="row.PriceOverFairValue"></span>
                </div>
              </mds-td>

              <mds-td left-aligned style="padding-left: 35px;">
                <div
                  v-if="
                    row.StockRiskRating == 'N/A' ||
                    row.StockRiskRating == 'NaN' ||
                    row.StockRiskRating == '0' ||
                    row.StockRiskRating == '' ||
                    row.StockRiskRating == '&mdash;'||
                    row.StockRiskRating == null||
                    row.StockRiskRating == '&nbsp;'
                  "
                >
                  &mdash;
                </div>
                <div v-else>
                  <span v-html="row.StockRiskRating"></span>
                </div>
              </mds-td>

              <mds-td right-aligned style="padding-right: 0px;">
                <div
                  v-if="
                    row.LastPrice == 'N/A' ||
                    row.LastPrice == 'NaN' ||
                    row.LastPrice == '0' ||
                    row.LastPrice == '' ||
                    row.LastPrice == '&mdash;'||
                    row.LastPrice == null||
                    row.LastPrice == '&nbsp;'
                  "
                >
                  &mdash;
                </div>
                <div v-else>
                  <span v-html="row.LastPrice"></span>
                </div>
              </mds-td>

              <mds-td right-aligned style="padding-right: 0px;">
                <div
                  v-if="
                    row.DividendRate == 'N/A' ||
                    row.DividendRate == 'NaN' ||
                    row.DividendRate == '0' ||
                    row.DividendRate == '' ||
                    row.DividendRate == '&mdash;'||
                    row.DividendRate == null||
                    row.DividendRate == '&nbsp;'
                  "
                >
                  &mdash;
                </div>
                <div v-else>
                  <span v-html="row.DividendRate"></span>
                </div>
              </mds-td>

              <mds-td right-aligned style="padding-right: 0px;">
                <div
                  v-if="
                    row.ForwardDividendYield == 'N/A' ||
                    row.ForwardDividendYield == 'NaN' ||
                    row.ForwardDividendYield == '0' ||
                    row.ForwardDividendYield == '' ||
                    row.ForwardDividendYield == '&mdash;'||
                    row.ForwardDividendYield == null||
                    row.ForwardDividendYield == '&nbsp;'
                  "
                >
                  &mdash;
                </div>
                <div v-else>
                  <span v-html="row.ForwardDividendYield"></span>
                </div>
              </mds-td>

              <mds-td right-aligned style="padding-right: 0px;">
                <div
                  v-if="
                    row.Shares == 'N/A' ||
                    row.Shares == 'NaN' ||
                    row.Shares == '0' ||
                    row.Shares == '' ||
                    row.Shares == '&mdash;'||
                    row.Shares == null||
                    row.Shares == '&nbsp;'
                  "
                >
                  &mdash;
                </div>
                <div v-else>
                  <span>{{ row.Shares | amountFilter }}</span>
                </div>
              </mds-td>

              <mds-td right-aligned style="padding-right: 0px;">
                <div
                  v-if="
                    row.MarketValue == 'N/A' ||
                    row.MarketValue == 'NaN' ||
                    row.MarketValue == '0' ||
                    row.MarketValue == '' ||
                    row.MarketValue == '&mdash;'||
                    row.MarketValue == null||
                    row.MarketValue == '&nbsp;'
                  "
                >
                  &mdash;
                </div>
                <div v-else>
                  <span>{{ row.MarketValue | amountFilter }}</span>
                </div>
              </mds-td>

              <mds-td right-aligned style="padding-right: 0px;">
                <div
                  v-if="
                    row.Weight == 'N/A' ||
                    row.Weight == 'NaN' ||
                    row.Weight == '0' ||
                    row.Weight == '' ||
                    row.Weight == '&mdash;'||
                    row.Weight == null||
                    row.Weight == '&nbsp;'
                  "
                >
                  &mdash;
                </div>
                <div v-else>
                  <span v-html="row.Weight"></span>
                </div>
              </mds-td>

              <mds-td right-aligned style="padding-right: 10px;">
                <div
                  v-if="
                    row.AnnualIncome == 'N/A' ||
                    row.AnnualIncome == 'NaN' ||
                    row.AnnualIncome == '0' ||
                    row.AnnualIncome == '' ||
                    row.AnnualIncome == '&mdash;'||
                    row.AnnualIncome == null||
                    row.AnnualIncome == '&nbsp;'
                  "
                >
                  &mdash;
                </div>
                <div v-else>
                  <span>{{ row.AnnualIncome | amountFilter }}</span>
                </div>
              </mds-td>             
            </mds-tr>
            <mds-tr v-for="(row, index) in cashArray" :key="'C' +index" class="mdi-dividend-defer-portfolio-cash-row">
              <!-- <mds-td style="padding-left:0px;">{{ row.Symbol }}</mds-td> -->
              <mds-td left-align class="mdi-dividend-defer-portfolio-cash-first-column"> {{ row.StandardName }}</mds-td>
              <mds-td ></mds-td>
              <mds-td left-aligned style="padding-left: 0px;">&mdash;</mds-td>
              <mds-td left-aligned style="padding-left: 0px">&mdash;</mds-td>
              <mds-td right-aligned style="padding-right:0px;">&mdash;</mds-td>
              <mds-td right-aligned style="padding-right:0px">&mdash;</mds-td>
              <mds-td left-aligned style="padding-left:35px">&mdash;</mds-td>
              <mds-td right-aligned style="padding-right:0px;">{{ row.LastPrice }}</mds-td>
              <mds-td right-aligned style="padding-right:0px;">&mdash;</mds-td>
              <mds-td right-aligned style="padding-right:0px;">&mdash;</mds-td>
              <mds-td right-aligned style="padding-right:0px;">{{ row.Shares }}</mds-td>
              <mds-td right-aligned style="padding-right:0px;">{{ row.MarketValue | amountFilter }}</mds-td>
              <mds-td right-aligned style="padding-right:0px;">{{ row.Weight }}</mds-td>
              <mds-td right-aligned style="padding-right:20px;">&mdash;</mds-td>
            </mds-tr>
            <mds-tr total-row>
              <mds-td> Total </mds-td>
              <mds-td></mds-td>
              <mds-td></mds-td>
              <mds-td></mds-td>
              <mds-td></mds-td>
              <mds-td></mds-td>
              <mds-td style="padding-left:25px"></mds-td>
              <mds-td></mds-td>
              <mds-td></mds-td>
              <mds-td right-aligned style="padding-right:0px;">{{ totalForwardDividendYield }}</mds-td>
              <mds-td></mds-td>
              <mds-td right-aligned style="padding-right:0px;">{{ totalMarketValue | amountFilter }}</mds-td>
              <mds-td right-aligned style="padding-right:0px;">{{ totalWeight }}</mds-td>
              <mds-td right-aligned style="padding-right:5px;">{{ totalAnnualIncome | amountFilter }}</mds-td>
            </mds-tr>
          </mds-tbody>
        </mds-table>
         

      <div class="mdi-dividend-defer-portfolio-balance-text">
        Balances for cash and dividends receivable are updated at least on a
        monthly basis, but may not match current amounts precisely.
        <br /><br />
        The above information is provided for illustrative purposes only. The data and statistics presented are based on
        the most recent information available from Morningstar, Inc. and may or may not be an accurate reflection of
        current
        data.
        Investing in securities is subject to investment risk, including possible loss of principal. Past performance does
        not guarantee future results. There can be no assurance that any financial strategy will be successful.
        <br /><br />
        The information, data, analyses, and opinions presented herein do not constitute investment advice, are provided
        as of the date written, are provided solely for informational purposes and therefore are not an offer to buy or
        sell a security. Please note that references to specific securities or other investment options within this piece
        should not be considered an offer (as defined by the Securities and Exchange Act) to purchase or sell that
        specific investment.
        <br /><br />
        Source: Morningstar Rating, rankings, categorizations, and fair value estimates are based on the analysis and
        estimates of Morningstar analysts.
      </div>
    </div> 
      <div class="mdi-dividend-defer-portfolio-second-border">
      </div>

      <div class="mdi-dividend-defer-portfolio-research-title">
        Recently Published Research by Morningstar, Inc.
      </div>

      <div style="margin-top:26px;">
        <div>
          <mds-loader v-if="isLoadingReport" aria-label="Medium Loader"></mds-loader>
        </div>
        <div class="mdi-dividend-defer-portfolio-research-table">
        <mds-table>
          <mds-thead>
            <mds-th style="width: 700px;">Title</mds-th>
            <mds-th style="width: 200px;padding-left: 8px;">Author</mds-th>
            <mds-th style="width: 100px;padding-left: 8px;">Date</mds-th>
          </mds-thead>
          <mds-tbody v-if="isResearchDisplay">
            <mds-tr v-for="(row, index) in rowsResultResearch" :key="'D' + index">
              <mds-td ><a class="mdi-dividend-defer-portfolio-first-row"
                @mouseover="openMedalistReport(row.SecId,row.Symbol)"
                @touchstart="openMedalistReport(row.SecId,row.Symbol)"
                target="_blank"
                :href="CurrentURL"
              > {{ row.Title
              }}</a>
              </mds-td>
              <mds-td >{{ row.Author }}</mds-td>
              <mds-td >{{ formatDateFunction(row.Date) }}</mds-td>
            </mds-tr>
          </mds-tbody>
        </mds-table>
      </div>
        <div class="mdi-dividend-defer-portfolio-intraday-text">
          U.S. intraday real-time exchange quotes are updated every 5 minutes during
          market hours. *Fair Value based on Morningstar analyst estimates. UR=Under
          Review. All data supplied by Morningstar Real-Time Data.
          <br />  <br v-if="isDataTableResponsiveToggle" />
          U.S. intraday real-time exchange quotes are sourced from BATS when available. End-of-day
          quotes for NASDAQ, NYSE, and Amex securities will appear 15 minutes after
          close.
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import MdsLoader from "@mds/loader";
import MdsStarRating from "@mds/star-rating";
import moment from 'moment';
import { saveExcel } from "@progress/kendo-vue-excel-export";
import { MdsButton } from "@mds/button";
import EncryptDecryptMixin  from "../mixins/EncryptDecryptMixin.js";
import {
  MdsTable,
  MdsThead,
  MdsTh,
  MdsTbody,
  MdsTr,
  MdsTd,
} from "@mds/data-table";
import { mapState } from "vuex";
import { fetchData } from "@/utilities/API/apiUtility.js";
export default {
  name: "dividend-defer-portfolio",
  components: {
    MdsButton,
    MdsTable,
    MdsThead,
    MdsTh,
    MdsTbody,
    MdsTr,
    MdsTd,
    MdsLoader,
    MdsStarRating,
  },
  mixins: [
		EncryptDecryptMixin
	],
  data() {
    return {
      CurrentURL :  "#",
      isDividentDeferred: true,
      isResearchDisplay: false,
      totalAnnualIncome: 0,
      totalMarketValue: 0,
      totalForwardDividendYield: 0,
      totalWeight: 0,
      cashArray: [],
      strSymbol: "",
      rowsResultResearch: [],      
      isDataTableResponsiveToggle: null,
      headers: [
        {
          text: "Ticker",
          fieldName: "Symbol",
          sortable: true,
          style: {
            width: "65px",
          }
        },
        {
          text: "Name",
          fieldName: "StandardName",
          sortable: true,
          sorted: 1,
          style: {
            width: "250px",
            'padding-left': '16px',
          }
        },

        {
          text: "Morningstar Rating",
          fieldName: "StockStarRating",
          sortable: true,
          style: {
            width: "100px"
          }
        },
        {
          text: "Economic Moat",
          fieldName: "EconomicMoat",
          sortable: true,
          style: {
            width: "70px"
          }
        },
        {
          text: "Fair <br/> Value $",
          fieldName: "FairValue",
          sortable: true,
          style: {
            width: "55px",
          },
          align: "right"
        },
        {
          text: "Price/Fair <br/> Value",
          fieldName: "PriceOverFairValue",
          sortable: true,
          style: {
            width: "90px",

          },
          align: "right"
        },
        {
          text: "Fair Value Uncertainity",
          fieldName: "StockRiskRating",
          sortable: true,
          style: {
            width: "115px",
            "padding-left": "35px"

          }
        },
        {
          text: "Current Price $",
          fieldName: "LastPrice",
          sortable: true,
          style: {
            width: "55px",

          },
          align: "right"
        },
        {
          text: "Dividend Rate $",
          fieldName: "DividendRate",
          sortable: true,
          style: {
            width: "75px"
          },
          align: "right"
        },
        {
          text: "Dividend Yield %",
          fieldName: "ForwardDividendYield",
          sortable: true,
          style: {
            width: "75px"
          },
          align: "right"
        },
        {
          text: "Number<br/> of Shares",
          fieldName: "Shares",
          sortable: true,
          style: {
            width: "75px"
          },
          align: "right"
        },
        {
          text: "Current <br/> Value $",
          fieldName: "MarketValue",
          sortable: true,
          style: {
            width: "90px",
          },
          align: "right"

        },
        {
          text: "Weight <br />%",
          fieldName: "Weight",
          sortable: true,
          style: {
            width: "70px"
          },
          align: "right"
        },
        {
          text: "Annual Income $",
          fieldName: "AnnualIncome",
          sortable: true,
          style: {
            width: "80px",
            "padding-right": "10px"
          },
          align: "right"
        },
        // {
        //   text: "Analysis<br/> Date",
        //   fieldName: "AnalysisDate",
        //   sortable: true,
        //    style:{
        //     width:"80px"
        //   }
        // },
      ],
      headersPublishRecords: [
        {
          text: "Title",
          fieldName: "Title",

        },
        {
          text: "Author",
          fieldName: "Author",
        },
        {
          text: "Date",
          fieldName: "Date",

        }
      ],
      rows: [],
      rowsRearch: [],
      sortOrder: 1,
      sortColIndex: 1,
      MdiDividendDeferUrl: process.env.VUE_APP_BackEndAPI + process.env.VUE_APP_DividendDeferURL,
      ResearchUrl: process.env.VUE_APP_BackEndAPI + process.env.VUE_APP_ResearchReportUrl,
      isLoading: true,
      isDisplay: false,
      currentDate: "",
      isLoadingReport: true
    };
  },
  methods: {
    GetDividendDeferredPortfolioData() {
      try {
        fetchData(this.MdiDividendDeferUrl)
          .then((response) => {
            if (response) {
              if (response.status == 200) {
                if (response.data) {
                  var pfData = response.data;
                  if (pfData) {
                    for (var i = 0; i < pfData.length; i++) {
                      //var obj = pfData[i];
                      //console.log(obj);
                      if (pfData[i].StandardName == "Cash") {
                        let cashValue = 0;
                        const formatNum = pfData[i].MarketValue;
                        let numWithoutComma = formatNum.replace(/,/g, '')
                        cashValue = numWithoutComma;
                        this.cashArray.push({ "StandardName": pfData[i].StandardName, "Symbol": pfData[i].Symbol, "StockStarRating": "-", "EconomicMoat": "-", "FairValue": "-", "PriceOverFairValue": "-", "StockRiskRating": "-", "LastPrice": parseFloat(pfData[i].LastPrice), "DividendRate": "-", "ForwardDividendYield": "-", "Shares": pfData[i].Shares, "MarketValue": cashValue, "Weight": parseFloat(pfData[i].Weight), "AnnualIncome": "-", "AnalysisDate": "-" });
                      }
                    }
                  }
                  var tempdata = [];
                  let totalAnnual = 0;
                  let totalMarket = 0;
                  let totalDividendYield = 0;
                  let totalWeightValue = 0;
                  let tempSymbol = "";
                  for (var j = 0; j < pfData.length; j++) {
                    var obj1 = pfData[j];
                    if (obj1.StandardName != "Cash") {
                      if (obj1.SecId) {
                        if (obj1.SecId != "&mdash;") {
                          tempSymbol += obj1.SecId + ",";

                        }
                      }
                      if (pfData[j].StockStarRating) {
                        if (obj1.StockStarRating != "&mdash;") {
                          pfData[j].StockStarRating = parseFloat(obj1.StockStarRating);
                        }
                      }
                      if (pfData[j].FairValue) {
                        if (obj1.FairValue != "&mdash;") {
                          let FairValue = obj1.FairValue + ".00";
                          pfData[j].FairValue = parseFloat(FairValue).toFixed(2);
                        }
                      }
                      if (pfData[j].PriceOverFairValue) {
                        if (obj1.PriceOverFairValue != "&mdash;") {
                          let PriceOverFairValue = obj1.PriceOverFairValue + ".00";
                          pfData[j].PriceOverFairValue = parseFloat(PriceOverFairValue).toFixed(2);
                        }
                      }
                      if (pfData[j].LastPrice) {
                        if (obj1.LastPrice != "&mdash;") {
                          let LastPrice = obj1.LastPrice + ".00";
                          pfData[j].LastPrice = parseFloat(LastPrice).toFixed(2);
                        }
                      }
                      if (pfData[j].DividendRate) {
                        if (obj1.DividendRate != "&mdash;") {
                          let DividendRate = obj1.DividendRate + ".00";
                          pfData[j].DividendRate = parseFloat(DividendRate).toFixed(2);
                        }
                      }
                      if (pfData[j].ForwardDividendYield) {
                        if (obj1.ForwardDividendYield != "&mdash;" && obj1.ForwardDividendYield != "&nbsp;") {
                          let ForwardDividendYield = obj1.ForwardDividendYield + ".0";
                          pfData[j].ForwardDividendYield = parseFloat(ForwardDividendYield).toFixed(1);
                          totalDividendYield += parseFloat(pfData[j].ForwardDividendYield);
                        }
                      }
                      if (pfData[j].Shares) {
                        if (obj1.Shares != "&mdash;") {
                          const formatNum = obj1.Shares;
                          let numWithoutComma = formatNum.replace(/,/g, '')
                          pfData[j].Shares = numWithoutComma;
                        }
                      }
                      if (pfData[j].MarketValue) {
                        if (obj1.MarketValue != "&mdash;" && obj1.MarketValue != "&nbsp;") {
                          const formatNum1 = obj1.MarketValue;
                          let numWithoutComma1 = formatNum1.replace(/,/g, '')
                          pfData[j].MarketValue = numWithoutComma1;
                          totalMarket += parseFloat(numWithoutComma1);
                        }
                      }
                      if (pfData[j].Weight) {
                        if (obj1.Weight != "&mdash;" && obj1.Weight != "&nbsp;") {
                          let Weight = obj1.Weight + ".0"
                          pfData[j].Weight = parseFloat(Weight).toFixed(1);
                          totalWeightValue += parseFloat(pfData[j].Weight);
                        }
                      }
                      if (pfData[j].AnnualIncome) {
                        if (pfData[j].AnnualIncome != "&mdash;" && pfData[j].AnnualIncome != "&nbsp;") {
                          let AnnualIncome = pfData[j].AnnualIncome + ".00"
                          AnnualIncome = AnnualIncome.replace(/,/g, '')
                          pfData[j].AnnualIncome = parseFloat(AnnualIncome).toFixed(2);
                          totalAnnual += parseFloat(AnnualIncome);

                        }
                      }
                      if (pfData[j].AnalysisDate) {
                        if (obj1.AnalysisDate != "&mdash;") {
                          pfData[j].AnalysisDate = obj1.AnalysisDate;
                        }
                      }
                      tempdata.push(pfData[j]);
                    }
                  }
                  this.rows = tempdata;
                  this.totalAnnualIncome = parseFloat(totalAnnual).toFixed(2);

                  let calc = parseFloat(totalMarket) + parseFloat(this.cashArray[0].MarketValue);

                  this.totalMarketValue = parseFloat(calc).toFixed(2);

                  let calcWeight = parseFloat(totalWeightValue) + parseFloat(this.cashArray[0].Weight);
                  this.totalWeight =Math.round(calcWeight.toFixed(1));
                  this.totalForwardDividendYield = parseFloat(totalDividendYield / (tempdata.length + 1)).toFixed(1);
                  if (tempSymbol) {
                    this.strSymbol = tempSymbol.replace(/,*$/, '');
                    this.GetResearchReport();
                  }
                }
              }
            }
          })
          .catch((error) => {
            console.log(error);

          })
          .finally(() => ((this.isLoading = false), (this.isDisplay = true)));
      } catch (ex) {
        console.log(ex.message);
      }
    },
    handleSortEvent(colIndex, colName) {
      this.sortOrder = this.sortOrder === 0 ? 1 : -this.sortOrder;

      if (this.sortColIndex !== null && this.sortColIndex !== colIndex) {
        this.$set(this.headers[this.sortColIndex], "sorted", 0);
        this.sortOrder = 1;
      }

      this.sortColIndex = colIndex;
      this.$set(this.headers[colIndex], "sorted", this.sortOrder);

      var IncludeDash = this.rows.filter(function (el) {
        return el[colName] == "&mdash;";
      });

      var WithOutDash = this.rows.filter(function (el) {
        return el[colName] != "&mdash;";
      });
      WithOutDash.sort((a, b) => {
        if (colName == "StockStarRating" || colName == "FairValue" || colName == "PriceOverFairValue" || colName == "LastPrice" ||
          colName == "DividendRate" || colName == "ForwardDividendYield" || colName == "MarketValue" || colName == "Weight" ||
          colName == "AnnualIncome" || colName == "Shares") {
          return parseFloat(a[colName]) >= parseFloat(b[colName]) ? this.sortOrder : -this.sortOrder;
        }
        else if (colName == "StockRiskRating") {
          let s = 0;
          const aval = a[colName];
          const bval = b[colName];
          if (aval === bval) {
            s = 0;
          } else if (aval === 'High') {
            s = 1;
          } else if (bval === 'High') {
            s = -1;
          } else if (aval === 'Medium') {
            s = 1;
          } else if (bval === 'Medium') {
            s = -1;
          } else if (aval === 'Low') {
            s = 1;
          } else if (bval === 'Low') {
            s = -1;
          }
          if (this.sortOrder != 1) {
            s *= -1;
          }

          return s;

        }
        else {
          return a[colName] >= b[colName] ? this.sortOrder : -this.sortOrder;
        }
      });
      let mergeArr = [];
      if (this.sortOrder == 1) {
        mergeArr = IncludeDash.concat(WithOutDash);
      } else {
        mergeArr = WithOutDash.concat(IncludeDash);
      }
      this.rows = mergeArr;
    },
    GetResearchReport() {
      //this.isLoading = true;
      try {
        var url = this.ResearchUrl;
        url = url.replace('{Portfolio}','DividendDefer');
        fetchData(url).then((response) => {
          if (response) {
            if (response.status == 200) {
              if (response.data) {
                this.rowsResultResearch = response.data;
              }
            }
          }
        }).catch((error) => {
          console.log(error);
          this.errored = true;
        })
          .finally(() => ((this.isLoadingReport = false), (this.isResearchDisplay = true)));
      } catch (ex) {
        console.log(ex.message);
      }
    },
    formatDateFunction(Date) {
      let str = "";
      try {
        const strSplit = Date.split("T");
        if (strSplit) {
          if (strSplit[0]) {
            let dateSplit = strSplit[0].split("-");
            str = dateSplit[1] + "/" + dateSplit[2] + "/" + dateSplit[0].toString().substr(-2);
          }
        }
        return str;
      }
      catch (ex) {
        return str;
      }
    },
    ExportExcel() {
      const today = moment();
      let excelData = [];
      for (var z = 0; z < this.rows.length; z++) {
        var currentRow = this.rows[z];
        currentRow.StandardName = currentRow.StandardName.replace('&amp;', '&');
        currentRow.StandardName = currentRow.StandardName.replace("&#39;", "'");
        if (currentRow.StockStarRating == "&mdash;") {
          this.rows[z].StockStarRating = "-";
        }
        if (currentRow.EconomicMoat == "&mdash;") {
          this.rows[z].EconomicMoat = "-";
        }
        if (currentRow.FairValue == "&mdash;") {
          this.rows[z].FairValue = "-";
        }
        if (currentRow.PriceOverFairValue == "&mdash;") {
          this.rows[z].PriceOverFairValue = "-";
        }
        if (currentRow.StockRiskRating == "&mdash;") {
          this.rows[z].StockRiskRating = "-";
        }
        if (currentRow.AnalysisDate == "&mdash;") {
          this.rows[z].AnalysisDate = "-";
        }
        excelData.push(this.rows[z]);
      }
      if (this.cashArray) {
        excelData.push(this.cashArray[0]);
      }
      excelData.push({ "StandardName": "Dividend Select Deferred Portfolio Total", "Symbol": "", "StockStarRating": "", "EconomicMoat": "", "FairValue": "", "PriceOverFairValue": "", "StockRiskRating": "", "LastPrice": "", "DividendRate": "", "ForwardDividendYield": parseFloat(this.totalForwardDividendYield), "Shares": "", "MarketValue": this.totalMarketValue, "Weight": parseFloat(this.totalWeight), "AnnualIncome": parseFloat(this.totalAnnualIncome) });
      saveExcel({
        data: excelData,
        fileName: "DivSelectDef_" + today.format("MMDDYYYY") + ".xlsx",
        columns: [
          { field: "Symbol", title: "Ticker" },
          { field: "StandardName", title: "Name" },
          { field: "StockStarRating", title: "Morningstar Rating" },
          { field: "EconomicMoat", title: "Economic Moat" },
          { field: "FairValue", title: "Fair Value $" },
          { field: "PriceOverFairValue", title: "Price/Fair Value" },
          { field: "StockRiskRating", title: "Fair Value Uncertainity" },
          { field: "LastPrice", title: "Current Price $" },
          { field: "DividendRate", title: "Dividend Rate $" },
          { field: "ForwardDividendYield", title: "Dividend Yield %" },
          { field: "Shares", title: "Number of Shares" },
          { field: "MarketValue", title: "Current Value $" },
          { field: "Weight", title: "Weight %" },
          { field: "AnnualIncome", title: "Annual Income $" },
          // {field:"AnalysisDate", title:"Analysis Date"}
        ],
      });
    },
    openMedalistReport(secId,Symbol) {
      this.$session.set("CurrentSecID",this.Encrypt(secId));
      this.$session.set("CurrentPortfolioName",this.Encrypt("divDifferSelect"));
      if(this.$store.state.responsive.isMobileContent){
        // this.$router.push({ name: 'analyst', params: { currentproduct: 'mdi',ticker:Symbol}})
        // .catch((err) => {
        //   if (
        //     err.name !== "NavigationDuplicated" &&
        //     !err.message.includes(
        //       "Avoided redundant navigation to current location"
        //     )
        //   ) {
        //     console.log(err);
        //   }
        // });
        let resolvedRoute = this.$router.resolve({
        name: "analyst",
        params: {currentproduct: "mdi",ticker:Symbol}
      });
      this.CurrentURL = resolvedRoute.href;
      }
      else
      {
      let resolvedRoute = this.$router.resolve({
        name: "analyst",
        params: {currentproduct: "mdi",ticker:Symbol}
      });
      //window.open(resolvedRoute.href, '_blank');
      this.CurrentURL = resolvedRoute.href;
    }
   },
    redirectToStockQuote(exchangeId,ticker){
      var quotePageURL = "https://www.morningstar.com/stocks/"+exchangeId.toLowerCase()+"/"+ticker.toLowerCase()+"/quote";
      window.open(quotePageURL,"_blank");
    }
  },
  mounted() {
    window.scrollTo(0, 0);
    this.isDataTableResponsiveToggle = this.$store.state.responsive.isDataTableResponsive;
    this.GetDividendDeferredPortfolioData();  
  },
  computed: mapState({
    isDataTableResponsive: state => state.responsive.isDataTableResponsive,
    isDataTableResponsiveAlias: 'isDataTableResponsive',
    isDataTableResponsiveState(state) {
      return state.responsive.isDataTableResponsive
    },
    isMobileContent: state => state.responsive.isMobileContent,
      isMobileContentAlias:'isMobileContent',
      isMobileContentState(state){
      return state.responsive.isMobileContent
    }

  }),
  watch: {
    isDataTableResponsiveState() {
      this.isDataTableResponsiveToggle = this.isDataTableResponsiveState;
    }
  }
};
</script>
    
<style lang="scss" scoped>
@import "@mds/constants";

.mdi-dividend-defer-portfolio-data-table.mds-table__scroller .mds-table--fixed-column {
  th {
    position: sticky;
    top: 0;
  }

 th:nth-child(1){
    z-index: $mds-z-index-low + 1;
   }
    th:nth-child(1){
     padding-left:2px;
   }
 }

::-webkit-scrollbar {
  height: 7px;
  width: 7px;
}

::-webkit-scrollbar-thumb {
  background: rgba(171, 171, 171, 0.3);
  border-radius: 6px;
}

.mdi-dividend-defer-portfolio-data-table{
  box-shadow: none;
}

.mdi-dividend-defer-portfolio-middle-text {
  color: #5E5E5E;
  font-weight: 400;
  font-size: 20px;
  padding-top: 24px;
  line-height: 26px;
  width: 55vw;
}

.mdi-dividend-defer-portfolio-middle-content{
  display:flex;
  width:128%
}

.mdi-dividend-defer-portfolio-research-table{
  overflow: scroll;
}

.mdi-dividend-defer-portfolio-research-table tr td {
  color: #1E1E1E;
  font-weight: 300;
  font-size: 16px;
  font-style: normal;
  line-height: 21px;
}

.mdi-dividend-defer-portfolio-first-row {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  color: #1E1E1E;
  line-height: 25px;
}

.mdi-dividend-defer-portfolio-first-row:hover {
  cursor: pointer;
  color: #006fba;
}

.mdi-dividend-defer-portfolio-table-header {
  color: #5E5E5E;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 0px;
}

body {
  font-family: "Univers", HelveticaNeue, "Helvetica Neue", Helvetica, Arial,
    sans-serif;
}

.mds-td {
  line-height: 25px;
}


.mdi-dividend-defer-portfolio-header-text {
  width: 78%;
  padding-top: 48px;
  margin-left: 2px;
}

.mdi-dividend-defer-portfolio-title {
  font-weight: 700;
  font-size: 32px;
  color: #1E1E1E;
  line-height: 36px
}


.mdi-dividend-defer-portfolio-download-holding {
  margin-top: 30px;
  cursor: pointer;
  order:2;
  margin-left: auto;
  float:right; 
}

.mdi-dividend-defer-portfolio-download-holding:hover {
  cursor: pointer;
}

.mdi-dividend-defer-portfolio-table-loader {
  position: absolute;
  width: "276px";
  height: "69px";
  left: 42.92%;
  right: 37.92%;
  top: 500px;
}

 .mdi-dividend-defer-portfolio-container {
  width: 1280px;
  margin: 0 auto;
}

.mdi-dividend-defer-portfolio-intraday-text {
  width: 90vw;
  text-align: justify;
  margin-top: 28px;
  left: 122px;
  margin-bottom: 10px;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #5E5E5E;
  padding-bottom: 40px;
}

.mdi-dividend-defer-portfolio-second-border {
    border: 1px solid #CCCCCC;
    width: 100.8%;
    margin-top: 0px;
    margin-bottom: 0px;
}

.mdi-dividend-defer-portfolio-balance-text {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #1E1E1E;
    margin-bottom: 20px;
    margin-top: 20px;
}

.mdi-dividend-defer-portfolio-research-title {
  font-weight: 700;
  font-size: 28px;
  color: #1E1E1E;
  padding-top: 10px;
}

.mdi-dividend-defer-portfolio-cash-first-column{
  width:3.5lvw;
  font-style: normal;
  font-weight: 700 !important;
  font-size: 16px;
  color:#1E1E1E;
  line-height: 21px;
}
.mds-table--show-sortable .mds-th--sortable .mds-th__inner {
  border-bottom: none;
}

.mds-th--sortable.mds-th--sorted-descending .mds-th__inner,
.mds-th--sortable.mds-th--sorted-ascending .mds-th__inner {
  border-bottom: 1px solid rgb(30, 30, 30);
}

.mdi-dividend-defer-portfolio-dividend-loader {
  position: absolute;
  width: "276px";
  height: "69px";
  left: 42.92%;
  right: 37.92%;
  top: 2000px;
}

.mdi-dividend-defer-portfolio-analyst-text {
    font-size: 14px;
    line-height: 18px;
    font-weight: 400;
    color: #5E5E5E;
    margin-top: 24px;
}
.mdi-dividend-defer-portfolio-analyst-text-sec {
    font-size: 14px;
    line-height: 18px;
    font-weight: 400;
    color: #5E5E5E;
    margin-top: 6px;
}


.mds-tr.mdi-dividend-defer-portfolio-cash-row {
  td {    
        border-top: 1px solid #808080;
        border-bottom: 1px solid #808080;
        border-collapse: collapse;
     }
  }


.mdi-dividend-defer-portfolio-ticker
{
  cursor:pointer;
}
.mdi-dividend-defer-portfolio-ticker:hover {
  color:#006fba;
}
@media only screen and (min-width: 320px) and (max-width: 1415px) {
  .mdi-dividend-defer-portfolio-container {
      width: 90%;
      margin-left: 5%;
      margin-right: 5%;
  }

  .mdi-dividend-defer-portfolio-balance-text {
    width:90vw; 
  }
}

@media only screen and (min-width: 320px) and (max-width: 600px) {

  .mdi-dividend-defer-portfolio-data-table.mds-table__scroller .mds-table--fixed-column {
  th {
    position: sticky;
    top: 0;  
  }  
  th:nth-child(1) {
        z-index: $mds-z-index-low + 1;
    }

  th:nth-child(1) {
    padding-left: 2px;
  }

  td {
    padding: 7px 2px;
  }

  td:nth-child(1) {
    padding: 5px 2px;
  }
}



.mdi-dividend-defer-portfolio-analyst-text {
  font-size: 14px;
  line-height: 18px;
  font-weight: 400;
  color: #5E5E5E;
  margin-top: 54px;
  width: 90vw;
}
.mdi-dividend-defer-portfolio-analyst-text-sec {
  font-size: 14px;
  line-height: 18px;
  font-weight: 400;
  color: #5E5E5E;
  width: 90vw;
}

.mdi-dividend-defer-portfolio-download-holding {
  margin-top: 16px;  
}

.mdi-dividend-defer-portfolio-middle-content {
  display:block; 
}

.mdi-dividend-defer-portfolio-title {
  font-weight: 700;
  font-size: 28px;
  color: #1e1e1e;
  line-height: 34px;
  width: 90vw;
}

.mdi-dividend-defer-portfolio-middle-text {
  color: #5E5E5E;
  font-weight: 300;
  font-size: 16px;
  padding-top: 12px;
  line-height: 21px;
  width: 90vw;
}

.mdi-dividend-defer-portfolio-research-title {
    font-weight: 700;
    font-size: 20px;
    color: #1E1E1E;
    padding-top: 10px;
    line-height: 23px;
    width:65vw;
  }
}



@media only screen and (min-width: 1537px) and (max-width:3840px) {

.mdi-dividend-defer-portfolio-middle-text { 
    width: 850px;
  }
}
.mdi-dividend-defer-portfolio-download-data{
    font-size: 14px;
    color: #5E5E5E;
}
.mdi-dividend-defer-portfolio-data-table > table > tbody > tr > td:nth-child(2) > div > a:focus{ 
    box-shadow:none;
}
.mdi-dividend-defer-portfolio-data-table > table > tbody > tr > td:nth-child(2) > div > a:hover{ 
    cursor:pointer;
}
.mdi-dividend-defer-portfolio-research-table > table > tbody > tr > td:nth-child(1) > a:focus{ 
    box-shadow:none;
    color: #006fba;
}
.mdi-dividend-defer-portfolio-research-table > table > tbody > tr > td:nth-child(1) > a:hover{ 
  cursor:pointer;
  color: #006fba;
}
.mdi-dividend-defer-portfolio-research-table > table > tbody > tr > td:nth-child(1) > a{ 
  color: #1e1e1e;
}
</style>

