const LayoutModule = {
    namespaced: true,
    state() {
        return {
          isHeaderRequired:true,
          isFooterRequired:true,
      }
    },
  };
  
  export default LayoutModule;