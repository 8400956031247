const HoldingModule = {
    namespaced: true,
    state() {
        return {
          CurrentSecID:"",
          CurrentPortfolioName:"",
      }
    },
  };
  
  export default HoldingModule;