<template>
  <div class="banner-bottom-main-div">
    <div class="banner-bottom-container">
      <div style="position: relative" v-if="
        this.$store.state.product.SelectedProductBanner ==
        'Morningstar DividendInvestor'
      " class="combined-bottom-banner-img-gradient" id="MdiBanner"></div>
      <div style="position: relative" v-if="
        this.$store.state.product.SelectedProductBanner ==
        'Morningstar FundInvestor'
      " class="combined-bottom-banner-img-gradient" id="MfiBanner"></div>
      <div style="position: relative" v-if="
        this.$store.state.product.SelectedProductBanner ==
        'Morningstar StockInvestor'
      " class="combined-bottom-banner-img-gradient" id="MsiBanner"></div>
      <div style="position: relative" v-if="
        this.$store.state.product.SelectedProductBanner ==
        'Morningstar ETFInvestor'
      " class="combined-bottom-banner-img-gradient" id="ETFBanner">
      </div>
      <div style="position: relative; z-index: 3" class="combine-hero-image-text-container">
        <div class="combined-bottom-div-style">
          <div class="combined-bottom-banner-heading" v-html="bannerProductTitle"></div>
          <div class="combined-bottom-mdi-statictext" v-if="
            this.$store.state.product.SelectedProductBanner ==
            'Morningstar DividendInvestor'
          ">
            Discover the world of dividend-paying stocks.
          </div>

          <div class="combined-bottom-banner-text" v-if="!Subscribed">
            <div class="combined-bottom-divBorder"></div>
            <div class="combined-bottom-productName">
              {{ this.$store.state.product.SelectedProductBanner }}
            </div>
            <div>
              <h1 class="combined-bottom-productPrice" v-if="
                this.$store.state.product.SelectedProductBanner ==
                'Morningstar FundInvestor' | this.$store.state.product.SelectedProductBanner ==
                'Morningstar StockInvestor'">
                $170 Annual Digital <span class="pipe-symbol">|</span> $190 Annual Print + Digital
              </h1>
              <h1 class="combined-bottom-productPrice" v-if="
                this.$store.state.product.SelectedProductBanner ==
                'Morningstar DividendInvestor' | this.$store.state.product.SelectedProductBanner ==
                'Morningstar ETFInvestor'">
                $239 Annual Digital <span class="pipe-symbol">|</span> $259 Annual Print + Digital
              </h1>
            </div>
            <mds-button id="footer" :target="this.$store.state.product.SelectedProductBanner" variation="secondary"
              class="combined-bottom-banner-button-color" @click="redirectToRegistration()">
              Subscribe
            </mds-button>
          </div>
          <div class="combined-bottom-banner-button" v-if="this.$store.state.user.LoggedIn">
            <div v-if="Subscribed">
              <mds-button variation="secondary" class="combined-bottom-banner-button-color"
                @click="redirectHomePageClick()">
                {{ this.currentProductText }}
              </mds-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { MdsButton } from "@mds/button";
export default {
  name: "banner-bottom-cover-image",
  components: {
    MdsButton,
  },
  data() {
    return {
      logoutUser: false,
      MEIAccess: "",
      MDIAccess: "",
      MSIAccess: "",
      MFIAccess: "",
    };
  },
  mounted() {

  },
  methods: {
    redirectHomePageClick() {
      var currentProduct = "";
      if (
        this.$store.state.product.SelectedProductBanner ==
        "Morningstar FundInvestor"
      ) {
        currentProduct = "mfi";
      } else if (
        this.$store.state.product.SelectedProductBanner ==
        "Morningstar StockInvestor"
      ) {
        currentProduct = "msi";
      } else if (
        this.$store.state.product.SelectedProductBanner ==
        "Morningstar DividendInvestor"
      ) {
        currentProduct = "mdi";
      } else if (
        this.$store.state.product.SelectedProductBanner ==
        "Morningstar ETFInvestor"
      ) {
        currentProduct = "mei";
      }
      this.$router.push({
        name: "home",
        params: {
          currentproduct: currentProduct.toLowerCase(),
        },
      }).catch(err => {
        if (
          err.name !== 'NavigationDuplicated' &&
          !err.message.includes('Avoided redundant navigation to current location')
        ) {
          console.log(err);
        }
      });
    },
    checkSelectedProductBanner() {
      if (this.$store.state.user.LoggedIn) {
        this.productDataRoles =
          this.$auth0.user["https://morningstar.com/uim_roles"];
        if (this.productDataRoles != undefined && this.productDataRoles != null) {
          this.MEIAccess = this.productDataRoles.includes("EI_MEMBER")
            ? "Morningstar ETFInvestor"
            : "";
          this.MDIAccess = this.productDataRoles.includes("DI_MEMBER")
            ? "Morningstar DividendInvestor"
            : "";
          this.MSIAccess = this.productDataRoles.includes("SI_MEMBER")
            ? "Morningstar StockInvestor"
            : "";
          this.MFIAccess = this.productDataRoles.includes("FI_MEMBER")
            ? "Morningstar FundInvestor"
            : "";
        }
        if (this.$store.state.product.SelectedProductBanner == this.MFIAccess) {
          this.loginUser = true;
        } else if (
          this.$store.state.product.SelectedProductBanner == this.MSIAccess
        ) {
          this.loginUser = true;
        } else if (
          this.$store.state.product.SelectedProductBanner == this.MDIAccess
        ) {
          this.loginUser = true;
        } else if (
          this.$store.state.product.SelectedProductBanner == this.MEIAccess
        ) {
          this.loginUser = true;
        }
      } else {
        this.logoutUser = true;
      }
    },
    getReferID(ProductName) {
      if (ProductName.toUpperCase() == 'MORNINGSTAR ETFINVESTOR')
        return "ETFGEN1";
      else if (ProductName.toUpperCase() == 'MORNINGSTAR DIVIDENDINVESTOR')
        return "ADIGRDG7";
      else if (ProductName.toUpperCase() == 'MORNINGSTAR STOCKINVESTOR')
        return "AMSGRDG7";
      else if (ProductName.toUpperCase() == 'MORNINGSTAR FUNDINVESTOR')
        return "AFNGRDG7";
    },
    getProductCode(ProductName) {
      if (ProductName.toUpperCase() == 'MORNINGSTAR ETFINVESTOR')
        return "NET0000001";
      else if (ProductName.toUpperCase() == 'MORNINGSTAR DIVIDENDINVESTOR')
        return "NDI0000001";
      else if (ProductName.toUpperCase() == 'MORNINGSTAR STOCKINVESTOR')
        return "NM00000001";
      else if (ProductName.toUpperCase() == 'MORNINGSTAR FUNDINVESTOR')
        return "NF00000001";
    },
    redirectToRegistration() {
      var selectedProduct = this.$store.state.product.SelectedProductBanner;
      var productCode = this.getProductCode(selectedProduct);
      var productReferId = this.getReferID(selectedProduct);
      this.$router.push({
        name: 'purchase',
        params: {
          referid: productReferId,
          productcode: productCode
        },
      })
    }
  },
  computed: {
    headerImage() {
      if (this.$store.state.product.SelectedProductBanner) {
        var images = require.context(
          "../../../assets/Images/CombinedBannerBottom/",
          false,
          /\.png$/
        );
        return images(
          "./" + this.$store.state.product.SelectedProductBanner + ".png"
        );
      } else {
        return images("./Morningstar FundInvestor.png");
      }
    },
    Subscribed() {
      if (this.$store.state.user.LoggedIn) {
        var productDataRoles =
          this.$auth0.user["https://morningstar.com/uim_roles"];
        if (productDataRoles != undefined && productDataRoles != null) {
          var MEIAccess = productDataRoles.includes("EI_MEMBER")
            ? "Morningstar ETFInvestor"
            : "";
          var MDIAccess = productDataRoles.includes("DI_MEMBER")
            ? "Morningstar DividendInvestor"
            : "";
          var MSIAccess = productDataRoles.includes("SI_MEMBER")
            ? "Morningstar StockInvestor"
            : "";
          var MFIAccess = productDataRoles.includes("FI_MEMBER")
            ? "Morningstar FundInvestor"
            : "";
        }
        if (
          this.$store.state.product.SelectedProductBanner == MFIAccess ||
          this.$store.state.product.SelectedProductBanner == MSIAccess ||
          this.$store.state.product.SelectedProductBanner == MDIAccess ||
          this.$store.state.product.SelectedProductBanner == MEIAccess
        ) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    currentProductText() {
      var currentProduct = "";
      if (
        this.$store.state.product.SelectedProductBanner ==
        "Morningstar FundInvestor"
      ) {
        currentProduct = "FundInvestor";
      } else if (
        this.$store.state.product.SelectedProductBanner ==
        "Morningstar StockInvestor"
      ) {
        currentProduct = "StockInvestor";
      } else if (
        this.$store.state.product.SelectedProductBanner ==
        "Morningstar DividendInvestor"
      ) {
        currentProduct = "DividendInvestor";
      } else if (
        this.$store.state.product.SelectedProductBanner ==
        "Morningstar ETFInvestor"
      ) {
        currentProduct = "ETFInvestor";
      }
      return "Go To " + currentProduct;
    },
    bannerProductTitle() {
      var text = "";
      if (
        this.$store.state.product.SelectedProductBanner ==
        "Morningstar FundInvestor"
      ) {
        text =
          "Get the gold standard in mutual fund research from the company that invented the field.";
      } else if (
        this.$store.state.product.SelectedProductBanner ==
        "Morningstar StockInvestor"
      ) {
        text = "Invest in the wide-moat stocks of today <br> (and tomorrow).";
      } else if (
        this.$store.state.product.SelectedProductBanner ==
        "Morningstar DividendInvestor"
      ) {
        text = "When a company thrives, its investors should too.";
      } else if (
        this.$store.state.product.SelectedProductBanner ==
        "Morningstar ETFInvestor"
      ) {
        text = "Find investment opportunities from across the globe.";
      }
      return text;
    },
  },
};
</script>

<style scoped>
body {
  font-family: "Univers", HelveticaNeue, "Helvetica Neue", Helvetica, Arial,
    sans-serif;
}

.banner-bottom-main-div {
  /*width:100%;*/
  margin-top: 61px;
  width: 100vw;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  background: #E5E5E5;
  height: 350px;
}

.combined-bottom-div-style {
  position: absolute;
  top: -300px;
  left: 44%;
  width: 52%;
  max-width: 785px;
}

.combined-bottom-banner-heading {
  font-style: normal;
  font-weight: 250;
  font-size: 45px;
  line-height: 50px;
  color: #ffffff;
}

.combined-bottom-banner-button {
  margin-top: 10px;
}

.combined-bottom-banner-button-color {
  background: #ffffff;
  color: #1e1e1e;
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 21px;
  margin-top: 20px;
}

.mds-button.mds-button--secondary,
a.mds-button.mds-button--secondary {
  background: #ffffff;
}

.combined-bottom-banner-text {
  width: 100%;
}

.combined-bottom-divBorder {
  margin-top: 20px;
  border: 1px solid #ffffff;
  width: 372px;
}

.combined-bottom-productName {
  font-style: normal;
  font-weight: 300;
  font-size: 20px;
  line-height: 23px;
  color: #ffffff;
  margin-top: 5px;
}

.combined-bottom-productPrice {
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 23px;
  color: #ffffff;
  margin-top: 5px;
}

.combined-bottom-mdi-statictext {
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 36px;
  color: #ffffff;
  margin-top: 5px;
}

#MdiBanner {
  background-image: linear-gradient(270deg, #5A5761 65.78%, rgba(90, 87, 97, 0.1875) 85.32%, rgba(90, 87, 97, 0) 97.98%),
    url("../../../assets/Images/CombinedBannerBottom/MdiBannerImage2500.jpg");
  width: 100%;
  height: 350px;
  background-size: contain;
  background-repeat: no-repeat;
}

#MsiBanner {
  background-image: linear-gradient(269deg, #718586 65.83%, rgba(122, 122, 122, 0.182243) 85.23%,
      rgba(122, 122, 122, 0.1875) 90.58%,
      rgba(128, 128, 128, 0) 160.62%),
    url("../../../assets/Images/CombinedBannerBottom/MsiBannerImage2500.jpg");
  width: 100%;
  height: 350px;
  background-size: contain;
  background-repeat: no-repeat;
}

#MfiBanner {
  background-image: linear-gradient(270deg,
      rgba(37, 37, 37, 255) 60.04%,
      rgba(0, 0, 0, 0) 95%),
    url("../../../assets/Images/CombinedBannerBottom/MFiBannerImage.jpg");
  width: 100%;
  height: 350px;
  background-size: contain;
  background-repeat: no-repeat;
}

#ETFBanner {
  background-image: linear-gradient(269deg, #253449 59.83%, rgba(37, 52, 73, 0.58) 72.23%, rgba(37, 52, 73, 0) 82.58%),
    url("../../../assets/Images/CombinedBannerBottom/EtfBannerImage.jpg");
  width: 100%;
  height: 350px;
  background-size: contain;
  background-repeat: no-repeat;
}

@media only screen and (min-width:320px) and (max-width:1024px) {
  .banner-bottom-main-div {
    width: 100%;
    margin-top: 61px;
  }
}

@media only screen and (min-width:320px) and (max-width:359px) {
  .combined-bottom-div-style {
    width: 160px;
    left: 153px;
    top: -355px;
  }

  .combined-bottom-banner-heading {
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    line-height: 22px;
    color: #FFFFFF;
  }

  .combined-bottom-divBorder {
    margin-top: 33px;
    border: 1px solid #FFFFFF;
    width: 100%;
  }

  .combined-bottom-productName {
    font-style: normal;
    font-weight: 300;
    font-size: 17px;
    line-height: 22px;
    color: #FFFFFF;
    margin-top: 6px;
  }

  .combined-bottom-productPrice {
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    color: #FFFFFF;
    margin-top: 16px;
  }

  .combined-bottom-mdi-statictext {
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 25px;
    color: #ffffff;
    margin-top: 5px;
  }

  .combined-bottom-banner-button-color {
    background: #ffffff;
    color: #1e1e1e;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 21px;
    margin-top: 17px;
  }

  .pipe-symbol {
    color: #ABABAB;
  }
}

@media only screen and (min-width:320px) and (max-width:700px) {
  .banner-bottom-main-div {
    margin-top: 61px;
    width: 100vw;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    background: #E5E5E5;
    height: 390px;
  }

  #MdiBanner {
    background-image: linear-gradient(270deg,
        rgba(90, 86, 96, 255) 50.04%,
        rgba(0, 0, 0, 0) 80%),
      url("../../../assets/Images/CombinedBannerBottom/MdiBannerImageMobile.jpg");
    width: 100%;
    height: 390px;
    background-size: cover;
    background-repeat: no-repeat;
  }

  #MsiBanner {
    background-image: linear-gradient(270deg,
        rgba(113, 132, 134, 255) 50.04%,
        rgba(0, 0, 0, 0) 80%),
      url("../../../assets/Images/CombinedBannerBottom/MsiBannerImageMobile.jpg");
    width: 100%;
    height: 390px;
    background-size: cover;
    background-repeat: no-repeat;
  }

  #MfiBanner {
    background-image: linear-gradient(270deg,
        rgba(37, 37, 37, 255) 50.04%,
        rgba(0, 0, 0, 0) 80%),
      url("../../../assets/Images/CombinedBannerBottom/MFiBannerImageMobile.jpg");
    width: 100%;
    height: 390px;
    background-size: cover;
    background-repeat: no-repeat;
  }

  #ETFBanner {
    background-image: linear-gradient(270deg,
        rgba(37, 52, 73, 255) 50.04%,
        rgba(0, 0, 0, 0) 80%),
      url("../../../assets/Images/CombinedBannerBottom/EtfBannerImageMobile.png");
    width: 100%;
    height: 390px;
    background-size: cover;
    background-repeat: no-repeat;
  }
}

@media only screen and (min-width:701px) and (max-width:767px) {
  .banner-bottom-main-div {
    margin-top: 61px;
    width: 100vw;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    background: #E5E5E5;
    height: 390px;
  }

  #MdiBanner {
    background-image: linear-gradient(270deg,
        rgba(90, 86, 96, 255) 50.04%,
        rgba(0, 0, 0, 0) 80%),
      url("../../../assets/Images/CombinedBannerBottom/MdiBannerImageMobile.jpg");
    width: 100%;
    height: 390px;
    background-size: contain;
    background-repeat: no-repeat;
  }

  #MsiBanner {
    background-image: linear-gradient(270deg,
        rgba(113, 132, 134, 255) 50.04%,
        rgba(0, 0, 0, 0) 80%),
      url("../../../assets/Images/CombinedBannerBottom/MsiBannerImageMobile.jpg");
    width: 100%;
    height: 390px;
    background-size: contain;
    background-repeat: no-repeat;
  }

  #MfiBanner {
    background-image: linear-gradient(270deg,
        rgba(37, 37, 37, 255) 50.04%,
        rgba(0, 0, 0, 0) 80%),
      url("../../../assets/Images/CombinedBannerBottom/MFiBannerImageMobile.jpg");
    width: 100%;
    height: 390px;
    background-size: contain;
    background-repeat: no-repeat;
  }

  #ETFBanner {
    background-image: linear-gradient(270deg,
        rgba(37, 52, 73, 255) 50.04%,
        rgba(0, 0, 0, 0) 80%),
      url("../../../assets/Images/CombinedBannerBottom/EtfBannerImageMobile.png");
    width: 100%;
    height: 390px;
    background-size: contain;
    background-repeat: no-repeat;
  }
}

@media only screen and (min-width:768px) and (max-width:962px) {
  .banner-bottom-main-div {
    margin-top: 61px;
    width: 100vw;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    background: #E5E5E5;
    height: 390px;
  }

  #MdiBanner {
    background-image: linear-gradient(270deg,
        rgba(90, 86, 96, 255) 50.04%,
        rgba(0, 0, 0, 0) 80%),
      url("../../../assets/Images/CombinedBannerBottom/MdiBannerImageMobile.jpg");
    width: 100%;
    height: 390px;
    background-size: contain;
    background-repeat: no-repeat;
  }

  #MsiBanner {
    background-image: linear-gradient(270deg,
        rgba(113, 132, 134, 255) 50.04%,
        rgba(0, 0, 0, 0) 80%),
      url("../../../assets/Images/CombinedBannerBottom/MsiBannerImageMobile.jpg");
    width: 100%;
    height: 390px;
    background-size: contain;
    background-repeat: no-repeat;
  }

  #MfiBanner {
    background-image: linear-gradient(270deg,
        rgba(37, 37, 37, 255) 50.04%,
        rgba(0, 0, 0, 0) 80%),
      url("../../../assets/Images/CombinedBannerBottom/MFiBannerImageMobile.jpg");
    width: 100%;
    height: 390px;
    background-size: contain;
    background-repeat: no-repeat;
  }

  #ETFBanner {
    background-image: linear-gradient(270deg,
        rgba(37, 52, 73, 255) 50.04%,
        rgba(0, 0, 0, 0) 80%),
      url("../../../assets/Images/CombinedBannerBottom/EtfBannerImageMobile.png");
    width: 100%;
    height: 390px;
    background-size: contain;
    background-repeat: no-repeat;
  }
}

@media only screen and (min-width:963px) and (max-width:1205px) {
  .banner-bottom-main-div {
    margin-top: 61px;
    width: 100vw;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    background: #E5E5E5;
    height: 390px;
  }

  #MdiBanner {
    background-image: linear-gradient(270deg,
        rgba(90, 86, 96, 255) 61.04%,
        rgba(0, 0, 0, 0) 85%),
      url("../../../assets/Images/CombinedBannerBottom/MdiBannerImageMobile.jpg");
    width: 100%;
    height: 390px;
    background-size: contain;
    background-repeat: no-repeat;
  }

  #MsiBanner {
    background-image: linear-gradient(270deg,
        rgba(113, 132, 134, 255) 61.04%,
        rgba(0, 0, 0, 0) 85%),
      url("../../../assets/Images/CombinedBannerBottom/MsiBannerImageMobile.jpg");
    width: 100%;
    height: 390px;
    background-size: contain;
    background-repeat: no-repeat;
  }

  #MfiBanner {
    background-image: linear-gradient(270deg,
        rgba(37, 37, 37, 255) 50.04%,
        rgba(0, 0, 0, 0) 80%),
      url("../../../assets/Images/CombinedBannerBottom/MFiBannerImageMobile.jpg");
    width: 100%;
    height: 390px;
    background-size: contain;
    background-repeat: no-repeat;
  }

  #ETFBanner {
    background-image: linear-gradient(270deg,
        rgba(37, 52, 73, 255) 61.04%,
        rgba(0, 0, 0, 0) 85%),
      url("../../../assets/Images/CombinedBannerBottom/EtfBannerImageMobile.png");
    width: 100%;
    height: 390px;
    background-size: contain;
    background-repeat: no-repeat;
  }
}

@media only screen and (min-width:360px) and (max-width:479px) {
  .combined-bottom-div-style {
    width: 188px;
    top: -360px;
    left: 45%;
  }

  .combined-bottom-banner-heading {
    font-style: normal;
    font-weight: 300;
    font-size: 23px;
    line-height: 27px;
    color: #FFFFFF;
  }

  .combined-bottom-divBorder {
    margin-top: 20px;
    border: 1px solid #ffffff;
    width: 100%;
  }

  .combined-bottom-productName {
    font-style: normal;
    font-weight: 300;
    font-size: 17px;
    line-height: 22px;
    color: #FFFFFF;
    margin-top: 6px;
  }

  .combined-bottom-productPrice {
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    color: #FFFFFF;
    margin-top: 16px;
  }

  .combined-bottom-mdi-statictext {
    font-style: normal;
    font-weight: 700;
    font-size: 21px;
    line-height: 26px;
    color: #ffffff;
    margin-top: 5px;
  }

  .combined-bottom-banner-button-color {
    background: #ffffff;
    color: #1e1e1e;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 21px;
    margin-top: 17px;
  }

  .pipe-symbol {
    color: #ABABAB;
  }
}

@media only screen and (min-width:480px) and (max-width:600px) {
  .combined-bottom-div-style {
    top: -350px;
    left: 50%;
    width: 236px;
  }

  .combined-bottom-banner-heading {
    font-style: normal;
    font-weight: 250;
    font-size: 23px;
    line-height: 27px;
    color: #ffffff;
  }

  .combined-bottom-divBorder {
    margin-top: 20px;
    border: 1px solid #ffffff;
    width: 100%;
  }

  .combined-bottom-productName {
    font-style: normal;
    font-weight: 300;
    font-size: 20px;
    line-height: 22px;
    color: #FFFFFF;
    margin-top: 6px;
  }

  .combined-bottom-productPrice {
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    color: #FFFFFF;
    margin-top: 16px;
  }

  .combined-bottom-mdi-statictext {
    font-style: normal;
    font-weight: 700;
    font-size: 25px;
    line-height: 30px;
    color: #ffffff;
    margin-top: 5px;
  }

  .combined-bottom-banner-button-color {
    background: #ffffff;
    color: #1e1e1e;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 21px;
    margin-top: 17px;
  }

  .pipe-symbol {
    color: #ABABAB;
  }
}

@media only screen and (min-width:601px) and (max-width:900px) {
  .combined-bottom-div-style {
    width: 300px;
    top: -360px;
    left: 49%;
  }

  .combined-bottom-banner-heading {
    font-style: normal;
    font-weight: 250;
    font-size: 30px;
    line-height: 34px;
    color: #ffffff;
  }

  .combined-bottom-divBorder {
    margin-top: 20px;
    border: 1px solid #ffffff;
    width: 100%;
  }

  .combined-bottom-productName {
    font-style: normal;
    font-weight: 300;
    font-size: 20px;
    line-height: 22px;
    color: #FFFFFF;
    margin-top: 6px;
  }

  .combined-bottom-productPrice {
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    color: #FFFFFF;
    margin-top: 16px;
  }

  .combined-bottom-mdi-statictext {
    font-style: normal;
    font-weight: 700;
    font-size: 25px;
    line-height: 30px;
    color: #ffffff;
    margin-top: 5px;
  }

  .combined-bottom-banner-button-color {
    background: #ffffff;
    color: #1e1e1e;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 21px;
    margin-top: 17px;
  }

  .pipe-symbol {
    color: #ABABAB;
  }
}

@media only screen and (min-width:901px) and (max-width:1205px) {
  .combined-bottom-div-style {
    width: 400px;
    left: 52%;
    top: -310px;
  }

  .combined-bottom-banner-heading {
    font-style: normal;
    font-weight: 250;
    font-size: 23px;
    line-height: 30px;
    color: #ffffff;
  }

  .pipe-symbol {
    color: #ABABAB;
  }
}

@media only screen and (min-width:1025px) and (max-width:1160px) {
  .combined-bottom-div-style {
    width: 400px;
    left: 52%;
    top: -330px;
  }
}

@media only screen and (min-width:1700px) and (max-width:1999px) {
  #MfiBanner {
    background-image: linear-gradient(270deg, rgba(37, 37, 37, 255) 70.04%, rgba(0, 0, 0, 0) 91%), url("../../../assets/Images/CombinedBannerBottom/MFiBannerImage.jpg");
    width: 100%;
    height: 350px;
    background-size: contain;
    background-repeat: no-repeat;
  }

  #MsiBanner {
    background-image: linear-gradient(269deg, #718586 73.83%, rgba(122, 122, 122, 0.182243) 87.23%, rgba(122, 122, 122, 0.1875) 90.58%, rgba(128, 128, 128, 0) 160.62%),
      url("../../../assets/Images/CombinedBannerBottom/MsiBannerImage.jpg");
    width: 100%;
    height: 350px;
    background-size: contain;
    background-repeat: no-repeat;
  }

  #MdiBanner {
    background-image: linear-gradient(270deg, #5A5761 73.78%, rgba(90, 87, 97, 0.1875) 91.32%, rgba(90, 87, 97, 0) 97.98%),
      url("../../../assets/Images/CombinedBannerBottom/MdiBannerImage.jpg");
    width: 100%;
    height: 350px;
    background-size: contain;
    background-repeat: no-repeat;
  }

  #ETFBanner {
    background-image: linear-gradient(269deg, #253449 68.83%, rgba(37, 52, 73, 0.58) 77.23%, rgba(37, 52, 73, 0) 82.58%),
      url("../../../assets/Images/CombinedBannerBottom/EtfBannerImage.jpg");
    width: 100%;
    height: 350px;
    background-size: contain;
    background-repeat: no-repeat;
  }
}

@media only screen and (min-width:2000px) and (max-width:2799px) {
  .banner-bottom-main-div {
    margin-top: 115px;
    width: 100vw;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    background: #E5E5E5;
    height: 450px;
  }

  .combined-bottom-div-style {
    position: absolute;
    top: -370px;
    left: 46%;
  }

  .banner-bottom-container {
    width: 100vw;

  }

  #MfiBanner {
    background-image: linear-gradient(270deg, rgba(37, 37, 37, 255) 69.04%, rgba(0, 0, 0, 0) 86%), url("../../../assets/Images/CombinedBannerBottom/MFiBannerImage2500.jpg");
    ;
    width: 100%;
    height: 450px;
    background-size: contain;
    background-repeat: no-repeat;
  }

  #MsiBanner {
    background-image: linear-gradient(269deg, #718586 74.83%, rgba(122, 122, 122, 0.182243) 89.23%,
        rgba(122, 122, 122, 0.1875) 90.58%,
        rgba(128, 128, 128, 0) 160.62%),
      url("../../../assets/Images/CombinedBannerBottom/MsiBannerImage2500.jpg");
    width: 100%;
    height: 450px;
    background-size: contain;
    background-repeat: no-repeat;
  }

  #MdiBanner {
    background-image: linear-gradient(270deg, #5A5761 69.78%, rgba(90, 87, 97, 0.1875) 84.32%, rgba(90, 87, 97, 0) 97.98%),
      url("../../../assets/Images/CombinedBannerBottom/MdiBannerImage2500.jpg");
    width: 100%;
    height: 450px;
    background-size: contain;
    background-repeat: no-repeat;
  }

  #ETFBanner {
    background-image: linear-gradient(269deg, #253449 69.83%, rgba(37, 52, 73, 0.58) 79.23%, rgba(37, 52, 73, 0) 93.58%),
      url("../../../assets/Images/CombinedBannerBottom/EtfBannerImage.jpg");
    width: 100%;
    height: 450px;
    background-size: contain;
    background-repeat: no-repeat;
  }
}

@media only screen and (min-width:2800px) and (max-width:3000px) {
  .banner-bottom-main-div {
    margin-top: 115px;
    width: 100vw;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    background: #E5E5E5;
    height: 450px;
  }

  .combined-bottom-div-style {
    position: absolute;
    top: -370px;
    left: 46%;
  }

  .banner-bottom-container {
    width: 100vw;

  }

  #MfiBanner {
    background-image: linear-gradient(270deg, rgba(37, 37, 37, 255) 74.04%, rgba(0, 0, 0, 0) 87%), url("../../../assets/Images/CombinedBannerBottom/MFiBannerImage2500.jpg");
    ;
    width: 100%;
    height: 450px;
    background-size: contain;
    background-repeat: no-repeat;
  }

  #MsiBanner {
    background-image: linear-gradient(269deg, #718586 74.83%, rgba(122, 122, 122, 0.182243) 89.23%,
        rgba(122, 122, 122, 0.1875) 90.58%,
        rgba(128, 128, 128, 0) 160.62%),
      url("../../../assets/Images/CombinedBannerBottom/MsiBannerImage2500.jpg");
    width: 100%;
    height: 450px;
    background-size: contain;
    background-repeat: no-repeat;
  }

  #MdiBanner {
    background-image: linear-gradient(270deg, #5A5761 74.78%, rgba(90, 87, 97, 0.1875) 89.32%, rgba(90, 87, 97, 0) 97.98%),
      url("../../../assets/Images/CombinedBannerBottom/MdiBannerImage2500.jpg");
    width: 100%;
    height: 450px;
    background-size: contain;
    background-repeat: no-repeat;
  }

  #ETFBanner {
    background-image: linear-gradient(269deg, #253449 74.83%, rgba(37, 52, 73, 0.58) 82.23%, rgba(37, 52, 73, 0) 93.58%),
      url("../../../assets/Images/CombinedBannerBottom/EtfBannerImage.jpg");
    width: 100%;
    height: 450px;
    background-size: contain;
    background-repeat: no-repeat;
  }
}

@media only screen and (min-width:3001px) and (max-width:5000px) {
  .banner-bottom-main-div {
    margin-top: 115px;
    width: 100vw;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    background: #E5E5E5;
    height: 500px;
  }

  .combined-bottom-div-style {
    position: absolute;
    top: -380px;
    left: 46%;
  }

  .banner-bottom-container {
    width: 100vw;

  }

  #MfiBanner {
    background-image: linear-gradient(270deg, rgba(37, 37, 37, 255) 78.04%, rgba(0, 0, 0, 0) 93%), url("../../../assets/Images/CombinedBannerBottom/MFiBannerImage2500.jpg");
    ;
    width: 100%;
    height: 500px;
    background-size: contain;
    background-repeat: no-repeat;
  }

  #MsiBanner {
    background-image: linear-gradient(269deg, #718586 78.83%, rgba(122, 122, 122, 0.182243) 89.23%,
        rgba(122, 122, 122, 0.1875) 90.58%,
        rgba(128, 128, 128, 0) 160.62%),
      url("../../../assets/Images/CombinedBannerBottom/MsiBannerImage2500.jpg");
    width: 100%;
    height: 500px;
    background-size: contain;
    background-repeat: no-repeat;
  }

  #MdiBanner {
    background-image: linear-gradient(270deg, #5A5761 78.78%, rgba(90, 87, 97, 0.1875) 89.32%, rgba(90, 87, 97, 0) 97.98%),
      url("../../../assets/Images/CombinedBannerBottom/MdiBannerImage2500.jpg");
    width: 100%;
    height: 500px;
    background-size: contain;
    background-repeat: no-repeat;
  }

  #ETFBanner {
    background-image: linear-gradient(269deg, #253449 78.83%, rgba(37, 52, 73, 0.58) 82.23%, rgba(37, 52, 73, 0) 93.58%),
      url("../../../assets/Images/CombinedBannerBottom/EtfBannerImage.jpg");
    width: 100%;
    height: 500px;
    background-size: contain;
    background-repeat: no-repeat;
  }
}

@media only screen and (min-width:5001px) and (max-width:6000px) {
  .banner-bottom-main-div {
    margin-top: 115px;
    width: 100vw;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    background: #E5E5E5;
    height: 600px;
  }

  .combined-bottom-div-style {
    position: absolute;
    top: -480px;
    left: 46%;
  }

  .banner-bottom-container {
    width: 100vw;

  }

  #MfiBanner {
    background-image: linear-gradient(270deg, rgba(37, 37, 37, 255) 80.04%, rgba(0, 0, 0, 0) 93%), url("../../../assets/Images/CombinedBannerBottom/MFiBannerImage2500.jpg");
    ;
    width: 100%;
    height: 600px;
    background-size: contain;
    background-repeat: no-repeat;
  }

  #MsiBanner {
    background-image: linear-gradient(269deg, #718586 78.83%, rgba(122, 122, 122, 0.182243) 89.23%,
        rgba(122, 122, 122, 0.1875) 90.58%,
        rgba(128, 128, 128, 0) 160.62%),
      url("../../../assets/Images/CombinedBannerBottom/MsiBannerImage2500.jpg");
    width: 100%;
    height: 600px;
    background-size: contain;
    background-repeat: no-repeat;
  }

  #MdiBanner {
    background-image: linear-gradient(270deg, #5A5761 81.78%, rgba(90, 87, 97, 0.1875) 93.32%, rgba(90, 87, 97, 0) 97.98%),
      url("../../../assets/Images/CombinedBannerBottom/MdiBannerImage2500.jpg");
    width: 100%;
    height: 600px;
    background-size: contain;
    background-repeat: no-repeat;
  }

  #ETFBanner {
    background-image: linear-gradient(269deg, #253449 80.83%, rgba(37, 52, 73, 0.58) 87.23%, rgba(37, 52, 73, 0) 93.58%),
      url("../../../assets/Images/CombinedBannerBottom/EtfBannerImage.jpg");
    width: 100%;
    height: 600px;
    background-size: contain;
    background-repeat: no-repeat;
  }
}

.pipe-symbol {
  color: #ABABAB;
}
</style>