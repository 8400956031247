<template>
    <div class="product-subscription-overlay">
      <!-- Background Overlay -->
      <div v-if="toggleOverlay" class="modal-overlay"></div>
  
      <!-- Modal Content -->
      <mds-modal size="small" width="600px" aria-label="Small Modal" v-model="toggleOverlay" class="product-subscription-modal">
        
        <div class="product-subscription-container">
           
           <div class="product-subscription-content">
          <div class="product-subscription-heading">
            <span>You need a subscription to view this newsletter</span>
          </div>
        <div class="button-container">
            <mds-button-container >
                <mds-button @click="resetOverlayStatus" variation="icon-only" icon="remove">Close</mds-button>
            </mds-button-container>
        </div>
        </div>

          <div class="product-subscription-border-seperator"></div>
          <div class="product-subscription-descriptive-text">
            <span>
              You don’t currently subscribe to Morningstar Investor Newsletters. Continue to our Newsletters
              homepage to learn more about our strategies for do-it-yourself investors.
            </span>
          </div>
          <div class="product-subscription-button">
            <mds-button variation="primary" size="large" @click="resetOverlayStatus()"> Continue to Homepage </mds-button>
          </div>
        </div>
      </mds-modal>
    </div>
  </template>
  
  <script lang="js">
  import MdsModal from '@mds/modal';
  import { MdsButton } from '@mds/button';
  
  export default {
    name: 'product-subscription-overlay',
    props: {
      subscriptionoverlayStatus: {
        type: Boolean,
        required: true,
      }
    },
    data() {
      return {
        toggleOverlay: this.subscriptionoverlayStatus,
      }
    },
    components: {
      MdsModal,
      MdsButton
    },
    methods: {
      resetOverlayStatus() {
        this.toggleOverlay = false;
      },
    },
  }
  </script>
  
  <style scoped>
  .product-subscription-container {
    max-width: 520px;
    margin: 0 auto;
  }
  
  .product-subscription-heading {
    width: 99%;
    color: #000;
    font-size: 45px;
    font-weight: 250;
    line-height: 50px;
    margin-bottom: 48px;
    margin-top: 32px;
  }
  
  .product-subscription-border-seperator {
    height: 1px;
    background: #808080;
    margin-bottom: 12px;
  }
  
  .product-subscription-descriptive-text {
    font-size: 23px;
    font-weight: 300;
    line-height: 27px;
    margin-bottom: 46px;
  }
  
  .product-subscription-button {
    margin-top: 46px;
    margin-bottom: 32px;
  }
  
  
  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    
    z-index: 101; 
    
  }
  
 
  .product-subscription-modal .mds-modal__wrapper {
    z-index: 3; 
  }
  
  @media only screen and (max-width: 320px) {
    .product-subscription-button button {
      font-size: 18px;
    }
  }
  
  @media only screen and (max-width: 767px) {
    .product-subscription-modal .mds-modal__wrapper {
      max-height: 60vh;
    }
    
    .product-subscription-content {
    width: 100%;
  display: flex;              
  justify-content: space-between; 
  align-items: center;         
}
  
    .product-subscription-heading {
        width: 99%;
      font-size: 24px;
      line-height: 35px;
      margin-top: 10px;
      margin-bottom: 20px;
    }

    .button-container {
        padding-right: 5%;
   padding-top: 25%;
    width: 1%;
  display: flex;              
  padding-left: 0px;    
}
  
    .product-subscription-descriptive-text {
      font-size: 20px;
      font-weight: 25px;
      margin-bottom: 20px;
    }
  
    .product-subscription-button {
      margin-top: 20px;
      margin-bottom: 20px;
    }
  
    .overlay-sign-in-button-text {
      font-size: 18px;
      font-weight: 23px;
    }
  }
  
  
  @media only screen and (max-width: 320px) {
    .product-subscription-modal .mds-modal__content {
      overflow-y: scroll;
    }
  }

  .product-subscription-content {
    width: 100%;
  display: flex;              
  justify-content: space-between; 
  align-items: center;         
}

.button-container {
    margin-top: -28%;
    width: 1%;
  display: flex;              
  padding-left: 0px;    
}

  </style>
  