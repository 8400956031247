<template>
    <div class="print-sub-background-color">
        <mds-page-shell :logo="getProductLogo()" :logo-responsive="getProductLogo()" logo-href="/"
            logo-alt="Back to home page">
            <div class="print-subscription-loader" v-if="isLoading">
                <mds-loader aria-label="Large Loader" size="large"></mds-loader>
            </div>
            <div class="print-subscription-main">
                <div class="print-subscription-main-container">
                    <div class="print-subscription-form">
                        <div class="print-subscription-form-container">
                            <div class="print-subscription-title">
                                Subscription information
                            </div>
                            <div class="print-subscrription-form-fields">
                                <mds-select label="Publication Name" placeholder="Select..." size="large"
                                    v-model.trim="publicationName" @change="onChangePublicationName" required>
                                    <option v-for="publicationName in lstPublicationName" :key="publicationName.text"
                                        :value="publicationName.value">
                                        {{ publicationName.text }}
                                    </option>
                                </mds-select>
                                <span class="FormsError" v-if="publicationNameError">Publication name required</span>
                                <br />
                                <div v-if="!productSubscribed">
                                    <mds-input size="large" v-model.trim="SubscriptionId" label="Subscription ID"
                                        type="text" required></mds-input>
                                    <span class="FormsError" v-if="SubscriptionIdError">{{
                                        SubscriptionIdErrorMessage
                                    }}</span>
                                    <br />
                                    <div class="horizonatal-subcription-fieldset">
                                        <div class="subscription-fieldset-first">
                                            <mds-input size="large" v-model.trim="Zipcode" :label="zipLable" type="text"
                                                required></mds-input>
                                            <span class="FormsError" v-if="zipcodeError">{{
                                                zipcodeErrorMessage
                                            }}</span>
                                        </div>
                                        <div class="subscription-fieldset-second">
                                            <mds-select v-model.trim="country" class="input-tab" size="large"
                                                label="Country" type="text" required @change="onChangeCountry">
                                                <option v-bind:key="country.CountryCode" :value="country.Country"
                                                    v-for="country in listCountry">
                                                    {{ country.Country }}
                                                </option>
                                            </mds-select>
                                        </div>
                                    </div>
                                    <mds-alert mds-alert-dismissed variation="error" class="print-registrationError"
                                        v-if="registrationError" @mds-alert-dismissed="closeAlert()"
                                        :tinted="registrationError">
                                        {{ registrationErrorMessage }}
                                    </mds-alert>
                                    <div class="print-sub-img">
                                        <img src="@/assets/PrintSubscription/PrintSubscription.png" height="261px" />
                                    </div>
                                    <div class="print-sub-lastline">
                                        Your subscription ID is located on the back of your print newsletter next to the
                                        address
                                        box.
                                    </div>
                                </div>
                                <div v-if="productSubscribed">
                                    <div class="print-sub-exists">
                                        <span>You've already registered for this product.<br /></span>
                                        <span>Select a different Newsletter for digital registration.</span>
                                    </div>
                                </div>
                                <div :class="!productSubscribed ? 'print-subscription-button-container' : 'print-subscription-button-container-productExists'"
                                    class="print-subscription-button-container">
                                    <div>
                                        <mds-button-container left-aligned>
                                            <!-- Icon On Left -->
                                            <mds-button variation="secondary" size="large" @click="
                                                $router.push({
                                                    name: 'print-registration',
                                                    params: { currentproduct: currentProduct },
                                                })
                                                ">
                                                Back
                                            </mds-button>
                                            <div class="print-subscription-cancel-button">
                                                <mds-button variation="secondary" size="large" type="button" @click="
                                                    $router.push({
                                                        name: 'combined-home',
                                                        params: { currentproduct: 'Common' },
                                                    })
                                                    ">
                                                    Cancel
                                                </mds-button>
                                            </div>
                                            <div class="print-subscription-continue-button">
                                                <!-- Icon On Both Sides -->
                                                <div v-if=!productSubscribed>
                                                    <mds-button variation="primary" size="large" class="continue-button"
                                                        type="button" right-aligned @click="ContinueForm">
                                                        Register
                                                    </mds-button>
                                                </div>
                                                <div v-if=productSubscribed>
                                                    <mds-button variation="primary" size="large" class="continue-button"
                                                        type="button" right-aligned disabled>
                                                        Register
                                                    </mds-button>
                                                </div>
                                            </div>
                                        </mds-button-container>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="subscribe-page-bottom"></div>
                    </div>
                </div>
            </div>
        </mds-page-shell>
    </div>
</template>

<script>
import MdsAlert from "@mds/alert";
import MdsInput from "@mds/input";
import MdsSelect from "@mds/select";
import { MdsButton, MdsButtonContainer } from "@mds/button";
import MdsPageShell from '@mds/page-shell';
import MdsLoader from "@mds/loader";
import { postData } from "@/utilities/API/apiUtility";
export default {
    name: "print-registration-subscribe",
    components: {
        MdsInput,
        MdsSelect,
        MdsButton,
        MdsPageShell,
        MdsButtonContainer,
        MdsAlert,
        MdsLoader
    },
    data() {
        return {
            lstPublicationName: [
                { text: 'FundInvestor', value: 'FSN' },
                { text: 'StockInvestor', value: 'MSI' },
                { text: 'DividendInvestor', value: 'DIV' },
                { text: 'ETFInvestor', value: 'ETN' },
            ],
            listCountry: this.$store.state.country.contryList,
            publicationName: "",
            SubscriptionId: "",
            Zipcode: "",
            country: "USA",

            zipcodeError: false,
            SubscriptionIdError: false,
            publicationNameError: false,

            SubscriptionIdErrorMessage: "",
            zipcodeErrorMessage: "",

            productSubscribed: false,
            currentProduct: this.$route.params.currentproduct,

            MEIAccess: false,
            MDIAccess: false,
            MSIAccess: false,
            MFIAccess: false,

            registrationError: false,

            phonenumber: "",
            phonetype: "",
            isLoading: true,
            registrationErrorMessage: "We were unable to process your transaction. Please try again.",
            MPhone: "",
            DPhone: "",
            zipLable: "Zip Code",
        }
    },
    methods: {
        async closeAlert() {
            this.registrationError = false;
        },
        onChangePublicationName() {
            this.publicationNameError = false,
                this.productSubscribed = false;
            if (this.publicationName == "FSN" && this.MFIAccess) {
                this.productSubscribed = true;
            }
            else if (this.publicationName == "MSI" && this.MSIAccess) {
                this.productSubscribed = true;
            }
            else if (this.publicationName == "DIV" && this.MDIAccess) {
                this.productSubscribed = true;
            }
            else if (this.publicationName == "ETN" && this.MEIAccess) {
                this.productSubscribed = true;
            }
        },
        async getPublicationName(productName) {
            if (productName.toLowerCase() == "mfi")
                this.publicationName = "FSN";
            else if (productName.toLowerCase() == "msi")
                this.publicationName = "MSI";
            else if (productName.toLowerCase() == "mdi")
                this.publicationName = "DIV";
            else if (productName.toLowerCase() == "mei")
                this.publicationName = "ETN";
        },
        ChangeContryName(Name) {
            var ContryName = "";
            if (Name) {
                if (Name.toUpperCase() == "USA") {
                    ContryName = Name.toUpperCase();
                } else {
                    var lowerText = Name.toLowerCase();
                    var temp = lowerText.split(' ');
                    if (temp) {
                        for (var i in temp) {
                            var obj = temp[i];
                            ContryName += obj.charAt(0).toUpperCase() + obj.slice(1) + " ";
                        }
                    }
                    ContryName = ContryName.substring(0, ContryName.length - 1);
                }

            }
            return ContryName;
        },
        getProductLogo() {
            var images = require.context(
                "../../assets/Images",
                false,
                /\.svg$/
            );
            return images("./Common.svg");
        },
        async ContinueForm() {
            this.isLoading = true;
            if (this.publicationName == "" || this.publicationName == null) {
                this.publicationNameError = true;
            }
            if (this.SubscriptionId == "" || this.SubscriptionId == null) {
                this.SubscriptionIdError = true;
                this.SubscriptionIdErrorMessage = "Subscription ID required";
            }
            else if (
                !/^\+?(\d[\d-. ]+)?(\([\d-. ]+\))?[\d-. ]+\d$/.test(this.SubscriptionId)
            ) {
                this.SubscriptionIdError = true;
                this.SubscriptionIdErrorMessage = "Subscription ID should be a numerical field";
            }
            if (this.Zipcode == "" || this.Zipcode == null) {
                this.zipcodeError = true;
                this.zipcodeErrorMessage = "Zip code is required";
            }
            else if (this.country.toLowerCase() == "usa") {
                if (!/^\d{5}(?:[-\s]\d{4})?$/.test(this.Zipcode)) {
                    this.zipcodeError = true;
                    this.zipcodeErrorMessage = "Zip code is not valid";
                }
            }
            else if (this.country.toLowerCase() == "canada") {
                if (!/^[a-zA-Z]{1}[0-9]{1}[a-zA-Z]{1}[ ]{0,1}[0-9]{1}[a-zA-Z]{1}[0-9]{1}/.test(this.Zipcode)) {
                    this.zipcodeError = true;
                    this.zipcodeErrorMessage = "Zip code is not valid";
                }
                else{
                    this.zipcodeError = false;
                    this.Zipcode = this.Zipcode.replace(/\s/g, '');
                    this.Zipcode = this.Zipcode.replace(/.{3}/g, '$& ');
                    this.Zipcode = this.Zipcode.trim();   
                }
            }

            if (!this.publicationNameError &&
                !this.SubscriptionIdError &&
                !this.zipcodeError) {
                await this.createPrintSubscription()
            }
            this.isLoading = false;
        },
        async getDataFromLocalStorage() {
            var data = localStorage.getItem("userDetails");
            if (data != "" && data != null) {
                data = JSON.parse(data);
                if (data.Zip != "" && data.Country != "null") {
                    this.Zipcode = data.Zip;
                    this.country = this.ChangeContryName(data.Country);
                    if (this.country.toLowerCase() == "usa") {
                        this.zipLable = "Zip Code";
                    } else if (this.country.toLowerCase() == "canada") {
                        this.zipLable = "Zip / Postal Code";
                    } else {
                        this.zipLable = "Zip / Postal Code";
                    }
                }
            }
        },
        saveDataTolocalStorage(data) {
            localStorage.setItem("PrintRegistrationDetails", JSON.stringify(data));
        },
        async createPrintSubscription() {
            this.registrationError = false;
            var data = localStorage.getItem("userDetails");
            if (data != "" && data != null) {
                data = JSON.parse(data);
                if (data.MPhone != null && data.MPhone != "") {
                    this.phonenumber = data.MPhone;
                    this.phonetype = "Mobile Phone";
                    this.MPhone = data.MPhone;
                }
                else {
                    this.phonenumber = data.DPhone;
                    this.phonetype = "Land Line";
                    this.DPhone = data.DPhone;
                }
                var printRegistrationCommand = {
                    firstName: data.FirstName,
                    lastName: data.LastName,
                    email: data.Email,
                    password: data.Password,
                    phoneType: this.phonetype,
                    dPhone: data.DPhone,
                    mphone: data.MPhone,
                    country: this.country,
                    zipcode: this.Zipcode,
                    address_1: data.Address_1,
                    address_2: data.Address_2,
                    city: data.City,
                    state: data.State,
                    publicationName: this.publicationName,
                    subscriptionId: this.SubscriptionId,
                    mobileAlert: data.MobileAlert,
                    emailAlert: data.EmailAlert,
                    omahaCustID: data.OmahaCustID,
                    userID: data.UserID
                }
                this.saveDataTolocalStorage(printRegistrationCommand);
                try {
                    return postData(
                            process.env.VUE_APP_BackEndAPI + process.env.VUE_APP_CreatePrintSubscription,
                            printRegistrationCommand
                        )
                        .then((response) => {
                            if (response) {
                                if (response.status == 200) {
                                    if (response.data) {
                                        var result = response.data;
                                        if (result) {
                                            if (result.ErrorMessage.toString() == "Successfull") {
                                                this.$router.push({
                                                    name: "print-registration-confirmation",
                                                    params: { currentproduct: this.currentProduct, },
                                                }).catch(err => {
                                                    if (
                                                        err.name !== 'NavigationDuplicated' &&
                                                        !err.message.includes('Avoided redundant navigation to current location')
                                                    ) {
                                                        console.log(err);
                                                    }
                                                });
                                            }
                                            else {
                                                if (result) {
                                                    if (result.ErrorMessage.toString() == "Subscription id and zipcode mapping is not correct") {
                                                        this.registrationError = true;
                                                        this.registrationErrorMessage = "The Subscription ID or Zip Code do not match our records. Has the correct publication name been selected? Please verify the information and try again.";
                                                    }
                                                    else {
                                                        this.registrationError = true;
                                                        this.registrationErrorMessage = "We were unable to process your transaction. Please try again.";
                                                    }
                                                }
                                                else {
                                                    this.registrationError = true;
                                                    this.registrationErrorMessage = "We were unable to process your transaction. Please try again.";
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        })
                        .catch(() => {
                            this.registrationError = true;
                        });
                } catch (ex) {
                    this.registrationError = true;
                    console.log(ex.message);
                }
            }
            else {
                this.registrationError = true;
            }
        },
        onChangeCountry(country) {
            this.zipcodeError = false;
            if (country.toLowerCase() == "usa") {
                this.zipLable = "Zip Code";
            } else if (country.toLowerCase() == "canada") {
                this.zipLable = "Zip / Postal Code";
            } else {
                this.zipLable = "Zip / Postal Code";
            }
        },
    },
    async mounted() {
        this.LoggedIn = this.$auth0.isAuthenticated && this.$session.exists();
        this.$store.state.user.LoggedIn = this.LoggedIn;
        window.scrollTo(0, 0);
        this.$store.state.layout.isHeaderRequired = false;
        this.$store.state.layout.isFooterRequired = false;
        if (this.$store.state.user.LoggedIn) {
            (this.productDataRoles = this.$auth0.user["https://morningstar.com/uim_roles"]);
            if(this.productDataRoles != undefined && this.productDataRoles !=null) {
            this.MEIAccess = this.productDataRoles.includes("EI_MEMBER");
            this.MDIAccess = this.productDataRoles.includes("DI_MEMBER");
            this.MSIAccess = this.productDataRoles.includes("SI_MEMBER");
            this.MFIAccess = this.productDataRoles.includes("FI_MEMBER");
            }
            this.userEmail = this.$auth0.user["email"];
            this.email = this.userEmail;
            this.userID = this.$auth0.user["https://morningstar.com/mstar_id"];
            this.existingUser = true;
            this.disabled = true;
        }
        await this.getDataFromLocalStorage();
        this.getPublicationName(this.currentProduct)
        this.isLoading = false;
    },
    watch: {
        publicationName(newVal, oldVal) {
            if (newVal != oldVal) {
                if (newVal == "" || newVal == null) {
                    this.publicationNameError = true;
                }
                else {
                    this.publicationNameError = false;
                }
            }

        },
        SubscriptionId(newVal, oldVal) {
            if (newVal != oldVal) {
                if (newVal == "" || newVal == null) {
                    this.SubscriptionIdError = true;
                    this.SubscriptionIdErrorMessage = "Subscription ID required";
                }
                else if (
                    !/^\+?(\d[\d-. ]+)?(\([\d-. ]+\))?[\d-. ]+\d$/.test(newVal)
                ) {
                    this.SubscriptionIdError = true;
                    this.SubscriptionIdErrorMessage = "Subscription ID should be a numerical field";
                }
                else {
                    this.SubscriptionIdError = false;
                }
            }
        },
        Zipcode(newVal, oldVal) {
            if (newVal != oldVal) {
                if (newVal == null || newVal == "") {
                    this.zipcodeError = true;
                    this.zipcodeErrorMessage = "Zip code is required";
                } else if (this.country.toLowerCase() == "usa") {
                    if (!/^\d{5}(?:[-\s]\d{4})?$/.test(newVal)) {
                        this.zipcodeError = true;
                        this.zipcodeErrorMessage = "Zip code is not valid";
                    }
                    else {
                        this.zipcodeError = false;
                    }
                }
                else if (this.country.toLowerCase() == "canada") {
                    if (!/^[a-zA-Z]{1}[0-9]{1}[a-zA-Z]{1}[ ]{0,1}[0-9]{1}[a-zA-Z]{1}[0-9]{1}/.test(newVal)) {
                        this.zipcodeError = true;
                        this.zipcodeErrorMessage = "Zip code is not valid";
                    }
                    else {
                        this.zipcodeError = false;
                    }
                }
                else {
                    this.zipcodeError = false;
                }
            }
        },
    }
}
</script>

<style scoped>
.print-subscription-loader {
    position: absolute;
    width: "276px";
    height: "69px";
    left: 38.92%;
    right: 37.92%;
    top: 50%;
}

.print-registrationError {
    margin-top: 5% !important;
    width: 100%;
}

.FormsError {
    font-size: 16px;
    font-weight: 300;
    line-height: 21px;
    color: #ca0000;
    display: block;
    margin-top: 2%;
}

.subscribe-page-bottom {
    opacity: 1;
    z-index: 2;
    transform: rotate(180deg);
    height: 50px;
}

.print-subscription-cancel-button {
    margin-left: auto;
}

.print-subscription-continue-button {
    margin-left: auto;
    order: 2;
}

.print-subscription-button-container {
    margin-top: 8%;
    width: 100%;
}

.print-subscription-button-container-productExists {
    margin-top: 20%;
    width: 100%;
}

.horizonatal-subcription-fieldset {
    display: flex;
}

.subscription-fieldset-first {
    width: 36.5%;
    flex-basis: 48%;
}

.subscription-fieldset-second {
    width: 37%;
    margin-left: auto;
    flex-basis: 48%;
}

.print-subscrription-form-fields {
    width: 100%;
    margin-top: 15% !important;
    font-size: 16px;
    line-height: 21px;
    color: #1e1e1e;
}

.print-subscription-form-container {
    width: 380px;
    margin: 0 auto;
}

.print-subscription-form {
    max-width: 480px;
    margin: 0 auto;
    background: #fff;
    height: 100%;
    min-height: 100vh;
}

.print-subscription-main {
    height: 100%;
    min-height: 100vh;
    background: #f2f2f2;
}

.print-subscription-main-container {
    max-width: 1280px;
    padding: auto;
    margin: auto;
}

.print-subscription-title {
    margin-top: 0px;
    margin-bottom: 0px;
    height: 44px;
    padding-top: 57px;
    font-weight: 250;
    font-size: 28px;
    line-height: 34px;
}

.print-sub-img {
    margin-top: 37px;
}

.print-sub-lastline {
    color: #000;
    font-size: 14px;
    line-height: 18px;
    margin-top: 15px;
    width: 100%;
}

.print-sub-exists {
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: 21px;
}

@media only screen and (max-width: 480px) {
    .print-subscription-form-container {
        width: 90%;
        margin: 0 auto;
    }

    .print-sub-img {
        width: 100%;
    }

    .print-sub-img img {
        width: 100%;
    }
    .print-subscription-title {
        height: auto;
        padding-top: 28px;
    }
    .print-subscrription-form-fields {
        margin-top: 10% !important;
    }
}
</style>
<style>
.print-subscrription-form-fields .mds-label.mds-label--large .mds-label__text {
    font-size: 16px !important;
    font-weight: 300 !important;
    line-height: 21px !important;
    font-weight: 700 !important;
    margin-bottom: 2% !important
}
</style>