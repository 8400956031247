var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:{
		[_vm.$style['mdc-carousel']]: true,
		[_vm.$style['mdc-carousel--native-scroll']]: _vm.isNativeScroll
	}},[_c('div',{ref:"scroll",class:_vm.$style['mdc-carousel__scroll']},[_c('ul',{ref:"items",class:{
				[_vm.$style['mdc-carousel__items']]: true,
				[_vm.$style[`mdc-carousel__items--${_vm.gutter}-gutter`]]: _vm.gutter
			}},[_vm._t("default")],2)]),_c('div',{class:{
			[_vm.$style['mdc-carousel__buttons']]: true,
			[_vm.$style['mdc-carousel__buttons--inset']]: _vm.insetControls
		}},[_c('div',{class:{
				[_vm.$style['mdc-carousel__button-container--left']]: _vm.insetControls,
				[_vm.$style['mdc-carousel__button-container--on-gray']]: _vm.insetControls && _vm.onGray,
				[_vm.$style['mdc-carousel__button-container--on-dark']]: _vm.insetControls && _vm.onDark
			}},[_vm._t("left-button",function(){return [(_vm.btnPrevcheck)?_c('div',{on:{"click":_vm.previousPage}},[_c('mds-icon',{staticClass:"home-commentary-angle-left",attrs:{"name":"angle-left","size":"medium"}})],1):_vm._e()]},{"previousPage":_vm.previousPage})],2),_c('div',{class:{
				[_vm.$style['mdc-carousel__button-container--right']]: _vm.insetControls,
				[_vm.$style['mdc-carousel__button-container--on-gray']]: _vm.insetControls && _vm.onGray,
				[_vm.$style['mdc-carousel__button-container--on-dark']]: _vm.insetControls && _vm.onDark
			}},[_vm._t("right-button",function(){return [(_vm.btnNextcheck)?_c('div',{staticClass:"angle-right-main-div",on:{"click":_vm.nextPage}},[_c('mds-icon',{staticClass:"home-commentary-angle-right",attrs:{"name":"angle-right","size":"medium"}})],1):_vm._e()]},{"nextPage":_vm.nextPage})],2)])])
}
var staticRenderFns = []

export { render, staticRenderFns }