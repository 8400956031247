import { render, staticRenderFns } from "./MdcCarouselItems.vue?vue&type=template&id=6fbc1ec0"
import script from "./MdcCarouselItems.vue?vue&type=script&lang=js"
export * from "./MdcCarouselItems.vue?vue&type=script&lang=js"
import style0 from "./MdcCarouselItems.vue?vue&type=style&index=0&id=6fbc1ec0&prod&lang=scss&module=true"




function injectStyles (context) {
  
  this["$style"] = (style0.locals || style0)

}

/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  null,
  null
  
)

export default component.exports