<template>
  <div class="video-div">
    <div class="video-div-border"></div>


    <div style="margin-top: 12px" >
      <span class="video-span-text"> Featured Videos</span>

      <span
        class="video-more-videos"
      
      >
        <router-link
          :to="{ name: 'videos', params: { currentproduct: 'Common' } }"
          target="_blank"
          @click.native="checkSelectedProduct"
          class="router-link-more-videos"
        >
          More Videos
          <div class="video-angle-right-icon-div">
            <mds-icon name="angle-right" size="small"></mds-icon>
          </div>
        </router-link>
      </span>

    </div>



    <div class="video-loader">
      <mds-loader v-if="isLoadingVideo" aria-label="Medium Loader"></mds-loader>
    </div>
    <div v-if="isDisplay">
      <mds-layout-grid class="video-grid">
        <mds-row>
          <mds-col
            :cols="12"
            :cols-at-l="4"
            :cols-at-xl="4"
            :cols-at-m="4"
            :cols-at-s="12"
            v-for="video in videos"
            :key="video.ref"
          >
            <div class="video-size">
              <video
                crossorigin="anonymous"
                style="margin-bottom: 10px; margin-top: 10px;"
                :ref="video.ref"
                :src="video.VideoURL"
                type="video/mp4"
                preload="auto"
                height="208"
                data-setup="{}"
                v-bind:poster="video.ImageThumb"
                :id="video.VideoId"
              >
              <track kind="subtitles" label="English" :src="video.closedCaptions" srclang="en" default>
              </video>

              <br />
              <div style="position: relative">
                <div class="controls">
                  <mds-button
                    variation="primary"
                    @click="onClick(video)"
                    :id="video.buttonWatchId"
                  >
                    <span style="position: absolute; top: 18%">
                      <mds-icon
                        name="play-circle"
                        size="small"
                        class="play-circle"
                      ></mds-icon>
                    </span>
                    <span style="margin-left: 20px">Watch</span>
                  </mds-button>

                  <mds-button
                    style="display: none"
                    variation="primary"
                    @click="onClick(video)"
                    :id="video.buttonStopId"
                    ><i class="fa fa fa fa fa-stop"> Stop</i>
                  </mds-button>
                </div>
              </div>
              <span class="video-title">{{ video.Title }}</span>
              <br />
              <div>
                <p class="video-description">
                  {{ video.VideoDescription }}
                </p>
              </div>
              <span class="video-publish-date">{{
                video.VideoPublishDate
              }}</span>
            </div>
          </mds-col>
        </mds-row>
      </mds-layout-grid>
    </div>
    <div v-if="isVideoReadMoreToggle" class="more-videos-read-more">
      <span
        class="video-more-videos-responsive"
        style="position: relative; top: 5%; right: 0%"
      >
        <router-link
          :to="{ name: 'videos', params: { currentproduct: 'Common' } }"
          target="_blank"
          @click.native="checkSelectedProduct"
          class="router-link-more-videos-responsive"
        >
          More Videos
          <div style="position: absolute; top: 5%; right: 0%">
            <mds-icon name="angle-right" size="small"></mds-icon>
          </div>
        </router-link>
      </span>
    </div>
    <div v-else></div>

    <div class="video-footnote">
      <div v-if="CurrentProduct.toUpperCase() == 'MSI'">
        <i>Morningstar StockInvestor</i> is intended for self-directed investors
        who have not hired an investment adviser to provide advisory services
        and do not reflect the deduction of any advisory fees. Your subscription
        may only be used for your own personal, noncommercial use and not for
        any use on behalf of any third party. It may not be used to service, or
        otherwise perform work on behalf of, any brokerage, financial services
        or related person(s) or their clients.
      </div>
      <div v-if="CurrentProduct.toUpperCase() == 'MDI'">
        <i>Morningstar DividendInvestor</i> is intended for self-directed
        investors who have not hired an investment adviser to provide advisory
        services and do not reflect the deduction of any advisory fees. Your
        subscription may only be used for your own personal, noncommercial use
        and not for any use on behalf of any third party. It may not be used to
        service, or otherwise perform work on behalf of, any brokerage,
        financial services or related person(s) or their clients.
      </div>
      <div v-if="CurrentProduct.toUpperCase() == 'MFI'">
        <i>Morningstar FundInvestor</i> includes information, analysis, and
        opinions from Morningstar’s Manager Research Group, which consists of
        employees of various Morningstar, Inc. subsidiaries who prepare analysis
        on investment products. In the United States, that subsidiary is
        Morningstar Research Services LLC, which is registered with and governed
        by the U.S. Securities and Exchange Commission. Please note,
        “Morningstar” is used to refer to Morningstar, Inc. and/or its
        affiliates, as applicable.<br /><br />

        Your subscription may only be used for your own personal, noncommercial
        use and not for any use on behalf of any third party. It may not be used
        to service, or otherwise perform work on behalf of, any brokerage,
        financial services or related person(s) or their clients.
      </div>
      <div v-if="CurrentProduct.toUpperCase() == 'MEI'">
        <i>Morningstar ETFInvestor</i> includes information, analysis, and
        opinions from Morningstar’s Manager Research Group, which consists of
        employees of various Morningstar, Inc. subsidiaries who prepare analysis
        on investment products. In the United States, that subsidiary is
        Morningstar Research Services LLC, which is registered with and governed
        by the U.S. Securities and Exchange Commission. Please note,
        “Morningstar” is used to refer to Morningstar, Inc. and/or its
        affiliates, as applicable. <br /><br />Your subscription may only be
        used for your own personal, noncommercial use and not for any use on
        behalf of any third party. It may not be used to service, or otherwise
        perform work on behalf of, any brokerage, financial services or related
        person(s) or their clients.
      </div>
    </div>
  </div>
</template>

<script>
import { MdsButton } from "@mds/button";
import MdsIcon from "@mds/icon";
import MdsLoader from "@mds/loader";
import { mapState } from "vuex";
import { MdsLayoutGrid, MdsRow, MdsCol } from "@mds/layout-grid";
import { fetchData } from "@/utilities/API/apiUtility";
export default {
  name: "video-section",
  components: {
    MdsLayoutGrid,
    MdsRow,
    MdsCol,
    MdsButton,
    MdsIcon,
    MdsLoader,
  },
  data() {
    return {
      VideoUrl: process.env.VUE_APP_BackEndAPI + process.env.VUE_APP_Video,
      videos: [],
      VideoTempData: [],
      CurrentProduct: this.$session.get("current-product"),
      isLoadingVideo: true,
      isDisplay: false,
      isVideoReadMoreToggle: null,
      viewPortWidth: null,
      closedCaptions:null,
    };
  },
  mounted() {
    this.isVideoReadMoreToggle=this.$store.state.responsive.isVideoReadMore;
    this.getVideo();
  },
  methods: {

    onClick({ ref }) {
      this.videos.forEach((video) => {
        const $video = this.$refs[video.ref][0];
        var oVideoTag = document.getElementById("VideoId_In" + video.ref);
        if (video.ref === ref) {
          if ($video.paused) {
            $video.play();
            document.getElementById("WatchId_In" + video.ref).style.display =
              "none";
            setTimeout(function () {
              oVideoTag.controls = "controls";
            }, 100);
          } else {
            $video.pause();
            document.getElementById("WatchId_In" + video.ref).style.display =
              "block";
            setTimeout(function () {
              oVideoTag.controls = false;
            }, 100);
          }
        } else {
          $video.pause();
          document.getElementById("WatchId_In" + video.ref).style.display =
            "block";
          setTimeout(function () {
            oVideoTag.controls = false;
          }, 100);

        }
      });
    },

    getVideo() {
      try {
        var Url = this.VideoUrl;
        Url = Url.replace("{ProductCode}", this.CurrentProduct);
        Url = Url.replace("{Limit}", "3");
        fetchData(Url)
          .then((response) => {
            if (response) {
              if (response.status) {
                if (response.status == 200) {
                  if (response.data) {
                    var lstData = response.data;
                    for (var i = 0; i < lstData.length; i++) {
                      var obj = lstData[i];

                      var ref = "";
                      var VideoURL = "";
                      var VideoDescription = "";
                      var Title = "";
                      var ImageThumb = "";
                      var VideoPublishDate = "";
                      var subtitles = "";

                      if (obj.Ref_Id) {
                        ref = obj.Ref_Id;
                      }

                      if (obj.VideoURL) {
                        VideoURL = obj.VideoURL;
                      }

                      if (obj.VideoDescription) {
                        VideoDescription = obj.VideoDescription;
                      }
                      if (obj.Title) {
                        Title = obj.Title;
                      }

                      if (obj.ImageThumb) {
                        ImageThumb = obj.ImageThumb;
                      }

                      if (obj.PublishDate) {
                        VideoPublishDate = obj.PublishDate;
                      }

                      if(obj.ClosedCaptions)
                      {
                        subtitles = obj.ClosedCaptions;
                      }

                      this.videos.push({
                        ref: ref,
                        VideoURL: VideoURL,
                        ImageThumb: ImageThumb,
                        Title: Title,
                        VideoDescription: VideoDescription,
                        buttonWatchId: "WatchId_In" + ref,
                        buttonStopId: "StopId_In" + ref,
                        VideoId: "VideoId_In" + ref,
                        VideoPublishDate: VideoPublishDate,
                        closedCaptions : subtitles,
                      });
                    }
                  }
                }
              }
            }
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(
            () => ((this.isLoadingVideo = false), (this.isDisplay = true))
          );
      } catch (ex) {
        console.log(ex.message);
      }
    },
    checkSelectedProduct() {
      var product = this.$session.get("current-product");
      
      localStorage.setItem("MoreVideosSelectedProduct", product.toUpperCase());
      // this.$router.push({
      //     name: 'videos',
      //     params: {
      //         currentproduct: product
      //     }
      // });
    },
  },
  computed:mapState({
    isVideoReadMore: state => state.responsive.isVideoReadMore,
    isVideoReadMoreAlias:'isVideoReadMore',
    isVideoReadMoreState(state){
      return state.responsive.isVideoReadMore
    }

  }),

  watch:{
    isVideoReadMoreState(){
      this.isVideoReadMoreToggle = this.isVideoReadMoreState;
    }
  }
};
</script>

<style scoped>
body {
  font-family: "Univers", HelveticaNeue, "Helvetica Neue", Helvetica, Arial,
    sans-serif;
}

@media only screen and (min-width: 1280px) and (max-width: 12900px) {

.video-div {
  width: 1280px;
  margin: auto;
  padding: auto;
  margin-top: 80px;
}

}

.video-grid {
  margin-top: 27px;
}

.video-div-border {
  border-top: 1px solid #5e5e5e;
  transform: rotate(180deg);
}

.video-span-text {
  font-family: "Univers", HelveticaNeue, "Helvetica Neue", Helvetica, Arial,
    sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 23px;
  line-height: 27px;
  color: #1e1e1e;
}

.router-link-more-videos {
  padding-right: -100px;
}



.video-title {
  font-family: "Univers", HelveticaNeue, "Helvetica Neue", Helvetica, Arial,
    sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 23px;
  color: #1e1e1e;
  margin-top: 18.37px;
  width: 100px;
}
.video-publish-date {
  margin-top: 2%;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #5e5e5e;
}

.video-description {
  font-family: "Univers", HelveticaNeue, "Helvetica Neue", Helvetica, Arial,
    sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 21px;
  color: #1e1e1e;
  margin-top: 8.63px;
  text-align: left;
}

.video-footnote {
  font-family: "UniversNext for MORN";

  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #1e1e1e;
  margin-top: 65.17px;
  margin-bottom: 20px;
  text-align: justify;
  
}

@media only screen and (min-width: 1280px) and (max-width: 76800px) {

.video-angle-right-icon-div{
  position: absolute;
  top: 5%;
  right: -2%;
}

}

@media only screen and (min-width: 1000px) and (max-width: 1280px) {

.video-angle-right-icon-div{
  position: absolute;
  top: 5%;
  right: -3%;
}

}

@media only screen and (min-width: 850px) and (max-width: 1000px) {

.video-angle-right-icon-div{
  position: absolute;
  top: 5%;
  right: -4%;
}

}

@media only screen and (min-width: 768px) and (max-width: 850px) {

.video-angle-right-icon-div{
  position: absolute;
  top: 5%;
  right: -4.3%;
}



}

.controls {
  position: absolute;
  /* top: 1300px; */
  top: -56px;
  margin-left: 13px;
}

i:hover {
  cursor: pointer;
}

.video-size {
  width: 100%;
}

@media only screen and (min-width: 1000px) and (max-width: 76800px) {

.video-size video {
    object-fit: cover;
  width: 100%;
  height: 210px;
  max-height: 210px;
}

}

@media only screen and (min-width: 768px) and (max-width: 1000px) {
  .video-size video {
    object-fit: cover;
  width: 100%;
  height: 150px;
  
}

}


.video-loader {
  width: "276px";
  height: "69px";
}

@media only screen and (min-width: 0px) and (max-width: 768px) {
  .video-size  {
  width: 100%;
  padding-bottom: 20px;
}
.video-size video {
    object-fit: cover;
  width: 100%;
  height: 100%;
  
}
}

@media only screen and (min-width: 0px) and (max-width: 600px) {
  .video-div {
  width: 100%;
  margin-left: 0.5%;
  margin-right: 5%;
  margin-top: 80px;
}


}

@media only screen and (min-width: 700px) and (max-width: 1200px) {
  .video-div {
    width: 94%;
  margin-left: 3%;
  
  margin-top: 80px;
}
}

@media only screen and (min-width: 600px) and (max-width: 700px) {
  .video-div {
    width: 96%;
  margin-left: 2.5%;
  
  margin-top: 80px;
}
}

@media only screen and (min-width: 0px) and (max-width: 1200px) {


.video-footnote {
  font-family: "UniversNext for MORN";

  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #1e1e1e;
  margin-top: 65.17px;
  margin-bottom: 20px;
  padding-left: 16px;
  padding-right: 16px;
  text-align: justify;
}


.video-grid {
  margin-top: 27px;
  margin-right: 16px;
  margin-left: 16px;
}
.video-span-text {
  font-family: "Univers", HelveticaNeue, "Helvetica Neue", Helvetica, Arial,
    sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 23px;
  line-height: 27px;
  color: #1e1e1e;
  margin-right: 16px;
  margin-left: 16px;
}
.video-div-border {
  border-top: 1px solid #5e5e5e;
  transform: rotate(180deg);
  margin-right: 16px;
  margin-left: 16px;
}

.router-link-more-videos-responsive{
  padding-right: 20px;
  margin-left: 18px;
  text-decoration: none;
  font-family: "Univers", HelveticaNeue, "Helvetica Neue", Helvetica, Arial,
    sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  position: relative;

  color: #5e5e5e !important;
}

.video-more-videos-responsive {

  font-family: "Univers", HelveticaNeue, "Helvetica Neue", Helvetica, Arial,
    sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  position: relative;
  right: 6%;
  color: #5e5e5e !important;
  width: 72px;
  
}
.more-videos-read-more{
  padding-top: 20px;
}
}

 @media only screen and (min-width: 350px) and (max-width: 400px) {
      .controls {
        position: absolute;
        top: -50px;
        opacity: 1;
        left: 0px;
        margin-left: 0px;
      }

     
    }
    @media only screen and (min-width: 401px) and (max-width: 450px) {
      .controls {
        position: absolute;
        top: -50px;
        opacity: 1;
      }
    }
    @media only screen and (min-width: 501px) and (max-width: 600px) {
      .controls {
        position: absolute;
        top: -50px;
        margin-left: 10px;
      }
    } 

    @media only screen and (min-width: 600px) and (max-width: 1200px) {
      .controls {
        position: absolute;
        top: -50px;
        margin-left: 10px;
      }
    } 


/* @media only screen and (min-width: 350px) and (max-width: 400px) {
      .controls {
        position: absolute;
        top: 90px;
        opacity: 1;
      }
    }
    @media only screen and (min-width: 401px) and (max-width: 500px) {
      .controls {
        position: absolute;
        top: 90px;
        opacity: 1;
      }
    }
    @media only screen and (min-width: 501px) and (max-width: 600px) {
      .controls {
        position: absolute;
        top: 134%;
        opacity: 1;
        margin-left: 10px;
        background-color: aquamarine;
      }
    }
    @media only screen and (min-width: 601px) and (max-width: 700px) {
      .controls {
        position: absolute;
        top: 710px;
        opacity: 1;
        margin-left: 10px;
        background-color: red;
      }
    }
    @media only screen and (min-width: 701px) and (max-width: 800px) {
      .controls {
        position: absolute;
        top: 700px;
        opacity: 1;
        margin-left: 10px;
      }
    }
    @media only screen and (min-width: 801px) and (max-width: 900px) {
      .controls {
        position: absolute;
        top: 102%;
        opacity: 1;
        margin-left: 10px;
      }
    }
    @media only screen and (min-width: 901px) and (max-width: 1000px) {
      .controls {
        position: absolute;
        top: 103%;
        opacity: 1;
        margin-left: 10px;
      }
    }
    @media only screen and (min-width: 1001px) and (max-width: 1100px) {
      .controls {
        position: absolute;
        top: 105%;
        opacity: 1;
        margin-left: 10px;
      }
    }
    @media only screen and (min-width: 1101px) and (max-width: 1200px) {
      .controls {
        position: absolute;
        top: -66px;
        opacity: 1;
        margin-left: 10px;
      }
    }
    @media only screen and (min-width: 1201px) and (max-width: 1300px) {
      .controls {
        position: absolute;
        top: -66px;
        opacity: 1;
        margin-left: 10px;
      }
    }
    @media only screen and (min-width: 1301px) and (max-width: 1400px) {
      .controls {
        position: absolute;
        top: -66px;
        opacity: 1;
        margin-left: 10px;
      }
    }
    @media only screen and (min-width: 1401px) and (max-width: 1500px) {
      .controls {
        position: absolute;
        top: -66px;
        opacity: 1;
        margin-left: 10px;
      }
    }*/

    @media only screen and (min-width: 1200px) and (max-width: 1280px) {
       .video-div {
  width: 90%;
  margin-left: 5%;
  margin-right: 5%;
  margin-top: 80px;
}
    }


    @media only screen and (min-width: 1280px) and (max-width: 1290px) {
       .video-div {
  width: 99%;
  margin-left: 0.5%;
  margin-right: 0;
  margin-top: 80px;
}
    }

    @media only screen and (min-width: 0px) and (max-width: 768px) {
    .router-link-more-videos {
  display: none;
}
    }
    
    @media only screen and (min-width: 768px) and (max-width: 1280px) {


.router-link-more-videos{
  padding-left: 0px;
}



    }


    @media only screen and (min-width: 1200px) and (max-width: 1280px) {
      .video-more-videos {
  font-family: "Univers", HelveticaNeue, "Helvetica Neue", Helvetica, Arial,
    sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  position: relative;
  right: 16%;
  color: #5e5e5e !important;
  width: 72px;
  padding-left: 75%;
}

    }

    @media only screen and (min-width: 1280px) and (max-width: 1290px) {
      .video-more-videos {
  font-family: "Univers", HelveticaNeue, "Helvetica Neue", Helvetica, Arial,
    sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  position: relative;
  right: 16%;
  color: #5e5e5e !important;
  width: 72px;
  padding-left: 78%;
}

    }

    @media only screen and (min-width: 1100px) and (max-width: 1200px) {
      .video-more-videos {
  font-family: "Univers", HelveticaNeue, "Helvetica Neue", Helvetica, Arial,
    sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  position: relative;
  right: 16%;
  color: #5e5e5e !important;
  width: 72px;
  padding-left: 69%;
}

    }

    @media only screen and (min-width: 1000px) and (max-width: 1100px) {
      .video-more-videos {
  font-family: "Univers", HelveticaNeue, "Helvetica Neue", Helvetica, Arial,
    sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  position: relative;
  right: 16%;
  color: #5e5e5e !important;
  width: 72px;
  padding-left: 66.5%;
}

    }

    @media only screen and (min-width: 900px) and (max-width: 1000px) {
      .video-more-videos {
  font-family: "Univers", HelveticaNeue, "Helvetica Neue", Helvetica, Arial,
    sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  position: relative;
  right: 16%;
  color: #5e5e5e !important;
  width: 72px;
  padding-left: 63.5%;
}

    }

    @media only screen and (min-width: 800px) and (max-width: 900px) {
      .video-more-videos {
  font-family: "Univers", HelveticaNeue, "Helvetica Neue", Helvetica, Arial,
    sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  position: relative;
  right: 16%;
  color: #5e5e5e !important;
  width: 72px;
  padding-left: 0%;
}

    }

    @media only screen and (min-width: 1290px) and (max-width: 9000px) {
    .video-more-videos {
  font-family: "Univers", HelveticaNeue, "Helvetica Neue", Helvetica, Arial,
    sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  position: relative;
  right: 6%;
  color: #5e5e5e !important;
  width: 72px;
  padding-left: 1000px;
}}

    @media only screen and (min-width: 768px) and (max-width: 900px) {
      .video-more-videos {
  font-family: "Univers", HelveticaNeue, "Helvetica Neue", Helvetica, Arial,
    sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  position: relative;
  right: 16%;
  color: #5e5e5e !important;
  width: 72px;
  padding-left: 58.5%;
}

    }

    @media only screen and (min-width: 350px) and (max-width: 400px) {
      .controls {
        position: absolute;
        top: -50px;
        margin-left: 15px;
      }
    } 

.video-more-videos a {
  text-decoration: none;
  color: #5e5e5e !important;
}

@media only screen and (min-width: 0px) and (max-width: 600px) {
    

.video-more-videos{
  padding-left: 0px;
}

}


.video-more-videos{
  position: relative;
   top: 13.5%; 
   right: -4.8%
}


</style><style lang="scss">
@import "@mds/constants";
@import "@mds/icon/src/icon_mixins";

.play-circle {
  @include mds-icon-color($mds-color-white);
}
</style>