<template>
    <li
        :class="$style['mdc-carousel-item-article']"
        :style="{
            width: 100
        }"
    >
        <slot />
    </li>
</template>

<script>
export default {
    props: {
        width: {
            type: String,
            description: 'Width of carousel item',
            default: null
        }
    }
};
</script>

<style lang="scss" module>

@media only screen and (min-width: 0px) and (max-width: 480px) {
.mdc-carousel-item {
    list-style: none;
    position: relative;
    min-width: 0;
}
}

@media only screen and (min-width: 480px) and (max-width: 600px) {
.mdc-carousel-item-article:nth-child(1) {
    padding-left: 0px;
    padding-right: 0px;
    width: 97%;
    list-style: none;
    position: relative;
    min-width: 0;
}

.mdc-carousel-item-article:nth-child(4) {
    padding-left: 100px;
    padding-right: 20px;
    width: 78%;
    list-style: none;
    position: relative;
    min-width: 0;
}


.mdc-carousel-item-article {
    padding-left: 100px;
    padding-right: 20px;
    width: 80%;
    list-style: none;
    position: relative;
    min-width: 0;
}

}

</style>