<template>
    <div class="combined-newsletter-type">
        <mds-layout-grid>
            <mds-row>
                <mds-col :cols="12" :cols-at-s="12" :cols-at-m="6" :cols-at-l="8" :cols-at-xl="8">
                    <div>
                        <p class="newsletter-product-name">{{ productName }}</p>
                        <span class="newsletter-productprice"
                            v-if="(productName == 'Morningstar FundInvestor' && !this.MFIAccess) || (productName == 'Morningstar StockInvestor' && !this.MSIAccess)">
                            $170 Annual Digital <span class="pipe-symbol">|</span> $190 Annual Print + Digital
                        </span>

                        <span class="newsletter-productprice"
                            v-if="(productName == 'Morningstar DividendInvestor' && !this.MDIAccess) || (productName == 'Morningstar ETFInvestor' && !this.MEIAccess)">
                            $239 Annual Digital <span class="pipe-symbol">|</span> $259 Annual Print + Digital
                        </span>
                    </div>
                </mds-col>
                <mds-col :cols="12" :cols-at-s="12" :cols-at-m="6" :cols-at-l="4" :cols-at-xl="4"
                    class="newsletter-printsubscription-main">
                    <div class="newsletter-printsubscription-container">
                        <span class="newsletter-printsubscription"
                            v-if="productName == 'Morningstar FundInvestor' && !this.MFIAccess">
                            For print subscriptions, please call 1-866-608-9570
                            <br />Monday-Friday 8AM–5PM CST.
                        </span>

                        <span class="newsletter-printsubscription"
                            v-if="productName == 'Morningstar StockInvestor' && !this.MSIAccess">
                            For print subscriptions, please call 1-866-608-9570
                            <br />Monday-Friday 8AM–5PM CST.
                        </span>

                        <span class="newsletter-printsubscription"
                            v-if="productName == 'Morningstar DividendInvestor' && !this.MDIAccess">
                            For print subscriptions, please call 1-866-608-9570
                            <br />Monday-Friday 8AM–5PM CST.
                        </span>

                        <span class="newsletter-printsubscription"
                            v-if="productName == 'Morningstar ETFInvestor' && !this.MEIAccess">
                            For print subscriptions, please call 1-866-608-9570
                            <br />Monday-Friday 8AM–5PM CST.
                        </span>
                    </div>
                </mds-col>
            </mds-row>
        </mds-layout-grid>
        <div
            v-if="productName != null && productName != undefined && productName != '' && productName.toUpperCase() == 'MORNINGSTAR FUNDINVESTOR'">
            <mds-layout-grid class="combine-newslettertype-grid">
                <mds-row class="combine-newslettertype-grid-row">
                    <mds-col :cols="12" :cols-at-s="12" :cols-at-m="12" :cols-at-l="4" :cols-at-xl="4"
                        class="combine-newslettertype-grid-col">
                        <div>
                            <mds-button-container class="combine-product-issue-button">
                                <mds-button v-if="!this.MFIAccess" class="newsletter-buttonsubscribe"
                                    variation="secondary" size="large" id="buttonSubscribeMFI"
                                    @click="redirectToRegistration('MORNINGSTAR FUNDINVESTOR')">Subscribe</mds-button>
                                <mds-button v-if="this.MFIAccess" class="newsletter-button-product"
                                    :size="productbuttonSize" variation="secondary" id="btnGoToFundInvestorMFI"
                                    @click="$router.push({ name: 'home', params: { currentproduct: 'mfi' } })">Go to
                                    FundInvestor</mds-button>
                                <mds-button v-if="!this.MFIAccess" class="newsletter-buttonreadcurrentissue"
                                    size="large" variation="secondary" id="btnCurrentIssueNAMFI" type="button"
                                    @click="getCurrentProduct('mfi')">
                                    <mds-icon name="lock-close" class="current-issue-lock-icon" size="small"></mds-icon>
                                    Current Issue
                                </mds-button>
                                <mds-button v-if="this.MFIAccess"
                                    class="newsletter-buttonreadcurrentissue current-issue" :size="productbuttonSize"
                                    variation="secondary" id="btnCurrentIssueAMFI" type="button"
                                    @click="DownloadReport('mfi')">
                                    Current Issue
                                </mds-button>
                            </mds-button-container>

                        </div>
                        <div>
                            <span class="newsletter-productdescription">
                                <p>
                                    <i>Morningstar FundInvestor</i> is dedicated to helping
                                    investors pick mutual funds and build portfolios that meet
                                    their financial goals. We look for funds with sustainable
                                    competitive advantages by analyzing key fundamental criteria,
                                    including management, strategy, expenses, trading costs, and
                                    long-term performance.
                                    <span v-if="!this.MFIAccess">
                                        <router-link :to="{
                                            name: 'marketing-landing',
                                            params: { currentproduct: 'mfi' }
                                        }" target="_blank" class="learn-more-link">Learn
                                            More</router-link>.
                                    </span>
                                </p>
                            </span>
                        </div>
                    </mds-col>
                    <mds-col :cols="12" :cols-at-s="12" :cols-at-m="6" :cols-at-l="4" :cols-at-xl="4"
                        class="combine-newslettertype-grid-col">
                        <div class="newsletters-issuedescription-container">
                            <span class="newsletters-issuedescription">
                                <div class="resources-link-every-issue">
                                    <img class="newsletter-lock-icon"
                                        v-bind:class="[this.MFIAccess ? 'hide-lock-btn' : '', '']"
                                        src="../../../assets/icons/lock-red.svg" />
                                    <router-link id="lock" target="in_ever_issue" :to="$route.path"
                                        @click.native="getCurrentProduct('mfi')"
                                        v-bind:class="[this.MFIAccess ? 'resources-link-common' : 'resources-link']">In
                                        Every Issue</router-link>
                                </div>
                            </span>
                        </div>
                        <div style="margin-top: 15px">
                            <span class="newsletter-everyissue">Commentary on current events relevant to the fund
                                industry</span>
                            <div class="height-inner-text"></div>
                            <div class="newsletter-solid-gray"></div>

                            <span class="newsletter-everyissue">Timely, in-depth articles of interest to
                                investors</span>

                            <div class="height-inner-text"></div>
                            <div class="newsletter-solid-gray"></div>

                            <span class="newsletter-everyissue">The FundInvestor 500 watchlist</span>

                            <div class="height-inner-text"></div>
                            <div class="newsletter-solid-gray"></div>

                            <span class="newsletter-everyissue">The Contrarian: A monthly feature that helps uncover
                                hidden
                                gems</span>

                            <div class="height-inner-text"></div>
                            <div class="newsletter-solid-gray no-newsletter-solid-gray"></div>
                        </div>
                        <div class="newsletters-samplepdf-download" v-if="!this.MFIAccess">
                            <mds-icon name="document" size="small" class="newsletters-doc"> </mds-icon>
                            <a href="https://www.morningstar.com/lp/mfi-sample-issue?utm_source=newsletters&utm_medium=combo"
                                target="_blank" class="newsletter-downloadpdf-btn">Download Free Sample Issue</a>
                        </div>
                    </mds-col>
                    <mds-col :cols="12" :cols-at-s="12" :cols-at-m="6" :cols-at-l="4" :cols-at-xl="4"
                        class="combine-newslettertype-grid-col combine-newslettertype-grid-col-right">
                        <div class="newsletters-issuedescription-container">
                            <span class="newsletters-issuedescription">
                                <div>
                                    <img class="newsletter-lock-icon"
                                        v-bind:class="[this.MFIAccess ? 'hide-lock-btn' : '', '']"
                                        src="../../../assets/icons/lock-red.svg" />
                                    <router-link id="lock" target="resources" :to="$route.path"
                                        @click.native="getCurrentProduct('mfi')"
                                        v-bind:class="[this.MFIAccess ? 'resources-link-common' : 'resources-link']">Resources
                                        and Bonus Reports</router-link>
                                </div>
                            </span>
                        </div>
                        <div style="margin-top: 15px">
                            <div>
                                <span class="newsletter-everyissue">Website with access to the FundInvestor 500's
                                    one-page fund
                                    reports</span>
                                <div class="height-inner-text"></div>
                                <div class="newsletter-solid-gray"></div>

                                <span class="newsletter-everyissue">Email alerts about significant events in the fund
                                    industry</span>
                                <div class="height-inner-text"></div>
                                <div class="newsletter-solid-gray"></div>

                                <span class="newsletter-everyissue"><i>Morningstar FundInvestor Subscriber's
                                        Handbook</i></span>
                                <div class="height-inner-text"></div>
                                <div class="newsletter-solid-gray"></div>

                                <span class="newsletter-everyissue">Morningstar North America Equity Market
                                    Outlook</span>
                                <div class="height-inner-text"></div>
                                <div class="newsletter-solid-gray"></div>

                                <span class="newsletter-everyissue">Issue archive</span>
                                <div class="height-inner-text"></div>
                                <div class="newsletter-solid-gray"></div>

                                <span class="newsletter-everyissue">Ask the Editor</span>
                                <div class="height-inner-text"></div>
                                <div class="newsletter-solid-gray no-newsletter-solid-gray"></div>
                            </div>
                        </div>
                    </mds-col>
                </mds-row>
            </mds-layout-grid>
        </div>
        <div
            v-if="productName != null && productName != undefined && productName != '' && productName.toUpperCase() == 'MORNINGSTAR STOCKINVESTOR'">
            <mds-layout-grid class="combine-newslettertype-grid">
                <mds-row class="combine-newslettertype-grid-row">
                    <mds-col :cols="12" :cols-at-s="12" :cols-at-m="12" :cols-at-l="4" :cols-at-xl="4"
                        class="combine-newslettertype-grid-col">
                        <div>
                            <mds-button-container class="combine-product-issue-button">
                                <mds-button v-if="!this.MSIAccess" class="newsletter-buttonsubscribe" size="large"
                                    variation="secondary" id="buttonSubscribeMSI"
                                    @click="redirectToRegistration('MORNINGSTAR STOCKINVESTOR')">Subscribe</mds-button>
                                <mds-button v-if="this.MSIAccess" class="newsletter-button-product"
                                    :size="productbuttonSize" variation="secondary" id="btnGoToFundInvestorMSI"
                                    @click="$router.push({ name: 'home', params: { currentproduct: 'msi' } })">Go to
                                    StockInvestor</mds-button>
                                <mds-button v-if="!this.MSIAccess" class="newsletter-buttonreadcurrentissue"
                                    size="large" variation="secondary" id="btnCurrentIssueNAMSI" type="button"
                                    @click="getCurrentProduct('msi')">
                                    <mds-icon name="lock-close" class="current-issue-lock-icon" size="small"></mds-icon>
                                    Current Issue
                                </mds-button>
                                <mds-button v-if="this.MSIAccess"
                                    class="newsletter-buttonreadcurrentissue current-issue" :size="productbuttonSize"
                                    variation="secondary" id="btnCurrentIssueAMSI" type="button"
                                    @click="DownloadReport('msi')">
                                    Current Issue
                                </mds-button>
                            </mds-button-container>
                        </div>
                        <div>
                            <span class="newsletter-productdescription">
                                <p>
                                    <i>Morningstar StockInvestor</i> focuses on companies that we
                                    believe offer competitive advantages and are trading at
                                    discounts to their intrinsic values. This investment
                                    philosophy is demonstrated through two portfolios owned by
                                    Morningstar, Inc.: the Tortoise, which focuses on “high
                                    quality” businesses, defined as having both durable
                                    competitive advantages and strong balance sheets, and the
                                    Hare, which aims to hold fast-growing companies with strong
                                    and improving competitive advantages.
                                    <span v-if="!this.MSIAccess">
                                        <router-link :to="{
                                            name: 'marketing-landing',
                                            params: { currentproduct: 'msi' }
                                        }" target="_blank" class="learn-more-link">Learn
                                            More</router-link>.
                                    </span>
                                </p>
                            </span>
                        </div>
                    </mds-col>
                    <mds-col :cols="12" :cols-at-s="12" :cols-at-m="6" :cols-at-l="4" :cols-at-xl="4"
                        class="combine-newslettertype-grid-col">
                        <div class="newsletters-issuedescription-container">
                            <span class="newsletters-issuedescription">
                                <div class="resources-link-every-issue" id="lock" target="in_ever_issue">
                                    <img class="newsletter-lock-icon"
                                        v-bind:class="[this.MSIAccess ? 'hide-lock-btn' : '', '']"
                                        src="../../../assets/icons/lock-red.svg" />
                                    <router-link :to="$route.path" @click.native="getCurrentProduct('msi')"
                                        v-bind:class="[this.MSIAccess ? 'resources-link-common' : 'resources-link']">In
                                        Every Issue</router-link>
                                </div>
                            </span>
                        </div>
                        <div style="margin-top: 15px">
                            <span class="newsletter-everyissue">Commentary on current events relevant to our
                                strategies</span>
                            <div class="height-inner-text"></div>
                            <div class="newsletter-solid-gray"></div>

                            <span class="newsletter-everyissue">
                                Timely, in-depth insights on current and prospective holdings in
                                our Tortoise and Hare Portfolios</span>

                            <div class="height-inner-text"></div>
                            <div class="newsletter-solid-gray"></div>

                            <span class="newsletter-everyissue">Tortoise Portfolio</span>

                            <div class="height-inner-text"></div>
                            <div class="newsletter-solid-gray"></div>

                            <span class="newsletter-everyissue">Hare Portfolio</span>

                            <div class="height-inner-text"></div>
                            <div class="newsletter-solid-gray"></div>

                            <span class="newsletter-everyissue">A watchlist of stocks that may fit the investment
                                mandates of
                                our portfolios</span>

                            <div class="height-inner-text"></div>
                            <div class="newsletter-solid-gray no-newsletter-solid-gray"></div>
                        </div>
                        <div class="newsletters-samplepdf-download" v-if="!this.MSIAccess">
                            <mds-icon name="document" size="small" class="newsletters-doc"> </mds-icon>
                            <a href="https://www.morningstar.com/lp/msi-sample-issue?utm_source=newsletters&utm_medium=combo"
                                target="_blank" class="newsletter-downloadpdf-btn">Download Free Sample Issue</a>
                        </div>
                    </mds-col>
                    <mds-col :cols="12" :cols-at-s="12" :cols-at-m="6" :cols-at-l="4" :cols-at-xl="4"
                        class="combine-newslettertype-grid-col combine-newslettertype-grid-col-right">
                        <div class="newsletters-issuedescription-container">
                            <span class="newsletters-issuedescription">
                                <div id="lock" target="resources">
                                    <img class="newsletter-lock-icon"
                                        v-bind:class="[this.MSIAccess ? 'hide-lock-btn' : '', '']"
                                        src="../../../assets/icons/lock-red.svg" />
                                    <router-link :to="$route.path" @click.native="getCurrentProduct('msi')"
                                        v-bind:class="[this.MSIAccess ? 'resources-link-common' : 'resources-link']">Resources
                                        and Bonus Reports</router-link>
                                </div>
                            </span>
                        </div>
                        <div style="margin-top: 15px">
                            <div>
                                <span class="newsletter-everyissue">Email alerts when trades occur in our Tortoise and
                                    Hare
                                    Portfolios</span>
                                <div class="height-inner-text"></div>
                                <div class="newsletter-solid-gray"></div>

                                <span class="newsletter-everyissue">Website with access to analyst reports related to
                                    holdings in
                                    our Tortoise and Hare Portfolios</span>
                                <div class="height-inner-text"></div>
                                <div class="newsletter-solid-gray"></div>

                                <span class="newsletter-everyissue">Weekly email that covers the latest research
                                    relevant to each
                                    portfolio's holdings</span>
                                <div class="height-inner-text"></div>
                                <div class="newsletter-solid-gray"></div>

                                <span class="newsletter-everyissue"><i>Morningstar StockInvestor Subscriber’s
                                        Handbook</i></span>
                                <div class="height-inner-text"></div>
                                <div class="newsletter-solid-gray"></div>
                                <span class="newsletter-everyissue">Morningstar North America Equity Market
                                    Outlook</span>
                                <div class="height-inner-text"></div>
                                <div class="newsletter-solid-gray"></div>

                                <span class="newsletter-everyissue">Issue archive</span>
                                <div class="height-inner-text"></div>
                                <div class="newsletter-solid-gray"></div>

                                <span class="newsletter-everyissue">Ask the Editor</span>
                                <div class="height-inner-text"></div>
                                <div class="newsletter-solid-gray no-newsletter-solid-gray"></div>
                            </div>
                        </div>
                    </mds-col>
                </mds-row>
            </mds-layout-grid>
        </div>
        <div
            v-if="productName != null && productName != undefined && productName != '' && productName.toUpperCase() == 'MORNINGSTAR DIVIDENDINVESTOR'">
            <mds-layout-grid class="combine-newslettertype-grid">
                <mds-row class="combine-newslettertype-grid-row">
                    <mds-col :cols="12" :cols-at-s="12" :cols-at-m="12" :cols-at-l="4" :cols-at-xl="4"
                        class="combine-newslettertype-grid-col">
                        <div>
                            <mds-button-container class="combine-product-issue-button">
                                <mds-button v-if="!this.MDIAccess" class="newsletter-buttonsubscribe"
                                    id="buttonSubscribeMDI" size="large" variation="secondary"
                                    @click="redirectToRegistration('MORNINGSTAR DIVIDENDINVESTOR')">Subscribe</mds-button>
                                <mds-button v-if="this.MDIAccess"
                                    class="newsletter-button-product newsletter-button-product-mdi"
                                    :size="productbuttonSize" variation="secondary" id="btnGoToFundInvestorMDI"
                                    @click="$router.push({ name: 'home', params: { currentproduct: 'mdi' } })">Go to
                                    DividendInvestor</mds-button>
                                <mds-button v-if="!this.MDIAccess" class="newsletter-buttonreadcurrentissue"
                                    size="large" variation="secondary" id="btnCurrentIssueNAMDI" type="button"
                                    @click="getCurrentProduct('mdi')">
                                    <mds-icon name="lock-close" class="current-issue-lock-icon" size="small"></mds-icon>
                                    Current Issue
                                </mds-button>
                                <mds-button v-if="this.MDIAccess"
                                    class="newsletter-buttonreadcurrentissue current-issue" :size="productbuttonSize"
                                    variation="secondary" id="btnCurrentIssueAMDI" type="button"
                                    @click="DownloadReport('mdi')">
                                    Current Issue
                                </mds-button>
                            </mds-button-container>
                        </div>
                        <div>
                            <span class="newsletter-productdescription">
                                <p>
                                    <i>Morningstar DividendInvestor</i> takes a concentrated, best-ideas
                                    approach when investing in common stocks of dividend-paying
                                    companies and other securities. We emphasize firms with wide
                                    or narrow moats that we believe are in a stronger competitive
                                    position than their peers and that are trading at a reasonable
                                    price.
                                    <span v-if="!this.MDIAccess">
                                        <router-link :to="{
                                            name: 'marketing-landing',
                                            params: { currentproduct: 'mdi' }
                                        }" target="_blank" class="learn-more-link">Learn
                                            More</router-link>.
                                    </span>
                                </p>
                            </span>
                        </div>
                    </mds-col>
                    <mds-col :cols="12" :cols-at-s="12" :cols-at-m="6" :cols-at-l="4" :cols-at-xl="4"
                        class="combine-newslettertype-grid-col">
                        <div class="newsletters-issuedescription-container">
                            <span class="newsletters-issuedescription">
                                <div class="resources-link-every-issue" id="lock" target="in_ever_issue">
                                    <img class="newsletter-lock-icon"
                                        v-bind:class="[this.MDIAccess ? 'hide-lock-btn' : '', '']"
                                        src="../../../assets/icons/lock-red.svg" />
                                    <router-link :to="$route.path" @click.native="getCurrentProduct('mdi')"
                                        v-bind:class="[this.MDIAccess ? 'resources-link-common' : 'resources-link']">In
                                        Every Issue</router-link>
                                </div>
                            </span>
                        </div>
                        <div style="margin-top: 15px">
                            <span class="newsletter-everyissue">Commentary on current events relevant to dividend
                                investing
                                strategies</span>
                            <div class="height-inner-text"></div>
                            <div class="newsletter-solid-gray"></div>

                            <span class="newsletter-everyissue">Timely, in-depth editorial on income-generating
                                industries</span>

                            <div class="height-inner-text"></div>
                            <div class="newsletter-solid-gray"></div>

                            <span class="newsletter-everyissue">Dividend Select Portfolio</span>

                            <div class="height-inner-text"></div>
                            <div class="newsletter-solid-gray"></div>

                            <span class="newsletter-everyissue">Dividend Select Deferred Portfolio</span>

                            <div class="height-inner-text"></div>
                            <div class="newsletter-solid-gray"></div>

                            <span class="newsletter-everyissue">A watchlist of dividend stocks that may fit the
                                investment
                                mandates of our portfolios</span>

                            <div class="height-inner-text"></div>
                            <div class="newsletter-solid-gray no-newsletter-solid-gray"></div>
                        </div>
                        <div class="newsletters-samplepdf-download" v-if="!this.MDIAccess">
                            <mds-icon name="document" size="small" class="newsletters-doc"> </mds-icon>
                            <a href="https://www.morningstar.com/lp/mdi-sample-issue?utm_source=newsletters&utm_medium=combo"
                                target="_blank" class="newsletter-downloadpdf-btn">Download Free Sample Issue</a>
                        </div>
                    </mds-col>
                    <mds-col :cols="12" :cols-at-s="12" :cols-at-m="6" :cols-at-l="4" :cols-at-xl="4"
                        class="combine-newslettertype-grid-col combine-newslettertype-grid-col-right">
                        <div class="newsletters-issuedescription-container">
                            <span class="newsletters-issuedescription">
                                <div id="lock" target="resources">
                                    <img class="newsletter-lock-icon"
                                        v-bind:class="[this.MDIAccess ? 'hide-lock-btn' : '', '']"
                                        src="../../../assets/icons/lock-red.svg" />
                                    <router-link :to="$route.path" @click.native="getCurrentProduct('mdi')"
                                        v-bind:class="[this.MDIAccess ? 'resources-link-common' : 'resources-link']">Resources
                                        and Bonus Reports</router-link>
                                </div>
                            </span>
                        </div>
                        <div style="margin-top: 15px">
                            <div>
                                <span class="newsletter-everyissue">Email alerts when trades occur in our Dividend
                                    Select and
                                    Dividend Select Deferred Portfolios</span>
                                <div class="height-inner-text"></div>
                                <div class="newsletter-solid-gray"></div>

                                <span class="newsletter-everyissue">Website with access to analyst reports related to
                                    holdings in
                                    our Dividend Select and Dividend Select Deferred
                                    Portfolios</span>
                                <div class="height-inner-text"></div>
                                <div class="newsletter-solid-gray"></div>

                                <span class="newsletter-everyissue">Weekly email that covers the latest research
                                    relevant to each
                                    portfolio's holdings</span>
                                <div class="height-inner-text"></div>
                                <div class="newsletter-solid-gray"></div>

                                <span class="newsletter-everyissue"><i>Morningstar DividendInvestor Subscriber's
                                        Manual</i></span>
                                <div class="height-inner-text"></div>
                                <div class="newsletter-solid-gray"></div>

                                <span class="newsletter-everyissue"><i>The Morningstar Guide to Dividend
                                        Investing</i></span>
                                <div class="height-inner-text"></div>
                                <div class="newsletter-solid-gray"></div>
                                <span class="newsletter-everyissue">Morningstar North America Equity Market
                                    Outlook</span>
                                <div class="height-inner-text"></div>
                                <div class="newsletter-solid-gray"></div>

                                <span class="newsletter-everyissue">Issue archive</span>
                                <div class="height-inner-text"></div>
                                <div class="newsletter-solid-gray"></div>

                                <span class="newsletter-everyissue">Ask the Editor</span>
                                <div class="height-inner-text"></div>
                                <div class="newsletter-solid-gray no-newsletter-solid-gray"></div>
                            </div>
                        </div>
                    </mds-col>
                </mds-row>
            </mds-layout-grid>
        </div>
        <div
            v-if="productName != null && productName != undefined && productName != '' && productName.toUpperCase() == 'MORNINGSTAR ETFINVESTOR'">
            <mds-layout-grid class="combine-newslettertype-grid">
                <mds-row class="combine-newslettertype-grid-row">
                    <mds-col :cols="12" :cols-at-s="12" :cols-at-m="12" :cols-at-l="4" :cols-at-xl="4"
                        class="combine-newslettertype-grid-col">
                        <div>
                            <mds-button-container class="combine-product-issue-button">
                                <mds-button v-if="!this.MEIAccess" class="newsletter-buttonsubscribe"
                                    id="buttonSubscribeMEI" size="large" variation="secondary"
                                    @click="redirectToRegistration('MORNINGSTAR ETFINVESTOR')">Subscribe</mds-button>
                                <mds-button v-if="this.MEIAccess" class="newsletter-button-product"
                                    :size="productbuttonSize" variation="secondary" id="btnGoToFundInvestorMEI"
                                    @click="$router.push({ name: 'home', params: { currentproduct: 'mei' } })">Go to
                                    ETFInvestor</mds-button>
                                <mds-button v-if="!this.MEIAccess" class="newsletter-buttonreadcurrentissue"
                                    size="large" variation="secondary" id="btnCurrentIssueNAMEI" type="button"
                                    @click="getCurrentProduct('mei')">
                                    <mds-icon name="lock-close" class="current-issue-lock-icon" size="small"></mds-icon>
                                    Current Issue
                                </mds-button>
                                <mds-button v-if="this.MEIAccess"
                                    class="newsletter-buttonreadcurrentissue current-issue" :size="productbuttonSize"
                                    variation="secondary" id="btnCurrentIssueAMEI" type="button"
                                    @click="DownloadReport('mei')">
                                    Current Issue
                                </mds-button>
                            </mds-button-container>
                        </div>
                        <div>
                            <span class="newsletter-productdescription">
                                <p>
                                    <i>Morningstar ETFInvestor</i> takes a long view, casting our
                                    analysis back many decades and over many different countries,
                                    and seeks to understand drivers of excess returns that have
                                    existed in those varied environments. One of the universal
                                    results is that high-yielding assets outperform over the long
                                    haul, but not at all times and in all environments.
                                    <span v-if="!this.MEIAccess">
                                        <router-link :to="{
                                            name: 'marketing-landing',
                                            params: { currentproduct: 'mei' }
                                        }" target="_blank" class="learn-more-link">Learn
                                            More</router-link>.
                                    </span>
                                </p>
                            </span>
                        </div>
                    </mds-col>
                    <mds-col :cols="12" :cols-at-s="12" :cols-at-m="6" :cols-at-l="4" :cols-at-xl="4"
                        class="combine-newslettertype-grid-col">
                        <div class="newsletters-issuedescription-container">
                            <span class="newsletters-issuedescription">
                                <div class="resources-link-every-issue" id="lock" target="in_ever_issue">
                                    <img class="newsletter-lock-icon"
                                        v-bind:class="[this.MEIAccess ? 'hide-lock-btn' : '', '']"
                                        src="../../../assets/icons/lock-red.svg" />
                                    <router-link :to="$route.path" @click.native="getCurrentProduct('mei')"
                                        v-bind:class="[this.MEIAccess ? 'resources-link-common' : 'resources-link']">In
                                        Every Issue</router-link>
                                </div>
                            </span>
                        </div>
                        <div style="margin-top: 15px">
                            <span class="newsletter-everyissue">Commentary and research on current investment
                                issues</span>
                            <div class="height-inner-text"></div>
                            <div class="newsletter-solid-gray"></div>

                            <span class="newsletter-everyissue">Timely, in-depth analysis of individual exchange-traded
                                funds
                                and market segments</span>

                            <div class="height-inner-text"></div>
                            <div class="newsletter-solid-gray"></div>

                            <span class="newsletter-everyissue">Access to five model portfolios, each with a different
                                focus:
                                Basic, Defensive, Factor, Income, and ESG
                            </span>

                            <div class="height-inner-text"></div>
                            <div class="newsletter-solid-gray"></div>

                            <span class="newsletter-everyissue">A watchlist of ETFs that may fit the investment mandates
                                of our
                                portfolios</span>

                            <div class="height-inner-text"></div>
                            <div class="newsletter-solid-gray"></div>

                            <span class="newsletter-everyissue">Monthly portfolio article highlighting adjustments
                                investors
                                could make to one of the models and how these adjustments might
                                affect performance</span>

                            <div class="height-inner-text"></div>
                            <div class="newsletter-solid-gray no-newsletter-solid-gray"></div>
                        </div>
                        <div class="newsletters-samplepdf-download" v-if="!this.MEIAccess">
                            <mds-icon name="document" size="small" class="newsletters-doc"> </mds-icon>
                            <a href="https://www.morningstar.com/lp/mei-sample-issue?utm_source=newsletters&utm_medium=combo"
                                target="_blank" class="newsletter-downloadpdf-btn">Download Free Sample Issue</a>
                        </div>
                    </mds-col>
                    <mds-col :cols="12" :cols-at-s="12" :cols-at-m="6" :cols-at-l="4" :cols-at-xl="4"
                        class="combine-newslettertype-grid-col combine-newslettertype-grid-col-right">
                        <div class="newsletters-issuedescription-container">
                            <span class="newsletters-issuedescription">
                                <div id="lock" target="resources">
                                    <img class="newsletter-lock-icon"
                                        v-bind:class="[this.MEIAccess ? 'hide-lock-btn' : '', '']"
                                        src="../../../assets/icons/lock-red.svg" />
                                    <router-link :to="$route.path" @click.native="getCurrentProduct('mei')"
                                        v-bind:class="[this.MEIAccess ? 'resources-link-common' : 'resources-link']">Resources
                                        and Bonus Reports</router-link>
                                </div>
                            </span>
                        </div>
                        <div style="margin-top: 15px">
                            <div>
                                <span class="newsletter-everyissue">Website with access to analyst reports related to
                                    holdings in
                                    our model portfolios</span>
                                <div class="height-inner-text"></div>
                                <div class="newsletter-solid-gray"></div>

                                <span class="newsletter-everyissue">Introduction to ETFInvestor Model Portfolios</span>
                                <div class="height-inner-text"></div>
                                <div class="newsletter-solid-gray"></div>

                                <span class="newsletter-everyissue"><i>Morningstar ETFInvestor Subscriber's
                                        Handbook</i></span>
                                <div class="height-inner-text"></div>
                                <div class="newsletter-solid-gray"></div>

                                <span class="newsletter-everyissue">Morningstar North America Equity Market
                                    Outlook</span>
                                <div class="height-inner-text"></div>
                                <div class="newsletter-solid-gray"></div>

                                <span class="newsletter-everyissue">Issue archive</span>
                                <div class="height-inner-text"></div>
                                <div class="newsletter-solid-gray"></div>

                                <span class="newsletter-everyissue">Ask the Editor</span>
                                <div class="height-inner-text"></div>
                                <div class="newsletter-solid-gray no-newsletter-solid-gray"></div>
                            </div>
                        </div>
                    </mds-col>
                </mds-row>
            </mds-layout-grid>
        </div>
        <div class="editor-top-border"></div>
        <common-overlay v-if="overlayStatus" :current-product="productSelected" :overlay-status="overlayStatus"
            @resetOverlayStatusValue="resetOverlayStatus"></common-overlay>
    </div>
</template>

<script>
import {
    MdsLayoutGrid,
    MdsRow,
    MdsCol
} from "@mds/layout-grid";
import {
    MdsButton,
    MdsButtonContainer
} from "@mds/button";
import MdsIcon from "@mds/icon";
import CommonOverlay from "@/components/Common/Overlay/CommonOverlay.vue";
import GA4TrackingMixin from "../../mixins/GA4TrackingMixin";
import { fetchData } from "@/utilities/API/apiUtility";
export default {
    name: "combine-newsletter-type",
    components: {
        MdsLayoutGrid,
        MdsRow,
        MdsCol,
        MdsButton,
        MdsButtonContainer,
        MdsIcon,
        CommonOverlay,
    },
    props: {
        productName: [],
    },
    mixins: [GA4TrackingMixin],
    data() {
        return {

            MEIAccess: false,
            MDIAccess: false,
            MSIAccess: false,
            MFIAccess: false,
            DownloadCurrentIssueUrl: process.env.VUE_APP_BackEndAPI + process.env.VUE_APP_DownloadPdfUrl,
            FileName: [],
            latestCoverImagesUrl: process.env.VUE_APP_BackEndAPI +
                process.env.VUE_APP_LatestCoverImagesUrl,
            currIssueMFI: false,
            everyIssueandReportMFI: false,
            productSelected: '',
            overlayStatus: false,
            viewPortWidth: null,
            productbuttonSize: "large",
            CurrentDate: new Date(),
        };
    },
    methods: {
        getReferID(ProductName) {
            if (ProductName.toUpperCase() == 'MORNINGSTAR ETFINVESTOR')
                return "ETFGEN1";
            else if (ProductName.toUpperCase() == 'MORNINGSTAR DIVIDENDINVESTOR')
                return "ADIGRDG7";
            else if (ProductName.toUpperCase() == 'MORNINGSTAR STOCKINVESTOR')
                return "AMSGRDG7";
            else if (ProductName.toUpperCase() == 'MORNINGSTAR FUNDINVESTOR')
                return "AFNGRDG7";
        },
        getProductCode(ProductName) {
            if (ProductName.toUpperCase() == 'MORNINGSTAR ETFINVESTOR')
                return "NET0000001";
            else if (ProductName.toUpperCase() == 'MORNINGSTAR DIVIDENDINVESTOR')
                return "NDI0000001";
            else if (ProductName.toUpperCase() == 'MORNINGSTAR STOCKINVESTOR')
                return "NM00000001";
            else if (ProductName.toUpperCase() == 'MORNINGSTAR FUNDINVESTOR')
                return "NF00000001";

        },
        getPdfFileNames() {
            try {
                var URL = this.latestCoverImagesUrl;
                fetchData(URL).then((response) => {
                    if (response) {
                        if (response.status == 200) {
                            if (response.data) {
                                var pfData = response.data;
                                if (pfData) {
                                    for (var i = 0; i < pfData.length; i++) {
                                        this.FileName[i] = pfData[i].FileName;
                                    }
                                }
                            }
                        }
                    }
                }).catch((error) => {
                    console.log(error);
                })
                    .finally(() => ((this.isLoadingImage = false), (this.isDisplayImage = true)));
            } catch (ex) {
                console.log(ex.message);
            }
        },
        DownloadReport(Product) {
            try {
                if (this.$store.state.user.LoggedIn) {
                    var bValid = false;

                    if (Product == 'mfi') {
                        this.$gtag.event("download_pdf", {
                            'product': "MFI",
                            'issue_date': this.CurrentDate,
                            'issue_title': this.FileName[0],
                            'type': "current",
                            'user_type': localStorage.getItem("userType_MFI")
                        });
                        if (this.MFIAccess) {
                            bValid = true;
                        }

                    } else if (Product == 'msi') {
                        this.$gtag.event("download_pdf", {
                            'product': "MSI",
                            'issue_date': this.CurrentDate,
                            'issue_title': this.FileName[1],
                            'type': "current",
                            'user_type': localStorage.getItem("userType_MSI")
                        });
                        if (this.MSIAccess) {
                            bValid = true;
                        }
                    } else if (Product == 'mdi') {
                        this.$gtag.event("download_pdf", {
                            'product': "MDI",
                            'issue_date': this.CurrentDate,
                            'issue_title': this.FileName[2],
                            'type': "current",
                            'user_type': localStorage.getItem("userType_MDI")
                        });
                        if (this.MDIAccess) {
                            bValid = true;
                        }
                    } else if (Product == 'mei') {
                        this.$gtag.event("download_pdf", {
                            'product': "MEI",
                            'issue_date': this.CurrentDate,
                            'issue_title': this.FileName[3],
                            'type': "current",
                            'user_type': localStorage.getItem("userType_ETF")
                        });
                        if (this.MEIAccess) {
                            bValid = true;
                        }
                    }

                    if (bValid) {

                        let Url = this.DownloadCurrentIssueUrl;
                        Url = Url.replace('{ProductCode}', Product);
                        if (Product == 'mfi')
                            Url = Url.replace('{FileName}', this.FileName[0]);
                        if (Product == 'msi')
                            Url = Url.replace('{FileName}', this.FileName[1]);
                        if (Product == 'mdi')
                            Url = Url.replace('{FileName}', this.FileName[2]);
                        if (Product == 'mei')
                            Url = Url.replace('{FileName}', this.FileName[3]);
                        Url = Url.replace('{Type}', 'ISSUES');

                        fetchData(Url).then((response) => {
                            if (response) {
                                if (response.status == 200) {
                                    if (response.data) {
                                        var results = response.data;
                                        if (results) {
                                            if (results.FilePath) {
                                                const link = document.createElement("a");
                                                link.setAttribute("href", results.FilePath);
                                                link.setAttribute("download", this.FileName);
                                                link.click();
                                            }
                                        }
                                    }
                                }
                            }
                        }).catch((error) => {
                            console.log(error);

                        })
                            .finally();
                    }
                }
            } catch (ex) {
                console.log(ex.message);
            }
        },
        redirectToRegistration(productType) {
            var productCode = this.getProductCode(productType);
            var productReferId = this.getReferID(productType);
            this.$router.push({
                name: 'purchase',
                params: {
                    referid: productReferId,
                    productcode: productCode
                },
            })
        },
        getCurrentProduct(productName) {
            if (this.$session.exists() && this.$store.state.user.LoggedIn) {
                const userProductAccess = this.$auth0.user["https://morningstar.com/uim_roles"];
                if (userProductAccess != undefined && userProductAccess != null) {
                    this.MEIAccess = userProductAccess.includes("EI_MEMBER");
                    this.MDIAccess = userProductAccess.includes("DI_MEMBER");
                    this.MSIAccess = userProductAccess.includes("SI_MEMBER");
                    this.MFIAccess = userProductAccess.includes("FI_MEMBER");
                }
                if (productName.toUpperCase() == 'MFI' && this.MFIAccess) {
                    this.redirectToIndividualHome(productName);
                }
                else if (productName.toUpperCase() == 'MSI' && this.MSIAccess) {
                    this.redirectToIndividualHome(productName);
                }
                else if (productName.toUpperCase() == 'MDI' && this.MDIAccess) {
                    this.redirectToIndividualHome(productName);
                }
                else if (productName.toUpperCase() == 'MEI' && this.MEIAccess) {
                    this.redirectToIndividualHome(productName);
                }
                else {
                    this.productSelected = productName;
                    this.overlayStatus = true;
                }
            }
            else {
                this.productSelected = productName;
                this.overlayStatus = true;
            }
        },
        resetOverlayStatus(toggleValue) {
            this.overlayStatus = toggleValue;
        },
        redirectToIndividualHome(productType) {
            this.$router.push({
                name: 'home',
                params: {
                    currentproduct: productType
                },
            })
        },
        checkViewPortSize() {
            this.viewPortWidth = window.innerWidth;
        }
    },
    mounted() {
        if (window.innerWidth <= 428) {
            this.productbuttonSize = 'medium';
        } else {
            this.productbuttonSize = 'large';
        }
        document.body.onresize = this.checkViewPortSize;
        this.getPdfFileNames();
        this.CurrentProductforusertype = this.$session.get("current-product");
        this.getUserDetailsMixin(this.CurrentProductforusertype);

    },
    created() {
        if (this.$store.state.user.LoggedIn) {
            this.productDataRoles = this.$auth0.user["https://morningstar.com/uim_roles"];
            if (this.productDataRoles != undefined && this.productDataRoles != null) {
                this.MEIAccess = this.productDataRoles.includes("EI_MEMBER");
                this.MDIAccess = this.productDataRoles.includes("DI_MEMBER");
                this.MSIAccess = this.productDataRoles.includes("SI_MEMBER");
                this.MFIAccess = this.productDataRoles.includes("FI_MEMBER");
            }
        }
    },
    watch: {
        viewPortWidth() {
            if (this.viewPortWidth <= 428) {
                this.productbuttonSize = 'medium';
            } else {
                this.productbuttonSize = 'large';
            }
        }
    }
};
</script>

<style lang="scss" scoped>
@import "@mds/constants";

.newsletters-samplepdf-download {
    color: #1E1E1E;
    /* Heading/Level 7: Bold */
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 21px;
    /* 131.25% */
    margin-top: 24px;
}

.newsletter-downloadpdf-btn {
    margin-left: 7px;
    color: #1e1e1e !important;
    text-decoration: none !important;
}

.newsletters-doc {
    margin-bottom: -1px;
}

.newsletter-product-name {
    font-weight: 700;
    font-size: 23px;
    line-height: 27px;
    color: #1e1e1e;
    padding-bottom: 20px;
}

.newsletter-productprice {
    font-weight: 300;
    font-size: 23px;
    line-height: 27px;
    color: #1e1e1e;
}

.newsletter-printsubscription-container {
    /*margin-left:7px;*/
}

.newsletter-printsubscription {
    /*margin-right: 6%
    float: right;*/
    font-weight: 300;
    font-size: 16px;
    line-height: 21px;
    color: #1e1e1e;
}

.newsletter-buttonsubscribe.mds-button.mds-button--secondary {
    background: #000000;
    color: #ffffff;
}

.newsletter-button-product.mds-button.mds-button--secondary {
    background: #000000;
    color: #ffffff;
}

.newsletter-productdescription {
    font-weight: 300;
    font-size: 20px;
    line-height: 26px;
    color: #000000;
}

.newsletter-everyissue {
    font-weight: 300;
    font-size: 16px;
    line-height: 21px;
    color: #1e1e1e;
    text-decoration: none;
}

.height-inner-text {
    margin-top: 3px;
}

.newsletter-solid-gray {
    border-top: 1px Solid #cccccc;
    margin-top: 7px;
    margin-bottom: 4px;

}

.newsletters-issuedescription {
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    color: #1e1e1e;
}

.editor-top-border {
    margin-top: 46px;
    width: 100%;
    height: 2px;
    background: #333333;
}

.learn-more-link {
    color: #1e1e1e !important;
    text-decoration: none !important;
    border-bottom: 1px solid #ababab !important;
}

.resources-link {
    color: #1e1e1e !important;
    margin-left: 5px !important;
    text-decoration: none !important;
}

.resources-link-common {
    color: #1e1e1e !important;
    text-decoration: none !important;
}

.combined-newsletter-type {
    margin-top: 13px !important;
}

.newsletter-lock-icon {
    width: 15px;
    height: 15px;
    margin-bottom: -2px;
    margin-left: -2px;
}

.hide-lock-btn {
    display: none;
}

.pipe-symbol {
    color: #ABABAB;
}

.combine-newslettertype-grid {
    margin-top: 30px;
}

.combine-newslettertype-grid .mds-layout-grid>div>div>div {
    padding-right: 24px;
    padding-left: 24px;
}

.combine-newsletter-modal-close-button {
    width: 30px;
    left: 94%;
}

.combine-product-issue-button {
    margin-bottom: 25px;
}

.editor-read-more-pop-up .mds-modal__wrapper {
    height: 95vh !important;
}

@media only screen and (min-width:360px) and (max-width:767px) {
    .newsletter-productprice {
        font-weight: 400;
        font-size: 18px;
        line-height: 22px;
        color: #1e1e1e;
    }
}

@media only screen and (min-width:320px) and (max-width:999px) {
    .combined-newsletter-type {
        width: 90%;
        margin-left: 5%;
    }

    .combine-newslettertype-grid {
        width: 100%;
        margin-top: 24px;
    }

    .combine-newslettertype-grid-row {
        width: 100%;
        margin: 0;
    }

    .combine-newslettertype-grid-col {
        padding: 0 !important;
    }

    .combine-newslettertype-grid-col-right {
        padding-left: 30px !important;
    }

    .editor-top-border {
        display: none;
    }
}

@media only screen and (max-width: 767px) {
    .newsletter-product-name {
        font-size: 20px;
        line-height: 23px;
    }

    .newsletter-productdescription {
        font-weight: 300;
        font-size: 16px;
        line-height: 21px;
        color: #000000;
    }

    .resources-link-every-issue {
        margin-top: 30px;
    }

    .combine-newslettertype-grid-col-right {
        padding-left: 0px !important;
        margin-top: 25px;
    }

    .newsletter-printsubscription-container {
        margin-left: 0 !important;
        margin-top: 13px;
    }

    .height-inner-text {
        margin-top: 6px;
    }

    .newsletter-solid-gray {
        border-top: 1px Solid #cccccc;
        margin-bottom: 4px;
    }

    .no-newsletter-solid-gray {
        display: none;
    }
}

@media only screen and (min-width:768px) and (max-width:1025px) {
    .combine-newslettertype-grid-col {
        margin-bottom: 25px;
    }
}

@media only screen and (max-width: 320px) {
    .newsletter-printsubscription {
        font-weight: 300;
        font-size: 15px;
        line-height: 21px;
        color: #1e1e1e;
    }

    .newsletter-productprice {
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        color: #1e1e1e;
    }
}

@media only screen and (min-width:901px) and (max-width:1290px) {
    .combined-newsletter-type {
        width: 90%;
        margin-left: 5%;
        margin-right: 5%;
        margin-top: 13px !important;
    }
}

@media only screen and (min-width:1001px) and (max-width:1290px) {
    .newsletters-issuedescription-container {
        margin-top: 65px;
    }

    .combine-product-issue-button {
        width: 134%;
    }
}

@media only screen and (min-width:320px) and (max-width:428px) {

    .newsletter-button-product.mds-button.mds-button--secondary,
    .newsletter-buttonreadcurrentissue.current-issue.mds-button.mds-button--secondary {
        font-size: 15px;
        padding: 8px 16px;
    }
}

@media only screen and (min-width:320px) and (max-width:900px) {
    .current-issue-lock-icon {
        display: inline-flex !important;
        height: 19px !important;
    }
}

.current-issue-lock-icon {
    display: none;
}
</style>
<style>
@media only screen and (max-width: 900px) {
    .newsletter-buttonreadcurrentissue.mds-button.mds-button--secondary>span>span>svg {
        margin-top: 3.5px;
    }
}
</style>