var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:{
		[_vm.$style['mdc-metadata']]: true,
		[_vm.$style['mdc-metadata--large']]: _vm.large
	}},[(_vm.thumbnail)?_c('mdc-image',{class:{
			[_vm.$style['mdc-metadata__thumbnail']]: true,
			[_vm.$style['mdc-metadata__thumbnail--large']]: _vm.large,
		},attrs:{"presentational":"","aspect-ratio":"1:1","size":_vm.large ? '32px': '18px',"src":_vm.thumbnail}}):_vm._e(),_c('div',{class:_vm.$style['mdc-metadata__list']},[_vm._t("default")],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }