var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mei-model-portfolio-details-container"},[_c('div',{staticClass:"loader"},[(_vm.isLoading)?_c('mds-loader',{attrs:{"aria-label":"Medium Loader"}}):_vm._e()],1),_vm._m(0),(_vm.isDisplay)?_c('div',[_c('div',[_c('div',{staticClass:"mei-portfolio-details-div-border"}),_vm._m(1),_c('div',{staticClass:"mei-portfolio-details-middle-text"},[_vm._v(" Harness the market's collective wisdom with ultra-low-cost funds. ")]),_c('PortfolioHoldings',{attrs:{"portfolios":_vm.basicPortfolios}})],1),_c('div',[_c('div',{staticClass:"mei-portfolio-details-div-border"}),_vm._m(2),_c('div',{staticClass:"mei-portfolio-details-middle-text"},[_vm._v(" Seeks lower volatility, better downside protection, and better risk-adjusted performance than the basic portfolio over the long term. ")]),_c('PortfolioHoldings',{attrs:{"portfolios":_vm.defensivePortfolios}})],1),_c('div',[_c('div',{staticClass:"mei-portfolio-details-div-border"}),_vm._m(3),_c('div',{staticClass:"mei-portfolio-details-middle-text"},[_vm._v(" Seeks to earn higher returns than the basic portfolio over the long term. ")]),_c('PortfolioHoldings',{attrs:{"portfolios":_vm.factorPortfolios}})],1),_c('div',[_c('div',{staticClass:"mei-portfolio-details-div-border"}),_vm._m(4),_c('div',{staticClass:"mei-portfolio-details-middle-text"},[_vm._v(" Seeks to earn a higher distribution yield than the basic portfolio without taking much more risk. ")]),_c('PortfolioHoldings',{attrs:{"portfolios":_vm.incomePortfolios}})],1),_c('div',[_c('div',{staticClass:"mei-portfolio-details-div-border"}),_vm._m(5),_c('div',{staticClass:"mei-portfolio-details-middle-text"},[_vm._v(" Target firms with strong corporate governance and sustainable environmental and social practices, while seeking competitive returns and similar risk to the basic portfolio. ")]),_c('PortfolioHoldings',{attrs:{"portfolios":_vm.ESGPortfolios}})],1)]):_vm._e()])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"mei-portfolio-details-title"},[_vm._v("Portfolio Details")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mei-portfolio-details-basic-portfolio"},[_c('img',{staticClass:"mei-port-img",attrs:{"src":require("../../assets/Images/mei/basic.svg")}}),_vm._v(" Basic Portfolio ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mei-portfolio-details"},[_c('img',{staticClass:"mei-port-img",attrs:{"src":require("../../assets/Images/mei/defensive.svg")}}),_vm._v(" Defensive Portfolio ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mei-portfolio-details",staticStyle:{"margin-top":"8px"}},[_c('img',{staticClass:"mei-port-img",attrs:{"src":require("../../assets/Images/mei/factor.svg")}}),_vm._v(" Factor Portfolio ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mei-portfolio-details"},[_c('img',{staticClass:"mei-port-img",attrs:{"src":require("../../assets/Images/mei/income.svg")}}),_vm._v(" Income Portfolio ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mei-portfolio-details"},[_c('img',{staticClass:"mei-port-img",attrs:{"src":require("../../assets/Images/mei/esg.svg")}}),_vm._v(" ESG Portfolio ")])
}]

export { render, staticRenderFns }