<template>
	<li
		:class="{
			[$style['mdc-list-group-item']]: true,
			[$style['mds-list-group-item']]: true,
			[$style['mds-list-group-item--disabled']]: disabled,
			[$style['mds-list-group-item--active']]: isActive,
			[$style['mdc-list-group-item--editable']]: editable
		}"
		role="none"
	>
		<component
			:is="isLink ? 'mdc-link' : 'div'"
			v-bind="linkProps"
			:class="{
				[$style['mdc-list-group-item__link']]: true,
				[$style['mds-list-group__link']]: true,
				[$style['mds-list-group__link--with-image']]: image
			}"
			:tabindex="isLink || !!$listeners ? 0 : undefined"
			:aria-disabled="disabled"
			role="option"
			:aria-selected="isActive"
			:aria-current="isActive"
			v-on="$listeners"
		>
			<div :class="$style['mds-list-group-item__link-inner']">
				<div :class="[$style['mds-list-group-item__before'], $style['mds-list-group-item__before--indent']]">
					<div v-if="icon" :class="$style['mds-list-group-item__icon-container']">
						<mdc-icon :class="$style['mds-list-group__icon']" :name="icon" :small="!isLarge" />
					</div>

					<mdc-image
						v-if="image"
						:class="[$style['mds-list-group-item__image']]"
						:src="image"
						:size="imageWidth"
						presentational
						aspect-ratio="1:1"
					/>
				</div>

				<div :class="$style['mds-list-group-item__main']">
					<div :class="$style['mds-list-group-item__upper']">
						<div
							:class="{
								[$style['mdc-list-group-item__text']]: true,
								[$style['mds-list-group__item-text']]: true,
								'mdc-list-group-item__text--truncated': truncateText,
								'mdc-list-group-item__text--editable': editable
							}"
						>
							<slot />

							<div v-if="editable" :class="$style['mdc-list-group-item__edit-actions']">
								<mdc-button :class="$style['mdc-list-group-item__edit-button']" flat icon-only icon="pencil" aria-label="Edit" @click.prevent.stop="onEdit" />
								<mdc-button :class="$style['mdc-list-group-item__delete-button']" flat icon-only icon="trash" aria-label="Delete" @click.prevent.stop="onDelete" />
							</div>
						</div>
					</div>

					<div v-if="hasMetadata" :class="$style['mds-list-group-item__lower']">
						<div :class="$style['mds-list-group-item__metadata-below']">
							<span
								v-for="(metadataItem, idx) in metadataArray"
								:key="idx"
								:class="$style['mds-list-group-item__metadata-below-item']"
							>
								{{ metadataItem }}
							</span>
						</div>
					</div>
				</div>
			</div>
		</component>
	</li>
</template>

<script>
import flatten from 'lodash/flatten';
import isArray from 'lodash/isArray';

import MdcButton from './MdcButton.vue';

import MdcIdMixin from "../mixins/MdcIdMixin"; 
import MdcLinkMixin from "../mixins/MdcLinkMixin";

export default {
	components: {
		MdcButton,
	},
	mixins: [
		MdcIdMixin,
		MdcLinkMixin
	],
	props: {
		disabled: {
			type: Boolean,
			description: 'Mark the item as disabled',
			default: false
		},
		icon: {
			type: String,
			description: 'Render left icon',
			default: undefined
		},
		editable: {
			type: Boolean,
			description: 'Render an edit icon over the entity to initiate user edits',
			default: false
		},
		image: {
			type: String,
			description: 'Image url',
			default: undefined
		},
		active: {
			type: Boolean,
			description: 'Mark the item as active',
			default: false
		},
		metadata: {
			type: [Array, String],
			description: 'provide additional information about the list item',
			default: undefined
		},
		truncateText: {
			type: Boolean,
			description: 'Truncate text within the list group item',
			default: false
		}
	},
	computed: {
		isActive() {
			return this.active || this.isActiveRoute;
		},
		hasMetadata() {
			return this.metadata && isArray(this.metadata);
		},
		isLarge() {
			if (this.$parent) return this.$parent.$props.large;
			return false;
		},
		imageWidth() {
			return '32px';
		},
		metadataArray() {
			return flatten([this.metadata]);
		}
	},
	methods: {
		onDelete() {
			this.$emit('delete');
		},
		onEdit() {
			this.$emit('edit');
		}
	}
};
</script>

<style lang="scss" module>
@import '@mds/list-group/src/list-group.scss';
@import "@mds/constants";
@import '@mds/typography';
.mdc-list-group-item {
	cursor: pointer;

	&__text {
		display: block;

		&--truncated {
			display: block;
			white-space: nowrap;
			text-overflow: ellipsis;
			overflow: hidden;
		}

		&--editable {
			margin-right: $mds-space-5-x;
		}

		&--left-icon {
			margin-left: $mds-space-3-x;
		}
	}

	&__edit-actions {
		display: flex;
		position: absolute;
		top: $mds-space-1-x;
		margin-right: $mds-space-1-x;
		right: 0;

		> * {
			margin-left: $mds-space-1-x;
		}
	}
}
</style>
