<template>
  <div>
    <div class="mei-bond-watchlist-container">
      <div class="mei-bond-watchlist-loader">
        <mds-loader v-if="isLoading" aria-label="Medium Loader"></mds-loader>
      </div>
      <div class="mei-bond-watchlist-header-text">
        <div class="mei-bond-watchlist-middle-content">
        <div class="mei-bond-watchlist-title">Bond Watchlist</div>
        <div class="mei-bond-watchlist-download-holding">
          <mds-button
            variation="flat"
            size="small"
            icon="document"
            @click="ExportExcel"
            style="font-size: 14px; margin-left: 5px; color: #5e5e5e"
          >
            Download data
          </mds-button>
        </div>
        </div>
        <div class="mei-bond-watchlist-analyst-text">
          Click on company name for latest Morningstar Analyst Report.
        </div>
        <div class="mei-bond-watchlist-analyst-text-sec">
          Click on ticker for additional ETF data from Morningstar.com.
        </div>
      </div>

      <div class="mei-bond-watchlist-table">
        <mds-table
          fixedFirstCol
          show-sortable
          class="mei-bond-watchlist-data-table"
        >
          <mds-thead :nested-headers="1">
            <template v-slot:mds-thead-group-1>
              <mds-th colspan="1" style="width: 65px"></mds-th>
              <mds-th colspan="1" style="width: 350px"></mds-th>
              <mds-th colspan="1" style="width: 200px"></mds-th>
              <mds-th colspan="1" style="width: 102px"></mds-th>
              <mds-th colspan="3" style="width: 300px"></mds-th>
              <mds-th colspan="2" style="width: 110px">
                <span style="" class="mei-bond-watchlist-nestedheader"
                  >Nav Return %</span
                >
                <span class="mei-bond-watchlist-borderbottom"></span>
              </mds-th>
              <mds-th colspan="3" style="width: 180px">
                <span
                  style=""
                  class="mei-bond-watchlist-nestedheader-annualized"
                >
                  Nav Return % Annualized</span
                >
                <span class="mei-bond-watchlist-borderbottom-annualized"></span>
              </mds-th>
              <mds-th colspan="1" style="width: 80px"></mds-th>
              <mds-th colspan="1" style="width: 110px"></mds-th>
              <mds-th colspan="1" style="width: 90px"></mds-th>
              <mds-th colspan="1" style="width: 80px"></mds-th>
              <mds-th colspan="1" style="width: 70px"></mds-th>
              <mds-th colspan="1" style="width: 130px"></mds-th>
              <mds-th colspan="1" style="width: 75px"></mds-th>
              <mds-th colspan="1" style="width: 120px"></mds-th>
              <mds-th colspan="5" style="width: 440px">
                <span style="" class="mei-bond-watchlist-nestedheader">
                  Sector Exposure (Long %)</span
                >
                <span class="mei-bond-watchlist-borderbottom"></span>
              </mds-th>
              <mds-th colspan="7" style="width: 440px">
                <span style="" class="mei-bond-watchlist-nestedheader">
                  Credit Quality</span
                >
                <span class="mei-bond-watchlist-borderbottom-credit"></span>
              </mds-th>
              <mds-th colspan="1" style="width: 75px"></mds-th>
              <mds-th colspan="1" style="width: 73px"></mds-th>
              <mds-th colspan="1" style="width: 114px"></mds-th>
              <mds-th colspan="1" style="width: 106px"></mds-th>
              <mds-th colspan="1" style="width: 110px"></mds-th>
            </template>
            <mds-th
              v-for="(header, index) in headers"
              :key="index"
              :sortable="header.sortable"
              :right-aligned="header.align === 'right'"
              :sorted="header.sorted"
              :style="header.style"
              @mds-th-sort-changed="
                handleSortEvent(index, header.fieldName, $event)
              "
            >
              <span
                v-html="header.text"
                class="mei-bond-watchlist-tblHeaderColor"
              ></span>
            </mds-th>
          </mds-thead>
          <mds-tbody v-if="isDisplay">
            <mds-tr v-for="(row, index) in rows" :key="index">
              <mds-td left-align style="padding-left: 5px">
                <div
                  v-if="
                    row.Ticker == '0' ||
                    row.Ticker == '' ||
                    row.Ticker == 'NaN' ||
                    row.Ticker == '&mdash;' ||
                    row.Ticker == null ||
                    row.Ticker == '&nbsp;'
                  "
                >
                  &mdash;
                </div>
                <div v-else @click="redirectToStockQuote(row.ExchangeId,row.Ticker)" class="mei-bond-watchlist-table-ticker">
              
              {{
                row.Ticker
              }}
              </div>
            </mds-td>
              <mds-td style="padding-left: 16px">
                <div
                  v-if="
                    row.Name == '0' ||
                    row.Name == '' ||
                    row.Name == 'NaN' ||
                    row.Name == '&mdash;' ||
                    row.Name == null ||
                    row.Name == '&nbsp;'
                  "
                >
                  &mdash;
                </div>
                <div v-else>
                <a
                  class="mei-bond-watchlist-tbl-first-row"
                  v-html="row.Name"
                  @mouseover="openMedalistReport(row.SecId,row.Ticker)"
                  @touchstart="openMedalistReport(row.SecId,row.Ticker)"
                  target="_blank"
                  :href="CurrentURL"
                ></a>
                </div>
              </mds-td>
              <mds-td left-aligned style="padding-left: 20px">  <div
                  v-if="
                    row.MorningstarCategory == '0' ||
                    row.MorningstarCategory == '' ||
                    row.MorningstarCategory == 'NaN' ||
                    row.MorningstarCategory == '&mdash;' ||
                    row.MorningstarCategory == null ||
                    row.MorningstarCategory == '&nbsp;'
                  "
                >
                  &mdash;
                </div>
                <div v-else>
                <span>{{ row.MorningstarCategory }}</span>
                </div>
              </mds-td>
              <mds-td style="padding-left: 2px;">
                <div 
                  v-if="
                    row.MorningstarMedalistRating == '0' ||
                    row.MorningstarMedalistRating == '' ||
                    row.MorningstarMedalistRating == 'NaN' ||
                    row.MorningstarMedalistRating == '&mdash;' ||
                    row.MorningstarMedalistRating == null ||
                    row.MorningstarMedalistRating == '&nbsp;'
                  "
                >
                &mdash;
                </div>
                <div style="padding-left: 2px; margin-top: 4px" v-else>
                  <mds-analyst-rating
                    :rating="
                      analystRatingClassMap[row.MorningstarMedalistRating]
                    "
                  >
                  </mds-analyst-rating>
                </div>
              </mds-td>
              <mds-td right-aligned style="padding-right: 9px">
                <div
                  v-if="
                    row.DataCoverage == '0' ||
                    row.DataCoverage == '' ||
                    row.DataCoverage == 'NaN' ||
                    row.DataCoverage == '&mdash;' ||
                    row.DataCoverage == null ||
                    row.DataCoverage == '&nbsp;'
                  "
                >
                 0
                </div>
              
                
                <div v-else>{{ row.DataCoverage | dataCoverageFilter }}</div>
              </mds-td>
              <mds-td right-aligned style="padding-right: 15px">
                
                <div
                  v-if="
                    row.AnalystDriven == '0' ||
                    row.AnalystDriven == '' ||
                    row.AnalystDriven == 'NaN' ||
                    row.AnalystDriven == '&mdash;' ||
                    row.AnalystDriven == null ||
                    row.AnalystDriven == '&nbsp;'
                  "
                >
                 0
                </div>
              
                <div v-else>{{ row.AnalystDriven }}</div>
                
              </mds-td>
              <mds-td style="padding-left: 5px">
               <div
                  v-if="
                    row.MorningstarRating == '0' ||
                    row.MorningstarRating == '' ||
                    row.MorningstarRating == 'NaN' ||
                    row.MorningstarRating == '&mdash;' ||
                    row.MorningstarRating == null ||
                    row.MorningstarRating == '&nbsp;'
                  "
                >
                  &mdash;
                </div>
                <div v-else>
                  <mds-star-rating
                    :rating="parseInt(row.MorningstarRating)"
                    :custom-size="14"
                  ></mds-star-rating>
                </div>
              </mds-td>
              <mds-td right-aligned style="padding-left: 16px">
                <div
                  v-if="
                    row.YTD == '0' ||
                    row.YTD == '' ||
                    row.YTD == 'NaN' ||
                    row.YTD == '&mdash;' ||
                    row.YTD == null ||
                    row.YTD == '&nbsp;'
                  "
                >
                  &mdash;
                </div>
                <div v-else>
                <span v-html="row.YTD"></span>
                </div>
              </mds-td>
              <mds-td right-aligned style="padding-left: 16px">
                <div
                  v-if="
                    row.Return1Yr == '0' ||
                    row.Return1Yr == '' ||
                    row.Return1Yr == 'NaN' ||
                    row.Return1Yr == '&mdash;' ||
                    row.Return1Yr == null ||
                    row.Return1Yr == '&nbsp;'
                  "
                >
                  &mdash;
                </div>
                <div v-else>
                <span v-html="row.Return1Yr"></span>
                </div>
              </mds-td>
              <mds-td right-aligned style="padding-left: 16px">
                <div
                  v-if="
                    row.Return3Yr == '0' ||
                    row.Return3Yr == '' ||
                    row.Return3Yr == 'NaN' ||
                    row.Return3Yr == '&mdash;' ||
                    row.Return3Yr == null ||
                    row.Return3Yr == '&nbsp;'
                  "
                >
                  &mdash;
                </div>
                <div v-else>
                <span v-html="row.Return3Yr"></span>
                </div>
              </mds-td>
              <mds-td right-aligned style="padding-left: 16px">
                <div
                  v-if="
                    row.Return5Yr == '0' ||
                    row.Return5Yr == '' ||
                    row.Return5Yr == 'NaN' ||
                    row.Return5Yr == '&mdash;' ||
                    row.Return5Yr == null ||
                    row.Return5Yr == '&nbsp;'
                  "
                >
                  &mdash;
                </div>
                <div v-else>
                <span v-html="row.Return5Yr"></span>
                </div>
              </mds-td>
              <mds-td right-aligned style="padding-left: 16px">
                <div
                  v-if="
                    row.Return10Yr == '0' ||
                    row.Return10Yr == '' ||
                    row.Return10Yr == 'NaN' ||
                    row.Return10Yr == '&mdash;' ||
                    row.Return10Yr == null ||
                    row.Return10Yr == '&nbsp;'
                  "
                >
                  &mdash;
                </div>
                <div v-else>
                <span v-html="row.Return10Yr"></span>
                </div>
              </mds-td>
              <mds-td right-aligned style="padding-left: 16px">
                <div
                  v-if="
                    row.SinceInception == '0' ||
                    row.SinceInception == '' ||
                    row.SinceInception == 'NaN' ||
                    row.SinceInception == '&mdash;' ||
                    row.SinceInception == null ||
                    row.SinceInception == '&nbsp;'
                  "
                >
                  &mdash;
                </div>
                <div v-else>
                <span v-html="row.SinceInception"></span>
                </div>
              </mds-td>
              <mds-td left-aligned style="padding-left: 20px">
                <div
                  v-if="
                    row.InceptionDate == '0' ||
                    row.InceptionDate == '' ||
                    row.InceptionDate == 'NaN' ||
                    row.InceptionDate == '&mdash;' ||
                    row.InceptionDate == null ||
                    row.InceptionDate == '&nbsp;'
                  "
                >
                  &mdash;
                </div>
                <div v-else>
                <span>{{ row.InceptionDate | formatDateValForDataTables }}</span>
                </div>
              </mds-td>
              <mds-td right-aligned style="padding-left: 16px">
                <div
                  v-if="
                    row.MarketPrice == '0' ||
                    row.MarketPrice == '' ||
                    row.MarketPrice == 'NaN' ||
                    row.MarketPrice == '&mdash;' ||
                    row.MarketPrice == null ||
                    row.MarketPrice == '&nbsp;'
                  "
                >
                  &mdash;
                </div>
                <div v-else>
                <span v-html="row.MarketPrice"></span>
                </div>
              </mds-td>
              <mds-td right-aligned style="padding-left: 16px">
                <div
                  v-if="
                    row.FundYield == '0' ||
                    row.FundYield == '' ||
                    row.FundYield == 'NaN' ||
                    row.FundYield == '&mdash;' ||
                    row.FundYield == null ||
                    row.FundYield == '&nbsp;'
                  "
                >
                  &mdash;
                </div>
                <div v-else>
                  <span v-html="row.FundYield"></span>
                </div>
              </mds-td>
              <mds-td right-aligned>
                <div
                  style="padding-left: 16px"
                  v-if="
                    row.Assets == '0' ||
                    row.Assets == '' ||
                    row.Assets == 'NaN' ||
                    row.Assets == '&mdash;' ||
                    row.Assets == null ||
                    row.Assets == '&nbsp;'
                  "
                >
                  &mdash;
                </div>
                <div style="padding-left: 16px" v-else>
                  <span> {{ row.Assets  }}</span>
                </div>
              </mds-td>
              <mds-td right-aligned style="padding-left: 16px">
                <div
                  v-if="
                    row.AvgShareVolume == '0' ||
                    row.AvgShareVolume == '' ||
                    row.AvgShareVolume == 'NaN' ||
                    row.AvgShareVolume == '&mdash;' ||
                    row.AvgShareVolume == null ||
                    row.AvgShareVolume == '&nbsp;'
                  "
                >
                  &mdash;
                </div>
                <div v-else>
                {{ row.AvgShareVolume | amountFilter }}
              </div>
              </mds-td>
              <mds-td right-aligned style="padding-left: 16px">
                <div
                  v-if="
                    row.ExpenseRatio == '0' ||
                    row.ExpenseRatio == '' ||
                    row.ExpenseRatio == 'NaN' ||
                    row.ExpenseRatio == '&mdash;' ||
                    row.ExpenseRatio == null ||
                    row.ExpenseRatio == '&nbsp;'
                  "
                >
                  &mdash;
                </div>
                <div v-else>
                <span v-html="row.ExpenseRatio"></span>
                </div>
              </mds-td>
              <mds-td right-aligned style="padding-left: 16px">
                <div
                  v-if="
                    row.TaxCostRatio == '0' ||
                    row.TaxCostRatio == '' ||
                    row.TaxCostRatio == 'NaN' ||
                    row.TaxCostRatio == '&mdash;' ||
                    row.TaxCostRatio == null ||
                    row.TaxCostRatio == '&nbsp;'
                  "
                >
                  &mdash;
                </div>
                <div v-else>
                <span v-html="row.TaxCostRatio"></span>
                </div>
              </mds-td>
              <mds-td right-aligned style="padding-left: 16px">
                <div
                  v-if="
                    row.SupSecGovt == '0' ||
                    row.SupSecGovt == '' ||
                    row.SupSecGovt == 'NaN' ||
                    row.SupSecGovt == '&mdash;' ||
                    row.SupSecGovt == null ||
                    row.SupSecGovt == '&nbsp;'
                  "
                >
                  &mdash;
                </div>
                <div v-else>
                <span v-html="row.SupSecGovt"></span>
                </div>
              </mds-td>
              <mds-td right-aligned style="padding-left: 16px">
                <div
                  v-if="
                    row.SupSecCorp == '0' ||
                    row.SupSecCorp == '' ||
                    row.SupSecCorp == 'NaN' ||
                    row.SupSecCorp == '&mdash;' ||
                    row.SupSecCorp == null ||
                    row.SupSecCorp == '&nbsp;'
                  "
                >
                  &mdash;
                </div>
                <div v-else>
                <span v-html="row.SupSecCorp"></span>
                </div>
              </mds-td>
              <mds-td right-aligned style="padding-left: 16px">
                <div
                  v-if="
                    row.SupSecStruc == '0' ||
                    row.SupSecStruc == '' ||
                    row.SupSecStruc == 'NaN' ||
                    row.SupSecStruc == '&mdash;' ||
                    row.SupSecStruc == null ||
                    row.SupSecStruc == '&nbsp;'
                  "
                >
                  &mdash;
                </div>
                <div v-else>
                <span v-html="row.SupSecStruc"></span>
                </div>
              </mds-td>
              <mds-td right-aligned style="padding-left: 16px">
                <div
                  v-if="
                    row.SupSecMuni == '0' ||
                    row.SupSecMuni == '' ||
                    row.SupSecMuni == 'NaN' ||
                    row.SupSecMuni == '&mdash;' ||
                    row.SupSecMuni == null ||
                    row.SupSecMuni == '&nbsp;'
                  "
                >
                  &mdash;
                </div>
                <div v-else>
                <span v-html="row.SupSecMuni"></span>
                </div>
              </mds-td>
              <mds-td right-aligned style="padding-left: 16px">
                <div
                  v-if="
                    row.SupSecCash == '0' ||
                    row.SupSecCash == '' ||
                    row.SupSecCash == 'NaN' ||
                    row.SupSecCash == '&mdash;' ||
                    row.SupSecCash == null ||
                    row.SupSecCash == '&nbsp;'
                  "
                >
                  &mdash;
                </div>
                <div v-else>
                <span v-html="row.SupSecCash"></span>
                </div>
              </mds-td>
              <mds-td right-aligned style="padding-left: 16px">
                <div
                  v-if="
                    row.CreditQualityAAA == '0' ||
                    row.CreditQualityAAA == '' ||
                    row.CreditQualityAAA == 'NaN' ||
                    row.CreditQualityAAA == '&mdash;' ||
                    row.CreditQualityAAA == null ||
                    row.CreditQualityAAA == '&nbsp;'
                  "
                >
                  &mdash;
                </div>
                <div v-else>
                <span v-html="row.CreditQualityAAA"></span>
                </div>
              </mds-td>
              <mds-td right-aligned style="padding-left: 16px">
                <div
                  v-if="
                    row.CreditQualityAA == '0' ||
                    row.CreditQualityAA == '' ||
                    row.CreditQualityAA == 'NaN' ||
                    row.CreditQualityAA == '&mdash;' ||
                    row.CreditQualityAA == null ||
                    row.CreditQualityAA == '&nbsp;'
                  "
                >
                  &mdash;
                </div>
                <div v-else>
                <span v-html="row.CreditQualityAA"></span>
                </div>
              </mds-td>
              <mds-td right-aligned style="padding-left: 16px">
                <div
                  v-if="
                    row.CreditQualityA == '0' ||
                    row.CreditQualityA == '' ||
                    row.CreditQualityA == 'NaN' ||
                    row.CreditQualityA == '&mdash;' ||
                    row.CreditQualityA == null ||
                    row.CreditQualityA == '&nbsp;'
                  "
                >
                  &mdash;
                </div>
                <div v-else>
                <span v-html="row.CreditQualityA"></span>
                </div>
              </mds-td>
              <mds-td right-aligned style="padding-left: 16px">
                <div
                  v-if="
                    row.CreditQualityBBB == '0' ||
                    row.CreditQualityBBB == '' ||
                    row.CreditQualityBBB == 'NaN' ||
                    row.CreditQualityBBB == '&mdash;' ||
                    row.CreditQualityBBB == null ||
                    row.CreditQualityBBB == '&nbsp;'
                  "
                >
                  &mdash;
                </div>
                <div v-else>
                <span v-html="row.CreditQualityBBB"></span>
                </div>
              </mds-td>
              <mds-td right-aligned style="padding-left: 16px">
                <div
                  v-if="
                    row.CreditQualityBB == '0' ||
                    row.CreditQualityBB == '' ||
                    row.CreditQualityBB == 'NaN' ||
                    row.CreditQualityBB == '&mdash;' ||
                    row.CreditQualityBB == null ||
                    row.CreditQualityBB == '&nbsp;'
                  "
                >
                  &mdash;
                </div>
                <div v-else>
                <span v-html="row.CreditQualityBB"></span>
                </div>
              </mds-td>
              <mds-td right-aligned style="padding-left: 16px">
                <div
                  v-if="
                    row.CreditQualityB == '0' ||
                    row.CreditQualityB == '' ||
                    row.CreditQualityB == 'NaN' ||
                    row.CreditQualityB == '&mdash;' ||
                    row.CreditQualityB == null ||
                    row.CreditQualityB == '&nbsp;'
                  "
                >
                  &mdash;
                </div>
                <div v-else>
                <span v-html="row.CreditQualityB"></span>
                </div>
              </mds-td>
              <mds-td right-aligned style="padding-left: 16px">
                <div
                  v-if="
                    row.CreditQualityBelowB == '0' ||
                    row.CreditQualityBelowB == '' ||
                    row.CreditQualityBelowB == 'NaN' ||
                    row.CreditQualityBelowB == '&mdash;' ||
                    row.CreditQualityBelowB == null ||
                    row.CreditQualityBelowB == '&nbsp;'
                  "
                >
                  &mdash;
                </div>
                <div v-else>
                <span v-html="row.CreditQualityBelowB"></span>
                </div>
              </mds-td>
              <mds-td right-aligned style="padding-left: 16px">
                <div
                  v-if="
                    row.AverageDuration == '0' ||
                    row.AverageDuration == '' ||
                    row.AverageDuration == 'NaN' ||
                    row.AverageDuration == '&mdash;' ||
                    row.AverageDuration == null ||
                    row.AverageDuration == '&nbsp;'
                  "
                >
                  &mdash;
                </div>
                <div v-else>
                <span v-html="row.AverageDuration"></span>
                </div>
              </mds-td>
              <mds-td right-aligned style="padding-left: 16px">
                <div
                  v-if="
                    row.Worst3MonthReturn == '0' ||
                    row.Worst3MonthReturn == '' ||
                    row.Worst3MonthReturn == 'NaN' ||
                    row.Worst3MonthReturn == '&mdash;' ||
                    row.Worst3MonthReturn == null ||
                    row.Worst3MonthReturn == '&nbsp;'
                  "
                >
                  &mdash;
                </div>
                <div v-else>
                <span v-html="row.Worst3MonthReturn"></span>
                </div>
              </mds-td>
              <mds-td right-aligned style="padding-left: 16px">
                <div
                  v-if="
                    row.DownsideMarketCapture == '0' ||
                    row.DownsideMarketCapture == '' ||
                    row.DownsideMarketCapture == 'NaN' ||
                    row.DownsideMarketCapture == '&mdash;' ||
                    row.DownsideMarketCapture == null ||
                    row.DownsideMarketCapture == '&nbsp;'
                  "
                >
                  &mdash;
                </div>
                <div v-else>
                <span v-html="row.DownsideMarketCapture"></span>
                </div>
              </mds-td>
              <mds-td right-aligned style="padding-left: 16px"><div
                  v-if="
                    row.StdDev5Yr == '0' ||
                    row.StdDev5Yr == '' ||
                    row.StdDev5Yr == 'NaN' ||
                    row.StdDev5Yr == '&mdash;' ||
                    row.StdDev5Yr == null ||
                    row.StdDev5Yr == '&nbsp;'
                  "
                >
                  &mdash;
                </div>
                <div v-else>

                <span v-html="row.StdDev5Yr"></span>
                </div>
              </mds-td>
              <mds-td right-aligned style="padding-left: 16px">
                <div
                  v-if="
                    row.SharpeRatio5Yr == '0' ||
                    row.SharpeRatio5Yr == '' ||
                    row.SharpeRatio5Yr == 'NaN' ||
                    row.SharpeRatio5Yr == '&mdash;' ||
                    row.SharpeRatio5Yr == null ||
                    row.SharpeRatio5Yr == '&nbsp;'
                  "
                >
                  &mdash;
                </div>
                <div v-else>
                <span
                  style="margin-right: 10px"
                  v-html="row.SharpeRatio5Yr"
                ></span>
                </div>
              </mds-td>
            </mds-tr>
          </mds-tbody>
        </mds-table>
        <div class="mei-bond-watchlist-footnote">
          <p style="text-align: justify">
            Data through {{ new Date() | formatDateValForDataTables }}. |*The downside
            capture ratio is calculated against Morningstar category benchmark.
            <br />
            <br v-if="isDataTableResponsiveToggle" />
            Portfolio, tax, and risk data displayed through previous month end.
            Return and transaction data displayed through previous day end.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
    
        
<script>
import MdsStarRating from "@mds/star-rating";
import MdsAnalystRating from "@mds/analyst-rating";
import MdsLoader from "@mds/loader";
import { MdsButton } from "@mds/button";
import moment from "moment";
import { saveExcel } from "@progress/kendo-vue-excel-export";
import { mapState } from "vuex";
import {
  MdsTable,
  MdsThead,
  MdsTh,
  MdsTbody,
  MdsTr,
  MdsTd,
} from "@mds/data-table";
import EncryptDecryptMixin  from "../mixins/EncryptDecryptMixin.js";
import { fetchData } from "@/utilities/API/apiUtility.js";
export default {
  name: "bond-watchlist",
  components: {
    MdsTable,
    MdsThead,
    MdsTh,
    MdsTbody,
    MdsTr,
    MdsTd,
    MdsStarRating,
    MdsAnalystRating,
    MdsLoader,
    MdsButton,
  },
  mixins: [
		EncryptDecryptMixin
	],
  mounted() {
    this.isDataTableResponsiveToggle =
      this.$store.state.responsive.isDataTableResponsive;
    window.scrollTo(0, 0);
  },
  data() {
    return {
      CurrentURL :  "#",
      BondWatchlistURL:
        process.env.VUE_APP_BackEndAPI + process.env.VUE_APP_BondWatchlistURL,
      analystRatingClassMap: {
        1: "not-ratable",
        2: "under-review",
        3: "negative",
        4: "neutral",
        5: "bronze",
        6: "silver",
        7: "gold",
      },
      headers: [
        {
          text: "Ticker",
          fieldName: "Ticker",
          align: "left",
          class: "mei-bond-watchlist-Ticker",
          sortable: true,
          style: {
            //"padding-left": "5px",
          },
        },
        {
          text: "Name",
          fieldName: "Name",
          class: "mei-bond-watchlist-Name",
          sortable: true,
          sorted: 1,
          style: {
            "padding-left": "16px",
          },
        },
        {
          text: "Morningstar<br/>Category",
          fieldName: "MorningstarCategory",
          // class:'mei-bond-watchlist-Category',
          style: {
            "margin-left": "20px",
            "padding-left": "20px",
          },
          sortable: true,
        },
        {
          text: "Morningstar <br/>Medalist Rating",
          fieldName: "MorningstarMedalistRating",
          class: "mei-bond-watchlist-medalistRating",
          sortable: true,
        },
        {
          text: "Data Coverage %",
          fieldName: "DataCoverage",
          sortable: true,
          align: "right",
          style: {
            "padding-right": "10px",
          },
        },
        {
          text: "Analyst-Driven %",
          fieldName: "AnalystDriven",
          sortable: true,
          align: "right",
          style: {
            "padding-right": "16px",
          },
        },
        {
          text: "Morningstar<br/>Rating",
          fieldName: "MorningstarRating",
          class: "mei-bond-watchlist-Rating",
          sortable: true,
        },
        {
          text: "YTD",
          fieldName: "YTD",
          class: "mei-bond-watchlist-YTD",
          sortable: true,
          align: "right",
        },
        {
          text: "1-Yr",
          fieldName: "Return1Yr",
          class: "mei-bond-watchlist-1Yr",
          sortable: true,
          align: "right",
        },
        {
          text: "3-Yr",
          fieldName: "Return3Yr",
          class: "mei-bond-watchlist-3Yr",
          sortable: true,
          align: "right",
        },
        {
          text: "5-Yr",
          fieldName: "Return5Yr",
          class: "mei-bond-watchlist-5Yr",
          sortable: true,
          align: "right",
        },
        {
          text: "10-Yr",
          fieldName: "Return10Yr",
          class: "mei-bond-watchlist-10Yr",
          sortable: true,
          align: "right",
        },
        {
          text: "Since<br/> Inception",
          fieldName: "SinceInception",
          class: "mei-bond-watchlist-Inception",
          sortable: true,
          align: "right",
        },
        {
          text: "Inception Date",
          fieldName: "InceptionDate",
          align: "right",
          class: "mei-bond-watchlist-InceptionDate",
          sortable: true,
        },
        {
          text: "Month-End <br/> Mkt Price $",
          fieldName: "MarketPrice",
          class: "mei-bond-watchlist-marketPrice",
          sortable: true,
          align: "right",
        },
        {
          text: "12-Month<br/> Yield %",
          fieldName: "FundYield",
          class: "mei-bond-watchlist-12MoYield",
          sortable: true,
          align: "right",
        },
        {
          text: "Assets ($Bil)",
          fieldName: "Assets",
          class: "mei-bond-watchlist-Assets",
          sortable: true,
          align: "right",
        },
        {
          text: "Trailing 3-Mo Avg <br/> Share Volume",
          fieldName: "AvgShareVolume",
          style: {},
          class: "mei-bond-watchlist-shareVol",
          sortable: true,
          align: "right",
        },
        {
          text: "Expense <br/> Ratio %",
          fieldName: "ExpenseRatio",
          class: "mei-bond-watchlist-expenseRatio",
          sortable: true,
          align: "right",
        },
        {
          text: "Trailing 3-Yr Tax <br/> Cost Ratio %",
          fieldName: "TaxCostRatio",
          class: "mei-bond-watchlist-taxCostRatio",
          sortable: true,
          align: "right",
        },
        {
          text: "FI Sup Sec<br/> Gov't",
          fieldName: "SupSecGovt",
          class: "mei-bond-watchlist-padding",
          sortable: true,
          align: "right",
        },
        {
          text: "FI Sup Sec<br/> Corp",
          fieldName: "SupSecCorp",
          class: "mei-bond-watchlist-padding",
          sortable: true,
          align: "right",
        },
        {
          text: "FI Sup Sec <br/> Securitized",
          fieldName: "SupSecStruc",
          sortable: true,
          align: "right",
          class: "mei-bond-watchlist-padding",
        },
        {
          text: "FI Sup Sec <br/> Muni",
          fieldName: "SupSecMuni",
          sortable: true,
          align: "right",
          class: "mei-bond-watchlist-padding",
        },
        {
          text: "FI Sup Sec <br/> Cash & Eq",
          fieldName: "SupSecCash",
          sortable: true,
          align: "right",
          class: "mei-bond-watchlist-padding",
        },
        {
          text: "AAA%",
          fieldName: "CreditQualityAAA",
          sortable: true,
          align: "right",
          class: "mei-bond-watchlist-padding",
        },
        {
          text: "AA%",
          fieldName: "CreditQualityAA",
          sortable: true,
          align: "right",
          class: "mei-bond-watchlist-padding",
        },
        {
          text: "A%",
          fieldName: "CreditQualityA",
          sortable: true,
          align: "right",
          class: "mei-bond-watchlist-padding",
        },
        {
          text: "BBB%",
          fieldName: "CreditQualityBBB",
          sortable: true,
          align: "right",
          class: "mei-bond-watchlist-padding",
        },
        {
          text: "BB%",
          fieldName: "CreditQualityBB",
          sortable: true,
          align: "right",
          class: "mei-bond-watchlist-padding",
        },
        {
          text: "B%",
          fieldName: "CreditQualityB",
          sortable: true,
          align: "right",
          class: "mei-bond-watchlist-padding",
        },
        {
          text: "Below B%",
          fieldName: "CreditQualityBelowB",
          sortable: true,
          align: "right",
          class: "mei-bond-watchlist-padding",
        },
        {
          text: "Average Duration",
          fieldName: "AverageDuration",
          sortable: true,
          align: "right",
          class: "mei-bond-watchlist-avgDuration",
        },
        {
          text: "Worst 3-Mth Rtn",
          fieldName: "Worst3MonthReturn",
          class: "mei-bond-watchlist-worst3MthRtn",
          sortable: true,
          align: "right",
        },
        {
          text: "5-Yr* Downside Capture Ratio",
          fieldName: "DownsideMarketCapture",
          class: "mei-bond-watchlist-DownsideMarketCapture",
          sortable: true,
          align: "right",
        },
        {
          text: "5-Yr Standard Deviation",
          fieldName: "StdDev5Yr",
          class: "mei-bond-watchlist-StdDev5Yr",
          sortable: true,
          align: "right",
        },
        {
          text: "5-Yr Sharpe <br/> Ratio",
          fieldName: "SharpeRatio5Yr",
          // class:'mei-bond-watchlist-SharpeRatio5Yr',
          align: "right",
          style: {
            "padding-right": "10px",
          },
          sortable: true,
        },
      ],
      rows: [],
      sortOrder: 1,
      sortColIndex: 1,
      isLoading: true,
      isDisplay: false,
      isDataTableResponsiveToggle: null,
      excelColumn: [
        {
          add: "",
          delete: "",
        },
      ],
    };
  },

  methods: {
    DateSortingFilter(value) {
      var result = "";
      if (value) {
        result = value.replaceAll("/", "-");
        if (result) {
          var split = result.split("-");
          var temp = split[2];
          result = split[0] + "-" + split[1] + "-" + temp.substring(4, 2);
        }
      }
      return result;
    },

    handleSortEvent(colIndex, colName) {
      // if your tracked sort order is 0 (none), set it 1 (ascending)
      // if there is already an order being tracked inverse it
      this.sortOrder = this.sortOrder === 0 ? 1 : -this.sortOrder;

      // if the table was already sorted by a different column, then reset that column to 0 (none)
      if (this.sortColIndex !== null && this.sortColIndex !== colIndex) {
        this.$set(this.headers[this.sortColIndex], "sorted", 0);
        this.sortOrder = 1;
      }

      // track the current column as the one being sorted on
      this.sortColIndex = colIndex;
      this.$set(this.headers[colIndex], "sorted", this.sortOrder);

      // sort the row data by column clicked on
      if (colName == "Ticker") {
        var IncludeDash = this.rows.filter(function (el) {
          return el[colName] == null;
        });

        var WithOutDash = this.rows.filter(function (el) {
          return el[colName] != null;
        });
        WithOutDash.sort((a, b) => {
          if (colName == "Ticker") {
            return a[colName] >= b[colName] ? this.sortOrder : -this.sortOrder;
          }
        });

        let mergeArr = [];
        if (this.sortOrder == 1) {
          mergeArr = IncludeDash.concat(WithOutDash);
        } else {
          mergeArr = WithOutDash.concat(IncludeDash);
        }
        this.rows = mergeArr;
      } else {
        this.rows.sort((a, b) => {
          if (colName == "Name") {
            const name1 = a.Name.toUpperCase();
            const name2 = b.Name.toUpperCase();

            if (name1 > name2) {
              return this.sortOrder;
            } else if (name1 < name2) {
              return -this.sortOrder;
            }
          } else if (
            colName == "Return10Yr" ||
            colName == "Return5Yr" ||
            colName == "Return1Yr" ||
            colName == "Return3Yr" ||
            colName == "AvgMarketCap" ||
            colName == "Assets" ||
            colName == "AvgShareVolume" ||
            colName == "MoatNone" ||
            colName == "MarketPrice" ||
            colName == "SupSecGovt" ||
            colName == "SupSecCorp" ||
            colName == "SupSecStruc" ||
            colName == "SupSecMuni" ||
            colName == "SupSecCash" ||
            colName == "CreditQualityAAA" ||
            colName == "CreditQualityAA" ||
            colName == "CreditQualityA" ||
            colName == "CreditQualityBBB" ||
            colName == "CreditQualityBB" ||
            colName == "CreditQualityB" ||
            colName == "CreditQualityBelowB" ||
            colName == "AverageDuration" ||
            colName == "Worst3MonthReturn" ||
            colName == "DownsideMarketCapture" ||
            colName == "StdDev5Yr" ||
            colName == "SharpeRatio5Yr"
          ) {
            return parseFloat(a[colName]) >= parseFloat(b[colName])
              ? this.sortOrder
              : -this.sortOrder;
          } else if (colName == "InceptionDate") {
            var date1 = a[colName];
            var date2 = b[colName];

            var tempDate1 = date1.split("/");
            var tempDate2 = date2.split("/");

            var sortDate1 = "";
            var sortDate2 = "";

            if (tempDate1) {
              sortDate1 =
                tempDate1[2] + "-" + tempDate1[0] + "-" + tempDate1[1];
            }
            if (tempDate2) {
              sortDate2 =
                tempDate2[2] + "-" + tempDate2[0] + "-" + tempDate2[1];
            }

            return new Date(sortDate1) >= new Date(sortDate2)
              ? this.sortOrder
              : -this.sortOrder;
          } else if (colName == "Ticker") {
            var IncludeDash = this.rows.filter(function (el) {
              return el[colName] == null;
            });

            var WithOutDash = this.rows.filter(function (el) {
              return el[colName] != null;
            });
            let mergeArr = [];
            if (this.sortOrder == 1) {
              mergeArr = IncludeDash.concat(WithOutDash);
            } else {
              mergeArr = WithOutDash.concat(IncludeDash);
            }
            this.rows = mergeArr;
            return a[colName] >= b[colName] ? this.sortOrder : -this.sortOrder;
          } else {
            return a[colName] >= b[colName] ? this.sortOrder : -this.sortOrder;
          }
        });
      }
    },

    changeDateFormatETF(value) {
      var result = "";
      if (value) {
        var split = value.split("/");
        if (split[2].length == 4) {
          result = split[0] + "/" + split[1] + "/" + split[2].substring(2, 4);
        }
        else {
          result = value;
        }
      }
      return result;
    },

    ExportExcel() {
      const today = moment();
      let excelData = [];
      for (var z = 0; z < this.rows.length; z++) {
        var obj1 = this.rows[z];
        if (obj1.InceptionDate) {
          if (obj1.InceptionDate != "&mdash;" || obj1.InceptionDate != "") {
            obj1.InceptionDate = this.changeDateFormatETF(obj1.InceptionDate);
          }
        }
        excelData.push(obj1);
      }
      saveExcel({
        data: excelData,
        fileName: "MEI_BondWatchlist_" + today.format("MMDDYYYY") + ".xlsx",
        columns: [
          {
            field: "Ticker",
            title: "Ticker",
          },
          {
            field: "Name",
            title: "Name",
          },
          {
            field: "MorningstarCategory",
            title: "Morningstar Category",
          },
          {
            field: "MorningstarMedalistRating",
            title: "Morningstar Medalist Rating",
          },
          {
            field: "DataCoverage",
            title: "Data Coverage",
            cellOptions: { format: "#,##0.00" },
          },

          {
            field: "AnalystDriven",
            title: "Analyst Driven",
          },
          {
            field: "MorningstarRating",
            title: "Morningstar Rating",
          },

          {
            field: "YTD",
            title: "YTD",
          },
          {
            field: "Return1Yr",
            title: "1-Yr",
          },
          {
            field: "Return3Yr",
            title: "3-Yr",
          },
          {
            field: "Return5Yr",
            title: "5-Yr",
          },
          {
            field: "Return10Yr",
            title: "10-Yr",
          },

          {
            field: "SinceInception",
            title: "Since Inception",
          },
          {
            field: "InceptionDate",
            title: "Inception Date",
          },
          {
            field: "MarketPrice",
            title: "Month-End Mkt Price $",
          },
          {
            field: "FundYield",
            title: "12-Month Yield %",
          },
          {
            field: "Assets",
            title: "Assets ($Bil)",
          },

          {
            field: "AvgShareVolume",
            title: "Trailing 3-Mo Avg Share Volume",
          },
          {
            field: "ExpenseRatio",
            title: "Expense Ratio %",
          },
          {
            field: "TaxCostRatio",
            title: "Trailing 3-Yr Tax Cost Ratio %",
          },

          {
            field: "SupSecGovt",
            title: "FI Sup Sec Gov't",
          },
          {
            field: "SupSecCorp",
            title: "FI Sup Sec Corp",
          },
          {
            field: "SupSecStruc",
            title: "FI Sup Sec Securitized",
          },
          {
            field: "SupSecMuni",
            title: "FI Sup Sec Muni",
          },
          {
            field: "SupSecCash",
            title: "FI Sup Sec Cash & Eq",
          },

          {
            field: "CreditQualityAAA",
            title: "AAA%",
          },
          {
            field: "CreditQualityAA",
            title: "AA%",
          },
          {
            field: "CreditQualityA",
            title: "A%",
          },
          {
            field: "CreditQualityBBB",
            title: "BBB%",
          },
          {
            field: "CreditQualityBB",
            title: "BB%",
          },
          {
            field: "CreditQualityB",
            title: "B%",
          },
          {
            field: "CreditQualityBelowB",
            title: "Below B%",
          },

          {
            field: "AverageDuration",
            title: "Average Duration",
          },
          {
            field: "Worst3MonthReturn",
            title: "Worst 3-Mth Rtn",
          },
          {
            field: "DownsideMarketCapture",
            title: "5-Yr* Downside Capture Ratio",
          },
          {
            field: "StdDev5Yr",
            title: "5-Yr Standard Deviation",
          },
          {
            field: "SharpeRatio5Yr",
            title: "5-Yr Sharpe Ratio",
          },
        ],
      });
    },
    openMedalistReport(secId,Symbol) {
      this.$session.set("CurrentSecID",this.Encrypt(secId));
      this.$session.set("CurrentPortfolioName",this.Encrypt("bondPort"));
      if(this.$store.state.responsive.isMobileContent){
        // this.$router
        // .push({ name: "analyst", params: { currentproduct: "mei",ticker:Symbol}})
        // .catch((err) => {
        //   if (
        //     err.name !== "NavigationDuplicated" &&
        //     !err.message.includes(
        //       "Avoided redundant navigation to current location"
        //     )
        //   ) {
        //     console.log(err);
        //   }
        // });
        let resolvedRoute = this.$router.resolve({
        name: "analyst",
        params: {currentproduct: "mei",ticker:Symbol}
      });
      this.CurrentURL = resolvedRoute.href;
    }
    else{
      let resolvedRoute = this.$router.resolve({
        name: "analyst",
        params: {currentproduct: "mei",ticker:Symbol}
      });
      //window.open(resolvedRoute.href, '_blank');
      this.CurrentURL = resolvedRoute.href;
    }
    },
    redirectToStockQuote(exchangeId,ticker){
      var quotePageURL = "https://www.morningstar.com/etfs/"+exchangeId.toLowerCase()+"/"+ticker.toLowerCase()+"/quote";
      window.open(quotePageURL,"_blank");
    }  
  },
  created() {
    fetchData(this.BondWatchlistURL)
      .then((response) => {
        var tempdata = [];
        var customData = response.data;
        if (customData) {
          for (var i = 0; i < customData.length; i++) {
            if (customData[i].Ticker != null) {
              customData[i].Name = customData[i].Name.replace('?', '®');
              if (customData[i].MarketPrice == 0) {
                customData[i].MarketPrice = "00.00";
              } else {
                customData[i].MarketPrice = parseFloat(
                  customData[i].MarketPrice
                ).toFixed(2);
              }
              if (customData[i].Assets == 0 || customData[i].Assets == null) {
                customData[i].Assets = "00.00";
              } else {
                customData[i].Assets = customData[i].Assets.replaceAll(',', '');
                customData[i].Assets = (customData[i].Assets / 1000000000).toFixed(2);
              }

              if (customData[i].AvgShareVolume == 0 || customData[i].AvgShareVolume == null) {
                customData[i].AvgShareVolume = "00.00";
              } else {
                customData[i].AvgShareVolume = Math.round(
                  customData[i].AvgShareVolume);
              }
              if (customData[i].FundYield == 0) {
                customData[i].FundYield = "0.00";
              } else {
                customData[i].FundYield = parseFloat(
                  customData[i].FundYield
                ).toFixed(2);
              }
              if (customData[i].Return10Yr == 0) {
                customData[i].Return10Yr = "0.00";
              } else {
                customData[i].Return10Yr = parseFloat(
                  customData[i].Return10Yr
                ).toFixed(2);
              }
              if (customData[i].Return5Yr == 0) {
                customData[i].Return5Yr = "0.00";
              } else {
                customData[i].Return5Yr = parseFloat(
                  customData[i].Return5Yr
                ).toFixed(2);
              }
              if (customData[i].Return3Yr == 0) {
                customData[i].Return3Yr = "0.00";
              } else {
                customData[i].Return3Yr = parseFloat(
                  customData[i].Return3Yr
                ).toFixed(2);
              }
              if (customData[i].SharpeRatio5Yr == 0) {
                customData[i].SharpeRatio5Yr = "0.00";
              } else {
                customData[i].SharpeRatio5Yr = parseFloat(
                  customData[i].SharpeRatio5Yr
                ).toFixed(2);
              }
              if (customData[i].StdDev5Yr == 0) {
                customData[i].StdDev5Yr = "0.00";
              } else {
                customData[i].StdDev5Yr = parseFloat(
                  customData[i].StdDev5Yr
                ).toFixed(2);
              }
              if (customData[i].DownsideMarketCapture == 0) {
                customData[i].DownsideMarketCapture = "0.00";
              } else {
                customData[i].DownsideMarketCapture = parseFloat(
                  customData[i].DownsideMarketCapture
                ).toFixed(2);
              }
              if (customData[i].AverageDuration == 0) {
                customData[i].AverageDuration = "0.00";
              } else {
                customData[i].AverageDuration = parseFloat(
                  customData[i].AverageDuration
                ).toFixed(2);
              }
              if (customData[i].CreditQualityBelowB == 0) {
                customData[i].CreditQualityBelowB = "0.00";
              } else {
                customData[i].CreditQualityBelowB = parseFloat(
                  customData[i].CreditQualityBelowB
                ).toFixed(2);
              }
              if (customData[i].CreditQualityB == 0) {
                customData[i].CreditQualityB = "0.00";
              } else {
                customData[i].CreditQualityB = parseFloat(
                  customData[i].CreditQualityB
                ).toFixed(2);
              }
              if (customData[i].CreditQualityBB == 0) {
                customData[i].CreditQualityBB = "0.00";
              } else {
                customData[i].CreditQualityBB = parseFloat(
                  customData[i].CreditQualityBB
                ).toFixed(2);
              }
              if (customData[i].CreditQualityBBB == 0) {
                customData[i].CreditQualityBBB = "0.00";
              } else {
                customData[i].CreditQualityBBB = parseFloat(
                  customData[i].CreditQualityBBB
                ).toFixed(2);
              }
              if (customData[i].CreditQualityA == 0) {
                customData[i].CreditQualityA = "0.00";
              } else {
                customData[i].CreditQualityA = parseFloat(
                  customData[i].CreditQualityA
                ).toFixed(2);
              }
              if (customData[i].CreditQualityAA == 0) {
                customData[i].CreditQualityAA = "0.00";
              } else {
                customData[i].CreditQualityAA = parseFloat(
                  customData[i].CreditQualityAA
                ).toFixed(2);
              }
              if (customData[i].CreditQualityAAA == 0) {
                customData[i].CreditQualityAAA = "0.00";
              } else {
                customData[i].CreditQualityAAA = parseFloat(
                  customData[i].CreditQualityAAA
                ).toFixed(2);
              }
              if (customData[i].SupSecMuni == 0) {
                customData[i].SupSecMuni = "0.00";
              } else {
                customData[i].SupSecMuni = parseFloat(
                  customData[i].SupSecMuni
                ).toFixed(2);
              }
              if (customData[i].SupSecStruc == 0) {
                customData[i].SupSecStruc = "0.00";
              } else {
                customData[i].SupSecStruc = parseFloat(
                  customData[i].SupSecStruc
                ).toFixed(2);
              }
              if (customData[i].SupSecGovt == 0) {
                customData[i].SupSecGovt = "0.00";
              } else {
                customData[i].SupSecGovt = parseFloat(
                  customData[i].SupSecGovt
                ).toFixed(2);
              }
              if (customData[i].TaxCostRatio == 0) {
                customData[i].TaxCostRatio = "0.00";
              } else {
                customData[i].TaxCostRatio = parseFloat(
                  customData[i].TaxCostRatio
                ).toFixed(2);
              }
              if (customData[i].SupSecCorp == 0) {
                customData[i].SupSecCorp = "0.00";
              } else {
                customData[i].SupSecCorp = parseFloat(
                  customData[i].SupSecCorp
                ).toFixed(2);
              }
              if (customData[i].DataCoverage != 0) {
                customData[i].DataCoverage = parseInt(customData[i].DataCoverage);
              }
              if (customData[i].AnalystDriven != 0) {
                customData[i].AnalystDriven = parseInt(
                  customData[i].AnalystDriven
                );
              }
              // if (customData[i].InceptionDate !="") {
              //     customData[i].InceptionDate = this.changeDateFormatETF(customData[i].InceptionDate);
              // }

              tempdata.push(customData[i]);
            }
          }
        }
        this.rows = tempdata;
      })
      .catch((error) => {
        console.log(error);
        this.errored = true;
      })
      .finally(() => (this.isLoading = false), (this.isDisplay = true));
  },
  computed: mapState({
    isDataTableResponsive: (state) => state.responsive.isDataTableResponsive,
    isDataTableResponsiveAlias: "isDataTableResponsive",
    isDataTableResponsiveState(state) {
      return state.responsive.isDataTableResponsive;
    },
    isMobileContent: state => state.responsive.isMobileContent,
      isMobileContentAlias:'isMobileContent',
      isMobileContentState(state){
      return state.responsive.isMobileContent
    }
  }),
  watch: {
    isDataTableResponsiveState() {
      this.isDataTableResponsiveToggle = this.isDataTableResponsiveState;
    },
  },
};
</script>
    
        
<style lang="scss" scoped>
@import "@mds/constants";

::-webkit-scrollbar {
  height: 7px;
  width: 7px;
}

::-webkit-scrollbar-thumb {
  background: rgba(171, 171, 171, 0.3);
  border-radius: 6px;
}

.mei-bond-watchlist-loader {
  position: absolute;
  width: "276px";
  height: "69px";
  left: 42.92%;
  right: 37.92%;
  top: 82%;
  z-index: 2;
}

.mei-bond-watchlist-container {
    width: 1280px;
    margin: 0 auto;
}

.mei-bond-watchlist-tblheader {
  font-weight: 700;
  font-size: 16px;
  color: #5e5e5e;
  line-height: 25px;
}

.mei-bond-watchlist-tbl-first-row {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  color: #1e1e1e;
  line-height: 25px;
  flex: none;
  order: 0;
  flex-grow: 1;
}

.mei-bond-watchlist-tbl-first-row:hover {
  cursor: pointer;
  color: #006fba;
}

.mei-bond-watchlist-title {
  font-weight: 700;
  font-size: 32px;
  color: #1e1e1e;
  line-height: 36px;
}

.mei-bond-watchlist-nestedheader {
  color: #5e5e5e;
  font-weight: 700;
  font-size: 16px;
  margin-left: 12px;
  padding-bottom: -8px;
  line-height: 25px;
}

.mei-bond-watchlist-nestedheader-annualized {
  color: #5e5e5e;
  font-weight: 700;
  font-size: 16px;
  line-height: 25px;
  margin-left: 10px;
}

.mei-bond-watchlist-borderbottom-annualized {
  border-bottom: 1px solid #808080;
  width: 98%;
  position: absolute;
  top: 24px;
  left: 12px;
}

.mei-bond-watchlist-borderbottom {
  border-bottom: 1px solid #808080;
  width: 97%;
  position: absolute;
  margin-left: 14px;
  top: 24px;
  left: 0px;
}

.mei-bond-watchlist-borderbottom-credit {
  border-bottom: 1px solid #808080;
  width: 97%;
  position: absolute;
  margin-left: 14px;
  top: 24px;
  left: 0px;
}

.mei-bond-watchlist-tblHeaderColor {
  color: #5e5e5e;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 0px;
}

.mei-bond-watchlist-header-text {
  padding-top: 48px;
}

$mds-data-table-border-fixed-col: 2px solid $mds-color-neutral-90 !default;

body {
  padding: 1em;
  font-family: "Univers", HelveticaNeue, "Helvetica Neue", Helvetica, Arial,
    sans-serif;
}

.mei-bond-watchlist-table {
  width: 100%;
  margin-top: 25px;
}

.mei-bond-watchlist-data-table {
  width: 100%;
  height: 600px;
  box-shadow: none;
}
.mei-bond-watchlist-data-table.mds-table__scroller .mds-table--fixed-column {
  th {
    position: sticky;
    top: 0;
  }

  th:nth-child(1) {
    z-index: $mds-z-index-low + 1;
  }

  th:nth-child(1) {
    padding-left: 2px;
  }

  td {
    padding: 7px 2px;
  }

  td:nth-child(1) {
    padding: 5px 2px;
  }
}

.mei-bond-watchlist-middle-content{
  display:flex;
  width:97%
}

.mei-bond-watchlist-download-holding {
  margin-top: 30px;
  cursor: pointer;
  order:2;
  margin-left: auto;
  float:right;
}

.mei-bond-watchlist-download-holding:hover {
  cursor: pointer;
}

.mds-table--show-sortable .mds-th--sortable .mds-th__inner {
  border-bottom: none;
}

.mds-th--sortable.mds-th--sorted-descending .mds-th__inner,
.mds-th--sortable.mds-th--sorted-ascending .mds-th__inner {
  border-bottom: 1px solid rgb(30, 30, 30);
}

.mei-bond-watchlist-footnote {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  display: flex;
  align-items: flex-end;
  color: #5e5e5e;
  margin-bottom: 56px;
  margin-top: 19px;
}

.mei-bond-watchlist-pagination {
  margin-top: 12px;
}

.mei-bond-watchlist-Name {
  width: "300px";
  height: "21px";
  font-family: "UniversNext for MORN";
  font-style: "normal";
  font-weight: "700";
  font-size: "16px";
  line-height: "21px";
  color: "#5E5E5E";
  padding-left: 8px;
}

.mei-bond-watchlist-analyst-text {
  font-size: 14px;
  line-height: 18px;
  font-weight: 400;
  color: #5e5e5e;
  margin-top: 24px;
}
.mei-bond-watchlist-analyst-text-sec {
  font-size: 14px;
  line-height: 18px;
  font-weight: 400;
  color: #5e5e5e;
  margin-top: 6px;
}
.mei-bond-watchlist-Ticker {
  width: "65px";
  height: "21px";
  font-family: "UniversNext for MORN";
  font-style: "normal";
  font-weight: "700";
  font-size: "16px";
  line-height: "21px";
  color: "#5E5E5E";
  padding-left: "5px";
}

.mei-bond-watchlist-Category {
  width: "80px";
  height: "42px";
  font-family: "UniversNext for MORN";
  font-style: "normal";
  font-weight: "700";
  font-size: "16px";
  line-height: "21px";
  color: "#5E5E5E";
  padding-left: "16px" !important;
}

.mei-bond-watchlist-medalistRating {
  width: "103px";
  padding-left: "16px";
}

.mei-bond-watchlist-Rating {
  width: "120px";
  padding-left: "26px" !important;
}

.mei-bond-watchlist-YTD {
  padding-left: "16px";
}

.mei-bond-watchlist-1Yr {
  padding-left: "16px";
}

.mei-bond-watchlist-3Yr {
  padding-left: "16px";
}

.mei-bond-watchlist-5Yr {
  padding-left: "16px";
}

.mei-bond-watchlist-10Yr {
  padding-left: "16px";
}

.mei-bond-watchlist-Inception {
  width: "83px";
  padding-left: "16px";
}

.mei-bond-watchlist-InceptionDate {
  width: "108px";
  padding-left: "16px";
}

.mei-bond-watchlist-marketPrice {
  width: "107px";
  padding-left: "16px";
}

.mei-bond-watchlist-12MoYield {
  width: "87px";
  padding-left: "16px";
}

.mei-bond-watchlist-Assets {
  width: "111px";
  padding-left: "16px";
}

.mei-bond-watchlist-shareVol {
  width: "135px";
  padding-left: "16px";
}

.mei-bond-watchlist-expenseRatio {
  padding-left: "16px";
}

.mei-bond-watchlist-taxCostRatio {
  width: "126px";
  padding-left: "16px";
}

.mei-bond-watchlist-padding {
  padding-left: "16px";
}

.mei-bond-watchlist-avgDuration {
  width: "83px";
  padding-left: "16px";
}

.mei-bond-watchlist-worst3MthRtn {
  width: "82px";
  padding-left: "16px";
}

.mei-bond-watchlist-DownsideMarketCapture {
  width: "125px";
  padding-left: "16px";
}
.mei-bond-watchlist-StdDev5Yr {
  width: "112px";
  padding-left: "16px";
}

.mei-bond-watchlist-SharpeRatio5Yr {
  width: "98px";
  padding-right: "16px";
  padding-left: "16px";
}
.mei-bond-watchlist-table-ticker{
  cursor:pointer;
}
.mei-bond-watchlist-table-ticker:hover {
  color:#006fba;
}


@media only screen and (min-width: 320px) and (max-width: 1415px) {
  .mei-bond-watchlist-container { 
    width:90%;
    margin-left: 5%;
    margin-right: 5%;
  }
}

@media only screen and (min-width: 320px) and (max-width: 428px) {
  .mei-bond-watchlist-analyst-text {
    font-size: 14px;
    line-height: 18px;
    font-weight: 400;
    color: #5e5e5e;
    margin-top: 54px;
    width:90vw;
}
.mei-bond-watchlist-analyst-text-sec {
    font-size: 14px;
    line-height: 18px;
    font-weight: 400;
    color: #5e5e5e;
    width:90vw;
}

  .mei-bond-watchlist-middle-content{
    display: block;
  }

.mei-bond-watchlist-middle-content{
  display: block;
}

  .mei-bond-watchlist-download-holding {
    margin-top: 16px;
  }

  .mei-bond-watchlist-title {
    font-weight: 700;
    font-size: 28px;
    color: #1e1e1e;
    line-height: 36px;
  }
}

@media only screen and (min-width: 1537px) and (max-width: 3840px) {
  

}
.mei-bond-watchlist-data-table > table > tbody > tr > td:nth-child(2) > div > a:focus{ 
    box-shadow:none;
}
.mei-bond-watchlist-data-table > table > tbody > tr > td:nth-child(2) > div > a:hover{ 
    cursor:pointer;
}
</style>