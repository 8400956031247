import { render, staticRenderFns } from "./IncomeBellwethers.vue?vue&type=template&id=f52d0dfc&scoped=true"
import script from "./IncomeBellwethers.vue?vue&type=script&lang=js"
export * from "./IncomeBellwethers.vue?vue&type=script&lang=js"
import style0 from "./IncomeBellwethers.vue?vue&type=style&index=0&id=f52d0dfc&prod&lang=scss&scoped=true"
import style1 from "./IncomeBellwethers.vue?vue&type=style&index=1&id=f52d0dfc&prod&lang=scss&module=true"




function injectStyles (context) {
  
  this["$style"] = (style1.locals || style1)

}

/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  "f52d0dfc",
  null
  
)

export default component.exports