<template>
  <div>
    <div class="msi-tortoise-hare-watchlist-container">
      <div class="msi-tortoise-hare-watchlist-loader">
        <mds-loader v-if="isLoading" aria-label="Medium Loader"></mds-loader>
      </div>
      <div class="msi-tortoise-hare-watchlist-header-text">
        <div class="msi-tortoise-hare-watchlist-middle-content">
          <div class="msi-tortoise-hare-watchlist-title">Tortoise & Hare Watchlist</div>
          <div class="msi-tortoise-hare-watchlist-download-holding">
            <mds-button variation="flat" size="small" icon="document" @click="ExportExcel"
              class="msi-tortoise-hare-watclist-download-data">
              Download data
            </mds-button>
          </div>
        </div>
        <div class="msi-tortoise-hare-watchlist-analyst-text">
          Click on company name for latest Morningstar Analyst Report.
        </div>
        <div class="msi-tortoise-hare-watchlist-analyst-text-sec">
          Click on ticker for additional stock data from Morningstar.com.
        </div>
      </div>

      <div style="padding-top:24px;">
        <mds-table show-sortable fixed-first-col class="msi-tortoise-hare-watchlist-data-table">
          <mds-thead fixedHeader>
            <mds-th v-for="(header, index) in headers" :key="index" :right-aligned="header.align === 'right'"
              :style="header.style" :sortable="header.sortable" :sorted="header.sorted"
              @mds-th-sort-changed="handleSortEvent(index, header.fieldName, $event)">
              <span v-html="header.text" class="msi-tortoise-hare-watchlist-table-header"></span>
            </mds-th>
          </mds-thead>
          <mds-tbody v-if="isDisplay">
            <mds-tr v-for="(row, index) in rows" :key="index">
              <mds-td left-align style="padding-left:5px;">
                <div v-if="
                                    row.Symbol == '0' ||
                                    row.Symbol == '' ||
                                    row.Symbol == 'NaN' ||
                                    row.Symbol == '&mdash;' ||
                                    row.Symbol == null ||
                                    row.Symbol == '&nbsp;'
                                  ">
                  &mdash;
                </div>
                <div v-else @click="redirectToStockQuote(row.ExchangeId,row.Symbol)" class="msi-tortoise-hare-watchlist-ticker">
                  {{ row.Symbol }}
                </div>
              </mds-td>
              <mds-td style="padding-left:16px;">
                <div v-if="
                                    row.StandardName == '0' ||
                                    row.StandardName == '' ||
                                    row.StandardName == 'NaN' ||
                                    row.StandardName == '&mdash;' ||
                                    row.StandardName == null ||
                                    row.StandardName == '&nbsp;'
                                  ">
                  &mdash;
                </div>
                <div v-else>
                  <a class="msi-tortoise-hare-watchlist-first-row" v-html="row.StandardName"
                  @mouseover="openMedalistReport(row.SecId,row.Symbol)"
                  @touchstart="openMedalistReport(row.SecId,row.Symbol)"
                  target="_blank"
                  :href="CurrentURL"
                  ></a>
                </div>
              </mds-td>

              <mds-td left-align style="padding: 8px 24px;">
                <!-- <mds-td style="padding-left:0px;">{{ row.Symbol  }}</mds-td>
                  <mds-td left-align style="padding-left:24px;"> -->
                <div v-if="
                                    row.StarRating == '0' ||
                                    row.StarRating == '' ||
                                    row.StarRating == 'NaN' ||
                                    row.StarRating == '&mdash;' ||
                                    row.StarRating == null ||
                                    row.StarRating == '&nbsp;'
                                  ">
                  &mdash;
                </div>
                <div v-else>
                  <mds-star-rating :rating="parseInt(row.StarRating)" :custom-size="14"></mds-star-rating>
                </div>
              </mds-td>
              <mds-td style="padding-left: 10px;">
                <div v-if="
                                    row.EconomicMoat == '0' ||
                                    row.EconomicMoat == '' ||
                                    row.EconomicMoat == 'NaN' ||
                                    row.EconomicMoat == '&mdash;' ||
                                    row.EconomicMoat == null ||
                                    row.EconomicMoat == '&nbsp;'
                                  ">
                  &mdash;
                </div>
                <div v-else>
                  <span v-html="row.EconomicMoat"></span>
                </div>
              </mds-td>
              <!-- <mds-td style="padding-left: 8px;">
                  <span v-html="row.MoatTrend"></span>
                </mds-td> -->
              <mds-td style="padding-left: 15px;">
                <div v-if="
                                    row.Stewardship == '0' ||
                                    row.Stewardship == '' ||
                                    row.Stewardship == 'NaN' ||
                                    row.Stewardship == '&mdash;' ||
                                    row.Stewardship == null ||
                                    row.Stewardship == '&nbsp;'
                                  ">
                  &mdash;
                </div>
                <div v-else>
                  <span v-html="row.Stewardship"></span>
                </div>
              </mds-td>
              <mds-td style="padding-left: 15px;">

                <div v-if="
                                    row.Risk == '0' ||
                                    row.Risk == '' ||
                                    row.Risk == 'NaN' ||
                                    row.Risk == '&mdash;' ||
                                    row.Risk == null ||
                                    row.Risk == '&nbsp;'
                                  ">
                  &mdash;
                </div>

                <div v-else>
                  <span v-html="row.Risk"></span>
                </div>
              </mds-td>
              <mds-td right-aligned>
                <div v-if="
                                    row.LastClose == '0' ||
                                    row.LastClose == '' ||
                                    row.LastClose == 'NaN' ||
                                    row.LastClose == '&mdash;' ||
                                    row.LastClose == null ||
                                    row.LastClose == '&nbsp;'
                                  ">
                  &mdash;
                </div>
                <div v-else>

                  {{ row.LastClose | amountFilter}}
                </div>
              </mds-td>
              <mds-td right-aligned style="padding-left: 10px;">
                <div v-if="
                                    row.PriceChangePct == '0' ||
                                    row.PriceChangePct == '' ||
                                    row.PriceChangePct == 'NaN' ||
                                    row.PriceChangePct == '&mdash;' ||
                                    row.PriceChangePct == null ||
                                    row.PriceChangePct == '&nbsp;'
                                  ">
                  &mdash;
                </div>
                <div v-else>


                  {{ row.PriceChangePct | customDataFilter }}
                </div>
              </mds-td>
              <mds-td right-aligned>
                <div v-if="
                                    row.FairValue == '0' ||
                                    row.FairValue == '' ||
                                    row.FairValue == 'NaN' ||
                                    row.FairValue == '&mdash;' ||
                                    row.FairValue == null ||
                                    row.FairValue == '&nbsp;'
                                  ">
                  0.00
                </div>
                <div v-else>
                  {{row.FairValue | amountFilter}}
                </div>
              </mds-td>
              <mds-td right-aligned>
                <div v-if="
                                    row.PFV == '0' ||
                                    row.PFV == '' ||
                                    row.PFV == 'NaN' ||
                                    row.PFV == '&mdash;' ||
                                    row.PFV == null ||
                                    row.PFV == '&nbsp;'
                                  ">
                  &mdash;
                </div>
                <div v-else>
                  <span v-html="row.PFV"></span>
                </div>
              </mds-td>
              <mds-td right-aligned style="padding-left: 10px;">
                <div v-if="
                                    row.ForwardPE == '0' ||
                                    row.ForwardPE == '' ||
                                    row.ForwardPE == 'NaN' ||
                                    row.ForwardPE == '&mdash;' ||
                                    row.ForwardPE == null ||
                                    row.ForwardPE == '&nbsp;'
                                  ">
                  &mdash;
                </div>
                <div v-else>

                  {{ row.ForwardPE  }}
                </div>
              </mds-td>
              <mds-td right-aligned>
                <div v-if="
                                    row.ForwardDividendYield == '0' ||
                                    row.ForwardDividendYield == '' ||
                                    row.ForwardDividendYield == 'NaN' ||
                                    row.ForwardDividendYield == '&mdash;' ||
                                    row.ForwardDividendYield == null ||
                                    row.ForwardDividendYield == '&nbsp;'
                                  ">
                  &mdash;
                </div>
                <div v-else>
                  <!-- <span v-html="row.ForwardDividendYield"></span> -->
                  {{ row.ForwardDividendYield  }}
                </div>
              </mds-td>
              <mds-td style="padding-left:27px;">
                <div v-if="
                                    row.SectorName == '0' ||
                                    row.SectorName == '' ||
                                    row.SectorName == 'NaN' ||
                                    row.SectorName == '&mdash;' ||
                                    row.SectorName == null ||
                                    row.SectorName == '&nbsp;'
                                  ">
                  &mdash;
                </div>
                <div v-else>
                  {{ row.SectorName }}
                </div>
              </mds-td>
            </mds-tr>
          </mds-tbody>
        </mds-table>

        <span class="msi-tortoise-hare-watchlist-data-text">Data through {{ new Date() | formatDateValForDataTables }}
          UR=Under Review</span>
        <div class="msi-tortoise-hare-watchlist-first-border">
        </div>
        <div class="msi-tortoise-hare-watchlist-balance-text">

          The above information is provided for illustrative purposes only. The data and statistics presented are based on
          the most recent information available from Morningstar, Inc. and may or may not be an accurate reflection of
          current data. Investing in securities is subject to investment risk, including possible loss of principal. Past
          performance does not guarantee future results. There can be no assurance that any financial strategy will be
          successful.
          <br /><br />

          The information, data, analyses, and opinions presented herein do not constitute investment advice, are provided
          as of the date written, are provided solely for informational purposes and therefore are not an offer to buy or
          sell a security. Please note that references to specific securities or other investment options within this
          piece should not be considered an offer (as defined by the Securities and Exchange Act) to purchase or sell that
          specific investment.
          <br /><br />

          The Tortoise and Hare Watchlists contains 120 companies that could potentially fit with the investment mandate
          of Morningstar’s Tortoise or Hare portfolios. These may include companies with wide or narrow moats, positive or
          stable moat trends, and below-average price/fair value ratios according to Morningstar analysts at the time the
          Watchlists are compiled. Watchlists are provided for informational purposes only. You should not assume that
          specific securities on the Watchlists are being considered for inclusion in Morningstar’s Tortoise or Hare
          portfolios or will be profitable.
          <br /><br />

          Source: Morningstar Rating, rankings, categorizations, and fair value estimates are based on the analysis and
          estimates of Morningstar analysts.
          <br /><br />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { fetchData } from "@/utilities/API/apiUtility.js";
import MdsStarRating from "@mds/star-rating";
import MdsLoader from "@mds/loader";
import { MdsButton } from "@mds/button";
import moment from "moment";
import { exportToExcel } from "../../utilities/Excel/exportToExcel.js";
//import { saveExcel } from "@progress/kendo-vue-excel-export";
import {
  MdsTable,
  MdsThead,
  MdsTh,
  MdsTbody,
  MdsTr,
  MdsTd,
} from "@mds/data-table";
import EncryptDecryptMixin  from "../mixins/EncryptDecryptMixin.js";
import { mapState } from "vuex";
export default {
  name: "tortoise-and-hare-watchlist",
  components: {
    MdsTable,
    MdsThead,
    MdsTh,
    MdsTbody,
    MdsTr,
    MdsTd,
    MdsStarRating,
    MdsLoader,
    MdsButton,
  },
  mixins: [
		EncryptDecryptMixin
	],
  data() {
    return {
      CurrentURL :  "#",
      WatchlistURL:process.env.VUE_APP_BackEndAPI + process.env.VUE_APP_TortoiseHareURL,
      headers: [
      {
          text: "Ticker",
          fieldName: 'Symbol',
          style: {
            width: "58px",
            'height': '21px',
            'font-family': 'UniversNext for MORN',
            'font-style': 'normal',
            'font-weight': '700',
            'font-size': '16px',
            'line-height': '21px',
            'color': '#5E5E5E',
          },
          align: "left",
          sortable: true,
        },
        {
          text: "Name",
          fieldName: 'StandardName',
          style: {
            width: "250px",
            'height': '21px',
            'font-family': 'UniversNext for MORN',
            'font-style': 'normal',
            'font-weight': '700',
            'font-size': '16px',
            'line-height': '21px',
            'color': '#5E5E5E',
            'padding-left':'16px',
          },
          sortable: true,
          sorted: 1
        },       
        {
          text: "Morningstar Rating",
          fieldName: 'StarRating',
          style: {
            // 'width': '80px',
            // 'padding-left': '5px',
            'width': '122px',
            'height': '42px',
            'font-family': 'UniversNext for MORN',
            'font-style': 'normal',
            'font-weight': '700',
            'font-size': '16px',
            'line-height': '21px',
            'color': '#5E5E5E',
            'padding-left':'24px'
          },
          sortable: true,
        },
        {
          text: "Economic Moat",
          fieldName: 'EconomicMoat',
          style: {
            width: "80px",
            //'width': '66px',
            'height': '42px',
            'font-family': 'UniversNext for MORN',
            'font-style': 'normal',
            'font-weight': '700',
            'font-size': '16px',
            'line-height': '21px',
            'color': '#5E5E5E',
            'padding-left':'10px'
          },
          sortable: true,
        },
        // {
        //   text: "Moat Trend",
        //   fieldName: 'MoatTrend',
        //   style: {
        //     width: "91px",
        //     // 'width': '52px',
        //     'height': '42px',
        //     'font-family': 'UniversNext for MORN',
        //     'font-style': 'normal',
        //     'font-weight': '700',
        //     'font-size': '16px',
        //     'line-height': '21px',
        //     'color': '#5E5E5E',
        //     'padding-left':'8px'
        //   },
        //   sortable: true,
        // },
        {
          text: "Capital Allocation",
          fieldName: 'Stewardship',
          style: {
            width: "96px",
            // 'width': '59px',
            'height': '42px',
            'font-family': 'UniversNext for MORN',
            'font-style': 'normal',
            'font-weight': '700',
            'font-size': '16px',
            'line-height': '21px',
            'color': '#5E5E5E',
            'padding-left':'16px'
          },
          sortable: true,
        },
        {
          text: "Uncertainty Rating",
          fieldName: 'Risk',
          style: {
            width: "94px",
   // 'width': '168px',
            'height': '42px',
            'font-family': 'UniversNext for MORN',
            'font-style': 'normal',
            'font-weight': '700',
            'font-size': '16px',
            'line-height': '21px',
            'color': '#5E5E5E',
            'padding-left':'15px'
          },
          sortable: true,
        },
        {
          text: "Last Price $",
          fieldName: 'LastClose',
          style: {
            width: "67px",
            //'width': '53px',
            'height': '42px',
            'font-family': 'UniversNext for MORN',
            'font-style': 'normal',
            'font-weight': '700',
            'font-size': '16px',
            'line-height': '21px',
            'color': '#5E5E5E',
            'padding-left':'10px'
          },
          sortable: true,
          align:"right"
        },
        {
          text: "Daily Price Change %",
          fieldName: 'PriceChangePct',
          style: {
            width: "95px",
            'height': '42px',
            'font-family': 'UniversNext for MORN',
            'font-style': 'normal',
            'font-weight': '700',
            'font-size': '16px',
            'line-height': '21px',
            'color': '#5E5E5E',
            'padding-left':'0px'
          },
          sortable: true,
          align:"right"
        },
        {
          text: "Fair Value $",
          fieldName: 'FairValue',
          style: {
             width: "73px",
            'height': '42px',
            'font-family': 'UniversNext for MORN',
            'font-style': 'normal',
            'font-weight': '700',
            'font-size': '16px',
            'line-height': '21px',
            'color': '#5E5E5E',
            'padding-left':'22px'
          },
          sortable: true,
          align:"right"
        },
        {
          text: "Price/Fair Value",
          fieldName: 'PFV',
          style: {
            width: "83px",
            'height': '42px',
            'font-family': 'UniversNext for MORN',
            'font-style': 'normal',
            'font-weight': '700',
            'font-size': '16px',
            'line-height': '21px',
            'color': '#5E5E5E',
            'padding-left':'21px'
          },
          sortable: true,
          align:"right"
        },
        {
          text: "Price/Earning Forward",
          fieldName:'ForwardPE',
          style: {
             width: "106px",
            'font-family': 'UniversNext for MORN',
            'font-style': 'normal',
            'font-weight': '700',
            'font-size': '16px',
            'line-height': '21px',
            'color': '#5E5E5E',
            'padding-left':'21px'
          },
          sortable: true,
          align:"right"
        },
        {
          text: "Forward Dividend Yield",
          fieldName:'ForwardDividendYield',
          style: {
            width: "107px",
            'height': '42px',
            'font-family': 'UniversNext for MORN',
            'font-style': 'normal',
            'font-weight': '700',
            'font-size': '16px',
            'line-height': '21px',
            'color': '#5E5E5E',
            'padding-left':'4px'

          },
          sortable: true,
          align:"right"
        },
        {
          text: "Sector Name",
          fieldName:'SectorName',
          style: {
            'width': "200px",
            'padding-left':'27px'
          },
          sortable: true
        },
      ],
      rows: [],
      sortOrder: 1,
      sortColIndex: 1,
      isLoading: true,
      isDisplay: false,
      excelColumn: [{ add: "", delete: "" }],
      columns: [        
        { field: "Symbol", title: "Ticker" },
        { field: "StandardName", title: "Name" },
        { field: "StarRating", title: "Morningstar Rating" },
        { field: "EconomicMoat", title: "Economic Moat" },
        // { field: "MoatTrend", title: "Moat Trend" },
        { field: "Stewardship", title: "Capital Allocation" },
        {
          field: "Risk",
          title: "Uncertainty Rating",
        },
        { field: "LastClose", title: "Last Price $" },
        { field: "PriceChangePct", title: "Daily Price Change %" },
        { field: "FairValue", title: "Fair Value $" },
        { field: "PFV", title: "Price/Fair Value" },
        { field: "ForwardPE", title: "Price/Earning Forward" },
        { field: "ForwardDividendYield", title: "Forward Dividend Yield" },
        { field: "SectorName", title: "Sector Name" },
      ],
    };
  },
  methods: {
    handleSortEvent(colIndex, colName) {
      // if your tracked sort order is 0 (none), set it 1 (ascending)
      // if there is already an order being tracked inverse it
      this.sortOrder = this.sortOrder === 0 ? 1 : -this.sortOrder;

      // if the table was already sorted by a different column, then reset that column to 0 (none)
      if (this.sortColIndex !== null && this.sortColIndex !== colIndex) {
        this.$set(this.headers[this.sortColIndex], "sorted", 0);
        this.sortOrder = 1;
      }

      // track the current column as the one being sorted on
      this.sortColIndex = colIndex;
      this.$set(this.headers[colIndex], "sorted", this.sortOrder);

       // sort the row data by column clicked on
       this.rows.sort((a, b) => {
        if(colName=="StandardName")
        {
        const name1 = a.StandardName.toUpperCase();
               const name2 = b.StandardName.toUpperCase();
       
                if (name1 > name2) {
       
                return this.sortOrder;
                } else if (name1 < name2) {
        
                return -this.sortOrder;
                  }
        }
          else if (colName == "Risk") {
          var lowValue = 5;
          var mediumValue = 10;
          var highValue = 15;
          var veryHighVal = 25;
          var extremeVal = 35;
          var currentRowValue =0;
          var nextRowValue =0;

          if(a[colName] == "N/A")
          currentRowValue =0;
          if(a[colName] == "Low")
          currentRowValue =lowValue;
          if(a[colName] == "Medium")
          currentRowValue =mediumValue;
          if(a[colName] == "High")
          currentRowValue =highValue;
          if(a[colName] == "Very High")
          currentRowValue =veryHighVal;
          if(a[colName] == "Extreme")
          currentRowValue =extremeVal;

          if(b[colName] == "N/A")
          nextRowValue =0;
          if(b[colName] == "Low")
          nextRowValue =lowValue;
          if(b[colName] == "Medium")
          nextRowValue =mediumValue;
          if(b[colName] == "High")
          nextRowValue =highValue;
          if(b[colName] == "Very High")
          nextRowValue =veryHighVal;
          if(b[colName] == "Extreme")
          nextRowValue =extremeVal;

          return currentRowValue >= nextRowValue ? this.sortOrder : -this.sortOrder;
        }
        else if (colName == "FairValue" ||
          colName == "PFV" || colName == "LastClose") {
          return parseFloat(a[colName]) >= parseFloat(b[colName]) ? this.sortOrder : -this.sortOrder;
        }        
        else {
          return a[colName] >= b[colName] ? this.sortOrder : -this.sortOrder;
        }
       
      });
    },
    
    ExportExcel() {
    const today = moment();
    
    // Transform the rows data
    for (var z = 0; z < this.rows.length; z++) {
        var currentRow = this.rows[z];
        currentRow.EconomicMoat = currentRow.EconomicMoat.replace('&mdash;', '-');
    }
    
    // Prepare filtered data based on columns
    const filteredData = this.rows.map(row => {
        return Object.fromEntries(
            this.columns.filter(col => col.visible !== false)
                       .map(col => [col.field, row[col.field]])
        );
    });
    
    exportToExcel(
        "MSI_WideMoat_" + today.format("MMDDYYYY") + ".xlsx",
        "Wide Moat Analysis",
        filteredData
    ).then(() => {
        console.log("Excel file downloaded successfully");
    }).catch(error => {
        console.error("Failed to download Excel file:", error);
    });
},
    calculateUncertaintyRating(value) {
      var riskRating = "N/A";
      if (value == 5) {
        riskRating = "Low";
      }
      if (value == 10) {
        riskRating = "Medium";
      }
      if (value == 15) {
        riskRating = "High";
      }
      if (value == 25) {
        riskRating = "Very High";
      }
      if (value == 35) {
        riskRating = "Extreme";
      }
      if (value == "&mdash;") {
        riskRating = "&mdash;";
      }
      return riskRating;
    },
    openMedalistReport(secId,Symbol){
      this.$session.set("CurrentSecID",this.Encrypt(secId));
      this.$session.set("CurrentPortfolioName",this.Encrypt("tortHarePort"));
      if(this.$store.state.responsive.isMobileContent)
      { 
        // this.$router
        // .push({ name: "analyst", params: { currentproduct: "msi",ticker:Symbol}})
        // .catch((err) => {
        //   if (
        //     err.name !== "NavigationDuplicated" &&
        //     !err.message.includes(
        //       "Avoided redundant navigation to current location"
        //     )
        //   ) {
        //     console.log(err);
        //   }
        // });
        let resolvedRoute = this.$router.resolve({
        name: "analyst",
        params: {currentproduct: "msi",ticker:Symbol}
      });
      this.CurrentURL = resolvedRoute.href;
      }
      else
      {
      let resolvedRoute = this.$router.resolve({
        name: "analyst",
        params: {currentproduct: "msi",ticker:Symbol}
      });
      // window.open(resolvedRoute.href, '_blank');
      this.CurrentURL = resolvedRoute.href;
     }
    },
    round(value, precision) {
    var multiplier = Math.pow(10, precision || 0);
    return Math.round(value * multiplier) / multiplier;
    },
    redirectToStockQuote(exchangeId,ticker){
      if(exchangeId.toLowerCase() == "nyse"){
        exchangeId = "xnys";
      }else if(exchangeId.toLowerCase() == "nas"){
        exchangeId = "xnas";
      }
      if(ticker.toLowerCase() == "atvi"){
        ticker="aiy";
      }
      var quotePageURL = "https://www.morningstar.com/stocks/"+exchangeId.toLowerCase()+"/"+ticker.toLowerCase()+"/quote";
      window.open(quotePageURL,"_blank");
    }
  },
  created() {
    window.scrollTo(0, 0);
    fetchData(this.WatchlistURL)
      .then((response) => {
        var customData=response.data;
        if(customData){
          for(var i=0;i<customData.length;i++)
          {
            var obj=customData[i];
            if(customData[i].Risk)
            {
              customData[i].Risk = this.calculateUncertaintyRating(parseInt(obj.Risk));
            }
            if (customData[i].FairValue) {
                //typeof(parseInt(obj.FairValue) === NaN ? obj.FairValue : parseInt(obj.FairValue))
                if (isNaN(customData[i].FairValue))
                  customData[i].FairValue = 0.00;
                else
                {
                  let FairValue = obj.FairValue + ".00";
                  customData[i].FairValue  = parseFloat(FairValue).toFixed(2);
                }
              }
              if (customData[i].LastClose) {
                //typeof(parseInt(obj.FairValue) === NaN ? obj.FairValue : parseInt(obj.FairValue))
                if (isNaN(customData[i].LastClose))
                  customData[i].LastClose = 0.00;
                else
                {
                  let LastClose = obj.LastClose + ".00";
                  customData[i].LastClose  = parseFloat(LastClose).toFixed(2);
                }
              }
            if(customData[i].ForwardPE)
            {
              //typeof(parseInt(obj.FairValue) === NaN ? obj.FairValue : parseInt(obj.FairValue))
              if(isNaN(customData[i].ForwardPE))
              {
                customData[i].ForwardPE = 0.0;
              }
              else{
                let ForwardPE = this.round(obj.ForwardPE, 1);
                customData[i].ForwardPE = parseFloat(ForwardPE).toFixed(1);
              }
            }
            if (customData[i].ForwardDividendYield) {
                if (isNaN(customData[i].ForwardDividendYield)){
                  customData[i].ForwardDividendYield = 0.0;
                }
                else{
                  let ForwardDividendYield = this.round(obj.ForwardDividendYield, 1);
                  customData[i].ForwardDividendYield = parseFloat(ForwardDividendYield).toFixed(1);
                }
              }

            if (customData[i].PFV) {
                if (isNaN(customData[i].PFV))
                  customData[i].PFV = 0.00;
                else
                  customData[i].PFV = parseFloat(obj.PFV).toFixed(2);
              }
            if (customData[i].StandardName) {
              var nameVal = customData[i].StandardName;
              if (nameVal.includes("&amp;")) {
                customData[i].StandardName = nameVal.split("&amp;").join('&')
                //customData[i].StandardName = nameVal.Replace("&amp;","")
              }
              
              if (nameVal.includes("&#39;")) {
                customData[i].StandardName = nameVal.split("&#39;").join("'")
                //customData[i].StandardName = nameVal.Replace("&amp;","")
              }
            }
            
          }
         
        }
        this.rows = customData;
      })
      .catch((error) => {
        console.log(error);
        this.errored = true;
      })
      .finally(() => ((this.isLoading = false), (this.isDisplay = true)));
  },
  computed: mapState({
    isMobileContent: state => state.responsive.isMobileContent,
      isMobileContentAlias:'isMobileContent',
      isMobileContentState(state){
      return state.responsive.isMobileContent
    }
  }),
};
</script>
<style lang="scss" scoped>
@import "@mds/constants";

.msi-tortoise-hare-watchlist-analyst-text {
  font-size: 14px;
  line-height: 18px;
  font-weight: 400;
  color: #5E5E5E;
  margin-top: 24px;
}
.msi-tortoise-hare-watchlist-analyst-text-sec {
  font-size: 14px;
  line-height: 18px;
  font-weight: 400;
  color: #5E5E5E;
  margin-top: 6px;
}
.mds-td {
  line-height: 25px;
}

.msi-tortoise-hare-watchlist-data-table {
  box-shadow: none;
  height: 600px;
}

.msi-tortoise-hare-watchlist-loader {
  position: absolute;
  width: "276px";
  height: "69px";
  left: 42.92%;
  right: 37.92%;
  top: 55%;
}

.msi-tortoise-hare-watchlist-data-text {
  width: 255px;
  height: 18px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  display: flex;
  align-items: flex-end;
  color: #5E5E5E;
  padding-top: 24px;
}

.msi-tortoise-hare-watchlist-title {
  font-weight: 700;
  font-size: 32px;
  color: #1E1E1E;
  line-height: 36px
}

.msi-tortoise-hare-watchlist-header-text {
  width: 78%;
  padding-top: 48px;
  margin-left: 0px;
}

.msi-tortoise-hare-watchlist-first-border {
  border: 1px solid #cccccc;
  width: 100.8%;
  margin-top: 20px;
}

$mds-data-table-border-fixed-col: 2px solid $mds-color-neutral-90 !default;

body {
  padding: 1em;
}

.msi-tortoise-hare-watchlist-data-table.mds-table__scroller .mds-table--fixed-column {
  th {
    position: sticky;
    top: 0;
  }

  th:nth-child(1) {
    z-index: $mds-z-index-low + 1;
  }

  th:nth-child(1) {
    padding-left: 2px;
  }

  td {
    padding: 7px 2px;
  }

  td:nth-child(1) {
    padding: 5px 2px;
  }
}

.msi-tortoise-hare-watchlist-first-row {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  color: #1E1E1E;
  line-height: 25px;
}

.msi-tortoise-hare-watchlist-first-row:hover {
  cursor: pointer;
  color: #006fba;
}

.msi-tortoise-hare-watchlist-table-header {
  color: #5E5E5E;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 0px;
}

body {
  font-family: "Univers", HelveticaNeue, "Helvetica Neue", Helvetica, Arial,
    sans-serif;
}

.msi-tortoise-hare-watchlist-download-holding {
  margin-top: 30px;
  cursor: pointer;
  order: 2;
  margin-left: auto;
  float: right;
}

.msi-tortoise-hare-watchlist-middle-content {
  display: flex;
  width: 128%
}

.msi-tortoise-hare-watclist-download-data {
  font-size: 14px;
  margin-left: 5px;
  color: #5E5E5E;
}

.msi-tortoise-hare-watchlist-download-holding:hover {
  cursor: pointer;
}

.msi-tortoise-hare-watchlist-container {
  width: 1280px;
  margin: 0 auto;
}


.msi-tortoise-hare-watchlist-balance-text {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #1E1E1E;
  margin-top: 20px;
}

.mds-table--show-sortable .mds-th--sortable .mds-th__inner {
  border-bottom: none;
}

.mds-th--sortable.mds-th--sorted-descending .mds-th__inner,
.mds-th--sortable.mds-th--sorted-ascending .mds-th__inner {
  border-bottom: 1px solid rgb(30, 30, 30);
}

::-webkit-scrollbar {
  height: 7px;
  width: 7px;
}

::-webkit-scrollbar-thumb {
  background: rgba(171, 171, 171, 0.3);
  border-radius: 6px;
}

.msi-tortoise-hare-watchlist-ticker
{
  cursor:pointer;
}
.msi-tortoise-hare-watchlist-ticker:hover {
  color:#006fba;
}

@media only screen and (min-width: 320px) and (max-width: 1415px) {
  .msi-tortoise-hare-watchlist-container {
    width: 90%;
    margin-left: 5%;
    margin-right: 5%;
  }

  .msi-tortoise-hare-watchlist-balance-text {
    width: 90vw;
  }
}


@media only screen and (min-width: 320px) and (max-width: 600px) {

  .msi-tortoise-hare-watchlist-analyst-text {
    font-size: 14px;
    line-height: 18px;
    font-weight: 400;
    color: #5E5E5E;
    margin-top: 54px;
    width: 90vw;
  }
  .msi-tortoise-hare-watchlist-analyst-text-sec{
    font-size: 14px;
    line-height: 18px;
    font-weight: 400;
    color: #5E5E5E;
    width: 90vw;
  }
  .msi-tortoise-hare-watchlist-middle-content {
    display: block;
  }

  .msi-tortoise-hare-watchlist-download-holding {
    margin-top: 16px;
  }

  .msi-tortoise-hare-watchlist-title {
    font-weight: 700;
    font-size: 28px;
    color: #1e1e1e;
    line-height: 34px;
    width: 90vw;
  }
}

.msi-tortoise-hare-watchlist-data-table > table > tbody > tr > td:nth-child(2) > div > a:focus{ 
    box-shadow:none;
}
.msi-tortoise-hare-watchlist-data-table > table > tbody > tr > td:nth-child(2) > div > a:hover{ 
    cursor:pointer;
}

</style>