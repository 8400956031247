var render = function render(){var _vm=this,_c=_vm._self._c;return _c(_vm.cardTag,_vm._b({tag:"component",class:{
  [_vm.$style['mdc-card']]: true,
  [_vm.$style['mdc-card--link']]: _vm.isLink,
  [_vm.$style['mdc-card--stretch']]: _vm.stretch,
},attrs:{"tabindex":_vm.isLink ? 0 : null,"role":_vm.isLink ? 'link' : null}},'component',_vm.linkProps,false),[(_vm.hasSecondaryText)?_c('div',{class:_vm.$style['mdc-card__secondary-text']},[_vm._t("secondary-text")],2):_vm._e(),_c('mdc-metadata',{class:_vm.$style['mdc-card__metadata'],attrs:{"large":"","thumbnail":_vm.thumbnail}},[_vm._t("metadata")],2),_c('div',{class:{
    [_vm.$style['mdc-card__content']]: true,
    [_vm.$style['mdc-card__content--reverse']]: _vm.mediaFirst,
  }},[_c('div',[_c('div',{style:(_vm.captionClampStyles)},[_vm._t("caption",function(){return [_vm._v(_vm._s(_vm.caption))]})],2)]),_vm._t("content",function(){return [_vm._v(_vm._s(_vm.content))]})],2),_c('footer',[_c('mdc-metadata',[_vm._t("readmore")],2)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }