/*
 * SSR Safe Client Side ID attribute generation
 * Taken from https://github.com/bootstrap-vue/bootstrap-vue/blob/dev/src/mixins/id.js
 */
export default {
	props: {
		id: {
			type: String,
			default: null
		}
	},
	data() {
		return {
			localId: null
		};
	},
	computed: {
		safeId() {
			const id = this.id || this.localId;
			return prefix => {
				if (!id) {
					return null;
				}
				const safePrefix = (prefix || '').replace(/\s+/g, '_');
				return prefix ? `${safePrefix}__${id}` : id;
			};
		}
	},
	mounted() {
		this.$nextTick(() => {
			// Update dom with auto ID after dom loaded to prevent SSR hydration errors.
			this.localId = `UID__${this._uid}`;
		});
	}
};
