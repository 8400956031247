var render = function render(){var _vm=this,_c=_vm._self._c;return _c('li',{class:{
		[_vm.$style['mdc-list-group-item']]: true,
		[_vm.$style['mds-list-group-item']]: true,
		[_vm.$style['mds-list-group-item--disabled']]: _vm.disabled,
		[_vm.$style['mds-list-group-item--active']]: _vm.isActive,
		[_vm.$style['mdc-list-group-item--editable']]: _vm.editable
	},attrs:{"role":"none"}},[_c(_vm.isLink ? 'mdc-link' : 'div',_vm._g(_vm._b({tag:"component",class:{
			[_vm.$style['mdc-list-group-item__link']]: true,
			[_vm.$style['mds-list-group__link']]: true,
			[_vm.$style['mds-list-group__link--with-image']]: _vm.image
		},attrs:{"tabindex":_vm.isLink || !!_vm.$listeners ? 0 : undefined,"aria-disabled":_vm.disabled,"role":"option","aria-selected":_vm.isActive,"aria-current":_vm.isActive}},'component',_vm.linkProps,false),_vm.$listeners),[_c('div',{class:_vm.$style['mds-list-group-item__link-inner']},[_c('div',{class:[_vm.$style['mds-list-group-item__before'], _vm.$style['mds-list-group-item__before--indent']]},[(_vm.icon)?_c('div',{class:_vm.$style['mds-list-group-item__icon-container']},[_c('mdc-icon',{class:_vm.$style['mds-list-group__icon'],attrs:{"name":_vm.icon,"small":!_vm.isLarge}})],1):_vm._e(),(_vm.image)?_c('mdc-image',{class:[_vm.$style['mds-list-group-item__image']],attrs:{"src":_vm.image,"size":_vm.imageWidth,"presentational":"","aspect-ratio":"1:1"}}):_vm._e()],1),_c('div',{class:_vm.$style['mds-list-group-item__main']},[_c('div',{class:_vm.$style['mds-list-group-item__upper']},[_c('div',{class:{
							[_vm.$style['mdc-list-group-item__text']]: true,
							[_vm.$style['mds-list-group__item-text']]: true,
							'mdc-list-group-item__text--truncated': _vm.truncateText,
							'mdc-list-group-item__text--editable': _vm.editable
						}},[_vm._t("default"),(_vm.editable)?_c('div',{class:_vm.$style['mdc-list-group-item__edit-actions']},[_c('mdc-button',{class:_vm.$style['mdc-list-group-item__edit-button'],attrs:{"flat":"","icon-only":"","icon":"pencil","aria-label":"Edit"},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.onEdit.apply(null, arguments)}}}),_c('mdc-button',{class:_vm.$style['mdc-list-group-item__delete-button'],attrs:{"flat":"","icon-only":"","icon":"trash","aria-label":"Delete"},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.onDelete.apply(null, arguments)}}})],1):_vm._e()],2)]),(_vm.hasMetadata)?_c('div',{class:_vm.$style['mds-list-group-item__lower']},[_c('div',{class:_vm.$style['mds-list-group-item__metadata-below']},_vm._l((_vm.metadataArray),function(metadataItem,idx){return _c('span',{key:idx,class:_vm.$style['mds-list-group-item__metadata-below-item']},[_vm._v(" "+_vm._s(metadataItem)+" ")])}),0)]):_vm._e()])])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }