<template>
  <component :is="cardTag" :tabindex="isLink ? 0 : null" :role="isLink ? 'link' : null" v-bind="linkProps" :class="{
    [$style['mdc-card']]: true,
    [$style['mdc-card--link']]: isLink,
    [$style['mdc-card--stretch']]: stretch,
  }">
    <div v-if="hasSecondaryText" :class="$style['mdc-card__secondary-text']">
      <slot name="secondary-text" />
    </div>
    <mdc-metadata large :thumbnail="thumbnail" :class="$style['mdc-card__metadata']">
      <slot name="metadata" />
    </mdc-metadata>
    <div :class="{
      [$style['mdc-card__content']]: true,
      [$style['mdc-card__content--reverse']]: mediaFirst,
    }">
      
     
        <div>
          <div :style="captionClampStyles">
            <slot name="caption">{{ caption }}</slot>
          </div>
        </div>
    
      <slot name="content">{{ content }}</slot>
    </div>
    <footer>
      <mdc-metadata>
        <slot name="readmore" />
      </mdc-metadata>
    </footer>
  </component>
</template>

<script>
import MdcImage from "./MdcImage.vue";
import MdcMetadata from "./MdcMetadata.vue";
import MdcLinkMixin from "../mixins/MdcLinkMixin";

export default {
  components: {
    MdcImage,
    MdcMetadata,
  },
  mixins: [MdcLinkMixin],
  props: {
    level: {
      type: [Number, String],
      description: "Specifies the heading level in the document structure",
      default: 2,
      validator: (v) => ["1", "2", "3", "4", "5", "6"].includes(`${v}`),
    },
    title: {
      type: String,
      description: "Briefly summarizes card content",
      default: undefined,
    },
    titleLineClamp: {
      type: Number,
      description:
        "The number of lines to display in the title before clamping",
      default: 3,
    },
    caption: {
      type: String,
      description:
        "Provides additional text to clarify or establish the card content",
      default: undefined,
    },
    content: {
      type: String,
      description:
        "Provides additional text to clarify or establish the card content",
      default: undefined,
    },
    captionLineClamp: {
      type: Number,
      description:
        "The number of lines to display in the caption before clamping",
      default: 0,
    },
    mediaFirst: {
      type: Boolean,
      description: "Render default slot above title/caption",
      default: false,
    },
    image: {
      type: String,
      description: "Image URL to use in place of supplemental content",
      default: undefined,
    },
    thumbnail: {
      type: String,
      description: "Image URL such as author headshot to support metadata",
      default: undefined,
    },
    fullBleed: {
      type: Boolean,
      description: "Full bleed content slot (such as with an image)",
      default: false,
    },
    stretch: {
      type: Boolean,
      description: "Expand to height of parent",
      default: false,
    },
    size: {
      type: [String, Number],
      description: "Font size of title",
      default: 6,
    },
  },
  created() {
    this.refreshSlotVisibility();
  },
  beforeUpdate() {
    this.refreshSlotVisibility();
  },
  data() {
    return {
      hasContext: false,
      hasMedia: false,
      hasMetadata: false,
      hasSecondaryText: false,
      //isLink: false,
    };
  },
  computed: {
    hasFooter() {
      return this.hasContext || this.hasMetadata;
    },
    cardTag() {
      if (this.isLink) return "mdc-link";
      return "article";
    },
    titleClampStyles() {
      if (!this.titleLineClamp) return {};

      return {
        display: "-webkit-box",
        "-webkit-box-orient": "vertical",
        "-webkit-line-clamp": this.titleLineClamp,
        "line-clamp": this.titleLineClamp,
        overflow: "hidden",
      };
    },
    captionClampStyles() {
      if (!this.captionLineClamp) return {};

      return {
        display: "-webkit-box",
        "-webkit-box-orient": "vertical",
        "-webkit-line-clamp": this.captionLineClamp,
        "line-clamp": this.captionLineClamp,
        overflow: "hidden",
      };
    },
  },
  methods: {
    refreshSlotVisibility() {
      this.hasContext = !!this.$slots.context;
      this.hasMetadata = !!this.$slots.metadata || !!this.$slots.thumbnail;
      this.hasMedia = !!this.$slots.default;
      this.hasSecondaryText = !!this.$slots["secondary-text"];
    },
  },
};
</script>

<style lang="scss" module>
@import "@mds/constants";
@import '@mds/typography';

.mdc-card {
  @mixin expand-height() {
    display: flex;
    flex-direction: column;

    >* {
      flex-grow: 0;
      flex-shrink: 0;
    }
  }

  &__metadata {
    font-family: "Univers", HelveticaNeue, "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;
    /* or 150% */


    color: #5E5E5E;

  }

  &--stretch {
    height: 100%;
  }

  &__secondary-text {
    margin-bottom: $mds-space-1-x;
    font-family: "Univers", HelveticaNeue, "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 25px;
    line-height: 30px;
    /* identical to box height, or 121% */

    color: #000000;
  }

  &__content {
    font-family: "Univers", HelveticaNeue, "Helvetica Neue", Helvetica, Arial, sans-serif ;
    ;
    font-style: normal ;
    font-weight: 300 ;
    font-size: 16px ;
    line-height: 21px ;
    /* or 131% */


    color: #000000 ;
  }

  &__header {
    @include expand-height;
    flex-grow: 1;
  }

  &__caption {
    font-family: "Univers", HelveticaNeue, "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 20px;
    line-height: 26px;
    /* or 131% */


    color: #000000;
  }
}
</style>
