<template>
  <component
    :is="buttonTag"
    :id="id"
    ref="button"
    :aria-label="iconAriaLabel"
    class="mdc-button"
    :class="{
      [$style['mds-button']]: true,
      [$style['mds-button--primary']]: primary,
      [$style['mds-button--icon-only']]: iconOnly,
      [$style['mds-button--flat']]: flat || body || panel,
      [$style['mds-button--flat-icon-m']]: flat && iconMedium,
      [$style['mds-button--small']]: small,
      [$style['mds-button--large']]: large,
      'mdc-button--active': active,
      'mdc-button--filled': filled,
      'mdc-button--loading': loading,
      'mdc-button--body': body || panel,
      'mdc-button--panel': panel,
      'mdc-button--truncated': truncateContent,
    }"
    :type="buttonType"
    :disabled="disabled || loading"
    tabindex="0"
    v-bind="buttonLinkProps"
    :role="isLink ? 'link' : 'button'"
    v-on="$listeners"
  >
    <mdc-loader
      v-if="loading"
      :inverted="primary"
      :gray="!primary"
      :class="$style['mds-button__loader']"
    />
    <mdc-icon
      v-if="icon"
      class="mdc-button__content mdc-button__icon"
      :class="{
        [$style['mds-button__icon']]: true,
      }"
      :name="icon"
      :small="iconSmall"
    >
      <slot v-if="iconOnly" />
    </mdc-icon>

    <span
      v-if="icon && !iconOnly"
      class="mdc-button__content mdc-button__text"
      :class="{ 'mdc-button__content--truncated': truncateContent }"
    >
      <slot />
    </span>

    <span
      v-if="!icon && !iconOnly"
      class="mdc-button__content"
      :class="{ 'mdc-button__content--truncated': truncateContent }"
      ><slot
    /></span>

    <mdc-icon
      v-if="rightIcon"
      class="mdc-button__content mdc-button__icon"
      :class="{
        [$style['mds-button__icon']]: true,
        [$style['mds-button__icon--right']]: true,
      }"
      :name="rightIcon"
      :small="rightIconSmall || iconSmall"
    />
  </component>
</template>

<script>
import defaults from "lodash/defaults";
import MdcIcon from "./MdcIcon";
import MdcLinkMixin from "../mixins/MdcLinkMixin";

export default {
  components: {
    MdcIcon,
  },
  mixins: [MdcLinkMixin],
  props: {
    id: {
      type: String,
      description: "HTML element ID",
      default: undefined,
    },
    ariaLabel: {
      type: String,
      description: "Descriptive label for use with assistive technology",
      default: null,
    },
    primary: {
      type: Boolean,
      description:
        "Use for highest-priority actions that are required to complete the provided task",
      default: false,
    },
    loading: {
      type: Boolean,
      description: "Display in loading state",
      default: false,
    },
    flat: {
      type: Boolean,
      description: "Use for tertiary actions",
      default: false,
    },
    body: {
      type: Boolean,
      description: "Use for actions appearing as body text",
      default: false,
    },
    panel: {
      type: Boolean,
      description:
        "Use a panel-form appearance, e.g. for form flow step selections",
    },
    active: {
      type: Boolean,
      description: "Indicate button is active",
      default: false,
    },
    icon: {
      type: String,
      description:
        "Add an icon in the default (left) position to provide additional affordance to a button; provide icon name from MDS",
      default: undefined,
    },
    rightIcon: {
      type: String,
      description:
        "Add an icon to the right provide additional affordance to trigger an action; provide icon name from MDS",
      default: undefined,
    },
    rightIconSmall: {
      type: Boolean,
      description:
        "Use the small icon size unconditionally, such as in a menu down-caret",
      default: false,
    },
    iconOnly: {
      type: Boolean,
      description:
        "Use for toolbars and displays where words will not comfortably fit alongside icons",
      default: false,
    },
    iconMedium: {
      type: Boolean,
      description: "Use default icon size within medium flat buttons",
      default: false,
    },
    small: {
      type: Boolean,
      description: "Small size variation",
      default: false,
    },
    large: {
      type: Boolean,
      description: "Large size variation",
      default: false,
    },
    reset: {
      type: Boolean,
      description: "Indicate form reset button",
      default: false,
    },
    submit: {
      type: Boolean,
      description: "Indicate form submit button",
      default: false,
    },
    tag: {
      type: String,
      description: "HTML tag to render with button styling",
      default: "button",
    },
    onDark: {
      type: Boolean,
      description: "Render in context wwith a dark background",
      default: false,
    },
    filled: {
      type: Boolean,
      description:
        "Fill in color of button (specifically secondary) rather than taking on background",
      default: false,
    },
    truncateContent: {
      type: Boolean,
      description: "Truncate the content within the button",
      default: false,
    },
    disabled: {
      type: Boolean,
      description: "Disable the button",
      default: false,
    },
  },
  computed: {
    buttonLinkProps() {
      if (!this.isLink) return null;

      return defaults(
        {
          activeClass: "mds-button--active",
        },
        this.linkProps
      );
    },
    iconSmall() {
      if (this.iconMedium) return false;
      if (!this.large || (!this.small && !this.iconOnly)) return true;
      return false;
    },
    buttonTag() {
      if (this.isLink) return "mdc-link";
      return this.tag;
    },
    buttonType() {
      if (this.reset) return "reset";
      if (this.submit) return "submit";
      return "button";
    },
    iconAriaLabel() {
      let buttonText = this.ariaLabel;
      if (!buttonText && this.$slots.default) {
        buttonText = this.$slots.default[0].text;
      }
      return buttonText;
    },
  },
  methods: {
    focus() {
      if (!this.isLink) {
        this.$nextTick(() => {
          this.$refs.button.focus();
        });
      }
    },
  },
};
</script>

<style lang="scss" module>
@import "@mds/button/src/button.scss";
</style>

<style lang="scss" scoped>
@import "@mds/constants";
@import "~@mds/link";
@import "@mds/button/src/button_constants.scss";

.mdc-button {
  white-space: nowrap;
  justify-content: center;

  &--active {
    //background-color: $mds-interactive-color-secondary-active;
    border: 1px solid $mds-interactive-color-secondary-active;
    color: $mds-text-color-primary-on-dark;

    .mdc-button__icon {
      transition: fill $mds-animation-timing-1-x linear,
        stroke $mds-animation-timing-1-x linear;
      fill: $mds-text-color-primary-on-dark !important;
      stroke: $mds-text-color-primary-on-dark !important;
    }
  }

  &--filled {
    background-color: white;
  }

  &--body {
    .mdc-button__content {
      transition: color $mds-animation-timing-1-x linear;
      color: $mds-text-color-interactive-primary;
    }

    .mdc-button__icon {
      transition: fill $mds-animation-timing-1-x linear,
        stroke $mds-animation-timing-1-x linear;
      fill: $mds-text-color-interactive-primary !important;
      stroke: $mds-text-color-interactive-primary !important;
    }

    &:hover {
      .mdc-button__content {
        color: $mds-text-color-primary;
      }

      .mdc-button__icon {
        fill: $mds-text-color-primary !important;
        stroke: $mds-text-color-primary !important;
      }
    }
  }

  &--loading {
    position: relative;

    .mdc-button__content {
      opacity: 0;
    }

    &:disabled {
      opacity: 1;
    }
  }

  &--panel {
    background-color: $mds-background-color-light-gray;
    border-radius: $mds-border-radius-panel;
    height: $mds-space-4-x + $mds-typography-line-height-m * 2;
    justify-content: flex-start;
    padding: $mds-space-2-x;
    width: 100%;
  }

  &--truncated {
    width: 100%;
  }

  &__content {
    &--truncated {
      display: block;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }

  &:hover,
  &:active {
    ::v-deep .mdc-loader__item:after {
      //background: $mds-button--secondary-text-color-hover !important;
    }
  }
}
</style>
