var render = function render(){var _vm=this,_c=_vm._self._c;return _c('span',{class:{
		[_vm.$style['mdc-icon']]: true,
		[_vm.$style['mdc-icon--small']]: _vm.small,
		[_vm.$style['mdc-icon--unstroke']]: !_vm.isMdsIcon
	},style:({
		stroke: _vm.color,
		fill: _vm.color,
	}),attrs:{"aria-hidden":!_vm.ariaLabel,"aria-label":_vm.ariaLabel},domProps:{"innerHTML":_vm._s(_vm.svgSrc)}})
}
var staticRenderFns = []

export { render, staticRenderFns }