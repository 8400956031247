<template>
  <div>
    <div class="msi-tortoise-portfolio-container">
      <div class="msi-tortoise-portfolio-table-loader">
        <mds-loader v-if="isLoading" aria-label="Medium Loader"></mds-loader>
      </div>

      <div class="msi-tortoise-portfolio-header-text">
        <div class="msi-tortoise-portfolio-title">Tortoise Portfolio Holdings</div>
        <div class="msi-tortoise-portfolio-middle-content">
        <div class="msi-tortoise-portfolio-middle-text">
          Current holdings as of last trading activity in a portfolio which
          Morningstar, Inc. has invested in accordance with the Tortoise
          strategy as described on the Home page.
        </div>
        <div class="msi-tortoise-portfolio-download-holding">
          <mds-button
            variation="flat"
            size="small"
            icon="document"
            @click="ExportExcel"
            class="msi-tortoise-portfolio-download-data"
          >
            Download data
          </mds-button>
        </div>
        </div>
        <div class="msi-tortoise-portfolio-analyst-text">
          Click on company name for latest Morningstar Analyst Report.
        </div>
        <div class="msi-tortoise-portfolio-analyst-text-sec">
          Click on ticker for additional stock data from Morningstar.com.
        </div>
      </div>
      <div style="padding-top: 24px">
        <mds-table
          show-sortable
          fixed-first-col
          style="max-height:600px"
          class="msi-tortoise-portfolio-data-table"
        >
          <mds-thead fixedHeader>
            <mds-th
              v-for="(header, index) in headers"
              :key="index"
              :right-aligned="header.align === 'right'"
              :style="header.style"
              :sortable="header.sortable"
              :sorted="header.sorted"
              @mds-th-sort-changed="handleSortEvent(index, header.fieldName, $event)"
            >
              <span
                v-html="header.text"
                class="msi-tortoise-portfolio-table-header"
              ></span>
            </mds-th>
          </mds-thead>
          <mds-tbody v-if="isDisplay">
            <mds-tr v-for="(row, index) in rows" :key="index">
              <mds-td left-align style="padding-left: 5px">
                <div
                  v-if="
                    row.Symbol == '0' ||
                    row.Symbol == '' ||
                    row.Symbol == 'NaN' ||
                    row.Symbol == '&mdash;' ||
                    row.Symbol == null ||
                    row.Symbol == '&nbsp;'
                  "
                >
                  &mdash;
                </div>
                <div
                  v-else
                  @click="redirectToStockQuote(row.ExchangeId, row.Symbol)"
                  class="msi-tortoise-portfolio-table-ticker"
                >
                  {{ row.Symbol }}
                </div>
              </mds-td>
              <mds-td style="padding-left: 16px">
                <div
                  v-if="
                    row.StandardName == '0' ||
                    row.StandardName == '' ||
                    row.StandardName == 'NaN' ||
                    row.StandardName == '&mdash;' ||
                    row.StandardName == null ||
                    row.StandardName == '&nbsp;'
                  "
                >
                  &mdash;
                </div>
                <div v-else>
                  <a
                    class="msi-tortoise-portfolio-table-first-row"
                    v-html="row.StandardName"
                    @mouseover="openMedalistReport(row.SecId, row.Symbol)"
                    @touchstart="openMedalistReport(row.SecId, row.Symbol)"
                    target="_blank"
                    :href="CurrentURL"
                  ></a>
                </div>
              </mds-td>

              <mds-td left-align style="padding: 8px 24px">
                <!-- <mds-td style="padding-left:0px;">{{ row.Symbol  }}</mds-td>
                  <mds-td left-align style="padding-left:0px;"> -->
                <div
                  v-if="
                    row.StarRating == '0' ||
                    row.StarRating == '' ||
                    row.StarRating == 'NaN' ||
                    row.StarRating == '&mdash;' ||
                    row.StarRating == null ||
                    row.StarRating == '&nbsp;'
                  "
                >
                  &mdash;
                </div>
                <div v-else>
                  <mds-star-rating
                    :rating="parseInt(row.StarRating)"
                    :custom-size="14"
                  ></mds-star-rating>
                </div>
              </mds-td>
              <mds-td style="padding-left: 16px">
                <div
                  v-if="
                    row.EconomicMoat == '0' ||
                    row.EconomicMoat == '' ||
                    row.EconomicMoat == 'NaN' ||
                    row.EconomicMoat == '&mdash;' ||
                    row.EconomicMoat == null ||
                    row.EconomicMoat == '&nbsp;'
                  "
                >
                  &mdash;
                </div>
                <div v-else>
                  <span v-html="row.EconomicMoat"></span>
                </div>
              </mds-td>
              <!-- <mds-td style="padding-left: 18px;">
                <span v-html="row.MoatTrend"></span>
              </mds-td> -->
              <mds-td style="padding-left: 15px">
                <div
                  v-if="
                    row.FidGrade == '0' ||
                    row.FidGrade == '' ||
                    row.FidGrade == 'NaN' ||
                    row.FidGrade == '&mdash;' ||
                    row.FidGrade == null ||
                    row.FidGrade == '&nbsp;'
                  "
                >
                  &mdash;
                </div>
                <div v-else>
                  <span v-html="row.FidGrade"></span>
                </div>
              </mds-td>
              <mds-td style="padding-left: 18px">
                <div
                  v-if="
                    row.RiskRating == '0' ||
                    row.RiskRating == '' ||
                    row.RiskRating == 'NaN' ||
                    row.RiskRating == '&mdash;' ||
                    row.RiskRating == null ||
                    row.RiskRating == '&nbsp;'
                  "
                >
                  &mdash;
                </div>
                <div v-else>
                  <span v-html="row.RiskRating"></span>
                </div>
                <!-- <span v-html="calculateUncertaintyRating(row.RiskRating)"></span> -->
              </mds-td>
              <mds-td right-aligned>
                <div
                  v-if="
                    row.LastPrice == '0' ||
                    row.LastPrice == '' ||
                    row.LastPrice == 'NaN' ||
                    row.LastPrice == '&mdash;' ||
                    row.LastPrice == null ||
                    row.LastPrice == '&nbsp;'
                  "
                >
                  &mdash;
                </div>
                <div v-else>
                  {{ row.LastPrice | amountFilter }}
                </div>
              </mds-td>
              <mds-td right-aligned>
                <div
                  v-if="
                    row.PriceChangePct == '0' ||
                    row.PriceChangePct == '' ||
                    row.PriceChangePct == 'NaN' ||
                    row.PriceChangePct == '&mdash;' ||
                    row.PriceChangePct == null ||
                    row.PriceChangePct == '&nbsp;'
                  "
                >
                  &mdash;
                </div>
                <div v-else>
                  {{ row.PriceChangePct | customDataFilter }}
                </div>
              </mds-td>
              <mds-td right-aligned>
                <div
                  v-if="
                    row.FairValue == '0' ||
                    row.FairValue == '' ||
                    row.FairValue == 'NaN' ||
                    row.FairValue == '&mdash;' ||
                    row.FairValue == null ||
                    row.FairValue == '&nbsp;'
                  "
                >
                  0.00
                </div>
                <div v-else>
                  {{ row.FairValue | amountFilter }}
                </div>
              </mds-td>
              <mds-td right-aligned>
                <div
                  v-if="
                    row.PFV == '0' ||
                    row.PFV == '' ||
                    row.PFV == 'NaN' ||
                    row.PFV == '&mdash;' ||
                    row.PFV == null ||
                    row.PFV == '&nbsp;'
                  "
                >
                  &mdash;
                </div>
                <div v-else>
                  <span v-html="row.PFV"></span>
                </div>
              </mds-td>
              <mds-td right-aligned>
                <div
                  v-if="
                    row.Quantity == '0' ||
                    row.Quantity == '' ||
                    row.Quantity == 'NaN' ||
                    row.Quantity == '&mdash;' ||
                    row.Quantity == null ||
                    row.Quantity == '&nbsp;'
                  "
                >
                  &mdash;
                </div>
                <div v-else>
                  {{ row.Quantity | amountFilter }}
                </div>
              </mds-td>
              <mds-td right-aligned style="margin-left: 20px">
                <div
                  v-if="
                    row.Weight == '0' ||
                    row.Weight == '' ||
                    row.Weight == 'NaN' ||
                    row.Weight == '&mdash;' ||
                    row.Weight == null ||
                    row.Weight == '&nbsp;'
                  "
                >
                  &mdash;
                </div>
                <div v-else>
                  {{ row.Weight }}
                </div>
              </mds-td>
              <mds-td style="padding-left: 33px">
                <div
                  v-if="
                    row.SectorName == '0' ||
                    row.SectorName == '' ||
                    row.SectorName == 'NaN' ||
                    row.SectorName == '&mdash;' ||
                    row.SectorName == null ||
                    row.SectorName == '&nbsp;'
                  "
                >
                  &mdash;
                </div>
                <div v-else>
                  {{ row.SectorName }}
                </div>
              </mds-td>
            </mds-tr>
            <mds-tr
              v-for="(row, index) in cashArray"
              :key="index + 'A'"
              class="msi-tortoise-portfolio-cash-row"
            >
              <!-- <mds-td><span class="msi-tortoise-portfolio-table-first-row" style="width:302px;" v-html="row1.StandardName"></span></mds-td> -->

              <mds-td left-align class="msi-tortoise-portfolio-cash-first-column">
                {{ row.StandardName }}</mds-td
              >
              <!-- <mds-td style="width: 1vw;padding-right: 8px;"></mds-td> -->
              <mds-td></mds-td>
              <mds-td left-align style="padding: 8px 24px">&mdash;</mds-td>
              <mds-td style="padding-left: 16px">&mdash;</mds-td>
              <mds-td style="padding-left: 15px">&mdash;</mds-td>
              <mds-td style="padding-left: 18px">&mdash;</mds-td>
              <mds-td right-aligned>&mdash;</mds-td>
              <mds-td right-aligned>&mdash;</mds-td>
              <mds-td right-aligned>&mdash;</mds-td>
              <mds-td right-aligned>&mdash;</mds-td>
              <mds-td right-aligned>&mdash;</mds-td>
              <mds-td right-aligned>{{ row.Weight }}</mds-td>
              <mds-td right-aligned style="padding-right: 25px">&mdash;</mds-td>
            </mds-tr>
            <mds-tr total-row>
              <mds-td style="padding-left: 0px"> Total </mds-td>
              <mds-td></mds-td>
              <mds-td></mds-td>
              <mds-td></mds-td>
              <mds-td></mds-td>
              <mds-td></mds-td>
              <mds-td></mds-td>
              <mds-td></mds-td>
              <mds-td></mds-td>
              <mds-td></mds-td>
              <mds-td></mds-td>
              <mds-td right-aligned>{{ totalWeight }}</mds-td>
              <mds-td></mds-td>
            </mds-tr>
          </mds-tbody>
        </mds-table>

        <span class="msi-tortoise-portfolio-data-text"
          >Data through {{ new Date() | formatDateValForDataTables }} UR=Under
          Review</span
        >
        <div class="msi-tortoise-portfolio-first-border"></div>
        <div class="msi-tortoise-portfolio-balance-text">
          The above information is provided for illustrative purposes only. The data and
          statistics presented are based on the most recent information available from
          Morningstar and may or may not be an accurate reflection of current data.
          Investing in securities is subject to investment risk, including possible loss
          of principal. Past performance does not guarantee future results. There can be
          no assurance that any financial strategy will be successful.
          <br /><br />

          The information, data, analyses, and opinions presented herein do not constitute
          investment advice, are provided as of the date written, are provided solely for
          informational purposes and therefore are not an offer to buy or sell a security.
          Please note that references to specific securities or other investment options
          within this piece should not be considered an offer (as defined by the
          Securities and Exchange Act) to purchase or sell that specific investment.
          <br /><br />

          Source: Morningstar Rating, rankings, categorizations, and fair value estimates
          are based on the analysis and estimates of Morningstar analysts.
          <br /><br />
        </div>
      </div>
      <div class="msi-tortoise-portfolio-second-border"></div>
      <div class="msi-tortoise-portfolio-research-title">
        Recently Published Research by Morningstar, Inc.
      </div>
      <div style="margin-top: 26px">
        <div>
          <mds-loader v-if="isLoadingReport" aria-label="Medium Loader"></mds-loader>
        </div>
        <div class="msi-tortoise-portfolio-research-table">
          <mds-table>
            <mds-thead>
              <mds-th style="width: 700px">Title</mds-th>
              <mds-th style="width: 200px; padding-left: 8px">Author</mds-th>
              <mds-th style="width: 100px; padding-left: 8px">Date</mds-th>
            </mds-thead>
            <mds-tbody v-if="isResearchDisplay">
              <mds-tr v-for="(row, index) in rowsResultResearch" :key="index">
                <mds-td
                  ><a
                    class="msi-tortoise-portfolio-table-first-row"
                    @mouseover="openMedalistReport(row.SecId, row.Symbol)"
                    @touchstart="openMedalistReport(row.SecId, row.Symbol)"
                    target="_blank"
                    :href="CurrentURL"
                  >
                    {{ row.Title }}</a
                  ></mds-td
                >
                <mds-td>{{ row.Author }}</mds-td>
                <mds-td>{{ formatDateFunction(row.Date) }}</mds-td>
              </mds-tr>
            </mds-tbody>
          </mds-table>
        </div>
        <div class="msi-tortoise-portfolio-intraday-text">
          U.S. intraday real-time exchange quotes are updated every 5 minutes during
          market hours. *Fair Value based on Morningstar analyst estimates. UR=Under
          Review.
          <br />
          <br v-if="isDataTableResponsiveToggle" />
          U.S. intraday real-time exchange quotes are sourced from BATS when available.
          End-of-day quotes for NASDAQ, NYSE, and Amex securities will appear 15 minutes
          after close.
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import moment from "moment";
//import { saveExcel } from "@progress/kendo-vue-excel-export";
import { exportToExcel } from "../../utilities/Excel/exportToExcel.js";
import { MdsButton } from "@mds/button";
import { fetchData } from "@/utilities/API/apiUtility.js";
import MdsLoader from "@mds/loader";
import MdsStarRating from "@mds/star-rating";
import { mapState } from "vuex";
import { MdsTable, MdsThead, MdsTh, MdsTbody, MdsTr, MdsTd } from "@mds/data-table";
import EncryptDecryptMixin from "../mixins/EncryptDecryptMixin.js";
export default {
  name: "tortoise-portfolio",
  components: {
    MdsTable,
    MdsThead,
    MdsTh,
    MdsTbody,
    MdsTr,
    MdsTd,
    MdsStarRating,
    MdsButton,
    MdsLoader,
  },
  mixins: [EncryptDecryptMixin],
  data() {
    return {
      CurrentURL: "#",
      TortoiseURL: process.env.VUE_APP_BackEndAPI + process.env.VUE_APP_TortoiseURL,
      totalWeight: 0,
      cashArray: [],
      isDataTableResponsiveToggle: null,
      headers: [
        {
          text: "Ticker",
          fieldName: "Symbol",
          style: {
            width: "65px",
            height: "21px",
            "font-family": "UniversNext for MORN",
            "font-style": "normal",
            "font-weight": "700",
            "font-size": "16px",
            "line-height": "21px",
            color: "#5E5E5E",
          },
          align: "left",
          sortable: true,
        },
        {
          text: "Name",
          fieldName: "StandardName",
          style: {
            width: "250px",
            height: "21px",
            "font-family": "UniversNext for MORN",
            "font-style": "normal",
            "font-weight": "700",
            "font-size": "16px",
            "line-height": "21px",
            color: "#5E5E5E",
            "padding-left": "16px",
          },
          sortable: true,
          sorted: 1,
        },
        {
          text: "Morningstar Rating",
          fieldName: "StarRating",
          style: {
            // 'width': '80px',
            // 'padding-left': '5px',
            width: "122px",
            height: "42px",
            "font-family": "UniversNext for MORN",
            "font-style": "normal",
            "font-weight": "700",
            "font-size": "16px",
            "line-height": "21px",
            color: "#5E5E5E",
            "padding-left": "24px",
          },
          sortable: true,
        },
        {
          text: "Economic Moat",
          fieldName: "EconomicMoat",
          style: {
            width: "89px",
            //'width': '66px',
            height: "42px",
            "font-family": "UniversNext for MORN",
            "font-style": "normal",
            "font-weight": "700",
            "font-size": "16px",
            "line-height": "21px",
            color: "#5E5E5E",
            "padding-left": "17px",
          },
          sortable: true,
        },
        // {
        //   text: "Moat Trend",
        //   fieldName: 'MoatTrend',
        //   style: {
        //     width: "96px",
        //     // 'width': '52px',
        //     'height': '42px',
        //     'font-family': 'UniversNext for MORN',
        //     'font-style': 'normal',
        //     'font-weight': '700',
        //     'font-size': '16px',
        //     'line-height': '21px',
        //     'color': '#5E5E5E',
        //     'padding-left':'16px'
        //   },
        //   sortable: true,
        // },
        {
          text: "Capital Allocation",
          fieldName: "FidGrade",
          style: {
            width: "99px",
            // 'width': '59px',
            height: "42px",
            "font-family": "UniversNext for MORN",
            "font-style": "normal",
            "font-weight": "700",
            "font-size": "16px",
            "line-height": "21px",
            color: "#5E5E5E",
            "padding-left": "16px",
          },
          sortable: true,
        },
        {
          text: "Uncertainty Rating",
          fieldName: "RiskRating",
          style: {
            width: "96px",
            // 'width': '168px',
            height: "42px",
            "font-family": "UniversNext for MORN",
            "font-style": "normal",
            "font-weight": "700",
            "font-size": "16px",
            "line-height": "21px",
            color: "#5E5E5E",
            "padding-left": "16px",
          },
          sortable: true,
        },
        {
          text: "Last Price $",
          fieldName: "LastPrice",
          style: {
            width: "67px",
            //'width': '53px',
            height: "42px",
            "font-family": "UniversNext for MORN",
            "font-style": "normal",
            "font-weight": "700",
            "font-size": "16px",
            "line-height": "21px",
            color: "#5E5E5E",
            "padding-left": "16px",
          },
          sortable: true,
          align: "right",
        },
        {
          text: "Daily Price Change %",
          fieldName: "PriceChangePct",
          style: {
            width: "95px",
            height: "42px",
            "font-family": "UniversNext for MORN",
            "font-style": "normal",
            "font-weight": "700",
            "font-size": "16px",
            "line-height": "21px",
            color: "#5E5E5E",
            "padding-left": "21px",
          },
          sortable: true,
          align: "right",
        },
        {
          text: "Fair Value $",
          fieldName: "FairValue",
          style: {
            width: "82px",
            height: "42px",
            "font-family": "UniversNext for MORN",
            "font-style": "normal",
            "font-weight": "700",
            "font-size": "16px",
            "line-height": "21px",
            color: "#5E5E5E",
            "padding-left": "29px",
          },
          sortable: true,
          align: "right",
        },
        {
          text: "Price/Fair Value",
          fieldName: "PFV",
          style: {
            width: "88px",
            height: "42px",
            "font-family": "UniversNext for MORN",
            "font-style": "normal",
            "font-weight": "700",
            "font-size": "16px",
            "line-height": "21px",
            color: "#5E5E5E",
            "padding-left": "21px",
          },
          sortable: true,
          align: "right",
        },
        {
          text: "Number of Shares",
          fieldName: "Quantity",
          style: {
            width: "86px",
            "font-family": "UniversNext for MORN",
            "font-style": "normal",
            "font-weight": "700",
            "font-size": "16px",
            "line-height": "21px",
            color: "#5E5E5E",
            "padding-left": "21px",
          },
          sortable: true,
          align: "right",
        },
        {
          text: "% of Portfolio",
          fieldName: "Weight",
          style: {
            width: "87px",
            height: "42px",
            "font-family": "UniversNext for MORN",
            "font-style": "normal",
            "font-weight": "700",
            "font-size": "16px",
            "line-height": "21px",
            color: "#5E5E5E",
            "padding-left": "21px",
          },
          sortable: true,
          align: "right",
        },
        {
          text: "Sector Name",
          fieldName: "SectorName",

          sortable: true,

          style: {
            width: "200px",
            "padding-left": "33px",
          },
        },
      ],
      rows: [],
      sortOrder: 1,
      sortColIndex: 1,
      isLoading: true,
      isLoadingReport: false,
      isDisplay: false,
      excelColumn: [{ add: "", delete: "" }],
      headersPublishRecords: [
        {
          text: "Title",
          fieldName: "Title",
        },
        {
          text: "Author",
          fieldName: "Author",
          style: {
            width: "100px",
            color: "red",
          },
        },
        {
          text: "Date",
          fieldName: "Date",
        },
      ],
      isResearchDisplay: false,
      tempdata: [],
      ResearchUrl: process.env.VUE_APP_BackEndAPI + process.env.VUE_APP_ResearchReportUrl,
      rowsResultResearch: [],
    };
  },
  methods: {
    formatDateFunction(Date) {
      let str = "";
      try {
        const strSplit = Date.split("T");
        if (strSplit) {
          if (strSplit[0]) {
            let dateSplit = strSplit[0].split("-");
            str =
              dateSplit[1] +
              "/" +
              dateSplit[2] +
              "/" +
              dateSplit[0].toString().substr(-2);
          }
        }
        return str;
      } catch (ex) {
        return str;
      }
    },

    handleSortEvent(colIndex, colName) {
      // if your tracked sort order is 0 (none), set it 1 (ascending)
      // if there is already an order being tracked inverse it
      this.sortOrder = this.sortOrder === 0 ? 1 : -this.sortOrder;

      // if the table was already sorted by a different column, then reset that column to 0 (none)
      if (this.sortColIndex !== null && this.sortColIndex !== colIndex) {
        this.$set(this.headers[this.sortColIndex], "sorted", 0);
        this.sortOrder = 1;
      }

      // track the current column as the one being sorted on
      this.sortColIndex = colIndex;
      this.$set(this.headers[colIndex], "sorted", this.sortOrder);

      // sort the row data by column clicked on
      this.rows.sort((a, b) => {
        if (colName == "StandardName") {
          const name1 = a.StandardName.toUpperCase();
          const name2 = b.StandardName.toUpperCase();

          if (name1 > name2) {
            return this.sortOrder;
          } else if (name1 < name2) {
            return -this.sortOrder;
          }
        } else if (colName == "RiskRating") {
          var lowValue = 5;
          var mediumValue = 10;
          var highValue = 15;
          var veryHighVal = 25;
          var extremeVal = 35;
          var currentRowValue = 0;
          var nextRowValue = 0;

          if (a[colName] == "N/A") currentRowValue = 0;
          if (a[colName] == "Low") currentRowValue = lowValue;
          if (a[colName] == "Medium") currentRowValue = mediumValue;
          if (a[colName] == "High") currentRowValue = highValue;
          if (a[colName] == "Very High") currentRowValue = veryHighVal;
          if (a[colName] == "Extreme") currentRowValue = extremeVal;

          if (b[colName] == "N/A") nextRowValue = 0;
          if (b[colName] == "Low") nextRowValue = lowValue;
          if (b[colName] == "Medium") nextRowValue = mediumValue;
          if (b[colName] == "High") nextRowValue = highValue;
          if (b[colName] == "Very High") nextRowValue = veryHighVal;
          if (b[colName] == "Extreme") nextRowValue = extremeVal;

          return currentRowValue >= nextRowValue ? this.sortOrder : -this.sortOrder;
        } else if (
          colName == "LastPrice" ||
          colName == "PriceChangePct" ||
          colName == "FairValue" ||
          colName == "PFV" ||
          colName == "Quantity" ||
          colName == "Weight"
        ) {
          return parseFloat(a[colName]) >= parseFloat(b[colName])
            ? this.sortOrder
            : -this.sortOrder;
        } else {
          return a[colName] >= b[colName] ? this.sortOrder : -this.sortOrder;
        }
      });
    },
    handleSortEvent2(colIndex, colName) {
      this.sortOrder = this.sortOrder === 0 ? 1 : -this.sortOrder;

      if (this.sortColIndex !== null && this.sortColIndex !== colIndex) {
        this.$set(this.headers[this.sortColIndex], "sorted", 0);
        this.sortOrder = 1;
      }

      this.sortColIndex = colIndex;
      this.$set(this.headers[colIndex], "sorted", this.sortOrder);

      var IncludeDash = this.rows.filter(function (el) {
        return el[colName] == "&mdash;";
      });

      var WithOutDash = this.rows.filter(function (el) {
        return el[colName] != "&mdash;";
      });
      WithOutDash.sort((a, b) => {
        if (
          colName == "StarRating" ||
          colName == "EconomicMoat" ||
          colName == "MoatTrend" ||
          colName == "FidGrade" ||
          colName == "RiskRating" ||
          colName == "LastPrice" ||
          colName == "PriceChangePct" ||
          colName == "FairValue" ||
          colName == "PFV" ||
          colName == "Quantity" ||
          colName == "Weight" ||
          colName == "SectorName"
        ) {
          return parseFloat(a[colName]) >= parseFloat(b[colName])
            ? this.sortOrder
            : -this.sortOrder;
        } else {
          return a[colName] >= b[colName] ? this.sortOrder : -this.sortOrder;
        }
      });
      let mergeArr = [];
      if (this.sortOrder == 1) {
        mergeArr = IncludeDash.concat(WithOutDash);
      } else {
        mergeArr = WithOutDash.concat(IncludeDash);
      }
      this.rows = mergeArr;
    },

   

    ExportExcel() {
      const today = moment().format("MMDDYYYY");

      // Replace &amp; with & in StandardName
      this.rows.forEach((row) => {
        row.StandardName = row.StandardName.replace("&amp;", "&");
      });

      // Format the data for export
      const formattedRows = this.rows.map((row) => ({
        Ticker: row.Symbol,
        Name: row.StandardName,
        "Morningstar Rating": row.StarRating,
        "Economic Moat": row.EconomicMoat,
        "Capital Allocation": row.FidGrade,
        "Uncertainty Rating": row.RiskRating,
        "Last Price $": parseFloat(row.LastPrice) || 0,
        "Daily Price Change %": parseFloat(row.PriceChangePct) || 0,
        "Fair Value $": parseFloat(row.FairValue) || 0,
        "Price/Fair Value": parseFloat(row.PFV) || 0,
        "Number of Shares": parseInt(row.Quantity, 10) || 0,
        "% of Portfolio": parseFloat(row.Weight) || 0,
        "Sector Name": row.SectorName,
      }));

      // Export to Excel
      exportToExcel(`MSI_Tortoise_${today}.xlsx`, "Tortoise Portfolio Data", formattedRows);

    }, 

    // Method to trigger download of the Excel file
    // downloadExcelFile(buffer) {
    //   const blob = new Blob([buffer], {
    //     type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    //   });
    //   const link = document.createElement("a");
    //   link.href = URL.createObjectURL(blob);
    //   link.download = `MSI_Tortoise_${moment().format("MMDDYYYY")}.xlsx`;
    //   link.click();
    // },

    GetResearchReport() {
      //tempSymbol = tempSymbol.replace(/,*$/, "");
      try {
        var url = this.ResearchUrl;
        url = url.replace("{Portfolio}", "Tortoise");
        fetchData(url)
          .then((response) => {
            if (response) {
              if (response.status == 200) {
                var customData = response.data;
                if (customData) {
                  for (var i = 0; i < customData.length; i++) {
                    if (customData[i].Title) {
                      var nameVal = customData[i].Title;
                      if (nameVal.includes("???")) {
                        customData[i].Title = nameVal.split("???").join("'");
                      }
                    }
                  }
                }
                this.rowsResultResearch = customData;
              }
            }
          })
          .catch(() => {
            this.errored = true;
          })
          .finally(
            () => ((this.isLoadingReport = false), (this.isResearchDisplay = true))
          );
      } catch (ex) {
        this.errored = true;
      }
    },
    openMedalistReport(secId, Symbol) {
      this.$session.set("CurrentSecID", this.Encrypt(secId));
      this.$session.set("CurrentPortfolioName", this.Encrypt("tortoisePort"));
      if (this.$store.state.responsive.isMobileContent) {
        // this.$router
        // .push({ name: "analyst", params: { currentproduct: "msi",ticker:Symbol}})
        // .catch((err) => {
        //   if (
        //     err.name !== "NavigationDuplicated" &&
        //     !err.message.includes(
        //       "Avoided redundant navigation to current location"
        //     )
        //   ) {
        //     console.log(err);
        //   }
        // });
        let resolvedRoute = this.$router.resolve({
          name: "analyst",
          params: { currentproduct: "msi", ticker: Symbol },
        });
        this.CurrentURL = resolvedRoute.href;
      } else {
        let resolvedRoute = this.$router.resolve({
          name: "analyst",
          params: { currentproduct: "msi", ticker: Symbol.toLowerCase() },
        });
        //window.open(resolvedRoute.href, '_blank');
        this.CurrentURL = resolvedRoute.href;
      }
    },
    round(value, precision) {
      var multiplier = Math.pow(10, precision || 0);
      return Math.round(value * multiplier) / multiplier;
    },
    redirectToStockQuote(exchangeId, ticker) {
      var quotePageURL =
        "https://www.morningstar.com/stocks/" +
        exchangeId.toLowerCase() +
        "/" +
        ticker.toLowerCase() +
        "/quote";
      window.open(quotePageURL, "_blank");
    },
  },
  created() {
    window.scrollTo(0, 0);
    fetchData(this.TortoiseURL)
      .then((response) => {
        var customData = response.data;
        let tempSymbol = "";
        var tempdata = [];
        let totalWeightValue = 0;
        if (customData) {
          for (var i = 0; i < customData.length; i++) {
            tempSymbol += customData[i].SecId + ",";
            var obj = customData[i];
            if (customData[i].StandardName != "Default Cash") {
              // if (customData[i].Risk) {
              //   customData[i].Risk = this.calculateUncertaintyRating(parseInt(obj.Risk));
              // }
              if (customData[i].FairValue) {
                //typeof(parseInt(obj.FairValue) === NaN ? obj.FairValue : parseInt(obj.FairValue))
                if (isNaN(customData[i].FairValue)) customData[i].FairValue = 0.0;
                else {
                  let FairValue = obj.FairValue + ".00";
                  customData[i].FairValue = parseFloat(FairValue).toFixed(2);
                }
              }
              if (customData[i].LastPrice) {
                if (obj.LastPrice != "&mdash;") {
                  let LastPrice = obj.LastPrice + ".00";
                  customData[i].LastPrice = parseFloat(LastPrice).toFixed(2);
                }
              }
              if (customData[i].PFV) {
                if (obj.PFV != "&mdash;") {
                  let PriceOverFairValue = obj.PFV + ".00";
                  customData[i].PFV = parseFloat(PriceOverFairValue).toFixed(2);
                }
              }
              if (customData[i].Quantity) {
                if (obj.Quantity != "&mdash;") {
                  let Quantity = obj.Quantity;
                  customData[i].Quantity = parseFloat(Quantity).toFixed(0);
                }
              }
              if (customData[i].Weight) {
                if (obj.Weight != "&mdash;") {
                  //let Weight = obj.Weight + ".0";
                  let Weight = this.round(obj.Weight, 1);
                  customData[i].Weight = parseFloat(Weight).toFixed(1);
                  totalWeightValue += parseFloat(customData[i].Weight);
                }
              }
              if (customData[i].StandardName) {
                var nameVal = customData[i].StandardName;
                if (nameVal.includes("&amp;")) {
                  customData[i].StandardName = nameVal.split("&amp;").join("&");
                  //customData[i].StandardName = nameVal.Replace("&amp;","")
                }
              }
              tempdata.push(customData[i]);
            } else {
              customData[i].StandardName = "Cash";
              let CashWeight = this.round(customData[i].Weight, 1);

              this.cashArray.push({
                StandardName: customData[i].StandardName,
                Symbol: customData[i].Symbol,
                StarRating: "-",
                EconomicMoat: "-",
                FidGrade: "-",
                RiskRating: "-",
                LastPrice: parseFloat(customData[i].LastPrice),
                PriceChangePct: "-",
                FairValue: "-",
                PFV: "-",
                Quantity: "-",
                Weight: parseFloat(CashWeight).toFixed(1),
                SectorName: "-",
              });
            }
          }
        }
        this.rows = tempdata;
        let calcWeight =
          parseFloat(totalWeightValue) + parseFloat(this.cashArray[0].Weight);
        this.totalWeight = Math.round(calcWeight);
        if (tempSymbol != null) {
          this.GetResearchReport();
        }
      })
      .catch((error) => {
        console.log(error);
        this.errored = true;
      })
      .finally(
        () => (
          (this.isLoading = false), (this.isLoadingReport = true), (this.isDisplay = true)
        )
      );
  },
  mounted() {
    this.isDataTableResponsiveToggle = this.$store.state.responsive.isDataTableResponsive;
  },
  computed: mapState({
    isDataTableResponsive: (state) => state.responsive.isDataTableResponsive,
    isDataTableResponsiveAlias: "isDataTableResponsive",
    isDataTableResponsiveState(state) {
      return state.responsive.isDataTableResponsive;
    },
    isMobileContent: (state) => state.responsive.isMobileContent,
    isMobileContentAlias: "isMobileContent",
    isMobileContentState(state) {
      return state.responsive.isMobileContent;
    },
  }),
  watch: {
    isDataTableResponsiveState() {
      this.isDataTableResponsiveToggle = this.isDataTableResponsiveState;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@mds/constants";

.msi-tortoise-portfolio-analyst-text {
  font-size: 14px;
  line-height: 18px;
  font-weight: 400;
  color: #5e5e5e;
  margin-top: 24px;
}
.msi-tortoise-portfolio-analyst-text-sec {
  font-size: 14px;
  line-height: 18px;
  font-weight: 400;
  color: #5e5e5e;
  margin-top: 6px;
}
.msi-tortoise-portfolio-data-table {
  box-shadow: none;
}

.mds-td {
  line-height: 25px;
}

.msi-tortoise-portfolio-table-loader {
  position: absolute;
  width: "276px";
  height: "69px";
  left: 42.92%;
  right: 37.92%;
  top: 500px;
}

.msi-tortoise-portfolio-dividend-loader {
  position: absolute;
  width: "276px";
  height: "69px";
  left: 42.92%;
  right: 37.92%;
  top: 2000px;
}

.msi-tortoise-portfolio-data-text {
  width: 255px;
  height: 18px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  display: flex;
  align-items: flex-end;
  color: #5e5e5e;
  padding-top: 24px;
}

.msi-tortoise-portfolio-first-border {
  border: 1px solid #cccccc;
  width: 100.8%;
  margin-top: 20px;
}

$mds-data-table-border-fixed-col: 2px solid $mds-color-neutral-90 !default;
body {
  padding: 1em;
}

.mds-tr.msi-tortoise-portfolio-cash-row {
  td {
    border-top: 1px solid #808080;
    border-bottom: 1px solid #808080;
    border-collapse: collapse;
  }
}

.msi-tortoise-portfolio-data-table.mds-table__scroller .mds-table--fixed-column {
  th {
    position: sticky;
    top: 0;
  }
  th:nth-child(1) {
    z-index: $mds-z-index-low + 1;
  }

  th:nth-child(1) {
    padding-left: 2px;
  }

  td {
    padding: 7px 2px;
  }

  td:nth-child(1) {
    padding: 5px 2px;
  }
}

.msi-tortoise-portfolio-title {
  font-weight: 700;
  font-size: 32px;
  color: #1e1e1e;
  line-height: 36px;
}

.msi-tortoise-portfolio-middle-content {
  display: flex;
  width: 128%;
}

.msi-tortoise-portfolio-download-holding {
  margin-top: 30px;
  cursor: pointer;
  order: 2;
  margin-left: auto;
  float: right;
}

.msi-tortoise-portfolio-download-holding:hover {
  cursor: pointer;
}

.msi-tortoise-portfolio-header-text {
  width: 78%;
  padding-top: 48px;
  margin-left: 2px;
}

.msi-tortoise-portfolio-intraday-text {
  width: 90vw;
  text-align: justify;
  margin-top: 28px;
  left: 122px;
  margin-bottom: 10px;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #5e5e5e;
  padding-bottom: 40px;
}

.msi-tortoise-portfolio-middle-text {
  color: #5e5e5e;
  font-weight: 400;
  font-size: 20px;
  padding-top: 24px;
  line-height: 26px;
  width: 55vw;
}

.msi-tortoise-portfolio-table-first-row {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  color: #1e1e1e;
  line-height: 25px;
}

.msi-tortoise-portfolio-table-first-row:hover {
  cursor: pointer;
  color: #006fba;
}

.msi-tortoise-portfolio-research-table {
  overflow: scroll;
}

.msi-tortoise-portfolio-research-table tr td {
  color: #1e1e1e;
  font-weight: 300;
  font-size: 16px;
  font-style: normal;
  line-height: 21px;
}

.msi-tortoise-portfolio-table-header {
  color: #5e5e5e;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 0px;
}

body {
  font-family: "Univers", HelveticaNeue, "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.msi-tortoise-portfolio-container {
  width: 1280px;
  margin: 0 auto;
}

.msi-tortoise-portfolio-second-border {
  border: 1px solid #cccccc;
  width: 100.8%;
  margin-top: 0px;
  margin-bottom: 0px;
}

.msi-tortoise-portfolio-balance-text {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #1e1e1e;
  margin-bottom: 0px;
  margin-top: 20px;
}

.msi-tortoise-portfolio-research-title {
  font-weight: 700;
  font-size: 28px;
  color: #1e1e1e;
  padding-top: 10px;
}

.mds-table--show-sortable .mds-th--sortable .mds-th__inner {
  border-bottom: none;
}

.mds-th--sortable.mds-th--sorted-descending .mds-th__inner,
.mds-th--sortable.mds-th--sorted-ascending .mds-th__inner {
  border-bottom: 1px solid rgb(30, 30, 30);
}

.msi-tortoise-portfolio-cash-first-column {
  width: 3.5lvw;
  font-style: normal;
  font-weight: 700 !important;
  font-size: 16px;
  color: #1e1e1e;
  line-height: 21px;
}

::-webkit-scrollbar {
  height: 7px;
  width: 7px;
}

::-webkit-scrollbar-thumb {
  background: rgba(171, 171, 171, 0.3);
  border-radius: 6px;
}

.msi-tortoise-portfolio-table-ticker {
  cursor: pointer;
}
.msi-tortoise-portfolio-table-ticker:hover {
  color: #006fba;
}

@media only screen and (min-width: 1001px) and (max-width: 1200px) {
  .msi-tortoise-portfolio-header-text {
    padding-top: 40px;
  }
}

@media only screen and (min-width: 320px) and (max-width: 1415px) {
  .msi-tortoise-portfolio-container {
    width: 90%;
    margin-left: 5%;
    margin-right: 5%;
  }

  .msi-tortoise-portfolio-balance-text {
    width: 90vw;
  }
}

@media only screen and (min-width: 320px) and (max-width: 600px) {
  .msi-tortoise-portfolio-analyst-text {
    font-size: 14px;
    line-height: 18px;
    font-weight: 400;
    color: #5e5e5e;
    margin-top: 54px;
    width: 90vw;
  }
  .msi-tortoise-portfolio-analyst-text-sec {
    font-size: 14px;
    line-height: 18px;
    font-weight: 400;
    color: #5e5e5e;
    width: 90vw;
  }
  .msi-tortoise-portfolio-middle-content {
    display: block;
  }

  .msi-tortoise-portfolio-download-holding {
    margin-top: 16px;
  }

  .msi-tortoise-portfolio-title {
    font-weight: 700;
    font-size: 28px;
    color: #1e1e1e;
    line-height: 34px;
    width: 90vw;
  }

  .msi-tortoise-portfolio-middle-text {
    color: #5e5e5e;
    font-weight: 300;
    font-size: 16px;
    padding-top: 12px;
    line-height: 21px;
    width: 90vw;
  }

  .msi-tortoise-portfolio-research-title {
    font-weight: 700;
    font-size: 20px;
    color: #1e1e1e;
    padding-top: 10px;
    line-height: 23px;
    width: 65vw;
  }
}

@media only screen and (min-width: 1537px) and (max-width: 3840px) {
  .msi-tortoise-portfolio-middle-text {
    width: 850px;
  }
}

.msi-tortoise-portfolio-download-data {
  font-size: 14px;
  color: #5e5e5e;
}
.msi-tortoise-portfolio-data-table
  > table
  > tbody
  > tr
  > td:nth-child(2)
  > div
  > a:focus {
  box-shadow: none;
}
.msi-tortoise-portfolio-data-table
  > table
  > tbody
  > tr
  > td:nth-child(2)
  > div
  > a:hover {
  cursor: pointer;
}
.msi-tortoise-portfolio-research-table > table > tbody > tr > td:nth-child(1) > a:focus {
  box-shadow: none;
  color: #006fba;
}
.msi-tortoise-portfolio-research-table > table > tbody > tr > td:nth-child(1) > a:hover {
  cursor: pointer;
  color: #006fba;
}

.msi-tortoise-portfolio-research-table > table > tbody > tr > td:nth-child(1) > a {
  color: #1e1e1e;
}
</style>