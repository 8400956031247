<template>
  <div>
    <mds-page-shell :logo="getProductLogo()" :logo-responsive="getProductLogo()" logo-href="/"
      logo-alt="Back to home page">
      <div class="print-register-loader">
        <mds-loader v-if="isLoading" aria-label="Large Loader" size="large"></mds-loader>
      </div>
      <div class="print-registration-main">
        <div class="print-registration-main-container">
          <div class="print-registration-form">
            <div class="print-registration-form-container">
              <div class="print-registration-title-form">
                <h1 class="print-registration-page-title">Digital registration for print subscribers</h1>
              </div>
              <div class="print-registration-top-part-text">
                <p class="print-registration-form-text-first">
                  Complete the form below to register your subscription for the companion website,
                  newsletters.morningstar.com.
                  <span v-if="!this.$store.state.user.LoggedIn">
                    If you already have digital access to another Morningstar product,
                    <a class="registration-hyperlink-test" target="_blank" @click="login()">sign in</a>
                    here for faster registration.
                  </span>
                </p>
              </div>
              <div class="print-registration-form-fields">
                <mds-input class="input-tab" size="large" label="First Name" type="text" required :error="firstNameError"
                  v-model.trim="firstname"></mds-input>
                <span class="FormsError" v-if="firstNameError">First name is required</span>
                <br />
                <mds-input v-model.trim="lastname" type="text" size="large" :error="lastNameError" label="Last Name"
                  required></mds-input>
                <span class="FormsError" v-if="lastNameError">Last name is required</span>
                <br />
                <mds-input v-model.trim="email" :error="emailError" size="large" label="Email" type="email" required
                  :disabled="existingUser"></mds-input>
                <span class="FormsError" v-if="emailError">{{
                  emailErrorMessage
                }}</span>
                <br />
                <mds-input v-model.trim="password" :error="passwordError" password size="large" label="Password" required
                  v-if="!disabled"></mds-input>
                <span class="FormsError" v-if="passwordError">Password is required</span>
                <div class="print-registration-password-div" v-if="!disabled">
                  <p v-bind:class="[
                    this.validatePasswordError
                      ? 'print-registration-password-text-error'
                      : 'print-registration-password-text',
                    'print-registration-password-text',
                  ]">
                    Your password must be 8 or more characters, with one upper or
                    lower case letter, one special character (e.g., ?$%!@#) AND one
                    number (1-9). It cannot contain more than 3 sequential or
                    repeating characters, username, mstar, or Morningstar.
                  </p>
                </div>

                <mds-input password v-model.trim="verifypassword" size="large" label="Verify Password"
                  :error="verifypasswordError" v-if="!disabled" required></mds-input>
                <span class="FormsError" v-if="verifypasswordError">Password verification does not match the password
                  entered</span>
                <br />
                <div class="print-horizonatal-fieldset">
                  <div class="print-fieldset-first">
                    <mds-select label="Phone Type" size="large" required v-model.trim="phonetype" @change="changePhoneType()">
                      <option v-for="phonetype in listphoneType" v-bind:key="phonetype.text" :value="phonetype.text">
                        {{ phonetype.text }}
                      </option>
                    </mds-select>
                  </div>
                  <div class="print-fieldset-second">
                    <mds-input label="Phone Number" size="large" type="text" required v-model.trim="phonenumber"
                      :error="phonenumberError"></mds-input>
                    <span class="FormsError" v-if="phonenumberError">{{
                      phoneErrorMessage
                    }}</span>
                  </div>
                </div>

                <div class="print-registration-checkboxes">
                  <div v-if="isMobileChecked">
                  <mds-checkbox size="small" value="accept" v-model.trim="checked">By checking the box and submitting
                    this form, I
                    consent to receive phone calls for special offers reserved for Morningstar customers at any number I
                    provide
                    here using automated technology. I understand my consent is not required in order to receive products
                    or
                    services from Morningstar.
                  </mds-checkbox>
                  <div class="print-registration-popover">
                    <a type="button" @click="toggle = !toggle" id="default-trigger" class="popover-print-registration">Why
                      do we need
                      it?</a>
                  </div>
                  <mds-popover size="small" class="printregistration-popover" triggered-by="default-trigger"
                    v-model="toggle" :position="['top-right']">
                    We use your phone number for Morningstar-related matters. Morningstar
                    does not share this contact information without your separate consent.
                    See our Privacy Policy for complete details.
                  </mds-popover>
                  <br />
                 </div>
                  <mds-checkbox size="small" value="accept" v-model.trim="emailoptions">Get emails about new Morningstar
                    products and
                    features.
                  </mds-checkbox>
                </div>
                <div class="print-registration-button-container">
                  <div>
                    <mds-button-container left-aligned>
                      <!-- Icon On Left -->
                      <mds-button variation="secondary" size="large" onclick="history.back()">
                        Back
                      </mds-button>
                      <div class="print-registration-cancel-button">
                        <mds-button variation="secondary" size="large" type="button" @click="
                          $router.push({
                            name: 'combined-home',
                            params: { currentproduct: 'Common' },
                          })
                          ">
                          Cancel
                        </mds-button>
                      </div>
                      <div class="print-registration-continue-button">
                        <!-- Icon On Both Sides -->
                        <mds-button variation="primary" size="large" class="continue-button" type="button" right-aligned
                          @click="ContinueForm">
                          Continue
                        </mds-button>
                      </div>
                    </mds-button-container>
                  </div>
                </div>
              </div>
            </div>
            <div class="register-page-bottom"></div>
          </div>
        </div>
      </div>
    </mds-page-shell>
  </div>
</template>

<script>
import MdsPageShell from '@mds/page-shell';
import MdsInput from "@mds/input";
import MdsCheckbox from "@mds/checkbox";
import { MdsButton, MdsButtonContainer } from "@mds/button";
import MdsLoader from "@mds/loader";
import MdsSelect from "@mds/select";
import MdsPopover from "@mds/popover";
import { fetchData } from '@/utilities/API/apiUtility';
export default {
  name: "registration-section",
  components: {
    MdsInput,
    MdsCheckbox,
    MdsButton,
    MdsButtonContainer,
    MdsLoader,
    MdsSelect,
    MdsPageShell,
    MdsPopover,
  },
  data() {
    return {
      currentProduct: this.$route.params.currentproduct,
      toggle: false,
      userDetails: [],
      userRoles: [],
      userEmail: null,
      OmahaHostURL: process.env.VUE_APP_OmahaHostURL,

      userID: null,
      firstname: null,
      lastname: null,
      email: null,
      password: null,
      verifypassword: null,
      disabled: false,
      checked: false,
      emailoptions: false,
      firstNameError: false,
      lastNameError: false,
      emailError: false,
      emailErrorMessage: "",
      passwordError: false,
      verifypasswordError: false,
      validatePasswordError: false,
      existingUser: false,
      isLoading: true,
      phonetype: "Mobile Phone",
      listphoneType: this.$store.state.country.phoneType,
      phonenumberError: false,
      phonenumber: "",
      isMobileChecked:true,
      DPhone: "",
      MPhone: "",
    };
  },
  methods: {
    async getEmailNotificationList(customerId) {
      var GetEmailNotificationUrl = process.env.VUE_APP_BackEndAPI + process.env.VUE_APP_EmailNotification;
      GetEmailNotificationUrl = GetEmailNotificationUrl.replace('{customerId}', customerId);
      try {
        return fetchData(GetEmailNotificationUrl)
          .then((response) => {
            if (response) {
              if (response.status == 200) {
                if (response.data) {
                  var responseData = response.data;
                  if (responseData) {
                    for (var i in responseData) {
                      var alertOptions = responseData[i];
                      if (alertOptions) {
                        if (alertOptions.OptionID == 19 && alertOptions.OptionValue == 1) {
                          this.emailoptions = true;
                        }
                        if (alertOptions.OptionID == 491 && alertOptions.OptionValue == 1) {
                          this.checked = true;
                        }
                      }
                    }
                  }
                }

              }
            }
          })
          .catch((error) => {
            console.log(error);
          })
          .finally();
      } catch (ex) {
        console.log(ex.message);
      }
    },
    getProductLogo() {
      var images = require.context(
        "../../assets/Images",
        false,
        /\.svg$/
      );
      return images("./Common.svg");
    },
    async getUserDetails(userID) {
      var getUserDetailsAPIURL =
        process.env.VUE_APP_BackEndAPI + process.env.VUE_APP_GetUserDetails;
      getUserDetailsAPIURL = getUserDetailsAPIURL.replace("{userId}", userID);
      try {
        return fetchData(getUserDetailsAPIURL, {
            headers: {
              Accept: "application/json",
            },
          })
          .then((response) => {
            if (response) {
              if (response.status == 200) {
                if (response.data) {
                  this.userDetails = response.data;
                  this.saveDataTolocalStorage(this.userDetails);
                  if (this.userDetails["Mphone"] != "" && this.userDetails["Mphone"] != null ) {
                      this.phonetype = "Mobile Phone";
                      this.phonenumber = this.userDetails["Mphone"];
                      this.MPhone = this.userDetails["Mphone"];
                    }
                    else {
                      this.phonetype = "Home Phone";
                      this.phonenumber = this.userDetails["Dphone"];
                    }
                  if (this.userDetails["Dphone"] != "" && this.userDetails["Dphone"] != null ) {
                    this.DPhone = this.userDetails["Dphone"];
                  }
                  this.firstname = this.userDetails["FirstName"];
                  this.lastname = this.userDetails["LastName"];
                }
              }
            }
          })
          .catch((error) => {
            this.$store.state.registration.UserRegistrationDetails = null;
            console.log(error);
          })
          .finally();
      } catch (ex) {
        console.log(ex.message);
      }
    },
    async checkuseremailExists(userEmail) {
      var getUserRoleAPIURL =
        process.env.VUE_APP_BackEndAPI + process.env.VUE_APP_GetUserEmail;
      getUserRoleAPIURL = getUserRoleAPIURL.replace("{UserEmail}", userEmail);
      try {
        return fetchData(getUserRoleAPIURL, {
            headers: {
              Accept: "application/json",
            },
          })
          .then((response) => {
            if (response) {
              if (response.status == 200) {
                if (response.data) {
                  this.userRoles = response.data;
                  this.userID = this.userRoles["UserID"];
                  if (this.userID != null) {
                    return true;
                  } else {
                    return false;
                  }
                }
              }
            }
          })
          .catch((error) => {
            console.log(error);
          });
      } catch (ex) {
        console.log(ex.message);
      }
    },
    validatePassword(password) {
      if (password.length >= 8 && password.length <= 24) {
        if (/\d+/.test(password) && /[A-Za-z]/.test(password)) {
          if (/[^\da-zA-Z]/.test(password)) {
            if (/(.)\1{3}/.test(password)) {
              return false;
            }
            else if (/(?:abcd|bcde|cdef|defg|efgh|fghi|ghij|hijk|ijkl|jklm|klmn|lmno|mnop|nopq|opqr|pqrs|qrst|rstu|stuv|tuvw|uvwx|vwxy|wxyz)/.test(password)) {
              return false;
            }
            else if (/^(?=.*?mstar)/.test(password.toLowerCase())) {
              return false;
            }
            else if (/^(?=.*?morningstar)/.test(password.toLowerCase())) {
              return false;
            }
            else if (this.firstname != null && this.firstname != "") {
              if (password.toLowerCase().includes(this.firstname.toLowerCase())) {
                return false;
              }
              else {
                return true;
              }
            }
            else {
              return true;
            }
          } else {
            return false;
          }
        }
        else {
          return false;
        }
      }
      else {
        return false;
      }
    },
    async ContinueForm() {
      this.isLoading = true;
      if (
        this.firstname == null ||
        this.firstname == "" ||
        this.firstname.length > 60
      ) {
        this.firstNameError = true;
      }
      if (
        this.lastname == null ||
        this.lastname == "" ||
        this.lastname.length > 60
      ) {
        this.lastNameError = true;
      }
      if (
        this.email == null ||
        this.email == ""
      ) {
        this.emailError = true;
        this.emailErrorMessage = "Email is required";
      }
      else if (!/^[^@]+@\w+(\.\w+)+\w$/.test(this.email)) {
        this.emailError = true;
        this.emailErrorMessage = "Email is not in correct format";
      }
      if (!this.checked) {
        this.agreetermError = true;
      }
      if (this.phonenumber == null || this.phonenumber == "") {
        this.phonenumberError = true;
        this.phoneErrorMessage = "Phone is required";
      } else if (
        !/^\+?(\d[\d-. ]+)?(\([\d-. ]+\))?[\d-. ]+\d$/.test(this.phonenumber) ||
        this.phonenumber.length < 10
      ) {
        this.phonenumberError = true;
        this.phoneErrorMessage = " Phone number is not valid";
      }
      if (!this.existingUser) {
        if (this.email != null) {
          if (await this.checkuseremailExists(this.email)) {
            this.emailError = true;
            this.emailErrorMessage =
              "This email is already in use. Please either sign in or choose a different email to continue with your registration.";
          }
        }
        if (this.password == null || this.password == "") {
          this.passwordError = true;
        }
        if (this.password != null || this.password == "") {
          if (await !this.validatePassword(this.password)) {
            this.validatePasswordError = true;
          }
          else {
            this.passwordError = false;
            this.validatePasswordError = false;
          }
        }
        if (
          this.verifypassword == null ||
          this.verifypassword == "" ||
          this.password != this.verifypassword
        ) {
          this.verifypasswordError = true;
        }
        else {
          this.verifypasswordError = false;
        }

      }
      if (
        !this.firstNameError &&
        !this.lastNameError &&
        !this.emailError &&
        !this.passwordError &&
        !this.validatePasswordError &&
        !this.verifypasswordError &&
        !this.phonenumberError
      ) {
        if (this.phonetype.toLowerCase() == "mobile phone") {
          this.MPhone = this.phonenumber;
          this.isMobileChecked = true;
        } else {
          this.DPhone = this.phonenumber;
          this.isMobileChecked = false;
        }
        if (this.$store.state.user.LoggedIn) {
          if (this.userDetails != null) {
            const data = {
              FirstName: this.firstname,
              LastName: this.lastname,
              Email: this.email,
              Password: "",
              UserID : this.userID,
              EmailAlert: this.emailoptions,
              MobileAlert: this.checked,
              Country: this.userDetails["Country"],
              Address_1: this.userDetails["Address_1"],
              Address_2: this.userDetails["Address_2"],
              City: this.userDetails["City"],
              State: this.userDetails["State"],
              Zip: this.userDetails["ZipCode"],
              MPhone: this.MPhone,
              DPhone: this.DPhone,
              OmahaCustID:this.userDetails["CustomerID"],
            };
            this.saveDataTolocalStorage(data);
          }
        }
        else {
          const data = {
            FirstName: this.firstname,
            LastName: this.lastname,
            Email: this.email,
            Password: this.password,
            UserID : "",
            EmailAlert: this.emailoptions,
            MobileAlert: this.checked,
            Country: "",
            Address_1: "",
            Address_2: "",
            City: "",
            State: "",
            Zip: "",
            MPhone: this.MPhone,
            DPhone: this.DPhone,
            OmahaCustID:"",
          };
          this.saveDataTolocalStorage(data);

        }
        this.isLoading = false;
        this.$router.push({
          name: "print-registration-subscribe",
          params: { currentproduct: this.currentProduct, },
        }).catch(err => {
          if (
            err.name !== 'NavigationDuplicated' &&
            !err.message.includes('Avoided redundant navigation to current location')
          ) {
            console.log(err);
          }
        });
      } else {
        this.isLoading = false;
      }
    },
    login() {
      this.$auth0.loginWithRedirect();
    },
    saveDataTolocalStorage(data) {
      localStorage.setItem("userDetails", JSON.stringify(data));
    },
    async getDataFromLocalStorage() {
      if (this.$store.state.user.LoggedIn) {
        await this.getUserDetails(this.userID);
        if (this.userDetails["CustomerID"] != null && this.userDetails["CustomerID"] != "") {
          await this.getEmailNotificationList(this.userDetails["CustomerID"])
        }
      }
      else {
        var data = localStorage.getItem("userDetails");
        if (data != "" && data != null) {
          data = JSON.parse(data);
          if (data.FirstName != null) {
            this.firstname = data.FirstName;
            this.lastname = data.LastName;
            this.email = data.Email;
            if(data.MPhone!=null && data.MPhone != "") {
              this.phonenumber = data.MPhone;
              this.phonetype = "Mobile Phone";
              this.isMobileChecked = true;
              this.MPhone = data.MPhone;
            }
            else{
              this.phonenumber = data.DPhone;
              this.phonetype = "Home Phone";
              this.isMobileChecked = false;
            }
            if(data.DPhone!=null && data.DPhone != "") {
              this.DPhone = data.DPhone;
            }
            this.emailoptions = data.EmailAlert;
            this.checked = data.MobileAlert;
          }
        }
      }
    },
    changePhoneType() {
            this.phonenumber = "";
            this.userDetails = localStorage.getItem("userDetails");
            if (this.userDetails != "" && this.userDetails != null) {
            this.userDetails = JSON.parse(this.userDetails);
            if (this.userDetails.Mphone != "" && this.userDetails.Mphone != null) {
            if (this.phonetype.toLowerCase() == "mobile phone") {
                if (this.userDetails.Mphone) {
                    this.phonenumber = this.userDetails.Mphone;
                }
            } 
          }
           if (this.userDetails.Dphone != "" && this.userDetails.Dphone != null) {
            if (this.phonetype.toLowerCase() != "mobile phone") {
                if (this.userDetails.Dphone) {
                    this.phonenumber = this.userDetails.Dphone;
                }
            } 
          }
        }
      },
  },

  async mounted() {
    this.LoggedIn = this.$auth0.isAuthenticated && this.$session.exists();
    this.$store.state.user.LoggedIn = this.LoggedIn;
    window.scrollTo(0, 0);
    this.$store.state.layout.isHeaderRequired = false;
    this.$store.state.layout.isFooterRequired = false;
    if (this.$store.state.user.LoggedIn) {
      this.userEmail = this.$auth0.user["email"];
      this.email = this.userEmail;
      this.userID = this.$auth0.user["https://morningstar.com/mstar_id"];
      this.existingUser = true;
      this.disabled = true;
    }
    await this.getDataFromLocalStorage();
    this.isLoading = false;
  },
  watch: {
    firstname(newVal, oldVal) {
      if(this.password !=null && this.password != " ") {
        this.passwordError=false;
        this.validatePasswordError=false;
      }
      if (newVal != oldVal) {
        if (newVal == "" || newVal.length > 60 || newVal == null) {
          this.firstNameError = true;
        } else {
          this.firstNameError = false;
        }
      }
    },
    lastname(newVal, oldVal) {
      if (newVal != oldVal) {
        if (newVal == "" || newVal.length > 60 || newVal == null) {
          this.lastNameError = true;
        } else {
          this.lastNameError = false;
        }
      }
    },
    email(newVal, oldVal) {
      if (newVal != oldVal) {
        if (
          newVal == "" ||
          newVal.length > 60 ||
          newVal == null ||
          !/^[^@]+@\w+(\.\w+)+\w$/.test(newVal)
        ) {
          this.emailError = true;
          this.emailErrorMessage = "Email is not in correct format";
        } else {
          this.emailError = false;
          this.isemailValidate = true;
        }
      }
    },
    password(newVal, oldVal) {
      this.verifypasswordError = false;
      if (newVal != oldVal) {
        if (newVal == null || newVal == "") {
          this.passwordError = true;
          this.validatePasswordError = false;
        } else if (!this.validatePassword(newVal)) {
          this.passwordError = false;
          this.validatePasswordError = true;
        } else {
          this.validatePasswordError = false;
          this.ispasswordValidate = true;
        }
      }
    },
    verifypassword(newVal, oldVal) {
      if (newVal != oldVal) {
        if (newVal == null || newVal == "" || this.password != newVal) {
          this.verifypasswordError = true;
        } else {
          this.verifypasswordError = false;
          this.isverifypasswordValidate = true;
        }
      }
    },
    phonenumber(newVal, oldVal) {
      if (newVal != oldVal) {
        if(newVal != null && newVal != "") {
       if (
          !/^\+?(\d[\d-. ]+)?(\([\d-. ]+\))?[\d-. ]+\d$/.test(
            this.phonenumber
          ) ||
          this.phonenumber.length < 10
        ) {
          this.phonenumberError = true;
          this.phoneErrorMessage = " Phone number is not valid";
        } else {
          this.phonenumberError = false;
        }
      }
    }
      if (this.phonenumber != null && this.phonenumber != "") {
       if (
        !/^\+?(\d[\d-. ]+)?(\([\d-. ]+\))?[\d-. ]+\d$/.test(this.phonenumber) ||
        this.phonenumber.length < 10
      ) {
        this.phoneErrorMessage = " Phone number is not valid";
      }
    }
    },

    checked(newVal, oldVal) {
      if (newVal != oldVal) {
        if (!newVal) {
          this.agreetermError = true;
        } else {
          this.agreetermError = false;
        }
      }
    },
 phonetype(newVal, oldVal) {
  if(newVal != oldVal) {
    if(newVal.toLowerCase() == "mobile phone")
    {
      this.isMobileChecked = true;
    }
    else{
      this.isMobileChecked = false;
    }
  }
 }
  },
};
</script>

<style scoped>
.print-registration-password-div {
  width: 100%;
  height: 88px;
  margin-left: 0px;
}

.print-registration-continue-button {
  margin-left: auto;
  order: 2;
}

.print-registration-cancel-button {
  margin-left: auto;
}

.print-registration-button-container {
  margin-top: 8%;
  width: 100%;
}

.print-fieldset-first {
  width: 36.5%;
  flex-basis: 48%;
}

.print-fieldset-second {
  width: 37%;
  margin-left: auto;
  flex-basis: 48%;
}

.print-horizonatal-fieldset {
  display: flex;
}

.print-registration-top-part-text {
  width: 100%;
  font-weight: 300;
  font-size: 16px;
  line-height: 21px;
  color: #000000;
}

.print-registration-form {
  max-width: 480px;
  margin: 0 auto;
  background: #fff;
  height: 100%;
  min-height: 100vh;
}

.print-registration-form-container {
  width: 380px;
  margin: 0 auto;
}

.print-registration-main-container {
  max-width: 1280px;
  padding: auto;
  margin: auto;
}
.print-registration-password-text {
  font-weight: 400;
  width: 100%;
  font-size: 14px;
  line-height: 18px;
  color: #000000;
  margin-top: 10px;
  margin-bottom: 0px;
}

.print-registration-password-text-error {
  width: 100%;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #000000;
  margin-top: 10px;
  margin-bottom: 0px;
  color: red;
}
.register-page-bottom {
  opacity: 1;
  z-index: 2;
  transform: rotate(180deg);
  height: 50px;
}

.print-register-loader {
  position: absolute;
  width: "276px";
  height: "69px";
  left: 38.92%;
  right: 37.92%;
  top: 60%;
  z-index: 1;
}

.print-registration-main {
  height: 100%;
  min-height: 100vh;
  background: #f2f2f2;
}
.print-registration-page-title {
  margin-top: 0px;
  margin-bottom: 0px;
  height: 44px;
  padding-top: 57px;
  font-weight: 250;
  font-size: 28px;
  line-height: 34px;
}

.print-registration-form-text-first {
  margin-top: 15%;
}

.registration-hyperlink-test {
  cursor: pointer;
  text-decoration: none;
  border-bottom: 1px solid #000000;
  color: #000000;
}
.print-registration-form-fields {
  width: 100%;
  margin-top: 5% !important;
  font-size: 16px;
  line-height: 21px;
  color: #1e1e1e;
}
.registration-password-div {
  width: 100%;
  height: 88px;
  margin-left: 0px;
}

.print-registration-checkboxes {
  margin-top: 26px;
}
.continue-button {
  margin-left: 10%;
}

.FormsError {
  font-size: 16px;
  font-weight: 300;
  line-height: 21px;
  color: #ca0000;
  display: block;
  margin-top: 2%;
}
.print-registration-popover {
  margin-left: 6.5%;
  margin-top: 1%;
}

.printregistration-popover {
  width: 300px;
}

.popover-print-registration {
  cursor: pointer;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  color: #5e5e5e;
}

@media only screen and (max-width: 480px) {
  .print-registration-form-container {
    width: 90%;
    margin: 0 auto;
  }
  .print-registration-form-text-first {
    margin-top: 10%;
  }
  .print-registration-page-title {
    height: auto;
    padding-top: 28px;
}
}</style>
<style>.print-registration-form-fields .mds-label.mds-label--large .mds-label__text {
  font-size: 16px !important;
  font-weight: 300 !important;
  line-height: 21px !important;
  font-weight: 700 !important;
  margin-bottom: 2% !important
}
.print-registration-checkboxes .mds-checkbox__visible-wrap{
  box-shadow: none !important;
}
</style>