
import every from 'lodash/every';
import includes from 'lodash/includes';
import isEqual from 'lodash/isEqual';
import isNil from 'lodash/isNil';
import keys from 'lodash/keys';
import partial from 'lodash/partial';
import pick from 'lodash/pick';

const START = '/';
const trailingSlashRE = /\/?$/;

const defaultLinkProps = ['href', 'to', 'replace', 'append', 'exact', 'event', 'activeClass', 'exactActiveClass', 'newWindow', 'noPrefetch', 'trackEvent'];

export default {
	props: defaultLinkProps,
	computed: {
		isActiveRoute() {
			if (!this.to) return false;

			const { route } = this.$router.resolve(this.to, this.$route, this.append);
			const isMatch = !isNil(this.exact) && this.exact !== false ? this.isSameRoute(this.$route, route) : this.isIncludedRoute(this.$route, route);
			return this.active || isMatch;
		},
		isLink() {
			return this.href || this.to;
		},
		linkProps() {
			return pick(this.$props, defaultLinkProps);
		}
	},
	methods: {
		isSameRoute(a, b) {
			if (b === START) {
				return a === b;
			} if (!b) {
				return false;
			} if (a.path && b.path) {
				return (
					a.path.replace(trailingSlashRE, '') === b.path.replace(trailingSlashRE, '')
					&& a.hash === b.hash
					&& isEqual(a.query, b.query)
				);
			} if (a.name && b.name) {
				return (
					a.name === b.name
					&& a.hash === b.hash
					&& isEqual(a.query, b.query)
					&& isEqual(a.params, b.params)
				);
			}
			return false;
		},
		isIncludedRoute(current, target) {
			if (!current) return false;
			return current.path.replace(trailingSlashRE, '/').indexOf(target.path.replace(trailingSlashRE, '/')) === 0
				&& (!target.hash || current.hash === target.hash) && this.queryIncludes(current.query, target.query);
		},
		queryIncludes(current, target) {
			const targetKeys = keys(target);
			const currentKeys = keys(current);
			return every(targetKeys, partial(includes, currentKeys));
		}
	}
};
