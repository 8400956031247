var render = function render(){var _vm=this,_c=_vm._self._c;return _c('ul',{staticClass:"mdc-list-group",class:{
		[_vm.$style['mds-list-group']]: true,
		[_vm.$style['mds-list-group--large']]: _vm.large,
		[_vm.$style['mds-list-group--small']]: _vm.small,
		[_vm.$style['mds-list-group--no-rules']]: _vm.borderless,
		[_vm.$style['mds-list-group--spacious']]: _vm.spacious,
		[_vm.$style['mds-list-group--with-icon']]: _vm.withIcon,
		[_vm.$style['mds-component--on-dark']]: _vm.onDark,
	},attrs:{"role":"listbox","tabindex":"-1"}},[_vm._t("default")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }