var render = function render(){var _vm=this,_c=_vm._self._c;return _c(_vm.buttonTag,_vm._g(_vm._b({ref:"button",tag:"component",staticClass:"mdc-button",class:{
    [_vm.$style['mds-button']]: true,
    [_vm.$style['mds-button--primary']]: _vm.primary,
    [_vm.$style['mds-button--icon-only']]: _vm.iconOnly,
    [_vm.$style['mds-button--flat']]: _vm.flat || _vm.body || _vm.panel,
    [_vm.$style['mds-button--flat-icon-m']]: _vm.flat && _vm.iconMedium,
    [_vm.$style['mds-button--small']]: _vm.small,
    [_vm.$style['mds-button--large']]: _vm.large,
    'mdc-button--active': _vm.active,
    'mdc-button--filled': _vm.filled,
    'mdc-button--loading': _vm.loading,
    'mdc-button--body': _vm.body || _vm.panel,
    'mdc-button--panel': _vm.panel,
    'mdc-button--truncated': _vm.truncateContent,
  },attrs:{"id":_vm.id,"aria-label":_vm.iconAriaLabel,"type":_vm.buttonType,"disabled":_vm.disabled || _vm.loading,"tabindex":"0","role":_vm.isLink ? 'link' : 'button'}},'component',_vm.buttonLinkProps,false),_vm.$listeners),[(_vm.loading)?_c('mdc-loader',{class:_vm.$style['mds-button__loader'],attrs:{"inverted":_vm.primary,"gray":!_vm.primary}}):_vm._e(),(_vm.icon)?_c('mdc-icon',{staticClass:"mdc-button__content mdc-button__icon",class:{
      [_vm.$style['mds-button__icon']]: true,
    },attrs:{"name":_vm.icon,"small":_vm.iconSmall}},[(_vm.iconOnly)?_vm._t("default"):_vm._e()],2):_vm._e(),(_vm.icon && !_vm.iconOnly)?_c('span',{staticClass:"mdc-button__content mdc-button__text",class:{ 'mdc-button__content--truncated': _vm.truncateContent }},[_vm._t("default")],2):_vm._e(),(!_vm.icon && !_vm.iconOnly)?_c('span',{staticClass:"mdc-button__content",class:{ 'mdc-button__content--truncated': _vm.truncateContent }},[_vm._t("default")],2):_vm._e(),(_vm.rightIcon)?_c('mdc-icon',{staticClass:"mdc-button__content mdc-button__icon",class:{
      [_vm.$style['mds-button__icon']]: true,
      [_vm.$style['mds-button__icon--right']]: true,
    },attrs:{"name":_vm.rightIcon,"small":_vm.rightIconSmall || _vm.iconSmall}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }