import Cookies from 'js-cookie';
import axios from 'axios';

// Replace this with the base64-encoded encryption key from your environment variables
const TOKEN_KEY = 'authToken';
const SECRET_KEY = Buffer.from(process.env.VUE_APP_Encryption_Key, 'base64'); // Convert base64 string to Buffer
const tokenUrl = process.env.VUE_APP_MAAS_Token_Url;

// Function to encrypt the token
async function encryptToken(token) {
  const iv = crypto.getRandomValues(new Uint8Array(12)); // Generate a random IV (12 bytes for AES-GCM)
  const key = await crypto.subtle.importKey(
    'raw',
    SECRET_KEY,
    { name: 'AES-GCM' },
    false,
    ['encrypt']
  );
  const encodedToken = new TextEncoder().encode(token); // Convert token to Uint8Array
  const encrypted = await crypto.subtle.encrypt(
    { name: 'AES-GCM', iv: iv },
    key,
    encodedToken
  );
  // Return a base64-encoded string with the IV and encrypted data
  return `${btoa(String.fromCharCode(...iv))}:${btoa(String.fromCharCode(...new Uint8Array(encrypted)))}`;
}

// Function to decrypt the token
async function decryptToken(encryptedToken) {
  const [ivBase64, encryptedDataBase64] = encryptedToken.split(':'); // Split the components (IV and encrypted data)
  const iv = Uint8Array.from(atob(ivBase64), c => c.charCodeAt(0)); // Convert IV back to Uint8Array
  const encryptedData = Uint8Array.from(atob(encryptedDataBase64), c => c.charCodeAt(0)); // Convert encrypted data back to Uint8Array
  const key = await crypto.subtle.importKey(
    'raw',
    SECRET_KEY,
    { name: 'AES-GCM' },
    false,
    ['decrypt']
  );
  const decrypted = await crypto.subtle.decrypt(
    { name: 'AES-GCM', iv: iv },
    key,
    encryptedData.buffer
  );
  return new TextDecoder().decode(decrypted); // Convert decrypted data back to string
}

// Function to set the token in cookies
export async function setToken(token) {
  const encryptedToken = await encryptToken(token); // Encrypt the token
    
  // Calculate expiration time in minutes
  const expirationMinutes = 59; // Set for 59 minutes
  const expirationTime = new Date(new Date().getTime() + expirationMinutes * 60 * 1000);
  Cookies.set(TOKEN_KEY, encryptedToken, { 
    secure: true, 
    sameSite: 'Strict', 
    expires: expirationTime 
  }); // Set the cookie securely // Set the cookie securely
}

// Function to get the token from cookies
export async function getToken() {
  let encryptedToken = Cookies.get(TOKEN_KEY); // Get the token from cookies
  if (!encryptedToken) {
    // If token is null, attempt to refresh it
    await refreshToken();
    encryptedToken = Cookies.get(TOKEN_KEY);
  }
  return await decryptToken(encryptedToken); // Decrypt and return the token
}

// Function to remove the token from cookies
export async function removeToken() {
  Cookies.remove(TOKEN_KEY); // Remove the token cookie
}

// Function to refresh the token
export async function refreshToken() {
  if (!tokenUrl) {
    //console.error('Error: VUE_APP_MAAS_Token_Url is undefined. Check your environment variables.');
    return;
  }
  try {
    const authHeaders = await addAuthCredentials(); // Get the auth headers
    const response = await axios.post(tokenUrl, 'grant_type=client_credentials', {
      headers: authHeaders,
    }); // Make the API call to refresh the token
    const newToken = response.data.access_token; // Get the new token from response
    if (!newToken) {
      throw new Error('Token response does not contain access_token');
    }
    // Save the new token securely
    await setToken(newToken);
  } catch (error) {
    //console.error('Error refreshing token:', error.response ? error.response.data : error.message);
    await removeToken(); // Optionally remove token if refresh fails
    throw error;
  }
}

async function addAuthCredentials() {
  const headers = {
    'Authorization': process.env.VUE_APP_MAAS_Credentials, // Ensure this value is properly loaded
    'Content-Type': 'application/x-www-form-urlencoded', // This may depend on your API requirements
  };
  return headers;
}

const crypto = window.crypto || window.msCrypto; // Added the declaration for the crypto object

