<template>
    <li
        :class="$style['mdc-carousel-item']"
        :style="{
            width: width
        }"
    >
        <slot />
    </li>
</template>

<script>
export default {
    props: {
        width: {
            type: String,
            description: 'Width of carousel item',
            default: null
        }
    }
};
</script>

<style lang="scss" module>
.mdc-carousel-item {
    list-style: none;
    position: relative;
    min-width: 0;
}
</style>