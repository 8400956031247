<template>
	<section :class="$style['mdc-feed-section']">
		<div :class="$style['mdc-feed-section__contents']">
			<slot />
		</div>
	</section>
</template>

<script>
export default {
	components: {
	},
	props: {
		title: {
			type: String,
			description: 'Collection title',
			default: null
		}
	},
	data() {
		return {
			hasActionsSlot: false
		};
	},
	created() {
		this.refreshSlotVisibility();
	},
	beforeUpdate() {
		this.refreshSlotVisibility();
	},
	methods: {
		refreshSlotVisibility() {
			this.hasActionsSlot = !!this.$slots.actions;
		}
	}
};
</script>

<style lang="scss" module>
@import '@mds/constants';
.mdc-feed-section {
	&:not(:first-child) {
		margin-top: $mds-space-4-x;
		border-top: $mds-border-separator;
		padding-top: $mds-space-1-x;
	}

	margin-bottom: $mds-space-4-x;

	&__header {
		margin-top: $mds-space-1-x;
		margin-bottom: $mds-space-2-x;
		//min-height: $mds-typography-line-height-s + $mds-space-1-x;
		display: flex;
		align-items: center;
	}

	&__actions {
		margin-bottom: $mds-space-2-x;
	}
}
</style>
