var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.isMobileHeaderToggle)?_c('div',[(this.$store.state.user.LoggedIn)?_c('mds-button',{staticClass:"menu-responsive-button",attrs:{"icon":"menu","variation":"flat","size":"large","id":"product-selector"},on:{"click":function($event){return _vm.openProductSelectorresponsive()}}}):_vm._e(),(!this.$store.state.user.LoggedIn)?_c('h1',{staticClass:"logged-out-menubar",on:{"click":function($event){return _vm.openProductSelectorresponsive()}}}):_vm._e(),(this.$store.state.user.LoggedIn)?_c('div',{key:this.$store.state.product.CurrentProduct,staticClass:"product-dropdown-responsive"},[_c('mds-popover',{staticClass:"product-dropdown-popover-responsive",attrs:{"triggered-by":"product-selector","position":['bottom-right']},model:{value:(_vm.openProductSelector),callback:function ($$v) {_vm.openProductSelector=$$v},expression:"openProductSelector"}},[_c('mds-list-group',{attrs:{"on-dark":"","no-rules":""}},[_c('mds-button',{attrs:{"variation":"flat","size":"large"},on:{"click":function($event){return _vm.openProductSelectorresponsiveclose()}}},[_c('mds-icon',{staticClass:"menu-bar-button",attrs:{"name":"menu","size":"large"}})],1),_c('router-link',{staticClass:"router-link-disable",attrs:{"to":{ name: 'home', params: { currentproduct: 'mfi' } }}},[(this.MFIAccess)?_c('mds-list-group-item',{staticClass:"top-product-selection",attrs:{"active":_vm.MainProductNonActive,"on-dark":"","size":"small"}},[[_c('div',{staticClass:"top-product-main-div",on:{"click":function($event){_vm.openProductSelector = !_vm.openProductSelector}}},[_c('div',[_c('h1',{staticClass:"top-product-selected-list"},[_vm._v("FUND")])]),_c('div',{staticClass:"investor-div"},[_c('span',[_vm._v("INVESTOR")])])])]],2):_vm._e()],1),(!this.MFIAccess)?_c('mds-list-group-item',{staticClass:"top-product-selection",attrs:{"exact":"","to":{
              name: 'marketing-landing',
              params: { currentproduct: 'mfi' },
            },"on-dark":"","size":"small"}},[[_c('div',{staticClass:"top-product-main-div-lock",on:{"click":function($event){_vm.openProductSelector = !_vm.openProductSelector}}},[_c('div',[_c('h1',{staticClass:"top-product-selected-list"},[_vm._v("FUND")])]),_c('div',{staticClass:"investor-div"},[_c('span',[_vm._v("INVESTOR")])]),_c('mds-icon',{staticClass:"product-lock-icon-responsive-mfi",attrs:{"name":"lock-close","size":"small"}})],1)]],2):_vm._e(),(
              this.currentProductFullName != 'NEWSLETTERS HOME' &&
              this.CurrentProduct == 'mfi'
            )?_c('mds-list-group-item',{staticClass:"tab-selection",attrs:{"exact":"","to":{
              name: 'home',
              params: { currentproduct: this.CurrentProduct },
            }}},[_c('span',{staticClass:"sub-tabs-class",on:{"click":function($event){_vm.openProductSelector = !_vm.openProductSelector}}},[_vm._v("Home")])]):_vm._e(),(
              this.currentProductFullName != 'NEWSLETTERS HOME' &&
              this.CurrentProduct == 'mfi'
            )?_c('mds-list-group-item',{staticClass:"tab-selection",attrs:{"to":{
              name: 'commentary',
              params: { currentproduct: this.CurrentProduct },
            }}},[_c('span',{staticClass:"sub-tabs-class",on:{"click":function($event){_vm.openProductSelector = !_vm.openProductSelector}}},[_vm._v("Commentary")])]):_vm._e(),(
              _vm.CurrentProduct == 'mfi' &&
              this.currentProductFullName != 'NEWSLETTERS HOME' &&
              this.CurrentProduct == 'mfi'
            )?_c('mds-list-group-item',{staticClass:"tab-selection",attrs:{"to":{
              name: 'fundinvestor',
              params: { currentproduct: this.CurrentProduct },
            }}},[_c('span',{staticClass:"sub-tabs-class",on:{"click":function($event){_vm.openProductSelector = !_vm.openProductSelector}}},[_vm._v("FundInvestor 500")])]):_vm._e(),(
              _vm.CurrentProduct == 'mfi' &&
              this.currentProductFullName != 'NEWSLETTERS HOME' &&
              this.CurrentProduct == 'mfi'
            )?_c('mds-list-group-item',{staticClass:"tab-selection",attrs:{"to":{
              name: 'fundselector',
              params: { currentproduct: this.CurrentProduct },
            }}},[_c('span',{staticClass:"sub-tabs-class",on:{"click":function($event){_vm.openProductSelector = !_vm.openProductSelector}}},[_vm._v("FundInvestor Selector")])]):_vm._e(),(
              this.currentProductFullName != 'NEWSLETTERS HOME' &&
              this.CurrentProduct == 'mfi'
            )?_c('mds-list-group-item',{staticClass:"tab-selection",attrs:{"active":_vm.archiveActive,"to":{
              name: 'archive',
              params: { currentproduct: this.CurrentProduct },
            }}},[_c('span',{staticClass:"sub-tabs-class",on:{"click":function($event){_vm.openProductSelector = !_vm.openProductSelector}}},[_vm._v("Archive ")])]):_vm._e(),_c('router-link',{staticClass:"router-link-disable",attrs:{"to":{ name: 'home', params: { currentproduct: 'msi' } }}},[(this.MSIAccess)?_c('mds-list-group-item',{staticClass:"top-product-selection",attrs:{"active":_vm.MainProductNonActive,"on-dark":"","size":"small"}},[[_c('div',{staticClass:"top-product-main-div",on:{"click":function($event){_vm.openProductSelector = !_vm.openProductSelector}}},[_c('div',[_c('h1',{staticClass:"top-product-selected-list"},[_vm._v("STOCK")])]),_c('div',{staticClass:"investor-div"},[_c('span',[_vm._v("INVESTOR")])])])]],2):_vm._e()],1),(!this.MSIAccess)?_c('mds-list-group-item',{staticClass:"top-product-selection",attrs:{"exact":"","to":{
              name: 'marketing-landing',
              params: { currentproduct: 'msi' },
            },"on-dark":"","size":"small"}},[[_c('div',{staticClass:"top-product-main-div-lock",on:{"click":function($event){_vm.openProductSelector = !_vm.openProductSelector}}},[_c('div',[_c('h1',{staticClass:"top-product-selected-list"},[_vm._v("STOCK")])]),_c('div',{staticClass:"investor-div"},[_c('span',[_vm._v("INVESTOR")])]),_c('mds-icon',{staticClass:"product-lock-icon-responsive-msi",attrs:{"name":"lock-close","size":"small"}})],1)]],2):_vm._e(),(
              this.currentProductFullName != 'NEWSLETTERS HOME' &&
              this.CurrentProduct == 'msi'
            )?_c('mds-list-group-item',{staticClass:"tab-selection",attrs:{"exact":"","to":{
              name: 'home',
              params: { currentproduct: this.CurrentProduct },
            }}},[_c('span',{staticClass:"sub-tabs-class",on:{"click":function($event){_vm.openProductSelector = !_vm.openProductSelector}}},[_vm._v("Home")])]):_vm._e(),(
              this.currentProductFullName != 'NEWSLETTERS HOME' &&
              this.CurrentProduct == 'msi'
            )?_c('mds-list-group-item',{staticClass:"tab-selection",attrs:{"to":{
              name: 'commentary',
              params: { currentproduct: this.CurrentProduct },
            }}},[_c('span',{staticClass:"sub-tabs-class",on:{"click":function($event){_vm.openProductSelector = !_vm.openProductSelector}}},[_vm._v("Commentary")])]):_vm._e(),(
              _vm.CurrentProduct == 'msi' &&
              this.currentProductFullName != 'NEWSLETTERS HOME'
            )?_c('mds-list-group-item',{staticClass:"tab-selection",attrs:{"to":{
              name: 'tortoisePort',
              params: { currentproduct: this.CurrentProduct },
            }}},[_c('span',{staticClass:"sub-tabs-class",on:{"click":function($event){_vm.openProductSelector = !_vm.openProductSelector}}},[_vm._v("Tortoise Portfolio")])]):_vm._e(),(
              _vm.CurrentProduct == 'msi' &&
              this.currentProductFullName != 'NEWSLETTERS HOME'
            )?_c('mds-list-group-item',{staticClass:"tab-selection",attrs:{"to":{
              name: 'harePort',
              params: { currentproduct: this.CurrentProduct },
            }}},[_c('span',{staticClass:"sub-tabs-class",on:{"click":function($event){_vm.openProductSelector = !_vm.openProductSelector}}},[_vm._v("Hare Portfolio")])]):_vm._e(),(
              _vm.CurrentProduct == 'msi' &&
              this.currentProductFullName != 'NEWSLETTERS HOME'
            )?_c('mds-list-group-item',{staticClass:"tab-selection",attrs:{"to":{ name: 'TortoiseAndHareWatchlist', params: { currentproduct: this.CurrentProduct }}}},[_c('span',{staticClass:"sub-tabs-class",on:{"click":function($event){_vm.openProductSelector = !_vm.openProductSelector}}},[_vm._v("Tortoise & Hare Watchlist")])]):_vm._e(),(
              _vm.CurrentProduct == 'msi' &&
              this.currentProductFullName != 'NEWSLETTERS HOME'
            )?_c('mds-list-group-item',{staticClass:"tab-selection",attrs:{"to":{
              name: 'analyst',
              params: { currentproduct: this.CurrentProduct },
            }}},[_c('span',{staticClass:"sub-tabs-class",on:{"click":function($event){_vm.openProductSelector = !_vm.openProductSelector}}},[_vm._v("Analyst Reports")])]):_vm._e(),(
              this.currentProductFullName != 'NEWSLETTERS HOME' &&
              this.CurrentProduct == 'msi'
            )?_c('mds-list-group-item',{staticClass:"tab-selection",attrs:{"active":_vm.archiveActive,"to":{
              name: 'archive',
              params: { currentproduct: this.CurrentProduct },
            }}},[_c('span',{staticClass:"sub-tabs-class",on:{"click":function($event){_vm.openProductSelector = !_vm.openProductSelector}}},[_vm._v("Archive ")])]):_vm._e(),_c('router-link',{staticClass:"router-link-disable",attrs:{"to":{ name: 'home', params: { currentproduct: 'mdi' } }}},[(this.MDIAccess)?_c('mds-list-group-item',{staticClass:"top-product-selection",attrs:{"active":_vm.MainProductNonActive,"size":"small"}},[[_c('div',{staticClass:"top-product-main-div",on:{"click":function($event){_vm.openProductSelector = !_vm.openProductSelector}}},[_c('div',[_c('h1',{staticClass:"top-product-selected-list"},[_vm._v("DIVIDEND")])]),_c('div',{staticClass:"investor-div"},[_c('span',[_vm._v("INVESTOR")])])])]],2):_vm._e()],1),(!this.MDIAccess)?_c('mds-list-group-item',{staticClass:"top-product-selection",attrs:{"exact":"","to":{
              name: 'marketing-landing',
              params: { currentproduct: 'mdi' },
            },"on-dark":"","size":"small"}},[[_c('div',{staticClass:"top-product-main-div-lock",on:{"click":function($event){_vm.openProductSelector = !_vm.openProductSelector}}},[_c('div',[_c('h1',{staticClass:"top-product-selected-list"},[_vm._v("DIVIDEND")])]),_c('div',{staticClass:"investor-div"},[_c('span',[_vm._v("INVESTOR")])]),_c('mds-icon',{staticClass:"product-lock-icon-responsive-mdi",attrs:{"name":"lock-close","size":"small"}})],1)]],2):_vm._e(),(
              this.currentProductFullName != 'NEWSLETTERS HOME' &&
              this.CurrentProduct == 'mdi'
            )?_c('mds-list-group-item',{staticClass:"tab-selection",attrs:{"exact":"","to":{
              name: 'home',
              params: { currentproduct: this.CurrentProduct },
            }}},[_c('span',{staticClass:"sub-tabs-class",on:{"click":function($event){_vm.openProductSelector = !_vm.openProductSelector}}},[_vm._v("Home")])]):_vm._e(),(
              this.currentProductFullName != 'NEWSLETTERS HOME' &&
              this.CurrentProduct == 'mdi'
            )?_c('mds-list-group-item',{staticClass:"tab-selection",attrs:{"to":{
              name: 'commentary',
              params: { currentproduct: this.CurrentProduct },
            }}},[_c('span',{staticClass:"sub-tabs-class",on:{"click":function($event){_vm.openProductSelector = !_vm.openProductSelector}}},[_vm._v("Commentary")])]):_vm._e(),(
              _vm.CurrentProduct == 'mdi' &&
              this.currentProductFullName != 'NEWSLETTERS HOME'
            )?_c('mds-list-group-item',{staticClass:"tab-selection",attrs:{"to":{
              name: 'dividendSelectPort',
              params: { currentproduct: this.CurrentProduct },
            }}},[_c('span',{staticClass:"sub-tabs-class",on:{"click":function($event){_vm.openProductSelector = !_vm.openProductSelector}}},[_vm._v(" Dividend Select Portfolio")])]):_vm._e(),(
              _vm.CurrentProduct == 'mdi' &&
              this.currentProductFullName != 'NEWSLETTERS HOME'
            )?_c('mds-list-group-item',{staticClass:"tab-selection",attrs:{"to":{
              name: 'incomeBellWethers',
              params: { currentproduct: this.CurrentProduct },
            }}},[_c('span',{staticClass:"sub-tabs-class",on:{"click":function($event){_vm.openProductSelector = !_vm.openProductSelector}}},[_vm._v("Income Bellwethers")])]):_vm._e(),(
              this.currentProductFullName != 'NEWSLETTERS HOME' &&
              this.CurrentProduct == 'mdi'
            )?_c('mds-list-group-item',{staticClass:"tab-selection",attrs:{"to":{
              name: 'analyst',
              params: { currentproduct: this.CurrentProduct },
            }}},[_c('span',{staticClass:"sub-tabs-class",on:{"click":function($event){_vm.openProductSelector = !_vm.openProductSelector}}},[_vm._v("Analyst Reports")])]):_vm._e(),(
              this.currentProductFullName != 'NEWSLETTERS HOME' &&
              this.CurrentProduct == 'mdi'
            )?_c('mds-list-group-item',{staticClass:"tab-selection",attrs:{"active":_vm.archiveActive,"to":{
              name: 'archive',
              params: { currentproduct: this.CurrentProduct },
            }}},[_c('span',{staticClass:"sub-tabs-class",on:{"click":function($event){_vm.openProductSelector = !_vm.openProductSelector}}},[_vm._v("Archive ")])]):_vm._e(),_c('router-link',{staticClass:"router-link-disable",attrs:{"to":{ name: 'home', params: { currentproduct: 'mei' } }}},[(this.MEIAccess)?_c('mds-list-group-item',{staticClass:"top-product-selection",attrs:{"active":_vm.MainProductNonActive,"on-dark":"","size":"small"}},[[_c('div',{staticClass:"top-product-main-div",on:{"click":function($event){_vm.openProductSelector = !_vm.openProductSelector}}},[_c('div',[_c('h1',{staticClass:"top-product-selected-list"},[_vm._v("ETF")])]),_c('div',{staticClass:"investor-div"},[_c('span',[_vm._v("INVESTOR")])])])]],2):_vm._e()],1),(!this.MEIAccess)?_c('mds-list-group-item',{staticClass:"top-product-selection",attrs:{"exact":"","to":{
              name: 'marketing-landing',
              params: { currentproduct: 'mei' },
            },"on-dark":"","size":"small"}},[[_c('div',{staticClass:"top-product-main-div-lock",on:{"click":function($event){_vm.openProductSelector = !_vm.openProductSelector}}},[_c('div',[_c('h1',{staticClass:"top-product-selected-list"},[_vm._v("ETF")])]),_c('div',{staticClass:"investor-div"},[_c('span',[_vm._v("INVESTOR")])]),_c('mds-icon',{staticClass:"product-lock-icon-responsive-etf",attrs:{"name":"lock-close","size":"small"}})],1)]],2):_vm._e(),(
              this.currentProductFullName != 'NEWSLETTERS HOME' &&
              this.CurrentProduct == 'mei'
            )?_c('mds-list-group-item',{staticClass:"tab-selection",attrs:{"exact":"","to":{
              name: 'home',
              params: { currentproduct: this.CurrentProduct },
            }}},[_c('span',{staticClass:"sub-tabs-class",on:{"click":function($event){_vm.openProductSelector = !_vm.openProductSelector}}},[_vm._v("Home")])]):_vm._e(),(
              this.currentProductFullName != 'NEWSLETTERS HOME' &&
              this.CurrentProduct == 'mei'
            )?_c('mds-list-group-item',{staticClass:"tab-selection",attrs:{"to":{
              name: 'commentary',
              params: { currentproduct: this.CurrentProduct },
            }}},[_c('span',{staticClass:"sub-tabs-class",on:{"click":function($event){_vm.openProductSelector = !_vm.openProductSelector}}},[_vm._v("Commentary")])]):_vm._e(),(
              _vm.CurrentProduct == 'mei' &&
              this.currentProductFullName != 'NEWSLETTERS HOME'
            )?_c('mds-list-group-item',{staticClass:"tab-selection",attrs:{"to":{
              name: 'modelPort',
              params: { currentproduct: this.CurrentProduct },
            }}},[_c('span',{staticClass:"sub-tabs-class",on:{"click":function($event){_vm.openProductSelector = !_vm.openProductSelector}}},[_vm._v("Model Portfolios")])]):_vm._e(),(
              _vm.CurrentProduct == 'mei' &&
              this.currentProductFullName != 'NEWSLETTERS HOME'
            )?_c('mds-list-group-item',{staticClass:"tab-selection",attrs:{"to":{
              name: 'equityWatchlist',
              params: { currentproduct: this.CurrentProduct },
            }}},[_c('span',{staticClass:"sub-tabs-class",on:{"click":function($event){_vm.openProductSelector = !_vm.openProductSelector}}},[_vm._v("Equity Watchlist")])]):_vm._e(),(
              _vm.CurrentProduct == 'mei' &&
              this.currentProductFullName != 'NEWSLETTERS HOME'
            )?_c('mds-list-group-item',{staticClass:"tab-selection",attrs:{"to":{
              name: 'bondWatchlist',
              params: { currentproduct: this.CurrentProduct },
            }}},[_c('span',{staticClass:"sub-tabs-class",on:{"click":function($event){_vm.openProductSelector = !_vm.openProductSelector}}},[_vm._v(" Bond Watchlist")])]):_vm._e(),(
              _vm.CurrentProduct == 'mei' &&
              this.currentProductFullName != 'NEWSLETTERS HOME'
            )?_c('mds-list-group-item',{staticClass:"tab-selection",attrs:{"to":{
              name: 'analyst',
              params: { currentproduct: this.CurrentProduct },
            }}},[_c('span',{staticClass:"sub-tabs-class",on:{"click":function($event){_vm.openProductSelector = !_vm.openProductSelector}}},[_vm._v("Analyst Reports")])]):_vm._e(),(
              this.currentProductFullName != 'NEWSLETTERS HOME' &&
              this.CurrentProduct == 'mei'
            )?_c('mds-list-group-item',{staticClass:"tab-selection",attrs:{"to":{
              name: 'archive',
              params: { currentproduct: this.CurrentProduct },
            }}},[_c('span',{staticClass:"sub-tabs-class",on:{"click":function($event){_vm.openProductSelector = !_vm.openProductSelector}}},[_vm._v("Archive ")])]):_vm._e(),_c('p',{staticClass:"border-top-newsletter-home"}),_c('p',{staticClass:"newsletterhome-para",on:{"click":function($event){_vm.openProductSelector = !_vm.openProductSelector}}},[_c('mds-list-group-item',{staticClass:"newsletter-product newsletters-home-link-responsive",staticStyle:{"margin-top":"10px"},attrs:{"text":"NEWSLETTERS HOME","to":{
                name: 'combined-home',
                params: { currentproduct: 'Common' },
              },"exact":""}})],1)],1)],1)],1):_vm._e()],1):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }