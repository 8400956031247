<template>
  <div class="save-article-main-div">
    <data-missing class="save-article-main-div" v-if="noArticleState && saveArticleLoader == false" :error-message="noArticleMessage"></data-missing>
    <div class="save-article-container">
      <mds-loader
      v-if="saveArticleLoader"
      aria-label="Medium Loader"
      ></mds-loader>
      <!--<data-missing class="save-article-main-div" v-else-if="noArticleState && saveArticleLoader == false" :error-message="noArticleMessage"></data-missing>-->
      <div v-else-if="noArticleState==false">
        <mds-alert :variation="saveArticleAlertVariationType" tinted mds-alert-dismissed :text="saveArticleAlertMessage" v-if="saveArticleAlertDisplay">
        </mds-alert>
        <div class="save-article-heading-and-draft-button-container">
          <div class="save-article-heading">
            <span>{{ formHeading }}</span>
          </div>
          <div class="save-article-draft-button-container">
            <mds-button variation="primary" size="medium"  icon="eye" @click="redirectToDrafts()">Drafts</mds-button>
          </div>
        </div>
        <div class="save-article-form-container">
          <mds-form size="medium">
            <mds-button variation="secondary" size="medium"  icon="lock-open" @click.prevent="saveArticleOtherAuthorLockToggle()" v-show="saveArticleOtherAuthorLockStatus" class="save-article-lock-design"></mds-button>
            <span v-show="saveArticleOtherAuthorLockStatus" class="save-article-lock-message"> Click the unlock icon to enable the drop down menu</span>   
            <mds-select
                  size="medium"
                  label="Author Name"
                  placeholder="Choose author name"
                  required
                  :error-text="['Author name field cannot be empty.']"
                  :options="[
                      { text: 'David Harrell', value: 'David Harrell' },
                      { text: 'Russel Kinnel', value: 'Russel Kinnel' },
                      { text: 'Bryan Armour', value: 'Bryan Armour' },
                      { text: 'Peggy Seemann', value: 'Peggy Seemann' },
                      { text: 'Other', value: 'Other' },
                  ]"
                  v-model="saveArticleAuthorNameValue"
                  @change="saveArticleAuthorNameSelection();saveArticleButtonState()"
                  :error="saveArticleErrorAuthorName"
                  :disabled="saveArticleAuthorSelectorDisabled"
                  class="selection-div-author"
              >
            </mds-select>
            <mds-input size="medium" hidden-label label="hidden-label" placeholder="Author Name" required v-show="saveArticleOtherAuthorNameStatus" v-model="saveArticleOtherAuthorNameValue" @input="saveArticleButtonState()" v-on:keypress="saveArticleValidateAuthorName($event)"></mds-input>
            <mds-input size="medium" label="Title" placeholder="Post Title" required :error-text="['Post title field cannot be empty.']" :error="saveArticleErrorInputBox" v-model="saveArticleTitleValue" @input="saveArticleButtonState()"></mds-input>
            <div class="save-article-body-title">
              Body
              <abbr title="This field is required" aria-label="This field is required" class="save-article-required-indicator">*</abbr>
            </div>
            <editor
                :api-key="tinyAPI"
                :init="{
                  height: 500,
                  menubar: true,
                  convert_urls:false,
                  plugins: [
                    'advlist autolink lists link image charmap print preview anchor',
                    'searchreplace visualblocks code fullscreen',
                    'insertdatetime media table paste code help wordcount'
                  ],
                  font_formats: 'Univers, HelveticaNeue, Helvetica Neue, Helvetica, Arial, sans-serif',
                  toolbar:
                    'undo redo | formatselect | bold italic backcolor | \
                    alignleft aligncenter alignright alignjustify | \
                    bullist numlist outdent indent | removeformat | help'
                }"
                v-model="saveArticleBodyValue"
                @input="saveArticleButtonState()"
            />
            <mds-button-container rightAligned class="save-article-operations-button">
                <mds-button variation="primary" size="medium" @click.prevent="saveArticle('savePost')" :disabled="saveArticleButtonsDisable" icon="upload"> {{ saveArticleButtonName }} </mds-button>
                <mds-button variation="primary" size="medium" @click.prevent="saveArticle('draftPost')" :disabled="saveArticleButtonsDisable" icon="save" v-if="showSaveAsDraftButton">Save As Draft</mds-button>
                <mds-button variation="secondary" size="medium" @click.prevent="$router.back ()" icon="remove"> Cancel </mds-button>
                <mds-button variation="secondary" size="medium" @click="resetArticleForm()" :disabled="saveArticleButtonsDisable" icon="undo"> Reset </mds-button>
                <mds-button variation="secondary" size="medium" icon="eye" @click.prevent="previewArticle()" :disabled="saveArticleButtonsDisable"> Preview </mds-button>
            </mds-button-container> 
          </mds-form>
          <mds-dialog v-model="saveArticlePreviewEnabled" :title="this.saveArticleTitleValue" class="save-article-preview-dialog">
              <div class="save-article-preview-author-date">
                <span id='save-article-preview-author-name'>by {{ saveArticlePreviewAuthorName }}</span>
                <span id='preview-aticle-date'>{{ this.dateTime | formatDateVal}}</span>
              </div>
              <div v-html="trimContent(this.saveArticleBodyValue)">
              </div>
                <template #mds-dialog-actions-right>
                    <mds-button-container right-aligned>
                        <mds-button type="button" variation="secondary" @click="saveArticlePreviewEnabled=!saveArticlePreviewEnabled"> Close </mds-button>
                    </mds-button-container>
                </template>
          </mds-dialog>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="js">
import MdsForm from '@mds/form';
import MdsSelect from '@mds/select';
import MdsInput from '@mds/input';
import Editor from '@tinymce/tinymce-vue';
import { MdsButton, MdsButtonContainer } from '@mds/button';
import MdsDialog from '@mds/dialog';
import MdsAlert from '@mds/alert';
import MdsLoader from "@mds/loader";
import DataMissing from "@/components/Common/ErrorPages/DataMissing";
import { fetchData, postData, putData } from '@/utilities/API/apiUtility';
  export default  {
    name: 'save-article',
    props: [],
    mounted () {
      window.scrollTo(0,0);
      if(this.postId)
      {
        this.formHeading='Update Post';
        this.saveArticleButtonName='Update'
        this.saveArticleButtonsDisable=false;
        this.saveArticleLoader=true;
        this.getPostById(this.postId);
      }
      else
      {
        this.formHeading='New Post';
        this.saveArticleButtonName='Post';
        this.saveArticleButtonsDisable=true;
        this.saveArticleLoader=false;
      }
    },
    components: {
      MdsForm,
      MdsSelect,
      MdsInput,
      Editor,
      MdsButtonContainer,
      MdsButton,
      MdsDialog,
      MdsAlert,
      MdsLoader,
      DataMissing,
    },
    data () {
      return {
        formHeading:null,
        postId:this.$route.params.pid,
        currentProduct : this.$session.get("current-product"),
        mdiSectionId: process.env.VUE_APP_MDISectionID,
        msiSectionId: process.env.VUE_APP_MSISectionID,
        mfiSectionId: process.env.VUE_APP_MFISectionID,
        meiSectionId: process.env.VUE_APP_MEISectionID,
        postByIdURL:process.env.VUE_APP_BackEndAPI + process.env.VUE_APP_PostById,
        saveArticleURL : process.env.VUE_APP_BackEndAPI + process.env.VUE_APP_CreateArticle,
        updateArticleURL : process.env.VUE_APP_BackEndAPI + process.env.VUE_APP_UpdateArticle,
        tinyAPI:process.env.VUE_APP_TINYMCE,
        saveArticleAlertVariationType:null,
        saveArticleAlertMessage:null,
        saveArticleAlertDisplay:false,
        saveArticleOtherAuthorLockStatus:false,
        saveArticleAuthorNameValue:null,
        saveArticleErrorAuthorName:false,
        saveArticleAuthorSelectorDisabled:false,
        saveArticleOtherAuthorNameStatus:false,
        saveArticleOtherAuthorNameValue:null,
        saveArticleErrorInputBox:false,
        saveArticleTitleValue:null,
        saveArticleBodyValue:null,
        saveArticleButtonsDisable:null,
        saveArticleButtonName:null,
        saveArticlePreviewEnabled:false,
        saveArticlePreviewAuthorName:null,
        dateTime:new Date(),
        noArticleState:false,
        noArticleMessage:null,
        productSectionID:null,
        saveArticleLoader:false,
        isDraft:0,
        showSaveAsDraftButton:true,
      }
    },
    methods: {
      getPostById(postId){
        const getPostByIdURL = this.postByIdURL + postId;
        try{
          fetchData(getPostByIdURL)
          .then((response) =>{
            if(response){
              if(response.status == 200){
                if(response.data !=null){
                  this.saveArticleLoader=false;
                  this.saveArticleAuthorNameValue = response.data.PostAuthorName;
                  this.saveArticleTitleValue = response.data.Subject;
                  this.saveArticleBodyValue = response.data.FormattedBody;
                  this.dateTime = response.data.PostDate;
                  this.productSectionID = response.data.SectionID;
                  this.isDraft = response.data.IsDraft;
                  const getSectionIDValue = this.getSectionID();
                  if(this.isDraft == 1){
                    this.saveArticleButtonName='Publish'
                  }
                  if(this.isDraft == 0){
                    this.showSaveAsDraftButton = false;
                  }
                  if(this.productSectionID != getSectionIDValue)
                  {
                    this.noArticleState=true;
                    this.noArticleMessage="The requested post doesn't belong to this product.";
                  }
                  if(response.data.PostAuthorName != "David Harrell" && response.data.PostAuthorName != "Peggy Seemann" && response.data.PostAuthorName != "Russel Kinnel" && response.data.PostAuthorName != "Bryan Armour")
                  {
                    this.getAuthorName();
                  }
                }
                else
                {
                  this.saveArticleLoader=false;
                  this.noArticleState=true;
                  this.noArticleMessage="The requested post doesn't exist.";
                }
              }
            }
          }).catch((error) => {
            this.saveArticleLoader=false;
            this.noArticleState=true;
            this.noArticleMessage="The requested post doesn't exist.";
            console.log(error);
          })
        }catch(ex){
          console.log(ex.message);
        }
      },
      getAuthorName(){
        this.saveArticleOtherAuthorNameValue = this.saveArticleAuthorNameValue;
        this.saveArticleAuthorNameValue = "Other";
        this.saveArticleAuthorNameSelection();
      },
      saveArticleAuthorNameSelection(){
        if(this.saveArticleAuthorNameValue == 'Other')
        {
          this.saveArticleAuthorSelectorDisabled=true;
          this.saveArticleOtherAuthorNameStatus=true;
          this.saveArticleOtherAuthorLockStatus=true;
          this.saveArticleAuthorNameValue="";
        }
        else
        {
          this.saveArticleAuthorSelectorDisabled=false;
          this.saveArticleOtherAuthorNameStatus=false;
          this.saveArticleOtherAuthorLockStatus=false;
          if(!this.postId){
            this.saveArticleOtherAuthorNameValue="";
          }
        }
      },
      saveArticleOtherAuthorLockToggle(){
        this.saveArticleAuthorSelectorDisabled=false;
        this.saveArticleOtherAuthorNameStatus=false;
        this.saveArticleOtherAuthorLockStatus=false;
        if(!this.postId){
          this.saveArticleOtherAuthorNameValue="";
        }
        this.saveArticleAuthorNameValue="";
        this.saveArticleButtonState();
      },
      saveArticleButtonState(){
        if(this.saveArticleAuthorSelectorDisabled)
        {
          if(this.saveArticleOtherAuthorNameValue && this.saveArticleTitleValue && this.saveArticleBodyValue)
          {
            this.saveArticleButtonsDisable=false;
          }
          else
          {
            this.saveArticleButtonsDisable = true;
          }
        }
        else if(!this.saveArticleAuthorSelectorDisabled)
        {
          if(this.saveArticleTitleValue && this.saveArticleBodyValue && this.saveArticleAuthorNameValue){
            this.saveArticleButtonsDisable = false;
          }
          else
          {
            this.saveArticleButtonsDisable = true; 
          }
        }
        else
        {
          this.saveArticleButtonsDisable = true; 
        }
      },
      saveArticleValidateAuthorName(e) {
        let char = String.fromCharCode(e.keyCode);
        if(/^[A-Za-z ]+$/.test(char)) return true;
        else e.preventDefault();
      },
      previewArticle(){
        this.saveArticlePreviewEnabled=true;
        if(!this.saveArticleAuthorSelectorDisabled)
        {
          this.saveArticlePreviewAuthorName = this.saveArticleAuthorNameValue;
        }
        else
        {
          this.saveArticlePreviewAuthorName = this.saveArticleOtherAuthorNameValue;
        }
      },
      trimContent(content) {
        if(content !=null){
          var htmlData = content.replace(/<((?!p)(?!ul)(?!ol)(?!a)(?!li)(?!b)(?!BR)(?!span)(?!sup)(?!sub)(?!i)(?!strong)(?!CENTER)(?!h)(?!em)(?!table)(?!tbody)(?!tr)(?!td)(?!th)(?!hr)\s*\b\/?)[^>]+>/g, '');
          //eslint-disable-next-line
          // var trimInlineCss = htmlData.replace(/(style=\"[^\"]*\")/g, '');
          return htmlData;
        }
      },
      resetArticleForm(){
        this.saveArticleAuthorNameValue="";
        this.saveArticleOtherAuthorNameValue="";
        this.saveArticleTitleValue="";
        this.saveArticleBodyValue="";
        this.saveArticleButtonsDisable=true;
        this.saveArticleOtherAuthorLockStatus=false;
        this.saveArticleAuthorSelectorDisabled=false;
        this.saveArticleOtherAuthorNameStatus = false;
        this.saveArticleErrorInputBox = false;
        this.saveArticleErrorAuthorName = false;
        this.saveArticleAlertDisplay=false;
      },
      getSectionID(){
        let sectionId=null;
        if(this.currentProduct.toUpperCase() == 'MDI')
        {
          sectionId = this.mdiSectionId;
        }
        else if(this.currentProduct.toUpperCase() == 'MSI')
        {
          sectionId = this.msiSectionId;
        }
        else if(this.currentProduct.toUpperCase() == 'MFI')
        {
          sectionId = this.mfiSectionId;
        }
        else
        {
          sectionId = this.meiSectionId;
        }

        return sectionId;
      },
      saveArticle(type){
        if(this.postId)
        {
          this.savePost('updateArticle',type);
        }
        else
        {
          this.savePost('saveArticle',type);
        }
      },
      savePost(operationType,savePostAs)
      {
        if (!this.saveArticleTitleValue) {
          this.saveArticleErrorInputBox = true;
        }
        else
        {
          this.saveArticleErrorInputBox=false;
          var postTitle= this.saveArticleTitleValue;
        }
        if (this.saveArticleBodyValue) {
          var postBody = this.saveArticleBodyValue;
        }

        if(!this.saveArticleAuthorSelectorDisabled)
        {
          if (!this.saveArticleAuthorNameValue) {
            this.saveArticleErrorAuthorName = true;
          }
          else
          {
            this.saveArticleErrorAuthorName=false;
            var postAuthorName = this.saveArticleAuthorNameValue;
          }
        }
        else
        {
          postAuthorName = this.saveArticleOtherAuthorNameValue;
        }
        if(this.saveArticleErrorInputBox == false && this.saveArticleErrorAuthorName==false)
        {
          window.scrollTo(0,0);
          this.saveArticleButtonsDisable=true;
          this.saveArticleAlertDisplay = true;
          this.saveArticleAlertVariationType="informational";
          this.saveArticleAlertMessage = "Please wait while the record is getting "+ (operationType === 'saveArticle' ? "saved.." : "updated..");
          let isDraftValue = 0;
          if(operationType=='saveArticle')
          {
            let sectionID = this.getSectionID();
            if(savePostAs == "draftPost"){
              isDraftValue = 1;
            }
            try{
            postData(this.saveArticleURL, {
              postVm:{
                SectionID:sectionID,
                Subject:postTitle,
                Body:postBody,
                FormattedBody:postBody,
                PostType:1,
                IsDraft:isDraftValue,
                PostAuthorName:postAuthorName
              }
            })
            .then((response) =>{
              if(response){
                if(response.status == 200){
                  this.saveArticleAlertDisplay = true;
                  this.saveArticleAlertVariationType="success";
                  if(savePostAs == "draftPost"){
                    this.saveArticleAlertMessage = "Post saved as draft successfully.";
                  }else{
                    this.saveArticleAlertMessage = "New post created successfully.";
                  }
                  var self = this;
                  setTimeout(function () { self.resetArticleForm() } , 2000)
                }
              }
            })
            .catch((error) => {
              this.saveArticleButtonsDisable=false;
              this.saveArticleAlertDisplay = true;
              this.saveArticleAlertVariationType="error";
              this.saveArticleAlertMessage = "Unable to add the post, please try again later.";
              console.log(error);
            })
            }
            catch (ex) {
              console.log(ex.message);
            }
          }
          else
          {
            if(savePostAs == "draftPost"){
              isDraftValue = 1;
            }
            try
            {
            putData(this.updateArticleURL, {
              postVm:{
                PostID:this.postId,
                Subject:postTitle,
                Body:postBody,
                FormattedBody:postBody,
                PostAuthorName:postAuthorName,
                IsDraft:isDraftValue,
              }
            })
            .then((response) =>{
              if(response){
                if(response.status == 200){
                  this.saveArticleAlertDisplay = true;
                  this.saveArticleAlertVariationType="success";
                  if(savePostAs == "draftPost"){
                    this.saveArticleAlertMessage = "Draft saved successfully.";
                  }else{
                    this.saveArticleAlertMessage = "Post updated successfully.";
                  }
                  
                  var self = this;
                  setTimeout(function () { self.resetArticleForm() } , 2000);
                  setTimeout(function () { self.redirectToCommentary(savePostAs) } , 2500);
                }
              }
            })
            .catch((error) => {
              this.saveArticleButtonsDisable=false;
              this.saveArticleAlertDisplay = true;
              this.saveArticleAlertVariationType="error";
              this.saveArticleAlertMessage = "Unable to update the post, please try again later.";
              console.log(error);
            })
            }
            catch (ex) {
              console.log(ex.message);
            }
          }
        }
      },
      redirectToCommentary(typeOfRedirect){
        var redirectTo = "commentary";
        if(typeOfRedirect == "draftPost")
        {
          redirectTo="draftsPost";
        }
        this.$router.push({ name: redirectTo }).catch(err => {
        if (
          err.name !== 'NavigationDuplicated' &&
          !err.message.includes('Avoided redundant navigation to current location')
        ) {
          console.log(err);
        }
      });
      },
      redirectToDrafts(){
        this.$router.push({ name: 'draftsPost' }).catch(err => {
          if (
            err.name !== 'NavigationDuplicated' &&
            !err.message.includes('Avoided redundant navigation to current location')
          ) {
            console.log(err);
          }
        });
      }
    },
}


</script>

<style scoped>
  .save-article-main-div{
    width:100%;
  }
  .save-article-container {
    margin:Auto;
    padding:auto;
    width: 50%;
    margin-top: 20px;
    padding-bottom:20px;
  }
  .save-article-heading-and-draft-button-container{
    width:90%;
    margin:0 auto;
    display:flex;
  }
  .save-article-heading{
    font-size: 20px;
    font-weight: 700;
    line-height: 21px;
    margin-bottom: 20px;
    padding-top: 20px;
  }
  .save-article-draft-button-container{
    margin-left:auto;
  }
  .save-article-draft-button-container button{
    margin-top:20px;
  }
  .save-article-form-container{
    background:transparent;
    width:90%;
    margin:auto;
    padding:auto;
  }
  .save-article-body-title{
    font-style: normal;
    font-size: 16px;
    line-height: 21px;
    font-weight: 600;
    color: #1e1e1e;
    margin-bottom:8px;
  }
  .save-article-required-indicator{
    color: #ca0000;
    text-decoration: none;
  }
  .save-article-operations-button{
    margin-top:20px;
  }
  .save-article-lock-design{
    margin-bottom:10px;
  }
  .save-article-lock-message{
    margin-left:10px;
    font-weight:500;
    color:#ca0000;
  }
  .save-article-preview-author-date{
    margin-top:20px;
    margin-bottom:10px;
  }
  #save-article-preview-author-name{
    font-weight:700;
    margin-right:10px;
  }
  .save-article-author-name-info-text{
    background:#fcfcfa;
    border-radius:5px;
    padding:10px;
    margin-bottom:10px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  }
</style>
<style>
  .save-article-form-container .mds-input{
    box-shadow: none!important;
    border-bottom: 1px solid #808080!important;
  }
  .save-article-form-container .mds-select__input{
    box-shadow: none!important;
    border-bottom: 1px solid #808080!important;
  }
  .save-article-preview-dialog .mds-dialog{
    max-height: 780px;
    overflow-y: scroll;
  }
  .save-article-preview-dialog .mds-dialog__message{
    line-height:normal
  }
  .save-article-preview-dialog > div > div:first-child {
    font-size:25px;
    color:red;
    line-height: normal;
  }
  .save-article-preview-dialog ul,ol{
    padding:20px;
  }
  .save-article-preview-dialog p{
    margin-bottom:15px;
  }
  .save-author-name-info-text > span{
    padding-top:2px !important;
  }
  .save-article-author-name-info-text > span{
    padding-top:2px !important;
  }
</style>
