var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('mds-page-shell',{attrs:{"logo":_vm.getProductLogo(),"logo-responsive":_vm.getProductLogo(),"logo-href":"/","logo-alt":"Back to home page"}},[_c('div',{staticClass:"product-subscription"},[_c('div',{staticClass:"product-subscription-main"},[_c('div',{staticClass:"product-subscription-form"},[_c('div',{staticClass:"product-subscription-form-container"},[_c('div',[_c('h1',{staticClass:"subcription-page-title"},[_c('span',[_vm._v("You're already subscribed to ")]),_vm._v(" "),_c('br'),_c('span',[_c('i',[_vm._v("Morningstar "+_vm._s(_vm.currentproductName))])])])]),_c('div',{staticClass:"product-subscription-info"},[_c('span',[_vm._v("Click Back to select a different subscription, or click Continue to navigate to "),_c('i',[_vm._v(_vm._s(_vm.currentproductName)+"’s")]),_vm._v(" homepage.")])]),_c('div',{staticClass:"product-subscription-container"},[_c('div',[_c('mds-button-container',[_c('mds-button',{attrs:{"variation":"secondary","size":"large"},on:{"click":function($event){return _vm.$router.push({
                  name: 'combined-home',
                  params: { currentproduct: 'Common' },
                })}}},[_vm._v(" Back ")]),_c('div',{staticClass:"product-subscription-cancel-button"},[_c('mds-button',{attrs:{"variation":"secondary","size":"large","type":"button"},on:{"click":function($event){return _vm.$router.push({
                name: 'combined-home',
                params: { currentproduct: 'Common' },
              })}}},[_vm._v(" Cancel ")])],1),_c('div',{staticClass:"product-subscription-continue-button"},[_c('mds-button',{attrs:{"variation":"primary","size":"large","type":"button","right-aligned":""},on:{"click":function($event){return _vm.$router.push({
                  name: 'home',
                  params: { currentproduct: _vm.currentproduct },
                })}}},[_vm._v(" Continue")])],1)],1)],1)])])])])])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }