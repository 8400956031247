import axios from 'axios';
import { getToken, removeToken } from './authToken';

// Create an Axios instance
const axiosInstance = axios.create({
  baseURL: process.env.VUE_APP_BackEndAPI, // Replace with your API endpoint
  timeout: 3600000,  // Timeout after 10 seconds
});

// Request interceptor to add token to headers
axiosInstance.interceptors.request.use(
  async (config) => {
    try {
      const token = await getToken();  // Asynchronously fetch token
      if (token) {
        config.headers['Authorization'] = `Bearer ${token}`;  // Attach token if available
      }
    } catch (error) {
      console.error('Error fetching token:', error);
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Response interceptor to handle token expiration
axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    if (error.response && error.response.status === 401) {
      try {
        // Token is invalid or expired, handle token removal
        await removeToken();
        // You can redirect the user to the login page or trigger token refresh logic here
        console.log('Token removed due to 401 error');
      } catch (removeError) {
        console.error('Error removing token:', removeError);
      }
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
