<template>
  <div>
    <mds-page-shell :logo="getProductLogo()" :logo-responsive="getProductLogo()" logo-href="/"
      logo-alt="Back to home page">
      <div class="print-registration-confirmation">
        <div class="print-registration-confirmation-main">
          <div class="print-registration-confirmation-container">
            <div class="print-registration-confirmation-exists">
              <h1 class="print-registration-page-title">
                <p>You’ve registered for your </p><p><i>Morningstar {{ currentProduct }}</i></p><p>digital
                  subscription.</p>
              </h1>
            </div>
            <div class="print-registration-final-button">
              <mds-button variation="secondary" type="button" size="large" @click="login()">
                Sign in
              </mds-button>
            </div>
          </div>
        </div>
      </div>
    </mds-page-shell>
  </div>
</template>
<script>
import MdsPageShell from '@mds/page-shell'
import { MdsButton } from "@mds/button";
export default {
  name: "print-registration-confirmation",
  components: { MdsPageShell, MdsButton },
  data() {
    return {
      currentProduct: "",
      productName: "",
      MEIAccess: false,
      MDIAccess: false,
      MSIAccess: false,
      MFIAccess: false,
    };
  },
  methods: {
    login() {
      this.$auth0.loginWithRedirect();
    },
    getProductLogo() {
      var images = require.context(
        "../../assets/Images",
        false,
        /\.svg$/
      );
      return images("./Common.svg");
    },
    async getDataFromlocalStorage() {
      var data = localStorage.getItem("PrintRegistrationDetails");
      if (data != "" && data != null) {
        data = JSON.parse(data);
        {
          if (data.publicationName.toLowerCase() == "fsn") {
            this.currentProduct = "FundInvestor";
            this.productName = 'mfi';
          }
          else if (data.publicationName.toLowerCase() == "msi") {
            this.currentProduct = "StockInvestor";
            this.productName = 'msi';
          }
          else if (data.publicationName.toLowerCase() == "div") {
            this.currentProduct = "DividendInvestor";
            this.productName = 'mdi';
          }
          else if (data.publicationName.toLowerCase() == "etn") {
            this.currentProduct = "ETFInvestor";
            this.productName = 'mei';
          }
        }
      }
    },
  },
  async mounted() {
    window.scrollTo(0, 0);
    this.$store.state.layout.isHeaderRequired = false;
    this.$store.state.layout.isFooterRequired = false;
    await this.getDataFromlocalStorage();
    if (this.$store.state.user.LoggedIn) {
      this.$store.state.user.NewRegister = true;
    }
    var accessProducts = this.$auth0.user['https://morningstar.com/uim_roles'];
    if(accessProducts != undefined && accessProducts !=null) {
          this.MEIAccess = accessProducts.includes("EI_MEMBER");
          this.MDIAccess = accessProducts.includes("DI_MEMBER");
          this.MSIAccess = accessProducts.includes("SI_MEMBER");
          this.MFIAccess = accessProducts.includes("FI_MEMBER");
      }
    if ((this.productName.toLowerCase() == 'mei' && this.MEIAccess) || (this.productName.toLowerCase() == 'mdi' && this.MDIAccess)
      || (this.productName.toLowerCase() == 'msi' && this.MSIAccess) || (this.productName.toLowerCase() == 'mfi' && this.MFIAccess)) {
      if (this.$auth0.isAuthenticated && this.$session.exists()) {
        this.$store.state.layout.isHeaderRequired = true;
        this.$store.state.layout.isFooterRequired = true;
        this.$session.set("current-product", this.productName.toUpperCase());
        this.$store.state.product.CurrentProduct = this.productName.toUpperCase();
        this.$router.push({
          name: 'home',
          params: {
            currentproduct: this.productName.toLowerCase()
          }
        }).catch(err => {
          if (
            err.name !== 'NavigationDuplicated' &&
            !err.message.includes('Avoided redundant navigation to current location')
          ) {
            console.log(err);
          }
        });
    }
    }
  }
};
</script>
<style scoped>
.print-registration-confirmation-container {
  max-width: 710px;
  margin: 0 auto;
  height: 100vh;
}

.print-registration-page-title{
  font-weight: 200;
  padding-top: 200px;
  padding-bottom: 20px;
  opacity: 0.7;
  line-height: 64.469px;
  justify-content: center;
  display: grid;
  align-items: center;
  font-size:60px;
}

.print-registration-confirmation {
  width: 100%;
  margin: 0 auto;
  background: #F2F2F2;
  background-image: url("../../assets/final_confirmation.svg");
  background-repeat: no-repeat;
  background-size: 100%;
}

.print-registration-confirmation-main {
  max-width: 1208px;
  margin: 0 auto;
  height: 100vh;
}

.print-registration-confirmation-exists {
  opacity: 1;
  font-weight: 200;
  font-size: 60px;
  line-height: 64px;
  color: #1e1e1e;
  mix-blend-mode: normal;
}
.print-registration-final-button {
  margin-top: 5%;
  float: right;
  margin-right: 5%;
}
@media only screen and (max-width:480px) {
  .print-registration-confirmation-main {
    width: 100%;
    background: transparent;
  }

  .print-registration-confirmation-container {
    width: 90%;
    margin: 0 auto;
    background: transparent;
  }

  .print-registration-confirmation-exists {
    font-size: 24px;
    line-height: normal;
  }

  .print-registration-page-title{
    font-size: 24px;
    font-weight: 200;
    padding-top: 100px;
    opacity: 0.7;
    line-height: 30px;
    justify-content: center;
    display: grid;
    align-items: center;
}
}
@media only screen and (min-width:481px)and (max-width:504px) {
  .print-registration-confirmation-main {
    width: 100%;
    background: transparent;
  }

  .print-registration-confirmation-container {
    width: 90%;
    margin: 0 auto;
    background: transparent;
  }

  .print-registration-confirmation-exists {
    font-size: 24px;
    line-height: normal;
  }

  .print-registration-page-title {
    font-size: 34px;
    font-weight: 200;
    padding-top: 100px;
    opacity: 0.7;
    line-height: 38px;
    justify-content: center;
    display: grid;
    align-items: center;
  }
}

@media only screen and (min-width:505px)and (max-width:767px) {
  .print-registration-confirmation-main {
    width: 100%;
    background: transparent;
  }

  .print-registration-confirmation-container {
    width: 90%;
    margin: 0 auto;
    background: transparent;
  }

  .print-registration-confirmation-exists {
    font-size: 24px;
    line-height: normal;
  }

  .print-registration-page-title {
    font-size: 42px;
    font-weight: 200;
    padding-top: 200px;
    padding-bottom: 20px;
    opacity: 0.7;
    line-height: 50px;
    justify-content: center;
    display: grid;
    align-items: center;
  }
}
</style>