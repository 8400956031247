/**
 *  External Modules
 */

import Vue from "vue";
import { createAuth0Client } from "@auth0/auth0-spa-js";

/**
 *  Vue.js Instance Definition
 */

let instance;

export const getInstance = () => instance;

/**
 *  Vue.js Instance Initialization
 */

export const useAuth0 = ({
  onRedirectCallback = () =>
    window.history.replaceState({}, document.title, window.location.pathname),
  redirectUri = window.location.origin,
  ...pluginOptions
}) => {
  if (instance) return instance;

  instance = new Vue({
    data() {
      return {
        auth0Client: null,
        isLoading: true,
        isAuthenticated: false,
        user: {},
        error: null,
      };
    },
    methods: {
      loginWithRedirect() {
        this.$session.start();
        this.auth0Client.loginWithRedirect({ appState: { targetPath: window.location.pathname } });
      },

      logout() {
        this.$store.state.product.CurrentProduct = "";
        this.$store.state.product.SelectedProductBanner = "";
        this.$store.state.access.PageNotFound = false;
        this.$auth0.isAuthenticated = false;
        this.$store.state.user.LoggedIn = false;
        this.$store.state.product.ProductOrderedArray = [];
        this.$store.state.article.ArticleCounter = 0;
        this.$store.state.holding.CurrentSecID = "";
        this.$store.state.holding.CurrentPortfolioName = "";
        this.$store.state.product.OmahaCustomer = false;
        this.$store.state.product.SuccessAlert =false;
        this.$store.state.product.ErrorAlert =false;
        this.$store.state.product.lstAlertMsg=[];
        this.$session.destroy();
        localStorage.setItem("SelectedProductVideoTranscript", "");
        localStorage.setItem("MoreVideosSelectedProduct", "");
        localStorage.removeItem("userDetails");
        localStorage.removeItem("MFIProductSubscription");
        localStorage.removeItem("MSIProductSubscription");
        localStorage.removeItem("MDIProductSubscription");
        localStorage.removeItem("MEIProductSubscription");
        localStorage.removeItem("ChaseErrorCode");
        localStorage.removeItem("CompleteChasePaymentData");
        localStorage.removeItem("PrintRegistrationDetails");
        localStorage.setItem("userType_MFI", "");
        localStorage.setItem("userType_MSI", "");
        localStorage.setItem("userType_MDI", "");
        localStorage.setItem("userType_ETF", "");
        localStorage.setItem("SearchVideo", "");
        localStorage.setItem("SearchVideoTitle", "");
        return this.auth0Client.logout({
          logoutParams: {
            returnTo: process.env.VUE_APP_AUTH0_REDIRECT
          }
        });
      },

      getAccessTokenSilently(o) {
        return this.auth0Client.getTokenSilently(o);
      },
    },

    async created() {
      this.auth0Client = await createAuth0Client({
        ...pluginOptions,
        domain: pluginOptions.domain,
        clientId: pluginOptions.clientId,
        authorizationParams: {
          audience: pluginOptions.audience,
          redirect_uri: redirectUri, 
          source: pluginOptions.source
        },
      });

      try {
        const search = window.location.search;

        if (
          (search.includes("code=") || search.includes("error=")) &&
          search.includes("state=")
        ) {
          const { appState } = await this.auth0Client.handleRedirectCallback();

          onRedirectCallback(appState);
        }
      } catch (error) {
        this.error = error;
      } finally {
        this.isAuthenticated = await this.auth0Client.isAuthenticated();
        this.user = await this.auth0Client.getUser();
        this.isLoading = false;
      }
    },
  });

  return instance;
};

/**
 *  Vue.js Plugin Definition
 */

export const Auth0Plugin = {
  install(Vue, options) {
    Vue.prototype.$auth0 = useAuth0(options);
  },
};
