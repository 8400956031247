<template>
    <div class="page-not-found">
        <div class="page-not-found-message-container">
            <div class="page-not-found-content">
                <h1 class="page-not-found-error">404 ERROR</h1>
                <div class="page-not-found-message-heading">This page may have moved or it no longer exists.</div>
                <div class="page-not-found-message-link"> Head to <span @click="pageNotFoundRedirect">Morningstar Investor Newsletters</span> or try entering the URL again.</div>
            </div>
        </div>
  </div>
</template>

<script>
export default {
    name: "page-not-found",
    methods:{
      pageNotFoundRedirect(){
        this.$router.push({
        name: "home",
        params: {
          currentproduct: this.$session.get("current-product")
        },
      }).catch(err => {
        if (
          err.name !== 'NavigationDuplicated' &&
          !err.message.includes('Avoided redundant navigation to current location')
        ) {
          console.log(err);
        }
      });
      }
    }
}
</script>
<style scoped>
  .page-not-found {
    width:100%;
    background:#f2f2f2;
    margin:0px;
    padding:0px;
    min-height: calc(100vh - 50px);
  }
  .page-not-found-message-container{
    width:100%;
    padding-top:50px;

  }
  .page-not-found-content{
    width:900px;
    padding:10px;
    margin:auto;
    padding:auto;
  }
  .page-not-found-error{
    font-size: 14px;
    letter-spacing: 1.67px;
    line-height: 12px;
    margin-bottom:30px;
  }
  .page-not-found-message-heading{
    font-size:70px;
    font-style:normal;
    font-weight:600;
    margin-bottom:24px;
  }
  .page-not-found-message-link{
    font-style: normal;
    font-size: 32px;
    font-weight: 200;
    line-height: 36px;
  }
  .page-not-found-message-link span{
    color: #5e5e5e;
    font-weight: inherit;
    outline:0;
    text-decoration:none;
    border-bottom: 1px solid #ababab;
    font-style: inherit;
  }
  .page-not-found-message-link span:hover{
    border-color: #006fba;
    color: #006fba
  }
</style>