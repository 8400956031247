<template lang="html">
  <div class="marketing-landing">
    <div
      :class="'marketing-landing-top-hero-image-container-main marketing-hero-image-background-gradient-' + this.productName.toLowerCase()">
      <div class="marketing-landing-top-hero-image-container">
        <div class="marketing-landing-top-hero-image-container-left">
          <div class="marketing-landing-top-hero-image-container-left-content">
            <div class="marketing-landing-hero-image-title">
              <h1>{{ marketingData[0].title }}</h1>
            </div>
            <div class="marketing-landing-hero-image-subtitle">
              <h2>{{ marketingData[0].subTitle }}</h2>
            </div>
            <div class="marketing-landing-hero-image-tagline">
              <h3>{{ marketingData[0].tagLine }}</h3>
            </div>
            <mds-button id="hero" :target="productName" variation="primary" size="large"
              @click="redirectToRegistration(productName)">
              Subscribe
            </mds-button>
          </div>
        </div>
        <div class="marketing-landing-top-hero-image-container-right">
          <div class="marketing-landing-top-hero-image-container-right-image-container">
            <img :src="marketingData[0].productImageSrc" :alt="marketingData[0].imageAlternativeText" />
          </div>
        </div>
      </div>
    </div>
    <div class="marketing-landing-product-info-text-container">
      <span v-html="marketingData[0].productInfoText"></span>
    </div>
    <div
      :class="'marketing-landing-product-features-container marketing-product-features-background-' + this.productName.toLowerCase()">
      <div class="marketing-landing-product-features-wrapper">
        <div class="marketing-landing-product-features-heading">
          <h2>What’s included</h2>
        </div>
        <mds-layout-grid gutters="none">
          <mds-row class="marketing-landing-product-features-row">
            <mds-col :cols="12" :cols-at-s="12" :cols-at-m="6" :cols-at-l="3" :cols-at-xl="3"
              class="marketing-landing-product-features-col">
              <div class="marketing-landing-product-features-icon-container">
                <img :src="marketingData[0].productFeatureImgageSrcOne" alt="Product Feature Icon" />
              </div>
              <div class="marketing-landing-product-features-text">{{ marketingData[0].productFeatureTextOne }}</div>
            </mds-col>
            <mds-col :cols="12" :cols-at-s="12" :cols-at-m="6" :cols-at-l="3" :cols-at-xl="3"
              class="marketing-landing-product-features-col">
              <div class="marketing-landing-product-features-icon-container">
                <img :src="marketingData[0].productFeatureImgageSrcTwo" alt="Product Feature Icon" />
              </div>
              <div class="marketing-landing-product-features-text">{{ marketingData[0].productFeatureTextTwo }}</div>
            </mds-col>
            <mds-col :cols="12" :cols-at-s="12" :cols-at-m="6" :cols-at-l="3" :cols-at-xl="3"
              class="marketing-landing-product-features-col">
              <div class="marketing-landing-product-features-icon-container">
                <img :src="marketingData[0].productFeatureImgageSrcThree" alt="Product Feature Icon" />
              </div>
              <div class="marketing-landing-product-features-text">{{ marketingData[0].productFeatureTextThree }}</div>
            </mds-col>
            <mds-col :cols="12" :cols-at-s="12" :cols-at-m="6" :cols-at-l="3" :cols-at-xl="3"
              class="marketing-landing-product-features-col">
              <div class="marketing-landing-product-features-icon-container">
                <img :src="marketingData[0].productFeatureImgageSrcFour" alt="Product Feature Icon" />
              </div>
              <div class="marketing-landing-product-features-text">{{ marketingData[0].productFeatureTextFour }}</div>
            </mds-col>
          </mds-row>
        </mds-layout-grid>
      </div>
    </div>
    <div class="marketing-landing-product-image-and-description-container">
      <mds-layout-grid gutters="none">
        <mds-row class="marketing-landing-product-image-description-row">
          <mds-col :cols="12" :cols-at-s="12" :cols-at-m="12" :cols-at-l="6" :cols-at-xl="6"
            class="marketing-landing-product-image-description-col">
            <img :src="marketingData[0].productDescriptionImageSrc"
              :alt="marketingData[0].title + ' Product Description Image'" />
          </mds-col>
          <mds-col :cols="12" :cols-at-s="12" :cols-at-m="12" :cols-at-l="6" :cols-at-xl="6"
            class="marketing-landing-product-image-description-col">
            <div class="marketing-landing-product-description-container">
              <div class="marketing-landing-product-description-heading">
                <h2>{{ marketingData[0].productDescriptionHeading }}</h2>
              </div>
              <div style="height:1px;background-color:#CB5C43;width:66px;margin-top:40px;margin-bottom:24px;"
                v-if="productName.toUpperCase() == 'MFI'"></div>
              <div class="marketing-landing-product-description">
                <h3 v-html="marketingData[0].productDescription"></h3>
              </div>
              <div v-if="productName.toUpperCase() != 'MFI'"
                :class="'marketing-landing-product-portfolios-description-container-' + this.productName.toLowerCase()">
                <ol v-for="(portfolioDescList, index) in marketingData[0].productPortfolioList" :key="index"
                  class="marketing-landing-product-portfolios-description">
                  <li>
                    <h3>{{ portfolioDescList.portfolioTitle }}</h3>
                  </li>
                  <li class="portfolio-description-list">{{ portfolioDescList.protfolioDescription }}</li>
                </ol>
              </div>
            </div>
          </mds-col>
        </mds-row>
      </mds-layout-grid>
    </div>
    <div
      :class="'marketing-landing-additional-subscription-container marketing-landing-additional-subscription-background-' + this.productName.toLowerCase()">
      <div class="marketing-landing-additional-subscription-wrapper">
        <div class="marketing-landing-additional-subscription-heading">
          <h2>Additional subscription features</h2>
        </div>
        <div class="marketing-landing-additional-subscription-data-wrapper">
          <mds-layout-grid>
            <mds-row class="marketing-landing-additional-subscription-row">
              <mds-col :cols="12" :cols-at-s="12" :cols-at-m="12" :cols-at-l="6" :cols-at-xl="6"
                style="padding-left:0px;" class="marketing-landing-additional-subscription-col">
                <div class="marketing-landing-additional-subscription-content">
                  <img :src="marketingData[0].checkMarkImage"
                    class="marketing-landing-additional-subscription-check-icon" />
                  <div class="marketing-landing-additional-subscription-benefits-text">
                    <span>Analyst research on trending topics</span>
                  </div>
                </div>
                <div class="marketing-landing-additional-subscription-border-seperator"></div>
                <div class="marketing-landing-additional-subscription-content">
                  <img :src="marketingData[0].checkMarkImage"
                    class="marketing-landing-additional-subscription-check-icon" />
                  <div class="marketing-landing-additional-subscription-benefits-text">
                    <span>Morningstar watchlists that monitor securities that could potentially fit this strategy</span>
                  </div>
                </div>
                <div class="marketing-landing-additional-subscription-border-seperator"></div>
                <div class="marketing-landing-additional-subscription-content">
                  <img :src="marketingData[0].checkMarkImage"
                    class="marketing-landing-additional-subscription-check-icon" />
                  <div class="marketing-landing-additional-subscription-benefits-text">
                    <span>Bonus reports to make the most of your monthly subscription</span>
                  </div>
                </div>
                <div
                  class="marketing-landing-additional-subscription-border-seperator marketing-landing-additional-subscription-last-border">
                </div>
              </mds-col>
              <mds-col :cols="12" :cols-at-s="12" :cols-at-m="12" :cols-at-l="6" :cols-at-xl="6"
                class="marketing-landing-additional-subscription-col">
                <div class="marketing-landing-additional-subscription-content">
                  <img :src="marketingData[0].checkMarkImage"
                    class="marketing-landing-additional-subscription-check-icon" />
                  <div class="marketing-landing-additional-subscription-benefits-text">
                    <span>Email alerts for new issues and breaking fund-industry news</span>
                  </div>
                </div>
                <div class="marketing-landing-additional-subscription-border-seperator"></div>
                <div class="marketing-landing-additional-subscription-content">
                  <img :src="marketingData[0].checkMarkImage"
                    class="marketing-landing-additional-subscription-check-icon" />
                  <div class="marketing-landing-additional-subscription-benefits-text">
                    <span>Subscriber-only website featuring current data and complete archives</span>
                  </div>
                </div>
                <div class="marketing-landing-additional-subscription-border-seperator"></div>
                <div class="marketing-landing-additional-subscription-content">
                  <img :src="marketingData[0].checkMarkImage"
                    class="marketing-landing-additional-subscription-check-icon" />
                  <div class="marketing-landing-additional-subscription-benefits-text">
                    <span>Editor access for questions and commentary</span>
                  </div>
                </div>
              </mds-col>
            </mds-row>
          </mds-layout-grid>
        </div>
      </div>
    </div>
    <div
      :class="'marketing-landing-subscription-option-container marketing-landing-subscription-background-' + this.productName.toLowerCase()">
      <div class="marketing-landing-subscription-option-wrapper">
        <div class="marketing-landing-additional-subscription-heading">
          <h2>Digital subscription options</h2>
        </div>
        <div class="marketing-landing-subscription-option-data-wrapper">
          <mds-layout-grid>
            <mds-row class="marketing-landing-subscription-option-data-row">
              <mds-col :cols="12" :cols-at-s="12" :cols-at-m="6" :cols-at-l="6" :cols-at-xl="6"
                class="marketing-landing-subscription-option-data-col">
                <div class="marketing-landing-subscription-option annual-subscription-container">
                  <div class="marketing-landing-subscription-option-subscription-top-header annual-subscription">
                    <h3>Annual</h3>
                  </div>
                  <div class="marketing-landing-subscription-price">{{ marketingData[0].annualSubscriptionPrice }}</div>
                </div>
              </mds-col>
              <mds-col :cols="12" :cols-at-s="12" :cols-at-m="6" :cols-at-l="6" :cols-at-xl="6"
                class="marketing-landing-subscription-option-data-col">
                <div class="marketing-landing-subscription-option">
                  <div class="marketing-landing-subscription-option-subscription-top-header quarterly-subscription">
                    <h3>Quarterly</h3>
                  </div>
                  <div class="marketing-landing-subscription-price">{{ marketingData[0].quarterlySubscriptionPrice }}
                  </div>
                </div>
              </mds-col>
            </mds-row>
          </mds-layout-grid>
        </div>
        <div class="marketing-landing-subscription-option-subscribe-button-container">
          <mds-button id="pricing" :target="productName" variation="primary" size="large"
            @click="redirectToRegistration(productName)">
            Subscribe
          </mds-button>
        </div>
        <div class="marketing-landing-subscription-option-print-subscription-text">
          For annual print subscriptions ({{ marketingData[0].printSubscriptionPrice }}) please call 1-866-608-9570
          (Monday-Friday 8AM–5PM CST).
        </div>
      </div>
    </div>
    <div class="marketing-landing-more-newsletters-container">
      <div class="marketing-landing-additional-subscription-heading">
        <h2>More newsletters to complement your investment strategy</h2>
      </div>
      <div class="marketing-landing-more-newsletters-product-container">
        <mds-layout-grid>
          <mds-row class="marketing-landing-more-newsletters-product-container-row">
            <mds-col v-for="(moreProductList, index) in marketingData[0].moreNewsletterList" :key="index" :cols="12"
              :cols-at-s="12" :cols-at-m="6" :cols-at-l="4" :cols-at-xl="4"
              class="marketing-landing-more-newsletters-product-container-col">
              <img :src="moreProductList.productDisplayBanner" :alt="moreProductList.productDisplayName + ' Image'" />
              <div class="marketing-landing-more-newsletters-product-name">{{ moreProductList.productDisplayName }}</div>
              <div class="marketing-landing-more-newsletters-subscribe-button">
                <mds-button id="footer" :target="productName" variation="primary" size="large"
                  @click="changeProduct(moreProductList.productSubscribeCode)">
                  Learn More
                </mds-button>
              </div>
            </mds-col>
          </mds-row>
        </mds-layout-grid>
      </div>
    </div>
  </div>
</template>

<script lang="js">
import { MdsButton } from "@mds/button";
import { MdsLayoutGrid, MdsRow, MdsCol } from '@mds/layout-grid'
export default {
  name: 'marketing-landing',
  props: [],
  components: {
    MdsButton,
    MdsLayoutGrid,
    MdsRow,
    MdsCol,
  },
  mounted() {
    this.$store.state.marketing.isMarketing = true;
    if (this.$auth0.isAuthenticated && this.$session.exists()) {
      var accessProducts = this.$auth0.user['https://morningstar.com/uim_roles'];
      if (accessProducts != undefined && accessProducts != null) {
        this.MEIAccess = accessProducts.includes("EI_MEMBER");
        this.MDIAccess = accessProducts.includes("DI_MEMBER");
        this.MSIAccess = accessProducts.includes("SI_MEMBER");
        this.MFIAccess = accessProducts.includes("FI_MEMBER");
      }
      if ((this.productName.toLowerCase() == 'mei' && this.MEIAccess) || (this.productName.toLowerCase() == 'mdi' && this.MDIAccess)
        || (this.productName.toLowerCase() == 'msi' && this.MSIAccess) || (this.productName.toLowerCase() == 'mfi' && this.MFIAccess)) {
        this.$session.set("current-product", this.productName.toUpperCase());
        this.$store.state.product.CurrentProduct = this.productName.toUpperCase();
        this.$store.state.marketing.isMarketing = false;
        this.$router.push({
          name: 'home',
          params: {
            currentproduct: this.productName.toLowerCase()
          }
        }).catch(err => {
          if (
            err.name !== 'NavigationDuplicated' &&
            !err.message.includes('Avoided redundant navigation to current location')
          ) {
            console.log(err);
          }
        });
      }
      else {
        window.scrollTo(0, 0);
        this.$session.set("current-product", "Common");
        this.$store.state.product.CurrentProduct = "Common";
      }
    }
    else {
      window.scrollTo(0, 0);
      this.$session.set("current-product", "Common");
      this.$store.state.product.CurrentProduct = "Common";
    }

  },
  data() {
    return {
      MEIAccess: false,
      MDIAccess: false,
      MSIAccess: false,
      MFIAccess: false,
      productName: null,
      marketingData: [],
      referId: null,
      productcode: null,
      mfiMarketingData: [
        {
          title: "Morningstar FundInvestor",
          subTitle: "Mutual Fund Research Demystified by Morningstar",
          tagLine: "We help you understand the ins and outs of mutual fund investments.",
          productImageSrc: require("../../assets/Images/MarketingLandingImages/MFI/Covers/FundInvestorCover.png"),
          imageAlternativeText: "FundInvestor Cover Image",
          productInfoText: "The <i>Morningstar FundInvestor</i> newsletter delivers mutual fund intelligence straight to you—so you can steward your investments accordingly.",
          productFeatureImgageSrcOne: require("../../assets/Images/MarketingLandingImages/MFI/Icons/MFI_handbook_icon.svg"),
          productFeatureImgageSrcTwo: require("../../assets/Images/MarketingLandingImages/MFI/Icons/MFI_database_icon.svg"),
          productFeatureImgageSrcThree: require("../../assets/Images/MarketingLandingImages/MFI/Icons/MFI_analysis_icon.svg"),
          productFeatureImgageSrcFour: require("../../assets/Images/MarketingLandingImages/MFI/Icons/MFI_report.svg"),
          productFeatureTextOne: "Comprehensive handbook on Morningstar’s approach to mutual fund investing",
          productFeatureTextTwo: "Searchable database of our star ratings and other criteria to see how your funds measure up",
          productFeatureTextThree: "Our editor’s analysis on breaking fund-industry news and events, delivered to your inbox",
          productFeatureTextFour: "One-page Analyst Reports for the FundInvestor 500, our curated list of top-performing funds",
          productDescriptionImageSrc: require("../../assets/Images/MarketingLandingImages/MFI/Product_Images/MFI_product_images.png"),
          productDescriptionHeading: "Meet the FundInvestor 500",
          productDescription: "To create the FundInvestor 500, we scrutinized trading costs, management trends, and long-term performance of more than 7,000 funds to bring you 500 funds to keep on your radar. Our ongoing analysis of these top funds helps guide your strategy for the long term.",
          checkMarkImage: require("../../assets/Images/MarketingLandingImages/MFI/Icons/MFI_checkmark.svg"),
          annualSubscriptionPrice: "$170",
          quarterlySubscriptionPrice: "$53.95",
          printSubscriptionPrice: "$190",
          moreNewsletterList: [
            {
              "productDisplayBanner": require("../../assets/Images/MarketingLandingImages/MFI/Covers/StockInvestorCover.png"),
              "productDisplayName": "Morningstar StockInvestor",
              "productSubscribeCode": "msi",
            },
            {
              "productDisplayBanner": require("../../assets/Images/MarketingLandingImages/MEI/Covers/ETFInvestorCover.png"),
              "productDisplayName": "Morningstar ETFInvestor",
              "productSubscribeCode": "mei",
            },
            {
              "productDisplayBanner": require("../../assets/Images/MarketingLandingImages/MDI/Covers/DividendInvestorCover.png"),
              "productDisplayName": "Morningstar DividendInvestor",
              "productSubscribeCode": "mdi",
            },

          ]
        }
      ],
      msiMarketingData: [
        {
          title: "Morningstar StockInvestor",
          subTitle: "Explore Durable Stocks Trading at a Discount",
          tagLine: "We'll help you seek out companies with economic moats that are trading at discounts to their intrinsic values.",
          productImageSrc: require("../../assets/Images/MarketingLandingImages/MSI/Covers/StockInvestorCover.png"),
          imageAlternativeText: "StockInvestor Cover Image",
          productInfoText: "The <i>Morningstar StockInvestor</i> newsletter highlights companies with competitive advantages that our analysts believe are undervalued.",
          productFeatureImgageSrcOne: require("../../assets/Images/MarketingLandingImages/MSI/Icons/MSI_handbook_icon.svg"),
          productFeatureImgageSrcTwo: require("../../assets/Images/MarketingLandingImages/MSI/Icons/MSI_analysis_icon.svg"),
          productFeatureImgageSrcThree: require("../../assets/Images/MarketingLandingImages/MSI/Icons/MSI_commentary_icon.svg"),
          productFeatureImgageSrcFour: require("../../assets/Images/MarketingLandingImages/MSI/Icons/MSI_outlook_icon.svg"),
          productFeatureTextOne: "Comprehensive handbook on Morningstar’s approach to investing in stocks",
          productFeatureTextTwo: "Independent professional analysis in every monthly issue",
          productFeatureTextThree: "Commentary on market trends, delivered weekly via email",
          productFeatureTextFour: "Quarterly equity market outlook reports to help sharpen your strategy",
          productDescriptionImageSrc: require("../../assets/Images/MarketingLandingImages/MSI/Product_Images/MSI_product_images.png"),
          productDescriptionHeading: "Analysis based on model portfolios",
          productDescription: "The portfolios demonstrate the investing philosophies outlined in <em>Morningstar StockInvestor</em> so you can see them in action.",
          productPortfolioList: [
            {
              "portfolioTitle": "Tortoise portfolio",
              "protfolioDescription": "Focused on high-quality businesses with both strong balance sheets and competitive advantages",
            },
            {
              "portfolioTitle": "Hare portfolio",
              "protfolioDescription": "Built around what we believe are fast-growing companies with continuously improving prospects",
            }

          ],
          checkMarkImage: require("../../assets/Images/MarketingLandingImages/MSI/Icons/MSI_checkmark.svg"),
          annualSubscriptionPrice: "$170",
          quarterlySubscriptionPrice: "$53.95",
          printSubscriptionPrice: "$190",
          moreNewsletterList: [
            {
              "productDisplayBanner": require("../../assets/Images/MarketingLandingImages/MFI/Covers/FundInvestorCover.png"),
              "productDisplayName": "Morningstar FundInvestor",
              "productSubscribeCode": "mfi",
            },
            {
              "productDisplayBanner": require("../../assets/Images/MarketingLandingImages/MEI/Covers/ETFInvestorCover.png"),
              "productDisplayName": "Morningstar ETFInvestor",
              "productSubscribeCode": "mei",
            },
            {
              "productDisplayBanner": require("../../assets/Images/MarketingLandingImages/MDI/Covers/DividendInvestorCover.png"),
              "productDisplayName": "Morningstar DividendInvestor",
              "productSubscribeCode": "mdi",
            },

          ]
        }
      ],
      mdiMarketingData: [
        {
          title: "Morningstar DividendInvestor",
          subTitle: "Make Decisions That Could Pay Dividends",
          tagLine: "DividendInvestor provides you with information about dividend-paying stocks to help inform your investing decisions.",
          productImageSrc: require("../../assets/Images/MarketingLandingImages/MDI/Covers/DividendInvestorCover.png"),
          imageAlternativeText: "DividendInvestor Cover Image",
          productInfoText: "The <i>Morningstar DividendInvestor</i> newsletter highlights dividend-paying companies that we believe are well-managed and trading at reasonable prices.",
          productFeatureImgageSrcOne: require("../../assets/Images/MarketingLandingImages/MDI/Icons/MDI_guide_icon.svg"),
          productFeatureImgageSrcTwo: require("../../assets/Images/MarketingLandingImages/MDI/Icons/MDI_insights_icon.svg"),
          productFeatureImgageSrcThree: require("../../assets/Images/MarketingLandingImages/MDI/Icons/MDI_reports_icon.svg"),
          productFeatureImgageSrcFour: require("../../assets/Images/MarketingLandingImages/MDI/Icons/MDI_watchlist_icon.svg"),
          productFeatureTextOne: "Morningstar’s comprehensive guide to dividend investing",
          productFeatureTextTwo: "Ongoing insights from our editor to guide your dividend-investing strategy",
          productFeatureTextThree: "The Week in Dividends reports for all the latest analysis",
          productFeatureTextFour: "Our Income Bellwethers watchlist that highlights dividend-paying stocks with above-average returns",
          productDescriptionImageSrc: require("../../assets/Images/MarketingLandingImages/MDI/Product_Images/MDI_product_images.png"),
          productDescriptionHeading: "Analysis based on model portfolios",
          productDescription: "Morningstar, Inc.’s portfolios demonstrate the investing philosophies outlined in <em>Morningstar DividendInvestor</em> so you can see them in action.",
          productPortfolioList: [
            {
              "portfolioTitle": "Dividend Select portfolio",
              "protfolioDescription": "Takes a concentrated, best-ideas approach when investing in select common stocks of dividend-paying companies and other securities such as American depositary receipts, master limited partnerships, and real estate investment trusts.",
            },
            {
              "portfolioTitle": "Dividend Select Deferred portfolio",
              "protfolioDescription": "A portfolio with the same objectives as the Dividend Select portfolio, but is more suitable for tax-deferred accounts",
            }

          ],
          checkMarkImage: require("../../assets/Images/MarketingLandingImages/MDI/Icons/MDI_checkmark.svg"),
          annualSubscriptionPrice: "$239",
          quarterlySubscriptionPrice: "$70.95",
          printSubscriptionPrice: "$259",
          moreNewsletterList: [
            {
              "productDisplayBanner": require("../../assets/Images/MarketingLandingImages/MFI/Covers/FundInvestorCover.png"),
              "productDisplayName": "Morningstar FundInvestor",
              "productSubscribeCode": "mfi",
            },
            {
              "productDisplayBanner": require("../../assets/Images/MarketingLandingImages/MDI/Covers/MsiCover.png"),
              "productDisplayName": "Morningstar StockInvestor",
              "productSubscribeCode": "msi",
            },
            {
              "productDisplayBanner": require("../../assets/Images/MarketingLandingImages/MEI/Covers/ETFInvestorCover.png"),
              "productDisplayName": "Morningstar ETFInvestor",
              "productSubscribeCode": "mei",
            },

          ]
        }
      ],
      meiMarketingData: [
        {
          title: "Morningstar ETFInvestor",
          subTitle: "In-Depth Analysis of Exchange-Traded Funds",
          tagLine: "Our mix of academic and practitioner research is designed to help you seek top-performing ETFs.",
          productImageSrc: require("../../assets/Images/MarketingLandingImages/MEI/Covers/ETFInvestorCover.png"),
          imageAlternativeText: "ETFInvestor Cover Image",
          productInfoText: "The <i>Morningstar ETFInvestor</i> newsletter scans the globe for promising investment opportunities.",
          productFeatureImgageSrcOne: require("../../assets/Images/MarketingLandingImages/MEI/Icons/MEI_handbook_icon.svg"),
          productFeatureImgageSrcTwo: require("../../assets/Images/MarketingLandingImages/MEI/Icons/MEI_model_icon.svg"),
          productFeatureImgageSrcThree: require("../../assets/Images/MarketingLandingImages/MEI/Icons/MEI_watchlist_icon.svg"),
          productFeatureImgageSrcFour: require("../../assets/Images/MarketingLandingImages/MEI/Icons/MEI_analysis_icon.svg"),
          productFeatureTextOne: "Comprehensive handbook on Morningstar’s approach to investing in ETFs",
          productFeatureTextTwo: "A deep dive into a model portfolio every month",
          productFeatureTextThree: "Stock and bond watchlists of Morningstar-analyzed ETFs, along with other notable ETFs to keep on your radar",
          productFeatureTextFour: "Timely, in-depth analysis of individual ETFs and market segments in each issue",
          productDescriptionImageSrc: require("../../assets/Images/MarketingLandingImages/MEI/Product_Images/MEI_product_images.png"),
          productDescriptionHeading: "Analysis based on model portfolios",
          productDescription: "The model portfolios demonstrate the investing philosophies outlined in <em>Morningstar ETFInvestor</em> so you can see them in action.",
          productPortfolioList: [
            {
              "portfolioTitle": "Basic portfolio",
              "protfolioDescription": "Built on low-cost funds and harnessing the market's collective wisdom; this is the baseline against which the other model portfolios are compared.",
            },
            {
              "portfolioTitle": "Defensive portfolio",
              "protfolioDescription": "Seeks long-term, risk-adjusted performance, designed to provide better downside protection",
            },
            {
              "portfolioTitle": "Factor portfolio",
              "protfolioDescription": "Geared toward funds historically associated with higher returns",
            },
            {
              "portfolioTitle": "Income portfolio",
              "protfolioDescription": "Aimed at a higher distribution yield while avoiding unnecessary risk",
            },
            {
              "portfolioTitle": "ESG portfolio",
              "protfolioDescription": "Prioritizes firms that we believe have sustainable practices, strong corporate governance, and competitive returns",
            },
          ],
          checkMarkImage: require("../../assets/Images/MarketingLandingImages/MEI/Icons/MEI_checkmark.svg"),
          annualSubscriptionPrice: "$239",
          quarterlySubscriptionPrice: "$70.95",
          printSubscriptionPrice: "$259",
          moreNewsletterList: [
            {
              "productDisplayBanner": require("../../assets/Images/MarketingLandingImages/MFI/Covers/FundInvestorCover.png"),
              "productDisplayName": "Morningstar FundInvestor",
              "productSubscribeCode": "mfi",
            },
            {
              "productDisplayBanner": require("../../assets/Images/MarketingLandingImages/MEI/Covers/StockInvestorCover.png"),
              "productDisplayName": "Morningstar StockInvestor",
              "productSubscribeCode": "msi",
            },
            {
              "productDisplayBanner": require("../../assets/Images/MarketingLandingImages/MDI/Covers/DividendInvestorCover.png"),
              "productDisplayName": "Morningstar DividendInvestor",
              "productSubscribeCode": "mdi",
            },

          ]
        }
      ],
    }
  },
  methods: {
    getReferID(ProductName) {
      var referid = '';
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      if (urlParams.has('referid')) {
        referid = urlParams.get('referid');
      }
      if (referid != "")
        return referid;
      else if (ProductName.toUpperCase() == 'MEI')
        return "ETFGEN1";
      else if (ProductName.toUpperCase() == 'MDI')
        return "ADIGRDG7";
      else if (ProductName.toUpperCase() == 'MSI')
        return "AMSGRDG7";
      else if (ProductName.toUpperCase() == 'MFI')
        return "AFNGRDG7";
    },
    getProductCode(ProductName) {
      if (ProductName.toUpperCase() == 'MEI')
        return "NET0000001";
      else if (ProductName.toUpperCase() == 'MDI')
        return "NDI0000001";
      else if (ProductName.toUpperCase() == 'MSI')
        return "NM00000001";
      else if (ProductName.toUpperCase() == 'MFI')
        return "NF00000001";
    },
    redirectToRegistration(productType) {
      var productCode = this.getProductCode(productType);
      var productReferId = this.getReferID(productType);
      this.$router.push({
        name: 'purchase',
        params: {
          referid: productReferId,
          productcode: productCode
        },
      })
    },
    changeProduct(product) {
      this.$router.push({
        name: 'marketing-landing',
        params: {
          currentproduct: product
        },
      })
    },
    populateMarketingData() {
      if (this.productName.toUpperCase() == 'MFI') {
        this.marketingData = this.mfiMarketingData;
      }
      else if (this.productName.toUpperCase() == 'MSI') {
        this.marketingData = this.msiMarketingData;
      }
      else if (this.productName.toUpperCase() == 'MDI') {
        this.marketingData = this.mdiMarketingData;
      }
      else if (this.productName.toUpperCase() == 'MEI') {
        this.marketingData = this.meiMarketingData;
      }
    }
  },
  watch: {
    "$route.params.currentproduct"(value) {
      this.productName = value;
      this.populateMarketingData();
      window.scrollTo(0, 0);
    },
  },
  created() {
    this.productName = this.$route.params.currentproduct;
    this.populateMarketingData();
  }
}
</script>

<style scoped>
.marketing-landing {
  max-width: 1280px;
  margin: 0 auto;
}

.marketing-landing-top-hero-image-container-main {
  width: 100vw;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  height: 577px;
}

.marketing-landing-top-hero-image-container {
  max-width: 1280px;
  margin: 0 auto;
  height: 100%;
  display: flex;
}

.marketing-landing-top-hero-image-container-left {
  width: 50%;
}

.marketing-landing-top-hero-image-container-right {
  width: 50%;
}

.marketing-landing-top-hero-image-container-left-content {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.marketing-landing-top-hero-image-container-right-image-container {
  width: 430px;
  height: 566px;
  float: right;
  margin-top: 56px;
}

.marketing-landing-top-hero-image-container-right-image-container img {
  width: 100%;
  height: 100%;
}

.marketing-landing-hero-image-title h1 {
  color: #FFF;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 16px;
}

.marketing-landing-hero-image-subtitle {
  width: 585px;
  margin-bottom: 32px;
}

.marketing-landing-hero-image-subtitle h2 {
  color: #FFF;
  font-size: 64px;
  font-style: normal;
  font-weight: 250;
  line-height: 64px;
}

.marketing-landing-hero-image-tagline {
  width: 448px;
  margin-bottom: 32px;
}

.marketing-landing-hero-image-tagline h3 {
  color: #FFF;
  font-size: 20px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}

.marketing-landing-product-info-text-container {
  background-color: #FFF;
  ;
  display: flex;
  width: 100%;
  height: 290px;
  color: #000;
  text-align: center;
  font-size: 45px;
  font-style: normal;
  font-weight: 250;
  line-height: 49px;
  justify-content: center;
  align-items: center;
}

.marketing-landing-product-features-container {
  background-color: rgba(226, 187, 102, 0.08);
  padding-bottom: 96px;
  width: 100vw;
  left: 50%;
  position: relative;
  transform: translateX(-50%);
}

.marketing-landing-product-features-wrapper {
  max-width: 1280px;
  margin: 0 auto;
}

.marketing-landing-product-features-heading {
  padding-top: 64px;
  margin-bottom: 40px;
}

.marketing-landing-product-features-heading h2 {
  color: #000;
  text-align: center;
  font-size: 45px;
  font-style: normal;
  font-weight: 250;
  line-height: 45px;
}

.marketing-landing-product-features-row {
  margin: 0px;
}

.marketing-landing-product-features-col {
  margin-right: 19px;
}

.marketing-landing-product-features-icon-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.marketing-landing-product-features-icon-container img {
  width: 142px
}

.marketing-landing-product-features-text {
  color: #1E1E1E;
  text-align: center;
  font-size: 23px;
  font-style: normal;
  font-weight: 300;
  line-height: 27px;
}

.marketing-landing-product-image-and-description-container {
  background: #FFF;
  padding-top: 64px;
  padding-bottom: 96px;
}

.marketing-landing-product-description-container {
  margin-left: 70px;
}

.marketing-landing-product-image-description-row {
  margin: 0px;
}

.marketing-landing-product-image-description-col img {
  width: 100%;
  filter: drop-shadow(0 0 1.1rem rgba(0, 0, 0, 0.10));
}

.marketing-landing-product-description-heading h2 {
  color: #000;
  font-size: 45px;
  font-style: normal;
  font-weight: 250;
  line-height: 45px;
  margin-bottom: 24px;
}

.marketing-landing-product-description h3 {
  color: #333;
  font-size: 23px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}

.marketing-landing-product-portfolios-description {
  list-style-type: none;
  color: #333;
  font-size: 23px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  padding: 0;
}

.marketing-landing-product-portfolios-description h3 {
  font-weight: 700;
  font-size: 23px;
  line-height: normal;
}

.portfolio-description-list {
  margin-bottom: 34px;
}

.marketing-landing-additional-subscription-container {
  position: relative;
  width: 100vw;
  left: 50%;
  transform: translateX(-50%);
  padding-top: 64px;
  padding-bottom: 96px;
}

.marketing-landing-additional-subscription-wrapper {
  max-width: 1280px;
  margin: 0 auto;
}

.marketing-landing-additional-subscription-heading h2 {
  color: #000;
  text-align: center;
  font-size: 45px;
  font-style: normal;
  font-weight: 250;
  line-height: 45px;
}

.marketing-landing-additional-subscription-data-wrapper {
  margin-top: 40px;
}

.marketing-landing-additional-subscription-row {
  margin: 0px;
}

.marketing-landing-additional-subscription-content {
  display: flex;
  margin-bottom: 10px;
  margin-top: 10px;
}

.marketing-landing-additional-subscription-border-seperator {
  height: 1px;
  background-color: #B3B3B3;
}

.marketing-landing-additional-subscription-last-border {
  display: none;
}

.marketing-landing-additional-subscription-check-icon {
  width: 25.98px;
  margin-right: 18px;
}

.marketing-landing-additional-subscription-benefits-text {
  color: #1E1E1E;
  font-size: 23px;
  font-style: normal;
  font-weight: 300;
  line-height: 27px;
}

.marketing-landing-subscription-option-container {
  position: relative;
  width: 100vw;
  left: 50%;
  transform: translateX(-50%);
  background: url("../../assets/Images/MarketingLandingImages/MFI/Background_Images/MFI_pricing_background_image.png"), lightgray 303.072px 0px / 77.31% 100% no-repeat;
  padding-top: 64px;
  padding-bottom: 40px;
}

.marketing-landing-subscription-option-wrapper {
  max-width: 1280px;
  margin: 0 auto;
}

.marketing-landing-subscription-option-data-wrapper {
  margin-top: 40px;
}

.marketing-landing-subscription-option-data-row {
  margin: 0px;
}

.marketing-landing-subscription-option {
  display: grid;
  border-radius: 6px;
  border: 1px solid #C4C4C4;
  background: #FFF;
  width: 450px;
  height: 150px;
}

.annual-subscription-container {
  float: right;
}

.marketing-landing-subscription-option-subscription-top-header {
  display: flex;
  height: 38px;
  border-radius: 6px 6px 0px 0px;
  align-items: center;
  justify-content: center;
}

.marketing-landing-subscription-option-subscription-top-header h3 {
  color: #FFF;
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 12px;
  letter-spacing: 1.67px;
  text-transform: uppercase;
}

.annual-subscription {
  background: #1E1E1E;
}

.quarterly-subscription {
  background: #C4C4C4;
}

.marketing-landing-subscription-price {
  color: #1E1E1E;
  text-align: center;
  font-size: 70px;
  font-style: normal;
  font-weight: 250;
  line-height: 50px;
}

.marketing-landing-subscription-option-subscribe-button-container {
  margin-top: 24px;
  text-align: center;
}

.marketing-landing-subscription-option-print-subscription-text {
  margin-top: 32px;
  color: #1E1E1E;
  text-align: center;
  font-size: 23px;
  font-style: normal;
  font-weight: 300;
  line-height: 27px;
}

.marketing-landing-more-newsletters-container {
  padding-top: 64px;
  padding-bottom: 96px;
}

.marketing-landing-more-newsletters-product-container {
  margin-top: 40px;
}

.marketing-landing-more-newsletters-product-container-row {
  margin: 0px;
  text-align: center;
}

.marketing-landing-more-newsletters-product-container-col img {
  height: 383px;
  box-shadow: 0px 0px 64px 0px rgba(0, 0, 0, 0.10);
}

.marketing-landing-more-newsletters-product-name {
  color: #1E1E1E;
  text-align: center;
  font-size: 23px;
  font-style: normal;
  font-weight: 300;
  line-height: 27px;
  margin-top: 40px;
}

.marketing-landing-more-newsletters-subscribe-button {
  text-align: center;
  margin-top: 24px;
}

/*===== Changes the background color and gradient images as per the product ====== */

.marketing-hero-image-background-gradient-mfi {
  background-image: linear-gradient(90deg,
      rgba(30, 30, 30, 255) 23.04%,
      rgba(0, 0, 0, 0) 70%),
    url("../../assets/Images/MarketingLandingImages/MFI/Background_Images/MFI_intro_background_image.png");
  background-repeat: no-repeat;
  background-size: cover;
}

.marketing-hero-image-background-gradient-msi {
  background-image: linear-gradient(90deg,
      rgba(30, 30, 30, 255) 23.04%,
      rgba(0, 0, 0, 0) 70%),
    url("../../assets/Images/MarketingLandingImages/MSI/Background_Images/MSI_intro_background_image.png");
  background-repeat: no-repeat;
  background-size: cover;
}

.marketing-hero-image-background-gradient-mdi {
  background-image: linear-gradient(90deg,
      rgba(30, 30, 30, 255) 23.04%,
      rgba(0, 0, 0, 0) 70%),
    url("../../assets/Images/MarketingLandingImages/MDI/Background_Images/MDI_intro_background_image.png");
  background-repeat: no-repeat;
  background-size: cover;
}

.marketing-hero-image-background-gradient-mei {
  background-image: linear-gradient(90deg,
      rgba(30, 30, 30, 255) 23.04%,
      rgba(0, 0, 0, 0) 70%),
    url("../../assets/Images/MarketingLandingImages/MEI/Background_Images/MEI_intro_background_image.png");
  background-repeat: no-repeat;
  background-size: cover;
}

.marketing-product-features-background-mfi {
  background: rgba(226, 187, 102, 0.08);
}

.marketing-product-features-background-msi {
  background: rgba(180, 218, 214, 0.15);
}

.marketing-product-features-background-mdi {
  background-color: #F5F6FA;
}

.marketing-product-features-background-mei {
  background: rgba(117, 130, 147, 0.10);
}

.marketing-landing-product-portfolios-description-container-msi::before {
  content: "";
  display: inline-block;
  margin-top: 48px;
  margin-bottom: 14px;
  height: 1px;
  width: 66px;
  background-color: #179C9E;
}

.marketing-landing-product-portfolios-description-container-mdi::before {
  content: "";
  display: inline-block;
  margin-top: 48px;
  margin-bottom: 14px;
  height: 1px;
  width: 66px;
  background-color: #3B94D2;
}

.marketing-landing-product-portfolios-description-container-mei::before {
  content: "";
  display: inline-block;
  margin-top: 48px;
  margin-bottom: 14px;
  height: 1px;
  width: 66px;
  background-color: #628683;
}

.marketing-landing-additional-subscription-background-mfi {
  background-color: #FDFAF3;
}

.marketing-landing-additional-subscription-background-msi {
  background-color: #F4F9F9;
}

.marketing-landing-additional-subscription-background-mdi {
  background-color: #F5F6FA;
}

.marketing-landing-additional-subscription-background-mei {
  background-color: #F1F3F4;
}

.marketing-landing-subscription-background-mfi {
  background: url("../../assets/Images/MarketingLandingImages/MFI/Background_Images/MFI_pricing_background_image.png"), lightgray 303.072px 0px / 77.31% 100% no-repeat;
  background-repeat: no-repeat;
  background-size: cover;
}

.marketing-landing-subscription-background-msi {
  background: url("../../assets/Images/MarketingLandingImages/MSI/Background_Images/MSI_pricing_background_image.png"), lightgray 303.072px 0px / 77.31% 100% no-repeat;
  background-repeat: no-repeat;
  background-size: cover;
}

.marketing-landing-subscription-background-mdi {
  background: url("../../assets/Images/MarketingLandingImages/MDI/Background_Images/MDI_pricing_background_image.png"), lightgray 303.072px 0px / 77.31% 100% no-repeat;
  background-repeat: no-repeat;
  background-size: cover;
}

.marketing-landing-subscription-background-mei {
  background: url("../../assets/Images/MarketingLandingImages/MEI/Background_Images/MEI_pricing_background_image.png"), lightgray 303.072px 0px / 77.31% 100% no-repeat;
  background-repeat: no-repeat;
  background-size: cover;
}


/*========== Responsive Design ================*/
@media only screen and (max-width:320px) {
  .marketing-landing-hero-image-subtitle {
    width: 290px !important;
  }

  .marketing-landing-hero-image-tagline {
    width: 140px !important;
  }

  .marketing-landing-top-hero-image-container-right-image-container {
    margin-top: 135px !important;
  }

  .marketing-landing-more-newsletters-product-container-col img {
    width: 100%;
    height: 70%;
  }
}

@media only screen and (min-width:320px) and (max-width:600px) {
  .marketing-landing-top-hero-image-container-main {
    height: 286px;
  }

  .marketing-landing-top-hero-image-container-right-image-container {
    width: 144px;
    height: 190px;
    margin-top: 121px;
  }

  .marketing-landing-hero-image-title h1 {
    font-size: 10px;
  }

  .marketing-landing-hero-image-subtitle {
    width: 334px;
    margin-bottom: 14px;
  }

  .marketing-landing-hero-image-subtitle h2 {
    font-size: 27px;
    line-height: 34px;
  }

  .marketing-landing-hero-image-tagline {
    width: 100%;
    margin-bottom: 14px;
  }

  .marketing-landing-hero-image-tagline h3 {
    font-size: 14px;
    line-height: 18px;
    font-weight: 400px;
  }

  .marketing-landing-top-hero-image-container-left-content button {
    padding: 4px 16px;
  }
}

@media only screen and (min-width:601px) and (max-width:900px) {
  .marketing-landing-top-hero-image-container-main {
    height: 320px;
  }

  .marketing-landing-top-hero-image-container-right-image-container {
    width: 230px;
    height: 300px;
  }

  .marketing-landing-hero-image-title h1 {
    font-size: 14px;
    margin-bottom: 14px;
  }

  .marketing-landing-hero-image-subtitle {
    width: 100%;
    margin-bottom: 14px;
  }

  .marketing-landing-hero-image-subtitle h2 {
    font-size: 30px;
    line-height: 35px;
  }

  .marketing-landing-hero-image-tagline {
    width: 100%;
    margin-bottom: 14px;
  }

  .marketing-landing-hero-image-tagline h3 {
    font-size: 17px;
    line-height: 23px;
    font-weight: 400px;
  }
}

@media only screen and (min-width:901px) and (max-width:1199px) {
  .marketing-landing-top-hero-image-container-main {
    height: 475px;
  }

  .marketing-landing-hero-image-subtitle {
    width: 100%;
  }

  .marketing-landing-hero-image-subtitle h2 {
    font-size: 45px;
    line-height: normal;
    /* 55 */
  }

  .marketing-landing-hero-image-tagline {
    width: 100%;
  }

  .marketing-landing-hero-image-tagline h3 {
    font-size: 24px;
    line-height: normal;
  }

  .marketing-landing-top-hero-image-container-right-image-container {
    width: 300px;
    height: 400px;
    margin-top: 110px;
  }

  .marketing-landing-product-features-text {
    width: 90%;
    margin: auto;
  }
}

@media only screen and (min-width:320px) and (max-width:1000px) {
  .marketing-landing {
    margin-top: 0px;
    width: 100%;
  }

  .marketing-landing-product-description-container {
    margin-left: 0;
  }

  .marketing-landing-product-image-description-row {
    row-gap: 40px;
  }

  .marketing-landing-product-features-row {
    row-gap: 34px;
  }

  .marketing-landing-product-features-col {
    margin-right: 0px;
  }

  .marketing-landing-additional-subscription-col {
    padding-left: 0;
  }

  .marketing-landing-additional-subscription-last-border {
    display: block;
    height: 1px;
    background-color: #B3B3B3;
  }

  .marketing-landing-subscription-option {
    width: 100%;
  }

  .annual-subscription-container {
    float: none;
  }

  .marketing-landing-more-newsletters-product-container-row {
    row-gap: 64px;
  }
}

@media only screen and (min-width:320px) and (max-width:1399px) {
  .demo {
    width: 90%;
    margin: 0 auto;
    background: blue;
  }

  .marketing-landing-top-hero-image-container {
    width: 90%;
  }

  .marketing-landing-product-info-text-container {
    width: 90%;
    margin: 0 auto;
  }

  .marketing-landing-product-features-wrapper {
    width: 90%;
  }

  .marketing-landing-product-image-and-description-container {
    width: 90%;
    margin: 0 auto;
  }

  .marketing-landing-additional-subscription-wrapper {
    max-width: 90%;
    margin: 0 auto;
  }

  .marketing-landing-subscription-option-wrapper {
    max-width: 90%;
    margin: 0 auto;
  }

  .marketing-landing-more-newsletters-container {
    width: 90%;
    margin: 0 auto;
  }
}

@media only screen and (max-width:767px) {
  .marketing-landing-product-info-text-container {
    font-size: 28px;
    line-height: 34px;
  }

  .marketing-landing-product-features-heading h2 {
    font-size: 28px;
  }

  .marketing-landing-product-features-text {
    font-size: 20px;
    line-height: 26px;
  }

  .marketing-landing-product-description-heading h2 {
    font-size: 28px;
    line-height: 34px;
  }

  .marketing-landing-product-description h3 {
    font-size: 20px;
    line-height: 26px;
  }

  .marketing-landing-product-portfolios-description,
  .marketing-landing-product-portfolios-description h3 {
    font-size: 20px;
    line-height: 26px;
  }

  .marketing-landing-additional-subscription-heading h2 {
    font-size: 28px;
    line-height: 34px;
  }

  .marketing-landing-additional-subscription-benefits-text {
    font-size: 20px;
    line-height: 26px;
  }

  .marketing-landing-subscription-option-data-col {
    padding: 0px;
  }

  .marketing-landing-subscription-option-data-row {
    row-gap: 48px;
  }

  .marketing-landing-more-newsletters-product-name {
    font-size: 20px;
    line-height: 26px;
  }
}

@media only screen and (min-width:1001px) {
  .marketing-landing-product-features-col {
    max-width: 23%;
    margin-right: 18px;
  }
}

@media only screen and (min-width:1000px) and (max-width:1279px) {
  .marketing-landing-more-newsletters-product-container-col img {
    height: 300px;
  }
}
</style>
