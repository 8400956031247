<template>
  <div id="app">
    <master-page :key="this.$store.state.product.CurrentProduct"></master-page>
  </div>
</template>

<script>
import MasterPage from "@/components/Common/Layout/MasterPage.vue";
export default {
  name: "App",
  components: {
    MasterPage,
  },
  methods: {
    checkViewPortSize() {
      let viewPortWidth = window.innerWidth;

      if (viewPortWidth <= 1400) {
        this.$store.state.responsive.isModelPortfolioResponsive = true;
        this.$store.state.responsive.viewPortSize = viewPortWidth;
        if (viewPortWidth <= 1000) {
          this.$store.state.responsive.isMobileHeader = true;
          this.$store.state.responsive.viewPortSize = viewPortWidth;
          if (viewPortWidth <= 900) {
            this.$store.state.responsive.isResponsive = true;
            this.$store.state.responsive.viewPortSize = viewPortWidth;
            if (viewPortWidth <= 768) {
              this.$store.state.responsive.isVideoReadMore = true;
              this.$store.state.responsive.viewPortSize = viewPortWidth;
              if (viewPortWidth <= 600) {
                this.$store.state.responsive.isMobileContent = true;
                this.$store.state.responsive.viewPortSize = viewPortWidth;
                if (viewPortWidth <= 480) {
                  this.$store.state.responsive.isDataTableResponsive = true;
                  this.$store.state.responsive.viewPortSize = viewPortWidth;
                } else {
                  this.$store.state.responsive.isDataTableResponsive = false;
                }
              } else {
                this.$store.state.responsive.isMobileContent = false;
              }
            } else {
              this.$store.state.responsive.isVideoReadMore = false;
            }
          } else {
            this.$store.state.responsive.isResponsive = false;
          }
        } else {
          this.$store.state.responsive.isMobileHeader = false;
        }
      } else {
        this.$store.state.responsive.isMobileHeader = false;
        this.$store.state.responsive.isModelPortfolioResponsive = false;
        this.$store.state.responsive.isResponsive = false;
        this.$store.state.responsive.isMobileContent = false;
        this.$store.state.responsive.isVideoReadMore = false;
      }
    },
  },
  created() {
    window.addEventListener("resize", this.checkViewPortSize);
    this.checkViewPortSize();
  },
  mounted() {
    document.body.onresize = this.checkViewPortSize;
  },
  onIdle() {
    if (this.$auth0.isAuthenticated && this.$session.exists()) {
           this.$auth0.logout();
       }
  },
};
</script>

<style>
.mds-thead .mds-thead__group .mds-th__inner {
  border-bottom: 0px !important;
  margin-bottom: 0px !important;
}

button:focus {
  box-shadow: none !important;
}

.mds-search-field__input:focus{
  box-shadow: none !important;
}

/*::-webkit-scrollbar {
  height: 6px;
  width: 6px;
  overflow-x: overlay;
}

::-webkit-scrollbar-thumb {
  background: rgba(171, 171, 171, 0.3);
  border-radius: 6px;
}*/

.mds-table__scroller {
  overflow-x: overlay;
}

.mds-dialog {
  max-width: 780px;
}

body,
body * {
  font-family: "Univers", HelveticaNeue, "Helvetica Neue", Helvetica, Arial,
    sans-serif;
  margin: 0;
  padding: 0;
}

body {
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
}

.mds-layout-grid .mds-layout-grid__col--3-at-1200 {
  width: 23.3% !important;
}
</style>