<template>
  <div>
    <div v-if="isMobileHeaderToggle">
      <div>
        <div :class="this.CurrentProduct == 'Common' ||
      this.CurrentProduct == '' ||
      this.CurrentProduct == undefined
      ? 'header-img-logout-responsive'
      : 'header-img-logo-responsive'
      ">
          <img :src="getHeaderImage()" :alt="CurrentProduct" @click="redirectToHomePage()" />
        </div>
      </div>



    </div>

    <div v-else>
      <div>
        <div :class="this.CurrentProduct == 'Common' ||
      this.CurrentProduct == '' ||
      this.CurrentProduct == undefined
      ? 'header-img-logout'
      : 'header-img-logo'
      ">
          <img :src="getHeaderImage()" :alt="CurrentProduct" @click="redirectToHomePage()" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "product-logo",
  components: {},
  data() {
    return {
      CurrentProduct: "",
      isMobileHeaderToggle: null,
      //viewPortWidth: null,
    };
  },
  mounted() {
    this.isMobileHeaderToggle = this.$store.state.responsive.isMobileHeader;
    this.CurrentProduct = this.$session.get("current-product");
  },
  methods: {
    getHeaderImage() {
      var images = require.context(
        "../../../../assets/Images/",
        false,
        /\.svg$/
      );
      if (
        this.CurrentProduct == "Common" ||
        this.CurrentProduct == "" ||
        this.CurrentProduct == undefined

      ) {
        return images("./Common.svg");
      } else {
        return images("./" + this.CurrentProduct.toUpperCase() + ".svg");
      }
    },
    redirectToHomePage() {
      if (this.$session.exists() && this.$store.state.user.LoggedIn) {
        this.$router.push({
          name: "home",
          params: {
            currentproduct: this.CurrentProduct
          },
        }).catch(err => {
          if (
            err.name !== 'NavigationDuplicated' &&
            !err.message.includes('Avoided redundant navigation to current location')
          ) {
            console.log(err);
          }
        });
      }
      else {
        this.$router.push({
          name: "combined-home",
          params: {
            currentproduct: this.CurrentProduct
          },
        }).catch(err => {
          if (
            err.name !== 'NavigationDuplicated' &&
            !err.message.includes('Avoided redundant navigation to current location')
          ) {
            console.log(err);
          }
        });
      }
    }
  },

  computed: mapState({
    isMobileHeader: state => state.responsive.isMobileHeader,
    isMobileHeaderAlias: 'isMobileHeader',
    isMobileHeaderState(state) {
      return state.responsive.isMobileHeader
    }

  }),

  watch: {
    isMobileHeaderState() {
      this.isMobileHeaderToggle = this.isMobileHeaderState;
    }
  }
}
</script>

<style scoped>
.main-div-for-morningstar-logo {
  width: 100%;
  text-align: justify;
  /* For Edge */
  -moz-text-align-last: center;
  /* For Firefox prior 58.0 */
  text-align-last: center;
}

.header-img-logo-responsive-one {
  margin-top: 0px;

}

.header-img-responsive-for-logout {
  width: 100%;
  margin-top: 0px;
  text-align: justify;
  /* For Edge */
  -moz-text-align-last: center;
  /* For Firefox prior 58.0 */
  text-align-last: center;
}



@media only screen and (min-width: 0px) and (max-width: 450px) {

  .main-div-for-morningstar-logo-for-product {
    width: 50%;
    margin-top: -33px;
    margin-left: 25%;
    text-align: justify;
    /* For Edge */
    -moz-text-align-last: center;
    /* For Firefox prior 58.0 */
    text-align-last: center;
  }

  .header-img-logo-responsive-one {
    margin-top: 0px;
    margin-left: 15%;
  }

}

@media only screen and (min-width: 450px) and (max-width: 600px) {
  .header-img-logo-responsive-one {
    margin-top: 0px;
    margin-left: 12%;
    margin-right: 13%;

  }

  .main-div-for-morningstar-logo-for-product {
    width: 50%;
    margin-top: -33px;
    margin-left: 25%;
    text-align: justify;
    /* For Edge */
    -moz-text-align-last: center;
    /* For Firefox prior 58.0 */
    text-align-last: center;
  }

}

@media only screen and (min-width: 600px) and (max-width: 800px) {
  .header-img-logo-responsive-one {
    margin-top: 0px;
    margin-left: 20%;
    margin-right: 20%;

  }

  .main-div-for-morningstar-logo-for-product {
    width: 50%;
    margin-top: -33px;
    margin-left: 25%;
    text-align: justify;
    /* For Edge */
    -moz-text-align-last: center;
    /* For Firefox prior 58.0 */
    text-align-last: center;
  }

}

@media only screen and (min-width: 800px) and (max-width: 1000px) {
  .header-img-logo-responsive-one {
    margin-top: 0px;
    margin-left: 24%;
    margin-right: 24%;

  }

  .main-div-for-morningstar-logo-for-product {
    width: 50%;
    margin-top: -33px;
    margin-left: 25%;
    text-align: justify;
    /* For Edge */
    -moz-text-align-last: center;
    /* For Firefox prior 58.0 */
    text-align-last: center;
  }

}


@media only screen and (min-width: 450px) and (max-width: 7680px) {}

@media only screen and (min-width: 1000px) and (max-width: 1100px) {

  .header-img-logo {
    position: absolute;
    width: 276px;
    height: 69px;
    left: 42%;

    cursor: pointer;
  }

}

@media only screen and (min-width: 1100px) and (max-width: 1200px) {

  .header-img-logo {
    position: absolute;
    width: 276px;
    height: 69px;
    left: 42.5%;

    cursor: pointer;
  }

}

@media only screen and (min-width: 1200px) and (max-width: 1300px) {

  .header-img-logo {
    position: absolute;
    width: 276px;
    height: 69px;
    left: 43.1%;

    cursor: pointer;
  }

}

@media only screen and (min-width: 1300px) and (max-width: 1400px) {

  .header-img-logo {
    position: absolute;
    width: 276px;
    height: 69px;
    left: 43.4%;

    cursor: pointer;
  }

}

@media only screen and (min-width: 1400px) and (max-width: 1500px) {

  .header-img-logo {
    position: absolute;
    width: 276px;
    height: 69px;
    left: 43.8%;

    cursor: pointer;
  }

}

@media only screen and (min-width: 1500px) and (max-width: 2000px) {

  .header-img-logo {
    position: absolute;
    width: 276px;
    height: 69px;
    left: 45%;

    cursor: pointer;
  }

}


@media only screen and (min-width: 2000px) and (max-width: 2500px) {

  .header-img-logo {
    position: absolute;
    width: 276px;
    height: 69px;
    left: 46%;
    cursor: pointer;
  }

}

.header-img-logout {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 14.3%;
  cursor: pointer;
  margin-top: -10px;
  padding-left: 3%;
}

.header-img-logo-responsive {
  position: absolute;
  width: "276px";
  height: "69px";
  left: 33.92%;
  right: 23.92%;
}

@media only screen and (min-width: 0px) and (max-width: 1000px) {
  .header-img-logout-responsive {
    width: 100%;
    margin-left: 0px;
    margin-top: -0.5px;
    cursor: pointer;
    text-align: justify;
    /* For Edge */
    -moz-text-align-last: center;
    /* For Firefox prior 58.0 */
    text-align-last: center;
  }

  .header-img-logo-responsive {
    top: 10px;
    position: relative;
    cursor: pointer;
    text-align: justify;
    /* For Edge */
    -moz-text-align-last: center;
    /* For Firefox prior 58.0 */
    text-align-last: center;
  }

}

@media only screen and (max-width: 350px) {
  .header-img-logo-responsive {
    top: 10px;
    margin-left: -15px;
    margin-right: 100px;
    position: relative;
    text-align: justify;
    /* For Edge */
    -moz-text-align-last: center;
    /* For Firefox prior 58.0 */
    text-align-last: center;
  }


}

@media only screen and (min-width: 350px) and (max-width: 400px) {
  .header-img-logo-responsive {
    top: 10px;
    margin-left: -15px;
    margin-right: 100px;
    position: relative;
    text-align: justify;
    /* For Edge */
    -moz-text-align-last: center;
    /* For Firefox prior 58.0 */
    text-align-last: center;
  }


}

@media only screen and (min-width: 400px) and (max-width: 440px) {
  .header-img-logo-responsive {
    top: 10px;
    margin-left: -15px;
    margin-right: 100px;
    position: relative;
    text-align: justify;
    /* For Edge */
    -moz-text-align-last: center;
    /* For Firefox prior 58.0 */
    text-align-last: center;
  }


}

@media only screen and (min-width: 440px) and (max-width: 480px) {
  .header-img-logo-responsive {
    top: 10px;
    margin-left: -15px;
    margin-right: 130px;
    position: relative;
    text-align: justify;
    /* For Edge */
    -moz-text-align-last: center;
    /* For Firefox prior 58.0 */
    text-align-last: center;
  }


}

@media only screen and (min-width: 480px) and (max-width: 520px) {
  .header-img-logo-responsive {
    top: 10px;
    margin-left: -15px;
    margin-right: 150px;
    position: relative;
    text-align: justify;
    /* For Edge */
    -moz-text-align-last: center;
    /* For Firefox prior 58.0 */
    text-align-last: center;
  }


}

@media only screen and (min-width: 520px) and (max-width: 600px) {
  .header-img-logo-responsive {
    top: 10px;
    margin-left: -15px;
    margin-right: 150px;
    position: relative;
    text-align: justify;
    /* For Edge */
    -moz-text-align-last: center;
    /* For Firefox prior 58.0 */
    text-align-last: center;
  }


}






@media only screen and (min-width: 600px) and (max-width: 650px) {



  .header-img-logout {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 14.3%;
    cursor: pointer;
    margin-top: -10px;
    padding-left: 3%;
  }

}


@media only screen and (min-width: 5000px) and (max-width: 5500px) {


  .header-img-logout {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 14.3%;
    cursor: pointer;
    margin-top: -10px;
    padding-left: 15%;
  }

}

@media only screen and (min-width: 1550px) and (max-width: 7000px) {


  .header-img-logout {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 14.3%;
    cursor: pointer;
    margin-top: -10px;
    padding-left: 7%;
  }

}

@media only screen and (min-width: 2500px) and (max-width: 3000px) {


  .header-img-logo {
    position: absolute;
    width: 276px;
    height: 69px;
    left: 46.7%;
    cursor: pointer;
  }

}

@media only screen and (min-width: 3000px) and (max-width: 3500px) {


  .header-img-logo {
    position: absolute;
    width: 276px;
    height: 69px;
    left: 47.2%;
    cursor: pointer;
  }

}

@media only screen and (min-width: 3500px) and (max-width: 4000px) {


  .header-img-logo {
    position: absolute;
    width: 276px;
    height: 69px;
    left: 47.5%;
    cursor: pointer;
  }

}


@media only screen and (min-width: 4000px) and (max-width: 4500px) {


  .header-img-logo {
    position: absolute;
    width: 276px;
    height: 69px;
    left: 47.8%;
    cursor: pointer;
  }

}


@media only screen and (min-width: 4500px) and (max-width: 5000px) {


  .header-img-logo {
    position: absolute;
    width: 276px;
    height: 69px;
    left: 47.9%;
    cursor: pointer;
  }

}

@media only screen and (min-width: 5000px) and (max-width: 5500px) {


  .header-img-logo {
    position: absolute;
    width: 276px;
    height: 69px;
    left: 48.3%;
    cursor: pointer;
  }

}

@media only screen and (min-width: 5500px) and (max-width: 6000px) {


  .header-img-logo {
    position: absolute;
    width: 276px;
    height: 69px;
    left: 48.3%;
    cursor: pointer;
  }

}

@media only screen and (min-width: 6000px) and (max-width: 60000px) {


  .header-img-logo {
    position: absolute;
    width: 276px;
    height: 69px;
    left: 48.5%;
    cursor: pointer;
  }

}




@media only screen and (min-width: 600px) and (max-width: 1000px) {
  .header-img-logo-responsive {
    top: 10px;
    margin-left: -23px;
    position: relative;
    padding-left: 10px;
    width: 250px;
  }


}
</style>