import { getInstance } from "../auth/auth0";

export const authGuard = (to, from, next) => {
  const authService = getInstance();

  const guardAction = () => {
    if (authService.isAuthenticated) {
      var MEIAccess=false;
      var MDIAccess=false;
      var MSIAccess=false;
      var MFIAccess=false;
      var DI_MEMBER_ADMIN=false;
      var SI_MEMBER_ADMIN=false;
      var EI_MEMBER_ADMIN=false;
      var FI_MEMBER_ADMIN=false;
      var accessProducts = authService.user['https://morningstar.com/uim_roles'];
      if(accessProducts != undefined && accessProducts !=null) {
        MEIAccess = accessProducts.includes("EI_MEMBER");
        MDIAccess = accessProducts.includes("DI_MEMBER");
        MSIAccess = accessProducts.includes("SI_MEMBER");
        MFIAccess = accessProducts.includes("FI_MEMBER");
        DI_MEMBER_ADMIN = accessProducts.includes("DI_MEMBER_ADMIN");
        SI_MEMBER_ADMIN = accessProducts.includes("SI_MEMBER_ADMIN");
        EI_MEMBER_ADMIN = accessProducts.includes("EI_MEMBER_ADMIN");
        FI_MEMBER_ADMIN = accessProducts.includes("FI_MEMBER_ADMIN");
      }
      if ((to.params.currentproduct == null || to.params.currentproduct == undefined) && to.name == 'searchResults') {
        to.params.currentproduct = from.params.currentproduct;
      }
      if (to.params.currentproduct != 'Common') {
        to.params.currentproduct = to.params.currentproduct.toLowerCase();
      }

      //Checking if the user has particular Product rights
      if ((to.params.currentproduct.toUpperCase() == 'MEI') && !MEIAccess) {
        return next('/accessDenied');
      } else if ((to.params.currentproduct.toUpperCase() == 'MDI') && !MDIAccess) {
        return next('/accessDenied');
      } else if ((to.params.currentproduct.toUpperCase() == 'MSI') && !MSIAccess) {
        return next('/accessDenied');
      } else if ((to.params.currentproduct.toUpperCase() == 'MFI') && !MFIAccess) {
        return next('/accessDenied');
      }

      if ((to.name == "newPost" || to.name == "updatePost" || to.name == "draftsPost") && (!DI_MEMBER_ADMIN && !SI_MEMBER_ADMIN && !EI_MEMBER_ADMIN && !FI_MEMBER_ADMIN)) {
        return next('/accessDenied');
      }

      //Checking if product is wrong but if page is correct
      if ((to.name == "modelPort" || to.name == "equityWatchlist" || to.name == "bondWatchlist") && to.params.currentproduct.toUpperCase() !== 'MEI') {
        return next("/pageNotFound");
      } else if ((to.name == "dividendDeferPort" || to.name == "dividendSelectPort" || to.name == "incomeBellWethers") && to.params.currentproduct.toUpperCase() !== 'MDI') {
        return next("/pageNotFound");
      } else if ((to.name == "tortoisePort" || to.name == "harePort" || to.name == "TortoiseAndHareWatchlist") && to.params.currentproduct.toUpperCase() !== 'MSI') {
        return next("/pageNotFound");
      } else if ((to.name == "fundinvestor" || to.name == "fundselector" || to.name=="categoryOverview") && to.params.currentproduct.toUpperCase() !== 'MFI') {
        return next("/pageNotFound");
      }

      return next();
    }
    authService.loginWithRedirect();
  };

  // If the Auth0Plugin has loaded already, check the authentication state
  if (!authService.isLoading) {
    return guardAction();
  }

  authService.$watch("isLoading", (isLoading) => {
    if (isLoading === false) {
      return guardAction();
    }
  });
};
