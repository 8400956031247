
<template>
  <div class="MainArchive">
    
    <div class="archive-Heading-name">
      <h1 class="archive-heading-text" v-if="CurrentProduct.toUpperCase() == 'MFI'">
        FundInvestor Archive
      </h1>
      <h1 class="archive-heading-text" v-if="CurrentProduct.toUpperCase() == 'MDI'">
        DividendInvestor Archive
      </h1>
      <h1 class="archive-heading-text" v-if="CurrentProduct.toUpperCase() == 'MEI'">
        ETFInvestor Archive
      </h1>
      <h1 class="archive-heading-text" v-if="CurrentProduct.toUpperCase() == 'MSI'">
        StockInvestor Archive
      </h1>
    </div>
    

    <div class="TopPicks">
      <div class="loader1">
        <mds-loader
          v-if="isLoadingImage"
          aria-label="Medium Loader"
        ></mds-loader>
      </div>


      <mds-layout-grid>
      <mds-row >
        <mds-col :cols-at-s="12" >
       <div class="top-image-and-data-main-class">
       
        <div class="image-header-div">
          <img class="imageheader" :src="imgSrc" />
        </div>
       
      
      <div class="headertext">
        <p class="archive-newissue">NEW ISSUE</p>
        <p class="latestTitle" v-html="Title"></p>
        <p class="latestAuthor" v-html="Author"></p>
        <p class="latestDeck" v-html="Deck"></p>
        <div class="top-button-div">
          <mds-button class="top-side-button" variation="secondary" @click="DownloadPdf">
          Download PDF
        </mds-button>
        </div>
      </div>

    </div>
      </mds-col>
     
      </mds-row>
      </mds-layout-grid>


    </div>

    <div>
      <mds-loader v-if="isLoadingImage" aria-label="Medium Loader"></mds-loader>
    </div>

    <div class="imageData" v-if="isDisplayImage">
      <mds-layout-grid>
      <mds-row>
      <mds-col
        class="column"
        :cols-at-s="12" 
        gutter="none"
      >
        <div class="image-and-all">
          <img :src="imgSrc1" class="ArchiveImage" />
          <p class="publishDate" v-html="PublishDate1"></p>
          <h1 class="TitlePdf" v-html="Title1"></h1>
          <p class="AuthorName" v-html="Author1"></p>
          <p class="DeckInfo" v-html="Deck1"></p>
          <mds-button variation="secondary" @click="DownloadPdf1"> Download PDF </mds-button>
        </div>
        <div class="image-and-all">
          <img :src="imgSrc2" class="ArchiveImage" />
          <p class="publishDate" v-html="PublishDate2"></p>
          <h1 class="TitlePdf" v-html="Title2"></h1>
          <p class="AuthorName" v-html="Author2"></p>
          <p class="DeckInfo" v-html="Deck2"></p>
          <mds-button variation="secondary" @click="DownloadPdf2"> Download PDF </mds-button>
        </div>
        <div class="image-and-all">
          <img :src="imgSrc3" class="ArchiveImage" />
          <p class="publishDate" v-html="PublishDate3"></p>
          <h1 class="TitlePdf" v-html="Title3"></h1>
          <p class="AuthorName" v-html="Author3"></p>
          <p class="DeckInfo" v-html="Deck3"></p>
          <mds-button variation="secondary" @click="DownloadPdf3"> Download PDF </mds-button>
        </div>
        <div class="image-and-all">
          <img :src="imgSrc4" class="ArchiveImage" />
          <p class="publishDate" v-html="PublishDate4"></p>
          <h1 class="TitlePdf" v-html="Title4"></h1>
          <p class="AuthorName" v-html="Author4"></p>
          <p class="DeckInfo" v-html="Deck4"></p>
          <mds-button variation="secondary" @click="DownloadPdf4"> Download PDF </mds-button>
        </div>
        <div class="image-and-all">
          <img :src="imgSrc5" class="ArchiveImage" />
          <p class="publishDate" v-html="PublishDate5"></p>
          <h1 class="TitlePdf" v-html="Title5"></h1>
          <p class="AuthorName" v-html="Author5"></p>
          <p class="DeckInfo" v-html="Deck5"></p>
          <mds-button variation="secondary" @click="DownloadPdf5"> Download PDF </mds-button>
        </div>
        <div class="image-and-all">
          <img :src="imgSrc6" class="ArchiveImage" />
          <p class="publishDate" v-html="PublishDate6"></p>
          <h1 class="TitlePdf" v-html="Title6"></h1>
          <p class="AuthorName" v-html="Author6"></p>
          <p class="DeckInfo" v-html="Deck6"></p>
          <mds-button variation="secondary" @click="DownloadPdf6"> Download PDF </mds-button>
        </div>
        <div class="image-and-all">
          <img :src="imgSrc7" class="ArchiveImage" />
          <p class="publishDate" v-html="PublishDate7"></p>
          <h1 class="TitlePdf" v-html="Title7"></h1>
          <p class="AuthorName" v-html="Author7"></p>
          <p class="DeckInfo" v-html="Deck7"></p>
          <mds-button variation="secondary" @click="DownloadPdf7"> Download PDF </mds-button>
        </div>
        <div class="image-and-all">
          <img :src="imgSrc8" class="ArchiveImage" />
          <p class="publishDate" v-html="PublishDate8"></p>
          <h1 class="TitlePdf" v-html="Title8"></h1>
          <p class="AuthorName" v-html="Author8"></p>
          <p class="DeckInfo" v-html="Deck8"></p>
          <mds-button variation="secondary" @click="DownloadPdf8"> Download PDF </mds-button>
        </div>
        <div class="image-and-all">
          <img :src="imgSrc9" class="ArchiveImage" />
          <p class="publishDate" v-html="PublishDate9"></p>
          <h1 class="TitlePdf" v-html="Title9"></h1>
          <p class="AuthorName" v-html="Author9"></p>
          <p class="DeckInfo" v-html="Deck9"></p>
          <mds-button variation="secondary" @click="DownloadPdf9"> Download PDF </mds-button>
        </div>
        <div class="image-and-all">
          <img :src="imgSrc10" class="ArchiveImage" />
          <p class="publishDate" v-html="PublishDate10"></p>
          <h1 class="TitlePdf" v-html="Title10"></h1>
          <p class="AuthorName" v-html="Author10"></p>
          <p class="DeckInfo" v-html="Deck10"></p>
          <mds-button variation="secondary" @click="DownloadPdf10"> Download PDF </mds-button>
        </div>
        <div class="image-and-all">
          <img :src="imgSrc11" class="ArchiveImage" />
          <p class="publishDate" v-html="PublishDate11"></p>
          <h1 class="TitlePdf" v-html="Title11"></h1>
          <p class="AuthorName" v-html="Author11"></p>
          <p class="DeckInfo" v-html="Deck11"></p>
          <mds-button variation="secondary" @click="DownloadPdf11"> Download PDF </mds-button>
        </div>
        <div class="image-and-all">
          <img :src="imgSrc12" class="ArchiveImage" />
          <p class="publishDate" v-html="PublishDate12"></p>
          <h1 class="TitlePdf" v-html="Title12"></h1>
          <p class="AuthorName" v-html="Author12"></p>
          <p class="DeckInfo" v-html="Deck12"></p>
          <mds-button variation="secondary" @click="DownloadPdf12"> Download PDF </mds-button>
        </div>
      </mds-col>
    </mds-row>
      </mds-layout-grid>
    </div>
  </div>
</template>
<script>
import { MdsButton } from "@mds/button";
import MdsLoader from "@mds/loader";
import { MdsLayoutGrid, MdsRow, MdsCol } from '@mds/layout-grid';
import GA4TrackingMixin  from "../../mixins/GA4TrackingMixin";
import { fetchData } from "@/utilities/API/apiUtility";

export default {
  name: "archive-section",

  components: {
    MdsButton,
    MdsLoader,
    MdsLayoutGrid, 
    MdsRow, 
    MdsCol
  },
  mixins: [
  GA4TrackingMixin
],
  data() {
    return {
      ArchiveListUrl:
        process.env.VUE_APP_BackEndAPI + process.env.VUE_APP_ArchiveListUrl,
      DownloadPdfUrl:
        process.env.VUE_APP_BackEndAPI + process.env.VUE_APP_DownloadPdfUrl,
      CurrentProduct: this.$session.get("current-product"),
      latest: "",
      isLoadingImage: true,
      isDisplayImage: false,
      list: [],
      pfData: [],
      CurrentDate: new Date(),
      Title:"",
      imgSrc:"",
      Author:"",
      Deck:"",
    };
  },

  mounted() {
    window.scrollTo(0, 0);
    this.GetIssueListData();
    this.getUserDetailsMixin(this.CurrentProduct);
  },

  methods: {
    DownloadPdf() {
      if(this.CurrentProduct == "mfi"){
      this.$gtag.event('download_pdf', {
        'product': "MFI",
        'issue_date': this.CurrentDate,
        'issue_title':  this.FileName,
        'type': 'archive',
        'user_type':localStorage.getItem("userType_MFI")
        })
        }if(this.CurrentProduct == "msi"){
      this.$gtag.event('download_pdf', {
        
        'product': "MSI",
        'issue_date': this.CurrentDate,
        'issue_title':  this.FileName,
        'type': 'archive',
        'user_type':localStorage.getItem("userType_MSI")
                    })
                  } if(this.CurrentProduct == "mdi"){
      this.$gtag.event('download_pdf', {
        'product': "MDI",
        'issue_date': this.CurrentDate,
        'issue_title':  this.FileName,
        'type': 'archive',
        'user_type':localStorage.getItem("userType_MDI")
                    })
                  }if(this.CurrentProduct == "mei"){
      this.$gtag.event('download_pdf', {
        'product': "MEI",
        'issue_date': this.CurrentDate,
        'issue_title':  this.FileName,
        'type': 'archive',
        'user_type':localStorage.getItem("userType_ETF")
                    })
                  }
      try {
        let Url = this.DownloadPdfUrl;
        Url = Url.replace("{ProductCode}", this.CurrentProduct);
        Url = Url.replace("{FileName}", this.FileName);
        Url = Url.replace("{Type}", "ISSUES");

        fetchData(Url)
          .then((response) => {
            if (response) {
              if (response.status == 200) {
                if (response.data) {
                  var results = response.data;
                  if (results) {
                    if (results.FilePath) {
                      const link = document.createElement("a");
                      link.setAttribute("href", results.FilePath);
                      
                      link.setAttribute("download", this.FileName);
                      link.click();
                    }
                  }
                }
              }
            }
          })
          .catch((error) => {
            console.log(error);
          })
          .finally();
      } catch (ex) {
        console.log(ex.message);
      }
    },

    DownloadPdf1() {
      if(this.CurrentProduct == "mfi"){
      this.$gtag.event('download_pdf', {
        'product': "MFI",
        'issue_date': this.CurrentDate,
        'issue_title':  this.FileName1,
        'type': 'archive',
        'user_type':localStorage.getItem("userType_MFI")
                    })
                  } if(this.CurrentProduct == "msi"){
      this.$gtag.event('download_pdf', {
        
        'product': "MSI",
        'issue_date': this.CurrentDate,
        'issue_title':  this.FileName1,
        'type': 'archive',
        'user_type':localStorage.getItem("userType_MSI")
                    })
                  } if(this.CurrentProduct == "mdi"){
      this.$gtag.event('download_pdf', {
        'product': "MDI",
        'issue_date': this.CurrentDate,
        'issue_title':  this.FileName1,
        'type': 'archive',
        'user_type':localStorage.getItem("userType_MDI")
                    })
                  } if(this.CurrentProduct == "mei"){
      this.$gtag.event('download_pdf', {
        'product': "MEI",
        'issue_date': this.CurrentDate,
        'issue_title':  this.FileName1,
        'type': 'archive',
        'user_type':localStorage.getItem("userType_ETF")
                    })
                  }
      try {
        let Url = this.DownloadPdfUrl;
        Url = Url.replace("{ProductCode}", this.CurrentProduct);
        Url = Url.replace("{FileName}", this.FileName1);
        Url = Url.replace("{Type}", "ISSUES");

        fetchData(Url)
          .then((response) => {
            if (response) {
              if (response.status == 200) {
                if (response.data) {
                  var results = response.data;
                  if (results) {
                    if (results.FilePath) {
                      const link = document.createElement("a");
                      link.setAttribute("href", results.FilePath);
                      
                      link.setAttribute("download", this.FileName);
                      link.click();
                    }
                  }
                }
              }
            }
          })
          .catch((error) => {
            console.log(error);
          })
          .finally();
      } catch (ex) {
        console.log(ex.message);
      }
    },

    DownloadPdf2() {
      if(this.CurrentProduct == "mfi"){
      this.$gtag.event('download_pdf', {
        'product': "MFI",
        'issue_date': this.CurrentDate,
        'issue_title':  this.FileName2,
        'type': 'archive',
        'user_type':localStorage.getItem("userType_MFI")
                    })
                  } if(this.CurrentProduct == "msi"){
      this.$gtag.event('download_pdf', {
        
        'product': "MSI",
        'issue_date': this.CurrentDate,
        'issue_title':  this.FileName2,
        'type': 'archive',
        'user_type':localStorage.getItem("userType_MSI")
                    })
                  } if(this.CurrentProduct == "mdi"){
      this.$gtag.event('download_pdf', {
        'product': "MDI",
        'issue_date': this.CurrentDate,
        'issue_title':  this.FileName2,
        'type': 'archive',
        'user_type':localStorage.getItem("userType_MDI")
                    })
                  } if(this.CurrentProduct == "mei"){
      this.$gtag.event('download_pdf', {
        'product': "MEI",
        'issue_date': this.CurrentDate,
        'issue_title':  this.FileName2,
        'type': 'archive',
        'user_type':localStorage.getItem("userType_ETF")
                    })
                  }
      try {
        let Url = this.DownloadPdfUrl;
        Url = Url.replace("{ProductCode}", this.CurrentProduct);
        Url = Url.replace("{FileName}", this.FileName2);
        Url = Url.replace("{Type}", "ISSUES");

        fetchData(Url)
          .then((response) => {
            if (response) {
              if (response.status == 200) {
                if (response.data) {
                  var results = response.data;
                  if (results) {
                    if (results.FilePath) {
                      const link = document.createElement("a");
                      link.setAttribute("href", results.FilePath);
                      
                      link.setAttribute("download", this.FileName);
                      link.click();
                    }
                  }
                }
              }
            }
          })
          .catch((error) => {
            console.log(error);
          })
          .finally();
      } catch (ex) {
        console.log(ex.message);
      }
    },

    DownloadPdf3() {
      if(this.CurrentProduct == "mfi"){
      this.$gtag.event('download_pdf', {
        'product': "MFI",
        'issue_date': this.CurrentDate,
        'issue_title':  this.FileName3,
        'type': 'archive',
        'user_type':localStorage.getItem("userType_MFI")
                    })
                  } if(this.CurrentProduct == "msi"){
      this.$gtag.event('download_pdf', {
        
        'product': "MSI",
        'issue_date': this.CurrentDate,
        'issue_title':  this.FileName3,
        'type': 'archive',
        'user_type':localStorage.getItem("userType_MSI")
                    })
                  } if(this.CurrentProduct == "mdi"){
      this.$gtag.event('download_pdf', {
        'product': "MDI",
        'issue_date': this.CurrentDate,
        'issue_title':  this.FileName3,
        'type': 'archive',
        'user_type':localStorage.getItem("userType_MDI")
                    })
                  } if(this.CurrentProduct == "mei"){
      this.$gtag.event('download_pdf', {
        'product': "MEI",
        'issue_date': this.CurrentDate,
        'issue_title':  this.FileName3,
        'type': 'archive',
        'user_type':localStorage.getItem("userType_ETF")
                    })
                  }
      try {
        let Url = this.DownloadPdfUrl;
        Url = Url.replace("{ProductCode}", this.CurrentProduct);
        Url = Url.replace("{FileName}", this.FileName3);
        Url = Url.replace("{Type}", "ISSUES");

        fetchData(Url)
          .then((response) => {
            if (response) {
              if (response.status == 200) {
                if (response.data) {
                  var results = response.data;
                  if (results) {
                    if (results.FilePath) {
                      const link = document.createElement("a");
                      link.setAttribute("href", results.FilePath);
                      
                      link.setAttribute("download", this.FileName);
                      link.click();
                    }
                  }
                }
              }
            }
          })
          .catch((error) => {
            console.log(error);
          })
          .finally();
      } catch (ex) {
        console.log(ex.message);
      }
    },

    DownloadPdf4() {
      if(this.CurrentProduct == "mfi"){
      this.$gtag.event('download_pdf', {
        'product': "MFI",
        'issue_date': this.CurrentDate,
        'issue_title':  this.FileName4,
        'type': 'archive',
        'user_type':localStorage.getItem("userType_MFI")
                    })
                  } if(this.CurrentProduct == "msi"){
      this.$gtag.event('download_pdf', {
        
        'product': "MSI",
        'issue_date': this.CurrentDate,
        'issue_title':  this.FileName4,
        'type': 'archive',
        'user_type':localStorage.getItem("userType_MSI")
                    })
                  } if(this.CurrentProduct == "mdi"){
      this.$gtag.event('download_pdf', {
        'product': "MDI",
        'issue_date': this.CurrentDate,
        'issue_title':  this.FileName4,
        'type': 'archive',
        'user_type':localStorage.getItem("userType_MDI")
                    })
                  } if(this.CurrentProduct == "mei"){
      this.$gtag.event('download_pdf', {
        'product': "MEI",
        'issue_date': this.CurrentDate,
        'issue_title':  this.FileName4,
        'type': 'archive',
        'user_type':localStorage.getItem("userType_ETF")
                    })
                  }
      try {
        let Url = this.DownloadPdfUrl;
        Url = Url.replace("{ProductCode}", this.CurrentProduct);
        Url = Url.replace("{FileName}", this.FileName4);
        Url = Url.replace("{Type}", "ISSUES");

        fetchData(Url)
          .then((response) => {
            if (response) {
              if (response.status == 200) {
                if (response.data) {
                  var results = response.data;
                  if (results) {
                    if (results.FilePath) {
                      const link = document.createElement("a");
                      link.setAttribute("href", results.FilePath);
                      
                      link.setAttribute("download", this.FileName);
                      link.click();
                    }
                  }
                }
              }
            }
          })
          .catch((error) => {
            console.log(error);
          })
          .finally();
      } catch (ex) {
        console.log(ex.message);
      }
    },

    DownloadPdf5() {
      if(this.CurrentProduct == "mfi"){
      this.$gtag.event('download_pdf', {
        'product': "MFI",
        'issue_date': this.CurrentDate,
        'issue_title':  this.FileName5,
        'type': 'archive',
        'user_type':localStorage.getItem("userType_MFI")
                    })
                  } if(this.CurrentProduct == "msi"){
      this.$gtag.event('download_pdf', {
        
        'product': "MSI",
        'issue_date': this.CurrentDate,
        'issue_title':  this.FileName5,
        'type': 'archive',
        'user_type':localStorage.getItem("userType_MSI")
                    })
                  } if(this.CurrentProduct == "mdi"){
      this.$gtag.event('download_pdf', {
        'product': "MDI",
        'issue_date': this.CurrentDate,
        'issue_title':  this.FileName5,
        'type': 'archive',
        'user_type':localStorage.getItem("userType_MDI")
                    })
                  } if(this.CurrentProduct == "mei"){
      this.$gtag.event('download_pdf', {
        'product': "MEI",
        'issue_date': this.CurrentDate,
        'issue_title':  this.FileName5,
        'type': 'archive',
        'user_type':localStorage.getItem("userType_ETF")
                    })
                  }
      try {
        let Url = this.DownloadPdfUrl;
        Url = Url.replace("{ProductCode}", this.CurrentProduct);
        Url = Url.replace("{FileName}", this.FileName5);
        Url = Url.replace("{Type}", "ISSUES");

        fetchData(Url)
          .then((response) => {
            if (response) {
              if (response.status == 200) {
                if (response.data) {
                  var results = response.data;
                  if (results) {
                    if (results.FilePath) {
                      const link = document.createElement("a");
                      link.setAttribute("href", results.FilePath);
                      
                      link.setAttribute("download", this.FileName);
                      link.click();
                    }
                  }
                }
              }
            }
          })
          .catch((error) => {
            console.log(error);
          })
          .finally();
      } catch (ex) {
        console.log(ex.message);
      }
    },

    DownloadPdf6() {
      if(this.CurrentProduct == "mfi"){
      this.$gtag.event('download_pdf', {
        'product': "MFI",
        'issue_date': this.CurrentDate,
        'issue_title':  this.FileName6,
        'type': 'archive',
        'user_type':localStorage.getItem("userType_MFI")
                    })
                  } if(this.CurrentProduct == "msi"){
      this.$gtag.event('download_pdf', {
        
        'product': "MSI",
        'issue_date': this.CurrentDate,
        'issue_title':  this.FileName6,
        'type': 'archive',
        'user_type':localStorage.getItem("userType_MSI")
                    })
                  } if(this.CurrentProduct == "mdi"){
      this.$gtag.event('download_pdf', {
        'product': "MDI",
        'issue_date': this.CurrentDate,
        'issue_title':  this.FileName6,
        'type': 'archive',
        'user_type':localStorage.getItem("userType_MDI")
                    })
                  } if(this.CurrentProduct == "mei"){
      this.$gtag.event('download_pdf', {
        'product': "MEI",
        'issue_date': this.CurrentDate,
        'issue_title':  this.FileName6,
        'type': 'archive',
        'user_type':localStorage.getItem("userType_ETF")
                    })
                  }
      try {
        let Url = this.DownloadPdfUrl;
        Url = Url.replace("{ProductCode}", this.CurrentProduct);
        Url = Url.replace("{FileName}", this.FileName6);
        Url = Url.replace("{Type}", "ISSUES");

        fetchData(Url)
          .then((response) => {
            if (response) {
              if (response.status == 200) {
                if (response.data) {
                  var results = response.data;
                  if (results) {
                    if (results.FilePath) {
                      const link = document.createElement("a");
                      link.setAttribute("href", results.FilePath);
                      
                      link.setAttribute("download", this.FileName);
                      link.click();
                    }
                  }
                }
              }
            }
          })
          .catch((error) => {
            console.log(error);
          })
          .finally();
      } catch (ex) {
        console.log(ex.message);
      }
    },

    DownloadPdf7() {
      if(this.CurrentProduct == "mfi"){
      this.$gtag.event('download_pdf', {
        'product': "MFI",
        'issue_date': this.CurrentDate,
        'issue_title':  this.FileName7,
        'type': 'archive',
        'user_type':localStorage.getItem("userType_MFI")
                    })
                  } if(this.CurrentProduct == "msi"){
      this.$gtag.event('download_pdf', {
        
        'product': "MSI",
        'issue_date': this.CurrentDate,
        'issue_title':  this.FileName7,
        'type': 'archive',
        'user_type':localStorage.getItem("userType_MSI")
                    })
                  } if(this.CurrentProduct == "mdi"){
      this.$gtag.event('download_pdf', {
        'product': "MDI",
        'issue_date': this.CurrentDate,
        'issue_title':  this.FileName7,
        'type': 'archive',
        'user_type':localStorage.getItem("userType_MDI")
                    })
                  } if(this.CurrentProduct == "mei"){
      this.$gtag.event('download_pdf', {
        'product': "MEI",
        'issue_date': this.CurrentDate,
        'issue_title':  this.FileName7,
        'type': 'archive',
        'user_type':localStorage.getItem("userType_ETF")
                    })
                  }
      try {
        let Url = this.DownloadPdfUrl;
        Url = Url.replace("{ProductCode}", this.CurrentProduct);
        Url = Url.replace("{FileName}", this.FileName7);
        Url = Url.replace("{Type}", "ISSUES");

        fetchData(Url)
          .then((response) => {
            if (response) {
              if (response.status == 200) {
                if (response.data) {
                  var results = response.data;
                  if (results) {
                    if (results.FilePath) {
                      const link = document.createElement("a");
                      link.setAttribute("href", results.FilePath);
                      
                      link.setAttribute("download", this.FileName);
                      link.click();
                    }
                  }
                }
              }
            }
          })
          .catch((error) => {
            console.log(error);
          })
          .finally();
      } catch (ex) {
        console.log(ex.message);
      }
    },

    DownloadPdf8() {
      if(this.CurrentProduct == "mfi"){
      this.$gtag.event('download_pdf', {
        'product': "MFI",
        'issue_date': this.CurrentDate,
        'issue_title':  this.FileName8,
        'type': 'archive',
        'user_type':localStorage.getItem("userType_MFI")
                    })
                  } if(this.CurrentProduct == "msi"){
      this.$gtag.event('download_pdf', {
        
        'product': "MSI",
        'issue_date': this.CurrentDate,
        'issue_title':  this.FileName8,
        'type': 'archive',
        'user_type':localStorage.getItem("userType_MSI")
                    })
                  } if(this.CurrentProduct == "mdi"){
      this.$gtag.event('download_pdf', {
        'product': "MDI",
        'issue_date': this.CurrentDate,
        'issue_title':  this.FileName8,
        'type': 'archive',
        'user_type':localStorage.getItem("userType_MDI")
                    })
                  } if(this.CurrentProduct == "mei"){
      this.$gtag.event('download_pdf', {
        'product': "MEI",
        'issue_date': this.CurrentDate,
        'issue_title':  this.FileName8,
        'type': 'archive',
        'user_type':localStorage.getItem("userType_ETF")
                    })
                  }
      try {
        let Url = this.DownloadPdfUrl;
        Url = Url.replace("{ProductCode}", this.CurrentProduct);
        Url = Url.replace("{FileName}", this.FileName8);
        Url = Url.replace("{Type}", "ISSUES");

        fetchData(Url)
          .then((response) => {
            if (response) {
              if (response.status == 200) {
                if (response.data) {
                  var results = response.data;
                  if (results) {
                    if (results.FilePath) {
                      const link = document.createElement("a");
                      link.setAttribute("href", results.FilePath);
                      
                      link.setAttribute("download", this.FileName);
                      link.click();
                    }
                  }
                }
              }
            }
          })
          .catch((error) => {
            console.log(error);
          })
          .finally();
      } catch (ex) {
        console.log(ex.message);
      }
    },

    DownloadPdf9() {
      if(this.CurrentProduct == "mfi"){
      this.$gtag.event('download_pdf', {
        'product': "MFI",
        'issue_date': this.CurrentDate,
        'issue_title':  this.FileName9,
        'type': 'archive',
        'user_type':localStorage.getItem("userType_MFI")
                    })
                  } if(this.CurrentProduct == "msi"){
      this.$gtag.event('download_pdf', {
        
        'product': "MSI",
        'issue_date': this.CurrentDate,
        'issue_title':  this.FileName9,
        'type': 'archive',
        'user_type':localStorage.getItem("userType_MSI")
                    })
                  } if(this.CurrentProduct == "mdi"){
      this.$gtag.event('download_pdf', {
        'product': "MDI",
        'issue_date': this.CurrentDate,
        'issue_title':  this.FileName9,
        'type': 'archive',
        'user_type':localStorage.getItem("userType_MDI")
                    })
                  } if(this.CurrentProduct == "mei"){
      this.$gtag.event('download_pdf', {
        'product': "MEI",
        'issue_date': this.CurrentDate,
        'issue_title':  this.FileName9,
        'type': 'archive',
        'user_type':localStorage.getItem("userType_ETF")
                    })
                  }
      try {
        let Url = this.DownloadPdfUrl;
        Url = Url.replace("{ProductCode}", this.CurrentProduct);
        Url = Url.replace("{FileName}", this.FileName9);
        Url = Url.replace("{Type}", "ISSUES");

        fetchData(Url)
          .then((response) => {
            if (response) {
              if (response.status == 200) {
                if (response.data) {
                  var results = response.data;
                  if (results) {
                    if (results.FilePath) {
                      const link = document.createElement("a");
                      link.setAttribute("href", results.FilePath);
                      
                      link.setAttribute("download", this.FileName);
                      link.click();
                    }
                  }
                }
              }
            }
          })
          .catch((error) => {
            console.log(error);
          })
          .finally();
      } catch (ex) {
        console.log(ex.message);
      }
    },

    DownloadPdf10() {
      if(this.CurrentProduct == "mfi"){
      this.$gtag.event('download_pdf', {
        'product': "MFI",
        'issue_date': this.CurrentDate,
        'issue_title':  this.FileName10,
        'type': 'archive',
        'user_type':localStorage.getItem("userType_MFI")
                    })
                  } if(this.CurrentProduct == "msi"){
      this.$gtag.event('download_pdf', {
        
        'product': "MSI",
        'issue_date': this.CurrentDate,
        'issue_title':  this.FileName10,
        'type': 'archive',
        'user_type':localStorage.getItem("userType_MSI")
                    })
                  } if(this.CurrentProduct == "mdi"){
      this.$gtag.event('download_pdf', {
        'product': "MDI",
        'issue_date': this.CurrentDate,
        'issue_title':  this.FileName10,
        'type': 'archive',
        'user_type':localStorage.getItem("userType_MDI")
                    })
                  } if(this.CurrentProduct == "mei"){
      this.$gtag.event('download_pdf', {
        'product': "MEI",
        'issue_date': this.CurrentDate,
        'issue_title':  this.FileName10,
        'type': 'archive',
        'user_type':localStorage.getItem("userType_ETF")
                    })
                  }
      try {
        let Url = this.DownloadPdfUrl;
        Url = Url.replace("{ProductCode}", this.CurrentProduct);
        Url = Url.replace("{FileName}", this.FileName10);
        Url = Url.replace("{Type}", "ISSUES");

        fetchData(Url)
          .then((response) => {
            if (response) {
              if (response.status == 200) {
                if (response.data) {
                  var results = response.data;
                  if (results) {
                    if (results.FilePath) {
                      const link = document.createElement("a");
                      link.setAttribute("href", results.FilePath);
                      
                      link.setAttribute("download", this.FileName);
                      link.click();
                    }
                  }
                }
              }
            }
          })
          .catch((error) => {
            console.log(error);
          })
          .finally();
      } catch (ex) {
        console.log(ex.message);
      }
    },

    DownloadPdf11() {
      if(this.CurrentProduct == "mfi"){
      this.$gtag.event('download_pdf', {
        'product': "MFI",
        'issue_date': this.CurrentDate,
        'issue_title':  this.FileName11,
        'type': 'archive',
        'user_type':localStorage.getItem("userType_MFI")
                    })
                  } if(this.CurrentProduct == "msi"){
      this.$gtag.event('download_pdf', {
        
        'product': "MSI",
        'issue_date': this.CurrentDate,
        'issue_title':  this.FileName11,
        'type': 'archive',
        'user_type':localStorage.getItem("userType_MSI")
                    })
                  } if(this.CurrentProduct == "mdi"){
      this.$gtag.event('download_pdf', {
        'product': "MDI",
        'issue_date': this.CurrentDate,
        'issue_title':  this.FileName11,
        'type': 'archive',
        'user_type':localStorage.getItem("userType_MDI")
                    })
                  } if(this.CurrentProduct == "mei"){
      this.$gtag.event('download_pdf', {
        'product': "MEI",
        'issue_date': this.CurrentDate,
        'issue_title':  this.FileName11,
        'type': 'archive',
        'user_type':localStorage.getItem("userType_ETF")
                    })
                  }
      try {
        let Url = this.DownloadPdfUrl;
        Url = Url.replace("{ProductCode}", this.CurrentProduct);
        Url = Url.replace("{FileName}", this.FileName11);
        Url = Url.replace("{Type}", "ISSUES");

        fetchData(Url)
          .then((response) => {
            if (response) {
              if (response.status == 200) {
                if (response.data) {
                  var results = response.data;
                  if (results) {
                    if (results.FilePath) {
                      const link = document.createElement("a");
                      link.setAttribute("href", results.FilePath);
                      
                      link.setAttribute("download", this.FileName);
                      link.click();
                    }
                  }
                }
              }
            }
          })
          .catch((error) => {
            console.log(error);
          })
          .finally();
      } catch (ex) {
        console.log(ex.message);
      }
    },

    DownloadPdf12() {
      if(this.CurrentProduct == "mfi"){
      this.$gtag.event('download_pdf', {
        'product': "MFI",
        'issue_date': this.CurrentDate,
        'issue_title':  this.FileName12,
        'type': 'archive',
        'user_type':localStorage.getItem("userType_MFI")
                    })
                  } if(this.CurrentProduct == "msi"){
      this.$gtag.event('download_pdf', {
        
        'product': "MSI",
        'issue_date': this.CurrentDate,
        'issue_title':  this.FileName12,
        'type': 'archive',
        'user_type':localStorage.getItem("userType_MSI")
                    })
                  } if(this.CurrentProduct == "mdi"){
      this.$gtag.event('download_pdf', {
        'product': "MDI",
        'issue_date': this.CurrentDate,
        'issue_title':  this.FileName12,
        'type': 'archive',
        'user_type':localStorage.getItem("userType_MDI")
                    })
                  } if(this.CurrentProduct == "mei"){
      this.$gtag.event('download_pdf', {
        'product': "MEI",
        'issue_date': this.CurrentDate,
        'issue_title':  this.FileName12,
        'type': 'archive',
        'user_type':localStorage.getItem("userType_ETF")
                    })
                  }
      try {
        let Url = this.DownloadPdfUrl;
        Url = Url.replace("{ProductCode}", this.CurrentProduct);
        Url = Url.replace("{FileName}", this.FileName12);
        Url = Url.replace("{Type}", "ISSUES");

        fetchData(Url)
          .then((response) => {
            if (response) {
              if (response.status == 200) {
                if (response.data) {
                  var results = response.data;
                  if (results) {
                    if (results.FilePath) {
                      const link = document.createElement("a");
                      link.setAttribute("href", results.FilePath);
                      
                      link.setAttribute("download", this.FileName);
                      link.click();
                    }
                  }
                }
              }
            }
          })
          .catch((error) => {
            console.log(error);
          })
          .finally();
      } catch (ex) {
        console.log(ex.message);
      }
    },

    GetIssueListData() {
      try {
        var Month = "";
        var str = "";
        var CurrentProduct="";
        if(this.CurrentProduct.toUpperCase() == "MEI" || this.CurrentProduct.toUpperCase() == "ETF"){
          CurrentProduct = "ETF";
        }
        else{
          CurrentProduct = this.CurrentProduct;
        }
        var URL = this.ArchiveListUrl;
        URL = URL.replace("{ProductCode}", CurrentProduct);
        fetchData(URL)
          .then((response) => {
            if (response) {
              if (response.status == 200) {
                if (response.data) {
                  var pfData = response.data;
                  if (pfData) {
                    for (var i = 0; i < pfData.length; i++) {
                      var obj = pfData[i];
                      if (obj) {
                        if (i == 0) {
                          if (
                            obj.ImageId &&
                            obj.Title &&
                            obj.PublishDate &&
                            obj.Author &&
                            obj.Deck &&
                            obj.FileName
                          ) {
                            this.imgSrc = obj.ImageId;
                            this.Title = obj.Title;
                            this.PublishDate = obj.PublishDate;
                            this.Author = obj.Author;
                            this.Deck = obj.Deck;
                            this.FileName = obj.FileName;
                          }
                        }
                        if (i == 1) {
                          if (
                            obj.ImageId &&
                            obj.Title &&
                            obj.PublishDate &&
                            obj.Author &&
                            obj.Deck &&
                            obj.FileName
                          ) {
                            this.imgSrc1 = obj.ImageId;
                            this.Title1 = obj.Title;
                            Month = obj.PublishDate.substring(5, 7);
                            str = this.GetMonth(Month);
                            this.PublishDate1 =
                              str + " " + obj.PublishDate.substring(0, 4);
                            this.Author1 = obj.Author;
                            this.Deck1 = obj.Deck;
                            this.FileName1 = obj.FileName;
                          }
                        } else if (i == 2) {
                          if (
                            obj.ImageId &&
                            obj.Title &&
                            obj.PublishDate &&
                            obj.Author &&
                            obj.Deck &&
                            obj.FileName
                          ) {
                            this.imgSrc2 = obj.ImageId;
                            this.Title2 = obj.Title;
                            Month = obj.PublishDate.substring(5, 7);
                            str = this.GetMonth(Month);
                            this.PublishDate2 =
                              str + " " + obj.PublishDate.substring(0, 4);
                            this.Author2 = obj.Author;
                            this.Deck2 = obj.Deck;
                            this.FileName2 = obj.FileName;
                          }
                        } else if (i == 3) {
                          if (
                            obj.ImageId &&
                            obj.Title &&
                            obj.PublishDate &&
                            obj.Author &&
                            obj.Deck &&
                            obj.FileName
                          ) {
                            this.imgSrc3 = obj.ImageId;
                            this.Title3 = obj.Title;
                            Month = obj.PublishDate.substring(5, 7);
                            str = this.GetMonth(Month);
                            this.PublishDate3 =
                              str + " " + obj.PublishDate.substring(0, 4);
                            this.Author3 = obj.Author;
                            this.Deck3 = obj.Deck;
                            this.FileName3 = obj.FileName;
                          }
                        } else if (i == 4) {
                          if (
                            obj.ImageId &&
                            obj.Title &&
                            obj.PublishDate &&
                            obj.Author &&
                            obj.Deck &&
                            obj.FileName
                          ) {
                            this.imgSrc4 = obj.ImageId;
                            this.Title4 = obj.Title;
                            Month = obj.PublishDate.substring(5, 7);
                            str = this.GetMonth(Month);
                            this.PublishDate4 =
                              str + " " + obj.PublishDate.substring(0, 4);
                            this.Author4 = obj.Author;
                            this.Deck4 = obj.Deck;
                            this.FileName4 = obj.FileName;
                          }
                        } else if (i == 5) {
                          if (
                            obj.ImageId &&
                            obj.Title &&
                            obj.PublishDate &&
                            obj.Author &&
                            obj.Deck &&
                            obj.FileName
                          ) {
                            this.imgSrc5 = obj.ImageId;
                            this.Title5 = obj.Title;
                            Month = obj.PublishDate.substring(5, 7);
                            str = this.GetMonth(Month);
                            this.PublishDate5 =
                              str + " " + obj.PublishDate.substring(0, 4);
                            this.Author5 = obj.Author;
                            this.Deck5 = obj.Deck;
                            this.FileName5 = obj.FileName;
                          }
                        } else if (i == 6) {
                          if (
                            obj.ImageId &&
                            obj.Title &&
                            obj.PublishDate &&
                            obj.Author &&
                            obj.Deck &&
                            obj.FileName
                          ) {
                            this.imgSrc6 = obj.ImageId;
                            this.Title6 = obj.Title;
                            Month = obj.PublishDate.substring(5, 7);
                            str = this.GetMonth(Month);
                            this.PublishDate6 =
                              str + " " + obj.PublishDate.substring(0, 4);
                            this.Author6 = obj.Author;
                            this.Deck6 = obj.Deck;
                            this.FileName6 = obj.FileName;
                          }
                        } else if (i == 7) {
                          if (
                            obj.ImageId &&
                            obj.Title &&
                            obj.PublishDate &&
                            obj.Author &&
                            obj.Deck &&
                            obj.FileName
                          ) {
                            this.imgSrc7 = obj.ImageId;
                            this.Title7 = obj.Title;
                            Month = obj.PublishDate.substring(5, 7);
                            str = this.GetMonth(Month);
                            this.PublishDate7 =
                              str + " " + obj.PublishDate.substring(0, 4);
                            this.Author7 = obj.Author;
                            this.Deck7 = obj.Deck;
                            this.FileName7 = obj.FileName;
                          }
                        } else if (i == 8) {
                          if (
                            obj.ImageId &&
                            obj.Title &&
                            obj.PublishDate &&
                            obj.Author &&
                            obj.Deck &&
                            obj.FileName
                          ) {
                            this.imgSrc8 = obj.ImageId;
                            this.Title8 = obj.Title;
                            Month = obj.PublishDate.substring(5, 7);
                            str = this.GetMonth(Month);
                            this.PublishDate8 =
                              str + " " + obj.PublishDate.substring(0, 4);
                            this.Author8 = obj.Author;
                            this.Deck8 = obj.Deck;
                            this.FileName8 = obj.FileName;
                          }
                        } else if (i == 9) {
                          if (
                            obj.ImageId &&
                            obj.Title &&
                            obj.PublishDate &&
                            obj.Author &&
                            obj.Deck &&
                            obj.FileName
                          ) {
                            this.imgSrc9 = obj.ImageId;
                            this.Title9 = obj.Title;
                            Month = obj.PublishDate.substring(5, 7);
                            str = this.GetMonth(Month);
                            this.PublishDate9 =
                              str + " " + obj.PublishDate.substring(0, 4);
                            this.Author9 = obj.Author;
                            this.Deck9 = obj.Deck;
                            this.FileName9 = obj.FileName;
                          }
                        } else if (i == 10) {
                          if (
                            obj.ImageId &&
                            obj.Title &&
                            obj.PublishDate &&
                            obj.Author &&
                            obj.Deck &&
                            obj.FileName
                          ) {
                            this.imgSrc10 = obj.ImageId;
                            this.Title10 = obj.Title;
                            Month = obj.PublishDate.substring(5, 7);
                            str = this.GetMonth(Month);
                            this.PublishDate10 =
                              str + " " + obj.PublishDate.substring(0, 4);
                            this.Author10 = obj.Author;
                            this.Deck10 = obj.Deck;
                            this.FileName10 = obj.FileName;
                          }
                        } else if (i == 11) {
                          if (
                            obj.ImageId &&
                            obj.Title &&
                            obj.PublishDate &&
                            obj.Author &&
                            obj.Deck &&
                            obj.FileName
                          ) {
                            this.imgSrc11 = obj.ImageId;
                            this.Title11 = obj.Title;
                            Month = obj.PublishDate.substring(5, 7);
                            str = this.GetMonth(Month);
                            this.PublishDate11 =
                              str + " " + obj.PublishDate.substring(0, 4);
                            this.Author11 = obj.Author;
                            this.Deck11 = obj.Deck;
                            this.FileName11 = obj.FileName;
                          }
                        } else if (i == 12) {
                          if (
                            obj.ImageId &&
                            obj.Title &&
                            obj.PublishDate &&
                            obj.Author &&
                            obj.Deck &&
                            obj.FileName
                          ) {
                            this.imgSrc12 = obj.ImageId;
                            this.Title12 = obj.Title;
                            Month = obj.PublishDate.substring(5, 7);
                            str = this.GetMonth(Month);
                            this.PublishDate12 =
                              str + " " + obj.PublishDate.substring(0, 4);
                            this.Author12 = obj.Author;
                            this.Deck12 = obj.Deck;
                            this.FileName12 = obj.FileName;
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(
            () => ((this.isLoadingImage = false), (this.isDisplayImage = true))
          );
      } catch (ex) {
        console.log(ex.message);
      }
    },
    GetMonth(Month) {
      var str = "";
      if (Month == "01") {
        str = "JANUARY";
      }
      if (Month == "02") {
        str = "FEBRUARY";
      }
      if (Month == "03") {
        str = "MARCH";
      }
      if (Month == "04") {
        str = "APRIL";
      }
      if (Month == "05") {
        str = "MAY";
      }
      if (Month == "06") {
        str = "JUNE";
      }
      if (Month == "07") {
        str = "JULY";
      }
      if (Month == "08") {
        str = "AUGUST";
      }
      if (Month == "09") {
        str = "SEPTEMBER";
      }
      if (Month == "10") {
        str = "OCTOBER";
      }
      if (Month == "11") {
        str = "NOVEMBER";
      }
      if (Month == "12") {
        str = "DECEMBER";
      }
      return str;
    },
  },
};
</script>
      
<style lang="scss" scoped>
@import "@mds/constants";


.archive-heading-text {
  font-size: 16px;
  margin-left: 53px;
  margin-top: 16px;
  margin-bottom: 16px;
  color: #1e1e1e;
}

.archive-Heading-name{
  width: 1280px;
  margin: auto;
  padding: auto;
}

.top-image-and-data-main-class{
  padding: auto;
  margin: auto;
  width: 1280px;
 display: flex;
}

.archive-product-header-name{
  padding: auto;
  margin: auto;
  width: 1280px;
}

.TopPicks {
  margin-top: 0px;
  padding: auto;
  margin: auto;
  height: 480px;
  background-color: #f2f2f2;
}

.imageheader {
  max-width: 274px;
  max-height: 360.68px;
  margin-top: 59px;
}

.headertext {
  font-weight: 700;
  font-size: 10px;
  margin-top: 59px;
  margin-left: 42px;
}

.latestTitle {
  margin-bottom: 0px;
  margin-top: 36px;
  letter-spacing: 0px;
  max-height: 36px;
  font-weight: 700;
  font-size: 32px;
  line-height: 36px;
  color: #1e1e1e;
}

.latestAuthor {
  font-weight: 300;
  letter-spacing: 0px;
  font-size: 16px;
  line-height: 21px;
  width: 291px;
  max-height: 22px;
  margin-top: 3px;
  margin-bottom: 13px;
  color: #1e1e1e;
}

.latestDeck {
  font-weight: 300;
  letter-spacing: 0px;
  font-size: 20px;
  line-height: 26px;
  width: 439px;
  max-height: 84px;
  margin-top: 0px;
  margin-bottom: 32px;
  color: #1e1e1e;
}

.imageData {
  max-width: 1280px;
  margin-top: 98px;
  margin-right: auto;
 margin-left: auto;
}

.ArchiveImage {
  max-width: 274px;
  max-height: 362px;
}

.publishDate {
  max-height: 22px;
  padding-top: 4px;
  margin-top: 26px;
  margin-bottom: 0px;
  font-weight: 700;
  font-size: 10px;
  line-height: 12px;
  letter-spacing: 1.67px;
  text-transform: uppercase;
  color: #1e1e1e;
  border-top: #1e1e1e solid 2px;
  width: fit-content;
}

.TitlePdf {
  max-width: 271px;
  margin-top: 16px;
  margin-bottom: 0px;
  max-height: 105px;
  font-weight: 700;
  font-size: 32px;
  line-height: 36px;
  color: #1e1e1e;
}

.AuthorName {
  margin-top: 7px;
  margin-bottom: 13px;
  max-width: 291px;
  max-height: 22px;
  font-weight: 300;
  font-size: 16px;
  line-height: 21px;
  color: #1e1e1e;
}

.DeckInfo {
  margin-top: 0px;
  margin-bottom: 32px;
  font-weight: 300;
  font-size: 20px;
  line-height: 26px;
  font-weight: 300;
  max-width: 270px;
  max-height: 190px;
  max-height: 190px;
  color: #1e1e1e;
}

.mds-button {
  font-weight: 300;
  font-size: 15.8px;
  line-height: 21px;
  width: 117px;
  font-size: 15.8px;
  margin-bottom: 80px;
  height: 29px;
  line-height: 21px;
  color: #1e1e1e;
  padding-right: 10px;
}

.archive-newissue {
  margin-top: 0px;
  margin-bottom: 0px;
  color: #1e1e1e;
  border-top: #1e1e1e solid 2px;
  height: 22px;
  width: fit-content;
  font-weight: 700;
  line-height: 12px;
  letter-spacing: 1.67px;
  text-transform: uppercase;
  padding-top: 4px;
}

.loader1 {
  position: absolute;
  margin-left: 650px;
  margin-top: 170px;
}
.routerLink {
  text-decoration: none;
  font-family: "Univers", HelveticaNeue, "Helvetica Neue", Helvetica, Arial,
    sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 22px;
  color: #000000;
}
.router-link-exact-active {
  margin-top: 10px;
  box-shadow: inset 0 -4px 0 0 red;
  position: relative;
  padding-bottom: 13px;
}

.MainArchive {
  //max-width: 100%;
  margin-bottom: 7px;
}


.column {
  //margin-right: -87px;
  display: grid; grid-template-columns: repeat(4, 1fr)
}


.image-header-div{
  max-height: 480px;
  margin-left: 24.3%;
}

.image-and-all{
  margin-left: 20px;
}

@media only screen and(min-width: 480px) and(max-width: 520px) {
.top-image-and-data-main-class{
padding: 0px;
margin: 0px;
width: 100%;

}
  .MainArchive {
  width: 100%;
  margin-top: 3%;
  margin-bottom: 7px;
}

.archive-heading-text{
  display: none !important;
}

.image-header-div{
  width: 81.2%;
  height: 50%;
  margin-left: 50px;
  height: auto;
  padding-bottom: 0px;
}

.TopPicks {
  margin-top: 0px;
  max-width: 100%;
  height: 594px;
  background-color: #f2f2f2;
 
}

.headertext {
  font-weight: 100;
  font-size: 20px;
  margin-top: 354px;
  margin-left: -65%;
}


.imageheader{
  max-width: 242px;
  max-height: 318px;
  margin-top: 20px;
  margin-left: 20%;
}

.archive-newissue{
  margin-top: 0px;
  margin-bottom: 0px;
  color: #1e1e1e;
  border-top: #1e1e1e solid 2px;
  height: 22px;
  width: fit-content;
  font-weight: 700;
  line-height: 12px;
  letter-spacing: 1.67px;
  text-transform: uppercase;
  padding-top: 3px;
  font-size: 10px;
}


.latestTitle {
  width: 70%;
  margin-bottom: 0px;
  margin-top: 3px;
  letter-spacing: 0px;
  max-height: 86px;
  font-weight: 700;
  font-size: 20px;
  line-height: 23px;
  color: #1e1e1e;
}

.latestAuthor {
  font-weight: 300;
  letter-spacing: 0px;
  font-size: 16px;
  line-height: 21px;
  width: 242px;
  max-height: 22px;
  margin-top: 3px;
  margin-bottom: 16px;
  color: #1e1e1e;
}

.latestDeck {
  font-weight: 300;
  letter-spacing: 0px;
  font-size: 16px;
  line-height: 21px;
  width: 242px;
  max-height: 187px;
  margin-top: 0px;
  margin-bottom: 21px;
  color: #1e1e1e;
}

.imageData {
  margin-top: 4%;
  width: 100%;
  margin-right: 0%;
  margin-left: 0%;
}

.ArchiveImage {
  width: 100%;
  height: 50%;
}

.publishDate {
  max-height: 22px;
  padding-top: 3px;
  margin-top: 16px;
  margin-bottom: 0px;
  font-weight: 700;
  font-size: 10px;
  line-height: 12px;
  letter-spacing: 1.67px;
  text-transform: uppercase;
  color: #1e1e1e;
  border-top: #1e1e1e solid 2px;
  width: fit-content;
}

.TitlePdf {
  width: 171px;
  max-height: 90px;
  margin-top: 3px;
  margin-bottom: 3px;
  font-weight: 700;
  font-size: 20px;
  line-height: 23px;
  color: #1e1e1e;
}

.AuthorName {
  margin-top: 0px;
  margin-bottom: 16px;
  width: 171px;
  max-height: 22px;
  font-weight: 300;
  font-size: 16px;
  line-height: 21px;
  color: #1e1e1e;
}

.DeckInfo {
  margin-top: 0px;
  margin-bottom: 14px;
  font-weight: 300;
  font-size: 16px;
  line-height: 21px;
  font-weight: 300;
  max-width: 171px;
  max-height: 173px;
  color: #1e1e1e;
}
.column {
  margin-right: 0px;
  display: grid; grid-template-columns: repeat(2, 1fr)
}

.image-and-all{
  margin-left: 5%;
  margin-right: 5%;
}




.mds-button {
  font-weight: 300;
  width: 160px;
  font-size: 20px;
  margin-bottom: 47px;
  height: 43px;
  line-height: 26px;
  color: #1e1e1e;
  padding-left: 27px;
}

.loader1 {
  position: absolute;
  margin-left: 40%;
  margin-top: 40%;
}

}
@media only screen and(min-width: 520px) and(max-width: 540px) {
.top-image-and-data-main-class{
padding: 0px;
margin: 0px;
width: 100%;

}
  .MainArchive {
  width: 100%;
  margin-top: 5%;
  margin-bottom: 7px;
}

.archive-heading-text{
  display: none !important;
}

.image-header-div{
  width: 81.2%;
  height: 50%;
  margin-left: 50px;
  height: auto;
  padding-bottom: 0px;
}

.TopPicks {
  margin-top: 0px;
  max-width: 100%;
  height: 594px;
  background-color: #f2f2f2;
 
}

.headertext {
  font-weight: 100;
  font-size: 20px;
  margin-top: 354px;
  margin-left: -63%;
}


.imageheader{
  max-width: 242px;
  max-height: 318px;
  margin-top: 20px;
  margin-left: 22%;
}

.archive-newissue{
  margin-top: 0px;
  margin-bottom: 0px;
  color: #1e1e1e;
  border-top: #1e1e1e solid 2px;
  height: 22px;
  width: fit-content;
  font-weight: 700;
  line-height: 12px;
  letter-spacing: 1.67px;
  text-transform: uppercase;
  padding-top: 3px;
  font-size: 10px;
}


.latestTitle {
  width: 70%;
  margin-bottom: 0px;
  margin-top: 3px;
  letter-spacing: 0px;
  max-height: 86px;
  font-weight: 700;
  font-size: 20px;
  line-height: 23px;
  color: #1e1e1e;
}

.latestAuthor {
  font-weight: 300;
  letter-spacing: 0px;
  font-size: 16px;
  line-height: 21px;
  width: 242px;
  max-height: 22px;
  margin-top: 3px;
  margin-bottom: 16px;
  color: #1e1e1e;
}

.latestDeck {
  font-weight: 300;
  letter-spacing: 0px;
  font-size: 16px;
  line-height: 21px;
  width: 242px;
  max-height: 187px;
  margin-top: 0px;
  margin-bottom: 21px;
  color: #1e1e1e;
}

.imageData {
  margin-top: 4%;
  width: 100%;
  margin-right: 0%;
  margin-left: 0%;
}

.ArchiveImage {
  width: 100%;
  height: 52%;
}

.publishDate {
  max-height: 22px;
  padding-top: 3px;
  margin-top: 16px;
  margin-bottom: 0px;
  font-weight: 700;
  font-size: 10px;
  line-height: 12px;
  letter-spacing: 1.67px;
  text-transform: uppercase;
  color: #1e1e1e;
  border-top: #1e1e1e solid 2px;
  width: fit-content;
}

.TitlePdf {
  width: 171px;
  max-height: 90px;
  margin-top: 3px;
  margin-bottom: 3px;
  font-weight: 700;
  font-size: 20px;
  line-height: 23px;
  color: #1e1e1e;
}

.AuthorName {
  margin-top: 0px;
  margin-bottom: 16px;
  width: 171px;
  max-height: 22px;
  font-weight: 300;
  font-size: 16px;
  line-height: 21px;
  color: #1e1e1e;
}

.DeckInfo {
  margin-top: 0px;
  margin-bottom: 14px;
  font-weight: 300;
  font-size: 16px;
  line-height: 21px;
  font-weight: 300;
  max-width: 171px;
  max-height: 173px;
  color: #1e1e1e;
}
.column {
  margin-right: 0px;
  display: grid; grid-template-columns: repeat(2, 1fr)
}

.image-and-all{
  margin-left: 5%;
  margin-right: 5%;
}




.mds-button {
  font-weight: 300;
  width: 160px;
  font-size: 20px;
  margin-bottom: 47px;
  height: 43px;
  line-height: 26px;
  color: #1e1e1e;
  padding-left: 27px;
}

.loader1 {
  position: absolute;
  margin-left: 40%;
  margin-top: 40%;
}

}
@media only screen and(min-width: 540px) and(max-width: 560px) {
.top-image-and-data-main-class{
padding: 0px;
margin: 0px;
width: 100%;

}
  .MainArchive {
  width: 100%;
  margin-top: 5%;
  margin-bottom: 7px;
}

.archive-heading-text{
  display: none !important;
}

.image-header-div{
  width: 81.2%;
  height: 50%;
  margin-left: 50px;
  height: auto;
  padding-bottom: 0px;
}

.TopPicks {
  margin-top: 0px;
  max-width: 100%;
  height: 594px;
  background-color: #f2f2f2;
 
}

.headertext {
  font-weight: 100;
  font-size: 20px;
  margin-top: 354px;
  margin-left: -61.5%;
}


.imageheader{
  max-width: 242px;
  max-height: 318px;
  margin-top: 20px;
  margin-left: 24%;
}

.archive-newissue{
  margin-top: 0px;
  margin-bottom: 0px;
  color: #1e1e1e;
  border-top: #1e1e1e solid 2px;
  height: 22px;
  width: fit-content;
  font-weight: 700;
  line-height: 12px;
  letter-spacing: 1.67px;
  text-transform: uppercase;
  padding-top: 3px;
  font-size: 10px;
}


.latestTitle {
  width: 70%;
  margin-bottom: 0px;
  margin-top: 3px;
  letter-spacing: 0px;
  max-height: 86px;
  font-weight: 700;
  font-size: 20px;
  line-height: 23px;
  color: #1e1e1e;
}

.latestAuthor {
  font-weight: 300;
  letter-spacing: 0px;
  font-size: 16px;
  line-height: 21px;
  width: 242px;
  max-height: 22px;
  margin-top: 3px;
  margin-bottom: 16px;
  color: #1e1e1e;
}

.latestDeck {
  font-weight: 300;
  letter-spacing: 0px;
  font-size: 16px;
  line-height: 21px;
  width: 242px;
  max-height: 187px;
  margin-top: 0px;
  margin-bottom: 21px;
  color: #1e1e1e;
}

.imageData {
  margin-top: 4%;
  width: 100%;
  margin-right: 0%;
  margin-left: 0%;
}

.ArchiveImage {
  width: 100%;
  height: 53%;
}

.publishDate {
  max-height: 22px;
  padding-top: 3px;
  margin-top: 16px;
  margin-bottom: 0px;
  font-weight: 700;
  font-size: 10px;
  line-height: 12px;
  letter-spacing: 1.67px;
  text-transform: uppercase;
  color: #1e1e1e;
  border-top: #1e1e1e solid 2px;
  width: fit-content;
}

.TitlePdf {
  width: 171px;
  max-height: 90px;
  margin-top: 3px;
  margin-bottom: 3px;
  font-weight: 700;
  font-size: 20px;
  line-height: 23px;
  color: #1e1e1e;
}

.AuthorName {
  margin-top: 0px;
  margin-bottom: 16px;
  width: 171px;
  max-height: 22px;
  font-weight: 300;
  font-size: 16px;
  line-height: 21px;
  color: #1e1e1e;
}

.DeckInfo {
  margin-top: 0px;
  margin-bottom: 14px;
  font-weight: 300;
  font-size: 16px;
  line-height: 21px;
  font-weight: 300;
  max-width: 171px;
  max-height: 173px;
  color: #1e1e1e;
}
.column {
  margin-right: 0px;
  display: grid; grid-template-columns: repeat(2, 1fr)
}

.image-and-all{
  margin-left: 5%;
  margin-right: 5%;
}




.mds-button {
  font-weight: 300;
  width: 160px;
  font-size: 20px;
  margin-bottom: 47px;
  height: 43px;
  line-height: 26px;
  color: #1e1e1e;
  padding-left: 27px;
}

.loader1 {
  position: absolute;
  margin-left: 40%;
  margin-top: 40%;
}

}

@media only screen and(min-width: 560px) and(max-width: 580px) {
.top-image-and-data-main-class{
padding: 0px;
margin: 0px;
width: 100%;

}
  .MainArchive {
  width: 100%;
  margin-top: 5%;
  margin-bottom: 7px;
}

.archive-heading-text{
  display: none !important;
}

.image-header-div{
  width: 81.2%;
  height: 50%;
  margin-left: 50px;
  height: auto;
  padding-bottom: 0px;
}

.TopPicks {
  margin-top: 0px;
  max-width: 100%;
  height: 594px;
  background-color: #f2f2f2;
 
}

.headertext {
  font-weight: 100;
  font-size: 20px;
  margin-top: 354px;
  margin-left: -60%;
}


.imageheader{
  max-width: 242px;
  max-height: 318px;
  margin-top: 20px;
  margin-left: 26%;
}

.archive-newissue{
  margin-top: 0px;
  margin-bottom: 0px;
  color: #1e1e1e;
  border-top: #1e1e1e solid 2px;
  height: 22px;
  width: fit-content;
  font-weight: 700;
  line-height: 12px;
  letter-spacing: 1.67px;
  text-transform: uppercase;
  padding-top: 3px;
  font-size: 10px;
}


.latestTitle {
  width: 70%;
  margin-bottom: 0px;
  margin-top: 3px;
  letter-spacing: 0px;
  max-height: 86px;
  font-weight: 700;
  font-size: 20px;
  line-height: 23px;
  color: #1e1e1e;
}

.latestAuthor {
  font-weight: 300;
  letter-spacing: 0px;
  font-size: 16px;
  line-height: 21px;
  width: 242px;
  max-height: 22px;
  margin-top: 3px;
  margin-bottom: 16px;
  color: #1e1e1e;
}

.latestDeck {
  font-weight: 300;
  letter-spacing: 0px;
  font-size: 16px;
  line-height: 21px;
  width: 242px;
  max-height: 187px;
  margin-top: 0px;
  margin-bottom: 21px;
  color: #1e1e1e;
}

.imageData {
  margin-top: 4%;
  width: 100%;
  margin-right: 0%;
  margin-left: 0%;
}

.ArchiveImage {
  width: 100%;
  height: 54%;
}

.publishDate {
  max-height: 22px;
  padding-top: 3px;
  margin-top: 16px;
  margin-bottom: 0px;
  font-weight: 700;
  font-size: 10px;
  line-height: 12px;
  letter-spacing: 1.67px;
  text-transform: uppercase;
  color: #1e1e1e;
  border-top: #1e1e1e solid 2px;
  width: fit-content;
}

.TitlePdf {
  width: 171px;
  max-height: 90px;
  margin-top: 3px;
  margin-bottom: 3px;
  font-weight: 700;
  font-size: 20px;
  line-height: 23px;
  color: #1e1e1e;
}

.AuthorName {
  margin-top: 0px;
  margin-bottom: 16px;
  width: 171px;
  max-height: 22px;
  font-weight: 300;
  font-size: 16px;
  line-height: 21px;
  color: #1e1e1e;
}

.DeckInfo {
  margin-top: 0px;
  margin-bottom: 14px;
  font-weight: 300;
  font-size: 16px;
  line-height: 21px;
  font-weight: 300;
  max-width: 171px;
  max-height: 173px;
  color: #1e1e1e;
}
.column {
  margin-right: 0px;
  display: grid; grid-template-columns: repeat(2, 1fr)
}

.image-and-all{
  margin-left: 5%;
  margin-right: 5%;
}




.mds-button {
  font-weight: 300;
  width: 160px;
  font-size: 20px;
  margin-bottom: 47px;
  height: 43px;
  line-height: 26px;
  color: #1e1e1e;
  padding-left: 27px;
}

.loader1 {
  position: absolute;
  margin-left: 40%;
  margin-top: 40%;
}

}

@media only screen and(min-width: 580px) and(max-width: 600px) {
.top-image-and-data-main-class{
padding: 0px;
margin: 0px;
width: 100%;

}
  .MainArchive {
  width: 100%;
  margin-top: 5%;
  margin-bottom: 7px;
}

.archive-heading-text{
  display: none !important;
}

.image-header-div{
  width: 81.2%;
  height: 50%;
  margin-left: 50px;
  height: auto;
  padding-bottom: 0px;
}

.TopPicks {
  margin-top: 0px;
  max-width: 100%;
  height: 594px;
  background-color: #f2f2f2;
 
}

.headertext {
  font-weight: 100;
  font-size: 20px;
  margin-top: 354px;
  margin-left: -58.3%;
}


.imageheader{
  max-width: 242px;
  max-height: 318px;
  margin-top: 20px;
  margin-left: 28%;
}

.archive-newissue{
  margin-top: 0px;
  margin-bottom: 0px;
  color: #1e1e1e;
  border-top: #1e1e1e solid 2px;
  height: 22px;
  width: fit-content;
  font-weight: 700;
  line-height: 12px;
  letter-spacing: 1.67px;
  text-transform: uppercase;
  padding-top: 3px;
  font-size: 10px;
}


.latestTitle {
  width: 70%;
  margin-bottom: 0px;
  margin-top: 3px;
  letter-spacing: 0px;
  max-height: 86px;
  font-weight: 700;
  font-size: 20px;
  line-height: 23px;
  color: #1e1e1e;
}

.latestAuthor {
  font-weight: 300;
  letter-spacing: 0px;
  font-size: 16px;
  line-height: 21px;
  width: 242px;
  max-height: 22px;
  margin-top: 3px;
  margin-bottom: 16px;
  color: #1e1e1e;
}

.latestDeck {
  font-weight: 300;
  letter-spacing: 0px;
  font-size: 16px;
  line-height: 21px;
  width: 242px;
  max-height: 187px;
  margin-top: 0px;
  margin-bottom: 21px;
  color: #1e1e1e;
}

.imageData {
  margin-top: 4%;
  width: 100%;
  margin-right: 0%;
  margin-left: 0%;
}

.ArchiveImage {
  width: 100%;
  height: 55%;
}

.publishDate {
  max-height: 22px;
  padding-top: 3px;
  margin-top: 16px;
  margin-bottom: 0px;
  font-weight: 700;
  font-size: 10px;
  line-height: 12px;
  letter-spacing: 1.67px;
  text-transform: uppercase;
  color: #1e1e1e;
  border-top: #1e1e1e solid 2px;
  width: fit-content;
}

.TitlePdf {
  width: 171px;
  max-height: 90px;
  margin-top: 3px;
  margin-bottom: 3px;
  font-weight: 700;
  font-size: 20px;
  line-height: 23px;
  color: #1e1e1e;
}

.AuthorName {
  margin-top: 0px;
  margin-bottom: 16px;
  width: 171px;
  max-height: 22px;
  font-weight: 300;
  font-size: 16px;
  line-height: 21px;
  color: #1e1e1e;
}

.DeckInfo {
  margin-top: 0px;
  margin-bottom: 14px;
  font-weight: 300;
  font-size: 16px;
  line-height: 21px;
  font-weight: 300;
  max-width: 171px;
  max-height: 173px;
  color: #1e1e1e;
}
.column {
  margin-right: 0px;
  display: grid; grid-template-columns: repeat(2, 1fr)
}

.image-and-all{
  margin-left: 5%;
  margin-right: 5%;
}




.mds-button {
  font-weight: 300;
  width: 160px;
  font-size: 20px;
  margin-bottom: 47px;
  height: 43px;
  line-height: 26px;
  color: #1e1e1e;
  padding-left: 27px;
}

.loader1 {
  position: absolute;
  margin-left: 40%;
  margin-top: 40%;
}

}

@media only screen and (max-width: 390px) {

  .top-image-and-data-main-class{
padding: 0px;
margin: 0px;
width: 100%;

}
  .MainArchive {
  width: 100%;
  margin-top: 3%;
  margin-bottom: 7px;
}

.archive-heading-text{
  display: none !important;
}

.imageheader{
  max-width: 242px;
  max-height: 318px;
  margin-top: 20px;
  margin-left: 75px;
}

.TopPicks {
  margin-top: 0px;
  max-width: 100%;
  height: 594px;
  background-color: #f2f2f2;
 
}

.image-header-div{
  margin-left: 0px;
  height: 894px;
  padding-bottom: 116px;
}

.headertext {
  font-weight: 100;
  font-size: 20px;
  margin-top: 354px;
  margin-left: -240px;
}

.archive-newissue{
  margin-top: 0px;
  margin-bottom: 0px;
  color: #1e1e1e;
  border-top: #1e1e1e solid 2px;
  height: 22px;
  width: fit-content;
  font-weight: 700;
  line-height: 12px;
  letter-spacing: 1.67px;
  text-transform: uppercase;
  padding-top: 3px;
  font-size: 10px;
}


.latestTitle {
  margin-bottom: 0px;
  margin-top: 3px;
  letter-spacing: 0px;
  max-height: 86px;
  font-weight: 700;
  font-size: 20px;
  line-height: 23px;
  color: #1e1e1e;
}

.latestAuthor {
  font-weight: 300;
  letter-spacing: 0px;
  font-size: 16px;
  line-height: 21px;
  width: 242px;
  max-height: 22px;
  margin-top: 3px;
  margin-bottom: 16px;
  color: #1e1e1e;
}

.latestDeck {
  font-weight: 300;
  letter-spacing: 0px;
  font-size: 16px;
  line-height: 21px;
  width: 242px;
  max-height: 187px;
  margin-top: 0px;
  margin-bottom: 21px;
  color: #1e1e1e;
}

.imageData {
  margin-top: 4%;
  width: 100%;
  margin-right: 0%;
  margin-left: 0%;
}

.ArchiveImage {
  width: 171px;
  height: 225px;
}

.publishDate {
  max-height: 22px;
  padding-top: 3px;
  margin-top: 16px;
  margin-bottom: 0px;
  font-weight: 700;
  font-size: 10px;
  line-height: 12px;
  letter-spacing: 1.67px;
  text-transform: uppercase;
  color: #1e1e1e;
  border-top: #1e1e1e solid 2px;
  width: fit-content;
}

.TitlePdf {
  width: 171px;
  max-height: 90px;
  margin-top: 3px;
  margin-bottom: 3px;
  font-weight: 700;
  font-size: 20px;
  line-height: 23px;
  color: #1e1e1e;
}

.AuthorName {
  margin-top: 0px;
  margin-bottom: 16px;
  width: 171px;
  max-height: 22px;
  font-weight: 300;
  font-size: 16px;
  line-height: 21px;
  color: #1e1e1e;
}

.DeckInfo {
  margin-top: 0px;
  margin-bottom: 14px;
  font-weight: 300;
  font-size: 16px;
  line-height: 21px;
  font-weight: 300;
  max-width: 171px;
  max-height: 173px;
  color: #1e1e1e;
}
.column {
  margin-right: 0px;
  display: grid; grid-template-columns: repeat(2, 1fr)
}

.image-and-all{
  margin-left: 4%;
}




.mds-button {
  font-weight: 300;
  width: 160px;
  font-size: 20px;
  margin-bottom: 47px;
  height: 43px;
  line-height: 26px;
  color: #1e1e1e;
  padding-left: 27px;
}

.loader1 {
  position: absolute;
  margin-left: 40%;
  margin-top: 40%;
}


}

@media only screen and(min-width: 300px) and(max-width: 360px) {
  .headertext {
  font-weight: 100;
  font-size: 20px;
  margin-top: 274px;
  margin-left: -180px;
  max-width: 50%;
}
.ArchiveImage {
  width: 151px;
  height: 205px;
}

.imageheader{
  max-width: 220px;
  max-height: 238px;
  margin-top: 20px;
  margin-left: 65px;
}
.top-image-and-data-main-class{
padding: 0px;
margin: 0px;
width: 100%;

}

.latestDeck{
  width: 90%;
}


}

@media only screen and(min-width: 390px) and(max-width: 410px) {
  .headertext {
  font-weight: 100;
  font-size: 20px;
  margin-top: 354px;
  margin-left: -240px;
}


.imageheader{
  max-width: 242px;
  max-height: 318px;
  margin-top: 20px;
  margin-left: 85px;
}
.top-image-and-data-main-class{
padding: 0px;
margin: 0px;
width: 100%;

}

}

@media only screen and(min-width: 410px) and(max-width: 430px) {
  .headertext {
  font-weight: 100;
  font-size: 20px;
  margin-top: 354px;
  margin-left: -240px;
}


.imageheader{
  max-width: 242px;
  max-height: 318px;
  margin-top: 20px;
  margin-left: 90px;
}
.top-image-and-data-main-class{
padding: 0px;
margin: 0px;
width: 100%;

}

}

@media only screen and(min-width: 430px) and(max-width: 455px) {
  .headertext {
  font-weight: 100;
  font-size: 20px;
  margin-top: 354px;
  margin-left: -240px;
}


.imageheader{
  max-width: 242px;
  max-height: 318px;
  margin-top: 20px;
  margin-left: 100px;
}
.top-image-and-data-main-class{
padding: 0px;
margin: 0px;
width: 100%;

}

}
@media only screen and(min-width: 455px) and(max-width: 480px) {
  .headertext {
  font-weight: 100;
  font-size: 20px;
  margin-top: 354px;
  margin-left: -240px;
}


.imageheader{
  max-width: 242px;
  max-height: 318px;
  margin-top: 20px;
  margin-left: 110px;
}
.top-image-and-data-main-class{
padding: 0px;
margin: 0px;
width: 100%;

}


}

@media only screen and(min-width: 390px) and(max-width: 480px) {
  .MainArchive {
  width: 100%;
  margin-top: 3%;
  margin-bottom: 7px;
}

.top-image-and-data-main-class{
padding: 0px;
margin: 0px;
width: 100%;

}

.archive-heading-text{
  display: none !important;
}


.TopPicks {
  margin-top: 0px;
  max-width: 100%;
  height: 594px;
  background-color: #f2f2f2;
 
}

.image-header-div{
  margin-left: 0px;
  height: 894px;
  padding-bottom: 116px;
}


.archive-newissue{
  margin-top: 0px;
  margin-bottom: 0px;
  color: #1e1e1e;
  border-top: #1e1e1e solid 2px;
  height: 22px;
  width: fit-content;
  font-weight: 700;
  line-height: 12px;
  letter-spacing: 1.67px;
  text-transform: uppercase;
  padding-top: 3px;
  font-size: 10px;
}


.latestTitle {
  width: 70%;
  margin-bottom: 0px;
  margin-top: 3px;
  letter-spacing: 0px;
  max-height: 86px;
  font-weight: 700;
  font-size: 20px;
  line-height: 23px;
  color: #1e1e1e;
}

.latestAuthor {
  font-weight: 300;
  letter-spacing: 0px;
  font-size: 16px;
  line-height: 21px;
  width: 242px;
  max-height: 22px;
  margin-top: 3px;
  margin-bottom: 16px;
  color: #1e1e1e;
}

.latestDeck {
  font-weight: 300;
  letter-spacing: 0px;
  font-size: 16px;
  line-height: 21px;
  width: 242px;
  max-height: 187px;
  margin-top: 0px;
  margin-bottom: 21px;
  color: #1e1e1e;
}

.imageData {
  margin-top: 4%;
  width: 100%;
  margin-right: 0%;
  margin-left: 0%;
}

.ArchiveImage {
  width: 100%;
  height: 50%;
}

.publishDate {
  max-height: 22px;
  padding-top: 3px;
  margin-top: 16px;
  margin-bottom: 0px;
  font-weight: 700;
  font-size: 10px;
  line-height: 12px;
  letter-spacing: 1.67px;
  text-transform: uppercase;
  color: #1e1e1e;
  border-top: #1e1e1e solid 2px;
  width: fit-content;
}

.TitlePdf {
  width: 171px;
  max-height: 90px;
  margin-top: 3px;
  margin-bottom: 3px;
  font-weight: 700;
  font-size: 20px;
  line-height: 23px;
  color: #1e1e1e;
}

.AuthorName {
  margin-top: 0px;
  margin-bottom: 16px;
  width: 171px;
  max-height: 22px;
  font-weight: 300;
  font-size: 16px;
  line-height: 21px;
  color: #1e1e1e;
}

.DeckInfo {
  margin-top: 0px;
  margin-bottom: 14px;
  font-weight: 300;
  font-size: 16px;
  line-height: 21px;
  font-weight: 300;
  max-width: 171px;
  max-height: 173px;
  color: #1e1e1e;
}
.column {
  margin-right: 0px;
  display: grid; grid-template-columns: repeat(2, 1fr)
}

.image-and-all{
  margin-left: 4%;
}




.mds-button {
  font-weight: 300;
  width: 160px;
  font-size: 20px;
  margin-bottom: 47px;
  height: 43px;
  line-height: 26px;
  color: #1e1e1e;
  padding-left: 27px;
}

.loader1 {
  position: absolute;
  margin-left: 40%;
  margin-top: 40%;
}


}



@media only screen and(min-width: 600px) and(max-width: 680px){

.imageheader{
max-width: 142px;
max-height: 187px;
margin-top: 32px;
margin-left: 75%;
}

.headertext {
height: 40%;
width: 50%;
font-weight: 100;
font-size: 20px;
margin-top: 32px;
margin-left: 115px;
}

.top-image-and-data-main-class{
padding: 0px;
margin: 0px;
width: 100%;
margin-bottom: -30px;

}

}

@media only screen and(min-width: 680px) and(max-width: 780px){

.imageheader{
max-width: 162px;
max-height: 215px;
margin-top: 32px;
margin-left: 76%;
}

.headertext {
height: 40%;
width: 40%;
font-weight: 100;
font-size: 20px;
margin-top: 32px;
margin-left: 135px;
}

.top-image-and-data-main-class{
padding: 0px;
margin: 0px;
width: 100%;
margin-bottom: 20px;

}
}

@media only screen and(min-width: 780px) and(max-width: 880px){

.imageheader{
max-width: 185px;
max-height: 245px;
margin-top: 32px;
margin-left: 80%;
}

.headertext {
height: 40%;
width: 46%;
font-weight: 100;
font-size: 20px;
margin-top: 32px;
margin-left: 160px;
}

.top-image-and-data-main-class{
padding: 0px;
margin: 0px;
width: 100%;

}
}

@media only screen and(min-width: 880px) and(max-width: 980px){

.imageheader{
max-width: 210px;
max-height: 2650px;
margin-top: 32px;
margin-left: 80%;
}

.headertext {
height: 50%;
width: 46%;
font-weight: 100;
font-size: 20px;
margin-top: 32px;
margin-left: 185px;
}

.top-image-and-data-main-class{
padding: 0px;
margin: 0px;
width: 100%;
padding-bottom: 30px;
}
}

@media only screen and(min-width: 980px) and(max-width: 1080px){

.imageheader{
max-width: 240px;
max-height: 310px;
margin-top: 32px;
margin-left: 80%;
}

.headertext {
height: 50%;
width: 46%;
font-weight: 100;
font-size: 20px;
margin-top: 32px;
margin-left: 205px;
}

.top-image-and-data-main-class{
padding: 0px;
margin: 0px;
width: 100%;
padding-bottom: 40px;

}
}
@media only screen and(min-width: 1080px) and(max-width: 1180px){

.imageheader{
max-width: 340px;
max-height: 315px;
margin-top: 32px;
margin-left: 85%;
}

.headertext {
height: 50%;
width: 46%;
font-weight: 100;
font-size: 20px;
margin-top: 32px;
margin-left: 220px;
}

.top-image-and-data-main-class{
padding: 0px;
margin: 0px;
width: 100%;
padding-bottom: 40px;

}
}
@media only screen and(min-width: 1180px) and(max-width: 1280px){

.imageheader{
max-width: 280px;
max-height: 340px;
margin-top: 32px;
margin-left: 85%;
}

.headertext {
height: 50%;
width: 46%;
font-weight: 100;
font-size: 20px;
margin-top: 32px;
margin-left: 238px;
}

.top-image-and-data-main-class{
padding: 0px;
margin: 0px;
width: 100%;
padding-bottom: 40px;

}
}

@media only screen and (min-width: 600px) and  (max-width: 1000px){
  .MainArchive {
  width: 100%;
  margin-top: 0%;
  margin-bottom: 7px;
}

}

@media only screen and (min-width: 1000px) and  (max-width: 1200px){
  .MainArchive {
  width: 100%;
  margin-top: 0%;
  padding-top: 15px;
  margin-bottom: 7px;
}

}

@media only screen and (min-width: 6000px) and  (max-width: 8000px){
  .MainArchive {
  width: 100%;
  margin-top: 0%;
  padding-top: 55px;
  margin-bottom: 7px;
}

}

@media only screen and (min-width: 600px) and  (max-width: 1280px){




.TopPicks {
  
  margin-top: 0px;
  max-width: 100%;
  height: auto;
  background-color: #f2f2f2;
 
}

.image-header-div{
  width: 31.2%;
  height: 50%;
  margin-left: 0px;
  height: auto;
  padding-bottom: 0px;
}



.archive-newissue{
  margin-top: 0px;
  margin-bottom: 2%;
  color: #1e1e1e;
  border-top: #1e1e1e solid 1%;
  height: 100%;
  width: fit-content;
  font-weight: 700;
  line-height: 100%;
 
  text-transform: uppercase;
  padding-top: 0.5%;
  font-size: 50%;
}



.imageData {
  margin-top: 4%;
  max-width: 100%;
  height: auto;
  margin-right: 0%;
  margin-left: 0%;
}

.ArchiveImage {
  max-width: 100%;
  max-height: 100%;
}

.publishDate {
  max-height: 22px;
  padding-top: 3px;
  margin-top: 16px;
  margin-bottom: 0px;
  font-weight: 700;
  font-size: 10px;
  line-height: 12px;
  letter-spacing: 1.67px;
  text-transform: uppercase;
  color: #1e1e1e;
  border-top: #1e1e1e solid 2px;
  width: fit-content;
}

.column {
  margin-right: 0px;
  display: grid; grid-template-columns: repeat(4, 1fr)
}

.image-and-all{
  margin-left: 6%;
  width: 90%;
}



.loader1 {
  position: absolute;
  margin-left: 40%;
  margin-top: 40%;
}


.mds-button {
  width: 50%;
  height: 5%;
  font-weight: 300;
  font-size: 80%;
  line-height: 80%;
  color: #1e1e1e;
  padding-left: 5%;
  padding-right: 3%;
}

.top-side-button{
  width: 30%;
  height: 50%;
}




}

@media only screen and (min-width: 600px) and  (max-width: 680px){

  .mds-button {
  width: 50%;
  height: 5%;
  font-weight: 300;
  font-size: 60%;
  line-height: 100%;
  color: #1e1e1e;
  padding-left: 5%;
  padding-right: 3%;
}

.top-side-button{
  width: 100%;
  height: 100%;
  padding-left: 15%;
 
}

.top-button-div{
  width: 30%;
  height: 50%;
}

.archive-heading-text{
 margin-left: 50px;
}


}



@media only screen and (min-width: 480px) and  (max-width: 580px){

.archive-heading-text{
 margin-left: 20px;
}

}

@media only screen and (min-width: 680px) and  (max-width: 780px){

.mds-button {
width: 70%;
height: 4%;
font-weight: 300;
font-size: 80%;
line-height: 100%;
color: #1e1e1e;
padding-left: 10%;
padding-right: 3%;
}

.top-side-button{
width: 100%;
height: 100%;
padding-left: 15%;

}

.top-button-div{
width: 40%;
height: 50%;
}
.archive-heading-text{
 margin-left: 55px;
}

}


@media only screen and (min-width: 780px) and  (max-width: 880px){

.mds-button {
width: 60%;
height: 4%;
font-weight: 300;
font-size: 80%;
line-height: 100%;
color: #1e1e1e;
padding-left: 10%;
padding-right: 3%;
}

.top-side-button{
width: 100%;
height: 100%;
padding-left: 15%;

}

.top-button-div{
width: 30%;
height: 50%;
}

.archive-heading-text{
 margin-left: 65px;
}

}

@media only screen and (min-width: 880px) and  (max-width: 980px){

.mds-button {
width: 55%;
height: 4%;
font-weight: 300;
font-size: 80%;
line-height: 100%;
color: #1e1e1e;
padding-left: 10%;
padding-right: 3%;
}

.top-side-button{
width: 100%;
height: 100%;
padding-left: 15%;

}

.top-button-div{
width: 28%;
height: 50%;
}
.archive-heading-text{
 margin-left: 85px;
}
}

@media only screen and (min-width: 980px) and  (max-width: 1080px){

.mds-button {
width: 43%;
height: 4%;
font-weight: 300;
font-size: 80%;
line-height: 100%;
color: #1e1e1e;
padding-left: 6%;
padding-right: 3%;
}

.top-side-button{
width: 100%;
height: 100%;
padding-left: 15%;

}

.top-button-div{
width: 25%;
height: 50%;
}
.archive-heading-text{
 
 margin-left: 95px;
}
}

@media only screen and (min-width: 1080px) and  (max-width: 1180px){

.mds-button {
width: 40%;
height: 4%;
font-weight: 300;
font-size: 80%;
line-height: 100%;
color: #1e1e1e;
padding-left: 5%;
padding-right: 3%;
}

.top-side-button{
width: 100%;
height: 100%;
padding-left: 15%;

}

.top-button-div{
width: 23%;
height: 50%;
}
.archive-heading-text{

 margin-left: 105px;
}
}
@media only screen and (min-width: 1180px) and  (max-width: 1278px){

.mds-button {
width: 35%;
height: 4%;
font-weight: 300;
font-size: 80%;
line-height: 100%;
color: #1e1e1e;
padding-left: 4%;
padding-right: 3%;
}

.top-side-button{
width: 100%;
height: 100%;
padding-left: 12%;

}

.top-button-div{
width: 20%;
height: 50%;
}
.archive-heading-text{

 margin-left: 110px;
}
}
@media only screen and (min-width: 600px) and  (max-width: 999px){
.archive-heading-text {
  font-size: 16px;
  margin-top: -36px;
  margin-bottom: 16px;
  color: #1e1e1e;
}
}

@media only screen and (min-width: 999px) and  (max-width: 1200px){
.archive-heading-text {
  font-size: 16px;
  margin-top: -16px;
  margin-bottom: 16px;
  color: #1e1e1e;
}
}
@media only screen and (min-width: 768px) and  (max-width: 1200px){
  .latestTitle {
  margin-bottom: 0px;
  margin-top: 36px;
  letter-spacing: 0px;
  max-height: 86px;
  font-weight: 700;
  font-size: 32px;
  line-height: 36px;
  color: #1e1e1e;
}

.latestAuthor {
  font-weight: 300;
  letter-spacing: 0px;
  font-size: 16px;
  line-height: 21px;
  width: 242px;
  max-height: 22px;
  margin-top: 3px;
  margin-bottom: 13px;
  color: #1e1e1e;
}

}

@media only screen and (min-width: 768px) and  (max-width: 915px){
  .latestDeck {
  font-weight: 300;
  letter-spacing: 0px;
  font-size: 20px;
  line-height: 26px;
  width: 220px;
  max-height: 187px;
  margin-top: 0px;
  margin-bottom: 32px;
  color: #1e1e1e;
}
}

@media only screen and (min-width: 768px) and  (max-width: 1200px){

  .TitlePdf {
  width: 100%;
  max-height: 900px;
  margin-top: 16px;
  margin-bottom: 0px;
  font-weight: 700;
  font-size: 32px;
  line-height: 36px;
  color: #1e1e1e;
}

.AuthorName {
  margin-top: 7px;
  margin-bottom: 13px;
  width: 50%;
  max-height: 2200px;
  font-weight: 300;
  font-size: 16px;
  line-height: 21px;
  color: #1e1e1e;
}

.DeckInfo {
  margin-top: 0px;
  margin-bottom: 32px;
  font-weight: 300;
  font-size: 20px;
  line-height: 26px;
  font-weight: 300;
  max-width: 80%;
  max-height: 1730px;
  color: #1e1e1e;
}
}

@media only screen and (min-width: 600px) and  (max-width: 768px){
.TitlePdf {
  width: 80%;
  max-height: 900px;
  margin-top: 3px;
  margin-bottom: 3px;
  font-weight: 700;
  font-size: 20px;
  line-height: 23px;
  color: #1e1e1e;
}

.AuthorName {
  margin-top: 0px;
  margin-bottom: 16px;
  width: 50%;
  max-height: 2200px;
  font-weight: 300;
  font-size: 16px;
  line-height: 21px;
  color: #1e1e1e;
}

.DeckInfo {
  margin-top: 0px;
  margin-bottom: 14px;
  font-weight: 300;
  font-size: 16px;
  line-height: 21px;
  font-weight: 300;
  max-width: 80%;
  max-height: 1730px;
  color: #1e1e1e;
}
.latestTitle {
  width: 90%;
  margin-bottom: 0px;
  margin-top: 3px;
  letter-spacing: 0px;
  max-height: 860px;
  font-weight: 700;
  font-size: 20px;
  line-height: 23px;
  color: #1e1e1e;
}

.latestAuthor {
  font-weight: 300;
  letter-spacing: 0px;
  font-size: 16px;
  line-height: 21px;
  width: 242px;
  max-height: 220px;
  margin-top: 3px;
  margin-bottom: 16px;
  color: #1e1e1e;
}

.latestDeck {
  font-weight: 300;
  letter-spacing: 0px;
  font-size: 16px;
  line-height: 21px;
  width: 242px;
  max-height: 1870px;
  margin-top: 0px;
  margin-bottom: 21px;
  color: #1e1e1e;
}

}

@media only screen and (min-width: 600px) and  (max-width: 1000px){
.archive-heading-text{
  display: none !important;
}

.MainArchive {
 
  margin-top: 5%;
 
}
}
@media only screen and (min-width: 320px) and  (max-width: 600px){
  .latestTitle{
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
  }
}

</style>
      