var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"msi-tortoise-hare-watchlist-container"},[_c('div',{staticClass:"msi-tortoise-hare-watchlist-loader"},[(_vm.isLoading)?_c('mds-loader',{attrs:{"aria-label":"Medium Loader"}}):_vm._e()],1),_c('div',{staticClass:"msi-tortoise-hare-watchlist-header-text"},[_c('div',{staticClass:"msi-tortoise-hare-watchlist-middle-content"},[_c('div',{staticClass:"msi-tortoise-hare-watchlist-title"},[_vm._v("Tortoise & Hare Watchlist")]),_c('div',{staticClass:"msi-tortoise-hare-watchlist-download-holding"},[_c('mds-button',{staticClass:"msi-tortoise-hare-watclist-download-data",attrs:{"variation":"flat","size":"small","icon":"document"},on:{"click":_vm.ExportExcel}},[_vm._v(" Download data ")])],1)]),_c('div',{staticClass:"msi-tortoise-hare-watchlist-analyst-text"},[_vm._v(" Click on company name for latest Morningstar Analyst Report. ")]),_c('div',{staticClass:"msi-tortoise-hare-watchlist-analyst-text-sec"},[_vm._v(" Click on ticker for additional stock data from Morningstar.com. ")])]),_c('div',{staticStyle:{"padding-top":"24px"}},[_c('mds-table',{staticClass:"msi-tortoise-hare-watchlist-data-table",attrs:{"show-sortable":"","fixed-first-col":""}},[_c('mds-thead',{attrs:{"fixedHeader":""}},_vm._l((_vm.headers),function(header,index){return _c('mds-th',{key:index,style:(header.style),attrs:{"right-aligned":header.align === 'right',"sortable":header.sortable,"sorted":header.sorted},on:{"mds-th-sort-changed":function($event){return _vm.handleSortEvent(index, header.fieldName, $event)}}},[_c('span',{staticClass:"msi-tortoise-hare-watchlist-table-header",domProps:{"innerHTML":_vm._s(header.text)}})])}),1),(_vm.isDisplay)?_c('mds-tbody',_vm._l((_vm.rows),function(row,index){return _c('mds-tr',{key:index},[_c('mds-td',{staticStyle:{"padding-left":"5px"},attrs:{"left-align":""}},[(
                                  row.Symbol == '0' ||
                                  row.Symbol == '' ||
                                  row.Symbol == 'NaN' ||
                                  row.Symbol == '&mdash;' ||
                                  row.Symbol == null ||
                                  row.Symbol == '&nbsp;'
                                )?_c('div',[_vm._v(" — ")]):_c('div',{staticClass:"msi-tortoise-hare-watchlist-ticker",on:{"click":function($event){return _vm.redirectToStockQuote(row.ExchangeId,row.Symbol)}}},[_vm._v(" "+_vm._s(row.Symbol)+" ")])]),_c('mds-td',{staticStyle:{"padding-left":"16px"}},[(
                                  row.StandardName == '0' ||
                                  row.StandardName == '' ||
                                  row.StandardName == 'NaN' ||
                                  row.StandardName == '&mdash;' ||
                                  row.StandardName == null ||
                                  row.StandardName == '&nbsp;'
                                )?_c('div',[_vm._v(" — ")]):_c('div',[_c('a',{staticClass:"msi-tortoise-hare-watchlist-first-row",attrs:{"target":"_blank","href":_vm.CurrentURL},domProps:{"innerHTML":_vm._s(row.StandardName)},on:{"mouseover":function($event){return _vm.openMedalistReport(row.SecId,row.Symbol)},"touchstart":function($event){return _vm.openMedalistReport(row.SecId,row.Symbol)}}})])]),_c('mds-td',{staticStyle:{"padding":"8px 24px"},attrs:{"left-align":""}},[(
                                  row.StarRating == '0' ||
                                  row.StarRating == '' ||
                                  row.StarRating == 'NaN' ||
                                  row.StarRating == '&mdash;' ||
                                  row.StarRating == null ||
                                  row.StarRating == '&nbsp;'
                                )?_c('div',[_vm._v(" — ")]):_c('div',[_c('mds-star-rating',{attrs:{"rating":parseInt(row.StarRating),"custom-size":14}})],1)]),_c('mds-td',{staticStyle:{"padding-left":"10px"}},[(
                                  row.EconomicMoat == '0' ||
                                  row.EconomicMoat == '' ||
                                  row.EconomicMoat == 'NaN' ||
                                  row.EconomicMoat == '&mdash;' ||
                                  row.EconomicMoat == null ||
                                  row.EconomicMoat == '&nbsp;'
                                )?_c('div',[_vm._v(" — ")]):_c('div',[_c('span',{domProps:{"innerHTML":_vm._s(row.EconomicMoat)}})])]),_c('mds-td',{staticStyle:{"padding-left":"15px"}},[(
                                  row.Stewardship == '0' ||
                                  row.Stewardship == '' ||
                                  row.Stewardship == 'NaN' ||
                                  row.Stewardship == '&mdash;' ||
                                  row.Stewardship == null ||
                                  row.Stewardship == '&nbsp;'
                                )?_c('div',[_vm._v(" — ")]):_c('div',[_c('span',{domProps:{"innerHTML":_vm._s(row.Stewardship)}})])]),_c('mds-td',{staticStyle:{"padding-left":"15px"}},[(
                                  row.Risk == '0' ||
                                  row.Risk == '' ||
                                  row.Risk == 'NaN' ||
                                  row.Risk == '&mdash;' ||
                                  row.Risk == null ||
                                  row.Risk == '&nbsp;'
                                )?_c('div',[_vm._v(" — ")]):_c('div',[_c('span',{domProps:{"innerHTML":_vm._s(row.Risk)}})])]),_c('mds-td',{attrs:{"right-aligned":""}},[(
                                  row.LastClose == '0' ||
                                  row.LastClose == '' ||
                                  row.LastClose == 'NaN' ||
                                  row.LastClose == '&mdash;' ||
                                  row.LastClose == null ||
                                  row.LastClose == '&nbsp;'
                                )?_c('div',[_vm._v(" — ")]):_c('div',[_vm._v(" "+_vm._s(_vm._f("amountFilter")(row.LastClose))+" ")])]),_c('mds-td',{staticStyle:{"padding-left":"10px"},attrs:{"right-aligned":""}},[(
                                  row.PriceChangePct == '0' ||
                                  row.PriceChangePct == '' ||
                                  row.PriceChangePct == 'NaN' ||
                                  row.PriceChangePct == '&mdash;' ||
                                  row.PriceChangePct == null ||
                                  row.PriceChangePct == '&nbsp;'
                                )?_c('div',[_vm._v(" — ")]):_c('div',[_vm._v(" "+_vm._s(_vm._f("customDataFilter")(row.PriceChangePct))+" ")])]),_c('mds-td',{attrs:{"right-aligned":""}},[(
                                  row.FairValue == '0' ||
                                  row.FairValue == '' ||
                                  row.FairValue == 'NaN' ||
                                  row.FairValue == '&mdash;' ||
                                  row.FairValue == null ||
                                  row.FairValue == '&nbsp;'
                                )?_c('div',[_vm._v(" 0.00 ")]):_c('div',[_vm._v(" "+_vm._s(_vm._f("amountFilter")(row.FairValue))+" ")])]),_c('mds-td',{attrs:{"right-aligned":""}},[(
                                  row.PFV == '0' ||
                                  row.PFV == '' ||
                                  row.PFV == 'NaN' ||
                                  row.PFV == '&mdash;' ||
                                  row.PFV == null ||
                                  row.PFV == '&nbsp;'
                                )?_c('div',[_vm._v(" — ")]):_c('div',[_c('span',{domProps:{"innerHTML":_vm._s(row.PFV)}})])]),_c('mds-td',{staticStyle:{"padding-left":"10px"},attrs:{"right-aligned":""}},[(
                                  row.ForwardPE == '0' ||
                                  row.ForwardPE == '' ||
                                  row.ForwardPE == 'NaN' ||
                                  row.ForwardPE == '&mdash;' ||
                                  row.ForwardPE == null ||
                                  row.ForwardPE == '&nbsp;'
                                )?_c('div',[_vm._v(" — ")]):_c('div',[_vm._v(" "+_vm._s(row.ForwardPE)+" ")])]),_c('mds-td',{attrs:{"right-aligned":""}},[(
                                  row.ForwardDividendYield == '0' ||
                                  row.ForwardDividendYield == '' ||
                                  row.ForwardDividendYield == 'NaN' ||
                                  row.ForwardDividendYield == '&mdash;' ||
                                  row.ForwardDividendYield == null ||
                                  row.ForwardDividendYield == '&nbsp;'
                                )?_c('div',[_vm._v(" — ")]):_c('div',[_vm._v(" "+_vm._s(row.ForwardDividendYield)+" ")])]),_c('mds-td',{staticStyle:{"padding-left":"27px"}},[(
                                  row.SectorName == '0' ||
                                  row.SectorName == '' ||
                                  row.SectorName == 'NaN' ||
                                  row.SectorName == '&mdash;' ||
                                  row.SectorName == null ||
                                  row.SectorName == '&nbsp;'
                                )?_c('div',[_vm._v(" — ")]):_c('div',[_vm._v(" "+_vm._s(row.SectorName)+" ")])])],1)}),1):_vm._e()],1),_c('span',{staticClass:"msi-tortoise-hare-watchlist-data-text"},[_vm._v("Data through "+_vm._s(_vm._f("formatDateValForDataTables")(new Date()))+" UR=Under Review")]),_c('div',{staticClass:"msi-tortoise-hare-watchlist-first-border"}),_vm._m(0)],1)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"msi-tortoise-hare-watchlist-balance-text"},[_vm._v(" The above information is provided for illustrative purposes only. The data and statistics presented are based on the most recent information available from Morningstar, Inc. and may or may not be an accurate reflection of current data. Investing in securities is subject to investment risk, including possible loss of principal. Past performance does not guarantee future results. There can be no assurance that any financial strategy will be successful. "),_c('br'),_c('br'),_vm._v(" The information, data, analyses, and opinions presented herein do not constitute investment advice, are provided as of the date written, are provided solely for informational purposes and therefore are not an offer to buy or sell a security. Please note that references to specific securities or other investment options within this piece should not be considered an offer (as defined by the Securities and Exchange Act) to purchase or sell that specific investment. "),_c('br'),_c('br'),_vm._v(" The Tortoise and Hare Watchlists contains 120 companies that could potentially fit with the investment mandate of Morningstar’s Tortoise or Hare portfolios. These may include companies with wide or narrow moats, positive or stable moat trends, and below-average price/fair value ratios according to Morningstar analysts at the time the Watchlists are compiled. Watchlists are provided for informational purposes only. You should not assume that specific securities on the Watchlists are being considered for inclusion in Morningstar’s Tortoise or Hare portfolios or will be profitable. "),_c('br'),_c('br'),_vm._v(" Source: Morningstar Rating, rankings, categorizations, and fair value estimates are based on the analysis and estimates of Morningstar analysts. "),_c('br'),_c('br')])
}]

export { render, staticRenderFns }