<template lang="html">
  <div class="data-missing">
    <div class="data-missing-message-container">
      <div class="data-missing-content">
        <h1 class="data-missing-error">ERROR</h1>
        <div class="data-missing-message-heading">{{ errorMessage }}</div>
        <div class="data-missing-message-link"> Try searching, or go to our <router-link :to="{ name: 'home'}">homepage.</router-link></div>
      </div>
    </div>
  </div>
</template>

<script lang="js">

  export default  {
    name: 'data-missing',
    props: {
      errorMessage:{
        type: String,
        required: true
      }
    },
}


</script>

<style scoped>
  .data-missing {
    width:100%;
    background:#f2f2f2;
    margin:0px;
    padding:0px;
    min-height: calc(100vh - 50px);
  }
  .data-missing-message-container{
    width:100%;
    padding-top:50px;

  }
  .data-missing-content{
    width:900px;
    padding:10px;
    margin:auto;
    padding:auto;
  }
  .data-missing-error{
    font-size: 14px;
    letter-spacing: 1.67px;
    line-height: 12px;
    margin-bottom:30px;
  }
  .data-missing-message-heading{
    font-size:70px;
    font-style:normal;
    font-weight:600;
    margin-bottom:24px;
  }
  .data-missing-message-link{
    font-style: normal;
    font-size: 32px;
    font-weight: 200;
    line-height: 36px;
  }
  .data-missing-message-link a{
    color: #5e5e5e;
    font-weight: inherit;
    outline:0;
    text-decoration:none;
    border-bottom: 1px solid #ababab;
    font-style: inherit;
  }
  .data-missing-message-link a:hover{
    border-color: #006fba;
    color: #006fba
  }
</style>
