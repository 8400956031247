<template>
  <div class="header-main-div">
    <div>
      <div>
        <div v-if="isMobileHeaderToggle" class="header-main-responsive">

          <div class="responsive-header-main-div-header-section">
            <responsive-header></responsive-header>
          </div>
          <div class="product-logo-main-div-header-section">
            <product-logo></product-logo>
          </div>
          <div class="search-and-user-main-div-header-section">
            <div class="responsive-search" v-if="!isMarketingPage">
              <mds-button v-if="this.$store.state.user.LoggedIn" variation="icon-only"
                @click="isSearchField = !isSearchField" icon="search" type="button">
              </mds-button>
            </div>
            <user-profile></user-profile>
          </div>
        </div>

        <div class="header-container" v-else>
          <product-selector v-if="!isMarketingPage || this.$route.name == 'myAccount'"></product-selector>
          <div class="logo-and-buttons">
            <mds-layout-grid>
              <mds-row>
                <mds-col :cols="3"></mds-col>
                <mds-col :cols="5">
                  <div class="product-logo">
                    <product-logo></product-logo>
                  </div>
                </mds-col>
                <mds-col :cols="4">
                  <div>
                    <search v-if="!isMarketingPage"></search>
                    <user-subscription></user-subscription>
                  </div>
                </mds-col>
              </mds-row>
            </mds-layout-grid>

          </div>
          <div :class="this.$store.state.product.CurrentProduct != 'Common' ? 'search-error-div' : ''">
            <search-error></search-error>
          </div>
          <div>
          </div>
          <product-navigation></product-navigation>
        </div>


        <div v-if="isSearchField">
          <div class="responsive-search-main-div">
            <div v-if="isSearchField">
              <mds-search-field placeholder="Search..." label="Search" icon="search" size="large"
                class="search-div-responsive" @keyup.enter="Search()" v-model="searchKeyword"></mds-search-field>
            </div>
            <div>
              <search-error></search-error>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import ProductNavigation from "./ProductNavigation.vue";
import ProductSelector from "./ProductSelector.vue";
import UserProfile from "./UserProfile.vue";
import Search from "./Search/Search.vue";
import SearchError from "./Search/SearchError.vue"
import ProductLogo from "./ProductLogo.vue";
import UserSubscription from "./UserSubscription.vue";
import ResponsiveHeader from "./ResponsiveHeader.vue";
import { MdsLayoutGrid, MdsRow, MdsCol } from "@mds/layout-grid";
import MdsSearchField from "@mds/search-field";
import {
  MdsButton
} from '@mds/button';
import { mapState } from "vuex";

export default {
  name: "header-section",
  components: {
    ProductNavigation,
    ProductSelector,
    UserProfile,
    Search,
    ProductLogo,
    UserSubscription,
    ResponsiveHeader,
    MdsLayoutGrid,
    MdsRow,
    MdsCol,
    MdsSearchField,
    MdsButton,
    SearchError
  },

  data() {
    return {
      isMobileHeaderToggle: null,
      //viewPortWidth: null,
      isSearchField: false,
      searchKeyword: "",
      isMarketingPage: false,
    };
  },
  methods: {
    Search() {
      if (this.searchKeyword != "") {
        this.$router.push({
          name: "searchResults",
          params: {
            currentproduct: this.$session.get("current-product"),
            keyword: this.searchKeyword,
          },
        }).catch(err => {
          if (
            err.name !== 'NavigationDuplicated' &&
            !err.message.includes('Avoided redundant navigation to current location')
          ) {
            console.log(err);
          }
        });
      }
    },
  },

  mounted() {
    this.isMarketingPage = this.$store.state.marketing.isMarketing;
    this.isMobileHeaderToggle = this.$store.state.responsive.isMobileHeader;
    //this.isSearchFieldToggle=this.$store.state.responsive.isSearchField;

  },

  computed: mapState({
    /*
    isSearchField: state => state.responsive.isSearchField,
    isSearchFieldAlias:'isSearchField',
    isSearchFieldState(state){
      return state.responsive.isSearchField
    },*/
    isMobileHeader: state => state.responsive.isMobileHeader,
    isMobileHeaderAlias: 'isMobileHeader',
    isMobileHeaderState(state) {
      return state.responsive.isMobileHeader
    },
    isMarketing: state => state.marketing.isMarketing,
    isMarketingAlias: 'isMarketing',
    isMarketingState(state) {
      return state.marketing.isMarketing
    }

  }),
  watch: {
    /*
    isSearchFieldState(){
      this.isSearchFieldToggle = this.isSearchFieldState;
    },*/
    isMobileHeaderState() {
      this.isMobileHeaderToggle = this.isMobileHeaderState;
    },
    isMarketingState() {
      this.isMarketingPage = this.isMarketingState;
    },
    searchKeyword(newVal, oldVal) {
      if (newVal != "") {
        if (newVal != oldVal) {
          if (newVal.trim().length == 0) {
            this.$store.state.search.InvalidSearchTerm = true;
          } else if (!/^[A-Za-z0-9\s]*$/.test(newVal)) {
            this.$store.state.search.InvalidSearchTerm = true;
          } else {
            this.$store.state.search.InvalidSearchTerm = false;
          }
        }
      } else {
        this.$store.state.search.InvalidSearchTerm = false;
      }
    },
  }
};
</script>

<style scoped>
.search-div-responsive {
  width: 95%;
  margin-left: 10px;
  margin-top: 20px;
  margin-bottom: 10px;

}

.header-main-responsive {
  width: 100%;
  display: flex;
  height: 50px;
  padding-top: 0px;
}

.responsive-header-main-div-header-section {
  width: 20%;
}

.product-logo-main-div-header-section {
  width: 60%;
}

.search-and-user-main-div-header-section {
  width: 20%;
}

.logo-and-buttons {
  height: 0px;
  margin: 0;
  padding: 0;
}

.header-main-div {}

.header-container{
  min-height:50px;
  /*max-height: 160px; */
  margin-top: 17px;
}

.search-error-div {
  margin-top: 44px;
}

@media only screen and (min-width: 600px) and (max-width: 1000px) {
  .responsive-search-main-div {
    margin-top: 50px;
  }

  .search-div-responsive {
    width: 97%;
    margin-left: 10px;
    margin-top: 20px;
    margin-bottom: 10px;
  }
}


@media only screen and (min-width: 1000px) and (max-width: 128000px) {
  .responsive-search-main-div {
    display: none;
  }

}
</style>
