import axiosInstance from './axiosInstance';  // Import your axios instance
import { refreshToken } from './authToken';   // Import token refresh logic

// Helper function to retry requests after refreshing token
async function retryRequest(requestFunction, ...args) {
  try {
    await refreshToken();  // Refresh the token
    return requestFunction(...args);  // Retry the original request with new token
  } catch (refreshError) {
    console.error('Token refresh failed:', refreshError);
    throw refreshError;  // Re-throw the error if token refresh fails
  }
}

// Fetch Data
export async function fetchData(endpoint, params = {}, headers = {}) {
  try {
   // headers["accept"] ="application/json";
    return await axiosInstance.get(endpoint, { params, headers });
  } catch (error) {
    console.error('Error fetching data:', error);

    // If unauthorized (401), attempt to refresh token and retry the request
    if (error.response && error.response.status === 401) {
      return retryRequest(fetchData, endpoint, params, headers);  // Retry after refresh
    }

    throw error;  // Re-throw other errors
  }
}

// Post Data
export async function postData(endpoint, body, params = {}) {
  try {
    return await axiosInstance.post(endpoint, body, { params });
  } catch (error) {
    console.error('Error posting data:', error);

    // If unauthorized (401), attempt to refresh token and retry the request
    if (error.response && error.response.status === 401) {
      return retryRequest(postData, endpoint, body, params);  // Retry after refresh
    }

    throw error;  // Re-throw other errors
  }
}

// Put Data
export async function putData(endpoint, body, params = {}) {
  try {
    return await axiosInstance.put(endpoint, body, { params });
  } catch (error) {
    console.error('Error updating data:', error);

    // If unauthorized (401), attempt to refresh token and retry the request
    if (error.response && error.response.status === 401) {
      return retryRequest(putData, endpoint, body, params);  // Retry after refresh
    }

    throw error;  // Re-throw other errors
  }
}

// Delete Data
export async function deleteData(endpoint, params = {}) {
  try {
    return await axiosInstance.delete(endpoint, { params });
  } catch (error) {
    console.error('Error deleting data:', error);

    // If unauthorized (401), attempt to refresh token and retry the request
    if (error.response && error.response.status === 401) {
      return retryRequest(deleteData, endpoint, params);  // Retry after refresh
    }

    throw error;  // Re-throw other errors
  }
}
