import { fetchData } from "@/utilities/API/apiUtility";

export default{
    mounted() {
        
    },
    data() {
        return {
            ProductCode :""
        }
    },
    methods:{
        getUserDetailsMixin(ProductCode) {
            if(this.$auth0 && this.$auth0.user){
                if(this.$auth0.user["https://morningstar.com/mstar_id"]){
                    this.userID = this.$auth0.user["https://morningstar.com/mstar_id"];
                    this.ProductCode = "";
                    if(ProductCode.toLowerCase() == "mfi"){
                        if(localStorage.getItem("userType_MFI")){
                            return false;
                        }
                        this.ProductCode = "NF00000001";
                    }
                    else if(ProductCode.toLowerCase()  == "msi"){
                        if(localStorage.getItem("userType_MSI")){
                            return false;
                        }
                        this.ProductCode = "NM00000001";
                    }
                    else if(ProductCode.toLowerCase()  == "mei" || ProductCode == "etf"){
                        if(localStorage.getItem("userType_ETF")){
                            return false;
                        }
                        this.ProductCode = "NET0000001";
                    }
                    else if(ProductCode.toLowerCase()  == "mdi"){
                        if(localStorage.getItem("userType_MDI")){
                            return false;
                        }
                        this.ProductCode = "NDI0000001";
                    }

                    if(this.userID && ProductCode){
                        var getUserDetailsAPIURL = process.env.VUE_APP_BackEndAPI + process.env.VUE_APP_GetUserDetails;
                        getUserDetailsAPIURL = getUserDetailsAPIURL.replace("{userId}", this.userID);
                        try {
                            fetchData(getUserDetailsAPIURL, {
                                    headers: {
                                        Accept: "application/json",
                                    },
                                })
                                .then((response) => {
                                    if (response) {
                                        if (response.status == 200) {
                                            if (response.data) {
                                                this.userDetails = JSON.parse(response.data);
                                                if (this.userDetails) {
                                                    if (this.userDetails["Email"] && this.ProductCode) {
                                                        this.getSourceCode(this.userDetails["Email"],this.ProductCode)
                                                    } 
                                                }
                                            }

                                        }
                                    }
                                })
                                .catch((error) => {
                                    console.log(error);
                                })
                                .finally(() => (this.isLoading = false));
                        } catch (ex) {
                            console.log(ex.message);
                        }
                    }
                }
            }
        },
        async getSourceCode(EmailId,ProductCode) {
            var getSourceCodeUrl = process.env.VUE_APP_BackEndAPI + process.env.VUE_APP_SourceCode;
            getSourceCodeUrl = getSourceCodeUrl.replace("{EmailId}", EmailId);
            getSourceCodeUrl = getSourceCodeUrl.replace("{ProductCode}", ProductCode);
            try {
                fetchData(getSourceCodeUrl, {
                        headers: {
                            Accept: "application/json",
                        },
                    })
                    .then((response) => {
                        if (response) {
                            if (response.status == 200) {
                                if (response.data) {
                                    this.response = JSON.parse(response.data);
                                    if (this.response) {
                                        if(this.response[0]){
                                            if(this.response[0]["SourceCode"]){
                                                this.checkUserType(this.response[0]["SourceCode"],ProductCode);
                                            }
                                        }
                                        
                                    }
                                }

                            }
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                    })
                    .finally(() => (this.isLoading = false));
            } catch (ex) {
                console.log(ex.message);
            }
        },
        async checkUserType(SourceCode,ProductCode){
            let userType = "";
            SourceCode = SourceCode.toUpperCase();
            if(SourceCode == "BFNCOMP1YRCP" || SourceCode == "BMSCOMP1YRCP" || SourceCode == "BDICOMP1YRCP" || SourceCode == "BETCOMP1YRCP" || 
            SourceCode == "BFNCMP10YRCP" || SourceCode == "BMSCMP10YRCP" || SourceCode == "BDICMP10YRCP" || SourceCode == "BETCMP10YRCP"|| 
            SourceCode == "99NFSN10COMP" || SourceCode == "99NMST10COMP"|| SourceCode == "99NDIT10COMP" || SourceCode == "99NETT10COMP"){
                userType = "TDA";
            }   
            else{
                userType = "paid";
            }
            if(ProductCode == "NF00000001"){
                localStorage.setItem("userType_MFI", userType);
            }
            else if(ProductCode  == "NM00000001"){
                localStorage.setItem("userType_MSI", userType);
            }
            else if(ProductCode == "NET0000001"){
                localStorage.setItem("userType_ETF", userType);
            }
            else if(ProductCode  == "NDI0000001"){
                localStorage.setItem("userType_MDI", userType);
            }
           // return userType;

        }
    }
}