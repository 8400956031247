<template>
	<div
		:class="{
			[$style['mdc-metadata']]: true,
			[$style['mdc-metadata--large']]: large
		}"
	>
		<mdc-image
			v-if="thumbnail"
			presentational
			aspect-ratio="1:1"
			:size="large ? '32px': '18px'"
			:src="thumbnail"
			:class="{
				[$style['mdc-metadata__thumbnail']]: true,
				[$style['mdc-metadata__thumbnail--large']]: large,
			}"
		/>
		<div :class="$style['mdc-metadata__list']">
			<slot />
		</div>
	</div>
</template>

<script>
import MdcImage from './MdcImage';

export default {
	components: {
		MdcImage
	},
	props: {
		large: {
			type: Boolean,
			description: 'Displays with an increased height and thumbnail image',
			default: false
		},
		thumbnail: {
			type: String,
			description: 'Optional image URL for prepended thumbnail',
			default: undefined
		}
	}
};
</script>

<style lang="scss" module>
@import '@mds/constants';
.mdc-metadata {
	$height: 18px;
	$large-height: 120px;

	display: flex;
	align-items: center;

	&__thumbnail {
		margin-right: $mds-space-1-x;
		border-radius: $mds-border-radius-panel;
		object-fit: cover;
		height: $height;
		width: $height;

		&--large {
			height: $large-height;
			width: $large-height;
		}
	}

	&__list {
		display: flex;

		color: $mds-text-color-secondary;
		flex-wrap: wrap;

		> * {
			white-space: nowrap;
		}

		> *:not(:last-child)::after {
			color: $mds-color-neutral-80;
			content: '\2022';
			padding-left: $mds-space-half-x;
			padding-right: $mds-space-half-x;
		}
	}
}
</style>
