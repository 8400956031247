<template>
    <div>

        <div v-if="isMobileContentToggle" class="main-msi-performance-section-responsive">
            <div class="msi-performance-divBorder-responsive"></div>
            <div class="common-span-cls">Resources</div>
            <div class="main-table-div-msi">
                <mds-table style="width: 90%;" class="msi-tbl-font-responsive">
                    <mds-tbody>
                        <mds-tr>
                            <mds-td>
                                <router-link class="msi-resources-links"
                                    :to="{ name: 'archive', params: { currentproduct: this.CurrentProduct } }">
                                    Past Issues
                                </router-link>
                            </mds-td>
                        </mds-tr>
                        <mds-tr>
                            <mds-td>
                                <a class="msi-resources-links" tabindex="0" @keydown.enter="toggle = !toggle"
                                    @click="toggle = !toggle">Investment Strategy</a>
                            </mds-td>
                        </mds-tr>
                        <mds-tr v-for="row in resourcesdata" :key="row.ID">
                            <mds-td>
                                <a class="msi-resources-links" tabindex="0"
                                    @keydown.enter="DownloadReport(row.PdfName, 'Resources')"
                                    v-on:click="DownloadReport(row.PdfName, 'Resources')">{{ row.PdfTitle }}</a>
                            </mds-td>
                        </mds-tr>
                        <mds-tr>
                            <mds-td>
                                <a class="msi-resources-links" href="mailto:msi@morningstar.com">Ask The Editor</a>
                            </mds-td>
                        </mds-tr>
                    </mds-tbody>
                </mds-table>
            </div>

            <div class="msi-performance-heading" style="margin-top: 28px;">Bonus Reports</div>

            <mds-table style="width: 90%;" class="msi-tbl-font-responsive">
                <mds-tbody>
                    <mds-tr v-for="row in pdfdata" :key="row.ID">
                        <mds-td>
                            <a class="msi-resources-links" tabindex="0"
                                @keydown.enter="DownloadReport(row.PdfName, 'BonusReports')"
                                v-on:click="DownloadReport(row.PdfName, 'BonusReports')">{{ row.PdfTitle }}</a>
                        </mds-td>
                    </mds-tr>

                </mds-tbody>
            </mds-table>

            <div class="msi-performance-heading" style="margin-top: 28px;">Portfolios and Watchlist</div>
            <mds-table style="width: 90%;" class="msi-tbl-font-responsive">
                <mds-tbody>
                    <mds-tr>
                        <mds-td>
                            <router-link class="msi-performance-text-anchor"
                                :to="{ name: 'tortoisePort', params: { currentproduct: this.CurrentProduct } }">
                                Tortoise Porfolio
                            </router-link>
                        </mds-td>
                    </mds-tr>
                    <mds-tr>
                        <mds-td>
                            <router-link class="msi-performance-text-anchor"
                                :to="{ name: 'harePort', params: { currentproduct: this.CurrentProduct } }">
                                Hare Portfolio
                            </router-link>
                        </mds-td>
                    </mds-tr>
                    <mds-tr>
                        <mds-td>
                            <router-link class="msi-performance-text-anchor"
                                :to="{ name: 'TortoiseAndHareWatchlist', params: { currentproduct: this.CurrentProduct } }">
                                Tortoise & Hare Watchlist
                            </router-link>
                        </mds-td>
                    </mds-tr>

                </mds-tbody>
            </mds-table>


            <div class="msi-performance-divBorder-responsive"></div>
            <div class="common-span-cls">Net Composite Performance</div>
            <div style="margin-top:32px; margin-right: 26px;" class="msi-performance-image-composite-responsive">
                <img class="msi-performance-image-composite-responsive-img" alt="composite portfolio performance"
                    :src="MSIcompositeperformanceimageUrl" />
            </div>
            <div
                style="margin-top:3px;font-size:14px;font-weight: 400;line-height: 18px;color: #1E1E1E; margin-right: 26px; margin-bottom: 54px;">
                The Tortoise and Hare strategies are managed by Morningstar Investment Management LLC. Morningstar
                Investment Management’s subsidiary offers these strategies through a discretionary investment advisory
                service (“Advisory Service”). The “Net of Fees” performance shown reflects a composite of accounts
                invested in accordance with the strategy through the Advisory Service. Net returns include the deduction
                of actual advisory and transaction fees incurred by clients.
            </div>



            <div class="msi-performance-divBorder-responsive"></div>
            <div class="common-span-cls">Portfolio Performance</div>
            <div style="margin-top:32px; margin-right: 26px;" class="msi-performance-image-composite-responsive"
                href="/msiDisclosure">
                <img class="msi-performance-image-composite-responsive-img" alt="Performance Graph"
                    :src="MSIportfolioperformanceimageUrl" />
            </div>
            <router-link class="msi-performance-disclosure-link" target="_blank"
                :to="{ name: 'msi-disclosure', params: { currentproduct: this.$session.get('current-product').toLowerCase() } }">
                Click here for important performance information.
            </router-link>
            <!--<mds-dialog v-model="toggle" class="investment-srategy-modal" title="Investment Strategy">
            <p style="text-align: justify">
                <span><b>Tortoise Portfolio.</b> The Tortoise targets undervalued companies that possess durable competitive advantages (as measured by their Morningstar Economic Moat Rating) and strong balance sheets.</span><br /><br />
                <span><b>Hare Portfolio.</b> The Hare focuses on companies with strong and growing competitive advantages (as measured by their Morningstar Economic Moat Rating). It uses a “growth at a reasonable price” approach, seeking companies with above-average earnings-per-share growth whose shares are trading at reasonable multiples of earnings.</span>
            </p>
        </mds-dialog>-->
            <mds-modal size="small" width="900px" aria-label="Small Modal" v-model="toggle" actionRequired
                class="investment-strategy-modal">
                <mds-section class="with-nested-sections" border-position="bottom" bold>
                    <template #mds-section-title>
                        <span id="title-id">Investment Strategy</span>
                    </template>
                    <template #mds-section-actions>
                        <mds-button-container right-aligned>
                            <mds-button @click="toggle = !toggle" variation="icon-only" icon="remove">Close</mds-button>
                        </mds-button-container>
                    </template>
                    <mds-section border="none" :size="6">
                        <p>
                            <span><b>Tortoise Portfolio.</b> The Tortoise targets undervalued companies that possess
                                durable competitive
                                advantages (as measured by their Morningstar Economic Moat Rating) and strong balance
                                sheets.</span><br /><br />
                            <span><b>Hare Portfolio.</b> The Hare focuses on companies with strong and growing
                                competitive advantages (as
                                measured by their Morningstar Economic Moat Rating). It uses a “growth at a reasonable
                                price” approach,
                                seeking companies with above-average earnings-per-share growth whose shares are trading
                                at reasonable
                                multiples of earnings.</span>
                        </p>
                    </mds-section>
                </mds-section>
            </mds-modal>


        </div>

        <!--Normal-->


        <div v-else class="msi-performance-section-main-div">
            <mds-layout-grid style="width:100%">
                <mds-row>
                    <mds-col :cols="3" :cols-at-s="6" :cols-at-m="6" :cols-at-l="6" :cols-at-xl="3">
                        <div class="msi-performance-divBorder"></div>
                        <div class="common-span-cls">Resources</div>
                        <div style="margin-top:32px;">
                            <div class="resources-main-div-one">
                                <div class="msi-performance-heading">Resources</div>


                                <mds-table class="msi-tbl-font">
                                    <mds-tbody>
                                        <mds-tr>
                                            <mds-td>
                                                <router-link class="msi-resources-links"
                                                    :to="{ name: 'archive', params: { currentproduct: this.CurrentProduct } }">
                                                    Past Issues
                                                </router-link>
                                            </mds-td>
                                        </mds-tr>
                                        <mds-tr>
                                            <mds-td>
                                                <a class="msi-resources-links" tabindex="0"
                                                    @keydown.enter="toggle = !toggle"
                                                    @click="toggle = !toggle">Investment Strategy</a>
                                            </mds-td>
                                        </mds-tr>
                                        <mds-tr v-for="row in resourcesdata" :key="row.ID">
                                            <mds-td>
                                                <a class="msi-resources-links" tabindex="0"
                                                    @keydown.enter="DownloadReport(row.PdfName, 'Resources')"
                                                    v-on:click="DownloadReport(row.PdfName, 'Resources')">{{
                                                        row.PdfTitle
                                                    }}</a>
                                            </mds-td>
                                        </mds-tr>
                                        <mds-tr>
                                            <mds-td>
                                                <a class="msi-resources-links" href="mailto:msi@morningstar.com">Ask The
                                                    Editor</a>
                                            </mds-td>
                                        </mds-tr>
                                    </mds-tbody>
                                </mds-table>



                                <div class="msi-performance-heading" style="margin-top: 28px;">Bonus Reports</div>


                                <mds-table class="msi-tbl-font">
                                    <mds-tbody>
                                        <mds-tr v-for="row in pdfdata" :key="row.ID">
                                            <mds-td>
                                                <a class="msi-resources-links" tabindex="0"
                                                    @keydown.enter="DownloadReport(row.PdfName, 'BonusReports')"
                                                    v-on:click="DownloadReport(row.PdfName, 'BonusReports')">{{
                                                        row.PdfTitle
                                                    }}</a>
                                            </mds-td>
                                        </mds-tr>

                                    </mds-tbody>
                                </mds-table>
                            </div>

                            <div class="main-div-class-bonus-watchlist">
                                <div class="msi-performance-heading" style="margin-top: 28px;">Portfolios and Watchlist
                                </div>

                                <mds-table class="msi-tbl-font-portfolio">
                                    <mds-tbody>
                                        <mds-tr>
                                            <mds-td>
                                                <router-link class="msi-performance-text-anchor"
                                                    :to="{ name: 'tortoisePort', params: { currentproduct: this.CurrentProduct } }">
                                                    Tortoise Porfolio
                                                </router-link>
                                            </mds-td>
                                        </mds-tr>
                                        <mds-tr>
                                            <mds-td>
                                                <router-link class="msi-performance-text-anchor"
                                                    :to="{ name: 'harePort', params: { currentproduct: this.CurrentProduct } }">
                                                    Hare Portfolio
                                                </router-link>
                                            </mds-td>
                                        </mds-tr>
                                        <mds-tr>
                                            <mds-td>
                                                <router-link class="msi-performance-text-anchor"
                                                    :to="{ name: 'TortoiseAndHareWatchlist', params: { currentproduct: this.CurrentProduct } }">
                                                    Tortoise & Hare Watchlist
                                                </router-link>
                                            </mds-td>
                                        </mds-tr>

                                    </mds-tbody>
                                </mds-table>
                            </div>
                        </div>
                    </mds-col>
                    <mds-col :cols="6" :cols-at-s="3" :cols-at-m="3" :cols-at-l="6" :cols-at-xl="6">
                        <div class="msi-net-composite-graph-main">
                            <div class="msi-performance-divBorder"></div>
                            <div class="common-span-cls">Net Composite Performance</div>
                            <div style="margin-top:32px;" class="msi-performance-image-composite">
                                <img alt="composite portfolio performance" :src="MSIcompositeperformanceimageUrl" />
                            </div>
                            <div
                                style="margin-top:3px;font-size:14px;font-weight: 400;line-height: 18px;color: #1E1E1E;">
                                The Tortoise and Hare strategies are managed by Morningstar Investment Management LLC.
                                Morningstar Investment Management’s subsidiary offers these strategies through a
                                discretionary
                                investment advisory service (“Advisory Service”). The “Net of Fees” performance shown
                                reflects a
                                composite of accounts invested in accordance with the strategy through the Advisory
                                Service. Net
                                returns include the deduction of actual advisory and transaction fees incurred by
                                clients.
                            </div>
                        </div>
                    </mds-col>
                    <mds-col :cols="3" :cols-at-s="3" :cols-at-m="3" :cols-at-l="3" :cols-at-xl="3">
                        <div class="msi-net-composite-graph-main-one">
                            <div class="msi-performance-divBorder"></div>
                            <div class="common-span-cls">Portfolio Performance</div>
                            <div style="margin-top:32px; padding-left: 8px;" class="msi-performance-performance-graph"
                                href="/msiDisclosure">
                                <img alt="Performance Graph" :src="MSIportfolioperformanceimageUrl" />
                            </div>
                            <router-link class="msi-performance-disclosure-link" target="_blank"
                                :to="{ name: 'msi-disclosure', params: { currentproduct: this.$session.get('current-product').toLowerCase() } }">
                                Click here for important performance information.
                            </router-link>
                        </div>
                    </mds-col>
                </mds-row>
            </mds-layout-grid>

            <!--<mds-dialog v-model="toggle" title="Investment Strategy">
            <p style="text-align: justify">
                <span><b>Tortoise Portfolio.</b> The Tortoise targets undervalued companies that possess durable competitive advantages (as measured by their Morningstar Economic Moat Rating) and strong balance sheets.</span><br /><br />
                <span><b>Hare Portfolio.</b> The Hare focuses on companies with strong and growing competitive advantages (as measured by their Morningstar Economic Moat Rating). It uses a “growth at a reasonable price” approach, seeking companies with above-average earnings-per-share growth whose shares are trading at reasonable multiples of earnings.</span>
            </p>
        </mds-dialog>-->
            <mds-modal size="small" width="900px" aria-label="Small Modal" v-model="toggle" actionRequired
                class="investment-strategy-modal">
                <mds-section class="with-nested-sections" border-position="bottom" bold>
                    <template #mds-section-title>
                        <span id="title-id">Investment Strategy</span>
                    </template>
                    <template #mds-section-actions>
                        <mds-button-container right-aligned>
                            <mds-button @click="toggle = !toggle" variation="icon-only" icon="remove">Close</mds-button>
                        </mds-button-container>
                    </template>
                    <mds-section border="none" :size="6">
                        <p>
                            <span><b>Tortoise Portfolio.</b> The Tortoise targets undervalued companies that possess
                                durable
                                competitive advantages (as measured by their Morningstar Economic Moat Rating) and
                                strong
                                balance sheets.</span><br /><br />
                            <span><b>Hare Portfolio.</b> The Hare focuses on companies with strong and growing
                                competitive
                                advantages (as measured by their Morningstar Economic Moat Rating). It uses a “growth at
                                a
                                reasonable price” approach, seeking companies with above-average earnings-per-share
                                growth whose
                                shares are trading at reasonable multiples of earnings.</span>
                        </p>
                    </mds-section>
                </mds-section>
            </mds-modal>
        </div>
    </div>
</template>

<script>
import { mapState } from "vuex";
import {
    MdsLayoutGrid,
    MdsRow,
    MdsCol
} from "@mds/layout-grid";
import {
    MdsTable,
    MdsTbody,
    MdsTr,
    MdsTd,
} from "@mds/data-table";
//import MdsDialog from "@mds/dialog";
import MdsModal from '@mds/modal';
import MdsSection from "@mds/section";
import { MdsButton, MdsButtonContainer } from '@mds/button';
import GA4TrackingMixin from "../../../mixins/GA4TrackingMixin";
import { fetchData } from "@/utilities/API/apiUtility";

export default {
    name: "msi-performance-section",
    components: {
        MdsLayoutGrid,
        MdsRow,
        MdsCol,
        //MdsDialog,
        MdsModal,
        MdsSection,
        MdsButton,
        MdsButtonContainer,
        MdsTable,
        MdsTbody,
        MdsTr,
        MdsTd
    },
    mixins: [GA4TrackingMixin],
    data() {
        return {
            MSIportfolioperformanceimageUrl: null,
            MSIcompositeperformanceimageUrl: null,
            ImageDataGet: process.env.VUE_APP_Newsletters_Utilities + process.env.VUE_APP_GetImagedata,
            MSIportfolioperformance: 'MSI Portfolio Performance Image', // Change this to your desired placement
            MSIcompositeperformance: 'MSI Composite Performance Image', // Change this to your desired placement
            toggle: false,
            DownloadPdfUrl: process.env.VUE_APP_BackEndAPI + process.env.VUE_APP_DownloadPdfUrl,
            CurrentProduct: this.$session.get("current-product"),
            isMobileContentToggle: null,
            viewPortWidth: null,
            CurrentDate: new Date(),
            GetAllMiscReportURL: process.env.VUE_APP_BackEndAPI + process.env.VUE_APP_GetAllMiscReport,
            pdfdata: "",
            resourcesdata: "",
        }
    },
    methods: {
        async MSIperformanceImagesdata() {
            try {
                let Url = this.ImageDataGet;
                Url = Url.replace("{ProductCode}", "MSI");
                fetchData(Url)
                    .then((response) => {
                        const images = response.data.fileData;
                        console.log(images);
                        const filteredImagePortfolio = images.find(image => image.description.includes(this.MSIportfolioperformance));
                        const filteredImageComposite = images.find(image => image.description.includes(this.MSIcompositeperformance));

                        if (filteredImagePortfolio) {
                            this.MSIportfolioperformanceimageUrl = `${filteredImagePortfolio.s3URL}?t=${new Date().getTime()}`;
                        }

                        if (filteredImageComposite) {
                            this.MSIcompositeperformanceimageUrl = `${filteredImageComposite.s3URL}?t=${new Date().getTime()}`;
                        }

                    })


            } catch (ex) {
                console.log(ex.message);
            }

        },

        DownloadReport(FileName, Type) {
            this.$gtag.event('download_pdf', {
                'product': 'MSI',
                'issue_date': this.CurrentDate,
                'issue_title': FileName,
                'type': "Bonus",
                'user_type': localStorage.getItem("userType_MSI")
            })
            try {
                let Url = this.DownloadPdfUrl;
                Url = Url.replace('{ProductCode}', this.CurrentProduct);
                Url = Url.replace('{FileName}', FileName);
                Url = Url.replace('{Type}', Type);
                fetchData(Url).then((response) => {
                    if (response) {
                        if (response.status == 200) {
                            if (response.data) {
                                var results = response.data;
                                if (results) {
                                    if (results.FilePath) {
                                        const link = document.createElement("a");
                                        link.setAttribute("href", results.FilePath);
                                        link.setAttribute("target", "_blank");
                                        link.setAttribute("download", FileName);
                                        link.click();
                                    }
                                }
                            }
                        }
                    }
                }).catch((error) => {
                    console.log(error);

                })
                    .finally();
            } catch (ex) {
                console.log(ex.message);
            }
        },
        GetAllPdfUpload() {
            try {
                fetchData(this.GetAllMiscReportURL).then((response) => {
                    if (response) {
                        if (response.status == 200) {
                            if (response.data) {
                                this.pdfdata = response.data.filter(row => {
                                    return row.PdfType === 'BonusReports' && row.ProductCode === 'MSI';
                                });
                                this.resourcesdata = response.data.filter(row => {
                                    return row.PdfType === 'Resources' && row.ProductCode === 'MSI';
                                });
                            }
                        }
                    }
                });
            } catch (ex) {
                console.log(ex.message);
            }
        },
    },

    mounted() {
        this.isMobileContentToggle = this.$store.state.responsive.isMobileContent;
        this.GetAllPdfUpload();
        this.getUserDetailsMixin(this.CurrentProduct);
    },

    created() {
        this.MSIperformanceImagesdata()
    },

    computed: mapState({
        isMobileContent: state => state.responsive.isMobileContent,
        isMobileContentAlias: 'isMobileContent',
        isMobileContentState(state) {
            return state.responsive.isMobileContent
        }

    }),

    watch: {
        isMobileContentState() {
            this.isMobileContentToggle = this.isMobileContentState;
        }
    }
}
</script>

<style scoped>
@media only screen and (min-width: 0px) and (max-width: 1200px) {

    .msi-performance-disclosure-link {
        margin-top: 3px;
        font-size: 14px;
        font-weight: 400;
        line-height: 18px;
        color: #1E1E1E;
        text-decoration: none;
    }
}

@media only screen and (min-width: 1200px) and (max-width: 12800px) {

    .msi-performance-disclosure-link {
        margin-top: -200px;
        font-size: 14px;
        font-weight: 400;
        line-height: 18px;
        color: #1E1E1E;
        text-decoration: none;
    }

    .msi-performance-divBorder {
        border-top: 1px solid #5e5e5e;
        transform: rotate(180deg);
    }

}


@media only screen and (min-width: 1000px) and (max-width: 10000px) {
    .common-span-cls {
        width: 150%;
        font-style: normal;
        font-weight: 300;
        font-size: 23px;
        line-height: 27px;
        color: #1E1E1E;
        margin-top: 12px;
    }
}

@media only screen and (min-width: 600px) and (max-width: 1000px) {
    .common-span-cls {
        width: 150%;
        font-style: normal;
        font-weight: 300;
        font-size: 23px;
        line-height: 27px;
        color: #1E1E1E;
        margin-top: 12px;
    }
}


@media only screen and (min-width: 0px) and (max-width: 600px) {
    .common-span-cls {
        width: 90%;
        font-style: normal;
        font-weight: 300;
        font-size: 23px;
        line-height: 27px;
        color: #1E1E1E;
        margin-top: 12px;
    }
}

.mds-layout-grid .mds-layout-grid__col--6-at-1200 {
    max-width: 53%;
    flex-basis: 53%;
}

.msi-resources-links {
    margin-left: 1px;
}

.msi-performance-image-composite {
    width: 100%;
}

.msi-performance-image-composite img {
    width: 100%;
    height: 500px;
}

.msi-performance-performance-graph {
    width: 100%;
}

.msi-performance-performance-graph img {
    width: 100%;
    margin-left: -10px;
    height: 490px;
}

.msi-performance-heading {
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    color: #1E1E1E;
    margin-bottom: 12px;
}

a.msi-performance-text-anchor:hover {
    color: #ff0000;
    text-decoration: none;
    cursor: pointer;
}

a.msi-performance-text-anchor:active {
    color: #666666;
    text-decoration: none;
}

.msi-performance-text-anchor,
a.msi-performance-text-anchor:link,
span.msi-performance-text-anchor {
    color: #1E1E1E;
    border-top: none;
}

.msi-performance-text-anchor {
    margin-left: 1px;
    color: #1E1E1E;
    text-decoration: none;
    width: 274px;
    border-top: 1px dotted #ff0000;
    font-family: "Univers", HelveticaNeue, "Helvetica Neue", Helvetica, Arial,
        sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 21px;
}

.msi-performance-Solid175Gray {
    border-top: 1px Solid #cccccc;
}

.heightInnerText {
    margin-top: 3px;
}

.msi-performance-text-anchor:hover {
    color: #ff0000 !important;
    text-decoration: none;
    cursor: pointer;
}

.routerLink {
    text-decoration: none;
    font-family: "Univers", HelveticaNeue, "Helvetica Neue", Helvetica, Arial,
        sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 22px;
    color: #000000;
}

.router-link-exact-active {
    margin-top: 10px;
    box-shadow: inset 0 -4px 0 0 red;
    position: relative;
    padding-bottom: 13px;
}

.msi-tbl-font tr td {
    color: #666666;
    text-decoration: none;
    font-family: "Univers", HelveticaNeue, "Helvetica Neue", Helvetica, Arial,
        sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 21px;
    cursor: pointer;
    pointer-events: all;
}

.msi-tbl-font tr td a {
    color: #666666;
    text-decoration: none;
    font-family: "Univers", HelveticaNeue, "Helvetica Neue", Helvetica, Arial,
        sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 21px;
    cursor: pointer;
    pointer-events: all;
}

.msi-tbl-font tr td:hover {
    color: #ff0000;
    text-decoration: none;
}

.mds-td:first-child {
    padding-left: 0px;
}

.msi-tbl-font tr td a:hover {
    color: #ff0000;
    text-decoration: none;
    cursor: pointer;
}

@media only screen and (min-width: 0px) and (max-width: 600px) {
    .main-msi-performance-section-responsive {

        width: 95%;
        margin-left: 16px;
        padding-right: 16px;
    }
}

@media only screen and (min-width: 600px) and (max-width: 6000px) {
    .main-msi-performance-section-responsive {
        display: none !important;
    }
}



.msi-tbl-font-responsive tr td {
    color: #666666;
    text-decoration: none;
    font-family: "Univers", HelveticaNeue, "Helvetica Neue", Helvetica, Arial,
        sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 21px;
    cursor: pointer;
    pointer-events: all;
    width: 100%;
    margin-right: 26px;
}

.msi-tbl-font-responsive tr td a {
    color: #666666;
    text-decoration: none;
    font-family: "Univers", HelveticaNeue, "Helvetica Neue", Helvetica, Arial,
        sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 21px;
    cursor: pointer;
    pointer-events: all;
    width: 100%;
}

.msi-tbl-font-responsive tr td:hover {
    color: #ff0000;
    text-decoration: none;
}


.msi-tbl-font-responsive tr td a:hover {
    color: #ff0000;
    text-decoration: none;
    cursor: pointer;
    width: 100%;
}

.main-table-div-msi {
    width: 100%;
    margin-right: 26px;
}

.msi-tbl-font-responsive {
    margin-right: 26px;
}

.msi-performance-divBorder-responsive {
    border-top: 1px solid #5e5e5e;
    transform: rotate(180deg);
    margin-right: 26px;
    margin-left: 0px;
    margin-top: 50px;
    width: 90%;
}



.msi-performance-image-composite-responsive-img {
    width: 100%;

    margin-bottom: 16px;

}

@media only screen and (min-width: 600px) and (max-width: 768px) {
    .msi-performance-section-main-div {

        width: 95%;
        margin: auto;
        padding: auto;
        margin-left: 5%;
    }

    .msi-tbl-font {
        width: 200%;
    }

    .msi-tbl-font-portfolio {
        width: 200%;
    }

    .msi-performance-divBorder {
        width: 200%;
        border-top: 1px solid #5e5e5e;
        transform: rotate(180deg);
    }

    .msi-net-composite-graph-main {

        width: 95%;
    }

    .msi-net-composite-graph-main-one {

        width: 100%;
        margin-left: 0%;
    }



    .msi-performance-image-composite img {
        width: 100%;
        margin-top: -150px;
        margin-bottom: -120px;
    }


}

@media only screen and (min-width: 768px) and (max-width: 1000px) {
    .msi-performance-section-main-div {

        width: 95%;
        margin: auto;
        padding: auto;
        margin-left: 5%;
    }

    .msi-tbl-font {
        width: 180%;
    }

    .msi-tbl-font-portfolio {
        width: 200%;
    }

    .msi-performance-divBorder {
        width: 200%;
        border-top: 1px solid #5e5e5e;
        transform: rotate(180deg);
    }

    .msi-net-composite-graph-main {

        width: 95%;
    }

    .msi-net-composite-graph-main-one {
        width: 90%;

        margin-left: 0%;
    }

    .msi-performance-image-composite img {
        width: 100%;
        margin-top: -120px;
        margin-bottom: -90px;
    }


}

@media only screen and (min-width: 1000px) and (max-width: 1199px) {
    .msi-performance-section-main-div {

        width: 95%;
        margin: auto;
        padding: auto;
        margin-left: 5%;
    }

    .msi-tbl-font {
        width: 180%;
    }

    .msi-tbl-font-portfolio {
        width: 200%;
    }

    .msi-performance-divBorder {
        width: 200%;
        border-top: 1px solid #5e5e5e;
        transform: rotate(180deg);
    }

    .msi-net-composite-graph-main {

        width: 95%;
    }

    .msi-net-composite-graph-main-one {
        width: 90%;

        margin-left: 0%;
    }



    .msi-performance-image-composite img {
        width: 100%;
        margin-top: -50px;
        margin-bottom: -50px;
    }


}

@media only screen and (min-width: 1200px) and (max-width: 1280px) {
    .msi-performance-section-main-div {
        width: 92%;
        margin: auto;
        padding: auto;
        margin-left: 5%;
    }
}


@media only screen and (min-width: 1280px) and (max-width: 12800px) {
    .msi-performance-section-main-div {
        width: 1280px;
        margin: auto;
        padding: auto;
        margin-top: 80px;
    }
}

@media only screen and (min-width: 0px) and (max-width: 600px) {
    .msi-performance-section-main-div {
        display: none !important;
    }

    .investment-strategy-modal .mds-modal__content {
        overflow-y: scroll;
    }
}

@media only screen and (min-width: 1600px) and (max-width: 12800px) {
    .msi-net-composite-graph-main-one {
        width: 100%;
        margin-top: -210.5%;
        margin-left: 330%;
    }

    .msi-net-composite-graph-main {
        width: 92%;
    }
}


@media only screen and (min-width: 600px) and (max-width: 768px) {
    .msi-performance-performance-graph {
        width: 100%;
    }

    .msi-performance-performance-graph img {
        width: 230px;
        margin-left: -10px;

        margin-top: -40px;

    }
}

@media only screen and (min-width: 768px) and (max-width: 1000px) {
    .msi-performance-performance-graph {
        width: 100%;
    }

    .msi-performance-performance-graph img {
        width: 300px;
        margin-left: -28px;
        margin-top: -12px;

    }

    .msi-performance-image-composite {
        width: 100%;
    }

    .msi-performance-image-composite img {
        width: 400px;
        margin-top: -130px;
    }
}

@media only screen and (min-width: 1000px) and (max-width: 1200px) {
    .msi-performance-performance-graph {
        width: 100%;
    }

    .msi-performance-performance-graph img {
        width: 400px;
        margin-left: -77px;
        margin-top: -12px;

    }

    .msi-performance-image-composite {
        width: 100%;
    }

    .msi-performance-image-composite img {
        width: 500px;
        margin-top: -100px;
    }
}

@media only screen and (min-width: 1200px) and (max-width: 1280px) {
    .msi-performance-performance-graph {
        width: 100%;
    }

    .msi-performance-performance-graph img {
        width: 250px;
        margin-left: -9px;
        margin-top: -32px;

    }

    .msi-performance-image-composite {
        width: 100%;
    }

    .msi-performance-image-composite img {
        width: 610px;
        margin-top: -60px;
    }
}

@media only screen and (min-width: 1280px) and (max-width: 12800px) {

    .msi-performance-image-composite {
        width: 100%;
    }

    .msi-performance-image-composite img {
        width: 610px;
        margin-top: -46px;
    }

    .msi-performance-performance-graph {
        width: 100%;
    }

    .msi-performance-performance-graph img {
        width: 250px;
        margin-left: -10px;
        margin-top: -35px;

    }
}


@media only screen and (min-width: 767px) and (max-width: 1200px) {
    .main-div-class-bonus-watchlist {

        width: 150%;
        margin-top: -273px;

        padding-left: 100%;
        margin-bottom: 160px;

    }

    .resources-main-div-one {
        width: 53%;
    }

}

@media only screen and (min-width: 600px) and (max-width: 768px) {
    .main-div-class-bonus-watchlist {


        padding-bottom: 20px;

    }



}

.investment-srategy-modal {
    width: 80%;
    padding-left: 8%;
}
</style>
<style>
/*
    .msi-performance-text-anchor:hover {
    color: #ff0000 !important;
    text-decoration: none;
    cursor: pointer;
    }
    */
.investment-strategy-modal .mds-section .mds-section__header-container {
    margin-bottom: 5px;
}
</style>