<template>
	<span
		:aria-hidden="!ariaLabel"
		:aria-label="ariaLabel"
		:class="{
			[$style['mdc-icon']]: true,
			[$style['mdc-icon--small']]: small,
			[$style['mdc-icon--unstroke']]: !isMdsIcon
		}"
		:style="{
			stroke: color,
			fill: color,
		}"
		v-html="svgSrc"
	>
	</span>
</template>

<script>
import mdsIcons from '@mds/icon/dist/mds-icons.json';
import mdcIcons from '@/assets/icons/mdc-icons.json';

export default {
	props: {
		ariaLabel: {
			type: String,
			description: 'Descriptive label for use with assistive technology',
			default: null
		},
		name: {
			type: String,
			description: 'Icon name as provided by MDS',
			default: undefined
		},
		small: {
			type: Boolean,
			description: 'Small size variation',
			default: false
		},
		color: {
			type: String,
			description: 'Tint color to apply to icon',
			default: undefined
		}
	},
	computed: {
		isMdsIcon() {
			return !!mdsIcons[this.name];
		},
		svgSrc() {
			return mdcIcons[this.name] || mdsIcons[this.name];
		}
	}
};
</script>

<style lang="scss" module>
@import '@mds/icon/src/icon_mixins.scss';
@import "@mds/constants";
@import '@mds/typography';
.mdc-icon {
	@include mds-border-box();
	@include mds-icon-color($mds-color-neutral-37);

	display: inline-block;
	height: $mds-size-icon-m;
	stroke-width: 1.1;
	width: $mds-size-icon-m;

	svg {
			display: block;
	}

	&--small {
		height: $mds-size-icon-s;
		width: $mds-size-icon-s;
	}

	&--unstroke {
		stroke: none;
	}
}
</style>
